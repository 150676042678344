import {Component, Input} from '@angular/core';
import {IPersonnelien} from '../../../interfaces/personne-lien';
import {Personne} from '../../../models/personne';
import {PersonGlobals} from "../../../const-global/globals";

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
    @Input() modeFiche: boolean;
    @Input() modeCarte: boolean;
    @Input() volet: boolean;
    @Input() centered: boolean;
    @Input() small: boolean;
    public readonly PERSON_GLOBAL = PersonGlobals;
    #personne: Partial<IPersonnelien> = {};
    /**
     * todo rework from api
     */
    @Input() set personne(data: Partial<IPersonnelien | Personne>) {
        this.#personne = data as IPersonnelien;
        if (!this.#personne.personnePhysique) {
            // @ts-ignore
            this.#personne.personnePhysique = {};
        }
        if ('raisonSociale' in data && !this.#personne.personneMorale) {
            // @ts-ignore
            this.#personne.personneMorale = {};
            this.#personne.personneMorale.libelle = data.raisonSociale || '';
        }
        if ('isComplete' in data) {
            this.#personne.isOptimized = !!data.isComplete;
        }
        if ('nom' in data) {
            this.#personne.personnePhysique.nom = data.nom || '';
        }
        if ('prenom' in data) {
            this.#personne.personnePhysique.prenom = data.prenom || '';
        }
    }

    // tslint:disable-next-line:typedef
    get personne() {
        return this.#personne;
    }
}
