import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractClient} from './abstract-client';
import {environment} from '../../environments/environment';
import {IPersonnelien} from '../../interfaces/personne-lien';
import {FilterItem} from "../../models/filteritem";
import {FiltresGlobals} from "../../const-global/filtres";

const API_URL = environment.apiURL;

export type Filter = {
    active?: boolean;
    page?: number;
    itemsPerPage?: number
    partialPagination?: boolean
    'groups[]'?: string[];
    'groups'?: string;
    'similarity'?: string
    'type[]'?: string[];
    'statut.libelle[]'?: string[];
    'visibilite[]'?: string[]
    'search'?: string;
    'not:uuid[]'?: string[]
    writable?: boolean;
    personReferent?: number;
};

@Injectable({
    providedIn: 'root'
})
export class LienMailService extends AbstractClient<IPersonnelien, Filter> {

    constructor(http: HttpClient) {
        super(http, API_URL + 'lien_mails');
    }

    buildFilterItems(): FilterItem[] {
        return [
            new FilterItem(Object.assign({}, FiltresGlobals.PERSONNE_TYPE_FILTRE, {attributeName: 'lien.type[]'})),
            new FilterItem(Object.assign({}, FiltresGlobals.PERSONNE_STATUT_FILTRE, {attributeName: 'lien.statut.libelle[]'})),
            new FilterItem({
                id: 'personneReferent',
                name: 'Référent(s)',
                attributeName: 'lien.referents[]',
                value: null,
                hidden: false,
                inputType: 'select',
            }),
            new FilterItem({
                id: 'personneConsent',
                name: 'Consentement',
                attributeName: 'consent[]',
                value: null,
                hidden: false,
                inputType: 'checkbox',
            }),
            new FilterItem({
                id: 'personneRecueil',
                name: 'Moyen de recueil',
                attributeName: 'consentType[]',
                value: null,
                hidden: false,
                inputType: 'checkbox'
            }),
        ];
    }
}
