import {Injectable} from '@angular/core';
import {AbstractClient} from './abstract-client';
import {Filter} from './personne-lien.service';
import {environment, environment as ENV} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {IDevisLigne} from '../../interfaces/devisligne';

const API_URL = environment.apiURL;

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    ),
    params: {}
};

@Injectable({
    providedIn: 'root'
})
export class DevisligneService extends AbstractClient<IDevisLigne, Filter> {
    devisLigneActiveSubject = new BehaviorSubject<boolean>(true);
    private httpClient: HttpClient;

    constructor(http: HttpClient) {
        super(http, ENV.apiURL + 'devis_lignes');
        this.httpClient = http;

    }
    public createMultiplesLignes(params): Observable<any> {
        return this.httpClient.post(`${API_URL}devis_lignes/multiple`, params, httpOptions);
    }

    public calculate(params): Observable<any> {
        return this.httpClient.post(`${API_URL}devis_lignes/calculate`, params, httpOptions);
    }
}
