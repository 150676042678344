import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {shareReplay} from 'rxjs/operators';

const API_URL = environment.apiURL;

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};

@Injectable({
    providedIn: 'root'
})
export class SegmentService {
    public subjectSegment = new Subject<any>();
    public segmentUpdatedSubject = new Subject<any>();

    constructor(private http: HttpClient) {}

    getSegmentsListe(offset: string = null,
                     limit: string = null,
                     recherche: string = null,
                     order: string = null,
                     myContacts: string = 'false'): Observable<any> {

        let options = new HttpParams();
        options = options.set('my_contacts', myContacts);

        if (offset && limit){
            options = options.set('offset', offset.toString());
            options = options.set('limit', limit.toString());
        }
        if (recherche){
            options = options.set('recherche', recherche);
        }
        if (order){
            options =  options.set('order', order);
        }

        return this.http.get(API_URL + 'segments', { params: options});
    }

    getFilAriane(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'segments/fil-ariane/' + uuid);
    }

    getSegment(uuid: string, displayValues: boolean): Observable<any> {
        const options = displayValues ? {
            params: new HttpParams()
                .set('displayValues', displayValues.toString())
        } : {};
        return this.http.get(API_URL + 'segments/' + uuid, options);
    }

    /**
     * data pour select2
     * liste des segments visibles par l'utilisateur courant
     */
    getUserSegmentsList(): Observable<any>{
        return this.http.get(API_URL + 'segments/get-list');
    }

    getSegmentResultats(uuid: string,
                        offset: string = null,
                        limit: string = null,
                        count: string = null
    ): Observable<any> {
        let options = {}
        if (offset && limit) {
            if (count) {
                options = {
                    params: new HttpParams()
                        .set('offset', offset.toString())
                        .set('limit', limit.toString())
                        .set('count', count.toString())
                }
            } else {
                options = {
                    params: new HttpParams()
                        .set('offset', offset.toString())
                        .set('limit', limit.toString())
                }
            }

        } else{
            if (count) {
                options = {
                    params: new HttpParams()
                        .set('count', count.toString())
                }
            }
        }
        return this.http.get(API_URL + 'segments/resultats/' + uuid, options);
    }

    getSegmentExportCsv(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'segments/export/csv/' + uuid, {responseType: 'arraybuffer'});
    }

    updateSegment(uuid: string, form: FormData): Observable<any> {
        return this.http.put(API_URL + 'segments/' + uuid, form, httpOptions);
    }

    createSegment(form: FormData): Observable<any> {
        return this.http.post(API_URL + 'segments', form, httpOptions);
    }

    duplicateSegment(uuid: string, form: FormData): Observable<any> {
        return this.http.post(API_URL + 'segments/duplicate/' + uuid , form, httpOptions);
    }

    getChamps(): Observable<any> {
        const listeChamps$ = this.http.get(API_URL + 'segments-champs').pipe(
            shareReplay()
        );
        return listeChamps$;
    }

    getOperateurs(): Observable<any> {
        const listeOperateurs$ = this.http.get(API_URL + 'segments-operateurs').pipe(
            shareReplay()
        );
        return listeOperateurs$;
    }

    calculerResultats(form: FormData): Observable<any> {
        return this.http.post(API_URL + 'segments/calculer', form, httpOptions);
    }

    getSegmentLiveResultats(form: any): Observable<any> {
        return this.http.post(API_URL + 'segments/live-resultats', form, httpOptions);
    }

    /* Récupère l'export csv du segment */
    public getExportCsv(uuid: string, titre: string): void {
        this.getSegmentExportCsv(
            uuid
        ).subscribe(
            data => {
                const blob = new Blob([data], {type: 'txt/csv'});
                const url = window.URL.createObjectURL(blob);
                const fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = titre + '_' + Date.now() + '.csv';
                fileLink.click();
                window.URL.revokeObjectURL(url);
            },
            err => {
                console.log(err);
            }
        );
    }

    public getCountList(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'segments/count-list/' + uuid);
    }
}
