import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {EvenementFilter, EvenementService} from '../../_services/evenement.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {NotifierService} from 'angular-notifier';
import {ModalService} from '../../_modal';
import {ExceptionService} from '../../_services/exception.service';
import {date} from '../../../const-global/format-date';
import {IEvenement} from '../../../interfaces/evenement';
import {PersonGlobals, EvenementGlobals} from '../../../const-global/globals';
import IHydraCollection from '../../../interfaces/hydra-collection';
import {finalize} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {PersonneService} from '../../_services/personne.service';
import {Subscription} from 'rxjs';
import {Moment} from 'moment';


@Component({
	selector: 'app-evenement',
	templateUrl: './evenement.component.html',
	styleUrls: ['./evenement.component.scss']
})
export class EvenementComponent implements OnInit, OnDestroy, OnChanges {
	public readonly PERSON_GLOBALS = PersonGlobals;
	public readonly EVENEMENT_GLOBALS = EvenementGlobals;

	public routeEventPersonne = this.router.url.startsWith('/repertoire');

	@Input() eventDashboard: boolean;
	@Input() eventRappel: boolean;
	@Input() uuid: string;
	@Output() closed = new EventEmitter<any>();
	@Output() eventVisible = new EventEmitter<any>();
	public uuidEvent: string;
	public index: number;

	public events: Array<IEvenement> = [];
	public dataEventAPI: IHydraCollection<IEvenement>;

	public fullDate = date.fullDate;
	public fullDateHeure = date.fullDateHeure;
	public jour = date.jour;
	public annee = date.annee;
	public heureMinute = date.heureMinute;

	// Paramètre request
	public filter: EvenementFilter = {
		page: 1,
		itemsPerPage: 10,
		titre: '',
		statut: null,
		type: null,
		actif: null,
		'dateEcheance[after]': null,
		'dateEcheance[before]': null,
	};

	public isLoading: any;
	private rechercheEventSubscription: Subscription;
	private typeEventSubscription: Subscription;
	private statutSubscription: Subscription;
	private dateFinEventSubscription: Subscription;
	private dateDebutEventSubscription: Subscription;

	constructor(
		public evenementService: EvenementService,
		private errorService: ExceptionService,
		private router: Router,
		private modalService: ModalService,
		private exceptionService: ExceptionService,
		private ngxService: NgxUiLoaderService,
		private personneService: PersonneService,
		private notifier: NotifierService
	) {
	}

	ngOnInit(): void {
		this.listenerFiltreEvent();
		this.fetchEvenement();
	}

	ngOnDestroy(): void {
		this.rechercheEventSubscription.unsubscribe();
		this.typeEventSubscription.unsubscribe();
		this.statutSubscription.unsubscribe();
		this.dateDebutEventSubscription.unsubscribe();
		this.dateFinEventSubscription.unsubscribe();
	}

	private fetchEvenement(refresh: boolean = false): void {
		this.ngxService.startLoader('list');
		this.isLoading = true;
		if (this.eventDashboard || this.eventRappel) {
			// get event sur dashboard et rappel (on récupère d'abord la personne connectée afin d'appeler ses events)
			this.filter['userAssigne'] = 'me'
			this.filter.actif = 1;
			this.filter.statut = 1;
			this.evenementService.getListeEvenementsByUserApiPlateforme(this.uuid, this.filter)
				.pipe(finalize(() => {
					this.ngxService.stopLoader('list');
					this.isLoading = false;
				})).subscribe(data => {
					this.dataEventAPI = data;
					this.getEventListener(data, false);
				},
				err => {
					this.errorService.statutErreur(err, true);
				}
			);
		} else {
			// get event sur une fiche personne
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.pipe(finalize(() => {
					this.ngxService.stopLoader('list');
					this.isLoading = false;
				})).subscribe(data => {
					this.getEventListener(data, refresh);
				},
				err => {
					this.errorService.statutErreur(err, true);
				}
			);
		}
	}

	// permet de formater et d'ajouter les events à la liste (scroll) ou de les remplacer (filtre et action)
	private getEventListener(data, replace: boolean = null): void {
		const evts = new Array<IEvenement>();
		this.dataEventAPI = data;
		data['hydra:member'].forEach(evenement => {
			this.formatDateEvent(evenement);
			evts.push(evenement);
		});
		if (replace === true) {
			this.events = evts;
		} else {
			this.events = this.events.concat(...evts);
		}
	}

	private listenerFiltreEvent(): void {
		// filtre type
		this.typeEventSubscription = this.evenementService.typeEventSubject.subscribe(type => {
			this.filter.page = 1;
			this.filter.type = type;
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.subscribe((eventAPI: IHydraCollection<IEvenement>) => {
					this.getEventListener(eventAPI, true);
				});
		});
		// filtre statut
		this.statutSubscription = this.evenementService.statutEventSubject.subscribe(statut => {
			this.filter.page = 1;
			this.filter.statut = statut;
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.subscribe((eventAPI: IHydraCollection<IEvenement>) => {
					this.getEventListener(eventAPI, true);
				});
		});
		// fitlre recherche
		this.rechercheEventSubscription = this.evenementService.rechercheEventSubject.subscribe(recherche => {
			this.filter.page = 1;
			this.filter.titre = recherche;
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.subscribe((eventAPI: IHydraCollection<IEvenement>) => {
					this.getEventListener(eventAPI, true);
				});
		});
		// filtre date de début
		this.dateDebutEventSubscription = this.evenementService.dateDebutEventSubject.subscribe(dateDebut => {
			this.filter.page = 1;
			this.filter['dateEcheance[after]'] = dateDebut;
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.subscribe((eventAPI: IHydraCollection<IEvenement>) => {
					this.getEventListener(eventAPI, true);
				});
		});
		// filtre date de fin
		this.dateFinEventSubscription = this.evenementService.dateFinEventSubject.subscribe(dateFin => {
			this.filter.page = 1;
			this.filter['dateEcheance[before]'] = dateFin;
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.subscribe((eventAPI: IHydraCollection<IEvenement>) => {
					this.getEventListener(eventAPI, true);
				});
		});
	}

	public onScroll(): void {
		if (this.dataEventAPI['hydra:view']['hydra:next'] !== 'undefined') {
			this.filter.page++;
			this.fetchEvenement();
		}
	}

	public modifierEvenement(uuidEvent: string): void {
		const options = {
			queryParams: {
				dashboard: this.eventDashboard,
				rappel: this.eventRappel
			}
		};
		this.router.navigate(['repertoire/modifier/evenement/' + uuidEvent], options);
	}

	// TODO : voir pour récupérer dynamiquement le count sur le retour de API Plateform
	public getNbEvents(): void {
		if (this.eventDashboard || this.eventRappel) {
			this.evenementService.getNbEventRappel().subscribe();
		} else {
			this.evenementService.getNbEventFiche(this.uuid).subscribe();
		}
		this.evenementService.majNbEventRappel();
	}

	public desactiveEvenement(): void {
		this.filter.page = 1;
		this.evenementService.desactiveEvenement(this.uuidEvent).subscribe(() => {
				this.getEventUserOrPersonne();
			},
			err => {
				this.showNotification('error', this.exceptionService.statutErreur(err));
			});
		this.closeModal('desactiveModalEvent');
	}

	public terminerEvenement(): void {
		this.filter.page = 1;
		this.evenementService.terminerEvenement(this.uuidEvent).subscribe(() => {
				this.getEventUserOrPersonne();
			},
			err => {
				this.showNotification('error', this.exceptionService.statutErreur(err));
			});
		this.closeModal('validateModalEvent');
	}

	// Appeler pour subscribe sur une action de type desactivation ou validation d'un event
	private getEventUserOrPersonne(): void {
		if ((!this.eventDashboard || !this.eventRappel) && this.routeEventPersonne) {
			this.evenementService.getListeEvenementsPersonneLienApiPlateforme(this.uuid, this.filter)
				.subscribe((events: IHydraCollection<IEvenement>) => {
					this.getEventListener(events, true);
				});
		} else {
			this.evenementService.getListeEvenementsByUserApiPlateforme(this.uuid, this.filter)
				.subscribe((events: IHydraCollection<IEvenement>) => {
					this.getEventListener(events, true);
				});
		}
		this.getNbEvents();
		this.showNotification('success', 'L\'événement vient d\'être validé !');
	}

	public showNotification(type: string, message: string): void {
		this.notifier.notify(type, message);
	}

	public openModal(id: string, uuidEvent, index): void {
		this.index = index;
		this.uuidEvent = uuidEvent;
		this.modalService.open(id);
	}

	public closeModal(idModal): void {
		this.closed.emit(true);
		this.modalService.close(idModal);
	}

	public getFormatFullDate(dateEvent: string): string {
		return moment(dateEvent).format('YYYYMMDD');
	}

	public formatDateEvent(evenement: any): IEvenement {
		evenement.dateEcheance = this.formatDateTime(evenement.dateEcheance);
		evenement.dateCreation = this.formatDate(evenement.dateCreation);
		evenement.dateModification = this.formatDate(evenement.dateModification);
		evenement.moisEcheance = this.formatMonth(evenement.dateEcheance);

		const today = moment();
		const eventDate = moment(evenement.dateEcheance);

		if (eventDate.isSame(today, 'day')) {
			this.setEventProperties(evenement, true, false, false, EvenementGlobals.EVENT_NOW);
		} else if (eventDate.isBefore(today)) {
			const delay = this.getDelay(eventDate);
			this.setEventProperties(evenement, false, true, false, EvenementGlobals.EVENT_EN_RETARD, delay);
		} else {
			this.setEventProperties(evenement, false, false, true, EvenementGlobals.EVENT_PLANIFIE);
		}

		if (evenement.statut === 2) {
			evenement.etat = EvenementGlobals.EVENT_FAIT;
		}

		return evenement;
	}

	private formatDateTime(date: any): Moment {
		return moment(date, 'YYYY-MM-DD HH:mm');
	}

	private formatDate(date: any): Moment {
		return moment(date, 'YYYY-MM-DD');
	}

	private formatMonth(date: any): string {
		return moment(date).format('MMMM');
	}

	private getDelay(eventDate: Moment): string {
		let delay = moment().diff(eventDate, 'seconds');
		let delayUnit = 'secondes';

		if (delay > 60) {
			delayUnit = 'minutes';
			delay = moment().diff(eventDate, 'minutes');
		}

		if (delay > 60) {
			delayUnit = 'heures';
			delay = moment().diff(eventDate, 'hours');
		}

		if (delay > 24) {
			delayUnit = 'jours';
			delay = moment().diff(eventDate, 'days');
		}

		return delay + ' ' + delayUnit;
	}

	private setEventProperties(
		evenement: any,
		aujourdHui: boolean,
		enRetard: boolean,
		planifie: boolean,
		etat: string,
		delay?: string
	): void {
		evenement.aujourdhui = aujourdHui;
		evenement.enRetard = enRetard;
		evenement.planifie = planifie;
		evenement.etat = etat;

		if (delay) {
			evenement.dateEcheanceCalc = delay;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			!changes.uuid.isFirstChange()
			&& changes.uuid.currentValue !== changes.uuid.previousValue
		) {
			this.fetchEvenement(true);
		}
	}
}
