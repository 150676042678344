<app-fil-ariane></app-fil-ariane>
<div class="body-container">
    <notifier-container></notifier-container>
    <div class="main">
        <form name="form" [formGroup]="form">
            <div class="bandeau_tag icon icon_catalog_produits">
                <ng-container *ngIf="!uuid; else realTitle;">
                    <h1>Nouveau produit / service</h1>
                </ng-container>
                <ng-template #realTitle>
                    <h1>{{ this.form.get('libelle').value }}</h1>
                </ng-template>
            </div>
            <div class="formulaire">
                <div class="container_form form_gauche">
                    <div class="bloc_tag">
                        <div class="bandeau_form">Informations générales</div>
                        <div class="bloc_container">
                            <div class="input_container full_width">
                                <div class="input_lg">
                                    <label for="libelle">Intitulé *</label>
                                    <input type="text"
                                           id="libelle"
                                           name="libelle"
                                           formControlName="libelle"
                                           maxlength="45"
                                           [ngClass]="{'background_alert_required': !form.controls.libelle.value}"
                                    />
                                </div>
                                <div class="alert" *ngIf="this.form.get('libelle').errors?.maxlength">
                                    Le nombre de caractères maximum est de 45
                                </div>
                            </div>
                            <div class="input_container full_width">
                                <label>Facturation *</label>
                                <div class="inline_radio">
                                    <input type="radio"
                                           id="facturation_forfait"
                                           name="typeFacturation"
                                           formControlName="typeFacturation"
                                           value="0"
                                           [ngClass]="{'background_alert_required': !form.controls.typeFacturation.value}"
                                    />
                                    <label for="facturation_forfait">Forfait</label>
                                    <input type="radio"
                                           id="facturation_au_temps"
                                           name="typeFacturation"
                                           formControlName="typeFacturation"
                                            value="1"
                                           [ngClass]="{'background_alert_required': !form.controls.typeFacturation.value}"
                                    />
                                    <label for="facturation_au_temps">Au temps passé</label>
                                    <input type="radio"
                                           id="abonnement"
                                           name="typeFacturation"
                                           formControlName="typeFacturation"
                                           value="2"
                                           [ngClass]="{'background_alert_required': !form.controls.typeFacturation.value}"
                                    />
                                    <label for="abonnement">Abonnement</label>
                                </div>
                                <div class="input_lg">
                                    <label for="prix" *ngIf="this.form.get('typeFacturation').value === '0'">Prix
                                        unitaire
                                        HT *</label>
                                    <label for="prix" *ngIf="this.form.get('typeFacturation').value === '1'">Prix
                                        horaire
                                        HT *</label>
                                    <label for="prix" *ngIf="this.form.get('typeFacturation').value === '2'">Prix
                                        unitaire
                                        HT / mois *</label>
                                    <div class="icon-money">
                                        <input type="number"
                                               id="prix"
                                               name="prix"
                                               step="0.01"
                                               formControlName="prixUnitaire"
                                               [ngClass]="{'background_alert_required': !form.controls.prixUnitaire.value}"
                                        />
                                    </div>
                                </div>
                                <div class="input_lg">
                                    <label for="tva">Taux de TVA (<span class="bold">en %</span>) *</label>
                                    <div class="icon-percent">
                                        <app-entity-select
                                                id="tva"
                                                [control]="form.get('tva')"
                                                [fetcher]="fetcher"
                                                [label]="'value'"
                                        ></app-entity-select>
                                    </div>
                                    <div class="alert"
                                         *ngIf="this.form.get('tva').errors?.min || this.form.get('tva').errors?.max">
                                        la TVA est un pourcentage compris entre 0 et 100% (valeur incorrecte)
                                    </div>
                                </div>
                                <div class="input_lg" [formGroup]="formCustomTaux">
                                    <label for="customTaux"
                                           class="smallLabel"
                                           (click)="openCustomTaux()"
                                    >
                                        OU <u>définir votre taux de TVA personnalisé</u>
                                    </label>
                                    <div [hidden]="this.hidden">
                                        <div id="customTaux">
                                            <input
                                                    formControlName="taux"
                                                    type="number"
                                                    step="0.01"
                                                    min="0" max="100"
                                                    [ngClass]="{'background_alert_required': !formCustomTaux.controls.taux.value}"
                                            >
                                            <button type="button" class="btn btn-med" (click)="add()">Ajouter</button>
                                            <div class="alert"
                                                 *ngIf="this.formCustomTaux.get('taux').errors?.min || this.formCustomTaux.get('taux').errors?.max">
                                                la TVA est un pourcentage compris entre 0 et 100% (valeur incorrecte)
                                            </div>
                                            <div class="alert"
                                                 *ngIf="this.error">
                                                <div *ngIf="this.error.status === 422">Ce taux de TVA est déjà présent
                                                    dans votre liste
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container_form form_droit" *ngIf="uuid">
                    <ng-container>
                        <div class="bandeau_form">
                            Visibilité
                            <div class="input_visibilite">
                                <app-switch
                                        (switch)="switchActive($event)"
                                        [switchValue]="this.form.get('active').value"
                                >
                                </app-switch>
                            </div>
                        </div>
                    </ng-container>
                    <div class="bloc_products">
                        <div class="bandeau_form">Utlisé dans ...</div>
                        <div class="offers_stats">
                            <div class="data_use">
                                <span>Offres</span>
                                <span>{{ stats.offer }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="form_bas">
            <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    [ngClass]="{'btn_disabled': this.form.invalid}"
                    [disabled]="this.form.invalid"
                    (click)="onSubmitCancel()">
                <span>Enregistrer et fermer</span>
            </button>
        </div>
    </div>
</div>

