import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {CoordonneeGlobals} from '../../const-global/globals';

interface emailStatut {
    uuid: string;
    previousValue: string;
}

@Injectable({
    providedIn: 'root'
})
export class CoordonneService {

    public personUpdatedSubject = new Subject<any>();
    public coordonneeSubject = new Subject<any>();
    public optOutCoordSubject = new BehaviorSubject<emailStatut[]>([]);
    public hasPrincipalList: any;

    // global types mapping
    private typeMapping = new Map([
        [CoordonneeGlobals.COORD_TYPE_EMAIL.text, 'emails'],
        [CoordonneeGlobals.COORD_TYPE_TEL.text, 'telephones'],
        [CoordonneeGlobals.COORD_TYPE_SITE.text, 'sites'],
        [CoordonneeGlobals.COORD_TYPE_FAX.text, 'faxes']
    ]);

    // global types mapping
    private typeMappingById = new Map([
        [CoordonneeGlobals.COORD_TYPE_EMAIL.id, 'emails'],
        [CoordonneeGlobals.COORD_TYPE_TEL.id, 'telephones'],
        [CoordonneeGlobals.COORD_TYPE_SITE.id, 'sites'],
        [CoordonneeGlobals.COORD_TYPE_FAX.id, 'faxes']
    ]);

    // coord mapping
    private typesCoordoonees = [
        {
            id: CoordonneeGlobals.COORD_TYPE_EMAIL.id,
            text: 'E-Mail',
            value: CoordonneeGlobals.COORD_TYPE_EMAIL.text,
            sous_type_defaut: CoordonneeGlobals.COORD_SOUS_TYPE_EMAIL.id
        },
        {
            id: CoordonneeGlobals.COORD_TYPE_SITE.id,
            text: 'Site',
            value: CoordonneeGlobals.COORD_TYPE_SITE.text,
            sous_type_defaut: CoordonneeGlobals.COORD_SOUS_TYPE_SITE.id
        },
        {
            id: CoordonneeGlobals.COORD_TYPE_TEL.id,
            text: 'Téléphone',
            value: CoordonneeGlobals.COORD_TYPE_TEL.text,
            sous_type_defaut: CoordonneeGlobals.COORD_SOUS_TYPE_TEL.fixe.id
        },
        {
            id: CoordonneeGlobals.COORD_TYPE_FAX.id,
            text: 'Fax',
            value: CoordonneeGlobals.COORD_TYPE_FAX.text,
            sous_type_defaut: CoordonneeGlobals.COORD_SOUS_TYPE_FAX.id
        }
    ];

    /**
     * retourne le type réel d'une coordonnée donnée
     */
    getRealType(type: string): string {
        const realType = this.typeMapping.get(type);
        if (realType) {
            return realType;
        }
        return '';
    }

    /**
     * retourne le type réel d'une coordonnée donnée
     */
    getRealTypeById(typeId: number): string {
        const realType = this.typeMappingById.get(typeId);
        if (realType) {
            return realType;
        }
        return '';
    }

    /**
     * retour la liste des types de coordonnées
     */
    getCoordInfos(): Array<any> {
        return this.typesCoordoonees;
    }

    /**
     * add a new element to the optout Subject
     * @param email
     */
    addOptOutElement(email: emailStatut) {
        let values = this.optOutCoordSubject.getValue();
        if((values.filter(mail => mail.uuid === email.uuid)).length === 0){
            values.push(email);
            this.optOutCoordSubject.next(values);
        }
    }

    /**
     * remove given element from the optout Subject
     * @param uuid
     */
    removeOptOutElement(uuid: string) {
        let values = this.optOutCoordSubject.getValue();
        values = values.filter(mail => mail.uuid !== uuid);
        this.optOutCoordSubject.next(values);
    }

    /**
     * reinit optout Subject
     */
    resetOptOutCoord() {
        this.optOutCoordSubject.next([]);
    }
}
