<div class="product champ" *ngIf="product" (click)="openCard(product)">
    <div class="champs_content">
        <div class="icon edition carte_titre bandeau_parametre">
            <h2>{{ product.libelle }}</h2>
        </div>
        <div class="carte_texte">
            <p class="texte_systeme">{{product.typeFacturationLibelle}}</p>
            <p class="texte_target">
                {{prixTypeLibelle}} :
                {{product?.prixUnitaire |number: '1.2-2':'FR-fr'}} €</p>
        </div>
    </div>
</div>
