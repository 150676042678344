<div class="main">
    <div class="bloc1">
        <button type="button" action class="btn btn-ajouter primary btn-med" [routerLink]="['creer']">
            Ajouter un segment
        </button>
        <app-debouce-input search (modelChange)="onRecherche($event)" [model]="filter.search"></app-debouce-input>
    </div>
    <div class="content_cartes cartes_segments"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scroll)="onScroll()">
        <div *ngIf="segments === undefined || segments.length === 0" class="message_information">Aucun résultat
            trouvé
        </div>
        <ul class="cartes">
            <li class="cartes_item" *ngFor="let segment of segments">
                <app-segment-carte [segment]="segment"></app-segment-carte>
            </li>
        </ul>
    </div>
</div>
