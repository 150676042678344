import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {shareReplay} from 'rxjs/operators';

const API_URL = environment.apiURL;

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    constructor(
        private http: HttpClient,
    ) {
    }

    getIntervenants(uuid: string, type: string): Observable<any> {
        const intervenants$ = this.http.get(API_URL + 'intervenants/' + uuid + '/' + type).pipe(
            shareReplay()
        );
        return intervenants$;
    }

    getDictionnaire(type: string): Observable<any> {
        const dictionnaire$ = this.http.get(API_URL + 'dictionnaire/' + type).pipe(
            shareReplay()
        );
        return dictionnaire$;
    }

    importFile(form: FormData): Observable<any> {
        return this.http.post(API_URL + 'import',
            form
        );
    }
}
