import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {IOrigine} from '../../interfaces/origine';
import {ITag} from '../../interfaces/tag';

const API_URL = environment.apiURL;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};

@Injectable({
    providedIn: 'root'
})
export class OrigineService {
    origineSubject = new Subject<ITag>();

    constructor(private http: HttpClient) {
    }

    getOrigineListe(recherche = ''): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('recherche', recherche)
        };
        return this.http.get<IOrigine[]>(API_URL + 'parametre/origines', options);
    }

    getOrigine(uuid): Observable<any> {
        return this.http.get(API_URL + 'parametre/origine/' + uuid);
    }

    updateOrigine(uuid: string, form: IOrigine): Observable<any> {
        return this.http.put(API_URL + 'parametre/origine/' + uuid, {
            origine: form,
        }, httpOptions);
    }

    creerOrigine(form: IOrigine): Observable<any> {
        return this.http.post(API_URL + 'parametre/origine', {
            origine: form,
        }, httpOptions);
    }

    searchOrigine(offset: string, limit: string, recherche: string): Observable<any> {
        const options = offset && limit
            ?
            {
                params: new HttpParams()
                    .set('recherche', recherche)
            }
            :
            {};
        return this.http.get(API_URL + 'parametre/origines', options);
    }

    countOrigineUsage(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'parametre/origine/' + uuid + '/count');
    }
}
