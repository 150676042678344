<app-fil-ariane></app-fil-ariane>
<div class="bloc_rappel">
    <div class="onglet evenement">
        <div *ngIf="nbEvent"
             class="onglet_fiche icon calendrier"
             (click)="openOnglet(RAPPEL_GLOBAL.RAPPEL_TYPE_TACHE_LIBELLE)"
             [ngClass]="{'onglet_evenement onglet_bg_white' : tabName === RAPPEL_GLOBAL.RAPPEL_TYPE_TACHE_LIBELLE}"
        >
            <div class="container_count_event"
                 *ngIf="nbEvent.nbEventEnRetard > 0 || nbEvent.nbEventNow > 0; else pasUrgent">{{libelleTache}}
                (
                <div [ngClass]="{'nb_event_dashboard_important' : nbEvent.nbEventEnRetard > 0 || nbEvent.nbEventNow > 0}">
                    {{ nbEvent.nbEventEnRetard + nbEvent.nbEventNow }}&nbsp;
                </div>
                / {{ nbEvent.nbEventRappel }})
            </div>
            <ng-template #pasUrgent>
                <div class="container_count_event"
                     *ngIf="nbEvent.nbEventEnRetard === 0 || nbEvent.nbEventNow === 0">{{libelleTache}}
                    ({{ nbEvent.nbEventRappel }})
                </div>
            </ng-template>
        </div>
        <div class="onglet_fiche icon addresse_card"
             (click)="openOnglet(RAPPEL_GLOBAL.RAPPEL_TYPE_FICHE_LIBELLE)"
             [ngClass]="{'onglet_evenement onglet_bg_white' : tabName === RAPPEL_GLOBAL.RAPPEL_TYPE_FICHE_LIBELLE}"
        >
            Fiche(s) à optimiser ({{ nbFicheIncomplete }})
        </div>
    </div>
    <app-event-rappel
            [tabName]="tabName">
    </app-event-rappel>
    <app-fiche-incomplete-rappel
            [tabName]="tabName">
    </app-fiche-incomplete-rappel>
</div>
