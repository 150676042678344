import {Component, Input, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {AbstractControl, FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, finalize, tap} from "rxjs/operators";
import {AddTagFn} from "@ng-select/ng-select/lib/ng-select.component";

export interface Fetcher<T> {
    (filter?: object): Observable<T>;
}

@Component({
    selector: 'app-entity-select',
    templateUrl: './entity-select.component.html',
})
export class EntitySelectComponent implements OnDestroy {
    @Input() label: string = 'libelle';
    @Input() control: FormControl | AbstractControl = new FormControl();
    @Input() multiple: boolean = false;
    @Input() readOnly: boolean = false;
    @Input() fetcher: Fetcher<any>;
    @Input() addTag: AddTagFn | false = false;
    @Input() pageParam: string;
    @Input() placeHolder: string;
    @Input() clearable = true;
    @Input() set searchParm(searchParms: string) {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe()
        }
        this.searchSubscription = this.input$.pipe(
            tap(() => this.loading = true),
            debounceTime(400),
            distinctUntilChanged(),
        ).subscribe((term) => {
            this.initFilter()
            this.filter[searchParms] = term;
            this.items = []
            this.fetch()
        })
    }

    searchSubscription: Subscription
    items = [];
    hasMore = true
    loading = false;
    input$ = new Subject<string>();
    filter: any = {}
    httpSubscription: Subscription = new Subscription();

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe()
        }
    }

    onOpen() {
        if (0 === this.items.length) {
            this.initFilter();
            this.fetch();
        }
    }

    scroll($event) {
        if (!this.pageParam) {
            return;
        }
        if (this.loading
            || !this.hasMore
            || $event.end < (this.items.length - 10)
        ) {
            return;
        }

        this.filter[this.pageParam]++;
        this.fetch()
    }

    private fetch() {
        this.loading = true
        if (this.httpSubscription) {
            this.httpSubscription.unsubscribe();
        }
        this.fetcher(this.filter)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(items => {
                this.items = this.items.concat(items['hydra:member'] || items);
                this.hasMore = !!items['hydra:view'] && !!items['hydra:view']['hydra:next']
            })
    }

    private initFilter() {
        this.hasMore = true
        this.filter = {}
        if (this.pageParam) {
            this.filter[this.pageParam] = 1;
        }
    }
}
