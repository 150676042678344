import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {IOnglet} from '../../../../interfaces/onglet';
import {PersonGlobals} from '../../../../const-global/globals';
import {PersonneService} from '../../../_services/personne.service';
import {EvenementService} from '../../../_services/evenement.service';

@Component({
    selector: 'app-onglet',
    templateUrl: './onglet.component.html',
    styleUrls: ['./onglet.component.scss']
})
export class OngletComponent implements OnInit, AfterViewChecked {
    public readonly PERSON_GLOBAL = PersonGlobals;
    public libelles: Array<any>;
    private alreadyFocused = false;

    @Input() data: IOnglet[];
    @Input() active: string;
    @Output() ongletEvent = new EventEmitter<string>();

    constructor(
        private location: Location,
        public router: Router,
        private evenementService: EvenementService,
        private personneService: PersonneService,
    ) {
    }

    ngOnInit(): void {
        this.libelles = [];
        if (this.data) {
            this.data.map((item: IOnglet) => {
                let donnee: string;
                if (item.type === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
                    donnee = item.prenom + ' ' + item.nom;
                } else {
                    donnee = item.fonction + ' (' + item.raisonSociale + ')';
                }
                this.libelles.push({
                    uuid: item.uuid,
                    libelle: donnee
                });
            });
        }
    }

    ngAfterViewChecked(): void {
        const el = document.getElementById('ongletActive');
        if (el && this.alreadyFocused === false) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
            this.alreadyFocused = true;
        }
    }

    public changeOnglet(event = null, uuid: string): void {
        this.personneService.refreshPersonne(uuid);

        if (event) {
            event.target.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
        this.ongletEvent.emit(uuid);
        this.active = uuid;
        this.router.navigate(['/repertoire/', uuid]);
    }

    public scrollRight(el: HTMLElement): void {
        el.scrollTo({left: (el.scrollLeft + 150), behavior: 'smooth'});
    }

    public scrollLeft(el: HTMLElement): void {
        el.scrollTo({left: (el.scrollLeft - 150), behavior: 'smooth'});
    }

    addFonction(uuid: string): void {
        let donnee = '';
        this.data.map((item) => {
            if (item.uuid === uuid) {
                donnee = item.prenom + ' ' + item.nom;
            }

        });
        window.sessionStorage.setItem(uuid, donnee);
        this.router.navigateByUrl('/repertoire/' + uuid + '/creer/fonction');
    }
}
