import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Select2OptionData} from 'ng-select2';
import {Options} from 'select2';
import {FormGroup} from '@angular/forms';
import {v4 as uuidv4} from 'uuid';

@Component({
    template: ''
})
// TODO : remove après avoir tout basuculé sur ng-select
export class BaseSelect2Component implements OnInit {

    constructor() {
    }
    private uuidSelect2: string = 'select2-' + uuidv4();

    public data: Observable<Array<Select2OptionData>> = new Observable(observer => {
        observer.next([]);
        observer.complete();
    });
    public options: Options;

    @Input() form: FormGroup;
    @Input() id: string;
    @Input() value: any;
    @Input() multiple: boolean;
    @Input() disabled: boolean;
    @Output() changed = new EventEmitter<any>();
    @ViewChild('inputSelect2') inputSelect2: ElementRef;

    protected valeurRechercheSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public focusSelect2(): void {
        // permet d'avoir le focus à l'ouverture d'un select2
        const span = document.getElementsByClassName('select2-search select2-search--dropdown')[0];
        if (span) {
            // permet de remettre le focus pour voir qu'on peut écrire sur le champ
            const input = span.children[0];
            input.id = this.uuidSelect2;
            document.getElementById(input.id).focus();
        }
    }

    ngOnInit(): any {
        this.options = {
            multiple: this.multiple
        };
        this.initSelect();
    }

    initSelect(): void {
        alert('You should not be using this base component as it');
    }

    public valueChanged($event: any): void {
        this.changed.emit($event);
    }
}
