import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PersonneLienService} from '../../_services/personne-lien.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IPersonnelien} from '../../../interfaces/personne-lien';
import IHydraCollection from '../../../interfaces/hydra-collection';
import {combineLatest} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {EntityTabsService} from '../../_services/entity-tabs.service';
import {EntityType} from '../../../models/lastentity';

@Component({
    selector: 'app-merge',
    templateUrl: './merge.component.html',
    styleUrls: ['./merge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeComponent implements OnInit {

    loaderId = 'merge-loader';
    isPreview = false;
    page = 1;
    itemsPerPage = 4;
    notIn = [];
    hydra: IHydraCollection<IPersonnelien> = {'hydra:member': [], 'hydra:totalItems': 0};
    personneLiens: IPersonnelien[] = [];
    fichePrincipale: IPersonnelien;
    resultMerge: IPersonnelien;
    mergeOptions = {};
    availableMergeOptions = ['Fusionner', 'N\'est pas un doublon', 'Désactiver', 'Ignorer'];
    min = Math.min;
    simpleFields = [];
    customFields = [];
    advancedFields = [];
    oldStatut = null;
    isStatutLocked = false;
    isUser = false;
    private isSaving: boolean;
    navigationTarget

    get all(): IPersonnelien[] {
        return [(this.isPreview ? this.resultMerge : this.fichePrincipale), ...this.personneLiens];
    }

    constructor(
        private pls: PersonneLienService,
        private route: ActivatedRoute,
        private router: Router,
        private loader: NgxUiLoaderService,
        private ref: ChangeDetectorRef,
        private entityTabsService: EntityTabsService,
    ) {
    }

    ngOnInit(): void {
        this.navigationTarget = {...this.navigationTarget, uuid: this.route.snapshot.params.uuid}
        if (this.route.parent.snapshot.params.level) {
            let find = ['very_weak',
                'weak',
                'strong',
                'very_strong'].find(v => this.route.parent.snapshot.params.level.includes(v))
            if (undefined !== find) {
                this.navigationTarget = {...this.navigationTarget, ...this.route.parent.snapshot.params}
            }
        }
        combineLatest([this.route.params, this.route.queryParams])
            .subscribe(([params, queryParams]) => {
                this.loader.startLoader(this.loaderId);
                combineLatest([
                    this.pls.detail(params.uuid, {params: {'groups[]': ['&PersonsSimilar']}}),
                    this.pls.list(Object.assign({
                            page: this.page,
                            itemsPerPage: this.itemsPerPage,
                            'personsSimilar.similaritiesCode': params.similaritiesCode,
                            'personsSimilar.to.uuid': params.uuid,
                            groups: '&PersonsSimilar',
                            partialPagination: false,
                            'not:uuid[]': this.notIn,
                            active: true,
                            writable: true,
                        }, queryParams)
                    )
                ])
                    .pipe(finalize(() => {
                        this.loader.stopLoader(this.loaderId);
                    }))
                    .subscribe(([fichePrincipale, personneLiens]) => {
                        if (fichePrincipale.personnePhysique?.isUser) {
                            this.isUser = true;
                        } else {
                            personneLiens['hydra:member'].forEach((pl, index) => {
                                if (pl.personnePhysique?.isUser) {
                                    personneLiens['hydra:member'].splice(index, 1);
                                    personneLiens['hydra:member'].push(fichePrincipale);
                                    fichePrincipale = pl;
                                    this.isUser = true;
                                }
                            });
                        }
                        this.entityTabsService.upsert({
                            uuid: fichePrincipale.uuid,
                            type: 'double_' + fichePrincipale.type as EntityType,
                            label: fichePrincipale.personnePhysique?.libelle || fichePrincipale.personneMorale?.libelle,
                            uri: this.router.url,
                        });
                        if (0 === personneLiens['hydra:totalItems']) {
                            this.entityTabsService.remove(this.fichePrincipale.uuid);
                            this.goPage()
                        }
                        this.fichePrincipale = fichePrincipale;
                        this.personneLiens = personneLiens['hydra:member'];
                        this.resultMerge = JSON.parse(JSON.stringify(fichePrincipale));
                        this.hydra = personneLiens;
                        this.mergeOptions = {};
                        ({
                            simpleFields: this.simpleFields,
                            advancedFields: this.advancedFields,
                            customFields: this.customFields
                        } = this.getConfig());
                        this.calculateFields();
                        this.isPreview = false;
                    });
            });
    }

    getConfig() {
        const simpleFields = [
            // Personne Morale
            {label: 'Raison sociale', path: 'personneMorale.raisonSociale', items: []},
            {label: 'Siret', path: 'personneMorale.siret', items: []},
            {label: 'No TVA', path: 'personneMorale.tvaCom', items: []},
            {label: 'Forme juridique', accessor: (i) => i?.libelle, path: 'personneMorale.formeJuridique', items: []},
            {label: 'Effectif', accessor: (i) => i?.libelle, path: 'personneMorale.effectif', items: []},
            {
                label: 'Chiffre D\'affaire',
                accessor: (i) => i?.libelle,
                path: 'personneMorale.chiffreAffaire',
                items: []
            },
            {label: 'Capital', path: 'personneMorale.capital', items: []},
            {label: 'Code NACE', path: 'personneMorale.codeNace', items: []},
            {label: 'Code NAF', path: 'personneMorale.codeNaf', items: []},
            // Fin Personne Morale
            {label: 'Civilité', path: 'personnePhysique.civilite', items: []},
            {label: 'Nom', path: 'personnePhysique.nom', items: []},
            {label: 'Prénom', path: 'personnePhysique.prenom', items: []},
            {label: 'Titre', accessor: (i) => i?.libelle, path: 'personnePhysique.titre', items: []},
            {label: 'Statut', accessor: (i) => i?.texte, path: 'statut', items: []},
            {label: 'Qualité relation', path: 'qualite', items: []},
            {label: 'Accepte les infos commerciales ?', path: 'personnePhysique.infoCommerciale', items: []},
            {label: 'Origine du contact', accessor: (i) => i?.libelle, path: 'personnePhysique.origine', items: []},
            {label: 'Contact apporté par', accessor: (i) => i?.libelle, path: 'apporteur', items: []},
            {label: 'Visibilité', path: 'visibilite', items: []},
            {
                label: 'Référent',
                accessor: (i) => i?.user?.personnePhysique?.libelle,
                path: 'intervenantReferent',
                items: []
            },
            {
                label: 'Société mère',
                accessor: (i) => i?.libelle,
                path: 'personneMorale.parent',
                items: []
            },
        ];

        const advancedFields = [
            {
                label: 'Intervenant(s)',
                accessor: (i) => i?.user?.personnePhysique.libelle,
                path: 'intervenantsSimple',
                items: []
            },
            {
                label: 'Contacts apportés',
                accessor: (i) => i.libelle,
                path: 'apportes',
                items: []
            },
            {label: 'Mots-clés', accessor: (i) => i?.libelle, path: 'tags', items: []},
            {label: 'Mémo(s)', accessor: (i) => i?.texte, path: 'memos', items: []},
            {label: 'Tél(s)', accessor: (i) => i?.telephone?.text, path: 'telephones', items: []},
            {label: 'Site(s)', accessor: (i) => i?.site?.valeur, path: 'sites', items: []},
            {label: 'E-mail(s)', accessor: (i) => i?.mail?.valeur, path: 'mails', items: []},
            {
                label: 'Adresse(s)', accessor: (i) => {
                    const a = i?.adresse;
                    const lines = [];
                    a && lines.push(
                        a.ligne1,
                        a.ligne2,
                        a.ligne3,
                        `${a.cp || ''} ${a.ville || ''}`,
                        `${a.cedexCode || ''} ${a.cedexCode || ''}`,
                        a.pays
                    );
                    return lines.filter(l => l && !!l.trim()).join('\n');
                }, path: 'adresses', items: []
            },
            {
                label: 'Fonctions(s)',
                accessor: (i) => {
                    let wording = i.fonction?.libelle;
                    if (i.fonctionPersonnalisee) {
                        if (wording) {
                            wording += ' - ';
                        }
                        wording += i.fonctionPersonnalisee;
                    }
                    if (wording) {
                        wording += ', ';
                    }
                    return wording + i.personneMorale?.libelle;
                },
                path: 'personnePhysique.liens',
                items: []
            },
            {
                label: 'Collaborateur(s)',
                accessor: (i) => {
                    let wording = '';
                    if (!!i.fonction?.libelle) {
                        wording += i.fonction?.libelle;
                    }
                    if (i.fonctionPersonnalisee) {
                        if (wording) {
                            wording += ' - ';
                        }
                        wording += i.fonctionPersonnalisee;
                    }
                    if (wording) {
                        wording += ', ';
                    }
                    return wording + i.personnePhysique?.libelle;
                },
                path: 'personneMorale.liens',
                items: []
            },
            {
                label: 'Filiale(s)',
                accessor: (i) => i.libelle,
                path: 'personneMorale.enfants',
                items: []
            },
        ];
        const customFields = [];
        this.all.forEach((personneLien: IPersonnelien) => {
            Object.entries(personneLien.champs).forEach(([k, lienChamp]) => {
                if (!customFields.find(v => `champs.${k}` === v.path)) {
                    customFields.push({
                        label: `${lienChamp.champ.libelle}`,
                        accessor: (i) => {
                            return Array.isArray(i?.value) ? i.value.map(v => v.text).join('\n') : (i?.value?.text || i?.value);
                        },
                        path: `champs.${k}`,
                        items: [],
                    });
                }
            });
        });

        return {simpleFields, advancedFields, customFields};
    }

    calculateFields() {
        this.simpleFields.forEach(field => {
            const i = field.path.lastIndexOf('.');
            const basePath = field.path.substring(0, i);
            field.key = field.path.substring(i + 1);
            field.model = '' === basePath ? this.resultMerge : basePath.split('.').reduce((o, i) => o[i] ? o[i] : {}, this.resultMerge);
            field.accessor = field.accessor || ((i) => i);
            field.value = field.accessor(field.model[field.key]);
            field.items = [];
        });

        this.advancedFields.forEach(field => {
            const i = field.path.lastIndexOf('.');
            const basePath = field.path.substring(0, i);
            field.key = field.path.substring(i + 1);
            field.model = '' === basePath ? this.resultMerge : basePath.split('.').reduce((o, i) => o[i] ? o[i] : {}, this.resultMerge);
            field.items = [];
        });

        this.customFields.forEach(field => {
            const i = field.path.lastIndexOf('.');
            const basePath = field.path.substring(0, i);
            field.key = field.path.substring(i + 1);
            field.model = '' === basePath ? this.resultMerge : basePath.split('.').reduce((o, i) => o[i] ? o[i] : {}, this.resultMerge);
            field.accessor = field.accessor || ((i) => i);
            field.value = field.accessor(field.model[field.key]);
            field.items = [];
        });

        this.all.forEach((personneLien: IPersonnelien) => {
            this.simpleFields.forEach(field => {
                const object = field.path.split('.').reduce((o, i) => o ? o[i] : undefined, personneLien);
                field.items.push({
                    id: field.path + personneLien.uuid,
                    object,
                    value: field.accessor(object),
                });
            });
            this.advancedFields.forEach(field => {
                let values = field.path.split('.').reduce((o, i) => o ? o[i] : undefined, personneLien);
                if (['personnePhysique.liens', 'personneMorale.liens', 'apporteur', 'apportes'].includes(field.path)) {
                    values = values?.filter(v => v['@id'] !== personneLien['@id']);
                    values = values?.filter(v => !v.anonymised && v.active);
                }
                field.items.push({
                    id: field.path + personneLien.uuid,
                    values,
                });
            });
            this.customFields.forEach(field => {
                const object = field.path.split('.').reduce((o, i) => o ? o[i] : undefined, personneLien);
                field.items.push({
                    id: field.path + personneLien.uuid,
                    object,
                    value: field.accessor(object),
                });
            });
        });

        this.simpleFields = this.simpleFields.filter(field => {
            const fieldsUniq = new Set(field.items.map((v) => {
                return v.value;
            }));

            field.disabled = fieldsUniq.size === 1;
            if ('statut' === field.key) {
                field.disabled = this.isStatutLocked;
            }
            return !(field.disabled && ![...fieldsUniq][0]);
        });

        this.advancedFields = this.advancedFields.filter(field => {
            return field.items.some((v) => {
                return !!v.values?.length;
            });
        });

        this.customFields = this.customFields.filter(field => {
            const fieldsUniq = new Set(field.items.map((v) => {
                return v.value;
            }));

            field.disabled = fieldsUniq.size === 1;

            return !(field.disabled && ![...fieldsUniq][0]);
        });
        this.ref.markForCheck();
    }

    dotGetter(obj: object, path: string, def = undefined) {
        return path.split('.').reduce((o, i) => o ? o[i] : def, obj);
    }

    dotSetter(obj: object, path: string, value) {
        const split = path.split('.');
        const len = split.length - 1;
        return split.reduce((o, i, j) => {
            if (j === len) {
                o[i] = value;
            } else {
                if (!o[i]) {
                    o[i] = {};
                }
                return o[i];
            }
        }, obj);
    }

    simpleFieldClick($event, item, field) {
        field.model[field.key] = item.object;
    }

    simpleFieldChecked(item, field) {
        return item.value === field.accessor(field.model[field.key]);
    }

    isChecked(i, field) {
        const v = field.accessor(i);
        return !!field.model[field.key].some(t => field.accessor(t) === v);
    }

    isCheckboxDisabled(i, field) {
        const model = this.dotGetter(this.fichePrincipale, field.path);
        const v = field.accessor(i);
        return !!model.some(t => field.accessor(t) === v);
    }

    checkboxChange($event, item, field) {
        if ($event.target.checked) {
            field.model[field.key].push(item);
        } else {
            const v = field.accessor(item);
            field.model[field.key] = field.model[field.key].filter(t => field.accessor(t) !== v);
        }
    }

    save() {
        if (this.isSaving) {
            return;
        } else {
            this.isSaving = true;
        }

        this.loader.startLoader(this.loaderId);
        const body = JSON.parse(JSON.stringify(this.resultMerge));
        body.champs = Object.values(body.champs).filter(v => v);
        const {simpleFields, advancedFields} = this.getConfig();
        const objectPaths = simpleFields.filter(field => 'accessor' in field).map(field => field.path);
        objectPaths.map(path => {
            const v = this.dotGetter(body, path + '.@id', null);
            if (v) {
                this.dotSetter(
                    body,
                    path,
                    v
                );
            }
        });

        advancedFields.forEach(field => {
            let values = this.dotGetter(body, field.path, null);
            if (['personnePhysique.liens', 'personneMorale.liens', 'apporteur', 'apportes'].includes(field.path)) {
                values = values?.filter(v => !v.anonymised);
            }
            if (values) {
                this.dotSetter(
                    body,
                    field.path,
                    values.map(v => v['@id'])
                );
            }
        });

        this.dotSetter(
            body,
            'champs',
            this.dotGetter(body, 'champs', null)?.map(v => v['@id'])
        );

        this.notIn.push(
            ...Object.entries(this.mergeOptions)
                .filter(([k, v]) => {
                    if (0 === v) {
                        if (!body.mergeOf) {
                            body.mergeOf = [];
                        }
                        const pl = this.all[k];
                        body.mergeOf.push(pl['@id']);
                        this.entityTabsService.remove(pl.personnePhysique?.uuid || pl.personneMorale?.uuid,);
                    }
                    if (1 === v) {
                        if (!body.personneSimilarityFalsePositive) {
                            body.personneSimilarityFalsePositive = [];
                        }

                        body.personneSimilarityFalsePositive.push(this.all[k]['@id']);
                    }

                    return 3 === v;
                })
                .map(([k, v]) => {
                    return this.all[k].uuid;
                })
        );

        combineLatest(
            Object.entries(this.mergeOptions)
                .filter(([k, options]) => 2 === options)
                .map(([k, options]) => this.pls.patch(this.all[k].uuid.toString(), {active: false}))
        ).pipe(finalize(() => {

                this.pls.patch(body.uuid.toString(), body)
                    .subscribe(() => {
                        this.ngOnInit();
                    }, () => this.isSaving = false, () => this.isSaving = false);
            })
        ).subscribe();
    }

    togglePreview() {
        this.isPreview = !this.isPreview;
        this.calculateFields();
    }

    setFichePrincipale(personneLien: IPersonnelien) {
        const urlTree = this.router.createUrlTree(['../', personneLien.uuid], {relativeTo: this.route});
        this.entityTabsService.upsert({
                uuid: personneLien.uuid,
                type: 'double_' + personneLien.type as EntityType,
                label: personneLien.personnePhysique?.libelle || personneLien.personneMorale?.libelle,
                uri: urlTree.toString(),
            },
            this.fichePrincipale.uuid
        );

        void this.router.navigateByUrl(urlTree);
    }

    hasContactToBeMerged() {
        for (const k in this.mergeOptions) {
            if (this.mergeOptions[k] === 0) {
                return true;
            }
        }
        return false;
    }

    cancel() {
        this.entityTabsService.remove(this.fichePrincipale.uuid);
        this.goPage()
    }

    changeMergeOptions($event, isUser): void {
        let found = false;
        let membreStatut = {};
        this.all.forEach(pl => {
            if ('membre' === pl.statut.libelle) {
                found = true;
                membreStatut = pl.statut;
            }
        });
        if (found) {
            this.isStatutLocked = false;
            if ('Fusionner' === $event && isUser) {
                this.lockStatutsOnMembre(membreStatut);
            } else if ('Fusionner' !== $event) {
                this.resultMerge.statut = this.oldStatut ? this.oldStatut : this.resultMerge.statut;
                this.simpleFields.forEach(item => {
                    if ('statut' === item.path) {
                        item.disabled = false;
                    }
                });
            } else {
                this.simpleFields.forEach(item => {
                    if ('statut' === item.path) {
                        item.items.forEach(statut => {
                            if (statut.object.libelle === 'membre') {
                                this.lockStatutsOnMembre(statut.object);
                            }
                        });
                    }
                });
            }
        }
    }

    lockStatutsOnMembre(statut): void {
        this.oldStatut = this.resultMerge.statut;
        this.resultMerge.statut = statut;
        this.isStatutLocked = true;
        this.simpleFields.forEach(itemMerge => {
            if ('statut' === itemMerge.path) {
                itemMerge.disabled = true;
            }
        });
    }

    goPage() {
        if (this.navigationTarget.level) {
            void this.router.navigate(['doublons', this.navigationTarget.level]);
        } else if (this.navigationTarget.uuid) {
            void this.router.navigate(['repertoire', this.navigationTarget.uuid])
        } else {
            void this.router.navigate(['doublons', 'all']);
        }
    }
}
