<ng-container *ngIf="!router.url.startsWith('/utilisateurs') && personne && !personne.isComplete"
              xmlns="http://www.w3.org/1999/html">
    <app-fiche-completion-notice [positionTop]="true" [personne]=personne
                                 [typeAlerts]="['champs_manquants']"></app-fiche-completion-notice>
</ng-container>
<div class="form_personne form_modifier" *ngIf="personne">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form"
          [formGroup]="form"
          novalidate
          autocomplete="off"
    >
        <div class="bandeau_personne_formulaire bandeau_personne_physique icon user"
             *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE && router.url.startsWith('/repertoire')">
            <h1>{{ personne.personnePhysiqueLibelle }}</h1>
        </div>
        <div class="bandeau_personne_formulaire bandeau_personne_morale icon building"
             *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE && router.url.startsWith('/repertoire')">
            <h1>{{ personne.personneMoraleLibelle }}</h1>
        </div>
        <div class="bandeau_personne_lien_multi"
             *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN && router.url.startsWith('/repertoire')">
            <div class="bandeau_personne_formulaire bandeau_personne_physique icon user">
                <h1>{{ personne.personnePhysiqueLibelle }}</h1>
            </div>
            <div class="bandeau_personne_formulaire bandeau_personne_morale icon building">
                <div>
                    <h3>{{ personne.fonctionLibelle }}</h3>
                    <h2>{{ personne.personneMoraleLibelle }}</h2>
                </div>
            </div>
        </div>
        <div class="bandeau_personne_formulaire bandeau_mon_compte icon mon_compte"
             *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE && router.url.startsWith('/utilisateurs')">
            <h1>{{ personne.personnePhysiqueLibelle }}</h1>
        </div>
        <div class="formulaire">
            <div class="container_form form_gauche">
                <div class="bloc_identite">
                    <div class="bandeau_form">Identité</div>
                    <div class="bloc_container">
                        <div class="input_container bloc_avatar">
                            <app-avatar [personne]="personne" *ngIf="!newUser" [modeFiche]="true"></app-avatar>
                            <!--                            TODO : temporaire : edition de l'avatar-->
                            <!--                            <div class="container_bouton_edition" *ngIf="personne.type !== 'lien'">-->
                            <!--                                <button class="btn btn-picto-lg btn-editer"></button>-->
                            <!--                                <button class="btn btn-picto-lg btn-supprimer"></button>-->
                            <!--                            </div>-->
                            <div class="carte_avatar" *ngIf="newUser">
                                <ngx-avatar [name]="personne.personnePhysiqueLibelle" [size]="146" initialsSize="2"
                                            textSizeRatio="3" bgColor="#F3F3F3" fgColor="#28B8CE"></ngx-avatar>
                            </div>
                        </div>
                        <div *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE">
                            <div class="input_container identite_personne_physique">
                                <div class="input_lg">
                                    <label for="prenom">Prénom</label>
                                    <input
                                            type="text"
                                            id="prenom"
                                            name="prenom"
                                            formControlName="prenom"
                                            [ngClass]="{'background_alert_required': !form.controls.prenom.value}"
                                    />
                                </div>
                                <div class="container_civilite"
                                     [ngClass]="{'background_alert_required': !form.controls.civilite.value}">
                                    <label>Civilité</label>
                                    <div>
                                        <input type="radio"
                                               id="civilite_mr"
                                               name="civilite"
                                               value="M"
                                               formControlName="civilite"
                                               [ngClass]="{'background_alert_required': !form.controls.civilite.value}"
                                        >
                                        <label for="civilite_mr">M.</label>
                                        <input type="radio"
                                               id="civilite_mme"
                                               name="civilite"
                                               value="Mme"
                                               formControlName="civilite"
                                               [ngClass]="{'background_alert_required': !form.controls.civilite.value}"
                                        >
                                        <label for="civilite_mme">Mme.</label>
                                        <input type="radio"
                                               id="civilite_autre"
                                               name="civilite"
                                               value="Non-précisé"
                                               formControlName="civilite"
                                               [ngClass]="{'background_alert_required': !form.controls.civilite.value}"
                                        >
                                        <label for="civilite_autre">Non-précisé</label>
                                    </div>
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="input_lg">
                                    <label for="nom">Nom</label>
                                    <input type="text"
                                           id="nom"
                                           name="nom"
                                           formControlName="nom"
                                           [ngClass]="{'background_alert_required': !form.controls.nom.value}"
                                    />
                                </div>
                                <div class="ng-autocomplete input_lg" *ngIf="hasTitre">
                                    <label for="titre">Titre</label>
                                    <app-titre-autocomplete
                                            id="titre"
                                            [control]="form.get('personnePhysique.titre')"
                                    ></app-titre-autocomplete>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE">
                            <div class="input_container full_width">
                                <div class="input_lg">
                                    <label>Raison sociale</label>
                                    <input
                                            type="text"
                                            formControlName="raisonSociale"
                                            [ngClass]="{'background_alert_required': !form.controls.raisonSociale.value}"
                                    />
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="select2_sans_badge input_lg">
                                    <label for="formeJuridique">Forme juridique</label>
                                    <app-form-juridique-select2
                                            id="formeJuridique"
                                            [control]="form.get('formeJuridique')"
                                            [ngClass]="{'background_alert_required': !form.controls.formeJuridique.value}"
                                    ></app-form-juridique-select2>
                                </div>
                                <div class="input_lg">
                                    <label for="capital">Capital</label>
                                    <input id="capital" name="capital"
                                           type="number"
                                           pattern="[0-9]*"
                                           formControlName="capital"/>
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="input_lg">
                                    <label for="siret">N° SIRET</label>
                                    <input id="siret"
                                           name="siret"
                                           title="SIRET = SIREN + NIC"
                                           type="number"
                                           [maxlength]="14"
                                           pattern="[0-9]*"
                                           [ngClass]="{
                                            'background_alert_recommended': !form.controls.siret.value,
                                            'background_alert_required': !this.form.get('siret').valid
                                           }"
                                           formControlName="siret"/>
                                </div>
                                <div class="select2_sans_badge input_lg">
                                    <label>Effectif</label>
                                    <ng-select
                                            class="azko"
                                            bindValue="eff_uuid"
                                            bindLabel="eff_lib"
                                            [items]="effectifs|async"
                                            formControlName="effectif"
                                    ></ng-select>
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="input_lg">
                                    <label for="tvaCom">Numéro de TVA intracommunautaire</label>
                                    <input
                                            type="text"
                                            id="tvaCom"
                                            name="tvaCom"
                                            formControlName="tvaCom"
                                            [value]="personne.tvaCom"
                                            [ngClass]="{'background_alert_required': !this.form.get('tvaCom').valid}"
                                    />
                                </div>
                                <div class="input_lg"></div>
                            </div>
                            <div class="input_container">
                                <div class="input_lg select2_sans_badge">
                                    <label for="codeNaf">Code activité (NAF)</label>
                                    <app-select2-code-naf
                                            id="codeNaf"
                                            [control]="form.get('codeNaf')"
                                            [ngClass]="{'background_alert_recommended': !form.controls.codeNaf.value}"
                                    ></app-select2-code-naf>
                                </div>
                                <div class="input_lg select2_sans_badge">
                                    <label for="codeNace">Code activité EU (NACE)</label>
                                    <app-select2-code-nace
                                            id="codeNace"
                                            [control]="form.get('codeNace')"
                                    ></app-select2-code-nace>
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="select2_sans_badge input_lg">
                                    <label>Organisation parente</label>
                                    <app-select-pm
                                            [filiales]="personne.personneMorale.flattenSubsidiaryCompany"
                                            [notUuid]="personne.uuid"
                                            [control]="form.get('personneMorale.parent')"
                                    ></app-select-pm>
                                </div>
                                <div class="select2_sans_badge input_lg">
                                    <label>Chiffre d'affaire</label>
                                    <app-chiffre-affaire-select2
                                            [control]="form.get('chiffreAffaire')"
                                    ></app-chiffre-affaire-select2>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="personne.type === 'lien'">
                            <div class="input_container identite_personne_lien select2_sans_majuscule">
                                <div>
                                    <label>Fonction</label>
                                    <div class="select2_sans_badge input_lg">
                                        <app-fonction-personne-select2
                                                id="fonction"
                                                [control]="form.get('fonction')"
                                        >
                                        </app-fonction-personne-select2>
                                    </div>
                                </div>
                                <div class="select2_sans_majuscule">
                                    <label>Origine du contact</label>
                                    <div class="select2_sans_badge input_lg">
                                        <ng-container [ngTemplateOutlet]="origineContact"></ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="input_container select2_sans_majuscule">
                                <div class="input_lg">
                                    <label for="fonctionPersonnalisee">Fonction personnalisée</label>
                                    <input
                                            type="text"
                                            id="fonctionPersonnalisee"
                                            name="fonctionPersonnalisee"
                                            formControlName="fonctionPersonnalisee"
                                    />
                                </div>
                                <div class="input_lg select2_sans_majuscule" *ngIf="!personne.apporteur?.anonymised">
                                    <label>Contact apporté par</label>
                                    <div class="select2_sans_badge input_lg">
                                        <app-apporteur-select2
                                                [control]="form.get('apporteur')"
                                        ></app-apporteur-select2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc_info_commerciales" *ngIf="!router.url.startsWith('/utilisateurs')">
                    <div class="bandeau_form">Info commerciales</div>
                    <div class="bloc_container" *ngIf="personne.type !== PERSON_GLOBAL.PERSON_LIEN">
                        <div class="input_container">
                            <div class="input_lg">
                                <label for="statut">Statut</label>
                                <app-statut-select2
                                        id="statut"
                                        [control]="form.get('statut')"
                                        [ngClass]="{'background_alert_required': !form.controls.statut.value}"
                                ></app-statut-select2>
                            </div>
                            <div class="bloc_qualite_relation input_container">
                                <ng-container [ngTemplateOutlet]="qualiteRelation"></ng-container>
                            </div>
                        </div>
                        <div class="input_container">
                            <div class="input_lg">
                                <label for="tags">Mots clés</label>
                                <app-select-tag
                                        id="tags"
                                        [control]="form.get('tags')"
                                        [plUuid]="personne.uuid"
                                        [target]="tagTarget"
                                ></app-select-tag>
                            </div>
                            <div class="input_lg"
                                 *ngIf="!personne.apporteur?.anonymised && personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE">
                                <label for="apporteur">Contact apporté par</label>
                                <app-apporteur-select2
                                        id="apporteur"
                                        [control]="form.get('apporteur')"
                                ></app-apporteur-select2>
                            </div>
                            <!--                            <div class="subgroup_small" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE">-->

                            <div class="affaire-apporte" *ngIf="personne.countApporte > 0">
                                <p *ngIf="1 === personne.countApporte">{{personne.countApporte}} contact apporté</p>
                                <p *ngIf="1 < personne.countApporte">{{personne.countApporte}} contacts apportés</p>
                            </div>
                            <!--                            </div>-->
                        </div>
                        <div class="input_container" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE">
                            <div class="input_lg">
                                <label for="origine">Origine du contact</label>
                                <ng-container [ngTemplateOutlet]="origineContact"></ng-container>
                            </div>
                            <div class="input_lg" *ngIf="!personne.apporteur?.anonymised">
                                <label for="apporteur">Contact apporté par</label>
                                <app-apporteur-select2
                                        id="apporteur"
                                        [control]="form.get('apporteur')"
                                        [notUuid]="personne.uuid"
                                ></app-apporteur-select2>
                            </div>
                        </div>
                        <div class="text_area_container">
                            <div class="memo">
                                <label for="memo">Mémo</label>
                                <ng-container [ngTemplateOutlet]="memos"></ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="bloc_container" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN">
                        <div class="input_container">
                            <div class="input_lg">
                                <label for="statut">Statut</label>
                                <app-statut-select2
                                        id="statut"
                                        [control]="form.get('statut')"
                                ></app-statut-select2>
                            </div>
                            <ng-container [ngTemplateOutlet]="qualiteRelation"></ng-container>
                        </div>
                        <div class="input_container">
                            <div class="tags input_lg">
                                <label for="tags">Mots clés</label>
                                <app-select-tag
                                        id="tags"
                                        [control]="form.get('tags')"
                                        [plUuid]="personne.uuid"
                                        [target]="tagTarget"
                                ></app-select-tag>
                            </div>
                            <div></div>
                        </div>
                        <div class="text_area_container">
                            <div class="memo">
                                <label for="memo">Mémo</label>
                                <ng-container [ngTemplateOutlet]="memos"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc_compte_utilisateur" *ngIf="router.url.startsWith('/utilisateurs')">
                    <div class="bandeau_form">Profil</div>
                    <div class="bloc_container">
                        <div class="input_container">
                            <div class="input_lg">
                                <label for="login">Identifiant</label>
                                <input type="email"
                                       id="login"
                                       name="login"
                                       [value]="login"
                                       pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                       formControlName="login"
                                       [ngClass]="{'disabled': !newUser, 'background_alert_required': form.controls.login.invalid}"
                                >
                            </div>
                            <div *ngIf="router.url.startsWith('/utilisateurs') && !router.url.endsWith('/mon-compte')">
                                <label for="isReferent">Est référent / intervenant</label>
                                <input type="checkbox"
                                       id="isReferent"
                                       name="isReferent"
                                       class="icon"
                                       formControlName="isReferent"
                                >
                            </div>
                        </div>
                        <div class="input_lg">
                            <label for="userProfil">Profil</label>
                            <app-roles-select2
                                    id="userProfil"
                                    [form]="form"
                                    [uuid]="this.userUuid"
                                    [ngClass]="{'background_alert_required': !form.controls.userProfil.value}"
                            ></app-roles-select2>
                        </div>
                    </div>
                    <div class="bloc_container" *ngIf="!newUser">
                        <div class="input_container">
                            <div>
                                <h2>Droits du profil</h2>
                                <app-roles-tree></app-roles-tree>
                            </div>
                            <div>
                                <h2>Droits personnalisés</h2>
                                <app-roles-tree-user></app-roles-tree-user>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc_champs_personalises" *ngIf="!newUser">
                    <div class="bandeau_form">Champs personnalisés</div>
                    <div class="bloc_container">
                        <app-champs-personalises
                                [uuid]="form.controls.uuid.value"
                                type="{{personne.type}}"
                                [editForm]="form"
                                [formValeurs]="valeursPersonnalises"
                                (valeursEvent)="onChangePersonnalise($event)"
                        ></app-champs-personalises>
                    </div>
                </div>
            </div>
            <div class="container_form form_droit" *ngIf="!newUser">
                <div class="bloc_visibilite"
                     *ngIf="personne.type !== PERSON_GLOBAL.PERSON_LIEN && !router.url.startsWith('/utilisateurs')">
                    <app-bloc-visibilite [form]="form"
                                         [type]="'personne'"
                                         [typePersonne]="personne.type"
                    ></app-bloc-visibilite>
                </div>
                <ng-container *ngIf="this.personne.type === PERSON_GLOBAL.PERSON_LIEN">
                    <div class="bandeau_form visibilite_lien">
                        Visibilité
                        <div class="input_visibilite">
                            <app-switch (switch)="showDeletionConfirmationModal($event)"
                                        [switchValue]="personne.actif"
                                        [resetSwitch]="resetSwitch"
                            >
                            </app-switch>
                        </div>
                    </div>
                </ng-container>
                <!-- Coordonnées de la personne morale  -->
                <div class="bloc_coordonnees" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN">
                    <div class="bandeau_form">Coordonnées
                        {{ personne.personneMorale.libelle }}
                    </div>
                    <ng-container
                            *ngIf="coordonnesPM.adresse || coordonnesPM.telephone || coordonnesPM.email; else aucuneCoordonneePM">
                        <div class="container_coordonnee_pm">
                            <div class="coordonnee_pm" *ngIf="coordonnesPM.adresse">
                                <div>
                                    <span class="icon marker"></span>
                                </div>
                                <div>
                                    <span *ngIf="coordonnesPM.adresse?.ligne1 !== ''">{{ coordonnesPM.adresse?.ligne1 }}</span>
                                    <span *ngIf="coordonnesPM.adresse?.ligne2 !== ''">{{ coordonnesPM.adresse?.ligne2 }}</span>
                                    <span *ngIf="coordonnesPM.adresse?.ligne3 !== ''">{{ coordonnesPM.adresse?.ligne3 }}</span>
                                    <div class="flex">
                                        <span>{{ coordonnesPM.adresse?.cp }}</span>&nbsp;
                                        <span> {{ coordonnesPM.adresse?.ville | titlecase }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="coordonnee_pm" *ngIf="coordonnesPM.email">
                                <span class="icon mail"></span>
                                <span><a class="text-black"
                                         href="mailto:{{ coordonnesPM.email?.valeur }}">{{ coordonnesPM.email?.valeur }}</a></span>
                            </div>
                            <div class="coordonnee_pm" *ngIf="coordonnesPM?.telephone">
                                <span class="icon phone"></span>
                                <span><a class="text-black"
                                         href="tel:{{ coordonnesPM?.telephone }}">{{ coordonnesPM?.telephone }}</a></span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #aucuneCoordonneePM>
                        <div class="container_coordonnee_pm">
                            <span class="text-center text-grey">Aucune coordonnée renseignée</span>
                        </div>
                    </ng-template>
                </div>
                <div class="bloc_coordonnees">
                    <div class="bandeau_form" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE">Coordonnées
                        Personnelles
                    </div>
                    <div class="bandeau_form" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE">Coordonnées
                    </div>
                    <div class="bandeau_form" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN">Coordonnées
                        Professionnelles
                    </div>
                    <app-coordonnees
                            [emailPrincipal]="emailPrincipal"
                            [telPrincipal]="telPrincipal"
                            [uuid]="form.controls.uuid.value"
                            [personType]="this.personne.type"
                            [personStatut]="this.personne.statut?.libelle"
                            (valeursEvent)="onChangeCoordonnees($event)"
                    ></app-coordonnees>
                </div>
                <div class="bloc_adresses">
                    <div class="bandeau_form" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE">Adresses
                        Personnelles
                    </div>
                    <div class="bandeau_form" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE">Adresses</div>
                    <div class="bandeau_form" *ngIf="personne.type === PERSON_GLOBAL.PERSON_LIEN">Adresses
                        Professionnelles
                    </div>
                    <app-adresses
                            (adressePrincipal)="setAdressePrincipal($event)"
                            [personType]="personne.type"
                            [uuid]="form.controls.uuid.value"
                    ></app-adresses>
                </div>
            </div>
        </div>
    </form>
    <div class="form_bas">
        <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
        <button type="button" class="btn btn-med btn-enregistrer"
                (click)="onSubmit()"
                [ngClass]="{'btn_disabled': !form.valid}"
                [disabled]="!form.valid"
                (animationend)="animationEnd()"
        ><span>Enregistrer</span></button>
        <button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmitCancel()"><span>Enregistrer et
		fermer</span>
        </button>
    </div>
</div>
<!-- ORIGINE CONTACT -->
<ng-template #origineContact>
    <app-entity-select
            id="origine"
            [fetcher]="originesFetcher"
            [control]="form.get('personnePhysique.origine')"
    ></app-entity-select>
</ng-template>

<!-- QUALITE RELATION -->
<ng-template #qualiteRelation>
    <div class="container_qualite_relation">
        <label for="qualite_relation">Qualité relation</label>
        <app-note id="qualite_relation"
                  [formControl]="form.get('qualite')"
                  [max]="3"
        ></app-note>
    </div>
</ng-template>
<app-modal-confirm-exit #confirmModal (cancelEvent)="onCancel()"></app-modal-confirm-exit>
<!-- MEMOS -->
<ng-template #memos>
    <app-creer-memo [personne]="personne" [persist]="true"></app-creer-memo>
    <app-memos id="memo"
               [disabled]="inputDisabled"
               [personne]="personne"
               [persist]="true"
    ></app-memos>
</ng-template>
<jw-modal id="confirmDeletionModal">
    <div class="bloc_title_modal">Confirmation</div>
    <hr>
    <div class="bloc_body_modal">Etes-vous certain de vouloir désactiver cette fonction ?</div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal('confirmDeletionModal')">
                <span>Annuler</span>
            </button>
            <button type="submit" class="btn btn-med btn-supprimer" (click)="confirmDeletion()">
                <span>Désactiver</span>
            </button>
        </div>
    </div>
</jw-modal>
<jw-modal id="desactivationCascade">
    <form>
        <div class="bloc_title_modal">
            Attention : Vous vous apprêtez à désactiver cette fiche
        </div>
        <div class="bloc_container">
            <label *ngIf="listPersonneAttached.length > 0">
                Si vous enregistrez, cette/ces fonction(s) ne seront plus visible(s) dans le répertoire :
            </label>
            <label *ngIf="listPersonneAttached.length === 0">
                Cette personne n'a aucune fonction répertoriée, la désactivation concernera donc cette fiche seulement.
            </label>
            <div class="bloc_container_ul" *ngIf="listPersonneAttached.length > 0">
                <ul class="bloc_ul">
                    <li *ngFor="let personne of listPersonneAttached">
                        {{ personne.nom }} {{ personne.prenom }}, {{ personne.raisonSociale }}
                        ({{ personne.formeJuridiqueLibelle }})
                    </li>
                </ul>
            </div>
        </div>
        <div class="bloc_footer_modal">
            <div class="action_bloc_modal">
                <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal('desactivationCascade')">
                    <span>Fermer</span></button>
            </div>
        </div>
    </form>
</jw-modal>
