import {Injectable} from '@angular/core';
import {AbstractClient} from "./abstract-client";
import {environment as ENV} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, Subject} from "rxjs";

export type Filter = {
    shared?: boolean;
}

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    ),
    params: {}
};

@Injectable({
    providedIn: 'root'
})
export class CampagneTemplateService extends AbstractClient<any, Filter> {
    private filter: Filter = {
        shared: true
    };

    public templateCampagneSubject = new Subject<any>();

    constructor(http: HttpClient) {
        super(http, ENV.apiURL + 'campagne_templates');
    }

    /**
     * Récupère les template des campagnes
     */
    public getAllTemplateCampagne(): Observable<any> {
        return super.list(this.filter);
    }

    public buyTemplate(uuid: string): Observable<any> {
        return this.http.post(ENV.apiURL + 'campagne_template/send/mail/confirmation', {
            uuid: uuid,
        }, httpOptions);
    }
}
