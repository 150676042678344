<div class="input_memo" [ngClass]="{'memo_fiche': this.memoFiche}"  *ngIf="personne || offre">
    <ng-container>
        <input *ngIf="personne?.type !== PERSON_GLOBALS.PERSON_LIEN"
               type="text"
               name="memo"
               id="memo"
               placeholder="Ajouter un mémo"
               size="50"
               [(ngModel)]="memoTerme"
               (keyup)="setMemoTerme($event)"
        />
        <input *ngIf="personne?.type === PERSON_GLOBALS.PERSON_LIEN && router.url.startsWith('/repertoire/')"
               class="d-none"
        />
        <input *ngIf="personne?.type === PERSON_GLOBALS.PERSON_LIEN && router.url.endsWith('/modifier')"
               type="text"
               name="memo"
               placeholder="Ajouter un mémo"
               class="background_disabled"
               size="50"
               [(ngModel)]="memoTerme"
        />
    </ng-container>
    <button type="button" *ngIf="personne?.type !== PERSON_GLOBALS.PERSON_LIEN && memoTerme; else withoutMemoInput"
            class="btn btn-picto-med btn-enregistrer btn_input_memo"
            (click)="addMemo()">
    </button>
    <ng-template #withoutMemoInput>
        <button type="button" class="btn btn-picto-med btn-enregistrer btn_input_memo"
                [ngClass]="{'btn_disabled': !memoTerme}">
        </button>
    </ng-template>
</div>
