import {Component, Input, OnInit} from '@angular/core';
import {MessageGlobals} from "../../../const-global/messages";
import {Router} from "@angular/router";

@Component({
    selector: 'app-fiche-completion-notice',
    templateUrl: './fiche-completion-notice.component.html',
    styleUrls: ['./fiche-completion-notice.component.scss'],
})
export class FicheCompletionNoticeComponent implements OnInit {

    public MESSGAGE_INFO = MessageGlobals;
    @Input() personne;
    @Input() typeMessage;
    @Input() positionTop = false;
    @Input() typeAlerts: string[] = [];
    constructor(public router: Router) {
    }
    ngOnInit(): void {
        if (this.typeAlerts.length === 0) {
            console.error(`Attribute typeAlerts is required`);
        }
    }

}
