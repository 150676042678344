  import {
      AfterViewInit,
      Component,
      ElementRef,
      OnInit, QueryList,
      ViewChild, ViewChildren
  } from '@angular/core';
  import {FormBuilder, FormGroup} from '@angular/forms';
  import {MatSort, SortDirection} from '@angular/material/sort';
  import {Router} from '@angular/router';
  import {debounceTime} from 'rxjs/operators';
  import {NgxUiLoaderService} from 'ngx-ui-loader';
  import {forkJoin, fromEvent, Observable, of, Subscription} from 'rxjs';
  import {DataFilter} from '../../../models/datafilter';
  import {MessageGlobals} from '../../../const-global/messages';
  import {CoordonneeGlobals} from '../../../const-global/globals';
  import {ConsentHelper} from '../../_helpers/consent.helper';
  import {LienMailService} from '../../_services/lien-mail.service';
  import {ModalInformations} from './modal/modal-informations';

  @Component({
      selector: 'app-consentement',
      templateUrl: './consentement.component.html',
      styleUrls: ['./consentement.component.scss']
  })
  export class ConsentementComponent implements OnInit, AfterViewInit {

      constructor(
          private formBuilder: FormBuilder,
          private lienMailService: LienMailService,
          private router: Router,
          private ngxService: NgxUiLoaderService
      ) {
      }

      get dataSourced(): Observable<any> {
          return of(this.dataSource);
      }

      public readonly MESSAGE_GLOBALS = MessageGlobals;
      public readonly COORD_GLOBALS = CoordonneeGlobals;
      public readonly MODAL_INFORMATIONS = ModalInformations;

      @ViewChild('matTableRef', {read: ElementRef}) public matTableRef: ElementRef;
      @ViewChild('consentSort') sort = new MatSort();
      @ViewChildren('listHeaders', {read: ElementRef}) listThHeaders: QueryList<ElementRef>;
      public displayModal = true;
      public displayModalCancel = false;
      public displayModalCancelSort = false;
      public displayModalCancelSearch = false;
      public displayModalConsentAll = false;
      public dataFilter: DataFilter;
      public form: FormGroup;
      displayedColumns: string[] = ['selectConsent', 'nom', 'type', 'statut', 'email', 'consent', 'moyen', 'ref'];
      listConsent: string[] = [];
      updateTypeConsentAll;
      dataSource;
      eventPaused;
      isLoading = false;
      isEdited = false;
      isSelected = false;
      initialValues = [];
      nbItemsPerPage = 30;
      endReached = false;
      currentPage = 2;
      public messageSwitch = {
          actif: this.MESSAGE_GLOBALS.MESSAGE_FICHE_ACTIVE,
          inactif: this.MESSAGE_GLOBALS.MESSAGE_FICHE_INACTIVE
      };
      public filters: any;
      protected readonly ConsentHelper = ConsentHelper;
      public showTooltip = false;
      protected readonly event = event;

      ngOnInit(): void {
          this.form = this.formBuilder.group([]);
          this.dataFilter = new DataFilter();
          this.dataFilter.lsKey = 'consent';
          this.dataFilter.filters = this.lienMailService.buildFilterItems();
          this.dataSource = [];
      }

      fetchEmails(sort: string, order: SortDirection, page: number, perpage: number): Observable<any> {
          this.ngxService.startLoader('list');
          this.isLoading = true;
          this.filters = this.dataFilter.getQueryParams();
          this.filters.groups = ['&EmailForConsent'];
          this.filters.personForConsent = 1;
          if (sort && order) {
              this.filters['order[' + sort + ']'] = order || 'asc';
              if ('consent' === sort && !this.filters['order[mail.valeur]']) {
                  this.filters['order[mail.valeur]'] = 'asc';
              }
          } else {
              this.filters['order[consent]'] = 'asc';
              this.filters['order[mail.valeur]'] = 'asc';
          }
          this.filters.page = page;
          this.filters.itemsPerPage = perpage;
          this.filters.partialPagination = false;
          return this.lienMailService.list(this.filters);
      }

      doSort(): void {
          this.currentPage = 1;
          this.endReached = false;
          const sub: Subscription = this.fetchEmails(
              this.sort.active,
              this.sort.direction,
              this.currentPage,
              this.nbItemsPerPage
          ).subscribe(
              (data) => {
                  this.isEdited = false;
                  this.dataSource = data['hydra:member'];
                  this.currentPage++;
                  this.dataSource = this.dataSource.filter(item => (item.lien.libelle !== 'Personne privée'));
              },
              () => {
                  this.isLoading = false;
              },
              () => {
                  this.isLoading = false;
                  sub.unsubscribe();
              }
          );
      }

      sortData(): void {
          this.matTableRef.nativeElement.scrollTop = 0;
          this.doSort();
      }

      ngAfterViewInit(): void {
          fromEvent(this.matTableRef.nativeElement, 'scroll')
              .pipe(
                  debounceTime(400)
              )
              .subscribe(() => {
                      const height = this.matTableRef.nativeElement.scrollTop + this.matTableRef.nativeElement.offsetHeight;
                      const position = this.matTableRef.nativeElement.scrollHeight + 1;
                      if (
                          position >= (height * 75 / 100) && position <= height + 100 && !this.endReached
                      ) {
                          this.fetchEmails(
                              this.sort.active,
                              this.sort.direction,
                              this.currentPage,
                              this.nbItemsPerPage
                          ).subscribe((data) => {
                              this.endReached = data['hydra:member'].length < 25;
                              this.currentPage++;
                              this.dataSource.push(...data['hydra:member']);
                              this.dataSource = this.dataSource.filter(item => (!item.lien?.anonymised));
                              this.isLoading = false;
                          });
                      }
                  }, () => {

                  },
                  () => {
                  }
              );
          this.listThHeaders.forEach(box => {
              const child = box.nativeElement.firstElementChild;
              if (child) {
                  child.addEventListener('click', (event) => {
                      if (this.isEdited || this.isSelected) {
                          this.displayModalCancelSort = true;
                          this.eventPaused = event;
                          event.stopPropagation();
                      } else {
                          this.eventPaused = null;
                      }
                  });
              }
          });
      }

      modalClicked(): void {
          this.displayModal = false;
      }

      onCancel(): void {
          if (!this.isEdited) {
              this.router.navigate(['repertoire']);
          } else {
              this.displayModalCancel = true;
          }
      }

      onCancelModal(): void {
          this.displayModalCancel = false;
          this.isEdited = false;
          this.changeFilter();
          this.router.navigate(['repertoire']);
      }

      onSubmitClose(): void {
          const obs = [];
          this.ngxService.startLoader('saveConsent');
          this.dataSource.filter(row => row.edit !== undefined).forEach(row => {
              row.edit = undefined;
              obs.push(this.lienMailService.update(row.uuid, row));
          });
          forkJoin(obs).subscribe(() => {
              this.listConsent = [];
              this.initialValues = [];
              this.computeIsSelected();
              this.computeIsEdited();
              this.router.navigate(['repertoire']);
          });
      }

      onSubmit(): void {
          const obs = [];
          this.ngxService.startLoader('saveConsent');
          this.dataSource.filter(row => row.edit !== undefined).forEach(row => {
              row.edit = undefined;
              obs.push(this.lienMailService.update(row.uuid, row));
          });
          forkJoin(obs).subscribe(() => {
              this.listConsent = [];
              this.initialValues = [];
              this.computeIsSelected();
              this.computeIsEdited();
              this.doSort();
              this.ngxService.stopLoader('saveConsent');
          });
      }

      edit(row: any, prop: string, value: any): void {
          this.isEdited = true;
          if (typeof row?.edit === 'undefined') {
              row.edit = [];
              this.initialValues[row.uuid] = {...row};
          }
          if (this.initialValues[row.uuid] && value === this.initialValues[row.uuid][prop]) {
              if (prop === 'consent' &&
                  (value === this.COORD_GLOBALS.CONSENT.ND.id || value === this.COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id)
              ) {
                  delete row.edit;
              } else {
                  delete row.edit[prop];
                  if (prop === 'consentType') {
                      delete row.edit.consentDesc;
                  }
              }
          } else {
              row.edit[prop] = true;
          }

          row[prop] = value;
          if (row?.edit && Object.keys(row?.edit).length === 0) {
              delete row.edit;
          }
          this.computeIsEdited();
      }

      changeFilter(): void {
          this.currentPage = 1;
          this.endReached = false;
          this.dataSource = [];
          this.fetchEmails(null, null, this.currentPage, this.nbItemsPerPage)
              .subscribe(
                  (data) => {
                      this.isEdited = false;
                      this.currentPage++;
                      this.dataSource.push(...data['hydra:member']);
                      this.dataSource = this.dataSource.filter(item => (!item.lien?.anonymised));
                  }
                  ,
                  () => {
                      this.isLoading = false;
                  },
                  () => {
                      this.isLoading = false;
                  }
              );
      }

      toggle(): void {
          this.dataFilter.active = !this.dataFilter.active;
          localStorage.setItem(this.dataFilter.lsKey, JSON.stringify(this.dataFilter.getValues()));
          this.changeFilter();
      }

      changeConsent($event, row): void {
          this.edit(row, 'consent', $event.id);
          if (row.consent === this.COORD_GLOBALS.CONSENT.ND.id
              || row.consent === this.COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id) {
              row.consentType = null;
              row.consentDesc = null;
          }
      }

      changeConsentType($event, row): void {
          if ($event.consent_type) {
              this.edit(row, 'consentType', $event.consent_type);
          }
          if ($event.consent_custom_type) {
              if ($event.consent_type === 5) {
                  this.edit(row, 'consentDesc', $event.consent_custom_type);
              } else {
                  this.edit(row, 'consentDesc', null);
              }
          } else if ($event.consent_type) {
              this.edit(row, 'consentDesc', null);
          }
      }

      prepareSort(): void {
          this.listConsent = [];
          this.isSelected = this.isEdited = false;
          this.displayModalCancelSort = false;
          const nEvent = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: false
          });
          this.eventPaused.target.dispatchEvent(nEvent);
      }

      filterAction(): void {
          this.listConsent = [];
          this.computeIsEdited();
          this.computeIsSelected();
          this.doSort();
      }

      cancelData(): void {
          this.computeIsEdited();
          this.computeIsSelected();
          this.displayModalCancelSort = false;
      }

      reduceDataSource(): [] {
          return this.dataSource.reduce((res, elem) => {
              if (3 !== elem.consent) {
                  res.push(elem.uuid);
              }
              return res;
          }, []);
      }
      reduceDataSourceLength(): number {
          return this.dataSource.reduce((res, elem) => {
              if (3 !== elem.consent) {
                  res.push(elem.uuid);
              }
              return res;
          }, []).length;
      }
      checkedConsentAll(): boolean {
          const reduce = this.reduceDataSource();
          return reduce.length === this.listConsent.length && 0 < reduce.length;
      }
      toggleSelectConsentAll($event): void {
          if ($event.checked) {
              this.listConsent = this.reduceDataSource();

          } else {
              this.listConsent = [];
          }
          this.computeIsSelected();
      }

      optionConsentAll(event): void {
          this.updateTypeConsentAll = event;
          this.displayModalConsentAll = true;
      }

      closeModalConsentAll($event): void {
          this.displayModalConsentAll = false;
          this.updateTypeConsentAll = null;
          if ($event && Object.prototype.hasOwnProperty.call($event, 'update')) {
              this.ngxService.startLoader('selectConsent');
              const keys = this.dataSource.filter((item, index) => {
                  if (item) {
                      item.index = index;
                      return this.listConsent.includes(item.uuid);
                  }
              });
              if (0 < keys.length && !this.isEdited) {
                  this.isEdited = true;
              }
              keys.forEach((item) => {
                  Object.assign(this.dataSource[item.index], {edit: []});
                  if (null !== $event.consent && undefined !== $event.consent) {
                      this.dataSource[item.index].edit.consent = true;
                      this.dataSource[item.index].consent = $event.consent;
                      this.dataSource[item.index].consentEditable = true;
                      this.initialValues[item.uuid] = {...item};
                  }
                  if ((null !== $event.consent && undefined !== $event.consent && 0 < $event.consent)
                      || 0 < this.dataSource[item.index].consent) {
                      if (undefined !== $event.consent_type) {
                          this.dataSource[item.index].edit.consentType = true;
                          this.dataSource[item.index].consentType = $event.consent_type;
                      }
                      if (undefined !== $event.consent_custom_type) {
                          this.dataSource[item.index].edit.consentDesc = true;
                          this.dataSource[item.index].consentDesc = $event.consent_custom_type;
                      }
                  } else if (null !== $event.consent && undefined !== $event.consent && 0 === $event.consent) {
                      this.dataSource[item.index].edit.consentType = true;
                      this.dataSource[item.index].edit.consentDesc = true;
                      this.dataSource[item.index].consentType = null;
                      this.dataSource[item.index].consentDesc = null;
                  }
              });
              this.listConsent = [];
              this.ngxService.stopLoader('selectConsent');
          }
      }

      toggleSelectConsent(uuid): void {
          if (!this.listConsent.includes(uuid)) {
              this.listConsent.push(uuid);
          } else {
              this.listConsent.splice(this.listConsent.indexOf(uuid), 1);
          }
          this.computeIsSelected();
      }

      computeIsEdited(): void {
          this.isEdited = this.dataSource.filter(row => row.edit !== undefined).length > 0;
      }

      computeIsSelected(): void {
          this.isSelected = 0 < this.listConsent.length;
      }

      isChecked(uuid): boolean {
          return this.listConsent.includes(uuid);
      }
      isSelectDisable(row): boolean {
          return row.consent === 3 && !this.initialValues[row.uuid];
      }
  }
