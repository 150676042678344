import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConsentHelper} from "../../../_helpers/consent.helper";
import {CoordonneeGlobals} from "../../../../const-global/globals";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-moyen-recueil',
  templateUrl: './moyen-recueil.component.html',
  styleUrls: ['./moyen-recueil.component.scss']
})
export class MoyenRecueilComponent implements OnInit {
  public readonly COORD_GLOBALS = CoordonneeGlobals
  public readonly ConsentHelper = ConsentHelper;
  form: FormGroup;

  @Output() change = new EventEmitter();
  @Input() value: any;
  constructor(private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      consent_type: [this.value.consent_type],
      consent_custom_type: [this.value.consent_custom_type]
    });
  }
  ngOnChanges(): void {
    if(this.form) {
      if(Object.prototype.hasOwnProperty.call(this.value, 'consent_type')) {
        this.form.patchValue({ consent_type: this.value.consent_type})
      }
      if(Object.prototype.hasOwnProperty.call(this.value, 'consent_custom_type')) {
        this.form.patchValue({ consent_custom_type: this.value.consent_custom_type})
      }
    }
  }
  update(): void {
    this.change.emit(this.form.value);
  }
}
