import {Injectable, Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'PersonType'
})
@Injectable({providedIn:  'root'})
export class PersonTypePipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): unknown {
        if(value === 'lien'){
            return 'Fonction';
        }
        return value;
    }
}
