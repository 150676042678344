import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-statut-select2',
    templateUrl: './statut-select2.component.html',
    styleUrls: ['./statut-select2.component.scss']
})
export class StatutSelect2Component {

    items = this.personneService.getListeStatuts();
    @Input() control: FormControl|AbstractControl = new FormControl();
    constructor(private personneService: PersonneService) {}
}
