<ng-container>
    <ng-container [ngSwitch]="this.typeSelect">
        <ng-container *ngSwitchCase="'number'">
            <ng-container [ngSwitch]="this.nbValeurs">
                <div *ngSwitchCase="1" class="input_lg">
                    <ng-container *ngIf="this.filtre.value.champ.value.type === 'string' &&
                                   this.filtre.value.champ.value.operateursValues === 6" >
                        <input

                                type="number"
                                [value]="this.valeurs"
                                [formControl]="valeursCtl"
                                max={{this.filtre.value.champ.value.max}}
                                min={{this.filtre.value.champ.value.min}}
                                step=0.01
                        />
                    </ng-container>
                    <ng-container  *ngIf="!(this.filtre.value.champ.value.type === 'string' &&
                                   this.filtre.value.champ.value.operateursValues === 6)" >
                    <input
                            type="number"
                            [value]="this.valeurs"
                            [formControl]="valeursCtl"
                            max={{this.filtre.value.champ.value.max}}
                            min={{this.filtre.value.champ.value.min}}
                    />
                    </ng-container>
                </div>
                <div *ngSwitchCase="2" class="input_lg">
                    <ng-container *ngIf="this.filtre.value.champ.value.type === 'string' &&
                                   this.filtre.value.champ.value.operateursValues === 6" >
                        <input
                                type="number"
                                [value]="interval[0]"
                                max={{this.filtre.value.champ.value.max}}
                                step=0.01
                                min=0
                                (change)="intervalChange()"
                                #intervalle
                        />
                        et
                        <input
                                type="number"
                                [value]="interval[1]"
                                max={{this.filtre.value.champ.value.max}}
                                step=0.01
                                min=0
                                (change)="intervalChange()"
                                #intervalle
                        />
                        <input type="hidden" [formControl]="valeursCtl" />
                    </ng-container>

                    <ng-container  *ngIf="!(this.filtre.value.champ.value.type === 'string' &&
                                   this.filtre.value.champ.value.operateursValues === 6)" >
                        <input
                                type="number"
                                [value]="interval[0]"
                                (change)="intervalChange()"
                                #intervalle
                                max={{this.filtre.value.champ.value.max}}
                        />
                        et
                        <input
                                type="number"
                                [value]="interval[1]"
                                (change)="intervalChange()"
                                #intervalle
                                max={{this.filtre.value.champ.value.max}}
                        />
                        <input type="hidden" [formControl]="valeursCtl" />
                    </ng-container>

                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
            <ng-container [ngSwitch]="this.nbValeurs">
                <div *ngSwitchCase="1" class="input_lg">
                    <input type="date" [value]="this.valeurs || ''" [formControl]="valeursCtl" />
                </div>
                <div *ngSwitchCase="2" class="input_lg">
                    <input type="date" [value]="interval[0]" (change)="intervalChange()"  #intervalle />
                    et
                    <input type="date" [value]="interval[1]" (change)="intervalChange()"  #intervalle />
                    <input type="hidden" [formControl]="valeursCtl" />
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'select2'">
            <div [ngSwitch]="this.nbValeurs">
                <div *ngSwitchCase="1" class="input_lg">
                    <ng-select2 [data]="dataAsync | async"
                                *ngIf="this.filtre.value.operateur.value.isTag"
                                [options]="options"
                                [className]="'typeSelect'"
                                (valueChanged)="valueChanged($event)"
                                [value]="this.value"
                    ></ng-select2>

                    <ng-select2 [data]="dataAsync | async"
                                *ngIf="!this.filtre.value.operateur.value.isTag"
                                [options]="options"
                                [className]="'typeSelect'"
                                (valueChanged)="valueChanged($event)"
                                [value]="this.value"
                    ></ng-select2>
                </div>
                <div *ngSwitchCase="2" class="input_lg" >
                    <input type="text" [value]="interval[0]" (change)="intervalChange()"  #intervalle />
                    et
                    <input type="text" [value]="interval[1]" (change)="intervalChange()" #intervalle />
                    <input type="hidden" [formControl]="valeursCtl" />
                </div>
                <div *ngSwitchCase="0" class="input_lg">
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
