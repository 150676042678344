import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotifierService} from "angular-notifier";
import {BehaviorSubject, forkJoin, of, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {ExceptionService} from "../../../_services/exception.service";
import {HelperService} from "../../../_services/helper.service";
import {ModalService} from "../../../_modal";
import {ProductService} from "../../../_services/product.service";
import {catchError} from "rxjs/operators";
import {MessageGlobals} from "../../../../const-global/messages";
import {IProduct} from "../../../../interfaces/product";
import {TauxTVAService} from "../../../_services/taux-tva.service";

@Component({
    selector: 'app-form-produit',
    templateUrl: './form-produit.component.html',
    styleUrls: ['./form-produit.component.scss']
})
export class FormProduitComponent implements OnInit, OnDestroy {

    public uuid: string;
    public form: FormGroup;
    public formCustomTaux: FormGroup;
    public stats: { offer: number };

    public hidden: boolean = true;
    error: any;
    public statsSubject = new BehaviorSubject({
        offer: 0
    });
    private notifier: NotifierService;
    private subscriptions = {
        stats: Subscription.EMPTY,
        router: Subscription.EMPTY,
        products: Subscription.EMPTY,
        fetcher: Subscription.EMPTY
    };
    fetcher = () => this.tauxTVAService.list({});

    add() {
        this.error = null;
        let newItem = null;
        return this.tauxTVAService.add({
            value: Math.round(this.formCustomTaux.get('taux').value * 100)
        }).subscribe(item => {
                newItem = item;
            },
            err => {
                this.error = err;
            },
            () => {
                this.notifier.notify('success', 'Valeur ajoutée avec succès');
                this.openCustomTaux();
                this.subscriptions.fetcher = this.fetcher().subscribe(() => {

                    },
                    () => {

                    }, () => {
                        this.form.get('tva').setValue(newItem);
                    });
            });
    }

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private productService: ProductService,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private helperService: HelperService,
        private modalService: ModalService,
        private tauxTVAService: TauxTVAService,
        notifier: NotifierService
    ) {
        this.notifier = notifier;
    }

    ngOnInit(): void {

        this.subscriptions.stats = this.statsSubject.subscribe(stats => {
            this.stats = stats;
        });

        this.form = this.formBuilder.group({
            active: [true, Validators.required],
            libelle: ['', [Validators.required, Validators.maxLength(45)]],
            typeFacturation: ['', Validators.required],
            prixUnitaire: ['', Validators.required],
            tva: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
        }, {});

        this.formCustomTaux = this.formBuilder.group({
            taux: ['', [Validators.min(0), Validators.max(100)]],
        })

        this.subscriptions.router = this.route.params.subscribe(params => {
            if (typeof params['id'] !== 'undefined') {
                this.uuid = params['id'];
                this.subscriptions.products = this.productService.detail(this.uuid).subscribe((data: IProduct) => {
                    data.typeFacturation = String(data.typeFacturation);
                    data.prixUnitaire = (Number(data.prixUnitaire) / 100).toFixed(2).toString();
                    data.tva = (Number(data.tva) / 100).toFixed(2).toString();

                    this.form.patchValue(data);
                });
            }
        });
    }

    public onSubmit(): string {
        this.ngxService.start();
        if (this.form.valid) {
            const dataForm = this.form.getRawValue();
            dataForm.typeFacturation = Number(dataForm.typeFacturation);
            dataForm.prixUnitaire = Math.round(Number(dataForm.prixUnitaire) * 100);
            if ('object' === typeof dataForm.tva && typeof dataForm.tva.value !== 'undefined') {
                dataForm.tva = dataForm.tva.value;
            }
            dataForm.tva = Math.round(Number(dataForm.tva) * 100);
            this.ngxService.stop();
            if (this.uuid) {
                this.productService.update(this.uuid, dataForm).subscribe(() => {
                    this.notifier.notify('success', MessageGlobals.MESSAGE_MODIFICATION);
                    this.router.navigate(['/parametres/catalogue-produits']);
                });
            } else {
                this.createNewProduct(dataForm);
            }
        } else {
            this.notifier.notify('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
        return 'submit';
    }

    public onCancel(): string {
        this.router.navigate(['/parametres/catalogue-produits']);
        return 'cancel';
    }

    public onSubmitCancel(): void {
        this.onSubmit();
    }

    public submit(): any {
        return forkJoin({
            submit: of(this.onSubmit()),
            cancel: of(this.onCancel())
        }).pipe(catchError(error => of(error)));
    }

    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }

    private createNewProduct(formData): void {
        this.productService.add(formData).subscribe((result) => {
            this.uuid = result.uuid;
        }, () => {
            this.notifier.notify('error', MessageGlobals.MESSAGE_ERROR);
        }, () => {
            this.router.navigate(['/parametres/catalogue-produits']);
            this.notifier.notify('success', 'Le produit a bien été créé.');
        });
    }

    switchActive($event): void {
        this.form.get('active').setValue($event);
    }

    openCustomTaux() {
        this.hidden = !this.hidden;

        if (this.hidden === false) {
            this.formCustomTaux.get("taux").setValidators([
                Validators.required,
                Validators.min(0),
                Validators.max(100)
            ]);
        } else {
            this.formCustomTaux.get("taux").setValue('');
            this.formCustomTaux.get("taux").clearValidators();
        }
        this.formCustomTaux.get("taux").updateValueAndValidity();
    }
}
