<app-fil-ariane></app-fil-ariane>
<div class="main">
    <div>
        <app-repertoire-outils
            (search)="onRecherche($event)"
            [type]="'champ'"
        ></app-repertoire-outils>
    </div>
    <div class="content_champs"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
    >
        <div *ngIf="champsPerso === undefined || champsPerso.length === 0" class="message_information">
            Aucun résultat trouvé
        </div>
        <ul class="champs">
            <li class="champs_item" *ngFor="let champ of champsPerso; let i = index">
                <div class="champ" *ngIf="champ" (click)="openChamp(champ)">
                    <div class="champs_content">
                        <div class="icon edition carte_titre bandeau_parametre">
                            <h2>{{ champ.libelle  }}</h2>
                        </div>
                        <div class="carte_texte">
                            <p class="texte_systeme">{{champ.type}}</p>
                            <div class="liste_target">
                                <p class="texte_target">Personne : {{ champ.cible }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
</div>

