import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment as ENV} from 'src/environments/environment';
import {IProduct} from "../../interfaces/product";
import {AbstractClient} from "./abstract-client";
import {Filter} from "./personne-lien.service";

@Injectable({
    providedIn: 'root'
})
export class ProductService extends AbstractClient<IProduct, Filter> {
    constructor(http: HttpClient) {
        super(http, ENV.apiURL + 'produits');
    }
}
