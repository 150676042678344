import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Personne} from '../../models/personne';
import {PersonneMorale} from '../../models/personnemorale';
import {PersonnePhysique} from '../../models/personnephysique';
import {PersonneFonction} from '../../models/personnefonction';
import {PersonGlobals} from '../../const-global/globals';
import {shareReplay} from 'rxjs/operators';

const API_URL = environment.apiURL;

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};

@Injectable({
    providedIn: 'root'
})
export class PersonneService {
    public personnes: Array<Personne>;
    public personne: Personne;
    private listUsers$: Observable<any> = null;
    private CodeNaceJson = 'assets/dataset/nace-codes.json';
    private CodeNafJson = 'assets/dataset/naf-codes.json';
    private subjectReset = new Subject<any>();
    public subjectPersonne = new Subject<any>();
    public resetSubject = new Subject<boolean>();
    public isEnabled = new Subject<boolean>();

    // Observables RxJs : ShareReplay
    private coordonneType$: Observable<any> = null;
    private codeNace$: Observable<any> = null;
    private nafCodes$: Observable<any> = null;
    private formeJuridique$: Observable<any> = null;
    private adressesType$: Observable<any> = null;
    private indicatifList$: Observable<any> = null;
    private imcompleteProfilsCount$: Observable<object> = null;
    private userCurrent$: Observable<object> = null;
    private personneStatuts$: Observable<object> = null;
    private personnesFonctions$: Observable<object> = null;

    constructor(
        private http: HttpClient
    ) {
    }

    getCurrentUser(refresh?: boolean): Observable<any> {
        if (!this.userCurrent$ || refresh === true) {
            this.userCurrent$ = this.http.get(API_URL + 'user-current').pipe(
                shareReplay(1)
            );
        }
        return this.userCurrent$;
    }

    getListeUsers(onlyReferents?: boolean): Observable<any> {
        if (!this.listUsers$) {
            let url = API_URL + 'personnes-users';
            url = onlyReferents ? url + '?referents=true' : url;
            this.listUsers$ = this.http.get(url).pipe(
                shareReplay(1)
            );
        }
        return this.listUsers$;
    }

    public refreshOnConnect(): void {
        this.userCurrent$ = null;
        this.listUsers$ = null;
        this.coordonneType$ = null;
        this.codeNace$ = null;
        this.nafCodes$ = null;
        this.formeJuridique$ = null;
        this.adressesType$ = null;
        this.indicatifList$ = null;
        this.imcompleteProfilsCount$ = null;
        this.personneStatuts$ = null;
        this.personnesFonctions$ = null;
    }

    getPersonnesListe(offset: string,
                      limit: string,
                      recherche: string,
                      type: string,
                      statut: string,
                      active: string,
                      order: string = null,
                      myContacts: string = null,
                      isComplete: string = null,
    ): Observable<any> {

        let httpParameters = new HttpParams();
        if (offset && limit) {
            httpParameters = httpParameters
                .set('offset', offset.toString())
                .set('limit', limit.toString())
                .set('active', active);

            if (statut) {
                httpParameters = httpParameters.set('statut', statut);
            }

            if (type) {
                httpParameters = httpParameters.set('type', type);
            }

            if (recherche) {
                httpParameters = httpParameters.set('recherche', recherche);
            }

            if (order) {
                httpParameters = httpParameters.set('order_date', order);
            }

            if (myContacts) {
                httpParameters = httpParameters.set('my_contacts', myContacts.toString());
            }

            if (isComplete) {
                httpParameters = httpParameters.set('is_complete', isComplete.toString());
            }
        }
        const options = {
            params: httpParameters
        };
        return this.http.get(API_URL + 'personnes', options);
    }

    getDatasFiche(uuid): Observable<any> {
        return this.http.get(API_URL + 'personnes/' + uuid);
    }

    getDatasFilAriane(uuid): Observable<any> {
        return this.http.get(API_URL + 'personnes/fil-ariane/' + uuid);
    }

    getDatasPersonne(uuid): Observable<any> {
        return this.http.get(API_URL + 'datas/personnes/' + uuid);
    }

    setTags(tags: string[], uuid: string): Observable<any> {
        return this.http.post(API_URL + 'personnes/tags', {
            tags,
            uuid
        }, httpOptions);
    }

    getPersonneLiens(uuid): Observable<any> {
        return this.http.get(API_URL + 'personnes/liens/' + uuid);
    }

    getPersonneChamps(uuid): Observable<any> {
        return this.http.get(API_URL + 'personnes/champs/' + uuid);
    }

    getCoordonnees(uuid): Observable<any> {
        return this.http.get(API_URL + 'personnes/coordonnees/all/' + uuid);
    }

    getCoordonneesTypes(): Observable<any> {
        if (!this.coordonneType$) {
            this.coordonneType$ = this.http.get(API_URL + 'personnes/coordonnees/types').pipe(
                shareReplay()
            );
        }
        return this.coordonneType$;
    }

    getAdresses(uuid): Observable<any> {
        return this.http.get(API_URL + 'personnes/adresses/all/' + uuid);
    }

    getAdressesTypesAndStatuts(): Observable<any> {
        if (!this.adressesType$) {
            this.adressesType$ = this.http.get(API_URL + 'personnes/adresses/types').pipe(
                shareReplay()
            );
        }
        return this.adressesType$;
    }

    getChampsDisponbiles(target): Observable<any> {
        return this.http.get(API_URL + 'personnes/champs-disponibles/' + target);
    }

    createPersonne(form): Observable<any> {
        return this.http.post(API_URL + 'personnes', {
            personne: form,
        }, httpOptions);
    }

    createPersonnePhysique(form): Observable<any> {
        return this.http.post(API_URL + 'personnes', {
            personne: form,
        }, httpOptions);
    }

    updatePersonne(uuid, form): Observable<any> {
        return this.http.put(API_URL + 'personnes/' + uuid, {
            personne: form,
        }, httpOptions);
    }

    setFonction(uuidPl, form): Observable<any> {
        return this.http.put(API_URL + 'set-fonction/' + uuidPl, {
            personne: form
        }, httpOptions);
    }


    getListeIndicatifs(): Observable<any> {
        if (!this.indicatifList$) {
            this.indicatifList$ = this.http.get(API_URL + 'telephones/indicatifs').pipe(
                shareReplay()
            );
        }
        return this.indicatifList$;
    }

    getListeChampsCreationPP(): Observable<any> {
        return this.http.get(API_URL + 'personnes/physique/champs');
    }

    getListeChampsCreationPM(): Observable<any> {
        return this.http.get(API_URL + 'personnes/morale/champs');
    }

    getListeChampsCreationPF(): Observable<any> {
        return this.http.get(API_URL + 'personnes/fonction/champs');
    }

    getListeOrigines(): Observable<any> {
        return this.http.get(API_URL + 'parametre/origines');
    }

    getListePersonnesPhysiques(): Observable<any> {
        return this.http.get(API_URL + 'personnes/physique/all');
    }

    getListeApporteur(): Observable<any> {
        return this.http.get(API_URL + 'personnes/physique/apporteurs');
    }

    getListeTitres(): Observable<any> {
        return this.http.get(API_URL + 'personnes/physique/titres');
    }

    getCountAllPersonnes(): Observable<any> {
        return this.http.get(API_URL + 'personnes/count/all');
    }

    getListeStatuts(): Observable<any> {
        if (!this.personneStatuts$) {
            this.personneStatuts$ = this.http.get(API_URL + 'personne_statuts.json').pipe(
                shareReplay()
            );
        }
        return this.personneStatuts$;
    }

    // GET list select 2
    getListeFormeJuridique(): Observable<any> {
        if (!this.formeJuridique$) {
            return this.http.get(API_URL + 'personne-forme-juridique').pipe(
                shareReplay()
            );
        }
        return this.formeJuridique$;
    }

    getListeEffectif(): Observable<any> {
        return this.http.get(API_URL + 'personne-effectif');
    }

    getListeChiffreAffaire(): Observable<any> {
        return this.http.get(API_URL + 'personne-chiffre-affaire');
    }

    getListeOrganisationParente(recherche = null, uuid = null, limit = null): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('recherche', recherche)
                .set('uuid', uuid)
                .set('limit', limit)
        };
        return this.http.get(API_URL + 'personne-organisation-parente', options);
    }

    getListePMWithoutChildren(uuid): Observable<any> {
        return this.http.get(API_URL + 'personne-morale-without-children/' + uuid);
    }

    getListePMWithoutParents(uuid): Observable<any> {
        return this.http.get(API_URL + 'personne-morale-without-parents/' + uuid);
    }

    getPersonnesSelect(type): Observable<any> {
        return this.http.get(API_URL + 'personnes-select/' + type);
    }

    getPersonnesFonctions(): Observable<any> {
        if (!this.personnesFonctions$) {
            this.personnesFonctions$ = this.http.get(API_URL + 'personnes-fonctions').pipe(
                shareReplay()
            );
        }
        return this.personnesFonctions$;
    }

    getAvailableFonctions(pp: string, pm: string): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('ppUuid', pp)
                .set('pmUuid', pm)
        };
        return this.http.get(API_URL + 'personnes-fonctions', options);
    }

    createPersonneFonction(form): Observable<any> {
        return this.http.post(API_URL + 'personne-fonction', {
            personne: form,
        }, httpOptions);
    }

    getLienPersonnePhysique(uuid): Observable<any> {
        return this.http.get(API_URL + 'personne-linked/physique/' + uuid);
    }

    getLienPersonneMorale(uuid): Observable<any> {
        return this.http.get(API_URL + 'personne-linked/morale/' + uuid);
    }

    createLien(uuid, form, type = null): Observable<any> {
        return this.http.post(API_URL + 'new-link/' + uuid, {
            personne: form,
            type
        }, httpOptions);
    }

    getCountPersonne(prospect = null, client = null, myContacts = null): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('prospect', prospect)
                .set('client', client)
                .set('myContacts', myContacts)
        };
        return this.http.get(API_URL + 'count-personne', options);
    }

    getCountIncompleteProfils(refresh?: boolean): Observable<any> {
        if (!this.imcompleteProfilsCount$ || refresh === true) {
            let httpParameters = new HttpParams();
            httpParameters = httpParameters
                .set('count', String(1))
                .set('active', 'true')
                .set('is_complete', 'false');
            const options = {
                params: httpParameters
            };
            this.imcompleteProfilsCount$ = this.http.get(API_URL + 'personnes', options).pipe(
                shareReplay()
            );
        }
        return this.imcompleteProfilsCount$;
    }

    getListePersonnesIncomplete(offset: string,
                                limit: string,
                                active: string,
                                order: string = null): Observable<any> {

        let httpParameters = new HttpParams();
        if (offset && limit) {
            httpParameters = httpParameters
                .set('offset', offset.toString())
                .set('limit', limit.toString())
                .set('active', active)
                .set('order', order)
                .set('is_complete', 'false');
        }
        const options = {
            params: httpParameters
        };
        return this.http.get(API_URL + 'personnes', options);
    }

    sendResetSelect2(value): void {
        this.subjectReset.next(value);
    }

    // retourne une liste de données de personne
    listeDatasPersonnes(data): Array<Personne> {
        this.personnes = new Array<Personne>();

        data.forEach((personne: Personne) => {
            if (personne.type === PersonGlobals.PERSON_LIEN_MORALE) {
                const personneMorale = new PersonneMorale();
                personneMorale.fillFromDatas(personne);
                this.personnes.push(personneMorale);
            }
            if (personne.type === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
                const personnePhysique = new PersonnePhysique();
                personnePhysique.fillFromDatas(personne);
                this.personnes.push(personnePhysique);
            }
            if (personne.type === PersonGlobals.PERSON_LIEN) {
                const personneFonction = new PersonneFonction();
                personneFonction.fillFromDatas(personne);
                this.personnes.push(personneFonction);
            }
        });

        return this.personnes;
    }

    // retourne les données d'une personne
    datasPersonne(personne): Personne {
        if (personne.type === PersonGlobals.PERSON_LIEN_MORALE) {
            const personneMorale = new PersonneMorale();
            personneMorale.fillFromDatas(personne);
            return personneMorale;
        }
        if (personne.type === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
            const personnePhysique = new PersonnePhysique();
            personnePhysique.fillFromDatas(personne);
            return personnePhysique;
        }
        if (personne.type === PersonGlobals.PERSON_LIEN) {
            const personneFonction = new PersonneFonction();
            personneFonction.fillFromDatas(personne);
            return personneFonction;
        }
        return new Personne();
    }

    public getNaceCodes(): Observable<any> {
        if (!this.codeNace$) {
            this.codeNace$ = this.http.get(this.CodeNaceJson).pipe(
                shareReplay()
            );
        }
        return this.codeNace$;
    }

    public getNafCodes(): Observable<any> {
        if (!this.nafCodes$) {
            this.nafCodes$ = this.http.get(this.CodeNafJson).pipe(
                shareReplay()
            );
        }
        return this.nafCodes$;
    }

    refreshPersonne(uuid: string): Subscription {
        return this.http.get<Personne>(API_URL + 'personnes/' + uuid).subscribe(
            personne => {
                this.subjectPersonne.next(personne);
            }
        );
    }

    public getChildrenOrParent(uuidCurrentPersonne: string): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('uuid', uuidCurrentPersonne)
                .set('uuidParent', undefined)
                .set('parent', String(false))
        };
        return this.http.get(API_URL + 'parents-or-children/morales', options);
    }
}
