import {Component, EventEmitter, Output} from '@angular/core';
import {BaseSelect2Component} from '../../base-select2/base-select2.component';

@Component({
    selector: 'app-statuts-event-select2',
    templateUrl: './statuts-event-select2.component.html',
    styleUrls: ['./statuts-event-select2.component.scss']
})
export class StatutsEventSelect2Component extends BaseSelect2Component {

    @Output() statutEvent = new EventEmitter<string>();
    items = [];

    initSelect(): void {
        this.items = [
            {uuid: '1', libelle: 'A faire'},
            {uuid: '2', libelle: 'Fait'},
            {uuid: '10', libelle: 'Annulé'},
        ];
    }

    public valueChangeType(statut: any): void {
        if (!statut) {
            this.statutEvent.emit(null);
        } else {
            this.statutEvent.emit(statut.uuid);
        }
    }

}
