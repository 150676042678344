<div>
    <app-fil-ariane></app-fil-ariane>
    <router-outlet></router-outlet>
</div>
<div class="content_choice" *ngIf="router.url.indexOf('/choix-modele') > -1">
    <div class="card_choice_campaign">
        <div class="bandeau_campagne icon mail">
            <h1>Nouvelle Campagne</h1>
        </div>
        <div class="content_card">
            <span class="text_card">Je souhaite créer une campagne...</span>
        </div>
        <div class="action_choice_campaign">
            <button type="button" class="btn btn-med campagne icon copy" (click)="navigate('catalogue')">
                <span>Depuis un modèle</span>
            </button>
            <button type="button" class="btn btn-med campagne icon add" (click)="navigate('/campagne/ajouter')">
                <span>Vierge</span>
            </button>
        </div>
    </div>
</div>
