import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'numberDecimal'
})
// return n number of decimal places
export class NumberDecimalPipe implements PipeTransform {
    transform(myNumber: number, nbDecimal: number): any {
        let abs = Math.abs(myNumber);
        const rounder = Math.pow(10, nbDecimal);

        if (isNaN(myNumber) || myNumber === null || myNumber === undefined) {
            return null;
        }

        if (myNumber === 0) {
            return 0;
        }

        return Math.round(abs * rounder) / rounder;
    }
}
