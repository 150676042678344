import {Component, Input, OnInit, Pipe, PipeTransform, ViewEncapsulation} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({name: 'previewCampagne'})
export class PreviewCampagne implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}

@Component({
    selector: 'app-campagne-email-preview',
    templateUrl: './campagne-email-preview.component.html',
    styleUrls: ['./campagne-email-preview.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CampagneEmailPreviewComponent implements OnInit {

    @Input() html;

    constructor() {
    }

    ngOnInit(): void {
    }

}
