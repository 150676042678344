<app-fil-ariane></app-fil-ariane>
<div class="form_tag">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form" [formGroup]="form">
        <div class="bandeau_tag icon icon_tag">
            <h1 *ngIf="tag && uuidTag; else elseModifierTitle">{{tag.libelle}}</h1>
            <ng-template #elseModifierTitle>
                <h1>Nouveau Mot-clé</h1>
            </ng-template>
        </div>
        <div class="formulaire">
            <div class="container_form form_gauche">
                <div class="bloc_tag">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div class="input_container full_width">
                            <div class="input_lg">
                                <label for="libelle">Intitulé*</label>
                                <input type="text"
                                       id="libelle"
                                       name="libelle"
                                       formControlName="libelle"
                                       maxlength="45"
                                       [ngClass]="{'background_alert_required': !form.controls.libelle.value}"
                                />
                            </div>
                            <div class="alert" role="alert" *ngIf="form.controls.libelle?.value?.length === 45">
                                Le nombre de caractères maximum est de 45
                            </div>
                        </div>
                        <div class="input_container full_width checkbox">
                            <label>Entités cibles</label>
                            <div class="container_checkox" [formArrayName]="'targets'"
                                 *ngIf="uuidTag; else elseModifierTarget">
                                <!--suppress TypeScriptValidateTypes -->
                                <ng-container *ngFor="let control of targets.controls; let i = index;"
                                              [formGroup]="control">
                                    <input type="checkbox"
                                           class="icon"
                                           formControlName="checkbox"
                                           value="{{ control?.controls.value.value }}"
                                           id="{{ control?.controls.id.value }}"
                                           [ngClass]="{'background_alert_required': !form.controls.selectedItems.value}"
                                    >
                                    <span class="label_checkbox">{{ control?.controls.id.value }}</span>
                                </ng-container>
                            </div>
                            <ng-template #elseModifierTarget>
                                <div class="container_checkox">
                                    <input id="cible_personne"
                                           type="checkbox"
                                           class="icon"
                                           value="1"
                                           (change)="onChangeCheckBox($event, 1)"
                                    />
                                    <span class="label_checkbox">Personnes</span>
                                    <input id="cible_offre"
                                           type="checkbox"
                                           class="icon"
                                           value="2"
                                           (change)="onChangeCheckBox($event, 2)"
                                    />
                                    <span class="label_checkbox">Offres</span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_form form_droit">
                <div class="bloc_tag" *ngIf="uuidTag">
                    <div class="bandeau_form">Utlisé dans ...</div>
                    <div class="bloc_container">
                        <div class="data_use">
                            <span>Personnes physiques</span>
                            <span *ngIf="nbUsePP; else elseBlock">{{ nbUsePP }}</span>
                            <ng-template #elseBlock>0</ng-template>
                        </div>
                        <div class="data_use">
                            <span>Personnes morales</span>
                            <span *ngIf="nbUsePM; else elseBlock">{{ nbUsePM }}</span>
                            <ng-template #elseBlock>0</ng-template>
                        </div>
                        <div class="data_use">
                            <span>Offres</span>
                            <span *ngIf="nbUseOffer; else elseBlock">{{ nbUseOffer }}</span>
                            <ng-template #elseBlock>0</ng-template>
                        </div>
                    </div>
                </div>
                <div class="bloc_tag" *ngIf="uuidTag">
                    <div class="bandeau_form">Actions</div>
                    <div class="bloc_container">
                        <div class="data_use">
                            <button type="button" class="btn btn-med btn-supprimer" (click)="deleteTag()">
                                <span>Supprimer</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="form_bas">
        <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
        <div *ngIf="uuidTag; else createBtn">
            <button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmit('modifier')">
                <span>Enregistrer</span>
            </button>
        </div>
        <ng-template #createBtn>
            <button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmit('creer')">
                <span>Enregistrer</span>
            </button>
        </ng-template>
        <button type="button" class="btn btn-med btn-enregistrer"
                (click)="onSubmitCancel()">
            <span>Enregistrer et fermer</span>
        </button>
    </div>
</div>
<jw-modal id="confirmDeletionModal">
    <div class="bloc_title_modal">Êtes-vous certain de vouloir <strong class="action_modal valider">supprimer ce mot clé
        ?</strong></div>
    <hr>
    <div class="bloc_body_modal">Si vous supprimez ce mot clé, vous ne pourrez plus y accéder par la suite.</div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="cancelDeletion()">
                <span>Annuler</span></button>
            <button type="submit" class="btn btn-med btn-valider" (click)="confirmDeletion()">
                <span>Supprimer le mot clé</span>
            </button>
        </div>
    </div>
</jw-modal>
