'use strict';

export class MessageGlobals {
    static readonly MESSAGE_CREATION: string = '';
    static readonly MESSAGE_MODIFICATION: string = 'Vos modifications ont bien été enregistrées';
    static readonly MESSAGE_DESACTIVATION: string = 'La personne fonction a bien été désactivée.';
    static readonly MESSAGE_CHAMPS_REQUIS: string = 'Veuillez remplir les champs obligatoires.';
    static readonly MESSAGE_INFORMATION: string = '';
    static readonly MESSAGE_EN_COURS: string = 'Bientôt disponible';
    static readonly MESSAGE_FICHE_ACTIVE: string = 'Vous consultez actuellement les fiches actives. Cliquez pour consulter les fiches inactives.';
    static readonly MESSAGE_FICHE_INACTIVE: string = 'Vous consultez actuellement les fiches inactives. Cliquez pour consulter les fiches actives.';
    static readonly MESSAGE_CAMPAGNE_ACTIVE: string = 'Vous consultez actuellement les campagnes actives. Cliquez pour' +
        ' consulter les campagnes inactives.';
    static readonly MESSAGE_CAMPAGNE_INACTIVE: string = 'Vous consultez actuellement les campagnes inactives. Cliquez pour' +
        ' consulter les campagnes actives.';
    static readonly MESSAGE_OFFRE_ACTIVE: string = 'Vous consultez actuellement les offres actives. Cliquez pour' +
        ' consulter les offres inactives.';
    static readonly MESSAGE_OFFRE_INACTIVE: string = 'Vous consultez actuellement les offres inactives. Cliquez pour' +
        ' consulter les offres actives.';
    static readonly MESSAGE_SUCCESS: string = 'Opération réalisée avec succès.';
    static readonly MESSAGE_ERROR: string = `Une erreur s'est produite, impossible de réaliser l'opération`;
    static readonly MESSAGE_SUPPORT: string = `Une erreur est survenue, veuillez contacter l'équipe support.`;
    // messages pour les fiches et le repertoire (bandeau jaune)
    static readonly FICHE_A_OPTIMISER_REPERTOIRE: string = `optimiser_repertoire`;
    static readonly FICHE_A_OPTIMISER_FICHE: string = `champs_manquants`;
    static readonly FICHE_DOUBLON: string = `info_doublon`;
    static readonly CONFIRMATION_ACHAT: string = `Votre achat a bien été effectué. Un e-mail de confirmation vous a été envoyé.`;
}
