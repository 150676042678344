<div class="dashboard">
    <div class="container">
        <div class="bandeau_form_sm bandeau_form_title"><span class="icon exclamation"></span>Doublons
            ({{totalDuplicated|number}})
        </div>
        <div class="carte grande_carte">
            <div *ngFor="let stat of statSimilarities.reverse().slice(1)"
                 class="content_data"
                 [routerLink]="['/doublons', labelStats[stat.similarityLevel]?.level]"
            >
                <div class="data_personne row" *ngIf="totalDuplicated > 0; else noData">
                    <div>
                        <span [className]="'icon user_group  similarity_level_' + stat.similarityLevel"></span>
                        <span> {{stat.distinct|number}} {{labelStats[stat.similarityLevel].label}} </span>
                    </div>
                    <div class="detail">
                        <strong>{{stat.totalDistinctIds|number}} fiches </strong>
                        <span> à vérifier</span>
                    </div>
                </div>
                <ng-template #noData>
                    <h2 class="text-center text-regular">Aucun doublon</h2>
                </ng-template>
            </div>
        </div>
    </div>
</div>
