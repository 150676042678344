'use strict';

export class ErrorsGlobals {
    static readonly TIMEOUT = 'Pas de réponse ou temps d\'attente expiré';
    static readonly AUTH_EXPIRED = 'Vous avez été déconnecté.';
    static readonly INVALID_REQUEST = 'Requête invalide';
    static readonly ACCESS_DENIED = 'Vous n\'avez pas accès à la ressource demandée';
    static readonly NOT_FOUND = 'La page demandée est introuvable';
    static readonly ALREADY_EXISTS = 'La ressource que vous souhaitez créer existe déjà';
    static readonly FATAL = 'Erreur fatale';
}
