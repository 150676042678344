import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalConfirmExitComponent} from '../../layout/modal-confirm-exit/modal-confirm-exit.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {CampagneService} from '../../_services/campagne.service';
import {PersonGlobals, VisibiliteGlobals} from '../../../const-global/globals';
import {Campagne} from '../../../models/campagne';
import * as moment from 'moment';
import {NotifierService} from 'angular-notifier';
import {HelperService} from '../../_services/helper.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {MessageGlobals} from '../../../const-global/messages';
import {v4 as uuidv4} from 'uuid';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {EmailEditorComponent, UnlayerOptions} from 'angular-email-editor';
import {combineLatest, forkJoin, Observable, of, Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {OrganisationService} from '../../_services/organisation.service';
import {ModalService} from '../../_modal';
import {catchError, startWith} from 'rxjs/operators';
import {SegmentService} from '../../_services/segment.service';

@Component({
    selector: 'app-fiche-edit-campagne',
    templateUrl: './fiche-edit-campagne.component.html',
    styleUrls: ['./fiche-edit-campagne.component.scss'],
    animations: [
        trigger('opening', [
            state('opened',
                style({height: '200px'})
            ),
            state('closed',
                style({height: '0px'})
            ),
            transition('opened => closed', [
                animate('0.5s')
            ]),
            transition('closed => opened', [
                animate('0.5s')
            ])
        ])
    ]
})
export class FicheEditCampagneComponent implements OnInit, OnDestroy {

    faSpinner = faSpinner;

    public form: FormGroup;
    public blink = false;
    public title: string;
    public PERSON_GLOBALS = PersonGlobals;
    public campagne: Campagne;
    public newCampaign = false;
    public countEmails: { count: number, skipped: number };
    public intervenants: [];
    public timezones: Array<object>;
    public referent: [];
    public uuid: string;
    public countInProgress = false;
    public galleryOpened = false;
    public orgUuid: string;
    public scheduled: boolean;
    public minDate: string = new Date().toISOString().split('T')[0];

    allSegments = [];

    get message(): string {
        return this.form.get('body').value;
    }

    get filtres(): FormArray {
        return this.form.get('filtres') as FormArray;
    }

    get PercentageCoverage(): number {
        if (this.countEmails?.count && this.countEmails?.skipped) {
            return (this.countEmails?.count * 100) / (this.countEmails.count + this.countEmails?.skipped);
        } else if (!this.countEmails?.skipped) {
            return 100;
        }
        return 0;
    }

    private subscriptions = {
        segmentList: Subscription.EMPTY,
        organisation: Subscription.EMPTY,
        segmentEmailCount: Subscription.EMPTY
    };

    @ViewChild('confirmModal') confirmModal: ModalConfirmExitComponent;
    @ViewChild(EmailEditorComponent) private emailEditor: EmailEditorComponent;

    public editorLoadStatus = false;
    private modified: false;
    public replyTto: string;

    constructor(public router: Router,
                public route: ActivatedRoute,
                private location: Location,
                private formBuilder: FormBuilder,
                private campagneService: CampagneService,
                private service: SegmentService,
                private helperService: HelperService,
                private notifier: NotifierService,
                private ngxService: NgxUiLoaderService,
                private organisationService: OrganisationService,
                private modalService: ModalService
    ) {
    }

    /**
     * Basic unlayer editor options
     */
    public _options(): UnlayerOptions {
        return {
            projectId: 103653,
            locale: 'fr-FR',
        };
    }

    /**
     * used to set up the editor appearance
     */
    public appearance(): any {
        return {
            theme: 'dark',
        };
    }

    /**
     * Custom tools
     */
    public tools(): any {
        return {
            // TODO : check code commenté
            // image: {
            //     properties: {
            //         src: {
            //             value: {
            //                 url: 'https://via.placeholder.com/100x100?text=DEFAULT',
            //                 width: 100,
            //                 height: 100
            //             }
            //         }
            //     }
            // }
        };
    }

    /**
     * When editor is ready
     */
    editorReady(): void {
        this.emailEditor.editor.registerCallback('image', (file, done) => {
            this.campagneService.uploadImage(this.orgUuid, file, done);
        });

        if (this.campagne?.templateConfig?.toString() !== '[]' && !(this.campagne.templateConfig instanceof Array)) {
            this.emailEditor.editor.loadDesign(this.campagne.templateConfig);
        }

        if (this.route.snapshot.queryParamMap.get('uuid_template')) {
            this.campagneService.getTemplateCampagneByUuuid(
                this.route.snapshot.queryParamMap.get('uuid_template')
            ).subscribe((data: any) => {
                this.form.controls.libelle.setValue('Nouvelle campagne : ' + data.title);
                this.emailEditor.editor.loadDesign(data.template);
            });
        }
        this.editorLoadStatus = true;
    }

    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.timezones = this.generateTimezone();
        this.campagneService.countSegmentEmails.next(null);
        this.countEmails = {count: 0, skipped: 0};

        this.organisationService.getDatasOrganisation().subscribe(org => {
            this.orgUuid = org.dbId;
            if (org.dbId) {
                this.organisationService.getOrganisationSettings(this.orgUuid).subscribe((settings: any) => {
                    this.replyTto = settings?.replyTto || 'noreply@mail.crm.azko.app';
                });
            }
        });

        this.service.getUserSegmentsList().subscribe(segments => {
            const formattedDatas = [];
            segments.forEach(segment => {
                formattedDatas.push({
                    id: segment.uuid,
                    text: segment.titre
                });
            });
            this.allSegments = [...formattedDatas];
        });
// if route has ajouter or has ajouter and segment
        this.newCampaign = (this.router.routerState.snapshot.url.split('/').pop() === 'ajouter')
            || (typeof this.route.snapshot.queryParamMap.get('segment') !== 'undefined'
                && this.route.snapshot.queryParamMap.get('segment') !== null)
            || this.route.snapshot.queryParamMap.get('uuid_template') !== null;

        if (typeof this.route.parent.snapshot.params.id !== 'undefined') {
            this.helperService.getIntervenants(this.route.parent.snapshot.params.id, 'campagne').subscribe((intervenants) => {
                this.referent = intervenants.filter((intervenant: any) => intervenant.type === true);
                this.intervenants = intervenants.filter((intervenant: any) => intervenant.type !== true);
            });
        } else {
            this.referent = null;
            this.intervenants = [];
        }

        if (!this.newCampaign) {
            this.campagneService.getFicheCampaign(this.route?.parent?.snapshot.params.id).subscribe(cmp => {
                this.campagne = new Campagne(cmp);
                if (this.campagne.segments) {
                    const segmentsUuid = [];
                    this.campagne.segments.forEach((value: any) => {
                        segmentsUuid.push(value.uuid);
                    });
                    this.campagne.segments = segmentsUuid;
                }
                this.initForm();
            }, () => {
                this.countInProgress = false;
            }, () => {
                this.countInProgress = true;
                this.campagneService.getContactsCountFromSegments(this.campagne?.uuid, this.campagne.segments);
            });
        } else {
            const segmentsTmp = [];
            if (this.route.snapshot.queryParamMap.get('segment')) {
                segmentsTmp.push(this.route.snapshot.queryParamMap.get('segment'));
                this.countEmails = {count: 0, skipped: 0};
                this.campagneService.getContactsCountFromSegments(this.campagne?.uuid, segmentsTmp);
            }

            this.campagne = new Campagne({
                uuid: uuidv4(),
                actif: true,
                visibilite: VisibiliteGlobals.PUBLIC,
                segments: segmentsTmp ? segmentsTmp : []
            });
            this.initForm();
        }
        // counter
        this.subscriptions.segmentEmailCount = this.campagneService.countSegmentEmails.subscribe((count: any) => {
            this.countEmails = count?.counters;
            this.countInProgress = false;
        });
    }

    generateTimezone(): Array<object> {
        // @ts-ignore
        const aryIanaTimeZones = Intl.supportedValuesOf('timeZone');
        const timezone: Array<object> = [];
        aryIanaTimeZones.forEach((timeZone) =>
        {
            timezone.push({id: timeZone, text: timeZone.replaceAll('_', ' ').replaceAll('/', ', ')});
        });
        return timezone;
    }
    private initForm(): void {
        let dateDenvoi = '';
        let heureDenvoi = '';
        let fuseauHoraire = this.campagne.fuseauHoraire
            ? this.campagne.fuseauHoraire
            : Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (this.campagne?.scheduledAt) {
            dateDenvoi = moment.utc(this.campagne?.scheduledAt.date).format('YYYY-MM-DD');
            heureDenvoi = moment.utc(this.campagne?.scheduledAt.date).format('HH:mm');
            fuseauHoraire = this.campagne?.scheduledAt.timezone;
        }

        this.form = this.formBuilder.group({
            uuid: [this.campagne.uuid],
            visibilite: [this.campagne?.visibilite, Validators.required],
            actif: [this.campagne?.actif],
            intervenants: [this.campagne?.intervenants],
            referent: [this.campagne?.referent],
            libelle: [this.campagne?.libelle, Validators.required],
            fuseauHoraire: [fuseauHoraire, Validators.required],
            campagneParente: [this.campagne?.linked],
            relance: [this.campagne?.relance],
            from: [this.campagne?.from, Validators.required],
            subject: [this.campagne?.subject, Validators.required],
            body: [this.campagne?.body, Validators.required],
            templateConfig: [this.campagne?.templateConfig, Validators.required],
            filtres: this.formBuilder.array([]),
            segments: [this.campagne?.segments || []],
            dateEnvoi: dateDenvoi,
            heureDebut: heureDenvoi
        });

        // Dynamic Form Controls
        this.form.controls.segments.valueChanges
            .subscribe(segmentsIds => {
                    if (this.campagne?.uuid && segmentsIds) {
                        this.countInProgress = true;
                        this.campagneService.getContactsCountFromSegments(this.campagne?.uuid, segmentsIds);
                    }
                }
            );

        combineLatest([
            this.form.get('dateEnvoi').valueChanges.pipe(startWith(dateDenvoi as string)),
            this.form.get('heureDebut').valueChanges.pipe(startWith(heureDenvoi as string)),
        ]).subscribe(([dateEnvoi, heureDebut]) => {
            this.scheduled = !!(dateEnvoi || heureDebut);
            ['dateEnvoi', 'heureDebut', 'segments'].forEach(field => {
                this.form.get(field).setValidators(this.scheduled ? Validators.required : []);
                this.form.get(field).updateValueAndValidity({emitEvent: false});
            });
        });

        this.ngxService.stop();
    }

    /**
     * Gen html from template
     */
    public exportHtml(): Observable<any> {
        return new Observable(obs => {
            this.emailEditor.editor.exportHtml((data) => {
                this.form.controls.body.setValue(data.html);
                obs.next(data.html);
                obs.complete();
            });
        });
    }

    /**
     * save template config
     */
    public saveDesign() {
        const self = this;
        return new Observable(obs => {
            this.emailEditor.editor.saveDesign((data) => {
                    self.form.get('templateConfig').setValue(data || {});
                    obs.next(data || {});
                    obs.complete();
                }
            );
        });
    }

    /**
     * Performs the export and save for the template
     */
    public exportAndSave(): Observable<any> {
        this.ngxService.start();
        return forkJoin({
            html: this.exportHtml(),
            design: this.saveDesign()
        }).pipe(catchError(error => of(error)));
    }

    public onSubmit(exit = false): void {
        this.exportAndSave().subscribe(() => {
                this.form.updateValueAndValidity();
                this.confirmModal.modified.next(false);

                if (!this.form.valid) {
                    this.notifier.notify('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);

                    return;
                }

                if (this.form.get('segments').value.length && !this.countEmails?.count) {
                    this.notifier.notify('error', 'Veuillez sélectionner un segment dont la prévision d\'envoi est supérieure à 0');

                    return;
                }

                this.ngxService.start();
                const campagne = new Campagne(this.form.value);
                if (this.newCampaign) {
                    // new campaign
                    this.campagneService.createCampaign(campagne).subscribe(
                        res => {
                            this.router.onSameUrlNavigation = 'reload';
                            if (!exit) {
                                this.router.navigate(['campagne/' + res.uuid + '/modifier']);
                                return;
                            }
                            this.router.navigate(['campagne/' + res.uuid]);
                            this.notifier.notify('success', MessageGlobals.MESSAGE_SUCCESS);
                            exit && this.exit(res.uuid);
                        },
                        error => {
                            this.notifier.notify('error', error?.error.detail ? error?.error.detail : error?.error.message);
                        },
                        () => {
                            this.campagneService.refreshCampaignData(campagne.uuid);
                        }
                    );
                } else {
                    // update
                    this.campagneService.updateCampaign(campagne).subscribe(
                        () => {
                            this.ngxService.stop();
                            this.notifier.notify('success', MessageGlobals.MESSAGE_MODIFICATION);
                            exit && this.exit(campagne.uuid);
                        },
                        error => {
                            this.ngxService.stop();
                            this.notifier.notify('error', error?.error.detail ? error?.error.detail : error?.error.message);
                        },
                        () => {
                            this.campagneService.refreshCampaignData(campagne.uuid);
                        }
                    );
                }

            }, error => {
                console.error(error);
            },
            () => {
                this.ngxService.stop();
            });
    }

    public onCancel(): void {
        if (this.modified) {
            this.confirmModal.openConfirmationModal();
        }
        if (!this.confirmModal.isOpened()) {
            this.router.onSameUrlNavigation = 'reload';
            if (this.route.snapshot.params.id) {
                this.router.navigate(['campagne/' + this.route.snapshot.params.id]);
            } else {
                this.location.back();
            }
        }
    }

    exit(uuid) {
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['campagne/' + uuid]);
    }

    public addFilter(): void {
        // todo : remove comments to allow filters
        // this.filtres.push(this.formBuilder.control([]));
    }

    public removeFilter(id): void {
        this.filtres.removeAt(id);
    }

    /** fired when CSS animation ends */
    animationEnd(): void {
        this.campagneService.campaignUpdatedSubject.next(false);
    }

    /**
     * modal
     */
    openGallery(): void {
        this.campagneService.getGallery(this.orgUuid);
        this.modalService.open('modal-gallery');
    }

    /**
     * modal
     */
    closeGallery(): void {
        this.modalService.close('modal-gallery');
    }
}
