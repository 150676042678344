<ngx-ui-loader></ngx-ui-loader>
<div class="dashboard">
    <div class="bloc_gauche">
        <div class="container container_demi_carte">
            <div>
                <div class="carte demi_carte">
                    <div class="bloc_icon icon addresse_card bandeau_personne_physique"
                         (click)="filterRepertoire({statut: PERSON_GLOBALS.PERSON_STATUT_CLIENT})">
                        Clients
                    </div>
                    <div class="bloc_data bloc_stats" *ngIf="nbPersonne">
                        <div class="content_data">
                            <p class="data_carte"
                               (click)="filterRepertoire({statut: 'client', visibility: [VISIBILITY_GLOBALS.PUBLIC, VISIBILITY_GLOBALS.PROTECTED]})">
                                <span class="icon unlock color-public">{{ shortNumber(nbPersonne['client']['public']) }}</span>
                            </p>
                            <p class="stats_carte up">
                                <span *ngIf="statsClientPublic > 0" class="icon unlock small color-public">+</span>
                                {{ statsClientPublic }}
                            </p>
                        </div>
                        <div class="content_data">
                            <p class="data_carte right" (click)="filterRepertoire({statut: PERSON_GLOBALS.PERSON_STATUT_CLIENT, visibility: [VISIBILITY_GLOBALS.PRIVATE]})">
                                <span class="icon lock red">{{ shortNumber(nbPersonne['client']['prive']) }}</span>
                            </p>
                            <p class="stats_carte up right">
                                <span *ngIf="statsClientPrive > 0" class="icon lock red small">+</span>
                                {{ statsClientPrive }}
                                <span class="icon question"
                                      matTooltip="Nombre de clients gagnés sur le mois N-1"
                                      matTooltipClass="tooltip-default"
                                      aria-label="tooltip stats dashboard"
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="carte demi_carte">
                    <div class="bloc_icon icon addresse_card bandeau_personne_physique"
                         (click)="filterRepertoire({statut: 'prospect'})">
                        Prospects
                    </div>
                    <div class="bloc_data bloc_stats" *ngIf="nbPersonne">
                        <div class="content_data">
                            <p class="data_carte"
                               (click)="filterRepertoire({statut: PERSON_GLOBALS.PERSON_STATUT_PROSPECT, visibility: [VISIBILITY_GLOBALS.PUBLIC, VISIBILITY_GLOBALS.PROTECTED]})">
                                <span class="icon unlock color-public">{{ shortNumber(nbPersonne['prospect']['public'])  }}</span>
                            </p>
                            <p class="stats_carte up">
                                <span *ngIf="statsProspectPublic > 0" class="icon unlock small color-public">+</span>
                                {{ statsProspectPublic }}
                            </p>
                        </div>
                        <div class="content_data">
                            <p class="data_carte right" (click)="filterRepertoire({statut: PERSON_GLOBALS.PERSON_STATUT_PROSPECT, visibility: [VISIBILITY_GLOBALS.PRIVATE]})">
                                <span class="icon lock red">{{ shortNumber(nbPersonne['prospect']['prive']) }}</span>
                            </p>
                            <!--TODO : calculer le nb par rapport au mois n - 1-->
                            <p class="stats_carte up right">
                                <span *ngIf="statsProspectPrive > 0" class="icon lock red small">+</span>
                                {{ statsProspectPrive }}
                                <span class="icon question"
                                      matTooltip="Nombre de prospects gagnés sur le mois N-1"
                                      matTooltipClass="tooltip-default"
                                      aria-label="tooltip stats dashboard"
                                ></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!--
                        <div>
                            <div class="carte demi_carte">
                                <div class="bloc_icon icon offre bandeau_offre">
                                    Offres
                                </div>
                                <div class="bloc_data en_cours">
                                    <p>En cours de développement...</p>
                                </div>
                            </div>
                        </div>
            -->
        </div>
        <div class="container container_grande_carte">
            <div *ngIf="personnes">
                <div class="bandeau_form_sm bandeau_personne_physique">Contacts récents</div>
                <div class="carte grande_carte personne" *ngIf="personnes.length > 0">
                    <div>
                        <div class="content_data" *ngFor="let personne of personnes; let i = index">
                            <div *ngIf="i < 3">
                                <div class="icon data_personne {{ personne.type }} row"
                                     [ngClass]="{
                                 'building': personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE,
                                 'user': personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE ||
                                         personne.type === PERSON_GLOBALS.PERSON_LIEN
                                 }"
                                     (click)="openFichePersonne(personne.uuid)"
                                >
                                    <ng-template [ngTemplateOutlet]="labelPersons"
                                                 [ngTemplateOutletContext]="{'personne': personne}">
                                    </ng-template>
                                </div>
                                <hr class="hr_custom" *ngIf="i !== 2">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="content_data" *ngFor="let personne of personnes; let i = index">
                            <div *ngIf="i >= 3 && i <= 5">
                                <div class="icon data_personne {{ personne.type }} row"
                                     [ngClass]="{
                                 'building': personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE,
                                 'user': personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE ||
                                         personne.type === PERSON_GLOBALS.PERSON_LIEN
                                 }"
                                     (click)="openFichePersonne(personne.uuid)"
                                >
                                    <ng-template [ngTemplateOutlet]="labelPersons"
                                                 [ngTemplateOutletContext]="{'personne': personne}">
                                    </ng-template>
                                </div>
                                <hr class="hr_custom" *ngIf="i !== 5">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carte grande_carte carte_empty" *ngIf="personnes.length === 0">
                    <div class="bloc_data empty">
                        <p>Aucun contact récent</p>
                    </div>
                </div>
            </div>
            <div *ngIf="segments">
                <div class="bandeau_form_sm bandeau_segment">Segments récents</div>
                <div class="carte grande_carte segment" *ngIf="segments.length > 0">
                    <div>
                        <div class="content_data" *ngFor="let segment of segments; let i = index">
                            <div class="icon filter data_segment row"
                                 (click)="openFicheSegment(segment.uuid)">
                                <span>{{ segment.titre }}</span>
                                <span class="info_droit">
                                    <span *ngIf="segment.derniereDate">{{ segment.derniereDate | date:'d/MM/y' }}
                                        - </span>
                                    <span *ngIf="segment.nbContactsPublics">{{ segment.nbContactsPublics }}</span>
                                    <span *ngIf="segment.nbContactsPrives"> + ({{ segment.nbContactsPrives }})</span>
                                    <span *ngIf="segment.nbContactsPublics || segment.nbContactsPrives"> contacts</span>
                                </span>
                            </div>
                            <hr class="hr_custom" *ngIf="i !== segments.length -1">
                        </div>
                    </div>
                </div>
                <div class="carte grande_carte carte_empty" *ngIf="segments.length === 0">
                    <div class="bloc_data empty">
                        <p>Aucun segment</p>
                    </div>
                </div>
            </div>
            <div>
                <div class="bandeau_form_sm bandeau_campagne">Campagnes récentes</div>
                <!--            TODO : css provisoire-->
                <ngx-ui-loader [loaderId]="'campagne'"></ngx-ui-loader>
                <div class="carte grande_carte campagne" *ngIf="campagnes.length > 0">
                    <div>
                        <div class="content_data" *ngFor="let campagne of campagnes; let i = index">
                            <div class="row">
                                <div class="icon mail data_campagne"
                                     (click)="openCampaign(campagne)">
                                    <span class="libelle">{{ campagne.libelle }}</span>
                                    <span class="info_droite">
                                        <span class="bold">{{ campagne.contacts }}</span> destinataire(s) | <span
                                            class="blue-azko">{{ campagne.sent }}</span> envoyé(s) | <span
                                            class="orange">{{ campagne.opened }}</span> ouvert(s) | <span
                                            class="vert">{{ campagne.clicked }}</span> cliqué(s)
                                    </span>
                                </div>
                            </div>
                            <hr class="hr_custom" *ngIf="i !== campagnes.length -1">
                        </div>
                    </div>
                </div>
                <div class="carte grande_carte" style="height: 130px; display: flex;" *ngIf="campagnes.length === 0">
                    <div class="bloc_data en_cours">
                        <p>Aucune campagne</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bloc_droit">
        <div class="container container_petite_carte">
            <div class="carte petite_carte">
                <div class="bloc_icon icon euro bandeau_offre">
                    Chiffres <br>
                    d'affaires
                </div>
                <div class="bloc_data en_cours">
                    <p>En cours de développement...</p>
                </div>
            </div>
        </div>
        <div class="container container_grande_carte">
            <div *ngIf="events">
                <div class="bandeau_form_sm bandeau_form_title icon clipboard_list">Tâches
                    <div class="nb_event_a_faire">{{ nbEventAFaire }} à faire</div>
                </div>
                <div class="carte grande_carte event" *ngIf="events.length > 0">
                    <div *ngIf="events">
                        <div class="content_data container_event"
                             *ngFor="let event of events; let i = index"
                             routerLink="/repertoire/modifier/evenement/{{event.uuid}}"
                             [queryParams]="{dashboard: 'true'}"
                        >
                            <div *ngIf="event.statut === 1">
                                <div class="icon data_event row">
                                    <div class="indication_status icon"
                                         [ngClass]="{
                                                 'calendrier' : event.type.id === EVENEMENT_GLOBALS.RDV_CABINET_ID || event.type.id === EVENEMENT_GLOBALS.RDV_EXTERIEUR_ID,
                                                 'phone' : event.type.id === EVENEMENT_GLOBALS.APPEL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.APPEL_SORTANT_ID,
                                                 'mail' : event.type.id === EVENEMENT_GLOBALS.MAIL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.MAIL_SORTANT_ID,
                                                 'devis' : event.type.id === EVENEMENT_GLOBALS.DEVIS_ID,
                                                 'changement_statut' : event.type.id === EVENEMENT_GLOBALS.CHANGEMENT_STATUT_ID,
                                                 'icon_blue' : event.planifie,
                                                 'icon_orange' : event.aujourdhui === true,
                                                 'icon_red' : event.enRetard === true
                                                }">
                                    </div>
                                    <div class="libelle_event">
                                        <h2 [ngClass]="{
                                                     'haut' : event.type.id === EVENEMENT_GLOBALS.APPEL_SORTANT_ID || event.type.id === EVENEMENT_GLOBALS.MAIL_SORTANT_ID || event.type.id === EVENEMENT_GLOBALS.DEVIS_ID || event.type.id === EVENEMENT_GLOBALS.RDV_EXTERIEUR_ID,
                                                     'bas' : event.type.id === EVENEMENT_GLOBALS.APPEL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.MAIL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.CHANGEMENT_STATUT_ID || event.type.id === EVENEMENT_GLOBALS.RDV_CABINET_ID,
                                                     'color_blue' : event.planifie,
                                                     'color_orange' : event.aujourdhui === true,
                                                     'color_red' : event.enRetard === true
                                                   }"
                                            class="libelle icon">
                                            {{event.titre}}
                                        </h2>
                                        <span *ngIf="event.description; else aucuneDescription">{{event.description}}</span>
                                        <ng-template #aucuneDescription>
                                            <span class="without_data">Aucune description</span>
                                        </ng-template>
                                    </div>
                                    <!-- <div class="icon data_personne {{ event.userAssigne.typePersonne }} row event_personne"-->
                                    <!--    [ngClass]="{-->
                                    <!--            'building': event.userAssigne.typePersonne === 'morale' ||-->
                                    <!--                        event.userAssigne.typePersonne === 'lien',-->
                                    <!--            'user': event.userAssigne.typePersonne === 'physique'-->
                                    <!--    }"-->
                                    <!-- <span>{{ event.lien.nom }} {{ event.userAssigne.typePersonne }}</span>-->
                                    <div class="icon data_personne {{ event.lien.type }} row event_personne"
                                         [ngClass]="{
                                                 'building': event.lien.type === PERSON_GLOBALS.PERSON_LIEN_MORALE ||
                                                             event.lien.type === PERSON_GLOBALS.PERSON_LIEN,
                                                 'user': event.lien.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE
                                                 }"
                                    ><span>{{event.lien.libelle}}</span>
                                    </div>
                                    <div class="event_date">
                                        <div class="icon_statut">
                                            <p class="icon clock"
                                               [ngClass]="{
                                                        'planifie icon_blue' : event.planifie,
                                                        'aujourdhui icon_orange' : event.aujourdhui,
                                                        'enRetard icon_red' : event.enRetard
                                                    }">
                                            </p>
                                        </div>
                                        <div class="date_statut">
                                        <span class="color_blue" *ngIf="event.planifie">Planifié
                                            le {{event.dateEcheance  | date : fullDateHeure}}</span>
                                            <span class="color_orange" *ngIf="event.aujourdhui">Planifié
                                            aujourd'hui
                                            à {{event.dateEcheance  | date : heureMinute}}</span>
                                            <span class="color_red" *ngIf="event.enRetard">En retard
                                            depuis {{event.dateEcheanceCalc}}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr class="hr_custom" *ngIf="i !== events.length - 1">
                            </div>
                        </div>
                        <div class="action_dashboard">
                            <div class="btn btn-med icon list"
                                 (click)="openRappel(uuidPersonneLienConnecte, 'tache')">Voir plus
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carte grande_carte carte_empty" *ngIf="events.length === 0">
                    <div class="bloc_data empty">
                        <p>Aucune tâche récente</p>
                    </div>
                </div>
            </div>
            <div *ngIf="personnes">
                <div class="bandeau_form_sm bandeau_form_title icon i_idea" *ngIf="nbFicheIncomplete">Fiche(s)
                    à optimiser ({{ nbFicheIncomplete }})
                </div>
                <div class="carte grande_carte fiche_incomplete" *ngIf="personnes.length > 0">
                    <div>
                        <div class="content_data" *ngFor="let personne of personnesIncomplete; let i = index">
                            <div *ngIf="!personne.isComplete"
                                 class="icon data_personne {{ personne.type }} row"
                                 [ngClass]="
                                 {
                                     'building': personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE,
                                     'user': personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE  || personne.type === PERSON_GLOBALS.PERSON_LIEN
                                 }"
                                 (click)="openFichePersonne(personne.uuid)">
                                <ng-template [ngTemplateOutlet]="labelPersons"
                                             [ngTemplateOutletContext]="{'personne': personne}">
                                </ng-template>
                                <!--                                <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE">-->
                                <!--                                    {{ personne.personnePhysiqueLibelle }}-->
                                <!--                                </span>-->
                                <!--                                <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE">-->
                                <!--                                    {{ personne.personneMoraleLibelle }}-->
                                <!--                                </span>-->
                                <!--                                <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN">-->
                                <!--                                    {{ personne.personnePhysiqueLibelle }}, {{ personne.personneMoraleLibelle }}-->
                                <!--                                </span>-->
                            </div>
                            <hr class="hr_custom" *ngIf="i !== personnes.length - 1 && !personne.isComplete">
                        </div>
                        <div class="action_dashboard">
                            <div class="btn btn-med icon list"
                                 (click)="openRappel(uuidPersonneLienConnecte, 'fiche')">Voir plus
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carte grande_carte carte_empty" *ngIf="personnes.length === 0">
                    <div class="bloc_data empty">
                        <p>Aucune fiche à optimiser</p>
                    </div>
                </div>
            </div>
            <app-duplication-overview></app-duplication-overview>
        </div>
    </div>
</div>
<ng-template #labelPersons let-personne="personne">
    <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE">
        {{ personne.personnePhysiqueLibelle }}
    </span>
    <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE">
        {{ personne.personneMoraleLibelle }}
    </span>
    <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN">
        {{ personne.personnePhysiqueLibelle }}, {{personne.fonctionLibelle}}, {{ personne.personneMoraleLibelle }}
    </span>
</ng-template>
