import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {ModalConfirmExitComponent} from '../../layout/modal-confirm-exit/modal-confirm-exit.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Lead, LeadService} from '../../_services/lead.service';
import {ParametreGlobals, PersonGlobals} from '../../../const-global/globals';
import {NotifierService} from 'angular-notifier';
import {HelperService} from '../../_services/helper.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {EmailEditorComponent} from 'angular-email-editor';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SegmentService} from '../../_services/segment.service';
import {Devis} from '../../../models/devis';
import {IProduct} from '../../../interfaces/product';
import {MessageGlobals} from '../../../const-global/messages';
import {DevisService} from '../../_services/devis.service';
import {DevisligneService} from '../../_services/devisligne.service';
import {MemoService} from '../../_services/memo.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Devisligne} from '../../../models/devisligne';

@Component({
    selector: 'app-fiche-edit-offre',
    templateUrl: './fiche-edit-offre.component.html',
    styleUrls: ['./fiche-edit-offre.component.scss'],
    animations: [
        trigger('opening', [
            state('opened',
                style({height: '200px'})
            ),
            state('closed',
                style({height: '0px'})
            ),
            transition('opened => closed', [
                animate('0.5s')
            ]),
            transition('closed => opened', [
                animate('0.5s')
            ])
        ])
    ]
})

export class FicheEditOffreComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public title: string;
    public PERSON_GLOBALS = PersonGlobals;
    public offre: Lead;
    public intervenants: [];
    public referent: [];
    public uuid: string;
    public newUuidLead: string;
    public orgUuid: string;
    public nombreLignes = 0;
    public newLibelle = true;
    public createMode = false;
    public creatingLigne = false;
    private subscriptions = {
        segmentList: Subscription.EMPTY,
        organisation: Subscription.EMPTY,
        segmentEmailCount: Subscription.EMPTY
    };
    public tagTarget = ParametreGlobals.TAG_ID_OFFRE;
    public DECIMALS_PATTERN = '^[-]?([0-9]+[\\s]?)*[\.,]?[0-9]{0,2}$';
    public POSITIVE_DECIMALS_PATTERN = '^([0-9]+[\\s]?)*[\.,]?[0-9]{0,2}$';
    public INT_PATTERN = '^[0-9]+$';
    public inputLabels = {
        prixUnitaireHT: 'Prix unitaire HT',
        quantite: 'Quantité',
        remise: 'Remise',
        remiseHT: 'Remise ',
        tauxTVA: 'Taux TVA'
    };
    @ViewChild('confirmModal') confirmModal: ModalConfirmExitComponent;
    @ViewChild(EmailEditorComponent)
    private emailEditor: EmailEditorComponent;
    public monthsData: any;
    public priorityData: any;
    public errorMessages = [];

    constructor(public router: Router,
                public route: ActivatedRoute,
                private location: Location,
                private formBuilder: FormBuilder,
                private leadService: LeadService,
                private devisService: DevisService,
                private devisLigneService: DevisligneService,
                private service: SegmentService,
                private helperService: HelperService,
                private memoService: MemoService,
                private notifier: NotifierService,
                private ngxService: NgxUiLoaderService,
    ) {
    }


    get formLignes(): FormArray {
        return this.form?.controls.devis.get('lignes') as FormArray;
    }

    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }

    ngOnInit(): void {
        const formattedDatas = [];
        const dureesMois = [
            {
                id: 1,
                text: '1 mois'
            },
            {
                id: 2,
                text: '2 mois'
            },
            {
                id: 3,
                text: '3 mois'
            },
            {
                id: 6,
                text: '6 mois'
            },
            {
                id: 12,
                text: '12 mois'
            },
            {
                id: 24,
                text: '24 mois'
            },
        ];
        const priorites = [
            {
                id: 4,
                text: 'Urgente',
                icon: 'critical'
            },
            {
                id: 3,
                text: 'Elevée',
                icon: 'high'
            },
            {
                id: 2,
                text: 'Normale',
                icon: 'medium'
            },

            {
                id: 1,
                text: 'Faible',
                icon: 'low'
            }
        ];
        dureesMois.forEach(month => {
            formattedDatas.push({
                id: month.id,
                text: month.text
            });
        });
        this.monthsData = [...formattedDatas];
        const formattedDatasPrio = [];

        priorites.forEach(prio => {
            formattedDatasPrio.push({
                id: prio.id,
                text: prio.text,
                icon: prio.icon
            });
        });
        this.priorityData = [...formattedDatasPrio];

        if (this.route?.parent?.snapshot.params.id) {
            this.leadService.detail(this.route?.parent?.snapshot.params.id).subscribe(lead => {
                this.offre = new Lead(lead);
                this.offre.referent = null;
                this.offre.intervenants = [];
                this.helperService.getIntervenants(this.route?.parent?.snapshot.params.id, 'lead')
                    .subscribe((intervenants) => {
                        intervenants.forEach(intervenant => {
                            if (intervenant.type) {
                                this.offre.referent = intervenant.uuid;
                            } else {
                                this.offre.intervenants.push(intervenant.uuid.toString());
                            }
                        });
                        this.initForm();
                    });
            });
        } else {
            this.createMode = true;
            this.offre = new Lead();
            this.offre.tags = [];
            this.offre.devis = new Devis();
            this.offre.devis.lignes = [];
            this.offre.memos = [];
            this.offre.visibilite = 1;
            this.offre.referent = null;
            this.offre.intervenants = [];
            this.offre.active = true;
            this.initForm();
        }
    }

    private initForm(): void {
        this.offre?.memos.sort((a, b) => (a.dateModification < b.dateModification) ? 1 : -1);
        this.form = this.formBuilder.group({
                uuid: [this.offre.uuid],
                libelle: [this.offre?.libelle, Validators.required],
                priorite: [this.offre?.priorite?.id],
                tags: [this.offre?.tags],
                memos: [this.offre?.memos],
                devis: this.formBuilder.group({
                    uuid: [this.offre?.devis?.uuid],
                    reference: [{value: this.offre?.devis?.reference, disabled: true}],
                    referencePerso: [this.offre?.devis?.referencePerso],
                    dureeValidite: [this.offre?.devis?.dureeValidite],
                    libelle: [this.offre?.devis?.libelle],
                    commentaire: [this.offre?.devis?.commentaire],
                    lignes: this.formBuilder.array([]),
                    montantHT: [
                        {
                            value: this.offre?.devis?.montantHT
                                ? this.formatCurrency(this.offre?.devis?.montantHT / 100)
                                : this.formatCurrency(0),
                            disabled: true
                        }
                    ],
                    montantTTC: [
                        {
                            value: this.offre?.devis?.montantTTC
                                ? this.formatCurrency(this.offre?.devis?.montantTTC / 100)
                                : this.formatCurrency(0),
                            disabled: true
                        }
                    ],
                    montantsTVA: [this.offre?.devis?.montantsTVA],
                }),
                visibilite: [this.offre?.visibilite, Validators.required],
                intervenants: [this.offre?.intervenants],
                referent: [this.offre?.referent],
                actif: [this.offre?.active],
                contact: [this.offre?.contact, Validators.required],
                newProduct: []
            }, {}
        );
        this.offre.devis.montantHT = this.form.controls.devis.get('montantHT').value;
        this.offre.devis.montantTTC = this.form.controls.devis.get('montantTTC').value;

        Object.keys(this.offre?.devis?.lignes).map((key) => {
            this.offre.devis.lignes[key] = new Devisligne(this.offre?.devis?.lignes[Number(key)]);
            this.formLignes.push(this.formBuilder.group({
                uuid: [this.offre?.devis?.lignes[Number(key)].uuid],
                libelle: [this.offre?.devis?.lignes[Number(key)].libelle],
                produitId: [this.offre?.devis?.lignes[Number(key)].produitId],
                produitUuid: [this.offre?.devis?.lignes[Number(key)].produit?.uuid],
                prixUnitaireHT: [
                    this.formatCurrency(
                        Number(this.offre?.devis?.lignes[Number(key)].prixUnitaireHT) / 100
                    ),
                    Validators.pattern(this.POSITIVE_DECIMALS_PATTERN),
                ],
                quantite: [this.offre?.devis?.lignes[Number(key)].quantite, Validators.pattern(this.INT_PATTERN)],
                tauxTVA: [
                    (this.offre?.devis?.lignes[Number(key)].tauxTVA / 100).toLocaleString(
                        'fr-FR',
                        {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                    [
                        Validators.pattern(this.POSITIVE_DECIMALS_PATTERN),
                        customMinMaxValidator(100),
                    ]
                ],
                totalHT: [
                    {
                        value: this.formatCurrency(this.offre?.devis?.lignes[Number(key)].totalHT / 100),
                        disabled: true
                    },
                    Validators.pattern(this.POSITIVE_DECIMALS_PATTERN),
                ],
                totalTTC: [
                    {
                        value:
                            this.formatCurrency(this.offre?.devis?.lignes[Number(key)].totalTTC / 100),
                        disabled: true
                    },
                    Validators.pattern(this.POSITIVE_DECIMALS_PATTERN),
                ],
                produit: [this.offre?.devis?.lignes[Number(key)].produit],
                poids: [this.offre?.devis?.lignes[Number(key)].poids],
                remise: [
                    (-this.offre?.devis?.lignes[Number(key)].remise / 100).toLocaleString(
                        'fr-FR',
                        {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                    [
                        Validators.pattern(this.DECIMALS_PATTERN),
                        customMinMaxValidator(100)
                    ]
                ],
                remiseHT: [
                    this.formatCurrency(-this.offre?.devis?.lignes[Number(key)].remiseHT / 100)
                ],
                typeRemise: [this.offre?.devis?.lignes[Number(key)].typeRemise],

            }));
            this.offre.devis.lignes[key].remiseDisabled =
                this.offre.devis.lignes[key].typeRemise > 0 && this.offre.devis.lignes[key].typeRemise !== 1;
            this.offre.devis.lignes[key].remiseHTDisabled =
                this.offre.devis.lignes[key].typeRemise > 0 && this.offre.devis.lignes[key].typeRemise !== 2;
            this.nombreLignes++;
        });
        this.formLignes.controls.forEach((ligne, index) => {
            ligne.get('remiseHT').setValidators(
                [Validators.pattern(this.DECIMALS_PATTERN),
                    this.remiseMinMaxValidator(this.formLignes.controls[index])]
            );
        });
        this.ngxService.stop();
    }

    public changeProduct(product: IProduct, index: number): void {
        this.offre.devis.lignes[index].produit = product;
        const ligne = this.formLignes.controls[index];
        ligne.get('libelle').setValue(product.libelle);
        if (product.prixUnitaire) {
            ligne.get('produitUuid').setValue(product.uuid);
            ligne.get('prixUnitaireHT').setValue(this.formatCurrency(Number(product.prixUnitaire) / 100));
            ligne.get('prixUnitaireHT').setValidators([
                Validators.pattern(this.POSITIVE_DECIMALS_PATTERN)
            ]);
            ligne.get('tauxTVA').setValue((Number(product.tva) / 100).toLocaleString(
                'fr-FR',
                {minimumFractionDigits: 2, maximumFractionDigits: 2}));
            ligne.get('tauxTVA').setValidators([
                Validators.pattern(this.POSITIVE_DECIMALS_PATTERN),
                customMinMaxValidator(100),
            ]);
            ligne.get('quantite').setValue(1);
            ligne.get('quantite').setValidators([
                Validators.pattern(this.INT_PATTERN)
            ]);
            ligne.get('remise').setValue(Number(-0).toLocaleString(
                'fr-FR',
                {minimumFractionDigits: 2, maximumFractionDigits: 2}));
            ligne.get('remise').setValidators([
                Validators.pattern(this.DECIMALS_PATTERN),
                customMinMaxValidator(100)
            ]);
            ligne.get('remiseHT').setValue(this.formatCurrency(-0));
            ligne.get('remiseHT').setValidators([
                Validators.pattern(this.DECIMALS_PATTERN),
                this.remiseMinMaxValidator(ligne),
            ]);
            ligne.get('totalHT').setValidators([
                Validators.pattern(this.POSITIVE_DECIMALS_PATTERN)
            ]);
            ligne.get('totalTTC').setValidators([
                Validators.pattern(this.POSITIVE_DECIMALS_PATTERN)
            ]);
            this.calculerDevis();
        }
    }

    private formatCurrency(numberData: number): string {
        return numberData.toLocaleString(
            'fr-FR',
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );
    }

    public onChangedSelect2($event, type): void {
        if (type === 'tags') {
            const newTags = [];
            $event.forEach((item) => {
                newTags.push({uuid: item});
            });
            this.form.controls.tags.setValue(newTags);
        }
    }

    public onSubmit(cancel): void {
        if (this.form.valid) {
            this.ngxService.start();
            this.calculerDevis();
            const dataMemos = {
                exists: [],
                new: []
            };
            this.offre?.memos?.sort((a, b) =>
                (a.dateModification < b.dateModification) ? 1 : -1);
            this.offre?.memos?.forEach((item) => {
                if (item['@id']) {
                    dataMemos.exists.push(item['@id']);
                } else {
                    dataMemos.new.push(item);
                }
            });
            const dataTags = [];
            this.form.controls.tags.value.forEach((item) => {
                dataTags.push('/api/v1/tags/' + item.uuid);
            });

            const data = this.form.value;
            if (data.uuid) {
                this.addNewMemos(data.uuid, dataMemos);
                this.updateLead(data, dataTags, cancel);
                this.ngxService.stop();
            } else {
                this.createDevisLignes(data, dataTags, dataMemos, cancel);
                this.ngxService.stop();
            }
        }
    }

    private addNewMemos(leadUuid, dataMemos): void {
        const dataNewMemos = [];
        dataMemos.new.forEach((newMemo) => {
            dataNewMemos.push({
                texte: newMemo.texte,
                persist: true,
                uuid: leadUuid
            });
        });
        this.memoService.addMultiple(dataNewMemos).subscribe(result => {
        });
    }

    private updateLead(data, dataTags, cancel): void {
        this.updateIntervenants(data.uuid, data.referent, data.intervenants);
        const dataLead = new Lead({
            uuid: data.uuid,
            libelle: data.libelle,
            priorite: data.priorite ? data.priorite : 0,
            contact: '/api/v1/personne_liens/' + data.contact.uuid,
            visibilite: data.visibilite,
            active: Number(data.actif) !== 0,
            tags: dataTags,
        });
        this.leadService.update(dataLead.uuid, dataLead).subscribe(result => {
        });
        this.updateDevisLignes(data, cancel);
    }

    formatFrenchDecimalsToInt(data): any {
        return Number(((Number(data.replace(/\s|-/g, '').replace(',', '.'))) * 100).toFixed());
    }

    private updateDevisLignes(data, cancel): void {
        const dataNewLignes = [];
        const lignesURIs = [];
        this.form.value.devis.lignes.forEach((ligne) => {
            if (ligne.uuid) {
                lignesURIs.push('/api/v1/devis_lignes/' + ligne.uuid);
                const dataLigne = {
                    uuid: ligne.uuid,
                    libelle: ligne.produit?.libelle,
                    prixUnitaireHT: this.formatFrenchDecimalsToInt(ligne.prixUnitaireHT),
                    tauxTVA: this.formatFrenchDecimalsToInt(ligne.tauxTVA),
                    quantite: Number(ligne.quantite),
                    produit: '/api/v1/produits/' + ligne.produitUuid,
                    poids: ligne.poids,
                    remise: this.formatFrenchDecimalsToInt(ligne.remise),
                    remiseHT: this.formatFrenchDecimalsToInt(ligne.remiseHT),
                    typeRemise: Number(ligne.typeRemise)
                };
                this.devisLigneService.update(dataLigne.uuid, dataLigne).subscribe(result => {
                });
            } else {
                dataNewLignes.push({
                        libelle: ligne.produit?.libelle,
                        prixUnitaireHT: this.formatFrenchDecimalsToInt(ligne.prixUnitaireHT),
                        tauxTVA: this.formatFrenchDecimalsToInt(ligne.tauxTVA),
                        quantite: Number(ligne.quantite),
                        produitUuid: ligne.produitUuid,
                        poids: ligne.poids,
                        remise: this.formatFrenchDecimalsToInt(ligne.remise),
                        remiseHT: this.formatFrenchDecimalsToInt(ligne.remiseHT),
                        typeRemise: Number(ligne.typeRemise)
                    }
                );
            }
        });

        this.devisLigneService.createMultiplesLignes(dataNewLignes).subscribe(result => {
            result.content.forEach((item) => {
                lignesURIs.push('/api/v1/devis_lignes/' + item);
            });
            this.updateDevis(data, lignesURIs, cancel);
        });
    }

    private updateDevis(data, lignesURIs, cancel): void {
        const dataDevis = new Devis({
            uuid: data.devis.uuid,
            referencePerso: data.devis.referencePerso,
            dureeValidite: data.devis.dureeValidite,
            libelle: data.devis.libelle,
            commentaire: data.devis.commentaire,
            lignes: lignesURIs
        });
        this.devisService.update(dataDevis.uuid, dataDevis).subscribe(resultDevis => {
            if (cancel) {
                this.onCancel();
            }
            this.notifier.notify('success', MessageGlobals.MESSAGE_MODIFICATION);
        });
    }

    private createDevisLignes(data, dataTags, dataMemos, cancel): void {
        const dataNewLignes = [];
        const lignesURIs = [];
        this.form.value.devis.lignes.forEach((ligne) => {
            dataNewLignes.push({
                    libelle: ligne.produit?.libelle,
                    prixUnitaireHT: this.formatFrenchDecimalsToInt(ligne.prixUnitaireHT),
                    tauxTVA: this.formatFrenchDecimalsToInt(ligne.tauxTVA),
                    quantite: Number(ligne.quantite),
                    produitUuid: ligne.produitUuid,
                    poids: ligne.poids,
                    remise: this.formatFrenchDecimalsToInt(ligne.remise),
                    remiseHT: this.formatFrenchDecimalsToInt(ligne.remiseHT),
                    typeRemise: Number(ligne.typeRemise)
                }
            );
        });
        this.devisLigneService.createMultiplesLignes(dataNewLignes).subscribe(result => {
            result.content.forEach((item) => {
                lignesURIs.push('/api/v1/devis_lignes/' + item);
            });
            this.createDevis(data, dataTags, lignesURIs, dataMemos, cancel);
        });
    }

    private createDevis(data, dataTags, lignesURIs, dataMemos, cancel): void {
        const dataDevis = new Devis({
            referencePerso: data.devis.referencePerso ? data.devis.referencePerso : '',
            dureeValidite: data.devis.dureeValidite ? data.devis.dureeValidite : 0,
            libelle: data.devis.libelle ? data.devis.libelle : '',
            commentaire: data.devis.commentaire ? data.devis.commentaire : '',
            lignes: lignesURIs
        });
        this.devisService.add(dataDevis).subscribe(resultDevis => {
            this.createLead(data, dataTags, dataMemos, resultDevis, cancel);
        });
    }

    private createLead(data, dataTags, dataMemos, resultDevis, cancel): void {
        const dataLead = new Lead({
            libelle: data.libelle,
            priorite: data.priorite ? data.priorite : 0,
            contact: '/api/v1/personne_liens/' + data.contact.uuid,
            visibilite: data.visibilite,
            active: Number(data.actif) !== 0,
            devis: resultDevis['@id'],
            statut: data.priorite ? data.priorite : 0,
            probabilite: 20,
            tags: dataTags,
        });
        this.leadService.getTotals(this.offre.active).subscribe(dataTotals => {
            dataLead.poids = Number(dataTotals.draft) + 1;
            this.leadService.add(dataLead).subscribe(resultLead => {
                this.newUuidLead = resultLead.uuid;
                this.addNewMemos(resultLead['@id'].replace('/api/v1/leads/', ''), dataMemos);
                this.notifier.notify('success', MessageGlobals.MESSAGE_MODIFICATION);
                if (cancel) {
                    this.router.navigate(['offre', this.newUuidLead]);
                } else {
                    this.router.navigate(['/offre/' + this.newUuidLead + '/modifier']);
                }
            });
        });
    }

    private updateIntervenants(uuid, referent, intervenants): void {
        this.leadService.updateIntervenants({
            uuid,
            referent,
            intervenants
        }).subscribe(resultIntervenants => {
        });
    }

    public onCancel(): void {
        if (this.route?.parent?.snapshot.params.id) {
            this.router.navigate(['/offre/' + this.route?.parent?.snapshot.params.id]);
        } else {
            this.location.back();
        }
    }

    public calculateWeights(): void {
        this.formLignes.controls.forEach((ligne, index) => {
            ligne.get('poids').setValue(index);
        });
        this.setErrorMessages();
    }

    public drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.formLignes.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.offre.devis.lignes, event.previousIndex, event.currentIndex);
        this.calculateWeights();
    }

    public onSubmitCancel(): void {
        this.onSubmit(true);
    }

    deleteLigne(index): void {
        this.formLignes.removeAt(index);
        this.offre.devis.lignes.splice(index, 1);
        this.calculerDevis();
        this.calculateWeights();
    }

    addLigne(): any {
        this.offre.devis.lignes[this.formLignes.controls.length] = new Devisligne();
        this.formLignes.push(this.formBuilder.group({
                libelle: [{value: '', disabled: false}],
                produitId: [{value: 0, disabled: false}],
                prixUnitaireHT: [{value: 0, disabled: false}],
                quantite: [{value: 0, disabled: false}],
                tauxTVA: [{value: 0, disabled: false}],
                totalHT: [{value: 0, disabled: true}],
                totalTTC: [{value: 0, disabled: true}],
                produit: [{value: null, disabled: false}],
                produitUuid: [{value: null, disabled: false}],
                poids: [this.formLignes.controls.length],
                remise: [{value: -0, disabled: false}],
                remiseHT: [{value: -0, disabled: false}],
                remiseDisabled: [false],
                remiseHTDisabled: [true],
                typeRemise: [0]
            })
        );
        this.nombreLignes++;
    }

    calculerDevis(): void {
        if (this.form.valid) {
            const dataLignes = [];
            this.form.value.devis.lignes.forEach((ligne, index) => {
                const dataLigne = {
                    index,
                    prixUnitaireHT: this.formatFrenchDecimalsToInt(ligne.prixUnitaireHT),
                    tauxTVA: this.formatFrenchDecimalsToInt(ligne.tauxTVA),
                    quantite: Number(ligne.quantite),
                    typeFacturation: this.offre.devis.lignes[index].produit.typeFacturation,
                    remise: this.formatFrenchDecimalsToInt(ligne.remise),
                    remiseHT: this.formatFrenchDecimalsToInt(ligne.remiseHT),
                    typeRemise: Number(ligne.typeRemise)
                };
                dataLignes.push(dataLigne);
            });
            this.devisLigneService.calculate(dataLignes).subscribe(result => {
                this.form.controls.devis?.get('montantHT').setValue(
                    this.formatCurrency(result.montantHT / 100)
                );
                this.form.controls.devis?.get('montantTTC').setValue(
                    this.formatCurrency(result.montantTTC / 100)
                );
                this.offre.devis.montantHT = this.form.controls.devis?.get('montantHT').value;
                this.offre.devis.montantTTC = this.form.controls.devis?.get('montantTTC').value;
                this.offre.devis.montantsDevis = result.montantsDevis;

                this.form.controls.devis?.get('montantsTVA').setValue(result.montantsTVA);
                result.lignes.forEach((ligne) => {
                    this.formLignes.controls.forEach((ligneControl, index) => {
                        if (ligne.index === index) {
                            ligneControl.get('prixUnitaireHT').setValue(
                                this.formatCurrency(ligne.prixUnitaireHT / 100)
                            );
                            ligneControl.get('quantite').setValue(ligne.quantite);
                            ligneControl.get('remise').setValue(Number(-ligne.remise / 100).toLocaleString(
                                'fr-FR',
                                {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            );
                            ligneControl.get('remiseHT').setValue(
                                this.formatCurrency(-ligne.remiseHT / 100)
                            );
                            ligneControl.get('totalHT').setValue(this.formatCurrency(ligne.totalHT / 100));
                            ligneControl.get('tauxTVA').setValue((Number(ligne.tauxTVA) / 100).toLocaleString(
                                'fr-FR',
                                {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            );
                            ligneControl.get('totalTTC').setValue(this.formatCurrency(ligne.totalTTC / 100));
                        }
                    });
                });
            });
        } else {
            this.setErrorMessages();
        }
    }

    public remiseChange(index): void {
        this.offre.devis.lignes[index].remiseDisabled = false;
        this.offre.devis.lignes[index].remiseHTDisabled = true;
        if (this.formatFrenchDecimalsToInt(this.formLignes.controls[index].get('remise').value) === 0) {
            this.formLignes.controls[index].get('typeRemise').setValue(0);
            this.offre.devis.lignes[index].remiseHTDisabled = false;
        } else {
            this.formLignes.controls[index].get('typeRemise').setValue(1);
        }
        this.calculerDevis();

    }

    public remiseChangeTTC(index): void {
        this.offre.devis.lignes[index].remiseHTDisabled = false;
        this.offre.devis.lignes[index].remiseDisabled = true;
        if (this.formatFrenchDecimalsToInt(this.formLignes.controls[index].get('remiseHT').value) === 0) {
            this.formLignes.controls[index].get('typeRemise').setValue(0);
            this.offre.devis.lignes[index].remiseDisabled = false;
        } else {
            this.formLignes.controls[index].get('typeRemise').setValue(2);
        }
        this.calculerDevis();

    }

    public syncLibelle(): void {
        if (this.createMode && this.newLibelle) {
            this.form.controls.devis.get('libelle').setValue(
                this.form.controls.libelle.value
            );
            this.newLibelle = false;
        }
    }

    public addNewLigne(): void {
        if (this.form.controls.newProduct.value) {
            this.addLigne();
            this.changeProduct(this.form.controls.newProduct.value, this.formLignes.controls.length - 1);
            this.creatingLigne = false;
            this.form.controls.newProduct.setValue(null);
        }
    }

    public remiseReset(index): void {
        this.offre.devis.lignes[index].remiseDisabled = false;
        this.offre.devis.lignes[index].remiseHTDisabled = true;
        const typeRemise = '-0,00' === this.formLignes.controls[index].get('remise').value ? 0 : 1;
        this.formLignes.controls[index].get('typeRemise').setValue(typeRemise);
    }

    public remiseHTReset(index): void {
        this.offre.devis.lignes[index].remiseDisabled = true;
        this.offre.devis.lignes[index].remiseHTDisabled = false;
        const typeRemise = '-0,00' === this.formLignes.controls[index].get('remiseHT').value
            ? 0 : 2
        this.formLignes.controls[index].get('typeRemise').setValue(typeRemise);
    }

    public setErrorMessages(): void {
        this.formLignes.controls.forEach((ligne, index) => {
            if (ligne.valid) {
                this.errorMessages[index] = {invalid: false};
            } else {
                if (typeof this.errorMessages[index] === 'undefined') {
                    this.errorMessages[index] = {invalid: true};
                }
                this.errorMessages[index].invalid = true;
                const ligneArray = ligne as FormArray;
                Object.keys(ligneArray.controls).map((key) => {
                    if (!ligneArray.controls[key].disabled) {
                        if (ligneArray.controls[key].valid) {
                            delete this.errorMessages[index][this.inputLabels[key]];
                        } else {
                            Object.keys(ligneArray.controls[key].errors).map((keyError) => {
                                switch (keyError) {
                                    case 'pattern' :
                                        switch (ligneArray.controls[key].errors[keyError].requiredPattern) {
                                            case this.POSITIVE_DECIMALS_PATTERN :
                                                this.errorMessages[index][this.inputLabels[key]] =
                                                    'la valeur doit être numérique et supérieure à 0.';
                                                break;
                                            case this.DECIMALS_PATTERN :
                                                this.errorMessages[index][this.inputLabels[key]] =
                                                    'la valeur doit être numérique.';
                                                break;
                                            case this.INT_PATTERN :
                                                this.errorMessages[index][this.inputLabels[key]] =
                                                    'la valeur doit être entière et supérieure à 0.';
                                                break;
                                        }
                                        break;
                                    case 'min' :
                                    case 'max' :
                                    case 'customMinMax' :
                                        if (key === 'remiseHT') {
                                            this.errorMessages[index][this.inputLabels[key]] =
                                                'la valeur ne peut pas dépasser le Total HT.';
                                        } else {
                                            this.errorMessages[index][this.inputLabels[key]] =
                                                'le taux doit être compris entre 0 et 100 %.';
                                        }
                                        break;
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    remiseMinMaxValidator = (ligne): ValidatorFn => {
        return (control: AbstractControl): ValidationErrors | null => {
            const borne = Number(ligne.get('prixUnitaireHT').value?.replace(',', '.').replace(/\s/g, '')) *
                Number(ligne.get('quantite').value);
            if (Number(control.value.replace(',', '.').replace(/\s/g, '')) <= borne
                && -borne <= Number(control.value.replace(/\s/g, '').replace(',', '.'))) {
                return null;
            }
            control.setErrors({customMinMax: true});
            return {customMinMax: true};
        };
    }
}

export function customMinMaxValidator(borne): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (Number(control.value.replace(',', '.').replace(/\s/g, '')) <= borne
            && -borne <= Number(control.value.replace(',', '.').replace(/\s/g, ''))) {
            return null;
        }
        control.setErrors({customMinMax: true});
        return {customMinMax: true};
    };
}

