import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Filter} from '../../_services/personne-lien.service';
import {ActivatedRoute} from '@angular/router';
import {MatAccordion} from '@angular/material/expansion';
import {DataFilter} from '../../../models/datafilter';
import {FiltresGlobals} from '../../../const-global/filtres';
import {FilterItem} from '../../../models/filteritem';
import {ModalInformations} from "../../repertoire/consentement/modal/modal-informations";
import {VoletService} from "../../_services/volet.service";
import {Subscription} from "rxjs";
import {DebouceInputComponent} from "../debouce-input/debouce-input.component";


@Component({
    selector: 'app-data-filter',
    templateUrl: './data-filter.component.html',
    styleUrls: ['./data-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DataFilterComponent implements OnInit {

    @Output() changeFilter = new EventEmitter<any>();
    @Output() saveData = new EventEmitter<any>();
    @Output() hideSticky = new EventEmitter<any>();
    @Output() resetData = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    @Input() dataFilter: DataFilter;
    @Input() messageSwitch = { actif: '', inactif: '' };
    @Input() noToggle = false;
    @Input() editLocked = false;

    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild(DebouceInputComponent) compChild: DebouceInputComponent;
    public readonly MODAL_INFORMATIONS = ModalInformations;
    public readonly FILTRES_GLOBALS = FiltresGlobals;
    public btnApplyFilter = false;
    public btnResetFilter = false;
    public countNbVoletOpen = 0;
    public filterOpen = false;
    public numberFilterSet: any;
    public displayModal = false;
    public isSearchClicked = false;
    public isOneFilterClicked = undefined;
    public areAllFiltersClicked = false;
    private subscription: Subscription;


    constructor(
        private route: ActivatedRoute,
        public voletService: VoletService,
    ) {
    }

    ngOnInit(): void {
        this.dataFilter.setValuesFromLS(JSON.parse(localStorage.getItem(this.dataFilter.lsKey) || '{}'));
        this.route.queryParams.subscribe((filter: Filter) => {
            this.dataFilter.filters.forEach(filterItem => {
                if (filterItem.inputType === 'checkbox' && filter[filterItem.attributeName]) {
                    filterItem.value = !Array.isArray(filter[filterItem.attributeName])
                        ? [filter[filterItem.attributeName]]
                        : [filter[filterItem.attributeName]];
                }
            });
        });
        this.voletService.volet.subscribe(() => {
            if (this.filterOpen) {
                this.filterOpen = false;
            }
        });
        this.numberFilterSet = this.dataFilter.getCounts();
        this.changeFilter.emit();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.voletService.volet.unsubscribe();
        }
    }

    updateFilter(event: any, values: any, filter: string): void {
        this.btnApplyFilter = true;
        this.btnResetFilter = true;
        this.dataFilter.filters.forEach(filtreItem => {
            if (filtreItem.attributeName === filter) {
                if (filtreItem.inputType === 'select') {
                    filtreItem.value = values;
                } else if (filtreItem.inputType === 'checkbox_unique') {
                    filtreItem.value = event.target.checked ? [values] : null;
                } else {
                    if (event.target.checked) {
                        if (!filtreItem.value?.includes(values)) {
                            filtreItem.value = [...filtreItem.value ?? [], values];
                        }
                    } else {
                        filtreItem.value = filtreItem.value?.filter((type) => {
                            return type.uuid !== values.uuid;
                        });
                    }
                }
            }
        });

        this.dataFilter.page = 1;
        this.numberFilterSet = this.dataFilter.getCounts();
        this.btnResetFilter = !(this.numberFilterSet.total === 0);
        this.saveAndEmit();
    }

    public doClearAllFilters(): void {
        this.dataFilter.reInit();
        this.numberFilterSet = this.dataFilter.getCounts();
        this.btnResetFilter = false;
        this.btnApplyFilter = true;
        this.saveAndEmit();
    }
    public clearAllFilter(): void {
        if (!this.editLocked) {
            this.doClearAllFilters();
        } else {
            this.areAllFiltersClicked = true;
            this.displayModal = true;
            this.hideSticky.emit(true);
        }
    }
    public doClearFilterById(filter: FilterItem, item): void {
        filter.removeItem(item);
        this.numberFilterSet = this.dataFilter.getCounts();
        this.saveAndEmit();
    }

    clearFilterById(filter: FilterItem, item): void {
        if (!this.editLocked) {
            this.doClearFilterById(filter, item);
        } else {
                this.displayModal =  true;
                this.isOneFilterClicked = {filter, item};
                this.hideSticky.emit(true);
        }
    }

    applyAndCloseFilter(): void {
        this.filterOpen = false;
        this.accordion.closeAll();
        this.btnApplyFilter = false;
    }
    focusSearch(): void {
        if (this.editLocked) {
            this.isSearchClicked = true;
            this.displayModal = true;
            this.hideSticky.emit(true);
        }
    }
    updateSearch($event): void {
        this.dataFilter.search = $event;
        this.dataFilter.page = 1;
        if (!this.editLocked) {
            this.saveAndEmit();
        }
    }

    switchActif(): void {
        this.saveAndEmit();
    }

    saveAndEmit(): void {
        localStorage.setItem(this.dataFilter.lsKey, JSON.stringify(this.dataFilter.getValues()));
        this.changeFilter.emit();
    }

    openFilter(): void {
        if (!this.editLocked) {
            this.filterOpen = !this.filterOpen;
        } else {
            this.displayModal = true;
            this.hideSticky.emit(true);
        }
    }

    resetAndUnlockEdit(): void {
        this.resetData.emit();
        this.unlockEdit();
    }

    saveAndUnlockEdit(): void {
        this.saveData.emit();
        this.unlockEdit();
    }

    cancelData(): void {
        this.hideSticky.emit(true);
        this.displayModal = false;
        this.cancel.emit();
    }

    unlockEdit(): void {
        this.hideSticky.emit(false);
        this.displayModal = false;
        this.editLocked = false;
        if (this.isSearchClicked) {
            this.isSearchClicked = false;
            this.compChild.debouceInput.nativeElement.focus()
        } else if (this.areAllFiltersClicked) {
            this.areAllFiltersClicked = false;
            this.doClearAllFilters();
        } else if (this.isOneFilterClicked) {
            this.doClearFilterById(this.isOneFilterClicked.filter, this.isOneFilterClicked.item);
            this.isOneFilterClicked = undefined;
        }else {
            this.filterOpen = !this.filterOpen;
        }
    }
}
