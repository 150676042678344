import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import {TagService} from '../../../_services/tag.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../../_services/exception.service';
import {HelperService} from '../../../_services/helper.service';
import {ITag} from '../../../../interfaces/tag';
import {Subscription} from 'rxjs';
import {PersonGlobals} from '../../../../const-global/globals';
import {ModalService} from '../../../_modal';
import {MessageGlobals} from "../../../../const-global/messages";

@Component({
	selector: 'app-form-tag',
	templateUrl: './form-tag.component.html',
	styleUrls: ['./form-tag.component.scss']
})
export class FormTagComponent implements OnInit, OnDestroy {
	public form: FormGroup;
	private notifier: NotifierService;
	public tag: ITag;
	public uuidTag = this.route.snapshot.params.id;
	private formValid = false;
	public nbUsePP: number;
	public nbUsePM: number;
	public nbUseOffer: number;
	public checkBoxValeurs = Array<string>();
	private subscriptionTag: Subscription;

	readonly TARGETS_CHECKBOXES = [
		{
			id: 'Personnes',
			value: 1,
			checkbox: false
		},
		{
			id: 'Offres',
			value: 2,
			checkbox: false
		}
	];

	constructor(
		private router: Router,
		public route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private tagService: TagService,
		private ngxService: NgxUiLoaderService,
		private exceptionService: ExceptionService,
		private helperService: HelperService,
		private modalService: ModalService,
		notifier: NotifierService
	) {
		this.notifier = notifier;
	}

	ngOnInit(): void {
		if (this.uuidTag) {
			const checkboxGroup = new FormArray(this.TARGETS_CHECKBOXES.map(item => new FormGroup({
					id: new FormControl(item.id),
					value: new FormControl(item.value),
					checkbox: new FormControl(false)
				})
			));

			const hiddenControl = new FormControl(this.mapItems(checkboxGroup.value), Validators.required);
			checkboxGroup.valueChanges.subscribe((v) => {
				hiddenControl.setValue(this.mapItems(v));
			});

			this.form = this.formBuilder.group({
				libelle: [this.tag, Validators.required],
				targets: checkboxGroup,
				selectedItems: hiddenControl
			}, {});

			this.tagService.getTag(this.uuidTag).subscribe((tag: ITag) => {
				this.tagService.tagSubject.next(tag);
			});

			this.subscriptionTag = this.tagService.tagSubject.subscribe((tag: ITag) => {
                    this.tag = tag;
                    this.form.get('libelle').setValue(tag?.libelle);
                    let result: any[] = this.TARGETS_CHECKBOXES;
                    result = this.TARGETS_CHECKBOXES.filter((cbx) => {
                        cbx.checkbox = false;
                        this.tag.target.forEach((item) => {
                            if (cbx.value === item) {
                                cbx.checkbox = true;
                            }
                        });
                        return cbx;
                    });
                    this.form.get('targets').setValue(result);
				},
				err => {
					this.showNotification('error', this.exceptionService.statutErreur(err));
				}
			);
			this.tagService.countTagUsage(this.uuidTag).subscribe(
				data => {
					this.nbUsePP = data['persons'].filter(count => count.pl_type === PersonGlobals.PERSON_LIEN_PHYSIQUE)[0]?.nbUsage;
					this.nbUsePM = data['persons'].filter(count => count.pl_type === PersonGlobals.PERSON_LIEN_MORALE)[0]?.nbUsage;
					this.nbUseOffer = data['offers']?.nbUsageOffre;
				}
			);
		} else {
			this.form = this.formBuilder.group({
				libelle: ['', Validators.required],
				target: ['']
			}, {});
		}
	}

	mapItems(items): [] | null {
		const selectedItems = items.filter((item) => item.checkbox).map((item) => item.value);
		return selectedItems.length ? selectedItems : null;
	}

	get targets(): FormArray {
		return this.form.controls.targets as FormArray;
	}

	private showNotification(type: string, message: string): void {
		setTimeout(() => {
			this.notifier.notify(type, message);
		}, 1000);
	}

	public onSubmit(typeForm: string): void {
		let formData: ITag = {};
		this.ngxService.start();
		if (typeForm === 'modifier') {
			formData = {
				libelle: this.form.get('libelle').value,
				target: this.form.get('selectedItems').value
			};
		} else {
			formData = {
				libelle: this.form.controls.libelle.value,
				target: this.checkBoxValeurs
			};
		}
		if (this.form.valid) {
			if (typeForm === 'modifier') {
				this.tagService.updateTag(this.uuidTag, formData).subscribe(
					() => {
						this.tagService.getTag(this.uuidTag).subscribe(tag => {
							this.tagService.tagSubject.next(tag);
						});
						this.ngxService.stop();
						this.showNotification('success', 'Le mot clé ' + this.form.controls.libelle.value + ' vient d\'être mis à jour');
					},
					err => {
						this.ngxService.stop();
						this.showNotification('error', this.exceptionService.statutErreur(err));
					}
				);
			} else {
				this.tagService.creerTag(formData).subscribe(
					() => {
						this.ngxService.stop();
						this.showNotification('success', 'Le mot clé ' + this.form.controls.libelle.value + ' vient d\'être crée');
						setTimeout(() => {
							this.router.navigate(['/tag']);
						}, 1000);
					},
					err => {
						this.ngxService.stop();
						this.showNotification('error', this.exceptionService.statutErreur(err));
					}
				);
			}
			this.formValid = true;
		} else {
			this.ngxService.stop();
			this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
		}
	}

	public onCancel(): void {
		this.router.navigate(['tag/']);
	}

	public onSubmitCancel(): void {
		if (this.uuidTag) {
			this.onSubmit('modifier');
		} else {
			this.onSubmit('creer');
		}
		setTimeout(() => {
			if (this.formValid === true) {
				this.router.navigate(['/tag']);
			}
		}, 1000);
	}

	ngOnDestroy(): void {
		if (this.uuidTag) {
			this.form.get('targets').reset();
			this.form.get('selectedItems').reset();
			this.subscriptionTag.unsubscribe();
		}
	}

	public onChangeCheckBox($event, value): void {
		if ($event.target.checked) {
			if (this.checkBoxValeurs === undefined) {
				this.checkBoxValeurs = [];
			}
			this.checkBoxValeurs.push(value);
		} else {
			if (this.checkBoxValeurs === undefined) {
				this.checkBoxValeurs = [];
			} else {
				this.checkBoxValeurs = this.checkBoxValeurs.filter(val => val !== value);
			}
		}
	}

	public deleteTag(): void {
		this.modalService.open('confirmDeletionModal');
	}

	public cancelDeletion(): void {
		this.modalService.close('confirmDeletionModal');
	}

	public confirmDeletion(): void {
		this.tagService.deleteTag(this.uuidTag).subscribe(message => {
				this.router.navigate(['/tag']).then(() => {
					this.notifier.notify('success', message);
				});
			},
			err => {
				this.notifier.notify('error', err.error);
			});
	}
}

