import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UIGlobals} from '../const-global/globals';
import {PreviousRouteService} from "./_services/previous-route.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

    constructor(private titleService: Title, private previousRouterService: PreviousRouteService) {
    }

    ngOnInit(): void {
        this.titleService.setTitle(UIGlobals.TITLE_CRM);
    }
}
