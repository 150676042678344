import {IUser} from './user';
import IHydraMember from './hydra-member';
import {IPersonnelien} from './personne-lien';

export enum EventType {
    leadDownloaded = 41,
    leadSent = 42,
}

export enum EventStatus {
    toDo = 1,
    endEd = 2,
    canceled = 10,
}

export interface IEvenement extends IHydraMember {
    '@id'?: string;
    uuid: string;
    type?: EventType | {
        id: number;
        libelle: string;
    };
    statut?: EventStatus;
    titre?: string;
    libelle?: string;
    description?: string;
    dateEcheance?: Date;
    dateFait?: Date;
    lien: IPersonnelien;
    lead?: any;
    userAssigne?: IUser;
    dateCreation?: Date;
    dateModification?: Date;
    userCreation?: IUser;
    userModification?: IUser;
    moisEcheance?: string;
    aujourdhui?: boolean;
    enRetard?: boolean;
    planifie?: boolean;
    etat?: number;
    dateEcheanceCalc?: string;
    actif?: number;
    typePersonne?: string;
    idMeetlaw?: string;
    imutable?: boolean;
    uuidCampagne?: string;
}
