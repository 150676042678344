import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormBuilder, FormGroup, NgSelectOption, Validators} from "@angular/forms";
import {ModalService} from "../../_modal";
import {ILead} from "../../../interfaces/lead";
import {OrganisationService} from "../../_services/organisation.service";
import {Subscription} from "rxjs";
import {PersonneService} from "../../_services/personne.service";
import {LeadService} from "../../_services/lead.service";
import {NotifierService} from "angular-notifier";
import {MessageGlobals} from "../../../const-global/messages";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import {CKEditor5} from "@ckeditor/ckeditor5-angular";


@Component({
	selector: 'app-modal-send-devis',
	templateUrl: './modal-send-devis.component.html',
	styleUrls: ['./modal-send-devis.component.scss']
})
export class ModalSendDevisComponent implements OnDestroy {

	@Input() id: string;
	@Output() displayChange = new EventEmitter<boolean>();
	editor = ClassicEditor;
	config: CKEditor5.Config = {
		language: 'fr',
		toolbar: {
			items: [
				'undo', 'redo',
				'|', 'heading',
				'|', 'bold', 'italic', 'underline',
				'|', 'bulletedList', 'numberedList', 'outdent', 'indent',
			],
			shouldNotGroupWhenFull: false
		}
	};
	offer: ILead;
	loading: boolean = false;
	private messagePlaceholder = "<p>Bonjour {{ civilite }} {{ prenom }} {{ nom }},</p>" +
		"<p>Veuillez trouver ci-joint votre devis pour l'offre \"{{ libelle }}\"</p>" +
		"<p>Cordialement,<br>{{ user.prenom }} {{ user.nom }}<br>Cabinet {{ cabinet }}</p>";

	private orgData: any;
	private subscriptions = {
		coordonnees: Subscription.EMPTY,
		personne: Subscription.EMPTY,
		lead: Subscription.EMPTY,
		userCurrent: Subscription.EMPTY
	};

	public readonly modalID = 'sendDevisModal';
	public form: FormGroup;
	public emailsData: NgSelectOption[] = [];

	constructor(
		private modalService: ModalService,
		private formBuilder: FormBuilder,
		private organisationService: OrganisationService,
		private personneService: PersonneService,
		private leadService: LeadService,
		private notifier: NotifierService,
	) {
	}

	ngOnInit() {
		this.form = this.formBuilder.group({
			to: this.formBuilder.control(null, [Validators.required]),
			sender: this.formBuilder.control({
				value: null,
				disabled: true
			}, [Validators.required, Validators.email]),
			senderDisplay: this.formBuilder.control({
				value: null,
				disabled: true
			}, [Validators.required, Validators.email]),
			senderName: this.formBuilder.control('', [Validators.required]),
			object: this.formBuilder.control('', [Validators.required]),
			message: this.formBuilder.control('', [Validators.required])
		});
	}

	ngOnDestroy(): void {
		for (const [key, value] of Object.entries(this.subscriptions)) {
			value.unsubscribe();
		}
	}

	open(offre?: ILead) {
		this.offer = offre;
		this.modalService.open(this.modalID);
		this.initForm();
	}

	close() {
		this.modalService.close(this.modalID);
	}

	send() {
		if (!this.subscriptions.lead.closed) {
			return ;
		}

		if (this.form.invalid) {
			this.notifier.notify('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
		} else {
			this.subscriptions.lead = this.leadService.sendDevis(this.offer.uuid, this.form.value).subscribe(v => {
				},
				() => {
					this.notifier.notify('error', 'Une erreur est survenue');
				},
				() => {
					this.notifier.notify('success', 'Votre devis a bien été envoyé.');
					this.close();
				});
		}
	}

	private initForm() {

		this.organisationService.getDatasOrganisation().subscribe(org => {
				this.orgData = org;
				if (org.dbId) {
					this.organisationService.getOrganisationSettings(org.dbId).subscribe((settings: any) => {
						// TODO : verifier le noreply
						this.form.get('sender').setValue(settings?.reply_to || 'noreply@mail.crm.azko.app');
					});
				}
			}, () => {

			},
			() => {
				this.subscriptions.userCurrent = this.personneService.getCurrentUser().subscribe(
					(data) => {
						const senderName = data.prenom + ' ' + (data.nom).toUpperCase()
						this.form.get('senderDisplay').setValue(data.login);
						this.form.get('senderName').setValue(senderName);
						this.replacePlaceholderString([
							'user.nom',
							'user.prenom'
						], [
							(data.nom).toUpperCase(),
							data.prenom
						]);
					}
				);
				this.form.get('object').setValue('Votre devis ' + this.offer?.libelle + ' - REF : ' + this.offer?.devis?.reference);
				this.replacePlaceholderString([
						'cabinet',
						'libelle'
					]
					, [
						this.orgData?.nom,
						this.offer?.libelle
					]
				);
			});

		this.loading = true;
		this.subscriptions.coordonnees = this.personneService.getCoordonnees(this.offer.contact.uuid).subscribe(coords => {
			this.emailsData = [...coords.emails];
			let preSelectedMail = coords.emails.filter(mail => mail?.principal === true);
			if (preSelectedMail.length > 0) {
				this.form.get('to').setValue(preSelectedMail[0].valeur);
			}
		}, () => {
			this.loading = false;
		}, () => {
			this.loading = false;
		});

		this.subscriptions.personne = this.personneService.getDatasFiche(this.offer.contact.uuid).subscribe(personne => {
			this.replacePlaceholderString([
				'civilite',
				'nom',
				'prenom'
			], [
				personne.civilite,
				personne.nom,
				personne.prenom
			]);
		})
	}

	replacePlaceholderString(props: Array<string>, values: Array<string>) {
		let self = this;
		props.forEach(function (prop, index) {
			let value = values[index];
			if (typeof value === 'undefined' || !value) {
				value = '';
			}
			self.messagePlaceholder = self.messagePlaceholder.replace("{{ " + prop + " }}", value);
		});

		this.form.get('message').setValue(this.messagePlaceholder);
		return this.messagePlaceholder;
	}
}
