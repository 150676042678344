import {Component, ViewEncapsulation} from '@angular/core';
import {SegmentService} from '../../../_services/segment.service';
import {CheckboxFilterItemComponent} from '../checkbox-filter-item/checkbox-filter-item.component';

@Component({
    selector: 'app-personne-segment-filter-item',
    templateUrl: '../checkbox-filter-item/checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class PersonneSegmentFilterItemComponent extends CheckboxFilterItemComponent {

    constructor(private service: SegmentService) {
        super();
        this.service.getUserSegmentsList().subscribe((data: any) => {
            this.dataSource = data;
        });
    }
}
