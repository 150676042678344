import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CampagneService} from '../../_services/campagne.service';
import {ExceptionService} from '../../_services/exception.service';
import {NotifierService} from 'angular-notifier';
import {Campagne} from '../../../models/campagne';
import {CampaignGlobals} from '../../../const-global/globals';
import {MatSort, SortDirection} from '@angular/material/sort';
import {BehaviorSubject, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {MessageGlobals} from '../../../const-global/messages';
import * as moment from 'moment';
import {ErrorsGlobals} from '../../../const-global/errors';
import {EntityTabsService} from '../../_services/entity-tabs.service';
import {EntityType} from '../../../models/lastentity';
import {environment} from '../../../environments/environment';
import {ModalService} from '../../_modal';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PersonneService} from '../../_services/personne.service';
import {DateFormaterService} from '../../_services/date-formater.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-fiche-campagne',
    templateUrl: './fiche-campagne.component.html',
    styleUrls: ['./fiche-campagne.component.scss']
})
export class FicheCampagneComponent implements OnInit, AfterViewInit {

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private campaignService: CampagneService,
        private exceptionService: ExceptionService,
        private notifier: NotifierService,
        private cdr: ChangeDetectorRef,
        private entityTabService: EntityTabsService,
        private modalService: ModalService,
        private personneService: PersonneService,
        private ngxService: NgxUiLoaderService,
        public dateFormaterService: DateFormaterService

    ) {
        this.formBuilder = new FormBuilder();
    }

    get emails(): FormArray {
        return this.formModal.get('emails') as FormArray;
    }
    get dataSourced(): Observable<any> {
        return of(this.dataSource);
    }
    @ViewChild('matTableRef', {read: ElementRef}) public matTableRef: ElementRef;
    @ViewChild('tableSort') sort = new MatSort();

    private formBuilder: FormBuilder;

    private campaignUuid: string;
    public campaign: Campagne;
    public readonly CAMPAIGN_GLOBALS = CampaignGlobals;
    public selectTab = 'message';
    public duplicateInProgress = false;
    public updateInProgress = false;
    displayedColumns: string[] = ['libelle', 'email', 'statut', 'nombre de clics'];
    dataSource;
    searchfield = new BehaviorSubject(null);
    nbItemsPerPage = 30;
    endReached = false;
    currentPage = 1;
    private update: Subject<any> = new Subject();

    public previewUrl: string;
    public page = 0;

    public sent = 0;
    public notSent = 0;
    public percentSent = 0;
    public failure = 0;
    public percentFailure = 0;

    public clicked = 0;
    public notClicked = 0;
    public percentClicked = 0;

    public opened = 0;
    public notOpened = 0;
    public percentOpened = 0;

    public formModal: FormGroup;

    private subscriptions = {
        route: Subscription.EMPTY,
        scrollEvent: Subscription.EMPTY,
        personne: Subscription.EMPTY
    };
    mode: string;

    ngOnInit(): void {
        this.formModal = this.formBuilder.group({
            emails: this.formBuilder.array([]),
            user_email: this.formBuilder.control({value: '', disabled: true}, [Validators.required])
        });

        this.campaignService.campaignDataSubject.subscribe(cmp => {
            if (cmp) {
                this.campaign = new Campagne(cmp);
            }
        });

        if (this.subscriptions.route) {
            this.subscriptions.route.unsubscribe();
        }
        this.subscriptions.route = this.route.params.subscribe((routerParams) => {
                this.campaignUuid = routerParams.id;
                this.campaignService.getFicheCampaign(routerParams.id).subscribe((campaign) => {
                        this.entityTabService.upsert({
                            uuid: campaign.uuid,
                            label: campaign.libelle,
                            uri: '/campagne/' + campaign.uuid,
                            type: EntityType.campagne
                        });
                        this.campaign = new Campagne(campaign);
                        this.mode = this.campaign.statut ;
                    },
                    err => {
                        this.notifier.notify('error', this.exceptionService.statutErreur(err));
                    }, () => {
                        this.drawCharts();
                        this.currentPage = 1;
                        if (undefined !== this.sort && (this.sort.active !== 'libelle' || this.sort.direction !== 'asc')) {
                            this.sort.sort({id: 'libelle', start: 'asc', disableClear: true});
                        }
                        this.fetchContacts(
                            this.campaignUuid,
                            this.sort && this.sort.active ? this.sort.active : null,
                            this.sort && this.sort.direction ? this.sort.direction : null,
                            this.currentPage,
                            this.nbItemsPerPage,
                            this.searchfield.value
                        ).subscribe((data) => {
                            this.endReached = data.length < 25;
                            this.currentPage++;
                            this.dataSource = data;
                        });
                        this.previewUrl = environment.apiURL + 'campagne/preview/' + this.campaign.uuid;
                });
            },
            () => {
                this.notifier.notify('error', ErrorsGlobals.FATAL);
            }
        );

        this.subscriptions.personne = this.personneService.getCurrentUser().subscribe(user => {
            this.formModal.get('user_email').setValue(user.login);
        });
    }
    applyFilter = (event: Event) => {
        const filterValue = (event.target as HTMLInputElement).value;
        this.searchfield.next(filterValue.trim().toLowerCase());
        this.matTableRef.nativeElement.scrollTop = 0;
    }
    ngAfterViewInit(): void {
        this.searchfield.pipe(
            debounceTime(1000)
        ).subscribe((filter) => {
            if (null !== filter) {
                this.doSort(filter);
            }
        });

        this.subscribeToScrollEvent();
    }
    subscribeToScrollEvent(): void {

        if (this.subscriptions.scrollEvent) {
            this.subscriptions.scrollEvent.unsubscribe();
        }
        this.subscriptions.scrollEvent = fromEvent(this.matTableRef.nativeElement, 'scroll')
                .pipe(debounceTime(400))
                .subscribe(() => {
                        const height = this.matTableRef.nativeElement.scrollTop + this.matTableRef.nativeElement.offsetHeight;
                        const position = this.matTableRef.nativeElement.scrollHeight + 1;
                        if (
                            position >= (height * 75 / 100) && position <= height + 100 && !this.endReached
                        ) {
                            this.ngxService.startLoader('campagne_destinataires');
                            this.fetchContacts(
                                this.campaignUuid,
                                this.sort.active,
                                this.sort.direction,
                                this.currentPage,
                                this.nbItemsPerPage,
                                this.searchfield.value
                            ).subscribe((data) => {
                                this.endReached = data.length < 25;
                                this.currentPage++;
                                this.dataSource.push(...data);
                                this.ngxService.stopLoader('campagne_destinataires');
                            });
                        }
                    }, () => {},
                    () => {
                        this.ngxService.stopLoader('campagne_destinataires');
                    }
                );
    }
    /**
     * Retrieve statut libelle
     */
    getStatutLibelle(statutId): string {
        const statut = CampaignGlobals.MAPPING.filter(s => s.id === statutId)[0];
        if (statut) {
            return statut.libelle;
        }
        return 'Non précisé';
    }

    /**
     * Retrieve segment list
     */
    getSegmentsLists(): string {
        if (this.campaign.segments.length > 0) {
            return this.campaign.segments.map((value: any) => {
                return value.text;
            }).join(', ');
        } else {
            return 'Non précisé';
        }
    }

    /**
     * Service pour dupliquer une campagne
     */
    duplicate(uuid: string): void {
        this.duplicateInProgress = true;
        this.campaignService.duplicate(uuid).subscribe((uuid: string) => {
                this.duplicateInProgress = false;
                this.router.navigate(['/campagne/' + uuid + '/modifier']);
            },
            () => {
                this.duplicateInProgress = false;
                this.notifier.notify('error', MessageGlobals.MESSAGE_ERROR);
            }, () => {
                this.duplicateInProgress = false;
                this.notifier.notify('success', MessageGlobals.MESSAGE_SUCCESS);
            });
    }

    /**
     * Update selected tab
     */
    changeTab(message: string): void {
        this.selectTab = message;
    }

    /**
     * Schedule Campaign
     */
    scheduleCampaignNow(): void {
        this.updateInProgress = true;
        this.campaignService.sendNow(this.campaignUuid).subscribe(
            () => {
                this.updateInProgress = false;
                this.notifier.notify('success', MessageGlobals.MESSAGE_SUCCESS);
                this.update.next(moment.now());
            },
            e => {
                this.updateInProgress = false;
                this.notifier.notify('error', e.error.message);
            }
        );
    }

    stopCampaign(): void {
        this.campaignService.stop(this.campaignUuid).subscribe(
            () => {
                this.updateInProgress = false;
                this.notifier.notify('success', MessageGlobals.MESSAGE_SUCCESS);
                this.update.next(moment.now());
            },
            e => {
                this.updateInProgress = false;
                this.notifier.notify('error', e.error.message);
            }
        );
    }

    private drawCharts(): void {
        // sent
        this.sent = this.campaign?.stats.nbEnvoyes || 0;
        this.notSent = this.campaign?.stats.nbDest - this.sent;
        this.failure = this.campaign?.stats.hardBounces + this.campaign?.stats.softBounces;
        this.percentSent = Math.round(this.sent * 100 / this.campaign?.stats.nbDest);
        this.percentFailure = Math.round((this.campaign?.stats.hardBounces + this.campaign?.stats.softBounces)
            * 100 / this.campaign?.stats.nbDest);

        // opened
        this.opened = this.campaign?.stats.vueUniques;
        this.notOpened = this.campaign?.stats.nbDest - this.opened;
        this.percentOpened = (this.opened !== 0) ? Math.round(this.opened * 100 / this.campaign?.stats.nbDest) : 0;

        // clicks
        this.clicked = this.campaign?.stats.clicksUnique;
        this.notClicked = this.campaign?.stats.nbDest - this.clicked;
        this.percentClicked = (this.clicked !== 0) ? Math.round(this.clicked * 100 / this.campaign?.stats.nbDest) : 0;
    }

    updateCampaign(campagne: any): void {
        if (campagne.statut <= this.CAMPAIGN_GLOBALS.QUEUED_ID) {
            this.router.navigate([{outlets: {primary: 'campagne/' + campagne.uuid + '/modifier'}}]).then();
        }
    }

    openModal(modalID: string): void {
        this.emails.clear();
        this.modalService.open(modalID);
    }

    closeModal(modalID: string): void {
        this.modalService.close(modalID);
    }

    validateAndClose(modalID: string): void {
        this.campaignService.sendTest(this.campaignUuid, this.formModal.getRawValue()).subscribe(
            () => {},
            () => {
                this.notifier.notify('error', 'Une erreur est survenue, l\'envoi pourrait avoir échoué');
            },
            () => {
                this.closeModal(modalID);
                this.notifier.notify('success', 'Votre e-mail test a bien été envoyé aux adresses indiquées.');
            }
        );
    }

    addNewInputMail(): void {
        this.emails.push(this.formBuilder.control('', [Validators.email]));
    }

    removeEmail(i: number): void {
        this.emails.removeAt(i);
    }

    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }
    sortData(): void {
        this.matTableRef.nativeElement.scrollTop = 0;
        this.doSort();
    }

    doSort(filter = this.searchfield.value): void {
        this.currentPage = 1;
        this.endReached = false;
        this.ngxService.startLoader('campagne_destinataires');

        const sub: Subscription = this.fetchContacts(
            this.campaignUuid,
            this.sort.active,
            this.sort.direction,
            this.currentPage,
            this.nbItemsPerPage,
            filter
        ).subscribe(
            (data) => {
                this.dataSource = data;
                this.currentPage++;
            },
            () => {},
            () => {
                this.ngxService.stopLoader('campagne_destinataires');
                sub.unsubscribe();
            }
        );
    }
    fetchContacts(campUuid: string, sort: string, order: SortDirection, page: number, perpage: number, search: string): Observable<any> {
        if (sort === null || sort === undefined || sort === '') { sort = 'libelle'; }
        if (order === null || order === undefined || order === '') { order = 'asc'; }
        if (search === undefined || search === '') { search = null; }
        return this.campaignService.getContactsFromCampaign(campUuid, sort, order, page, perpage, search);
    }
}
