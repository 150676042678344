<jw-modal id="adresseModal">
    <div class="bandeau_form_modal">
        <ng-container *ngIf="edit">Editer une adresse</ng-container>
        <ng-container *ngIf="!edit">Ajouter une adresse</ng-container>
    </div>
    <div class="edit_data" [formGroup]="this.form">
        <div class="input_container">
            <div class="input_lg">
                <label>Type</label>
                <ng-select [items]="types |async"
                           bindLabel="text"
                           (change)="selectChange($event, 'typeId')"
                           [ngClass]="{'background_alert_required': this.form.controls.typeId.invalid}"
                           formControlName="type"
                ></ng-select>
            </div>
            <div class="input_lg">
                <label>Statut</label>
                <ng-select [items]="statuts |async"
                           bindLabel="text"
                           (change)="selectChange($event, 'statutId')"
                           [ngClass]="{'background_alert_required': this.form.controls.statutId.invalid}"
                           formControlName="statut"
                ></ng-select>
            </div>
        </div>
        <div class="input_lg">
            <ng-container [formGroup]="lignes">
                <div class="input_container_single">
                    <label for="ligne1">Ligne1</label>
                    <input placeholder="N°, voie, etc..."
                           type="text"
                           id="ligne1"
                           formControlName="ligne1"
                           [ngClass]="{'background_alert_required': this.form.controls.lignes.get('ligne1').invalid}"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
                <div class="input_container_single">
                    <label for="ligne2">Ligne2</label>
                    <input type="text"
                           id="ligne2"
                           formControlName="ligne2"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
                <div class="input_container_single">
                    <label for="ligne3">Ligne3</label>
                    <input type="text"
                           id="ligne3"
                           formControlName="ligne3"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
            </ng-container>
            <div class="input_container">
                <div class="input_lg">
                    <label for="cp">CP</label>
                    <input type="text"
                           id="cp"
                           formControlName="cp"
                           minlength="1"
                           maxlength="20"
                           [ngClass]="{'background_alert_required': this.form.controls.cp.invalid}"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
                <div class="input_lg">
                    <label for="ville">Ville</label>
                    <input type="text"
                           id="ville"
                           formControlName="ville"
                           [ngClass]="{'background_alert_required': this.form.controls.ville.invalid}"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
            </div>
            <div class="input_container">
                <div class="input_lg">
                    <label for="cedexCode">Cedex Code</label>
                    <input type="text"
                           id="cedexCode"
                           formControlName="cedexCode"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
                <div class="input_lg">
                    <label for="cedexLibelle">Cedex Libellé</label>
                    <input type="text"
                           id="cedexLibelle"
                           formControlName="cedexLibelle"
                           (keydown.enter)="onSubmitModal()"
                    />
                </div>
            </div>
            <div class="input_container_single">
                <label for="pays">Pays</label>
                <input type="text"
                       id="pays"
                       formControlName="pays"
                       (keydown.enter)="onSubmitModal()"
                />
            </div>
            <div class="input_container">
                <div>
                    <label for="principalModal">Marquer comme principal</label>
                    <input id="principalModal"
                           name="principal"
                           type="checkbox"
                           class="icon checkbox_principal"
                           formControlName="principal"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal();">
                <span>Annuler</span>
            </button>
            <button type="submit" class="btn btn-med btn-enregistrer" (click)="onSubmitModal()">
                <span>Valider</span>
            </button>
        </div>
    </div>
</jw-modal>

