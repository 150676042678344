import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-information',
    templateUrl: './information.html',
    styleUrls: ['./information.scss']
})
export class InformationComponent implements OnInit {
    @Output() fermer = new EventEmitter();
    @Output() valider = new EventEmitter();
    @Input() titre: string;
    @Input() aide: string;
    @Input() bas: string;
    @Input() fermerTexte: string;
    @Input() validerTexte: string;
    @Input() validerOn: boolean = true;
    @Input() fermerOn: boolean = true;

    constructor(
        public router: Router,
    ) {}
    ngOnInit(): void {}
}
