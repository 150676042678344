import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'visibility'
})
@Injectable({providedIn:  'root'})
export class VisibilityPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    return ['', 'Public', 'Protégé', 'Privé'][value];
  }
}
