<notifier-container></notifier-container>
<div class="fiche segment" *ngIf="segment">
    <div class="fiche_gauche">
        <div class="fiche_bloc1">
            <div class="carte_titre bandeau_personne bandeau_segment icon user">
                <h1>{{segment.titre}}</h1>
            </div>
            <div class="carte_information">
                <div class="information">
                    <div class="container_information" *ngFor="let filtre of segment.filtres |keyvalue">
                        <span class="libelle_champ">{{filtre.value.champ.libelle}}</span>
                        <p class="valeur_champ">
                            {{filtre.value.operateur.libelle}}
                            <span *ngFor="let valeur of filtre.value.valeurs; index as i ">
                                <span *ngIf="i === 0">
                                     :
                                    <span *ngIf="isInstanceOf(valeur[0]) === 'string' && isInstanceOf(valeur) !== 'object'">
                                          "{{valeur}}"
                                    </span>
                                    <span *ngIf="isInstanceOf(valeur) === 'object' && isInstanceOf(valeur[0]) !== 'object'">
                                        <span *ngFor="let item of valeur | keyvalue">
                                            <span *ngIf="item.key != 0">
                                            ,
                                            </span>
                                            "{{item.value}}"
                                        </span>
                                    </span>
                                    <span
                                            *ngIf="isInstanceOf(valeur[0]) === 'object' && isInstanceOf(valeur) === 'object'">
                                        <span *ngFor="let item of valeur | keyvalue">
                                            <!--suppress TypeScriptValidateTypes -->
                                            <span *ngFor="let val of item.value  | keyvalue; index as j">
                                                <span *ngIf="j === 0">
                                                    "{{val.value}}"
                                                </span>
                                                <span *ngIf="j !== 0">
                                                    et  "{{val.value}}"
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span *ngIf="i !== 0">
                                    <span *ngIf="isInstanceOf(valeur[0]) === 'string'">
                                    , "{{valeur}}"
                                    </span>
                                    <span *ngIf="isInstanceOf(valeur[0]) === 'object'">
                                        <span *ngFor="let item of valeur | keyvalue">
                                            <!--suppress TypeScriptValidateTypes -->
                                            <span *ngFor="let val of item.value  | keyvalue; index as j">
                                                <span *ngIf="j === 0">
                                                    , "{{val.value}}"
                                                </span>
                                                <span *ngIf="j !== 0">
                                                    et  "{{val.value}}"
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="btn_border_white duplicate">
                    <button type="button" class="btn btn-picto-lg btn-copy" (click)="toDuplicate(segment.uuid)"></button>
                </div>
                <div class="btn_border_white">
                    <button type="button" class="btn btn-picto-lg btn-editer" (click)="toModifier(segment.uuid)"></button>
                </div>
            </div>
        </div>
        <div class="fiche_bloc2">
        </div>
        <div class="fiche_bloc3">
            <div class="status_personne">
                <div class="bloc_actif">
                    <i class="icon" [ngClass]="{
                            'actif': segment?.actif,
                            'inactif': !segment?.actif
                        }"></i>
                    <span *ngIf="segment?.actif">Actif</span>
                    <span class="inactif" *ngIf="!segment?.actif">Inactif</span>
                </div>
            </div>
            <div class="date_creation_fiche">
                <p>Crée le {{segment.dateCreation | date:fullDate}}
                   <ng-container *ngIf="segment.userCreation">
                       par {{segment.userCreation?.prenom}} {{segment.userCreation?.nom}}
                    </ng-container>
                </p>
                <p>Modifié le {{segment.dateModification | date:fullDate}}
                    <ng-container *ngIf="segment.userModification">
                    par {{segment.userModification?.prenom}} {{segment.userModification?.nom}}
                    </ng-container>
                </p>
            </div>
        </div>
    </div>
    <div class="fiche_droite">
        <div class="onglet segment">
            <div class="onglet_segment icon list">Résultat</div>
        </div>
        <div class="carte_information">
            <div class="container_information">
                <div class="info_fiche_segment">
                    <h2>Dernière exécution : {{segment.derniereDateExecution | date : fullDate}} -&nbsp;</h2>
                    <h2>{{segment.nbContactsPublics}} + {{segment.nbContactsPrives}} contacts trouvés</h2>
                </div>
            </div>
            <div class="container_action">
                <div class="action_fiche_segment">
                    <button type="button" class="btn btn-med btn_reload_filtre btn-actualiser"
                            [ngClass]="{'updating': updateInProgress}"
                            (click)="actualiser(segment.uuid)"
                    >
                        <span>Actualiser</span>
                    </button>
                    <button type="button" class="btn btn-med btn-exporter" (click)="export(segment.uuid)">
                        <span>Exporter</span>
                    </button>
                    <button type="button" class="btn btn-med btn_add_segement btn-ajouter" (click)="createCampaign(segment.uuid)">
                        <span>Créer une campagne</span>
                    </button>
                </div>
            </div>
        </div>
        <div id="information"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             (scrolled)="onScroll(uuidSegment)"
             [scrollWindow]="false">
            <ngx-ui-loader [loaderId]="'resultats'"></ngx-ui-loader>
            <ng-container *ngFor="let res of resultats">
                <div class="contacts_find">
                    <span class="icon {{res.type}}"
                          [ngClass]="{
                                'user': res.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE || res.type === PERSON_GLOBALS.PERSON_LIEN,
                                'building': res.type === PERSON_GLOBALS.PERSON_LIEN_MORALE
                            }"
                    ></span>
                    <span class="libelle_contact"
                          matTooltip="{{ res.libelle }}"
                          aria-label="tooltip"
                    >
                       {{res.libelle}}
                    </span>
                    <span class="libelle_email">
                        {{res.mails}}
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
</div>

