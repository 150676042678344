import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../_modal';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-role-downgrade-confirmation',
    templateUrl: './role-downgrade-confirmation.component.html',
    styleUrls: ['./role-downgrade-confirmation.component.scss']
})
export class RoleDowngradeConfirmationComponent implements OnInit {

    public confirmChange: Subject<boolean> = new Subject<boolean>();

    constructor(private modalService: ModalService) { }

    ngOnInit(): void {
    }

    cancel(): void {
        this.confirmChange.next(false);
        this.modalService.close('confirmDowngradeModal');
    }

    confirm(): void {
        this.confirmChange.next(true);
        this.modalService.close('confirmDowngradeModal');
    }

}
