import {Component, ViewEncapsulation} from '@angular/core';
import {ConsentHelper} from '../../../_helpers/consent.helper';
import {CheckboxFilterItemComponent} from '../checkbox-filter-item/checkbox-filter-item.component';

@Component({
    selector: 'app-mail-consent-moyen-recueil',
    templateUrl: '../checkbox-filter-item/checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class MailConsentMoyenRecueilComponent extends CheckboxFilterItemComponent {
    constructor() {
        super();

        const consentType = [];
        ConsentHelper.consentType.forEach(item => {
            consentType.push({titre: item.text, uuid: item.id});
        });

        this.dataSource = consentType;
    }
}
