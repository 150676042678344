<div>
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
</div>
<ng-container>
    <div class="form_galerie_campagne">
        <div>
            <div class="bandeau_campagne icon mail">
                <h1>Catalogue de modèles de campagnes</h1>
            </div>
            <div class="content_component_model">
                <app-campagne-modele [modelVierge]="true"></app-campagne-modele>
                <div *ngFor="let model of models">
                    <app-campagne-modele [model]="model"></app-campagne-modele>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<app-modal-apercu-template id="modalTemplate" [models]="models"></app-modal-apercu-template>
<app-modal-buy-template id="modalBuyTemplate" [models]="models"></app-modal-buy-template>

