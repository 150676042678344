import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CampagneService} from '../../_services/campagne.service';
import * as moment from 'moment';

@Component({
    selector: 'app-consommation-emails',
    templateUrl: './consommation-emails.component.html',
    styleUrls: ['./consommation-emails.component.scss']
})
export class ConsommationEmailsComponent implements OnInit, AfterViewInit {

    public moment: any = moment;
    public panelOpenState = false;
    public consommation = [];
    public type = 'email';

    constructor(private campagneService: CampagneService) {
    }

    ngOnInit(): void {
        this.campagneService.getConsommationEmails(this.type).subscribe(datas => {
            this.consommation = datas;
        });
    }

    ngAfterViewInit(): void {
    }

}
