<div *ngIf="!uploadedFile?.originalName">
    <label class="btn btn-med btn-add-file">Ajouter un fichier
        <input type="file" (change)="handle($event)" [accept]="accept.join(', ')"/>
    </label>
</div>
<ng-container *ngIf="uploadedFile?.originalName">
    <div class="flex gap-2 items-center">
        <a class="logo" [href]="uploadedFile.url" [download]="uploadedFile.originalName" target="_blank" *ngIf="uploadedFile.url">
            <img *ngIf="uploadedFile.mimeType?.startsWith('image'); else documentTemplate" [src]="uploadedFile.url" alt="Logo du cabinet" [title]="uploadedFile.originalName">
            <ng-template #documentTemplate>
                <p class="text-black text-semi-bold" [title]="uploadedFile.originalName">
                    <i class="fa-solid fa-file-lines"></i>&nbsp;{{ uploadedFile.originalName }}
                </p>
            </ng-template>
        </a>
        <div class="flex gap-2">
            <label class="btn btn-picto-med btn-editer">
                <input type="file" (change)="handle($event)" [accept]="accept.join(', ')"/>
            </label>
            <button type="button" class="btn btn-picto-med btn-supprimer" (click)="remove()"></button>
        </div>
    </div>
</ng-container>
<p *ngIf="error" class="mat-error">{{error}}</p>
