const ssoHostname = 'sso-test.azko.app';
const apiHostname = 'api-test.upsell.azko.app';

export const environment = {
    production: false,
    environmentName: 'TEST',
    ssoHostname,
    apiHostname,
    authURL: `https://${ssoHostname}/`,
    apiURL : `https://${apiHostname}/api/v1/`,
    apiRootURL : `https://${apiHostname}/`,
    ssoRootURL :`https://${ssoHostname}/`,
    parentsOrChildrenPMEndPoint: 'parents-or-children/morales'
};
