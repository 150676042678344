<div class="coordonnees warning" *ngIf="!hasProperty(coordonnees, 'emails')">
    <div class="container_coordonnee">
        <div class="border_icon_coordonnee icon mail"></div>
        <div class="data_coordonnee"
             [ngClass]="{'background_alert_recommended': emailPrincipal === 0}">
            <div class="input_container">
                <div class="icon i_alerte"></div>
                <div>
                    <label class="type_coordonnee">Veuillez saisir au moins une adresse mail</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="coordonnees warning" *ngIf="!hasProperty(coordonnees, 'telephones')">
    <div class="container_coordonnee">
        <div class="border_icon_coordonnee icon phone"></div>
        <div class="data_coordonnee"
             [ngClass]="{'background_alert_recommended': telPrincipal === 0}">
            <div class="input_container">
                <div class="icon i_alerte"></div>
                <div>
                    <label class="type_coordonnee">Veuillez saisir au moins un numéro de téléphone</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="coordonnees" *ngIf="coordonnees">
    <div class="container_coordonnee" *ngIf="hasProperty(coordonnees, 'emails')">
        <div class="border_icon_coordonnee icon mail"></div>
        <div class="data_coordonnee">
            <div *ngFor="let email of coordonnees.emails" class="input_container">
                <input name="'email'"
                       (change)="onChangeRadio(email.uuid, 'emails')"
                       type="radio"
                       [checked]="email.principal || coordonnees.emails.length === 1"
                       #email
                />
                <div class="container_radio">
                    <label class="type_coordonnee">E-mail</label>
                    <span>{{email.valeur}}
                        <i class="icon"
                           [ngClass]="{
                                        'question-circle': (email.consent === COORD_GLOBALS.CONSENT.ND.id && email.statut_id === 1) ,
                                        'inactif': email.consent === COORD_GLOBALS.CONSENT.INVALID.id || email.consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id  || email.statut_id === 2,
                                        'actif': (email.consent === COORD_GLOBALS.CONSENT.VALID.id || (this.personStatut === 'membre'))
                            }"
                           *ngIf="this.personType !== PERSON_GLOBALS.PERSON_LIEN_MORALE
                            && (this.personStatut !== PERSON_GLOBALS.PERSON_STATUT_MEMBRE && this.personStatut !== PERSON_GLOBALS.PERSON_STATUT_ADVERSE); else otherPersons"
                           matTooltip="{{
                                email.statut_id === 2 ? 'Statut : Invalide'
                                : email.consent === COORD_GLOBALS.CONSENT.INVALID.id ? 'Consentement : Non'
                                : email.consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id ? 'Consentement : Désabonné'
                                : email.consent === COORD_GLOBALS.CONSENT.VALID.id ? 'Consentement : Oui'
                                : 'Consentement : Non-renseigné'
                           }}"
                           matTooltipClass="tooltip-consent-coordonnee"
                        ></i>
                        <ng-template #otherPersons>
                            <i class="icon"
                               [ngClass]="{
                                    'inactif': email.statut_id === -1 || email.statut_id === 2,
                                    'actif': email.statut_id === 1
                                    }" *ngIf="this.personType === PERSON_GLOBALS.PERSON_LIEN_MORALE ||
                                    (this.personType !== PERSON_GLOBALS.PERSON_LIEN_MORALE &&
                                    (this.personStatut === PERSON_GLOBALS.PERSON_STATUT_MEMBRE || this.personStatut === PERSON_GLOBALS.PERSON_STATUT_ADVERSE))"
                               matTooltip="{{ email.statut_id === -1 || email.statut_id === 2 ? 'Statut : Invalide' : 'Statut : Actif'}}"
                               matTooltipClass="tooltip-consent-coordonnee"
                            ></i>
                        </ng-template>
                        </span>
                </div>
                <div class="container_bouton_coordonees">
                    <ng-container
                            *ngIf=" email.consent !== COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id  then editable; else locked"></ng-container>
                    <ng-template #editable>
                        <button type="button" class="btn btn-picto-med btn-editer" (click)="onEditer(email)"></button>
                        <button type="button" class="btn btn-picto-med btn-supprimer"
                                (click)="onSupprimer(email.uuid, 'emails')"></button>
                    </ng-template>
                    <ng-template #locked>
                        <button type="button" class="btn btn-picto-med btn-editer btn_disabled"></button>
                        <button type="button" class="btn btn-picto-med btn-supprimer btn_disabled"></button>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="container_coordonnee" *ngIf="hasProperty(coordonnees, 'telephones')">
        <div class="border_icon_coordonnee icon phone"></div>
        <div class="data_coordonnee">
            <div *ngFor="let telephone of coordonnees.telephones" class="input_container">
                <input name="'telephone'"
                       (change)="onChangeRadio(telephone.uuid, 'telephones')"
                       type="radio"
                       [checked]="telephone.principal || coordonnees.telephones.length === 1"
                />
                <div class="container_radio">
                    <label class="type_coordonnee">Téléphone</label>
                    <span>
                        <ng-container *ngIf="telephone.indicatif">
                            {{getIndicatifLibelle(telephone.indicatif)}}
                        </ng-container>
                    </span>
                    <span>{{telephone.valeur}}
                        <i class="icon"
                           [ngClass]="{
                            'inactif': telephone.statut_id === 2,
                            'actif': telephone.statut_id === 1
                            }"
                           matTooltip="{{ telephone.statut_id === -1 || telephone.statut_id === 2 ? 'Statut : Invalide' : 'Statut : Actif'}}"
                           matTooltipClass="tooltip-consent-coordonnee"
                        ></i></span>
                </div>
                <div class="container_bouton_coordonees">
                    <button type="button" class="btn btn-picto-med btn-editer" (click)="onEditer(telephone)"></button>
                    <button type="button" class="btn btn-picto-med btn-supprimer"
                            (click)="onSupprimer(telephone.uuid, 'telephones')">
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container_coordonnee" *ngIf="coordonnees.faxes.length">
        <div class="border_icon_coordonnee icon faxe"></div>
        <div class="data_coordonnee">
            <div *ngFor="let fax of coordonnees.faxes" class="input_container">
                <input
                        name="'fax'"
                        (change)="onChangeRadio(fax.uuid, 'faxes')"
                        type="radio"
                        [checked]="fax.principal || coordonnees.faxes.length === 1"
                />
                <div class="container_radio">
                    <label class="type_coordonnee">Fax</label>
                    <span>
                        <ng-container *ngIf="fax.indicatif">
                            {{getIndicatifLibelle(fax.indicatif)}}
                        </ng-container>
                    </span>
                    <span>{{fax.valeur}}
                        <i class="icon"
                           [ngClass]="{
                            'inactif': fax.statut_id === 2,
                            'actif': fax.statut_id === 1
                           }"
                           matTooltip="{{ fax.statut_id === -1 || fax.statut_id === 2 ? 'Statut : Invalide' : 'Statut : Actif'}}"
                           matTooltipClass="tooltip-consent-coordonnee"
                        ></i></span>
                </div>
                <div class="container_bouton_coordonees">
                    <button type="button" class="btn btn-picto-med btn-editer" (click)="onEditer(fax)"></button>
                    <button type="button" class="btn btn-picto-med btn-supprimer"
                            (click)="onSupprimer(fax.uuid, 'faxes')"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="container_coordonnee" *ngIf="coordonnees.sites.length">
        <div class="border_icon_coordonnee icon site"></div>
        <div class="data_coordonnee">
            <div *ngFor="let site of coordonnees.sites" class="input_container">
                <input
                        name="'site'"
                        (change)="onChangeRadio(site.uuid, 'sites')"
                        type="radio"
                        [checked]="site.principal || coordonnees.sites.length === 1"
                />
                <div class="container_radio">
                    <label class="type_coordonnee">Site</label>
                    <span>{{site.valeur}}
                        <i class="icon"
                           [ngClass]="{
                            'inactif': site.statut_id === 2,
                            'actif': site.statut_id === 1
                            }"
                           matTooltip="{{ site.statut_id === -1 || site.statut_id === 2 ? 'Statut : Invalide' : 'Statut : Actif'}}"
                           matTooltipClass="tooltip-consent-coordonnee"
                        ></i></span>
                </div>
                <div class="container_bouton_coordonees">
                    <button type="button" class="btn btn-picto-med btn-editer" (click)="onEditer(site)"></button>
                    <button type="button" class="btn btn-picto-med btn-supprimer"
                            (click)="onSupprimer(site.uuid, 'sites')"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="bloc_container" *ngIf="display === true">
        <button type="button" class="btn btn-med btn-ajouter" (click)="onAjouter()">
            <span>Ajouter une coordonnée</span>
        </button>
    </div>
</div>
<app-coordonnees-edit-modal
    #coordonneeModal
    [Acoordonnes]="coordonnees"
    [personType]="this.personType"
    [personStatut]="this.personStatut"
    (added)="updateCoordonnee($event)"
></app-coordonnees-edit-modal>
