import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PersonGlobals} from '../../../../const-global/globals';
import {Personne} from '../../../../models/personne';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {distinctUntilChanged} from 'rxjs/operators';
import {VisibilityPipe} from "../../../_pipe/visibility.pipe";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-informations-detaillees',
    templateUrl: './informations-detaillees.component.html',
    styleUrls: ['./informations-detaillees.component.scss']
})
export class InformationsDetailleesComponent implements OnInit, OnDestroy {

    @Input() personne: Personne;
    public AMoreField = [];
    public creation = '';
    Breakpoints = Breakpoints;
    currentBreakpoint: string = '';
    subscriptions: Subscription;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private visibilityPipe: VisibilityPipe,
    ) {
    }

	ngOnInit(): void {
        this.subscriptions = this.breakpointObserver
            .observe([Breakpoints.Large, Breakpoints.Web, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
            .pipe(distinctUntilChanged())
		    .subscribe(() => this.breakpointChanged());

		if (this.personne?.creationNom && this.personne?.creationPrenom) {
			this.creation = this.personne?.creationNom + ' ' + this.personne?.creationPrenom;
		}
		this.moreFieldOnFiche();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private breakpointChanged() {
        if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
            this.currentBreakpoint = Breakpoints.Large;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
            this.currentBreakpoint = Breakpoints.Medium;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
            this.currentBreakpoint = Breakpoints.Small;
        } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
            this.currentBreakpoint = Breakpoints.XSmall;
        } else if (this.breakpointObserver.isMatched(Breakpoints.Web)) {
            this.currentBreakpoint = Breakpoints.Web;
        }
    }

    moreFieldOnFiche(): void {
        this.AMoreField = [];
        const visibility = this.visibilityPipe.transform(this.personne?.visibilite);
        // @ts-ignore
        const isApporteur = this.personne?.countApporte.toString();
        const infoCommercial = this.personne?.personnePhysique?.infoCommerciale ? 'Non' : 'Oui';

        if (this.personne?.type === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
            this.AMoreField = [
                {key: 'Créer par', value: this.creation},
                {key: 'Visibilité', value: visibility},
                {key: 'Contacts apportés', value: isApporteur},
                {key: 'Accepte de recevoir des informations commerciales', value: infoCommercial}
            ];
        }

        if (this.personne?.type === PersonGlobals.PERSON_LIEN_MORALE) {
            this.AMoreField = [
                {key: 'Créer par', value: this.creation},
                {key: 'Visibilité', value: visibility},
                {key: 'Numéro de siret', value: this.personne?.siret},
                {key: 'Numéro de TVA intracommunautaire', value: this.personne?.tvaCom},
                {key: 'Code activité (NAF)', value: this.personne?.codeNaf},
                {key: 'Code activité EU (NACE)', value: this.personne?.codeNace},
                {key: 'Organisation parente', value: this.personne?.organisationParenteLibelle},
                {key: 'Chiffre d\'affaire', value: this.personne?.chiffreAffaireLibelle}
            ];
        }

        if (this.personne?.type === PersonGlobals.PERSON_LIEN) {
            this.AMoreField = [
                {key: 'Créer par', value: this.creation},
                {key: 'Visibilité', value: visibility},
                {key: 'Numéro de siret', value: this.personne?.siret},
                {key: 'Numéro de TVA intracommunautaire', value: this.personne?.tvaCom},
                {key: 'Code activité (NAF)', value: this.personne?.codeNaf},
                {key: 'Code activité EU (NACE)', value: this.personne?.codeNace},
                {key: 'Organisation parente', value: this.personne?.organisationParenteLibelle},
                {key: 'Chiffre d\'affaire', value: this.personne?.chiffreAffaireLibelle},
                {key: 'Contacts apportés', value: isApporteur},
                {key: 'Accepte de recevoir des informations commerciales', value: infoCommercial}
            ];
        }
    }
}
