import {Injectable} from '@angular/core';

const TOKEN_KEY = 'auth-token';
const TOKEN_REFRESH_KEY = 'auth-token-refresh';
const USER_KEY = 'auth-user';



@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() { }

    signOut(): void {
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(TOKEN_REFRESH_KEY);
        localStorage.removeItem('statutValue');
    }

    public saveToken(token: string): void {
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    public saveRefreshToken(tokenRefresh: string): void {
        window.localStorage.setItem(TOKEN_REFRESH_KEY, tokenRefresh);
    }
    public getToken(): string | null {
        return window.localStorage.getItem(TOKEN_KEY);
    }

    public getRefreshToken(): string | null {
        return window.localStorage.getItem(TOKEN_REFRESH_KEY);
    }

    public saveUser(user: any): void {
        user.refreshAt = (user.exp * 1000) - 30000;
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    public getUser(): any {
        const user = window.localStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user);
        }
        return {};
    }
}
