<div class="container" [formGroup]="this.form">
    <ng-select
        #type
        [items]="ConsentHelper.consentType"
        bindValue="id"
        bindLabel="text"
        id="modalConsentType"
        formControlName="consent_type"
        (change)="update()"
        [clearable]="false"
    >
        <ng-template ng-label-tmp let-item="item">
            {{item.text}}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{item.text}}
        </ng-template>
    </ng-select>
    <ng-container *ngIf="this.form.get('consent_type').value === COORD_GLOBALS.CONSENT.TYPE.AUTRE.id">
        <input
                type="text"
                id="modalCustomConsent"
                formControlName="consent_custom_type"
                (change)="update()"
                (keydown)="update()"
        />
    </ng-container>
</div>
