import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-campagne',
    templateUrl: './campagne.component.html',
    styleUrls: ['./campagne.component.scss']
})
export class CampagneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
