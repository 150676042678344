<ng-select
    [bindLabel]="label"
    [loading]="loading"
    [items]="items"
    [formControl]="control"
    [virtualScroll]="true"
    [multiple]="multiple"
    [addTag]="addTag"
    [clearable]="clearable"
    [typeahead]="input$"
    [readonly]="readOnly"
    (scroll)="scroll($event)"
    (open)="onOpen()"
    placeholder="{{placeHolder}}"
>
</ng-select>
