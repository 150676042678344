import {Component, ViewEncapsulation} from '@angular/core';
import {PersonGlobals} from '../../../../const-global/globals';
import {CheckboxFilterItemComponent} from '../checkbox-filter-item/checkbox-filter-item.component';

@Component({
    selector: 'app-personne-type-filter-item',
    templateUrl: '../checkbox-filter-item/checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class PersonneTypeFilterItemComponent extends CheckboxFilterItemComponent {

    dataSource = [
        {
            uuid: PersonGlobals.PERSON_LIEN_PHYSIQUE,
            titre: 'Personne physique'
        },
        {
            uuid: PersonGlobals.PERSON_LIEN_MORALE,
            titre: 'Personne morale'
        },
        {
            uuid: PersonGlobals.PERSON_LIEN,
            titre: 'Personne fonction'
        },
    ];
}
