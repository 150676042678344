import {HttpClient, HttpHeaders, HttpParams,} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import IHydraCollection from '../../interfaces/hydra-collection';

const API_URL = environment.apiURL;

export interface Options {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams | object;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

export abstract class AbstractClient<T, U extends {} = {}> {

    defaultOptions = {
        headers: new HttpHeaders(
            {
                accept: 'application/ld+json'
            }
        )
    };

    protected constructor(protected http: HttpClient, private baseUrl: string = API_URL, options: Options = {}) {
        this.defaultOptions = {...this.defaultOptions, ...options};
    }

    // tslint:disable-next-line:no-shadowed-variable
    request<T>(method: string, endPoint: string = '', options = {}): Observable<T | IHydraCollection<T> | any> {
        return this.http.request<T>(method, this.baseUrl + endPoint, {...this.defaultOptions, ...options});
    }

    list(filter?: U): Observable<IHydraCollection<T>> {
        const params = Object.fromEntries(
            Object.entries(filter).filter(([, v]) => {
                return !(null === v || undefined === v);
            })
        );

        return this.request('GET', '', {params});
    }

    detail(uuid: string, options: Options = {}): Observable<T> {
        return this.request('GET', `/${uuid}`, options);
    }

    add(body: T): Observable<T> {
        return this.request(
            'POST',
            ``,
            {body, headers: new HttpHeaders({'Content-Type': 'application/json'})}
        );
    }

    update(uuid: string, body: T): Observable<T> {
        return this.request(
            'PUT',
            `/${uuid}`,
            {body, headers: new HttpHeaders({'content-Type': 'application/json'})}
        );
    }

    patch(uuid: string, body: Partial<T>): Observable<T> {
        return this.request(
            'PATCH',
            `/${uuid}`,
            {body, headers: new HttpHeaders({'content-type': 'application/merge-patch+json'})}
        );
    }

    delete(uuid: string): Observable<T> {
        return this.request('DELETE', `/${uuid}`);
    }
}
