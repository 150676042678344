import {IDevis} from '../interfaces/devis';

export class Devis implements IDevis{
    '@id'?: string;
    'uuid'?: string;
    reference?: string;
    referencePerso?: string;
    libelle?: string;
    dureeValidite?: number;
    commentaire?: string;
    lignes?: any;
    lead?: any;
    dateCreation?: Date;
    dateModification?: Date;
    userCreation?: any;
    userModification?: any;
    montantHT?: number;
    montantTTC?: number;
    montantsTVA?: [];
    montantsDevis?: any;

    constructor(data?: object) {
        Object.assign(this, data);
    }
}
