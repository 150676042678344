import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractClient} from './abstract-client';
import {environment} from '../../environments/environment';
import {IPersonnelien} from '../../interfaces/personne-lien';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import PersonneSimilarStat from "../../models/personne-similar-stat";
import IHydraCollection from "../../interfaces/hydra-collection";
import PersonneSimilarities from "../../models/personne-similarities";

export const LABEL_STATS = [
    {
        "level": "",
        "label": "",
    },
    {
        "level": "very_weak",
        "label": "DOUBLONS POTENTIELS",
    },
    {
        "level": "weak",
        "label": "DOUBLONS PROBABLES",
    },
    {
        "level": "strong",
        "label": "DOUBLONS TRÈS PROBABLES",
    },
    {
        "level": "very_strong",
        "label": "DOUBLONS AVÉRÉS",
    },
]

const API_URL = environment.apiURL;

export type Filter = {
    active?: boolean;
    page?: number;
    itemsPerPage?: number
    partialPagination?: boolean
    'groups[]'?: string[];
    'groups'?: string;
    'personsSimilar.similarityLevel'?: number
    'similarTo.similarityLevel'?: number
    similarityLevel?: number
    'similarity'?: string
    'type[]'?: string[];
    'statut.libelle[]'?: string[] | Object | string;
    'visibilite[]'?: string[] | Object | string;
    'search'?: string;
    'not:uuid[]'?: string[]
    'segment'?: string;
    writable?: boolean;
    'order[countApportes]'?: 'asc' | 'desc';
    personReferent?: number;
};

@Injectable({
    providedIn: 'root'
})
export class PersonneLienService extends AbstractClient<IPersonnelien, Filter> {
    searchRaisonSocialeSubject = new Subject<string>();
    activeSubject = new BehaviorSubject<boolean>(true);

    constructor(http: HttpClient) {
        super(http, API_URL + 'personne_liens');
    }

    similarities(params: Filter = {}): Observable<IHydraCollection<PersonneSimilarities>> {
        return this.request("GET", "/similarities", {params})
    }

    statsSimilarity(params: Filter = {}): Observable<IHydraCollection<PersonneSimilarStat>> {
        return this.request("GET", "/stats/similarity", {params: {...params, writable: true}})
    }
}
