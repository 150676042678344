import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {UploadedFile} from "../../interfaces/uploaded-file";

@Component({
    selector: 'app-file-field',
    templateUrl: './file-field.component.html',
    styleUrls: ['./file-field.component.scss']
})
export class FileFieldComponent implements OnInit {

    @Input() uploadedFile!: UploadedFile
    @Output() uploadedFileChange = new EventEmitter<null | UploadedFile>();
    @Output() fileChange = new EventEmitter<null | File>();
    @Input() accept: string[] = [];
    @Input() error;

    constructor(
        private domSanitizer: DomSanitizer,
    ) {
    }

    ngOnInit(): void {
    }

    handle($event: Event) {
        const input = $event.target as HTMLInputElement;

        if (!input.files?.length) {
            return;
        }

        const file = input.files[0];
        this.fileChange.emit(file)
        this.uploadedFileChange.emit(this.uploadedFile = {
            url: this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file)),
            mimeType: file.type,
            originalName: file.name,
        });
    }

    remove(): void {
        this.uploadedFileChange.emit(this.uploadedFile = null);
        this.fileChange.emit(null);
    }
}
