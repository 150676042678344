<div id="ariane">
    <ol class="fil_ariane">
        <li *ngFor="let filAriane of breadcrumbs; let i = index">
            <ng-container *ngIf="filAriane.noRouting === true; else breadcrumbslink;">
                <span class="no-link">
                    {{ filAriane.label }}
                </span>
            </ng-container>
            <ng-template #breadcrumbslink>
                <span [routerLink]="filAriane.url" routerLinkActive="router-link-active">
                  {{ filAriane.label }}
                </span>
            </ng-template>
        </li>
    </ol>
</div>
