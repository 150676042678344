import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from '../_services/token-storage.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(
        private tokenStorage: TokenStorageService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        if (this.tokenStorage.getToken()) {
            this.router.navigate(['home']);
        }
    }
}
