<div class="champs_perso">
    <ng-container *ngIf="listeChampsVisibles.length === 0">
        <div style="text-align: center;">Aucun champ personnalisé</div>
    </ng-container>
    <div [formGroup]="form"
         *ngFor="let champ of listeChampsVisibles"
         class="input_container select2_sans_majuscule">
        <div class="custom_title select2_sans_badge input_lg">
            <ng-select2
                    [data]="data | async"
                    [options]="options"
                    [value]="champ.uuid"
                    [className]="'typeSelect'"
                    [disabled]="champ.disabled"
                    placeholder=""
                    id="{{champ.uuid}}"
                    (valueChanged)="onChampChange($event, champ.uuid)"
                    #select2
            ></ng-select2>
        </div>
        <div class="custom_data espace_input select2_sans_badge" [ngSwitch]="champ.type">
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_TEL_FAX.text" class="input_lg">
                <input type="tel"
                       formControlName="{{champ.uuid}}"
                       (change)="onChange($event, champ.uuid)"
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_EMAIL.text" class="input_lg">
                <input type="email"
                       formControlName="{{champ.uuid}}"
                       value=""
                       (change)="onChange($event, champ.uuid)"
                       [placeholder]=""
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_LIBRE_MULTIPLE_LINE.text">
                <textarea formControlName="{{champ.uuid}}"
                          (change)="onChange($event, champ.uuid)"
                ></textarea>
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_LIBRE_SINGLE_LINE.text" class="input_lg">
                <input type="text"
                       formControlName="{{champ.uuid}}"
                       (change)="onChange($event, champ.uuid)"
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_INTEGER.text">
                <input type="number"
                       formControlName="{{champ.uuid}}"
                       (change)="onChange($event, champ.uuid)"
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_MONEY.text">
                <input type="number"
                       formControlName="{{champ.uuid}}"
                       (change)="onChange($event, champ.uuid)"
                       step=0.01
                       min=0
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_DATE.text" class="input_lg">
                <input type="date"
                       formControlName="{{champ.uuid}}"
                       (change)="onChange($event, champ.uuid)"
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_DATE_TIME.text" class="input_lg">
                <input type="datetime-local"
                       formControlName="{{champ.uuid}}"
                       (change)="onChange($event, champ.uuid)"
                />
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_LISTE.text" class="select2_sans_badge input_lg">
                <ng-select2 [data]="champ.valeurs"
                            [options]="options"
                            formControlName="{{champ.uuid}}"
                            (valueChanged)="onValueChange($event, champ.uuid)"
                >
                </ng-select2>
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_LISTE_MULTIPLE.text" class="select2_sans_badge input_lg">
                <ng-select2 [data]="champ.valeurs"
                            [options]="optionsMultiple"
                            formControlName="{{champ.uuid}}"
                            (valueChanged)="onValueChange($event, champ.uuid)"
                >
                </ng-select2>
            </div>
            <div formArrayName="{{champ.uuid}}"
                 *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_CHECKBOX.text"
                 class="container_checkbox"
            >
                <div *ngFor="let valeur of champ.valeurs; let i = index;" [formGroupName]="i">
                    <input type="checkbox"
                           name="{{valeur.id}}"
                           class="icon"
                           (change)="onChange($event, champ.uuid)"
                           formControlName="{{valeur.id}}"
                    >
                    <label for="{{valeur.id}}">{{valeur.text}}</label>
                </div>
            </div>
            <div *ngSwitchCase="CHAMPS_GLOBAL.CHAMP_RADIO.text" class="container_radio">
                <div *ngFor="let valeur of champ.valeurs">
                    <input type="radio"
                           id="{{valeur.id}}"
                           name="{{champ.uuid}}"
                           value="{{valeur.id}}"
                           (change)="onChange($event, champ.uuid)"
                           formControlName="{{champ.uuid}}">
                    <label for="{{valeur.id}}">{{valeur.text}}</label>
                </div>
            </div>
            <p *ngSwitchDefault></p>
        </div>
        <button type="button" *ngIf="!champ.requis; else actionDisabled"
                class="btn btn-picto-lg btn-supprimer"
                (click)="onSupprimer(champ.uuid)"
                type="button"
        ></button>
        <ng-template #actionDisabled>
            <button class="btn btn-picto-lg btn-supprimer btn_disabled"
                    [ngClass]="{'btn_disabled' : champ.requis === true}"
                    matTooltip="Ce champ est obligatoire, il ne peut pas être remplacé/supprimé"
                    matTooltipClass="tooltip-alert"
                    aria-label="tooltip"
                    type="button"
            ></button>
        </ng-template>
    </div>
    <div class="action_bloc">
        <ng-container *ngIf="this.listeChampsVisibles.length < this.listeChamps.length; else btnDisabled">
            <button type="button" class="btn btn-med btn-ajouter" (click)="ajouterChamp()"><span>Ajouter</span></button>
        </ng-container>
        <ng-template #btnDisabled>
            <button type="button" class="btn btn-med btn-ajouter btn_disabled"
                    matTooltip="Plus aucun champ disponible"
                    matTooltipClass="tooltip-alert"
                    aria-label="tooltip"><span>Ajouter</span>
            </button>
        </ng-template>
    </div>
</div>
