import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import {DevisSettingService} from '../../_services/devis-setting.service';
import {OrganisationService} from '../../_services/organisation.service';
import {IOrganisation} from '../../../interfaces/organisation';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, finalize, mergeMap, tap} from 'rxjs/operators';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import {CKEditor5} from '@ckeditor/ckeditor5-angular';
import {NotifierService} from 'angular-notifier';
import {HttpEventType} from '@angular/common/http';
import {ProgressBarMode} from '@angular/material/progress-bar/progress-bar';
import {MessageGlobals} from '../../../const-global/messages';
import {PreviousRouteService} from '../../_services/previous-route.service';


@Component({
    selector: 'app-configuration-generale',
    templateUrl: './configuration-generale.component.html',
    styleUrls: ['./configuration-generale.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationGeneraleComponent implements OnInit, OnDestroy {
    public Editor = ClassicEditor;
    config: CKEditor5.Config = {
        language: 'fr',
        toolbar: {
            items: [
                'undo', 'redo',
                '|', 'heading',
                '|', 'bold', 'italic',
                '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
            ],
            shouldNotGroupWhenFull: false
        }
    };
    form: FormGroup;
    formData = new FormData();
    organization: IOrganisation = {devisSetting: {logo: null, cgi: null}};
    files: { [key: string]: File } = {};
    uploadProgress: number;
    querying = false;
    progressMode: ProgressBarMode = 'determinate';
    backUrl: string;
    subscription: Subscription = new Subscription();
    hasFile: boolean;
    fileErrors: { [key: string]: string } = {};

    constructor(
        private devisSettingService: DevisSettingService,
        private organisationService: OrganisationService,
        private formBuilder: FormBuilder,
        private notifier: NotifierService,
        private previousRouteService: PreviousRouteService,
    ) {
    }

    get devisControls(): FormGroup {
        return this.form.get('devisSetting') as FormGroup;
    }

    ngOnInit(): void {
        this.backUrl = this.previousRouteService.getPreviousUrl();
        this.form = this.formBuilder.group({
            accountingExerciseDate: ['', [dayMonthValidator()]],
            devisSetting: this.formBuilder.group({
                footer: [''],
            }),
        }, {});

        this.organisationService.detail().subscribe(organization => {
            this.setOrganization(organization);
            this.form.patchValue(this.organization);
        });
    }

    setOrganization(organization: IOrganisation): void {
        organization.accountingExerciseDate = organization.accountingExerciseDate?.split('T')[0];
        this.organization = organization;
    }

    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
        this.querying = true;
        const formData = new FormData();
        let sendFile = false;

        Object.entries(this.files).forEach(([k, v]) => {
            sendFile = true;
            formData.append(k, v);
        });
        this.files = {};
        this.hasFile = false;
        this.fileErrors = {};

        let obs: Observable<any> = of({});

        if (sendFile) {
            const fileObs = this.devisSettingService
                .upload(this.organization.dbId, formData)
                .pipe(
                    catchError((error) => {
                        this.notifier.notify('error', 'Une erreur est survenue lors de l\'envoi du fichier');
                        if (error.error?.violations?.length) {
                            error.error.violations.forEach((violation) => {
                                this.fileErrors[violation.propertyPath] = violation.message;
                            });
                        }
                        return throwError(error);
                    }),
                    tap(event => {
                        if (event.type === HttpEventType.UploadProgress) {
                            this.progressMode = 'determinate';
                            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                        }
                    }),
                );
            obs = obs.pipe(mergeMap(() => fileObs));
        }
        if (this.form.dirty) {
            this.progressMode = 'indeterminate';
            const orgObs = this.organisationService
                .update(this.form.value)
                .pipe(
                    catchError((error) => {
                        if (error.error?.violations?.length) {
                            error.error.violations.forEach((violation) => {
                                this.form.get(violation.propertyPath).setErrors({help: violation.message});
                            });
                        }

                        this.notifier.notify('error', 'Une erreur est survenue lors de la mise à jour des données');

                        return throwError(error);
                    }),
                    tap(organization => {
                        this.setOrganization(organization);
                        this.form.patchValue(this.organization);
                        this.form.markAsPristine();
                    })
                );
            obs = obs.pipe(mergeMap((event) => {
                if (undefined === event.type
                    || event.type === HttpEventType.Response
                ) {
                    return orgObs;
                }
                return of(event);
            }));
        }

        obs
            .pipe(finalize(() => this.querying = false))
            .subscribe((event) => {
            if (undefined === event.type
                || event.type === HttpEventType.Response
            ) {
                this.notifier.notify('success', MessageGlobals.MESSAGE_SUCCESS);
            }
        });
    }

    handleFile(propName: string, file?: File, validators?: Array<ValidatorFn>): void {
        if (!this.form.get('devisSetting.' + propName)) {
            this.devisControls.addControl(propName, new FormControl(file, validators || []));
        } else {
            this.form.get('devisSetting.' + propName).setValue(file);
        }

        this.form.get('devisSetting').updateValueAndValidity();

        this.files[`${propName}[file]`] = file;
        this.hasFile = true;
        this.fileErrors = {};
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onInputAccountingExerciseDate($event: InputEvent): void {
        const target = $event.target as HTMLInputElement;
        let pos = target.selectionStart;
        if ($event.data && !$event.data.match(/(\d|\/)/)) {
            pos--; // remove the last char
        }
        const parts = target.value.split('/');
        const day = (parts[0] || '').substring(0, 2);
        const month = (parts[1] || '').substring(0, 2);


        if ('insertText' === $event.inputType) {
            if (pos === 2) {
                pos++;
            }
            target.value = [day, month].join('/');
        }

        target.setSelectionRange(pos, pos);
        this.form.get('accountingExerciseDate').setValue(target.value);
    }

    focusOutAccountingExerciseDate($event: FocusEvent): void {
        const target = $event.target as HTMLInputElement;
        const value = target.value.replace(/[^\d\/]/g, '');
        const parts = value.split('/');
        if (parts[1]) {
            parts[0] = parts[0].padStart(2, '0');
            parts[1] = parts[1].padStart(2, '0');
        }
        target.value = parts.join('/');
        this.form.get('accountingExerciseDate').setValue(target.value);
    }

    /**
     * check if current size is above max size value
     * @param limit max size value in megabytes (ex: 0.7 = 0.7Mb, 10 = 10Mb)
     */
    public fileSizeValidator(limit: number): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(limit)) {
                return;
            }
            const file = control.value;
            if (file) {
                const fileSizeInKB = Math.round(file.size / 1024);
                if (fileSizeInKB >= (limit * 1000)) {
                    return {
                        fileSizeValidator: true,
                        error: 'Le fichier importé est trop lourd. Veuillez le modifier.'
                    };
                } else {
                    return null;
                }
            }
            return null;
        };
    }
}

function isEmptyInputValue(value: any): boolean {
    return value == null || value.length === 0;
}

function dayMonthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const parts = control.value.split('/');
        const day = parts[0];
        const month = parts[1];
        const bissextile = '2020';
        const date = new Date(`${month}/${day}/${bissextile}`);
        if (isNaN(date.getDate())) {
            return {dayMonthInvalid: true};
        }

        return null;
    };
}
