<div class="form_segment form_modifier" >
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form [formGroup]="form">
        <div class="bandeau_segment_formulaire bandeau_segment icon search">
            <h1>{{ this.form.get('titre').value}}</h1>
        </div>
        <div class="formulaire">
            <div class="container_form form_gauche">
                <div class="bloc_information_generale">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div class="full_width">
                            <div>
                                <label for="intitule">Intitulé</label>
                                <input
                                        type="text"
                                        id="intitule"
                                        name="titre"
                                        formControlName="titre"
                                        [ngClass]="{'background_alert_required': !form.controls.titre.value}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc_champs_personalises">
                    <div class="bandeau_form">Filtres</div>
                    <div class="bloc_container bloc_container_filtre" formArrayName="filtres" *ngIf="listeChamps">
                        <div class="bloc_filtre_segment">
                            <ngx-ui-loader [loaderId]="'filtres'"></ngx-ui-loader>
                            <ng-container *ngIf="filtres">
                                <div class="edit_segment" *ngFor="let filtre of filtres.controls; index as i ">
                                    <div>
                                        <label *ngIf="i === 0" class="label_input_container">
                                            Extraire la liste des contacts dont...
                                        </label>
                                        <div class="input_container">
                                        <span *ngIf="i > 0">
                                            et...
                                        </span>
                                        </div>
                                        <div class="input_container filtres">
                                            <div class="espace_input select2_sans_badge input_lg"
                                                 *ngIf="listeChamps && filtre.value.champ">
                                                <app-champs-requetables-select2
                                                        id="{{filtre.value.uuid}}"
                                                        [filtre]="filtre"
                                                        [form]="form"
                                                        [champ]="filtre.value.champ"
                                                        [listeChamps]="listeChamps"
                                                >
                                                </app-champs-requetables-select2>
                                            </div>
                                            <div class="espace_input select2_sans_badge input_lg"
                                                 *ngIf="listeOperateurs && filtre.value.operateur">
                                                <app-operateurs-select2
                                                    #operateursSelect2Component
                                                    id="ope-{{filtre.value.uuid}}"
                                                    [form]="form"
                                                    [filtre]="filtre"
                                                    [operateur]="filtre.value.operateur"
                                                    [listeOperateurs]="listeOperateurs"
                                                    [disabled]="!filtre.value.champ.value"
                                                    [refreshCustom]="filtre.value.refreshCustom.value"
                                                >
                                                </app-operateurs-select2>
                                            </div>
                                            <div class="espace_input input_valeurs"
                                                 *ngIf="listeOperateurs && filtre.value.operateur">
                                                <app-segment-edit-valeurs
                                                        id="val-{{filtre.value.uuid}}"
                                                        [form]="form"
                                                        [filtre]="filtre"
                                                        [nbValeurs]="filtre.value.operateur.value.nbValeurs"
                                                        [valeurs]="filtre.value.valeurs.value"
                                                        [refreshCustom]="filtre.value.refreshCustom.value"
                                                >
                                                </app-segment-edit-valeurs>
                                            </div>
                                            <div class="delete_filtre_segment">
                                                <button type="button" class="btn btn-picto-med btn-supprimer"
                                                        (click)="onSupprimer(filtre.value.uuid, i)"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <app-segment-chart-custom
                                *ngIf="filtres.controls.length > 0 && nbContacts"
                                [data]="nbContacts"
                                [nbFilter]="filtres.controls.length"
                        ></app-segment-chart-custom>
                    </div>
                    <div class="bloc_container">
                        <div class="action_filtre_segment">
                            <button type="button" class="btn btn-med btn-ajouter"
                                    (click)="ajouterChamp()"
                            >
                                <span>Ajouter un filtre</span>
                            </button>
                            <button type="button" class="btn btn-med btn_reload_filtre btn-actualiser"
                                    [ngClass]="{'updating': updateInProgress}"
                                    (click)="calculateFiltres()"
                                    (animationend)="animationEnd()"
                            ><span>Actualiser</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_form form_droit">
                <div class="bloc_visibilite">
                    <app-bloc-visibilite [form]="form" [type]="'segment'" [creation]="creation"></app-bloc-visibilite>
                </div>
                <div class="bloc_coordonnees">
                    <div class="bandeau_form">Prévisualisation</div>
                    <div class="bloc_container">
                        <ngx-ui-loader [loaderId]="'previsualisation'"></ngx-ui-loader>
                        <button type="button" class="btn btn-med btn_reload_filtre btn-actualiser"
                                [ngClass]="{'updating': liveUpdateInProgress}"
                                (click)="refreshResultats(segment.uuid)"
                                (animationend)="animationEnd()"
                        >
                            <span>Actualiser</span>
                        </button>
                        <div class="bloc_prev" *ngIf="resultats && showResultats">
                            <p>{{segment.nbContactsPublics}} + {{segment.nbContactsPrives}} contacts</p>
                            <div *ngFor="let res of resultats">
                                {{res.libelle}}
                                {{res.email}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form_bas">

            <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
            <button  type="button" class="btn btn-med btn-enregistrer"
                    (click)="onSubmit(true, false)"
                    [ngClass]="{'blinking': blink, 'btn-disabled': form.invalid}"
                    (animationend)="animationEnd()"
            ><span>Enregistrer</span>
            </button>
            <button type="button" class="btn btn-med btn-enregistrer" [ngClass]="{'btn-disabled': form.invalid}"
                    (click)="onSubmit(false, true)"><span>Enregistrer et fermer</span>
            </button>
        </div>
    </form>
</div>
<app-modal-confirm-exit #confirmModal (cancelEvent)="onCancel()"></app-modal-confirm-exit>
