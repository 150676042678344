<jw-modal id="confirmCancelling">
    <div class="bloc_title_modal">Êtes-vous sûr de vouloir <strong class="action_modal valider">quitter la page sans sauvegarder ?</strong></div>
    <hr>
    <div class="bloc_body_modal">Si vous quittez cette page sans sauvegarder, toutes les modifications réalisées seront perdues.</div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="cancelConfirmationModal()">
                <span>Annuler</span></button>
            <button type="submit" class="btn btn-med btn-valider" (click)="confirmExitModal()">
                <span>Quitter la page</span>
            </button>
        </div>
    </div>
</jw-modal>
