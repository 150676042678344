import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../_modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-modal-confirm-probabilite',
  templateUrl: './modal-confirm-probabilite.component.html',
  styleUrls: ['./modal-confirm-probabilite.component.scss']
})
export class ModalConfirmProbabiliteComponent implements OnInit {

  private modalOpen = false;
  public modified = new Subject<boolean>();
  @Input() probabilite: number;
  @Output() confirmEvent = new EventEmitter<boolean>();
  @Output() cancelEvent = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.modified.next(true);
  }

  public openConfirmationModal(): void {
    this.modalService.open('confirmCancelling');
    this.modalOpen = true;
  }

  public confirmConfirmationModal(): void {
    this.modalOpen = false;
    this.modified.next(false);
    this.confirmEvent.emit(true);
    this.modalService.close('confirmCancelling');
  }

  public cancelConfirmationModal(): void {
    this.modalService.close('confirmCancelling');
    this.cancelEvent.emit(true);
    this.modalOpen = false;
  }

  public isOpened(): boolean {
    return this.modalOpen;
  }

}
