<div class="bloc_onglet_fiche_incomplete"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     (scrolled)="onScroll()"
     *ngIf="tabName === RAPPEL_GLOBAL.RAPPEL_TYPE_FICHE_LIBELLE">
    <table class="tableau_fiche_incomplete" *ngIf="listePersonnes; else aucuneFicheAOptimiser">
        <thead>
        <tr>
            <th></th>
            <th>Personne</th>
            <th>Type</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let fiche of listePersonnes; let i = index" class="row_tableau">
            <td class="row_fiche_incomplete">
                <div class="icon data_personne {{ fiche.type }} row"
                     [ngClass]="{
                                 'building': fiche.type === PERSON_GLOBAL.PERSON_LIEN_MORALE ||
                                             fiche.type === PERSON_GLOBAL.PERSON_LIEN,
                                 'user': fiche.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE
                                 }"
                     (click)="openFiche(fiche.uuid)"
                >
                </div>
            </td>
            <td class="person_name"
                (click)="openFiche(fiche.uuid)">{{ fiche?.libelle  |uppercase }}</td>
            <td class="action_rappel">
                <button type="button" class="btn btn-picto-med picto-edition"
                        (click)="modifierFiche(fiche.uuid)">
                </button>
            </td>
        </tr>
        </tbody>
    </table>
    <ng-template #aucuneFicheAOptimiser>
        <div class="message_information">Vous n'avez aucune fiche à optimiser</div>
    </ng-template>
</div>
