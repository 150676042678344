import {ITag} from '../interfaces/tag';
import {IMemo} from '../interfaces/memo';
import {IOnglet} from '../interfaces/onglet';
import {IEvenement} from '../interfaces/evenement';
import {PersonneLinked} from './personnemoralelinked';

export class Personne {
    // Commun
    public uuid?: string;
    public liens?: any;
    public tags?: ITag[];
    public onglet?: IOnglet[];
    public qualite?: number;
    public evenements?: Array<IEvenement>;
    public personnePhysiqueLibelle?: string;
    public personneMoraleLibelle?: string;
    public personneLinked?: PersonneLinked;
    // coordonnées et adresses
    public adresses?: any;
    public champs?: any;
    public mails?: any;
    public sites?: any;
    public telephones?: any;
    public ville?: string;
    public cp?: string;
    public mail?: string;
    public telephone?: string;
    public indicatifTelephone?: string;
    // morale / fonction
    public pmUuid?: string;
    public raisonSociale?: string;
    public chiffreAffaire?: string;
    public chiffreAffaireLibelle?: string;
    public capital?: string;
    public effectif?: string;
    public effectifLibelle?: string;
    public formeJuridiqueLibelle?: string;
    public formeJuridique?: string;
    public organisationParente?: string;
    public organisationParenteLibelle?: string;
    public codeNace?: string;
    public codeNaf?: string;
    public siret?: string;
    // physique / fonction
    public ppUuid?: string;
    public nom?: string;
    public prenom?: string;
    public titre?: string;
    public origine?: any;
    public apporteur?: any;
    public isApporteur?: boolean;
    public infoCommerciale?: boolean;
    // Fonction
    public isComplete?: boolean;
    public fonction?: any;
    public fonctionLibelle?: string;
    public idSecib?: number;
    public visibilite?: number;
    public statut?: any;
    public userCreation?: any;
    public userModification?: any;
    public type?: string;
    public libelle?: string;
    public actif?: string | boolean;
    public creationDate?: Date;
    public modificationDate?: Date;
    public creationNom?: string;
    public modificationNom?: string;
    public creationPrenom?: string;
    public modificationPrenom?: string;
    public memos?: IMemo[];
    public civilite?: string;
    public tvaCom?: string;
    public optimized: boolean;
    public personneMorale: any;
    public personnePhysique: any;
    countApporte: number;

    fillFromDatas(personne): void {
        Object.assign(this, personne);
    }
}
