import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductService} from '../../../_services/product.service';
import {finalize} from 'rxjs/operators';
import {IProduct} from '../../../../interfaces/product';
import {Subscription} from 'rxjs';
import {Filter} from '../../../_services/personne-lien.service';

@Component({
    selector: 'app-catalogue-produits',
    templateUrl: './catalogue-produits.component.html',
    styleUrls: ['./catalogue-produits.component.scss']
})
export class CatalogueProduitsComponent implements OnInit, OnDestroy {

    #active = true;
    public products: Array<IProduct> = [];
    private productSubscription = Subscription.EMPTY;
    public activeFilter = true;

    filter: Filter = {
        itemsPerPage: 28,
        page: 1,
        search: null,
        active: this.activeFilter
    };

    constructor(
        private productService: ProductService
    ) {
    }

    ngOnInit(): void {
        this.fetchProducts();
    }

    private fetchProducts(): void{
        this.productService.list(this.filter).pipe(
            finalize(
                () => {
                })
        ).subscribe(data => {
            this.products = this.products.concat(...data['hydra:member']);
        });
    }
    onRecherche($event: string): void {
        this.products = [];
        this.filter.page = 1;
        this.filter.search = $event;
        this.fetchProducts();
    }

    ngOnDestroy(): void {
        this.productSubscription.unsubscribe();
    }
    switchActive($event): void {
        this.filter.active = $event;
        this.products = [];
        this.filter.page = 1;
        this.fetchProducts();
    }
    onScroll(): void {
        if (!this.productSubscription.closed) {
            return;
        }
        this.filter.page++;
        this.fetchProducts();

    }

}
