import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ModalService} from '../../_modal';

@Component({
	selector: 'app-campagne-modele',
	templateUrl: './campagne-modele.component.html',
	styleUrls: ['./campagne-modele.component.scss']
})
export class CampagneModeleComponent implements OnInit {

	@Input() public model: any;
	@Input() public models: any;
	@Input() public modelVierge: boolean;
	public rootImage = environment.apiRootURL + 'assets/template_campagne/';

	public isLoading: any;
	public env = environment;

	constructor(private router: Router,
	            private route: ActivatedRoute,
				private modalService: ModalService
	) {
	}

	ngOnInit(): void {
		if (this.modelVierge) {
			this.model = {
				id: 0,
				uuid: '',
				title: 'Modèle vierge',
				image: 'template_model.PNG',
				free: true
			};
		}
	}

	chooseModel(model): void {
		if (model === 'vierge') {
			this.router.navigate(['/campagne/ajouter'],
				{
					relativeTo: this.route,
					queryParamsHandling: 'merge'
				},
		);
		} else {
			this.router.navigate(['/campagne/ajouter'], {
				queryParams:
					{
						uuid_template: this.model.uuid
					},
				relativeTo: this.route,
				queryParamsHandling: 'merge'
			});
		}
	}

	openApercuTemplate(id, idTempalte): void {
		this.modalService.subjectDatas.next(idTempalte);
		this.modalService.open(id);
	}

	openModalBuyTemplate(id, idTempalte): void {
		this.modalService.subjectDatas.next(idTempalte);
		this.modalService.open(id);
	}
}
