import {Component, OnInit} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import {TagService} from '../../_services/tag.service';
import {ITag} from '../../../interfaces/tag';
import {ParametreGlobals} from '../../../const-global/globals';

@Component({
    selector: 'app-liste-tags',
    templateUrl: './liste-tags.component.html',
    styleUrls: ['./liste-tags.component.scss']
})
export class ListeTagsComponent implements OnInit {
    public ParametreGlobals = ParametreGlobals;
    public plUuid = this.route.snapshot.params.id;
    public tags: ITag[];
    public recherche: '';

    private readonly mapping = [
        {id: ParametreGlobals.TAG_ID_PERSON, label: 'Personnes'},
        {id: ParametreGlobals.TAG_ID_OFFRE, label: 'Offres'},
        {id: ParametreGlobals.TAG_ID_FULL, label: 'Personnes, Offres'}
    ];

    constructor(
        public router: Router,
        private tagService: TagService,
        private route: ActivatedRoute,
        public notifier: NotifierService
    ) {
    }

    ngOnInit(): void {
        const ls = localStorage.getItem('rechercheValue');
        if (ls){
            const lsValue = JSON.parse(ls);
            if (lsValue && lsValue.tag){
                this.onRecherche(lsValue.tag);
            }else{
                this.onRecherche('');
            }
        }else{
            this.onRecherche('');
        }
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }

    public openTag(uuid: string, systeme: string): void {
        if (systeme.toString() === ParametreGlobals.SYSTEM_ID.toString()) {
            this.showNotification('warning', 'Vous n\'avez pas les droits pour modifier les mots clés systèmes');
        } else {
            this.router.navigate(['tag/modifier/' + uuid]);
        }
    }

    public onRecherche(recherche): void {
        this.tagService.getAllTags(recherche).subscribe(apiTags => {
            this.tags = this.formatTag(apiTags);
        });
    }

    public formatTag(tags): Array<ITag> {
        tags.forEach((tag: ITag) => {
            const target = this.mapping.filter(map => map.id === Number(tag.target));
            tag.target = target.length > 0 ? target[0].label : '';
        });

        return tags;
    }
}
