<div class="container_event"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     (scrolled)="onScroll()"
>
    <div class="information_event" *ngIf="events?.length > 0; else aucunEvent">
        <div *ngFor="let event of events; let i = index">
            <div class="row_event"
                 [ngClass]="{'group_by_date' : i === 0 || getFormatFullDate(event.dateEcheance) === getFormatFullDate(events[i-1].dateEcheance)}">
                <div class="timeline"
                     [ngClass]="{'first_event_tl' : i === 0 || getFormatFullDate(event.dateEcheance) !== getFormatFullDate(events[i-1].dateEcheance) }">
                    <div class="tl planifie"
                         *ngIf="event.actif === EVENEMENT_GLOBALS.ACTIF && event.planifie && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                        <div *ngIf="i === 0 || getFormatFullDate(event.dateEcheance) !== getFormatFullDate(events[i-1].dateEcheance)">
                            <h1>{{event.dateEcheance | date : jour}}</h1>
                            <h3>{{event.moisEcheance}}</h3>
                            <h3>{{event.dateEcheance | date : annee}}</h3>
                        </div>
                    </div>
                    <div class="tl aujourdhui"
                         *ngIf="event.aujourdhui && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                        <div *ngIf="i === 0 || getFormatFullDate(event.dateEcheance) !== getFormatFullDate(events[i-1].dateEcheance)">
                            <h2>Aujourd'hui</h2>
                        </div>
                    </div>
                    <div class="tl retard"
                         *ngIf="event.enRetard && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                        <div *ngIf="i === 0 || getFormatFullDate(event.dateEcheance) !== getFormatFullDate(events[i-1].dateEcheance)">
                            <h1>{{event.dateEcheance | date : jour}}</h1>
                            <h3>{{event.moisEcheance}}</h3>
                            <h3>{{event.dateEcheance | date : annee}}</h3>
                        </div>
                    </div>
                    <div class="tl fait"
                         *ngIf="event.statut === EVENEMENT_GLOBALS.STATUT_FAIT || event.statut === EVENEMENT_GLOBALS.STATUT_ANNULE">
                        <div *ngIf="i === 0 ||
                    getFormatFullDate(event.dateEcheance) !== getFormatFullDate(events[i-1].dateEcheance)">
                            <h1>{{event.dateEcheance | date : jour}}</h1>
                            <h3>{{event.moisEcheance}}</h3>
                            <h3>{{event.dateEcheance | date : annee}}</h3>
                        </div>
                    </div>
                </div>
                <div class="event {{event.etat}}"
                     *ngIf="event.actif === EVENEMENT_GLOBALS.ACTIF"
                     [ngClass]="{
                         'blue_five_percent border_blue' : event.planifie && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                         'warning_five_percent border_orange' : event.aujourdhui && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                         'alert_five_percent border_red' : event.enRetard && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                         'grey_five_percent border_grey' : event.statut === EVENEMENT_GLOBALS.STATUT_FAIT || event.statut === EVENEMENT_GLOBALS.STATUT_ANNULE
                      }">
                    <div class="indication_status icon"
                         [ngClass]="{
                         'calendrier' : event.type.id === EVENEMENT_GLOBALS.RDV_CABINET_ID || event.type.id === EVENEMENT_GLOBALS.RDV_EXTERIEUR_ID,
                         'phone' : event.type.id === EVENEMENT_GLOBALS.APPEL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.APPEL_SORTANT_ID,
                         'mail' : event.type.id === EVENEMENT_GLOBALS.MAIL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.MAIL_SORTANT_ID,
                         'mail_clic': event.type.id === EVENEMENT_GLOBALS.MAIL_CMP_CLIC,
                         'mail_envoyer': event.type.id === EVENEMENT_GLOBALS.MAIL_CMP_DESTINATAIRE,
                         'mail_open': event.type.id === EVENEMENT_GLOBALS.MAIL_CMP_OPEN,
                         'mail_desinscription': event.type.id === EVENEMENT_GLOBALS.MAIL_CMP_DESINSCRIPTION,
                         'devis' : event.type.id === EVENEMENT_GLOBALS.DEVIS_ID,
                         'changement_statut' : event.type.id === EVENEMENT_GLOBALS.CHANGEMENT_STATUT_ID,
                         'icon_blue' : event.planifie && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                         'icon_orange' : event.aujourdhui === true && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                         'icon_red' : event.enRetard === true && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                         'icon_grey' : event.statut === EVENEMENT_GLOBALS.STATUT_FAIT || event.statut === EVENEMENT_GLOBALS.STATUT_ANNULE
                        }">
                    </div>
                    <div class="libelle_event">
                        <h2 [ngClass]="{
                             'haut' : event.type.id === EVENEMENT_GLOBALS.APPEL_SORTANT_ID || event.type.id === EVENEMENT_GLOBALS.MAIL_SORTANT_ID || event.type.id === EVENEMENT_GLOBALS.DEVIS_ID || event.type.id === EVENEMENT_GLOBALS.RDV_EXTERIEUR_ID,
                             'bas' : event.type.id === EVENEMENT_GLOBALS.APPEL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.MAIL_ENTRANT_ID || event.type.id === EVENEMENT_GLOBALS.CHANGEMENT_STATUT_ID || event.type.id === EVENEMENT_GLOBALS.RDV_CABINET_ID,
                             'color_blue' : event.planifie && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                             'color_orange' : event.aujourdhui === true && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                             'color_red' : event.enRetard === true && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                             'color_grey' : event.statut === EVENEMENT_GLOBALS.STATUT_FAIT || event.statut === EVENEMENT_GLOBALS.STATUT_ANNULE
                           }"
                            class="libelle icon">
                            {{event.titre}}
                        </h2>
                        <p class="description_event">{{event.description}}</p>
                    </div>
                    <div class="date_event">
                        <div class="icon_statut">
                            <p class="icon clock"
                               *ngIf=" event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE"
                               [ngClass]="{
                                    'planifie icon_blue' : event.planifie && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                                    'aujourdhui icon_orange' : event.aujourdhui && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE,
                                    'enRetard icon_red' : event.enRetard && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE
                                }">
                            </p>
                            <p *ngIf="event.statut === EVENEMENT_GLOBALS.STATUT_FAIT"
                               class="icon valider icon_grey">
                            </p>
                            <p *ngIf="event.statut === EVENEMENT_GLOBALS.STATUT_ANNULE"
                               class="icon annuler icon_grey">
                            </p>
                        </div>
                        <div class="date_statut">
                            <p class="color_blue"
                               *ngIf="event.planifie && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                                Planifié
                                le {{event.dateEcheance  | date : fullDateHeure}}</p>
                            <p class="color_orange"
                               *ngIf="event.aujourdhui && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                                Planifié aujourd'hui
                                à {{event.dateEcheance  | date : heureMinute}}</p>
                            <p class="color_red"
                               *ngIf="event.enRetard && event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                                En retard
                                depuis {{event.dateEcheanceCalc}}</p>
                            <p class="color_grey" *ngIf="event.statut === EVENEMENT_GLOBALS.STATUT_FAIT">Terminé
                                le {{event.dateFait  | date : fullDate}}</p>
                            <p class="color_grey" *ngIf="event.statut === EVENEMENT_GLOBALS.STATUT_ANNULE">Annulé
                                le {{event.dateModification  | date : fullDate}}</p>
                        </div>
                    </div>
                    <div class="avatar_event">
                        <ng-container *ngIf="!eventRappel && event.userAssigne?.personnePhysique">
                            <app-avatar [personne]="event.userAssigne.personnePhysique"></app-avatar>
                            <div class="data_personne">
                                <span>Assigné à :</span>
                                <div title="{{ event.userAssigne.personnePhysique.prenom | titlecase }}
                                            {{ event.userAssigne.personnePhysique.nom | uppercase }}"
                                >
                                    {{ event.userAssigne.personnePhysique.prenom | titlecase }} {{ event.userAssigne.personnePhysique.nom | uppercase }}
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="eventDashboard || eventRappel"
                             [routerLink]="['/repertoire', event.lien.uuid]"
                             class="icon data_personne {{ event.lien.type }} info-avatar-event"
                             [ngClass]="{
                                 'building inline': event.lien.type === PERSON_GLOBALS.PERSON_LIEN_MORALE ||
                                             event.lien.type === PERSON_GLOBALS.PERSON_LIEN,
                                 'user inline': event.lien.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE
                                 }">
                            <span class="info_libelle">{{event?.lien?.libelle}}</span>
                        </div>
                    </div>
                    <div class="action_event"
                         *ngIf="event.statut !== EVENEMENT_GLOBALS.STATUT_FAIT && event.statut !== EVENEMENT_GLOBALS.STATUT_ANNULE">
                        <button type="button" class="btn btn-picto-med picto-edition" *ngIf="!this.event.idMeetlaw"
                                (click)="modifierEvenement(this.event.uuid)"></button>
                        <button type="button" class="btn btn-picto-med btn-valider"
                                (click)="openModal('validateModalEvent', this.event.uuid, i)">
                        </button>
                        <button type="button" class="btn btn-picto-med btn-supprimer" *ngIf="!this.event.idMeetlaw"
                                (click)="openModal('desactiveModalEvent', this.event.uuid, i)">
                        </button>
                    </div>
                    <div class="action_event"
                         *ngIf="event">
                        <button type="button" class="btn btn-picto-med icon mail btn-med campagne" *ngIf="this.event.uuidCampagne"
                                [routerLink]="['/campagne/', this.event.uuidCampagne]"
                                title="Accéder à la campagne"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="inline_loader" [hidden]="!isLoading">
        <ngx-ui-loader [loaderId]="'list'"></ngx-ui-loader>
    </div>
</div>

<!--- modals --->
<jw-modal id="validateModalEvent">
    <div class="bloc_title_modal">Êtes-vous sûr de vouloir <strong class="action_modal valider">terminer</strong>
        l'évènement ?
    </div>
    <hr>
    <div class="bloc_body_modal">Vous ne pourrez plus annuler cette action par la suite</div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal('validateModalEvent')">
                <span>Fermer</span></button>
            <button type="submit" class="btn btn-med btn-enregistrer" (click)="terminerEvenement()">
                <span>Terminer</span>
            </button>
        </div>
    </div>
</jw-modal>
<jw-modal id="desactiveModalEvent">
    <div class="bloc_title_modal">Êtes-vous sûr de vouloir <strong class="action_modal desactiver">supprimer</strong>
        l'évènement ?
    </div>
    <hr>
    <div class="bloc_body_modal">Vous ne pourrez plus annuler cette action par la suite</div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal('desactiveModalEvent')">
                <span>Fermer</span></button>
            <button type="submit" class="btn btn-med btn-enregistrer" (click)="desactiveEvenement()">
                <span>Supprimer</span></button>
        </div>
    </div>
</jw-modal>
<!-- templates -->
<ng-template #aucunEvent>
    <div class="message_information" *ngIf="!isLoading">Aucun évenement</div>
</ng-template>
