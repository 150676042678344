import {Injectable} from '@angular/core';
import {Personne} from './personne';

@Injectable({
    providedIn: 'root',
})
export class PersonneMorale extends Personne {
    public codeNaf?: string;
    public siret?: string;
    public dateCreation?: Date;
    public dateModification?: Date;
    public organisationParente?: string;
    public personneMoraleLibelle?: string;

    public fillFromDatas(personne): void {

        super.fillFromDatas(personne);
        Object.assign(this, personne);
    }
}
