import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-navigation-item',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {
    @Input() className: string;
    @Input() faIconName;
    @Input() route: string;
    env = environment;

    constructor(public router: Router) {
    }

    ngOnInit(): void {
        if (this.route === '/statistique') {
            this.route = '/';
        }
    }

}
