import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PersonneService} from '../_services/personne.service';
import {catchError, map} from 'rxjs/operators';
import {PersonGlobals} from '../../const-global/globals';

@Injectable({ providedIn: 'root' })
export class PersonneResolver implements Resolve<Observable<any>> {
    constructor(private service: PersonneService) {}

    resolve = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> => {
        return this.service.getDatasFiche(route.paramMap.get('id')).pipe(map(personne => {

            if (PersonGlobals.PERSON_LIEN_MORALE === personne.type) {
                personne.pl_libelle = PersonGlobals.PERSON_LIEN_MORALE === personne.type
                    ? personne.personneMorale.raisonSociale
                    : personne.personnePhysique.libelle
            }

            return personne;
        }));
    }
}
