<div [ngClass]="type === 'products' ? 'bandeau_outils products' : 'bandeau_outils'">
    <div class="bloc1 {{type}}">
        <button type="button" class="btn btn-ajouter btn-med" (click)=creerTag() *ngIf="type === 'tag'">
            Ajouter un mot clé
        </button>
        <button type="button" class="btn btn-ajouter btn-med" (click)=creerChamp() *ngIf="type === 'champ'">
            Ajouter un champ
        </button>
        <button type="button" class="btn btn-ajouter btn-med" (click)=creerOrigine() *ngIf="type === 'origine'">
            Ajouter une origine de contact
        </button>
        <button type="button" class="btn btn-ajouter blue btn-med" (click)="createProduct()"
                *ngIf="type === 'products'">
            Ajouter un produit / service
        </button>
        <button *ngIf="type === 'users'"
                routerLink="/utilisateurs/creer"
                class="btn btn-ajouter btn-med btn-hidden"
                title="En cours de développement"
                disabled
                type="button"
        >
            Ajouter un utilisateur
        </button>
        <!--     TODO : En cours de dev-->
        <!--    <button class="btn btn-mode-tableau btn-picto-med" title="Affichage en mode liste" (click) = changeAffichage() *ngIf="type === 'repertoire'"></button>-->
    </div>
    <div class="bloc2 input_sm {{type}}">
        <div class="recherche-bandeau-outils">
            <input type="search"
                   class="input-selection"
                   placeholder="Recherche"
                   size="30"
                   [value]="searchValue"
                   (keydown.enter)="prevent($event)"
                   #searchfield/>
        </div>
    </div>
    <div class="checkbox_fiche_disabled after {{type}}"  *ngIf="type === 'products'">
        <input type="checkbox"
               class="switch"
               id="activeFiche"
               (change)="active = !active"
               [checked]="!!active"
        >
        <label for="activeFiche"
               class="switch icon fiche_active"
               matTooltip="{{MESSAGE_GLOBALS.MESSAGE_FICHE_ACTIVE}}"
               matTooltipClass="tooltip-default"
               aria-label="tooltip"
               *ngIf="active">
        </label>
        <label for="activeFiche"
               class="switch icon fiche_inactive"
               matTooltip="{{MESSAGE_GLOBALS.MESSAGE_FICHE_INACTIVE}}"
               matTooltipClass="tooltip-default"
               aria-label="tooltip"
               *ngIf="!active">
        </label>
    </div>

</div>
<div class="alerte_container">
    <div class="carte_alerte" *ngIf="active === false && type === 'products'">
        <div class="icon i_alerte i_alerte_sm"></div>
        <span>Vous consultez actuellement les fiches inactives.
                <a class="view_fiche_active" (click)="active = true">Cliquez ici pour revenir à la liste des fiches actives</a>
            </span>
    </div>
</div>
<div class="clear"></div>
