import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FilterItem} from '../../../../models/filteritem';

@Component({
    selector: 'app-checkbox-filter-item',
    templateUrl: './checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class CheckboxFilterItemComponent {

    @Input() filter: FilterItem;
    @Output() updated = new EventEmitter();
    dataSource: any;

    updateFilter(event: any, values: any): void {
        this.updated.emit({event, values, filter: this.filter.attributeName});
    }

    isChecked(item): boolean {
        if (Array.isArray(this.filter.value)) {
            return this.filter.value.find(i => i.uuid === item.uuid);
        }

        return (item.uuid || item) === (this.filter.value?.uuid || this.filter.value);
    }
}
