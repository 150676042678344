<div class="form_personne form_ajouter<">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form"
          [formGroup]="form"
          #f="ngForm"
          novalidate
    >
        <div class="icon user bandeau_personne_formulaire bandeau_personne_morale">
            <h1>Nouvelle fonction</h1>
        </div>
        <div class="formulaire personne_fonction">
            <div class="container_form">
                <div class="bloc_information_generale">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div class="input_container_single input_lg full_width">
                            <label for="physique">Personne Physique</label>
                            <app-entity-select
                                    id="physique"
                                    [control]="form.get('physique')"
                                    readOnly="true"
                            ></app-entity-select>
                        </div>
                        <div class="input_container_single input_lg full_width">
                            <label for="morale">Personne Morale</label>
                            <div [ngClass]="{'background_alert_required': !form.controls.morale.value}">
                                <app-entity-select
                                        id="morale"
                                        [fetcher]="pmFetcher"
                                        [control]="form.get('morale')"
                                ></app-entity-select>
                            </div>
                        </div>
                        <div class="input_container_single full_width" *ngIf="this.form.get('morale').value">

                            <label>Fonction</label>
                            <div [ngClass]="{'background_alert_required': !form.controls.fonction.value}"
                                 class="input_lg">
                                <app-fonction-personne-select2 [control]="form.get('fonction')"
                                                               [ppUuid]="this.form.get('physique').value.uuid"
                                                               [pmUuid]="this.form.get('morale').value.uuid"
                                >
                                </app-fonction-personne-select2>
                            </div>
                        </div>
                        <div class="input_container_single full_width" *ngIf="this.form.get('morale').value">
                            <div class="input_container_single full_width">
                                <label for="fonction_personnalisee">Fonction personnalisée</label>
                                <input type="text"
                                       id="fonction_personnalisee"
                                       formControlName="fonction_personnalisee"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form_bas">
            <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
            <button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmit()"><span>Enregistrer</span>
            </button>
        </div>
    </form>
</div>
