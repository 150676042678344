import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {AuthService} from '../_services/auth.service';
import {catchError, switchMap} from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<object>> {
        let uri;
        try {
            uri = new URL(req.url);
        } catch (error) {
            return next.handle(req);
        }

        if (![environment.apiHostname, environment.ssoHostname].includes(uri.hostname)) {
            return next.handle(req);
        }

        if (uri.pathname.match(/^\/(send-password|token(\/(authentication|refresh|invalidate))?)$/)) {
            return next.handle(req)
        }

        return this.auth.getToken().pipe(
            switchMap(token => {

                if (null === token) {
                    return of(null)
                }

                return next.handle(req.clone({setHeaders: { Authorization: `Bearer ${token}`}}));
            })
        )
    }
}
