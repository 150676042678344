import {IAdresse} from '../interfaces/adresse';

export class Adresse implements IAdresse {
    '@id'?: string;
    "@context": string;
    "@type": string;
    public uuid?: string;
    public type?: string;
    public statut?: string;
    public lignes: {
        ligne1: string;
        ligne2: string;
        ligne3: string;
    };
    public cp?: string;
    public cedexCode?: string;
    public cedexLibelle?: string;
    public ville?: string;
    public pays?: string;
    public liens?: any;
    public dateCreation?: Date;
    public dateModification?: Date;
    public userCreation?: any;
    public userModification?: any;
    public principal: boolean;
    public typeId: string;
    public statutId: string;

    constructor(data?: object) {
        if (data) {
            Object.assign(this, data);
        }
    }


}
