<div class="main">
    <div>
        <app-data-filter
                [dataFilter]="dataFilter"
                (changeFilter)="changeFilter()"
                [messageSwitch]="messageSwitch"
        >
            <div class="bloc1 flex gap-3">
                <button type="button" class="btn btn-ajouter btn-med"
                        [routerLink]="['creer']">
                    Ajouter une personne
                </button>
                <button type="button" class="btn btn-ajouter btn-med icon user_group"
                        [routerLink]="['/doublons', 'all']">
                    Doublons ({{totalDuplicated|number}})
                </button>
                <button type="button" class="btn btn-check btn-med"
                        [routerLink]="['gestion-des-consentements']">
                    Gestion des consentements
                </button>
            </div>
        </app-data-filter>
        <div class="alert_container">
            <div class="carte_alerte" *ngIf="this.dataFilter.active === false">
                <div class="icon i_alerte i_alerte_sm"></div>
                <span>Vous consultez actuellement les fiches inactives.
                <a class="view_fiche_active"
                   (click)="toggle()">Cliquez ici pour revenir à la liste des fiches actives</a>
            </span>
            </div>
        </div>
    </div>

    <div class="content_cartes cartes_personnes"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         [scrollWindow]="false"
         (scrolled)="onScroll()">
        <div *ngIf="!isLoading && personnes.length === 0" class="message_information">Aucun
            résultat trouvé
        </div>
        <ul class="cartes" *ngIf="modeAffichage === 'carte' && personneLiens">
            <li class="cartes_item" *ngFor="let personne of personnes; let i = index">
                <app-repertoire-carte [personne]="personne" [formatCarte]="true"></app-repertoire-carte>
            </li>
        </ul>
        <div class="inline_loader" [hidden]="!isLoading">
            <ngx-ui-loader [loaderId]="'list'"></ngx-ui-loader>
        </div>
        <div *ngIf="modeAffichage === 'liste'">
            <app-repertoire-liste [personnes]="personneLiens"></app-repertoire-liste>
        </div>
    </div>
    <notifier-container></notifier-container>
</div>
