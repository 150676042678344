import {Component, Input, OnInit} from '@angular/core';
import {CampagneTemplateInterface} from "../../../interfaces/campagneTemplate";
import {ModalService} from "../../_modal";
import {CampagneTemplateService} from "../../_services/campagne-template.service";
import {NotifierService} from "angular-notifier";
import {MessageGlobals} from "../../../const-global/messages";

@Component({
    selector: 'app-modal-buy-template',
    templateUrl: './modal-buy-template.component.html',
    styleUrls: ['./modal-buy-template.component.scss']
})
export class ModalBuyTemplateComponent implements OnInit {
    public modelApercu: CampagneTemplateInterface;

    @Input() public models: any;

    constructor(
        private modalService: ModalService,
        private campagneTemplateService: CampagneTemplateService,
        private notifier: NotifierService
    ) {
    }

    ngOnInit(): void {
        this.modalService.subjectDatas.subscribe((idTemplate) => {
            this.models.filter((model) => {
                if (model.id === idTemplate) {
                    this.modelApercu = model;
                }
            });
        });
    }

    closeApercuTemplate(id): void {
        this.modalService.close(id);
    }

    confirmationBuyTemplate(): void {
        this.modalService.close('modalBuyTemplate');

        this.campagneTemplateService.buyTemplate(this.modelApercu.uuid).subscribe(() => {
            this.notifier.notify('success', MessageGlobals.CONFIRMATION_ACHAT);

            this.campagneTemplateService.getAllTemplateCampagne().subscribe((data: any) => {
                this.campagneTemplateService.templateCampagneSubject.next(data['hydra:member']);
            });
        }, () => {
            this.notifier.notify('error', MessageGlobals.MESSAGE_SUPPORT);
        });
    }


}
