import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LeadService} from '../_services/lead.service';

@Injectable({ providedIn: 'root' })
export class OffresResolver implements Resolve<Observable<any>> {
    constructor(private service: LeadService) {}

    resolve = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> => {
        return this.service.detail(route.paramMap.get('id')).pipe(
            catchError((error) => {
                console.error(state + ' ' + error);
                return of({titre: 'N/A'});
            })
        );
    }
}
