<app-fil-ariane></app-fil-ariane>
<div class="facturation">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form">
        <div class="bandeau_tag icon mail">
            <h1>Consommation e-mails</h1>
        </div>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let conso of consommation | keyvalue"
                                 (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false"
                                 class="block_bandeau"
            >
                <mat-expansion-panel-header class="block_bandeau_header" *ngIf="conso.key !== 'price'">
                    <mat-panel-title class="title title_bandeau_facturation">
                        <div class="title title_bandeau_left">
                            <span>{{ moment(conso.key).lang("fr").format('MMMM YYYY') | titlecase }}</span>
                        </div>
                        <div class="title title_bandeau_right">
                            <span>{{ conso.value.sent }} envoyés</span>
                            <span>{{ conso.value.invoice }} facturés</span>
                            <span>{{ conso.value.price }} €</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div *ngFor="let facturationItem of conso.value.campaigns; let i = index"
                         class="block_info_facturation">
                        <div class="header_facturation" *ngIf="i === 0">
                            <div class="content_facturation_left">
                                <span class="title_facturation">{{ 'campagnes' | uppercase}}</span>
                            </div>
                            <div class="content_facturation_right">
                                <span class="date_facturation">{{ 'date d\'envoi' | uppercase}}</span>
                                <span class="count_facturation">{{ 'nb d\'email' | uppercase}}</span>
                            </div>
                        </div>
                        <div class="content_facturation">
                            <div class="content_facturation_left">
                                <div class="icon mail"></div>
                                <div class="">{{ facturationItem.libelle }}</div>
                            </div>
                            <div class="content_facturation_right">
                                <div>{{ moment(facturationItem.date.date).format('DD/MM/YYYY') }}</div>
                                <div class="flex">
                                    <span class="value_colored_azko mr-1">{{ facturationItem.sent }}</span>
                                    <div>envoyés</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>
