import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {TokenStorageService} from './token-storage.service';
import {UIGlobals} from '../../const-global/globals';
import {Title} from '@angular/platform-browser';
import {PersonneService} from './personne.service';
import {UserService} from './user.service';
import {catchError, finalize, map, shareReplay, switchMap, tap} from "rxjs/operators";
import tokenDecoder from "jwt-decode";
import {NotifierService} from "angular-notifier";

const AUTH_API = environment.authURL;

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    ),
    withCredentials: false
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    #refreshing: Observable<null|string>;
    tokenSubject?: Observable<string>

    constructor(
        private router: Router,
        private http: HttpClient,
        private tokenStorageService: TokenStorageService,
        private personneService: PersonneService,
        private userService: UserService,
        private titleService: Title,
        private notifier: NotifierService,
    ) {
    }

    login(login: string, password: string): Observable<any> {
        this.personneService.refreshOnConnect();
        this.userService.refreshOnConnect();
        window.localStorage.clear();
        return this.http.post(AUTH_API + 'token/authentication', {
            login,
            password
        }, httpOptions).pipe(tap(data => {
            this.saveToken(data)
        }));
    }

    logout(redirect?: string): void {
        this.http.post<any>(AUTH_API + 'token/invalidate', {
            refresh_token: this.tokenStorageService.getRefreshToken()
        }, httpOptions)
            .pipe(
                finalize(() => {
                    this.tokenSubject = null;
                    this.#refreshing = null;
                    this.titleService.setTitle(UIGlobals.TITLE_CRM);
                    let options = redirect ? { queryParams: {redirect}} : {}
                    this.router.navigate(['/login'], options);
                    this.tokenStorageService.signOut();
                })
            )
            .subscribe();
    }

    private saveToken(data) {
        this.tokenStorageService.saveToken(data.token);
        this.tokenStorageService.saveRefreshToken(data.refresh_token);
        this.tokenStorageService.saveUser(tokenDecoder(data.token));
    }

    private refreshToken() {
        return this.http.post(AUTH_API + 'token/refresh', {
            refresh_token: this.tokenStorageService.getRefreshToken()
        }, httpOptions).pipe(
            tap(data => {
                this.saveToken(data)
            }),
            shareReplay()
        )
    }

    getToken(): Observable<null|string>
    {
        if (!this.tokenSubject) {
            if (!this.tokenStorageService.getToken()) {
                return of(null)
            }

            this.tokenSubject = of(this.tokenStorageService.getToken());
        }

        const decodedToken = this.tokenStorageService.getUser();

        if (decodedToken.refreshAt && decodedToken.refreshAt > (new Date).getTime()) {
            return this.tokenSubject;
        }

        this.tokenSubject = null;

        if (this.#refreshing) {
            return this.#refreshing
        }

        return this.#refreshing = this.refreshToken().pipe(
            map( () => {
                return this.tokenStorageService.getToken()
            }),
            catchError(err => {
                if (this.#refreshing) {
                    this.notifier.notify('error', 'Vous avez été déconnecté.');
                    this.logout(window.location.href.slice(window.location.origin.length));
                }

                return of(null)
            }),
            finalize(() => {
                this.#refreshing = null
            })
        )
    }

    isAuthenticated(): Observable<boolean> {

        return this.getToken().pipe(
            switchMap( (isAuth: string|null) => {
                return of(null !== isAuth);
            })
        );
    }

    sendMotDePasse(login: string): Observable<any> {
        return this.http.post(`${AUTH_API}send-password`, {
            login
        }, httpOptions);
    }
}
