import {Component, Input, OnInit} from '@angular/core';
import {EvenementService} from '../../../_services/evenement.service';
import {Personne} from '../../../../models/personne';
import {NotifierService} from 'angular-notifier';
import {RappelGlobals} from '../../../../const-global/globals';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonneService} from '../../../_services/personne.service';
import {ExceptionService} from '../../../_services/exception.service';

@Component({
    selector: 'app-event-rappel',
    templateUrl: './event-rappel.component.html',
    styleUrls: ['./event-rappel.component.scss']
})
export class EventRappelComponent {
    public readonly RAPPEL_GLOBAL = RappelGlobals;

    @Input() tabName: string;
    public plUuid = this.route.snapshot.params.id;

    constructor(
        private evenementService: EvenementService,
        private notifier: NotifierService,
        private route: ActivatedRoute,
        public router: Router,
    ) {
    }


    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }
}
