import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractClient} from './abstract-client';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {IDevisSetting} from '../../interfaces/i-devis-setting';


const API_URL = environment.ssoRootURL;


@Injectable({
    providedIn: 'root'
})
export class DevisSettingService extends AbstractClient<IDevisSetting, { }> {
    constructor(http: HttpClient) {
        super(http, API_URL + 'devis_settings');
    }

    upload(uuid: string, formData): Observable<any> {
        return this.http.put(`${API_URL}devis_settings/${uuid}`, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }
}
