<ng-select
        bindLabel="libelle"
        [loading]="loading"
        [items]="items"
        [formControl]="control"
        [virtualScroll]="true"
        [typeahead]="input$"
        (scroll)="scroll($event)"
        (open)="onOpen()"
>
</ng-select>
