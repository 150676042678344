import {
	Component,
	ViewEncapsulation,
	ElementRef,
	Input,
	OnInit,
	OnDestroy,
	Output,
	EventEmitter,
} from '@angular/core';
import {ModalService} from './modal.service';

@Component({
	selector: 'jw-modal',
	templateUrl: 'modal.component.html',
	styleUrls: ['modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {

	@Input() id: string;
	@Input() display: boolean = false;
	@Output() displayChange = new EventEmitter<boolean>();

	constructor(private modalService: ModalService, private ref: ElementRef) {
	}

	ngOnInit(): void {
		this.modalService.add(this);
		document.body.appendChild(this.ref.nativeElement)
	}

	// remove self from modal service when component is destroyed
	ngOnDestroy(): void {
		this.modalService.remove(this.id);
		this.ref.nativeElement.remove()
	}

    // open modal
    open(): void {
		if (!this.display) {
			this.display = true;
			this.displayChange.emit(true);
		}
    }

    // close modal
	close(): void {
		if (this.display) {
			this.displayChange.emit(false);
			this.display = false;
		}
  }
}
