<div class="carte carte_segment" *ngIf="segment">
    <div class="action_carte_segment">
        <div class="btn_border_white">
            <button type="button" class="btn btn-picto-med btn-actualiser" [ngClass]="{'updating': updateInProgress}" (click)="calculate(segment.uuid)"></button>
        </div>
        <div class="btn_border_white">
            <button type="button" class="btn btn-picto-med btn-exporter" (click)="export(segment.uuid)"></button>
        </div>
    </div>
    <div class="carte_content" (click)="openFiche(segment.uuid)">
        <div class="carte_titre bandeau_segment icon search">
            <h2>{{ segment.titre }}</h2>
        </div>
        <div class="carte_texte">
            <span>Dernière exécution : {{segment.derniereDateExecution | date : fullDate}}</span>
            <div class="container_contact_find">
                <fa-icon
                    [icon]="faArrowRight"
                    [classes]="['menu_' + faArrowRight.iconName]"
                    size="lg"
                ></fa-icon>
                <span>{{segment.nbContactsPublics}} + {{segment.nbContactsPrives}} contacts trouvés</span>
            </div>
        </div>
        <app-avatar [personne]="personne" [small]="true"></app-avatar>
    </div>
</div>
