<mat-tree [dataSource]="userDataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button type="button" mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="checklistSelection.isSelected(node)"
                      (change)="leafItemSelectionToggle(node)" disabled="{{ disabledFeature }}">{{node.libelle}}</mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding disabled
                   matTooltip="Bientôt disponible !"
                   matTooltipClass="tooltip-soon"
    >
        <button type="button" mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.libelle">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)"
                      [indeterminate]="descendantsPartiallySelected(node)"
                      (change)="itemSelectionToggle(node)" disabled="{{ disabledFeature }}">{{node.libelle}}</mat-checkbox>
    </mat-tree-node>
</mat-tree>
