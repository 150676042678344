import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter} from 'rxjs/operators';

export interface BreadcrumbItem {
    label: string;
    url: string;
    noRouting: boolean;
}

@Component({
    selector: 'app-fil-ariane',
    templateUrl: './fil-ariane.component.html',
    styleUrls: ['./fil-ariane.component.scss']
})
export class FilArianeComponent implements OnInit {
    public breadcrumbs: BreadcrumbItem[];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter((event: any) => event instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        });
    }

    buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
        let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.ariane : '';
        let path = route.routeConfig && route.routeConfig.data && route.routeConfig.path ? route.routeConfig.path : '';
        // If the route is dynamic route such as ':id', remove it
        const lastRoutePart = path.split('/').pop();
        const isDynamicRoute = lastRoutePart.startsWith(':');
        if (isDynamicRoute && !!route.snapshot) {
            let data = '';
            if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.ariane) {
                if (route.routeConfig.data.ariane[0] === '@') {
                    route.routeConfig.data.ariane.split('.').forEach((level: string, index: number) => {
                        if (index === 0) {
                            data = route.snapshot.data[level.substr(1)];
                        } else {
                            data = !!data ? (data as any)[level] : null;
                        }
                    });
                } else {
                    data = route.routeConfig.data.ariane;
                }
            }

            const paramName = lastRoutePart.split(':')[1];

            path = path.replace(lastRoutePart, route.snapshot.params[paramName]);

            label = (data !== '') ? data : route.snapshot.params[paramName];
        }

        const nextUrl = path ? `${url}/${path}` : url;
        const noRouting = (route.routeConfig && route.routeConfig.data?.noRouting) ? route.routeConfig.data?.noRouting : false;
        const breadcrumb: BreadcrumbItem = {
            label,
            url: nextUrl,
            noRouting: noRouting
        };

        // Only adding route with non-empty label
        const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }
}
