import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MessageGlobals} from '../../../const-global/messages';
import {Subscription} from 'rxjs';
import {DataFilter} from '../../../models/datafilter';

@Component({
    selector: 'app-active-switcher',
    templateUrl: './active-switcher.component.html',
    styleUrls: ['./active-switcher.component.scss']
})
export class ActiveSwitcherComponent {

    @Input() messageSwitch: { actif: string, inactif: string};
    @Input() dataFilter: DataFilter;
    @Output() switchActif = new EventEmitter<any>();

    toggle(): void {
        this.dataFilter.active = !this.dataFilter.active;
        this.dataFilter.page = 1;
        this.switchActif.emit();
    }
}
