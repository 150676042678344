<div class="checkbox_fiche_disabled">
    <input type="checkbox"
           class="switch"
           id="active"
           [checked]="this.dataFilter.active"
           (change)="toggle()"
    >
    <label for="active"
           class="switch icon active"
           matTooltip="{{messageSwitch.actif}}"
           matTooltipClass="tooltip-default"
           aria-label="tooltip"
           *ngIf="this.dataFilter.active === true">
    </label>
    <label for="active"
           class="switch icon inactive"
           matTooltip="{{messageSwitch.inactif}}"
           matTooltipClass="tooltip-default"
           aria-label="tooltip"
           *ngIf="this.dataFilter.active === false">
    </label>
</div>
