import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment as ENV, environment} from '../../environments/environment';
import {SortDirection} from '@angular/material/sort';
import {CampaignContacts, ICampagne} from '../../interfaces/campagne';
import {Campagne} from '../../models/campagne';
import {ImageListItem} from '../campagne/img-gallery/img-gallery.component';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AbstractClient} from './abstract-client';

export type Filter = {
    active?: boolean;
    page?: number;
    itemsPerPage?: number
    partialPagination?: boolean
    'groups[]'?: string[];
    'groups'?: string;
};

const API_URL = environment.apiURL;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    ),
    params: {}
};

@Injectable({
    providedIn: 'root'
})
export class CampagneService extends AbstractClient<any, Filter> implements OnDestroy {
    public campaignUpdatedSubject = new Subject<any>();
    public campaignsSubject = new BehaviorSubject<Campagne[]>([]);
    public campaignActiveSubject = new BehaviorSubject<boolean>(true);
    public countSegmentEmails: BehaviorSubject<string> = new BehaviorSubject<string>('0');
    public imgGallery: BehaviorSubject<ImageListItem[]> = new BehaviorSubject<any>([]);
    public imgGalleryLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private campaignsSubscription: Subscription;
    private _http: HttpClient;

    public campaignDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        http: HttpClient,
        private ngxService: NgxUiLoaderService
    ) {
        super(http, ENV.apiURL + 'campagnes');
        this._http = http;
    }

    /**
     * Création d'une campagne (CRM)
     */
    public createCampaign(params: Campagne): Observable<any> {
        return this._http.post(`${API_URL}campagne`, params, httpOptions);
    }

    /**
     * Mises à jour d'une campagne
     */
    public updateCampaign(params: ICampagne): Observable<any> {
        return this._http.put(`${API_URL}campagne/${params.uuid}`, params, httpOptions);
    }

    /**
     * Récupération de toutes les campagnes
     */
    public getAllCampaigns(active = true, limit = null, order = null, refresh = false): Observable<any> {
        if (this.campaignsSubject.getValue().length === 0 || refresh === true) {
            this.refreshCampaigns(active, limit, order);
        }
        return this.campaignsSubject.asObservable();
    }

    public refreshCampaigns(active: boolean, limit: any, order: any) {
        this.ngxService.start();
        let httpParams = new HttpParams();
        httpParams = httpParams.set('active', active.toString());
        if (limit) {
            httpParams = httpParams.set('limit', limit);
        }
        if (order) {
            httpParams = httpParams.set('order', order);
        }
        httpOptions.params = httpParams;
        this._http.get(API_URL + 'campagne/all', httpOptions).subscribe(
            (campaigns: Campagne[]) => this.campaignsSubject.next(campaigns),
            (err) => {
                this.ngxService.stop();
                this.campaignsSubject.error(err)
            },
            () => {
                this.ngxService.stop();
            }
        );
    }

    /**
     * Récupération de la fiche campagne
     */
    public getFicheCampaign(uuid): Observable<any> {
        return this._http.get(`${API_URL}campagne/${uuid}`, httpOptions);
    }

    public refreshCampaignData(uuid): Observable<any> {
        this._http.get(`${API_URL}campagne/${uuid}`, httpOptions).subscribe(v => {
            this.campaignDataSubject.next(v);
        });
        return this.campaignDataSubject.asObservable();
    }

    /**
     * Duplique une campagne
     */
    public duplicate(uuid: string): Observable<any> {
        return this._http.get(`${API_URL}campagne/${uuid}/duplicate`, httpOptions);
    }

    /**
     * Récupère la consommation d'emails d'une organisation
     */
    public getConsommationEmails(type: string): Observable<any> {
        return this._http.get(`${API_URL}facturation/organisation/type/${type}`, httpOptions);
    }

    /**
     * Récupère les contacts liés à une campagne
     */
    public getContactsFromCampaign(uuid: string, sort: string, order: SortDirection, page: number, perPage: number,
                                   search: string)
        : Observable<CampaignContacts> {
        let options = `?sort=${sort}&order=${order}&page=${page}&perPage=${perPage}`;
        if (search) {
            options = options + `&search=${search}`;
        }
        return this._http.get<CampaignContacts>(
            `${API_URL}campagne/${uuid}/contacts` + options, httpOptions);
    }

    /**
     * Comptes les contacts pour chaques segments sélectionnés
     */
    public getContactsCountFromSegments(uuid: string, uuids: any[]): void {
        this._http.get(`${API_URL}campagne/${uuid}/contacts/count?uuids=${uuids.join(',')}`).subscribe(
            (res: any) => {
                this.countSegmentEmails.next(res);
            });
    }


    /**
     * Commence l'envoi de la campagne lors du clic
     */
    public sendNow(uuid: string): Observable<any> {
        return this._http.put<any>(
            `${API_URL}campagne/${uuid}/send-now`
            , httpOptions);
    }

    /**
     * stop l'envoi d'une campagne
     */
    public stop(uuid: string): Observable<any> {
        return this._http.put<any>(
            `${API_URL}campagne/${uuid}/stop`
            , httpOptions);
    }

    /**
     *
     * @param plUuid
     */
    public askConsent(plUuid) {
        return this._http.post(`${API_URL}consent/${plUuid}`, httpOptions)
    }

    /**
     * upload img to S3
     * @param orgUuid
     * @param file
     * @param done
     */
    public uploadImage(orgUuid, file, done) {
        const formData: FormData = new FormData();
        for (let i = 0; i < file.attachments.length; i++) {
            formData.append('organisation', orgUuid);
            formData.append('visibility', 'public');
            formData.append('file_' + i, file.attachments[i], file.attachments[i].name);
        }
        this._http.post<any>(`${environment.apiURL}organization/storage/campaign`, formData, {
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            (response: HttpEvent<any>) => {
                if (response.type === HttpEventType.Response) {
                    response.body.path.forEach((path: string) => {
                        done({progress: 100, url: `${path}`})
                    })
                }
            },
            error => {
                console.error(error);
            },
            () => {
                this.getGallery(orgUuid);
            });
    }

    /**
     * get img list from S3
     * @param orgUuid
     */
    public getGallery(orgUuid) {
        this.imgGalleryLoading.next(true);
        this._http.get<ImageListItem[]>(`${environment.apiURL}organization/storage/campaign`, httpOptions)
            .subscribe((res: any) => {
                    this.imgGallery.next(res);
                },
                () => {
                    this.imgGalleryLoading.next(false);
                },
                () => {
                    this.imgGalleryLoading.next(false);
                });
    }

    /**
     * Récupère un template par son uuid
     */
    public getTemplateCampagneByUuuid(uuid): Observable<any> {
        return this._http.get(`${API_URL}campagne_templates/${uuid}`, httpOptions);
    }

    /**
     *
     * @param campaignUuid
     * @param formData
     */
    sendTest(campaignUuid: string, formData: any) {
        return this._http.post(`${API_URL}campagne/${campaignUuid}/send_test`, formData, httpOptions);
    }

    ngOnDestroy(): void {
        this.campaignsSubscription.unsubscribe();
    }
}
