<jw-modal id="modalBuyTemplate">
    <div class="bloc_title_modal">Acheter le {{ modelApercu?.title }}</div>
    <div class="bloc_body_modal">
        Vous êtes sur le point d'acheter le {{ modelApercu?.title }} dont le montant (49€ HT) sera prélevé sur votre
        prochaine facture. <br><br>
        Ce modèle sera immédiatement disponible dans votre catalogue. <br><br>
        <div class="text-sm text-regular text-italic">En procédant à cet achat, vous reconnaissez avoir renoncé à
            l’exercice de votre droit de rétractation en application des dispositions de l’article L. 221-28 du Code de
            la consommation.
        </div>
    </div>
    <div class="edit_data">
    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeApercuTemplate('modalBuyTemplate')">
                <span>Annuler</span>
            </button>
            <button type="button" class="btn btn-med campagne icon basket" (click)="confirmationBuyTemplate()">
                <span>Acheter</span>
            </button>
        </div>
    </div>
</jw-modal>
