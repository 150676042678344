<app-modal-confirm-probabilite
    #confirmModal
    (confirmEvent)="onConfirmModal($event)"
    (cancelEvent)="onCancelModal($event)"
    [probabilite]="newProbabilite"
></app-modal-confirm-probabilite>
<app-data-filter
        [dataFilter]="dataFilter"
        (changeFilter)="changeFilter()"
        [messageSwitch]="messageSwitch"
>
    <div class="bloc1">
        <button type="button" action class="btn btn-ajouter btn-med offre" [routerLink]="['/offre/ajouter']">
            Ajouter une offre
        </button>
    </div>

</app-data-filter>
<div class="alert_container">
    <div class="carte_alerte" *ngIf="!this.dataFilter?.active">
        <div class="icon i_alerte i_alerte_sm"></div>
        <span>
            Vous consultez actuellement les offres inactives.
            <a class="view_active" (click)="toggle()">Cliquez ici pour revenir à la liste des offres actives</a>
        </span>
    </div>
</div>



<ngx-ui-loader></ngx-ui-loader>
<div>
    <div class="offre_kanban_grid" cdkScrollable>
        <div class="kanban_col">
            <div class="kanban_col_header light-purple">
                <div class="kanban_col_title icon message">20% - En préparation</div>
                <div class="kanban_col_title_count">{{totalDraft}}/{{draftTotal}}</div>
            </div>
            <div class="kanban_col_stats">
                <span class="stats_count"><ng-container [ngTemplateOutlet]="currency"
                                                        [ngTemplateOutletContext]="{value: counters.draft / 100}"></ng-container></span>
            </div>
            <div class="kanban_col_body"
                 cdkDropList
                 cdkDropListAutoScrollStep="5"
                 id="column_draft"
                 [cdkDropListData]="draft"
                 [cdkDropListConnectedTo]="['column_sent','column_negociation', 'column_promise','column_signed','column_canceled']"
                 (cdkDropListDropped)="drop($event)"
            >
                <div class="column_drop">
                    <div class="kanban_col_content" *ngFor="let item of this.draft"
                         cdkDrag id="{{item.uuid}}">
                        <app-card-kanban [offer]="item"></app-card-kanban>
                    </div>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header light-purple-2">
                <div class="kanban_col_title icon envoi_en_cours">40% - Offre envoyée</div>
                <div class="kanban_col_title_count">{{ totalSent }}/{{ sentTotal }}</div>
            </div>
            <div class="kanban_col_stats">
                <span class="stats_count"><ng-container [ngTemplateOutlet]="currency"
                                                        [ngTemplateOutletContext]="{value: counters.sent / 100}"></ng-container></span>
            </div>
            <div class="kanban_col_body"
                 cdkDropList
                 cdkDropListAutoScrollStep="5"
                 id="column_sent"
                 [cdkDropListData]="sent"
                 [cdkDropListConnectedTo]="['column_draft','column_negociation', 'column_promise','column_signed','column_canceled']"
                 (cdkDropListDropped)="drop($event)"
            >
                <div class="column_drop">
                    <div class="kanban_col_content" *ngFor="let item of this.sent"
                         cdkDragBoundary=".offre_kanban_grid"
                         cdkDrag id="{{item.uuid}}">
                        <app-card-kanban [offer]="item"></app-card-kanban>
                    </div>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header pastel-purple">
                <div class="kanban_col_title icon exchange">60% - Négociation</div>
                <div class="kanban_col_title_count">{{ totalNegociation }}/{{ negociationTotal }}</div>
            </div>
            <div class="kanban_col_stats">
                <span class="stats_count"><ng-container [ngTemplateOutlet]="currency"
                                                        [ngTemplateOutletContext]="{value: counters.negociation / 100}"></ng-container></span>
            </div>
            <div class="kanban_col_body"
                 cdkDropList
                 cdkDropListAutoScrollStep="5"
                 id="column_negociation"
                 [cdkDropListData]="negociation"
                 [cdkDropListConnectedTo]="['column_draft','column_sent', 'column_promise','column_signed','column_canceled']"
                 (cdkDropListDropped)="drop($event)"
            >
                <div class="column_drop">
                    <div class="kanban_col_content" *ngFor="let item of this.negociation" cdkDrag id="{{item.uuid}}">
                        <app-card-kanban [offer]="item"></app-card-kanban>
                    </div>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header pastel-purple-2">
                <div class="kanban_col_title icon thumbs-up white">80% - Promesse</div>
                <div class="kanban_col_title_count white">{{ totalPromise }}/{{ promiseTotal }}</div>
            </div>
            <div class="kanban_col_stats">
                <span class="stats_count">
                    <ng-container [ngTemplateOutlet]="currency"
                                  [ngTemplateOutletContext]="{value: counters.promise / 100}"></ng-container>
                </span>
            </div>
            <div class="kanban_col_body"
                 cdkDropList
                 cdkDropListAutoScrollStep="5"
                 id="column_promise"
                 [cdkDropListData]="promise"
                 [cdkDropListConnectedTo]="['column_draft','column_sent', 'column_negociation','column_signed','column_canceled']"
                 (cdkDropListDropped)="drop($event)"
            >
                <div class="column_drop">
                    <div class="kanban_col_content" *ngFor="let item of this.promise" cdkDrag id="{{item.uuid}}">
                        <app-card-kanban [offer]="item"></app-card-kanban>
                    </div>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header">
                <div class="kanban_col_title icon handshake white">100% - Signé</div>
                <div class="kanban_col_title_count white">{{ totalSigned }}/{{ signedTotal }}</div>
            </div>
            <div class="kanban_col_stats">
                <span class="stats_count">
                    <ng-container [ngTemplateOutlet]="currency"
                                  [ngTemplateOutletContext]="{value: this.counters.signed / 100}"></ng-container>
                </span>
            </div>
            <div class="kanban_col_body"
                 cdkDropList
                 cdkDropListAutoScrollStep="5"
                 id="column_signed"
                 [cdkDropListData]="signed"
                 [cdkDropListConnectedTo]="['column_draft','column_sent', 'column_negociation','column_promise','column_canceled']"
                 (cdkDropListDropped)="drop($event)"
            >
                <div class="column_drop">
                    <div class="kanban_col_content" *ngFor="let item of this.signed" cdkDrag [cdkDragDisabled]="true"
                         id="{{item.uuid}}">
                        <app-card-kanban [offer]="item"></app-card-kanban>
                    </div>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header black">
                <div class="kanban_col_title icon thumbs-down white">-100% - Perdu</div>
                <div class="kanban_col_title_count white">{{ totalCanceled }}/{{ canceledTotal }}</div>
            </div>
            <div class="kanban_col_stats">
                <span class="stats_count">
                    <ng-container [ngTemplateOutlet]="currency"
                                  [ngTemplateOutletContext]="{value: this.counters.canceled / 100}"></ng-container>
                </span>
            </div>
            <div class="kanban_col_body opacity05"
                 cdkDropList
                 cdkDropListAutoScrollStep="5"
                 id="column_canceled"
                 [cdkDropListData]="canceled"
                 [cdkDropListConnectedTo]="['column_draft','column_sent', 'column_negociation','column_promise','column_signed']"
                 (cdkDropListDropped)="drop($event)"
            >
                <div class="column_drop">
                    <div class="kanban_col_content" *ngFor="let item of this.canceled" cdkDrag [cdkDragDisabled]="true"
                         id="{{item.uuid}}"
                    >
                        <app-card-kanban [offer]="item"></app-card-kanban>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ng-template #currency let-value="value">
    {{ value| currency:'EUR':'symbol':'1.2-2':'fr' }}
</ng-template>
