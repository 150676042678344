import {IFilterItem} from '../interfaces/filteritem';

export class FilterItem implements IFilterItem {
    id?: string;
    name?: string;
    attributeName?: string;
    value?: any;
    hidden?: boolean;
    inputType?: string;

    constructor(data?: object) {
        Object.assign(this, data);
    }

    getCount(): number {
        return this.value?.length || 0;
    }

    removeItem(item: any): void {
        this.value = this.value.filter(i => i.uuid !== item.uuid);
    }
}
