<ng-container *ngIf="!router.url.endsWith('/modifier')">
    <div *ngIf="personne">
        <app-onglet [data]="personne.onglet"
                    [active]="personne.uuid"
                    *ngIf="personne.type !== PERSON_GLOBALS.PERSON_LIEN_MORALE">
        </app-onglet>
    </div>
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <div class="fiche {{personne.type}}" *ngIf="personne">
        <div class="fiche_gauche">
            <div class="fiche_bloc1 {{personne.type}}" [ngClass]="{incomplete: personne.optimized === false}">
                <div *ngIf="personne.type !== PERSON_GLOBALS.PERSON_LIEN_MORALE"
                     class="carte_titre bandeau_personne bandeau_personne_physique icon user">
                    <h1>{{ personne.personnePhysiqueLibelle }}</h1>
                </div>
                <div *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE"
                     class="carte_titre bandeau_personne bandeau_personne_morale icon building">
                    <h1>{{ personne.personneMoraleLibelle }}</h1>
                </div>
                <div *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN"
                     class="icon carte_sous_titre {{personne.type}}">
                    <div class="info_personne">
                        <h3>{{ personne.fonctionLibelle }}</h3>
                        <h2 (click)="toPM(personne.personneMorale?.lien?.uuid);">{{ personne.personneMoraleLibelle }}</h2>
                    </div>
                </div>
                <div class="carte_information">
                    <app-avatar [personne]="personne" [modeFiche]="true"></app-avatar>
                    <div class="information carte_texte">
                        <app-fonction-sticker [statut]="personne.statut"></app-fonction-sticker>

                        <div class="icon marker action_fiche">
                            <h3 *ngIf="hasContactInfo(personne.cp || personne?.personneMorale?.lien?.adressePrincipal?.adresse?.cp); else adresse">
                                {{personne.cp || personne?.personneMorale?.lien?.adressePrincipal?.adresse?.cp}} {{personne.ville || personne?.personneMorale?.lien?.adressePrincipal?.adresse?.ville | uppercase}}
                            </h3>
                        </div>
                        <ng-template #adresse>
                            <p class="libelle_sans_data">Non-renseignée</p>
                        </ng-template>

                        <div *ngIf="hasContactInfo(personne.mail || personne?.personneMorale?.lien?.mailPrincipal?.mail?.valeur); else mail"
                             class="icon mail action_fiche">
                            <a href="mailto:{{personne.mail || personne?.personneMorale?.lien?.mailPrincipal?.mail?.valeur}}"
                               *ngIf="personne.mail || personne?.personneMorale?.lien?.mailPrincipal?.mail?.valeur">
                                {{personne.mail || personne?.personneMorale?.lien?.mailPrincipal?.mail?.valeur}}
                            </a>
                        </div>
                        <ng-template #mail>
                            <div class="icon mail action_fiche">
                                <a class="libelle_sans_data">Non-renseignée</a>
                            </div>
                        </ng-template>

                        <div *ngIf="hasContactInfo(personne.telephone || personne?.personneMorale?.lien?.telephonePrincipal?.telephone?.text); else telephone"
                             class="icon phone action_fiche">
                            <a href="tel:{{personne.telephone || personne?.personneMorale?.lien?.telephonePrincipal?.telephone?.text}}" *ngIf="personne.telephone || personne?.personneMorale?.lien?.telephonePrincipal?.telephone?.text">
                                {{personne.indicatifTelephone}}{{personne.telephone || personne?.personneMorale?.lien?.telephonePrincipal?.telephone?.text}}
                            </a>
                        </div>
                        <ng-template #telephone>
                            <div class="icon phone action_fiche">
                                <a class="libelle_sans_data">Non-renseignée</a>
                            </div>
                        </ng-template>

                        <div class="chiffres_personne">
                                <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE && personne.capital; else capital">Cap. {{personne.capital}}
                                    €</span>
                            <ng-template #capital>
                                <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE"
                                      class="libelle_sans_data">Capital : -
                                </span>
                            </ng-template>

                            <span *ngIf="personne.effectif; else effectif">{{personne.effectifLibelle}}</span>
                            <ng-template #effectif>
                                <span *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE"
                                      class="libelle_sans_data">Effectif : -
                                </span>
                            </ng-template>

                            <span *ngIf="personne.civilite && personne.civilite !== 'Non-renseigné'; else civilite">Civilité :  {{personne.civilite}}
                                .</span>
                            <ng-template #civilite>
                                <span *ngIf="personne.type !== PERSON_GLOBALS.PERSON_LIEN_MORALE"
                                      class="libelle_sans_data">Civilité : -
                                </span>
                            </ng-template>

                            {{personne.titre}}
                            <span *ngIf="personne.personnePhysique.titre; else titre">Titre : {{personne.personnePhysique.titre.libelle}}</span>
                            <ng-template #titre>
                                <span *ngIf="personne.type !== PERSON_GLOBALS.PERSON_LIEN_MORALE"
                                      class="libelle_sans_data">Titre : -
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <button type="button"
                            *ngIf="personne?.userUuid"
                            class="btn_border_white btn btn-picto-lg picto-user"
                            [routerLink]="['/', 'utilisateurs', personne.userUuid]"
                            title="Voir le profil utilisateur"
                    >
                    </button>
                    <button type="button"
                            class="btn_border_white btn btn-picto-lg picto-edition"
                            (click)="toModif(personne.uuid)"
                            title="Éditer la personne"
                    >
                    </button>
                </div>
                <div class="info_fiche">
                    <ng-container>
                        <app-fiche-completion-notice [personne]="personne" [typeAlerts]="['champs_manquants', 'info_doublon']"></app-fiche-completion-notice>
                    </ng-container>
                </div>
            </div>
            <div class="fiche_bloc2">
                <div class="content_select2 input_lg bloc_tag" *ngIf="personne">
                    <ng-container [ngTemplateOutlet]="tags"
                                  [ngTemplateOutletContext]="{
                                    disabled: (personne.type === PERSON_GLOBALS.PERSON_LIEN)
                                  }"
                    ></ng-container>
                </div>
                <ng-container *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN">
                    <app-memos [personne]="personne" [disabled]="true" [persist]="true" [memoFiche]="true"></app-memos>
                </ng-container>
                <form name="form"
                      novalidate
                      autocomplete="off">
                    <ng-container *ngIf="personne.type !== PERSON_GLOBALS.PERSON_LIEN">
                        <app-creer-memo [personne]="personne" [persist]="true"></app-creer-memo>
                        <app-memos [personne]="personne" [disabled]="disabled" [persist]="true"
                                   [memoFiche]="true"></app-memos>
                    </ng-container>
                </form>
                <div class="qualite_relation">
                    <h3>Qualité relation</h3>
                    <app-note
                            [ngModel]="personne.qualite"
                            disabled
                    ></app-note>
                </div>
            </div>
            <div class="fiche_bloc3">
                <div class="status_personne">
                    <div class="bloc_actif">
                        <i class="icon" [ngClass]="{
                            'actif': personne?.actif,
                            'inactif': !personne?.actif
                        }"></i>
                        <span *ngIf="personne?.actif">Actif</span>
                        <span class="inactif" *ngIf="!personne?.actif">Inactif</span>
                    </div>
                </div>
                <div class="date_creation_fiche">
                    <p *ngIf="personne.creationDate">
                        <span>Créé le {{personne.creationDate | date: fullDate}}</span>
                        <span *ngIf="personne.creationNom">
                           par {{personne.creationPrenom}} {{personne.creationNom}}
                        </span>
                    </p>
                    <p *ngIf="personne.modificationDate">
                        <span>Modifié le {{personne.modificationDate | date: fullDate}}</span>
                        <span *ngIf="personne.modificationNom">
                            par {{personne.modificationPrenom}} {{personne.modificationNom}}
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="fiche_droite">
            <div class="onglet evenement">
                <div class="onglet_fiche icon calendrier"
                     [ngClass]="{'onglet_evenement onglet_bg_white' : ongletName === 'event'}"
                     (click)="openOnglet('event')">Evènements ({{nbEventATraiter}}/{{nbEvent}})
                </div>
                <div class="onglet_fiche icon info {{personne.type}}"
                     [ngClass]="{
                                 'onglet_bg_white': ongletName === 'info',
                                 'onglet_personne_physique': ongletName === 'info' && (personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE || personne.type === PERSON_GLOBALS.PERSON_LIEN),
                                 'onglet_personne_morale': ongletName === 'info' && personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE
                                 }"
                     (click)="openOnglet('info')">Informations détaillées
                </div>
                <div class="onglet_fiche icon sitemap"
                     [ngClass]="{'onglet_personne_lien onglet_bg_white' : ongletName === 'personne-linked'}"
                     *ngIf="personne.type !== 'physique'"
                     (click)="openOnglet('personne-linked')">Personnes liées ({{ totalPersonne }})
                </div>
            </div>
            <div class="bloc_onglet_fiche bloc_event" *ngIf="ongletName === 'event'">
                <div class="recherche-events">
                    <div>
                        <input type="search"
                               class="input-selection"
                               placeholder="Recherche"
                               size="30"
                               (input)="onSearchInput($event)"
                        />
                    </div>
                    <div class="" [ngClass]="toggleFilter ? 'fadein_filter' : 'fadeout_filter'">
                        <app-types-event-select2 (typeEvent)="changeTypeEvent($event)"></app-types-event-select2>
                    </div>
                    <div class="" [ngClass]="toggleFilter ? 'fadein_filter' : 'fadeout_filter'">
                        <app-statuts-event-select2
                                (statutEvent)="changeStatutEvent($event)">
                        </app-statuts-event-select2>
                    </div>
                    <div class="" [ngClass]="toggleFilter ? 'fadein_filter' : 'fadeout_filter'">
                        <input placeholder="Echéance après le"
                               class="input-selection"
                               type="text"
                               lang="fr-FR"
                               onfocusin="(this.type='date')"
                               (change)="changeDateDebutEvent($event)"
                        >
                    </div>
                    <div class="" [ngClass]="toggleFilter ? 'fadein_filter' : 'fadeout_filter'">
                        <input placeholder="Echéance avant le"
                               class="input-selection"
                               type="text"
                               onfocusin="(this.type='date')"
                               (change)="changeDateFinEvent($event)"
                        >
                    </div>
                    <!--                <div class="action_fiche_evenement">-->
                    <!--                    <button class="btn btn-picto-med" title="Afficher les filtres"-->
                    <!--                            [ngClass]="toggleFilter ? 'btn-filter-slash' : 'btn-filter'"-->
                    <!--                            (click)="toggleFilter = !toggleFilter">-->
                    <!--                    </button>-->
                    <!--                </div>-->
                    <div class="container_action">
                        <div class="action_fiche_evenement">
                            <button type="button" class="btn btn-med btn-ajouter" (click)="creerEvenement()">
                                <span>Ajouter un évènement</span>
                            </button>
                        </div>
                    </div>
                </div>
                <app-evenement [uuid]="personne.uuid"
                               [eventDashboard]="false"
                               [eventRappel]="false"
                ></app-evenement>
            </div>
            <div class="bloc_onglet_fiche bloc_info" *ngIf="ongletName === 'info'">
                <app-informations-detaillees
                        [personne]="personne"
                ></app-informations-detaillees>
            </div>
            <div class="bloc_onglet_fiche" *ngIf="ongletName === 'personne-linked' && personne.type !== 'physique'">
                <app-personne-linked [uuid]="personne.uuid"
                                     [personne]="personne.personneLinked"
                                     [ongletName]="ongletName"
                                     [creationPersonne]="paramsCreation"
                                     [typePersonne]="paramsType"
                                     [uuidPersonneCreate]="uuidPersonneCreate"
                                     [libellePersonneCreate]="libellePersonneCreate"
                                     (reload)="eventCreateLien($event)"
                                     [personnesPhysiquesLinked]="personne.personneLinked.physique"
                                     [personnesMoralesLinked]="personne.personneLinked.morale"
                                     [nbLienPhysique]="nbLienPhysique"
                                     [nbEnfant]="nbEnfant"
                                     [nbParent]="nbParent"
                ></app-personne-linked>
            </div>
        </div>
    </div>
    <ng-template #tags let-disabled="disabled">
        <app-select-tag
                id="tags"
                [control]="form?.controls.tags"
                [plUuid]="personne.uuid"
                [target]="tagTarget"
        ></app-select-tag>
    </ng-template>
</ng-container>

