<div class="volet_rappel">
    <div class="title">
        <h2 *ngIf="nbEvent.nbEventEnRetard > 0; then manyCallbacks else noCallback"></h2>
        <ng-template #manyCallbacks>Vous avez actuellement : {{ getCurrentRappelCounts() }} Rappel(s)</ng-template>
        <ng-template #noCallback>Vous n'avez aucun rappel</ng-template>
    </div>
    <div class="datas_volet">
        <div class="content_data metrics">
            <div class="title_bloc">
                <h2 *ngIf="nbFicheIncomplete > 0">{{ nbFicheIncomplete }} Fiche(s) à optimiser</h2>
                <h2 *ngIf="nbFicheIncomplete === 0">Aucune fiche à optimiser</h2>
            </div>
            <div class="title_bloc">
                <h2 *ngIf="nbEvent.nbEventEnRetard > 0">{{ nbEvent.nbEventEnRetard }} Tâche(s) en retard</h2>
                <h2 *ngIf="nbEvent.nbEventEnRetard === 0">Aucune tâche en retard</h2>
            </div>
        </div>
        <div class="content_data actions">
            <div class="container_fiche">
                <button type="button" class="btn btn-med" (click)="openOnglet('fiche')">
                    <span>Voir les fiches</span>
                </button>
            </div>
            <div class="container_event">
                <button type="button" class="btn btn-med" (click)="openOnglet('tache')">
                    <span>Voir mes tâches</span>
                </button>
            </div>
        </div>
    </div>
</div>
