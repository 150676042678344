/* tslint:disable */
import {ICoordonnee} from '../interfaces/coordonnee';

export class Coordonnee implements ICoordonnee {
	public '@id'?: string;
	public principal?: boolean;
	public type_id?: number;
	public sous_type_id?: string;
	public statut_id?: string;
	public uuid?: string;
	public valeur?: string;
	public indicatif?: any;

	public consent?: boolean;

	public consent_type?: boolean;

	public consent_custom_type?: boolean;

	constructor(data?: object) {
		if (data) {
			Object.assign(this, data);
		}
	}

}
