import HydraMember from "./hydra-member";

export default class PersonneSimilarStat extends HydraMember{
    similarityLevel?: number = 0;
    totalDistinctIds: number = 0;
    distinct: number = 0;
    private: number = 0;
    publicted: number = 0;

    constructor(level: number = 0) {
        super();
        this.similarityLevel = level
    }
}