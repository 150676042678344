<div class="bloc_title_modal">{{title}}</div>
<div class="bloc_body_modal">
    <div class="introduction">
        {{introduction}}
    </div>
    <app-consent-select *ngIf="'consent' === type || 'consentFull' === type" (change)="changeConsent($event)">
    </app-consent-select>

    <div class="sub_text" *ngIf="''!== this.sub_text && !unsubscribe">
        {{sub_text}}
    </div>
    <app-moyen-recueil *ngIf="('consentType' === type || 'consentFull' === type) && !unsubscribe"
                       (change)="changeConsentType($event)"
                       [value]= "{consent_type: '', consent_custom_type: ''}"></app-moyen-recueil>
</div>
<div class="bloc_footer_modal">
    <div class="action_bloc_modal">
        <button type="reset" class="btn btn-med btn-fermer" (click)="cancel()">
            <span>Annuler</span>
        </button>
        <button type="submit" class="btn btn-med btn-valider"
                [ngClass]="{'btn_disabled': isValid()}"
                [disabled]="isValid()" (click)="update()">
            <span>Appliquer</span>
        </button>
    </div>
</div>