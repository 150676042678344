import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {ExceptionService} from '../../_services/exception.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PersonneService} from '../../_services/personne.service';
import {UserService} from '../../_services/user.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-modifier-utilisateur',
    templateUrl: './modifier-utilisateur.component.html',
    styleUrls: ['./modifier-utilisateur.component.scss']
})
export class ModifierUtilisateurComponent implements OnInit {
    private notifier: NotifierService;
    public form: FormGroup;
    public user: any;

    constructor(private personneService: PersonneService,
                private userService: UserService,
                private ref: ChangeDetectorRef,
                public router: Router,
                public route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private ngxService: NgxUiLoaderService,
                private exceptionService: ExceptionService,
                notifier: NotifierService,
    ) {
        this.notifier = notifier;
    }

    ngOnInit(): void {
        const userUuid = this.route.snapshot.params.id;
        if (userUuid) {
            this.userService.getUserByUuid(userUuid).subscribe(user => {
                    this.user = user;
                },
                error => {
                    this.notifier.notify('error', this.exceptionService.statutErreur(error));
                });
        } else {
            this.notifier.notify('error', this.exceptionService.statutErreur('User not Found'));
        }
    }
}
