import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {PersonneLienService} from "../../../../_services/personne-lien.service";

@Component({
    selector: 'app-consent-select-all',
    templateUrl: './consent-select-all.component.html',
    styleUrls: ['./consent-select-all.component.scss']
})
export class ConsentSelectAllComponent implements OnInit {
    title : string = '';
    introduction : string = '';
    sub_text : string = '';
    options : object = {}
    unsubscribe : boolean = false
    @Output() complete = new EventEmitter();
    @Input() type: string;
    @Input() nb: number;
    constructor(
        public router: Router,
        public pls: PersonneLienService
    ) {}
    ngOnInit(): void {
        if (1 === this.nb) {
            this.introduction = 'Pour la personne sélectionnée, '
        } else {
            this.introduction = 'Pour les '+this.nb+' personnes sélectionnées, '
        }
        switch (this.type) {
            case 'consent':
                this.title ='Modifier les consentements'
                this.introduction += 'quel est le statut du consentement que vous souhaitez appliquer ?'
                break;
            case 'consentType':
                this.title ='Modifier le moyen de recueil'
                this.introduction += 'quel est le moyen de recueil que vous souhaitez appliquer ?'
                break;
            case 'consentFull':
                this.title ='Modifier les deux'
                this.introduction += 'quel est le statut du consentement que vous souhaitez appliquer ?'
                this.sub_text += 'Quel est le moyen de recueil que vous souhaitez appliquer ?'
                break;
        }

    }
    isValid() : boolean {
        switch (this.type) {
            case 'consent':
                return !this.hasOwn(this.options, 'consent')
            case 'consentType':
                return !this.hasOwn(this.options, 'consent_type')
                    || (
                        this.hasOwn(this.options,'consent_type')
                        && 5 === this.options['consent_type']
                        && (!this.hasOwn(this.options,'consent_custom_type')
                            || (this.hasOwn(this.options,'consent_custom_type')
                                && '' === this.options['consent_custom_type'])
                        )
                    )
            case 'consentFull':
                return !this.hasOwn(this.options,'consent')
                    || !this.hasOwn(this.options,'consent_type')
                    || (
                        this.hasOwn(this.options,'consent_type')
                            && 5 === this.options['consent_type']
                            && (!this.hasOwn(this.options,'consent_custom_type')
                                || (this.hasOwn(this.options,'consent_custom_type')
                                && '' === this.options['consent_custom_type'])
                            )
                    )
        }
    }
    hasOwn(obj, key) {
        return Object.prototype.hasOwnProperty.call(obj, key)
    }
    cancel(): void {
        this.complete.emit();
    }
    update(): void {
        this.complete.emit({...this.options, update:true});
    }
    changeConsent($event): void {
        if (this.hasOwn($event, 'id')) {
            this.options['consent'] = $event.id
            if(0 === $event.id || 3 === $event.id) {
                this.unsubscribe = true
                this.options['consent_type'] = null
                this.options['consent_custom_type'] = null
            } else {
                this.unsubscribe = false
            }
        }
    }
    changeConsentType($event): void {
        if (this.hasOwn($event, 'consent_type') && this.hasOwn($event, 'consent_custom_type')) {
            this.options['consent_type'] = $event.consent_type
            if (5 === $event.consent_type) {
                this.options['consent_custom_type'] = $event.consent_custom_type
            } else {
                delete this.options['consent_custom_type']
            }
        }
    }
}
