<div class="form_personne form_modifier">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form"
          [formGroup]="form"
          novalidate
          autocomplete="off"
          *ngIf="user"
    >
        <div class="bandeau_personne_formulaire bandeau_mon_compte icon mon_compte">
            <h1>{{ user.prenom | titlecase}} {{ user.nom | uppercase}}</h1>
        </div>
        <div class="container_form form_gauche">
            <div class="bloc_compte_utilisateur">
                <div class="bandeau_form">Modifier mon mot de passe</div>
                <div class="bloc_container">
                    <div class="input_form_password">
                        <label for="oldPassword">Mot de passe actuel</label>
                        <input [type]="typeChampPassword"
                               name="oldPassword"
                               id="oldPassword"
                               formControlName="oldPassword"
                               #oldPasswordInput
                               autocomplete="off"
                        >
                        <div class="viewPassword">
                            <div class="icon oeil d-none" (click)="showHidePassword()"></div>
                        </div>
                        <div class="viewPassword">
                            <div class="icon oeil_barre" (click)="showHidePassword()"></div>
                        </div>
                    </div>
                    <div class="input_form_password">
                        <label for="password">Nouveau mot de passe</label>
                        <input [type]="typeChampPassword"
                               name="password"
                               id="password"
                               formControlName="newPassword"
                               #newPasswordInput
                               autocomplete="off"
                        >
                        <div class="viewPassword">
                            <div class="icon oeil d-none" (click)="showHidePassword()"></div>
                        </div>
                        <div class="viewPassword">
                            <div class="icon oeil_barre" (click)="showHidePassword()"></div>
                        </div>
                        <ul>
                            <li class="alert" *ngIf="form.controls.newPassword.errors?.minlength">
                                Le mot de passe doit contenir au moins {{longueurPassword}} caractères
                            </li>
                            <li class="alert" *ngIf="form.controls.newPassword.errors?.pattern">
                                Le mot de passe doit contenir au moins 1 caractères spécial et un nombre
                            </li>
                        </ul>
                    </div>
                    <div class="input_form_password">
                        <label for="repeatPassword">Confirmer le nouveau mot de passe</label>
                        <input [type]="typeChampPassword"
                               name="repeatPassword"
                               id="repeatPassword"
                               formControlName="newRepeatPassword"
                               #newRepeatPasswordInput
                               autocomplete="off"
                        >
                        <div class="viewPassword">
                            <div class="icon oeil d-none"
                                 (click)="showHidePassword()"
                                 #oeilShow>
                            </div>
                        </div>
                        <div class="viewPassword">
                            <div class="icon oeil_barre"
                                 (click)="showHidePassword()"
                                 #oeilSlash
                            >
                            </div>
                        </div>
                        <ul>
                            <li class="alert"
                                *ngIf="form.controls.newRepeatPassword.hasError('matching')">
                                Les mots de passe ne sont pas identiques
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="form_bas">
        <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()">
            <span>Fermer</span>
        </button>
        <button type="button" class="btn btn-med btn-enregistrer"
                (click)="onSubmit()"
                [ngClass]="{'btn_disabled': !this.form.valid}"
                [disabled]="!this.form.valid">
            <span>Enregistrer</span>
        </button>
        <button type="button" class="btn btn-med btn-enregistrer"
                (click)="onSubmitCancel()"
                [ngClass]="{'btn_disabled': !this.form.valid}"
                [disabled]="!this.form.valid"
        >
            <span>Enregistrer et fermer</span>
        </button>
    </div>
</div>
