import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output, QueryList,
    SimpleChanges, ViewChildren
} from '@angular/core';
import {Options} from 'select2';
import {HelperService} from '../../_services/helper.service';
import {Observable} from 'rxjs';
import {Select2OptionData} from 'ng-select2';
import {FormControl, FormGroup} from '@angular/forms';
import {BaseSelect2Component} from '../../select2/base-select2/base-select2.component';
import {PersonneService} from '../../_services/personne.service';
import {isArray} from "rxjs/internal-compatibility";

@Component({
    selector: 'app-segment-edit-valeurs',
    templateUrl: './segment-edit-valeurs.component.html',
    styleUrls: ['./segment-edit-valeurs.component.scss']
})
export class SegmentEditValeursComponent extends BaseSelect2Component implements OnInit, OnChanges, OnDestroy {

    @Input() form: FormGroup;
    @Input() filtre: any;
    @Input() valeurs: Array<any>;
    @Input() nbValeurs: number;
    @Input() refreshCustom: any;
    @Output() recalculer = new EventEmitter<string>();
    public options: Options;
    public interval = [null, null];
    public dataAsync: Observable<Array<Select2OptionData>>;
    public dataSync;
    public typeSelect: string;
    @ViewChildren('intervalle') intervalle: QueryList<ElementRef>;

    constructor(
        private helperService: HelperService,
        private personneService: PersonneService
    ) {
        super();
    }

    ngOnDestroy(): void {
        this.options = {};
    }

    ngOnChanges(changes: SimpleChanges): void {
        // tslint:disable-next-line:forin
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'refreshCustom': {
                        this.ngOnDestroy();

                        this.initSelect();
                    }
                    break;
                }
            }
        }
    }

    valueChanged($event): void {
        // si false les deux arrays diffèrent
        if (!Array.isArray($event)) {
            $event = [$event];
        }
        const updatedVals = $event.every(elem => {
            return this.filtre.value.valeurs.value.includes(elem);
        });

        if (!updatedVals || ($event.length !== this.filtre.value.valeurs.value.length)) {
            this.valeurs = $event;
            this.filtre.value.valeurs.setValue($event);
        }
    }

    initSelect(): void {
        this.dataAsync = null;
        if (this.filtre) {

            this.setTypeSelect();
            switch (this.typeSelect) {
                case 'select2':
                    this.value = [];
                    if (this.filtre.value.champ.value.type === 'string') {
                        if (this.valeurs.length === 2) {
                            this.interval = [this.valeurs[0][0], this.valeurs[1][0]];
                        }
                        const tmp = [];
                        this.valeurs.map((item) => {
                            if (Array.isArray(item)) {
                                tmp.push({
                                    id: item[0],
                                    text: item[0]
                                });
                                this.value.push(item[0]);
                            } else {
                                tmp.push({
                                    id: item,
                                    text: item
                                });
                                this.value.push(item);
                            }
                        });
                        this.dataAsync = new Observable(observer => {
                            observer.next(tmp);
                            observer.complete();
                        });
                    } else {
                        if (this.filtre.value.champ.value.type === 'radio') {
                            if (Array.isArray(this.valeurs[0])) {
                                this.valeurs[0] = this.valeurs[0][0];
                            }
                        }
                        if (this.filtre.value.champ.value.type === 'checkbox') {
                            if (Array.isArray(this.valeurs[0])) {
                                this.valeurs[0] = this.valeurs[0][0];
                            }
                            const tmp = [];
                            tmp.push({
                                id: '0',
                                text: 'Non'
                            });
                            tmp.push({
                                id: '1',
                                text: 'Oui'
                            });
                            this.dataAsync = new Observable(observer => {
                                observer.next(tmp);
                                observer.complete();
                            });

                            if (this.valeurs[0]) {
                                this.value.push(this.valeurs[0]);
                                this.filtre.value.valeurs.setValue([this.valeurs[0]]);

                            } else {
                                this.filtre.value.valeurs.setValue(['0']);
                            }

                        } else {
                            if (this.filtre.value.champ.value.text === 'Code NACE') {
                                this.personneService.getNaceCodes().subscribe(
                                    (data: Array<any>) => {
                                        const codeNace = [];
                                        codeNace.push({
                                            id: -1,
                                            text: 'Non renseigné'
                                        });
                                        data.forEach(code => {
                                            if (code?.fields?.buyer_nace_cde) {
                                                codeNace.push({
                                                    id: code?.fields?.buyer_nace_cde,
                                                    text: code?.fields?.buyer_nace_cde + ' - ' + code?.fields?.buyer_nace_name
                                                });
                                            }
                                        });
                                        this.fillWithDictionaryValues(codeNace);
                                    }
                                );
                            } else if (this.filtre.value.champ.value.text === 'Code NAF') {
                                this.personneService.getNafCodes().subscribe(
                                    (data: Array<any>) => {
                                        const codeNaf = [];
                                        codeNaf.push({
                                            id: -1,
                                            text: 'Non renseigné'
                                        });
                                        data.forEach(code => {
                                            if (code?.code) {
                                                codeNaf.push({
                                                    id: code.code,
                                                    text: code.code + ' - ' + code.libelle
                                                });
                                            }
                                        });
                                        this.fillWithDictionaryValues(codeNaf);
                                    }
                                );
                            } else {
                                this.helperService.getDictionnaire(this.filtre.value.champ.value.uuid).subscribe(
                                    data => {
                                        const tmp = [];
                                        data.map((item) => {
                                            tmp.push({
                                                id: item.uuid,
                                                text: item.libelle
                                            });
                                        });
                                        this.fillWithDictionaryValues(tmp);
                                    },
                                    err => {
                                        console.error('an error occurred' + err);
                                    }
                                );
                            }
                        }

                    }
                    break;
                case 'select':
                    if (this.filtre.value.champ.value.type === 'radio') {
                        if (Array.isArray(this.valeurs[0])) {
                            this.valeurs[0] = this.valeurs[0][0];
                        }
                        this.helperService.getDictionnaire(this.filtre.value.champ.value.uuid).subscribe(
                            data => {
                                const tmp = [];
                                data.map((item) => {
                                    tmp.push({
                                        id: item.uuid,
                                        text: item.libelle
                                    });
                                });
                                this.dataSync = tmp;
                            },
                            err => {
                                console.error('an error occurred' + err);
                            }
                        );
                    }
                    break;
                case 'input':
                    break;
                case 'number':
                    if (this.valeurs.length === 2) {
                        if (this.filtre.value.champ.value.type === 'string') {
                            this.valeurs[0][0] =  parseFloat(this.valeurs[0][0].replace(',', '.'));
                            this.valeurs[1][0] =  parseFloat(this.valeurs[1][0].replace(',', '.'));
                        }
                        this.interval = [this.valeurs[0][0], this.valeurs[1][0]];
                    }else{
                        if (this.filtre.value.champ.value.type === 'string'){
                            if (Array.isArray(this.valeurs[0])) {
                                this.valeurs[0] = this.valeurs[0][0];
                            }
                            if (typeof this.valeurs[0] === 'string'){
                                this.valeurs[0] = parseFloat(this.valeurs[0].replace(',', '.'));

                            }
                        }
                    }
                    break;
                case 'date':
                    if (this.valeurs.length === 2) {
                        this.interval = [this.valeurs[0][0], this.valeurs[1][0]];
                    }
                    break;
            }
        }
    }

    get valeursCtl(): FormControl {
        return this.filtre.value.valeurs as FormControl;
    }

    intervalChange(): void {
        const tmp = [];
        this.intervalle.map((item) => {
            tmp.push(item.nativeElement.value);
        });
        this.filtre.value.valeurs.setValue(tmp);
    }
    radioChange($event): void {
        this.filtre.value.valeurs.setValue([$event.target.id]);
    }

    checkboxChange($event): void {
        this.filtre.value.valeurs.setValue([$event.target.checked]);
    }

    setTypeSelect(): void {
        if (this.filtre.value.champ) {
            switch (this.filtre.value.champ.value.operateursValues) {
                case 1:
                    this.typeSelect = 'select2';
                    this.options = {
                        multiple: false,
                        tags: false
                    };
                    break;
                case 2:
                    this.typeSelect = 'select2';
                    this.options = {
                        multiple: true,
                        tags: false
                    };
                    break;
                case 3:
                case 5:
                    this.typeSelect = 'select2';
                    this.options = {
                        multiple: true,
                        tags: this.filtre.value.operateur.value.isTag
                    };
                    break;
                case 4:
                case 10:
                    this.typeSelect = 'select2';
                    this.options = {
                        multiple: true,
                        tags: true
                    };
                    break;
                case 6:
                    this.typeSelect = 'number';
                    break;
                case 7:
                    this.typeSelect = 'date';
                    break;
                case 8:
                    if (this.filtre.value.champ.value.type === 'select2') {
                        this.typeSelect = 'select2';
                        let multiple = true;
                        if (this.filtre.value.operateur.value.groupe === 2){
                            multiple = false;
                        }
                        this.options = {
                            multiple,
                            tags: this.filtre.value.operateur.value.isTag
                        };
                    }
                    break;
                case 9:
                    switch (this.filtre.value.champ.value.type) {
                        case 'select2':
                            this.typeSelect = 'select2';
                            this.options = {
                                multiple: true,
                                tags: true
                            };
                            break;
                        case 'date':
                            this.typeSelect = 'date';
                            break;
                        case 'int':
                            this.typeSelect = 'number';
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    fillWithDictionaryValues(data): void {
        this.valeurs.map((item) => {
            let found = false;
            data.map((obj) => {
                if (obj.text === item || obj.id === item || obj.id === Number(item)) {
                    this.value.push(item);
                    found = true;
                }
            });
            if (this.filtre.value.operateur.value.isTag && !found) {
                data.push({
                    id: item,
                    text: item
                });
                this.value.push(item);
            }
        });
        if (isArray(this.value) && 0 < this.value.length) {
            this.value = Array.from(new Set(this.value));
        } else {
            this.value = null
        }
        this.dataAsync = new Observable(observer => {
            observer.next(data);
            observer.complete();
        });
    }
}
