import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {TagService} from '../../_services/tag.service';

@Component({
  selector: 'app-select-tag',
  templateUrl: './select-tag.component.html',
  styleUrls: ['./select-tag.component.scss']
})
export class SelectTagComponent {
  @Input() control: FormControl|AbstractControl = new FormControl();
  @Input() plUuid: string;
  @Input() target: number;
  public placeHolder = 'Mots clés';
  fetcher = () => this.tagService.getTagsFiltered(this.target.toString());
  add = (libelle) => {
    return this.tagService.creerTag({libelle, target: [this.target.toString()]}).toPromise();
  }
  constructor(private tagService: TagService) {}
}
