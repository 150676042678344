import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {InterceptorProviders} from './_interceptors';
import {BrowserModule, Title} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {MenuComponent} from './menu/menu.component';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoginComponent} from './login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {LayoutComponent} from './layout/layout.component';
import {RepertoireComponent} from './repertoire/repertoire.component';
import {LoginFormComponent} from './login-form/login-form.component';
import {NavigationItemComponent} from './menu/navigation-item/navigation-item.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SegmentComponent} from './segment/segment.component';
import {CampagneComponent} from './campagne/campagne.component';
import {OffreComponent} from './offre/offre.component';
import {StatistiqueComponent} from './statistique/statistique.component';
import {CarteComponent} from './repertoire/carte/carte.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {OutilsComponent} from './repertoire/outils/outils.component';
import {NgSelect2Module} from 'ng-select2';
import {CreerComponent} from './repertoire/creer/creer.component';
import {FicheComponent} from './repertoire/fiche/fiche.component';
import {FilArianeComponent} from './layout/fil-ariane/fil-ariane.component';
import {ListeCartesComponent} from './repertoire/carte/liste-cartes/liste-cartes.component';
import {AvatarComponent} from './repertoire/avatar/avatar.component';
import {FonctionStickerComponent} from './repertoire/fonction-sticker/fonction-sticker.component';
import {OngletComponent} from './repertoire/fiche/onglet/onglet.component';
import {NoteComponent} from './repertoire/note/note.component';
import {CreerPersonneComponent} from './repertoire/creer/creer-personne/creer-personne.component';
import {CreerChoixComponent} from './repertoire/creer/creer-choix/creer-choix.component';
import {BlocVisibiliteComponent} from './bloc-visibilite/bloc-visibilite.component';
import {ModifierPersonneComponent} from './repertoire/modifier-personne/modifier-personne.component';
import {UsersSelect2Component} from './select2/users-select2/users-select2.component';
import {OrigineSelect2Component} from './select2/origine-select2/origine-select2.component';
import {
    PersonnnesPhysiqueSelect2Component
} from './select2/personnnes-physique-select2/personnnes-physique-select2.component';
import {StatutSelect2Component} from './select2/statut-select2/statut-select2.component';
import {TitreAutocompleteComponent} from './select2/titre-autocomplete/titre-autocomplete.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MemosComponent} from './repertoire/memos/memos.component';
import {NotifierModule, NotifierOptions, NotifierService} from 'angular-notifier';
import {ChampsPersonalisesComponent} from './repertoire/champs-personalises/champs-personalises.component';
import {CoordonneesComponent} from './repertoire/coordonnee/coordonnees/coordonnees.component';
import {
    CoordonneesEditModalComponent
} from './repertoire/coordonnee/coordonnees-edit-modal/coordonnees-edit-modal.component';
import {AdressesComponent} from './repertoire/adresse/adresses/adresses.component';
import {AdressesEditModalComponent} from './repertoire/adresse/adresses-edit-modal/adresses-edit-modal.component';
import {FormJuridiqueSelect2Component} from './select2/form-juridique-select2/form-juridique-select2.component';
import {VoletParametreComponent} from './menu/volet-parametre/volet-parametre.component';
import {
    ParentsOrChildrenComponent
} from './select2/personnes-morales/parents-or-children/parents-or-children.component';
import {ChiffreAffaireSelect2Component} from './select2/chiffre-affaire-select2/chiffre-affaire-select2.component';
import {CreerFonctionComponent} from './repertoire/creer/creer-fonction/creer-fonction.component';
import {
    FonctionPersonneSelect2Component
} from './select2/fonction-personne-select2/fonction-personne-select2.component';
import {CarteSegmentComponent} from './segment/carte-segment/carte-segment.component';
import {ListeCartesSegmentComponent} from './segment/liste-cartes-segment/liste-cartes-segment.component';
import {FicheSegmentComponent} from './segment/fiche-segment/fiche-segment.component';
import {FicheEditSegmentComponent} from './segment/fiche-edit-segment/fiche-edit-segment.component';
import {
    ChampsRequetablesSelect2Component
} from './select2/champs-requetables-select2/champs-requetables-select2.component';
import {OperateursSelect2Component} from './select2/operateurs-select2/operateurs-select2.component';
import {SegmentEditValeursComponent} from './segment/segment-edit-valeurs/segment-edit-valeurs.component';
import {SelectDynamiqueComponent} from './helper/select-dynamique/select-dynamique.component';
import {FiltreTypeSelect2Component} from './select2/filtre-type-select2/filtre-type-select2.component';
import {EvenementComponent} from './liste-evenements/evenement/evenement.component';
import {NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';
import {ListeTagsComponent} from './parametre/liste-tags/liste-tags.component';
import {PersonneLinkedComponent} from './repertoire/personne-linked/personne-linked.component';
import {ListeComponent} from './repertoire/carte/liste/liste.component';
import {TestComposantComponent} from './test-composant/test-composant.component';
import {VoletRappelComponent} from './menu/volet-rappel/volet-rappel.component';
import {BadgeNotificationComponent} from './menu/badge-notification/badge-notification.component';
import {RappelComponent} from './repertoire/rappel/rappel.component';
import {ListeChampsPersoComponent} from './parametre/liste-champs-perso/liste-champs-perso.component';
import {BaseSelect2Component} from './select2/base-select2/base-select2.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SegmentFunnelChartComponent} from './segment/segment-funnel-chart/segment-funnel-chart.component';
import {ApporteurSelect2Component} from './select2/apporteur-select2/apporteur-select2.component';
import {CartesUtilisateurComponent} from './gestion-users/cartes-utilisateur/cartes-utilisateur.component';
import {AjouterUtilisateurComponent} from './gestion-users/ajouter-utilisateur/ajouter-utilisateur.component';
import {ModifierUtilisateurComponent} from './gestion-users/modifier-utilisateur/modifier-utilisateur.component';
import {ListeUtilisateurComponent} from './gestion-users/liste-utilisateur.component';
import {RolesSelect2Component} from './select2/roles-select2/roles-select2.component';
import {RolesTreeComponent} from './gestion-users/roles-tree-component/roles-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {RolesTreeUserComponent} from './gestion-users/roles-tree-component/roles-tree-user/roles-tree-user.component';
import {EventRappelComponent} from './repertoire/rappel/event-rappel/event-rappel.component';
import {
    FicheIncompleteRappelComponent
} from './repertoire/rappel/fiche-incomplete-rappel/fiche-incomplete-rappel.component';
import {
    ListeOriginesDeContactComponent
} from './parametre/liste-origines-de-contact/liste-origines-de-contact.component';
import {ModalConfirmExitComponent} from './layout/modal-confirm-exit/modal-confirm-exit.component';
import {FicheCampagneComponent} from './campagne/fiche-campagne/fiche-campagne.component';
import {FicheEditCampagneComponent} from './campagne/fiche-edit-campagne/fiche-edit-campagne.component';
import {KanbanCampagneComponent} from './campagne/kanban-campagne/kanban-campagne.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {KanbanTacheComponent} from './campagne/kanban-tache/kanban-tache.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AvatarModule} from 'ngx-avatar';
import {FicheCompletionNoticeComponent} from './helper/fiche-completion-notice/fiche-completion-notice.component';
import {SegmentChartCustomComponent} from './segment/segment-chart-custom/segment-chart-custom.component';
import {Select2CodeNaceComponent} from './select2/code-nace/select2-code-nace.component';
import {Select2CodeNafComponent} from './select2/code-naf/select2-code-naf.component';
import {NgxEchartsModule} from 'ngx-echarts';
import {CampagneParenteSelect2Component} from './select2/campagne-parente-select2/campagne-parente-select2.component';
import {
    RoleDowngradeConfirmationComponent
} from './gestion-users/role-downgrade-confirmation/role-downgrade-confirmation.component';
import {CreerMemoComponent} from './repertoire/memos/creer-memo/creer-memo.component';
import {FormTagComponent} from './parametre/liste-tags/form-tag/form-tag.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormOrigineComponent} from './parametre/liste-origines-de-contact/form-origine/form-origine.component';
import {FormEvenementComponent} from './liste-evenements/form-evenement/form-evenement.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {
    CampagneEmailPreviewComponent,
    PreviewCampagne
} from './campagne/campagne-email-preview/campagne-email-preview.component';
import {FormChampsPersoComponent} from './parametre/liste-champs-perso/form-champs-perso/form-champs-perso.component';
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {
    InformationsDetailleesComponent
} from './repertoire/fiche/informations-detaillees/informations-detaillees.component';
import {TypesEventSelect2Component} from './select2/filtres-events/types-event-select2/types-event-select2.component';
import {
    StatutsEventSelect2Component
} from './select2/filtres-events/statuts-event-select2/statuts-event-select2.component';
import {ConsommationEmailsComponent} from './parametre/consommation-emails/consommation-emails.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {EmailEditorModule} from 'angular-email-editor';
import {ImgGalleryComponent} from './campagne/img-gallery/img-gallery.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {getFrenchPaginatorIntl} from './_intl/fr-paginator';
import {MatBadgeModule} from '@angular/material/badge';
import {NotFoundComponent} from './not-found/not-found.component';
import {CampagneActiveSwitcherComponent} from './campagne/campagne-active-switcher/campagne-active-switcher.component';
import {SwitchComponent} from './components/switch/switch.component';
import {ModifierMotDePasseComponent} from './repertoire/modifier-mot-de-passe/modifier-mot-de-passe.component';
import {OverviewComponent} from './duplication/overview/overview.component';
import {DuplicationListingComponent} from './duplication/listing/duplication-listing.component';
import {MergeComponent} from './duplication/merge/merge.component';
import {DuplicationComponent} from './duplication/duplication.component';
import {VisibilityPipe} from './_pipe/visibility.pipe';
import {EntityTabComponent} from './menu/entity-tab/entity-tab.component';
import {FilterComponent} from './layout/filter/filter.component';
import {DataFilterComponent} from './form/data-filter/data-filter.component';
import {DebouceInputComponent} from './form/debouce-input/debouce-input.component';
import {CatalogueProduitsComponent} from './parametre/products/catalogue-produits/catalogue-produits.component';
import {CarteProduitComponent} from './parametre/products/carte-produit/carte-produit.component';
import {FormProduitComponent} from './parametre/products/form-produit/form-produit.component';
import {CampagneGalerieModelesComponent} from './campagne/campagne-galerie-modeles/campagne-galerie-modeles.component';
import {CampagneModeleComponent} from './campagne/campagne-modele/campagne-modele.component';
import {ChoixModeleComponent} from './campagne/choix-modele/choix-modele.component';
import {SelectTagComponent} from './form/select-tag/select-tag.component';
import {EntitySelectComponent} from './form/entity-select/entity-select.component';
import {OffreKanbanComponent} from './offre/offre-kanban/offre-kanban.component';
import {CardKanbanComponent} from './offre/card-kanban/card-kanban.component';
import {MatChipsModule} from '@angular/material/chips';
import {ActiveSwitcherComponent} from './offre/active-switcher/active-switcher.component';
import {FicheEditOffreComponent} from './offre/fiche-edit-offre/fiche-edit-offre.component';
import {SelectContactComponent} from './form/select-contact/select-contact.component';
import {SelectProductComponent} from './form/select-product/select-product.component';
import {SelectPmComponent} from './form/select-pm/select-pm.component';
import {ModalApercuTemplateComponent} from './campagne/modal-apercu-template/modal-apercu-template.component';
import {FicheOffreComponent} from './offre/fiche-offre/fiche-offre.component';
import {ProbabiliteCursorComponent} from './offre/probabilite-cursor/probabilite-cursor.component';
import {ModalConfirmProbabiliteComponent} from './layout/modal-confirm-probabilite/modal-confirm-probabilite.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {
    PersonneTypeFilterItemComponent
} from './form/data-filter/personne-type-filter-item/personne-type-filter-item.component';
import {
    PersonneStatutFilterItemComponent
} from './form/data-filter/personne-statut-filter-item/personne-statut-filter-item.component';
import {
    PersonneSegmentFilterItemComponent
} from './form/data-filter/personne-segment-filter-item/personne-segment-filter-item.component';
import {
    PersonneVisibiliteFilterItemComponent
} from './form/data-filter/personne-visibilite-filter-item/personne-visibilite-filter-item.component';
import {ModalComponent} from './_modal/modal.component';
import {CheckboxFilterItemComponent} from './form/data-filter/checkbox-filter-item/checkbox-filter-item.component';
import {ConsentementComponent} from './repertoire/consentement/consentement.component';
import {ModalSendDevisComponent} from './offre/modal-send-devis/modal-send-devis.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {ConfigurationGeneraleComponent} from './parametre/configuration-generale/configuration-generale.component';
import {FileFieldComponent} from './file-field/file-field.component';
import {PersonTypePipe} from './_pipe/person-type.pipe';
import {ConsentSelectComponent} from './repertoire/consentement/consent-select/consent-select.component';
import {MoyenRecueilComponent} from './repertoire/consentement/moyen-recueil/moyen-recueil.component';
import {SelectFilterItemComponent} from './form/data-filter/select-filter-item/select-filter-item.component';
import {
    PersonneReferentFilterComponent
} from './form/data-filter/personne-referent-filter/personne-referent-filter.component';
import {MailConsentComponent} from './form/data-filter/mail-consent/mail-consent.component';
import {
    MailConsentMoyenRecueilComponent
} from './form/data-filter/mail-consent-moyen-recueil/mail-consent-moyen-recueil.component';
import {NumberDecimalPipe} from './pipes/number.pipe';
import {SpinnerComponent} from './spinner/spinner.component';
import {ModalBuyTemplateComponent} from './campagne/modal-buy-template/modal-buy-template.component';
import {MatSelectModule} from '@angular/material/select';
import {ConsentSelectAllComponent} from './repertoire/consentement/modal/consent-select-all/consent-select-all.component';
import {InformationComponent} from './repertoire/consentement/modal/information/information';
import {BlockCampagneComponent} from './campagne/block-campagne/block-campagne.component';

registerLocaleData(localeFr);

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
    position: {
        horizontal: {
            position: 'middle'
        },
        vertical: {
            position: 'top',
            distance: 60,
            gap: 10
        }
    },
    theme: 'material',
    behaviour: {
        autoHide: 10000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
    },
    animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsPosition: POSITION.centerCenter,
    bgsSize: 40,
    blur: 2,
    overlayColor: 'rgba(40,40,40,0.5)',
    bgsType: SPINNER.rectangleBounce, // background spinner type
    fgsType: SPINNER.rectangleBounce, // foreground spinner type
    pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
    pbThickness: 0, // progress bar thickness
};

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        LoginComponent,
        LayoutComponent,
        RepertoireComponent,
        LoginFormComponent,
        NavigationItemComponent,
        DashboardComponent,
        SegmentComponent,
        CampagneComponent,
        OffreComponent,
        StatistiqueComponent,
        CarteComponent,
        OutilsComponent,
        CreerComponent,
        FicheComponent,
        FilArianeComponent,
        ListeCartesComponent,
        AvatarComponent,
        FonctionStickerComponent,
        OngletComponent,
        NoteComponent,
        CreerPersonneComponent,
        CreerChoixComponent,
        BlocVisibiliteComponent,
        ModifierPersonneComponent,
        UsersSelect2Component,
        OrigineSelect2Component,
        PersonnnesPhysiqueSelect2Component,
        StatutSelect2Component,
        TitreAutocompleteComponent,
        MemosComponent,
        ChampsPersonalisesComponent,
        CoordonneesComponent,
        CoordonneesEditModalComponent,
        AdressesComponent,
        AdressesEditModalComponent,
        FormJuridiqueSelect2Component,
        VoletParametreComponent,
        ParentsOrChildrenComponent,
        ChiffreAffaireSelect2Component,
        CreerFonctionComponent,
        FonctionPersonneSelect2Component,
        CarteSegmentComponent,
        ListeCartesSegmentComponent,
        FicheSegmentComponent,
        FicheEditSegmentComponent,
        ChampsRequetablesSelect2Component,
        OperateursSelect2Component,
        SegmentEditValeursComponent,
        SelectDynamiqueComponent,
        FiltreTypeSelect2Component,
        EvenementComponent,
        ListeTagsComponent,
        ListeComponent,
        PersonneLinkedComponent,
        TestComposantComponent,
        VoletRappelComponent,
        BadgeNotificationComponent,
        RappelComponent,
        ListeChampsPersoComponent,
        BaseSelect2Component,
        SegmentFunnelChartComponent,
        ApporteurSelect2Component,
        CartesUtilisateurComponent,
        AjouterUtilisateurComponent,
        ModifierUtilisateurComponent,
        ListeUtilisateurComponent,
        RolesSelect2Component,
        RolesTreeComponent,
        RolesTreeUserComponent,
        EventRappelComponent,
        FicheIncompleteRappelComponent,
        ListeOriginesDeContactComponent,
        FicheCampagneComponent,
        FicheEditCampagneComponent,
        KanbanCampagneComponent,
        KanbanTacheComponent,
        ModalConfirmExitComponent,
        FicheCompletionNoticeComponent,
        SegmentChartCustomComponent,
        Select2CodeNaceComponent,
        Select2CodeNafComponent,
        CampagneParenteSelect2Component,
        RoleDowngradeConfirmationComponent,
        CreerMemoComponent,
        FormTagComponent,
        FormOrigineComponent,
        InformationsDetailleesComponent,
        FormChampsPersoComponent,
        CampagneEmailPreviewComponent,
        PreviewCampagne,
        FormEvenementComponent,
        ShortNumberPipe,
        TypesEventSelect2Component,
        StatutsEventSelect2Component,
        ConsommationEmailsComponent,
        ImgGalleryComponent,
        NotFoundComponent,
        CampagneActiveSwitcherComponent,
        SwitchComponent,
        ModifierMotDePasseComponent,
        OverviewComponent,
        DuplicationListingComponent,
        MergeComponent,
        DuplicationComponent,
        VisibilityPipe,
        EntityTabComponent,
        FilterComponent,
        DataFilterComponent,
        DebouceInputComponent,
        CatalogueProduitsComponent,
        CarteProduitComponent,
        FormProduitComponent,
        SelectPmComponent,
        CampagneGalerieModelesComponent,
        CampagneModeleComponent,
        ChoixModeleComponent,
        SelectTagComponent,
        EntitySelectComponent,
        OffreKanbanComponent,
        CardKanbanComponent,
        ActiveSwitcherComponent,
        FicheEditOffreComponent,
        SelectContactComponent,
        SelectProductComponent,
        ModalApercuTemplateComponent,
        FicheOffreComponent,
        ProbabiliteCursorComponent,
        ModalConfirmProbabiliteComponent,
        PersonneTypeFilterItemComponent,
        PersonneStatutFilterItemComponent,
        PersonneSegmentFilterItemComponent,
        PersonneVisibiliteFilterItemComponent,
        ModalComponent,
        CheckboxFilterItemComponent,
        ConsentementComponent,
        NumberDecimalPipe,
        ModalSendDevisComponent,
        ConsentementComponent,
        ConfigurationGeneraleComponent,
        FileFieldComponent,
        ModalBuyTemplateComponent,
        ModalSendDevisComponent,
        PersonTypePipe,
        ConsentSelectComponent,
        MoyenRecueilComponent,
        SelectFilterItemComponent,
        PersonneReferentFilterComponent,
        MailConsentComponent,
        MailConsentMoyenRecueilComponent,
        SpinnerComponent,
        ConsentSelectAllComponent,
        InformationComponent,
        BlockCampagneComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        FontAwesomeModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        InfiniteScrollModule,
        NgSelect2Module,
        AutocompleteLibModule,
        NotifierModule.withConfig(customNotifierOptions),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        InfiniteScrollModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatTreeModule,
        MatCheckboxModule,
        MatIconModule,
        MatFormFieldModule,
        MatProgressBarModule,
        DragDropModule,
        AvatarModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        NgSelectModule,
        MatExpansionModule,
        NgSelectModule,
        EmailEditorModule,
        ClipboardModule,
        MatBadgeModule,
        MatChipsModule,
        MatSidenavModule,
        MatInputModule,
        CKEditorModule,
        MatSelectModule
    ],
    providers: [InterceptorProviders, NotifierService, Title, {
        provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl()
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
