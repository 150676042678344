<div
        class="onglet_last_item {{type}}"
        [class.active]="isActive"
        (auxclick)="$event.button === 1 && close.emit($event)"
>
    <div>
        <span
                [class]="'icon type ' + type"
                [ngClass]="{
                    'user': type === PersonGlobals.PERSON_LIEN_PHYSIQUE || type === PersonGlobals.PERSON_LIEN,
                    'building': type === PersonGlobals.PERSON_LIEN_MORALE,
                    'filter': type === VueGlobals.SEGMENT,
                    'campagne': type === VueGlobals.CAMPAGNE
                }"
        ></span>
        <span class="text_onglet_item">{{ label }}</span>
    </div>
    <span class="icon close" (click)="close.emit($event)"></span>
</div>
