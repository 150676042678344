import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import PersonneSimilarStat from '../../../models/personne-similar-stat';
import {Filter, PersonneLienService} from '../../_services/personne-lien.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {finalize} from 'rxjs/operators';
import IHydraCollection from '../../../interfaces/hydra-collection';
import {EChartsOption} from 'echarts';
import {Subscription} from 'rxjs';
import PersonneSimilarities from '../../../models/personne-similarities';
import {DataFilter} from '../../../models/datafilter';
import {FilterItem} from '../../../models/filteritem';
import {FiltresGlobals} from '../../../const-global/filtres';
import {MessageGlobals} from '../../../const-global/messages';

@Component({
    selector: 'app-duplication-listing',
    templateUrl: './duplication-listing.component.html',
    styleUrls: ['./duplication-listing.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicationListingComponent implements OnInit {

    listSubscription?: Subscription;
    statsSubscription?: Subscription;
    similarityLevel;
    level;
    filter: Filter;
    loaded = false;
    listLoading = false;
    donutDataIsLoading = true;
    hydra: IHydraCollection<PersonneSimilarities>;
    list: PersonneSimilarities[] = [];
    stats = null;
    donut: EChartsOption;
    statSimilarity: PersonneSimilarStat[] = [];
    statAll;
    ratioDuplicated = 0;
    total: number;
    dataFilter: DataFilter;
    public FILTRES_GLOBALS = FiltresGlobals;
    public readonly MESSAGE_GLOBALS = MessageGlobals;
    public messageSwitch = {
        actif: this.MESSAGE_GLOBALS.MESSAGE_FICHE_ACTIVE,
        inactif: this.MESSAGE_GLOBALS.MESSAGE_FICHE_INACTIVE
    };

    readonly labelLevel = [
        '',
        'very_weak',
        'weak',
        'strong',
        'very_strong',
        'all'
    ];

    readonly libelleLevel = [
        '',
        'potentiels',
        'probables',
        'très probables',
        'avérés',
        ''
    ];

    readonly similarities = {
        NAME_EXACT: 'Noms et prénoms identiques',
        NAME_SIMILAR: 'Noms et prénoms similaires',
        SIRET_EXACT: 'Numéros de siret identiques',
        SIRET_SIMILAR: 'Numéros de siret similaires',
        COMPANY_NAME_EXACT: 'Raisons sociales identiques',
        COMPANY_NAME_SIMILAR: 'Raisons sociales similaires',
        PHONE_OR_EMAIL_EXACT: 'Emails ou téléphones identiques',
    };

    getLibelleSimilarity(similarities: string[]): any {
        return similarities.map(s => {
            return this.similarities[s];
        }).join(' + ');
    }

    visibilities(visibilities): any {
        return (visibilities.map(v => {
            return {1: 'Public', 2: 'Protégé', 3: 'Privé'}[v];
        })).join(' / ');
    }

    constructor(
        public router: Router,
        public pls: PersonneLienService,
        private activatedRoute: ActivatedRoute,
        private ngxUiLoaderService: NgxUiLoaderService,
        private ref: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.ngxUiLoaderService.startLoader('list');
        this.initStats();
        this.initDataFilter();
        this.activatedRoute.params.subscribe(data => {
            this.list = [];
            this.level = data.level;
            this.similarityLevel = {
                very_strong: 4,
                strong: 3,
                weak: 2,
                very_weak: 1,
                all: 5
            }[this.level] || 5;
            this.loaded && this.dataFilter && this.fetchPersonneLien();
            this.loaded = true;
        });
    }

    initDataFilter(): void {
        this.dataFilter = new DataFilter();
        this.dataFilter.lsKey = 'duplication';
        this.dataFilter.filters = [
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_TYPE_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_STATUT_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_SEGMENT_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.VISIBILITE_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_GROUPS_FILTRE),
        ];
    }

    fetchPersonneLien(): void {
        if (!this.level) {
            return;
        }
        if (this.listSubscription) {
            this.listSubscription.unsubscribe();
        }
        this.listLoading = true;
        this.listSubscription = this.pls.similarities({
            ...this.dataFilter.getQueryParams(),
            ...(5 === this.similarityLevel) ? {'personsSimilar.similarityLevel[lt]': this.similarityLevel} : {'personsSimilar.similarityLevel': this.similarityLevel},
            writable: true,
        })
            .pipe(finalize(() => this.listLoading = false))
            .subscribe(data => {
                this.list = this.list.concat(data['hydra:member']);
                this.hydra = data;
                this.ref.markForCheck();
            });
    }

    fetchStat(): void {
        if (this.statsSubscription) {
            this.statsSubscription.unsubscribe();
        }
        this.donutDataIsLoading = false;
        this.statsSubscription = this.pls.statsSimilarity(this.dataFilter.getQueryParams()).subscribe(data => {
            this.initStats();
            this.initDonut();
            this.total = 0;

            data['hydra:member'].forEach((stat, index) => {
                this.statSimilarity[stat.similarityLevel || 0] = stat;
                this.donut.series[0].data[4 - (stat.similarityLevel || 0)] = stat.distinct;
                this.total += stat.distinct;
                if (stat.similarityLevel) {
                    this.statAll.distinct += stat.distinct
                    this.statAll.private += stat.private
                    this.statAll.publicted += stat.publicted
                    this.statAll.totalDistinctIds += stat.totalDistinctIds
                }
            });

            this.ratioDuplicated =
                ((this.statSimilarity[1].distinct
                        + this.statSimilarity[2].distinct
                        + this.statSimilarity[3].distinct
                        + this.statSimilarity[4].distinct)
                    / this.total)
                || 0;

            this.donutDataIsLoading = false;
            this.ref.markForCheck();
        });
    }

    private initDonut(): void {
        this.donut = {
            color: [
                '#CC0000',
                '#FF8147',
                '#FFB200',
                '#FFDD8F',
                '#49CD8F'
            ],
            series: [
                {
                    name: 'État du répertoire',
                    type: 'pie',
                    radius: ['62%', '80%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    data: [0, 0, 0, 0, 0]
                }
            ]
        };
    }

    private initStats(): void {
        this.statSimilarity[0] = new PersonneSimilarStat(0);
        this.statSimilarity[1] = new PersonneSimilarStat(1);
        this.statSimilarity[2] = new PersonneSimilarStat(2);
        this.statSimilarity[3] = new PersonneSimilarStat(3);
        this.statSimilarity[4] = new PersonneSimilarStat(4);
        this.statAll = new PersonneSimilarStat(5)
    }

    onInputFilter(): void {
        this.list = [];
        this.dataFilter.page = 1;
        this.fetchPersonneLien();
        this.fetchStat();
    }

    onScroll(): void {
        if (this.hydra['hydra:view']['hydra:next'] && !this.listLoading) {
            this.dataFilter.page++;
            this.fetchPersonneLien();
        }
    }
    goToAll(shouldGo: boolean): void {
        this.dataFilter.page = 1;
        if (!shouldGo) {
            return;
        }
        this.router.navigate(['doublons', 'all']);
    }
}
