import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SegmentService} from '../../_services/segment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../_services/exception.service';
import {VisibiliteService} from '../../_services/visibilite.service';
import {ISegment} from '../../../interfaces/segment';
import {Segment} from '../../../models/segment';
import {IChamp, IFiltre, IOperator} from '../../_helpers/segment.helper';
import {ModalConfirmExitComponent} from '../../layout/modal-confirm-exit/modal-confirm-exit.component';
import {environment} from '../../../environments/environment';
import {MessageGlobals} from "../../../const-global/messages";

@Component({
    selector: 'app-fiche-edit-segment',
    templateUrl: './fiche-edit-segment.component.html',
    styleUrls: ['./fiche-edit-segment.component.scss'],
})
export class FicheEditSegmentComponent implements OnInit, AfterViewChecked, AfterViewInit {
    public form: FormGroup;
    public message: string;
    public segment: ISegment;
    public errorMessage: string;
    public resultats: Array<any>;
    public nbContacts: Array<any>;
    public showCalculer = false;
    public creation = false;
    public duplicate = false;
    public formValid = false;
    public listeChamps: Array<IChamp>;
    public listeOperateurs: Array<IOperator>;
    public updateInProgress = false;
    public liveUpdateInProgress = false;
    public blink = false;
    private segmentModified = false;
    @ViewChild('confirmModal') confirmModal: ModalConfirmExitComponent;
    @ViewChildren('operateursSelect2Component') operateursSelect2Component: QueryList<Component>;

    constructor(
        public router: Router,
        private segmentService: SegmentService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private visibiliteService: VisibiliteService,
        private notifier: NotifierService,
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            uuid: '',
            titre: ['', Validators.required],
            visibilite: [3, Validators.required],
            actif: ['1'],
            referent: [],
            intervenants: [''],
            filtres: this.formBuilder.array([])
        }, {});
        // get la liste des champs requétables disponibles
        this.segmentService.getChamps().subscribe(champs => {
            this.listeChamps = champs;
        });
        this.segmentService.getOperateurs().subscribe(
            (data: Array<any>) => {
                this.listeOperateurs = data;
            }
        );
        /**
         * si la route contient duplicate, on set duplicate à true, on get les infos du segment à dupliquer
         * et on reset l'uuid avec un nouvel uuid pour faire un create
         */
        if (this.router.url.endsWith('duplicate')) {
            this.duplicate = true;
            this.getData(this.route.snapshot.params.id);
        } else if (this.router.url.endsWith('modifier')) {
            this.getData(this.route.snapshot.params.id);
        } else {
            this.creation = true;
            this.segment = new Segment('', '', [], [], '3', 1, 0, 0, null);
            // this.form.patchValue(this.segment);
        }
    }

    get filtres(): FormArray {
        return this.form.get('filtres') as FormArray;
    }

    /* Récupère les données d'une fiche */
    getData(uuid: string): void {
        this.ngxService.startLoader('filtres');
        this.segmentService.getSegment(
            uuid,
            false
        ).subscribe(
            data => {
                this.form.get('uuid').setValue(data?.uuid);
                this.form.get('titre').setValue(data?.titre);
                this.form.get('actif').setValue(data?.actif);
                this.form.get('visibilite').setValue(parseInt(data?.visibilite));
                if (data.filtres) {
                    data.filtres.map((dataFiltre) => {
                        const filtre = new IFiltre();
                        filtre.uuid = dataFiltre?.uuid;
                        if (!dataFiltre.champ) return;
                        filtre.champ = this.formBuilder.control({
                            uuid: dataFiltre?.champ.uuid,
                            text: dataFiltre?.champ.libelle,
                            type: dataFiltre?.champ.type,
                            max: dataFiltre?.champ.max,
                            min: dataFiltre?.champ.min,
                            operateursValues: dataFiltre?.champ.operateursValues
                        });
                        if (!dataFiltre.operateur) return;
                        filtre.operateur = this.formBuilder.control({
                            uuid: dataFiltre?.operateur.uuid,
                            text: dataFiltre?.operateur.libelle,
                            nbValeurs: dataFiltre?.operateur.nbValeurs,
                            isTag: dataFiltre?.operateur.isTag,
                            groupe: dataFiltre?.operateur.groupe,
                            operateursValues: dataFiltre?.operateur.operateursValues
                        });
                        if (!dataFiltre.valeurs) return;
                        filtre.valeurs = this.formBuilder.control(dataFiltre?.valeurs);
                        filtre.ordre = dataFiltre?.ordre;
                        filtre.refreshCustom = this.formBuilder.control(false);
                        this.filtres.push(this.formBuilder.control(filtre, Validators.required));
                    });
                    this.calculateFiltres();
                }
                this.segment = data;
                this.visibiliteService.sendData(this.segment.uuid, 'segment');
                this.ngxService.stopLoader('filtres');
            },
            err => {
                this.ngxService.stopLoader('filtres');
                console.error('an error occurred' + err);
            }
        );
    }

    /* Récupère la liste des résultats du segment */
    getLiveResultats(uuid: string): void {
        this.liveUpdateInProgress = true;
        this.segmentService.getSegmentLiveResultats(this.form.getRawValue()).subscribe(
            data => {
                this.resultats = data.content.resultats;
                this.segment.nbContactsPublics = data.content.count.public;
                this.segment.nbContactsPrives = data.content.count.prive;
            },
            err => {
                if (typeof err === 'object' && environment.production === false) {
                    console.error(err);
                }
                this.liveUpdateInProgress = false;
                this.resultats = [];
                this.segment.nbContactsPublics = 0;
                this.segment.nbContactsPrives = 0;
            },
            () => {
                setTimeout(() => {
                    this.liveUpdateInProgress = false;
                }, 200);
            }
        );
    }

    ngAfterViewChecked(): void {
        this.ref.detectChanges();
    }

    ngAfterViewInit(): void {
        this.segmentService.segmentUpdatedSubject.subscribe(v => {
            if (v === true) {
                this.confirmModal.modified.next(true);
            }
            this.blink = v;
        });
        this.confirmModal.modified.subscribe(v => {
            this.segmentModified = v;
        });
    }

    refreshResultats(uuid: string): void {
        this.ngxService.startLoader('previsualisation');
        this.getLiveResultats(uuid);
        this.ngxService.stopLoader('previsualisation');
    }

    public onSubmit(redirect: boolean, navigate: boolean): void {
        this.ngxService.start();
        if (this.form.valid) {
            if (this.duplicate === true) {
                // this.form.get('uuid').setValue();
            }
            let ls = JSON.parse(localStorage.getItem('repertoire'));
            this.updateLocalStorage(ls);
            if (this.router.url.endsWith('modifier')) {
                this.updateSegment(redirect, navigate);
            }
            if (this.router.url.endsWith('creer')) {
                this.createSegment();
            }
            if (this.router.url.endsWith('duplicate')) {
                this.duplicateSegment();
            }
        } else {
            this.ngxService.stop();
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
        this.segmentModified = false;
    }

    private createSegment() {
        this.segmentService.createSegment(this.form.getRawValue()).subscribe(
            data => {
                this.showNotification('success', 'Le segment ' + data.titre + ' a bien été crée');
                this.router.navigate(['/segment', data.uuid, 'modifier']);
            }
        );
    }

    private updateSegment(redirect: boolean, navigate: boolean) {
        this.segmentService.updateSegment(this.segment.uuid, this.form.getRawValue()).subscribe(
            data => {
                if (this.creation === true || this.duplicate === true) {
                    this.segment.uuid = data.content;
                }
                this.ngxService.stop();
                const titre = this.form.getRawValue().titre;
                this.showNotification('success', 'Le segment ' + titre + ' a bien été mis à jour');
                this.calculateFiltres();
                if (redirect) {
                    this.router.routeReuseStrategy.shouldReuseRoute = function te(): boolean {
                        return false;
                    };
                }
                if (navigate) {
                    this.router.navigate(['/segment/' + this.segment.uuid]);
                }
            },
            err => {
                this.ngxService.stop();
                this.showNotification('error', this.exceptionService.statutErreur(err));
            },
            () => {

            }
        );
    }

    private duplicateSegment() {
        this.segmentService.duplicateSegment(this.segment.uuid, this.form.getRawValue()).subscribe(
            data => {
                this.showNotification('success', 'Le segment ' + data.titre + ' a bien été créé');
                this.router.navigate(['/segment', data.uuid, 'modifier']);
            }
        );
    }

// permet d'update le localStorage avec les nouvelles valeurs du segment
    private updateLocalStorage(ls: { segment: { titre: any; uuid: any; }[]; }) {
        if (ls && ls.segment) {
            ls.segment.filter((segment) => {
                if (segment.uuid === this.form.getRawValue().uuid) {
                    // setItem into localStorage
                    ls.segment.splice(ls.segment.indexOf(segment), 1);
                    ls.segment.push({'titre': this.form.getRawValue().titre, 'uuid': this.form.getRawValue().uuid});
                    // update filter repertoire
                    localStorage.setItem('repertoire', JSON.stringify(ls));
                }
            });
        }
    }

    public onCancel(): void {
        if (this.segmentModified) {
            this.confirmModal.openConfirmationModal();
        }
        if (!this.confirmModal.isOpened()) {
            this.router.navigate(['/segment/']);
        }
    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    calculateFiltres(): void {
        this.ngxService.startLoader('filtres');
        this.nbContacts = [];
        this.updateInProgress = true;
        this.segmentService.calculerResultats(this.form.getRawValue()).subscribe(
            data => {
                this.nbContacts = data.content;
            },
            () => {
                this.ngxService.stopLoader('filtres');
                this.updateInProgress = false;
            },
            () => {
                this.ngxService.stopLoader('filtres');
                this.updateInProgress = false;
            }
        );
    }

    showResultats(): void {
        this.resultats = null;
    }

    ajouterChamp(): void {
        const newField = new IFiltre();
        newField.champ = this.formBuilder.control({
            uuid: '',
            text: '',
            type: ''
        });
        newField.operateur = this.formBuilder.control({
            uuid: '',
            text: '',
            nbValeurs: ''
        });
        newField.valeurs = this.formBuilder.control([]);
        newField.ordre = this.filtres.length;
        newField.refreshCustom = this.formBuilder.control(false);
        this.filtres.push(this.formBuilder.control(newField, Validators.required));
        this.segmentService.segmentUpdatedSubject.next(true);
    }

    onSupprimer(uuid: string, index: number): void {
        this.filtres.removeAt(index);
        this.calculateFiltres();
        this.segmentService.segmentUpdatedSubject.next(true);
    }

    /** fired when CSS animation ends */
    animationEnd(): void {
        this.segmentService.segmentUpdatedSubject.next(false);
    }
}
