import {Injectable} from '@angular/core';
import {
     HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable()
export class NotfoundInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<object>> {
         return next.handle(req).pipe(catchError(error => {
             if (
                 error instanceof HttpErrorResponse && error.status === 404
                 && !req.url.includes(environment.apiURL) // avoid 404 on API causing redirection on the front
             ) {
                 this.router.navigate(['/not-found']);
             }
             return throwError(error);
         }));
     }
}
