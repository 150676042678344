import {Component, Input, OnInit} from '@angular/core';
import {IUser} from '../../../interfaces/user';
import {OrganisationService} from '../../_services/organisation.service';
import {Organisation} from '../../../models/organisation';
import {UIGlobals} from '../../../const-global/globals';
import {PersonneService} from '../../_services/personne.service';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../../_services/auth.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-volet-parametre',
    templateUrl: './volet-parametre.component.html',
    styleUrls: ['./volet-parametre.component.scss']
})
export class VoletParametreComponent implements OnInit {
    @Input() personneConnecte: IUser;
    @Input() organisation: Organisation;
    env = environment;
    displayContactSupport = false;

    constructor(
        private authService: AuthService,
        private organisationService: OrganisationService,
        private personneService: PersonneService,
        private titleService: Title,
    ) {
    }

    ngOnInit(): void {
        if (this.organisation?.nom) {
            this.titleService.setTitle(UIGlobals.TITLE_CRM + ' - ' + this.organisation?.nom.toUpperCase());
        }
    }

    logout(): void {
        this.authService.logout();
    }
}
