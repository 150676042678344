import {Component, Input} from '@angular/core';
import {PersonneService} from "../../../_services/personne.service";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-parents-or-children',
    templateUrl: './parents-or-children.component.html',
    styleUrls: ['./parents-or-children.component.scss']
})
export class ParentsOrChildrenComponent {
    @Input() currentPersonne: string;
    @Input() control: FormControl | AbstractControl = new FormControl();
    public personnes = [];

    constructor(private personneService: PersonneService) {
    }

    onOpen(): void {
        this.personneService.getChildrenOrParent(this.currentPersonne).subscribe(items => {
            this.personnes = items;
        });
    }
}
