import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsentHelper} from "../../../_helpers/consent.helper";
import {CoordonneeGlobals} from "../../../../const-global/globals";

@Component({
    selector: 'app-consent-select',
    templateUrl: './consent-select.component.html',
    styleUrls: ['./consent-select.component.scss']
})
export class ConsentSelectComponent implements OnInit {
    public readonly COORD_GLOBALS = CoordonneeGlobals;
    public readonly ConsentHelper = ConsentHelper;

    @Output() change = new EventEmitter();
    @Input() value: any;

    ngOnInit(): void {
    }

    update($event): void {
        this.change.emit($event);
    }
}
