import {Component, OnInit} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {CampagneService} from '../../_services/campagne.service';
import {Campagne} from '../../../models/campagne';
import {CampaignGlobals} from '../../../const-global/globals';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DateFormaterService} from '../../_services/date-formater.service';

@Component({
	selector: 'app-kanban-campagne',
	templateUrl: './kanban-campagne.component.html',
	styleUrls: ['./kanban-campagne.component.scss']
})
export class KanbanCampagneComponent implements OnInit {
	public draft: Campagne[];
	public scheduled: Campagne[];
	public inProgress: Campagne[];
	public done: Campagne[];
	public active: boolean;

	color: ThemePalette = 'warn';

	constructor(
		private campagneService: CampagneService,
		private ngxService: NgxUiLoaderService,
		private dateFormaterService: DateFormaterService
	) {
	}

	ngOnInit(): void {
		this.ngxService.start();

		this.campagneService.getAllCampaigns(this.active).subscribe((data) => {
				this.draft = data.filter(campaign => campaign?.statut === CampaignGlobals.DRAFT);
				this.scheduled = data.filter(campaign => campaign?.statut === CampaignGlobals.QUEUED && this.dateFormaterService.formatDateTime(campaign?.startDate) > this.dateFormaterService.formatDateTime(new Date()));
				this.inProgress = data.filter(campaign => campaign?.statut === CampaignGlobals.IN_PROCESS || (this.dateFormaterService.formatDateTime(campaign?.startDate) <= this.dateFormaterService.formatDateTime(new Date()) && campaign?.statut === CampaignGlobals.QUEUED));
				this.done = data.filter(campaign => (campaign?.statut === CampaignGlobals.SENT || campaign?.statut === CampaignGlobals.SUSPENDED));
				this.done = this.dateFormaterService.sortByDateDESC(this.done);
				this.ngxService.stop();
			},
			() => {
				this.ngxService.stop();
			}
		);

		this.campagneService.campaignActiveSubject.subscribe(value => {
			this.active = value;
			this.campagneService.refreshCampaigns(this.active, null, null);
		});
	}

	getTotalScheduled(): number {
		let total = 0;
		this.scheduled.forEach((item) => {
			total = total + item.contacts;
		});
		return total;
	}

	getTotalInProgress(): number {
		let total = 0;
		this.inProgress.forEach((item) => {
			total = total + item.contacts;
		});
		return total;
	}

	getTotalDone(): number {
		let total = 0;
		this.done.forEach((item) => {
			total = total + item.contacts;
		});
		return total;
	}

	getPercentInProgress(): number {
		let total = 0;
		let totalSent = 0;
		this.inProgress.forEach((item) => {
			total = total + item.contacts;
			totalSent += item.sent;
		});
		if (total > 0) {
			return totalSent * 100 / total;
		}
		return total;
	}
}
