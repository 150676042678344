import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import {interval, Observable, Subscription} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {mergeMap, startWith} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class RefreshGuard implements CanActivateChild {

    private lastModified?: string
    private notModified = true;
    private subscription: Subscription
    constructor(private http: HttpClient) {

        this.subscription = interval(60*1000)
            .pipe(startWith(0), mergeMap(() => {
                return this.http.request('HEAD', 'index.html', {observe: 'response'})
            }))
            .subscribe(data => {
                const headers = data.headers;
                if (!headers.has('Last-Modified')) {
                    this.subscription.unsubscribe()
                    return ;
                }
                const currentLastModified = data.headers.get('Last-Modified');
                if (!this.lastModified) {
                    this.lastModified = currentLastModified;
                    return ;
                }
                if (this.lastModified !== currentLastModified) {
                    this.notModified = false
                    this.subscription.unsubscribe()
                }
            });
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (!this.notModified) {
            location.href = state.url
        }

        return true;
    }
}
