import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VoletService {

  constructor() {}

  public volet = new Subject<string>();
}
