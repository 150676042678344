import {v4 as uuidv4} from 'uuid';
import {FormControl} from '@angular/forms';

export class IFiltre {
    public uuid = uuidv4();
    public champ: FormControl;
    public operateur: FormControl;
    public valeurs: FormControl;
    public ordre: number;
    public refreshCustom: FormControl;
}

export class IChamp {
    uuid: string;
    text: string;
    type: string;
    max: number;
    min: number;
}

export class IOperator {
    uuid: string;
    text: string;
    nbValeurs: number|string;
    operateursValues: Array<number>;
    isTag: boolean;
}
