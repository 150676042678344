import {Component, Input} from '@angular/core';
import {BaseSelect2Component} from '../base-select2/base-select2.component';
import {PersonneService} from '../../_services/personne.service';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-select2-code-naf',
    templateUrl: './select2-code-naf.component.html',
    styleUrls: ['./select2-code-naf.component.scss']
})
export class Select2CodeNafComponent {

    items = this.personneService.getNafCodes().pipe(map(items => {
        return items.map((item) => {
                return {...item, label: item.code + ' - ' + item.libelle}
            }
        )
    }));

    @Input() control: FormControl|AbstractControl = new FormControl();

    constructor(private personneService: PersonneService) {}
}

