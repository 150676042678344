import {Component, Input, OnInit} from '@angular/core';
import {EvenementService} from '../../_services/evenement.service';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-filtre-type-select2',
    templateUrl: './filtre-type-select2.component.html',
    styleUrls: ['./filtre-type-select2.component.scss']
})
export class FiltreTypeSelect2Component implements OnInit {
    @Input() control: FormControl | AbstractControl = new FormControl();
    statuts = [];

    constructor(private evenementService: EvenementService) {
    }

    ngOnInit(): void {
        this.evenementService.getListeTypeEvent().subscribe((data: Array<string>) => {
            this.statuts = data;
        });
    }
}
