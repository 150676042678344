import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterItem} from "../../../../models/filteritem";

@Component({
    selector: 'app-select-filter-item',
    templateUrl: './select-filter-item.component.html',
    styleUrls: ['./select-filter-item.component.scss']
})
export class SelectFilterItemComponent implements OnInit {
    @Input() filter: FilterItem;
    @Output() updated = new EventEmitter();
    dataSource: any;

    ngOnInit(): void {
    }
}
