<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title class="justify-between">
            <div>{{filter.name}}</div>
            <div class="pastille_counter"
                 *ngIf="filter.getCount() !== 0">{{ filter.getCount() }}</div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex items-center gap-2">
        <ng-select
                bindLabel="personnePhysique.libelle"
                id="referentFilter"
                [items]="dataSource"
                [clearable]="true"
                [multiple]="true"
                [virtualScroll]="true"
                [dropdownPosition]="'bottom'"
                (change)="updateFilter($event)"
                (clear)="updateFilter(null)"
                [(ngModel)]="this.filter.value"
        ></ng-select>
    </div>
</mat-expansion-panel>
