<div class="azko-spinner"
     [@display]="display ? 'enter' : 'exit'"
     (@display.done)="animationDone($event)"
     (@display.start)="animationStart()"
>
    <svg
            [class.in]="display"
            [class.out]="!display"
            xmlns="http://www.w3.org/2000/svg">
        <g id="Calque_1">
            <path class="cls-1 a e"
                  d="M5.51,53.82,59.21,0H48.68L.18,48.49a.64.64,0,0,0,0,.89l4.44,4.44A.62.62,0,0,0,5.51,53.82Z"/>
            <path class="cls-1 a o"
                  d="M10.84,60l4.44,4.44a.63.63,0,0,0,.89,0L80.52,0H70L10.84,59.15A.62.62,0,0,0,10.84,60Z"/>
            <path class="cls-1 a e"
                  d="M21.49,70.69l4.44,4.44a.62.62,0,0,0,.89,0L101.82,0H91.3L21.49,69.8A.64.64,0,0,0,21.49,70.69Z"/>
            <path class="cls-1 b o"
                  d="M79.21,33.4,4.11,108.63H14.64l69.9-69.9a.64.64,0,0,0,0-.89L80.1,33.4A.62.62,0,0,0,79.21,33.4Z"/>
            <path class="cls-1 b e"
                  d="M89.87,44.06,25.42,108.63H36L95.19,49.38a.62.62,0,0,0,0-.89l-4.44-4.44A.62.62,0,0,0,89.87,44.06Z"/>
            <path class="cls-1 b o"
                  d="M100.52,54.71,46.73,108.63H57.26L105.85,60a.64.64,0,0,0,0-.89l-4.44-4.44A.62.62,0,0,0,100.52,54.71Z"/>
        </g>
    </svg>
</div>