<router-outlet></router-outlet>
<notifier-container></notifier-container>
<app-modal-confirm-probabilite
    #confirmModal
    (confirmEvent)="onConfirmModal($event)"
    (cancelEvent)="onCancelModal($event)"
    [probabilite]="newProbabilite"
></app-modal-confirm-probabilite>
<app-modal-send-devis #sendDevisModal></app-modal-send-devis>
<ng-container *ngIf="!router.url.endsWith('/modifier') && form">
    <div>
        <app-fil-ariane></app-fil-ariane>
        <ngx-ui-loader></ngx-ui-loader>
    </div>
    <notifier-container></notifier-container>
    <div class="fiche_offre_grid">
        <div class="sidebar">
            <div class="fiche_infos" *ngIf="offre">
                <div></div>
                <div class="content">
                    <div>
                        <div class="header">
                            <span class="icon file-signature"></span>
                            <h1 class="title">{{ offre.libelle }}</h1>
                            <div class="btn_border_white" *ngIf="this.form?.controls.probabilite?.value.id < 99">
                                <button type="button" class="btn btn-picto-lg picto-edition" (click)="openEdition()" name="Editer">
                                </button>
                            </div>
                        </div>
                        <div class="montant">
                            <h5>Montant HT : <span class="stronger">{{montantHT}}</span></h5>
                            <h5>Montant TTC : <span class="stronger">{{montantTTC}}</span></h5>
                        </div>
                        <form [formGroup]='form'>

                            <div class="probabilite" *ngIf="currentProbabilite">
                                <app-probabilite-cursor
                                        *ngIf="currentProbabilite"
                                        [formControl]="form.controls.probabilite"
                                        ngDefaultControl
                                        (changed)="updateProbabilite($event)"
                                        [modalOpened]="confirmModal.isOpened()"
                                        [currentValue]="currentProbabilite "
                                >
                                </app-probabilite-cursor>
                            </div>
                        </form>
                    </div>
                    <div class="elements">
                        <div class="input_lg bloc_tag">
                            <app-select-tag
                                    id="tags"
                                    [control]="form?.controls.tags"
                                    [target]="tagTarget"
                            >
                            </app-select-tag>
                        </div>
                        <div class="memos">
                            <app-creer-memo [offre]="offre" [memoFiche]="true" [persist]="true"></app-creer-memo>
                            <app-memos id="memo"
                                       [disabled]="false"
                                       [offre]="offre"
                                       [persist]="true"
                                       [memoFiche]="true"
                            ></app-memos>
                        </div>
                        <div class="priorite">
                            <h3>Priorité</h3>
                            <div class="icon circle"
                                 [ngClass]="{
                                  'low': offre?.priorite.id === 1,
                                  'medium': offre?.priorite.id === 2,
                                  'high': offre?.priorite.id === 3,
                                  'critical': offre?.priorite.id === 4
                             }"
                                 *ngIf="offre.priorite?.id > 0"
                            >&nbsp;
                                {{offre?.priorite?.libelle ? offre?.priorite.libelle : 'Non définie'}}
                            </div>
                            <div class="icon" *ngIf="offre.priorite?.id === 0">
                                Non définie
                            </div>
                        </div>
                    </div>

                </div>
                <div class="footer">
                    <div class="status_personne">
                        <div class="bloc_actif">
                            <i class="icon" [ngClass]="{
                            'actif': offre?.active,
                            'inactif': !offre?.active
                        }"></i>
                            <span *ngIf="offre?.active">Actif</span>
                            <span class="inactif" *ngIf="!offre?.active">Inactif</span>
                        </div>
                    </div>
                    <div class="date_creation_fiche">
                        <p *ngIf="offre">
                            <span>Créé le {{offre.createdAt | date: fullDate}}</span>
                            <span *ngIf="offre.userCreation?.personnePhysique">
                           par {{offre.userCreation.personnePhysique.nom}} {{offre.userCreation.personnePhysique.prenom}}
                        </span>
                        </p>
                        <p *ngIf="offre">
                            <span>Modifié le  {{offre.updatedAt | date: fullDate}}</span>
                            <span *ngIf="offre.userModification?.personnePhysique">
                           par {{offre.userModification.personnePhysique.nom}} {{offre.userModification.personnePhysique.prenom}}
                        </span>
                        </p>
                    </div>
                </div>
            </div>
            <app-repertoire-carte [personne]="personneContact" [formatCarte]="true"></app-repertoire-carte>
        </div>
        <div class="main">
            <div class="tabs">
                <div class="tab active"><i class="fa-solid fa-file-lines mr-2"></i>Devis</div>
<!--                <div class="tab"><i class="fa-solid fa-calendar-day"></i>&nbsp;Événement (2/10)</div>-->
            </div>
            <div  class="tab_body">
                <div class="row">
                    <div class="pull-right">
                        <a
                                [href]="pdfUrl"
                                [download]="pdfFilename"
                                [ngClass]="{btn_disabled : !pdfUrl}"
                                (click)="onDownload()"
                                class="btn btn-med"
                                target="_blank"
                        >
                            <i class="fa-solid fa-file-arrow-down mr-2"></i>&nbsp;Télécharger
                        </a>
                        <button type="button" [ngClass]="{'btn_disabled': !pdfUrl || this.offre?.probabilite?.id === 999 || this.offre?.probabilite?.id === 100}"
                                (click)="sendDevisModal.open(this.offre)"
                                class="btn btn-med">
                            <i class="fa-solid fa-paper-plane mr-2"></i>&nbsp;Envoyer
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="pdfUrl">
                <embed id="pdf-embeded" [src]="pdfUrl"/>
                </ng-container>
                <ng-container *ngIf="0 === offre.devis.lignes.length">
                    <p class="text-danger">Génération du devis impossible : veuillez ajouter des lignes à votre offre.</p>
                </ng-container>
            </div>
        </div>


    </div>
</ng-container>

<ng-template #currency let-value="value">
    {{ value| currency:'EUR':'symbol':'1.2-2':'fr' }}
</ng-template>
