import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ModalService } from '../../../_modal';
import { AdresseService } from '../../../_services/adresse.service';
import { IAdresse } from 'src/interfaces/adresse';
import { Adresse } from 'src/models/adresse';
import { v4 as uuidv4 } from 'uuid';
import { AdressesEditModalComponent } from '../adresses-edit-modal/adresses-edit-modal.component';
import {PersonGlobals} from '../../../../const-global/globals';
import {CoordonneService} from '../../../_services/coordonne.service';

@Component({
    selector: 'app-adresses',
    templateUrl: './adresses.component.html',
    styleUrls: ['./adresses.component.scss']
})
export class AdressesComponent implements OnInit {
    @Input() uuid;
    @Input() personType: string;
    @Output() adressePrincipal = new EventEmitter<string>();

    @ViewChild('addressModal') addressModal: AdressesEditModalComponent;

    public PERSON_GLOBALS = PersonGlobals;
    public adresses: Array<Adresse>;
    public objetCourant: Adresse;

    constructor(
        private modalService: ModalService,
        private adresseService: AdresseService,
        private coordonneeService: CoordonneService,
    ) {
    }

    ngOnInit(): void {
        this.adresseService.addressSubject.subscribe((data: Array<Adresse>) => {
            this.adresses = data;
        },
            err => {
                console.log(err);
            }
        );
    }

    onChangeRadio(uuid: string): void {
        this.adresses.map((item: IAdresse) => {
            item.principal = (item.uuid === uuid);
        });
        this.adresseService.addressSubject.next(this.adresses);
    }

    onAjouter(): void {
        this.addressModal.openModal('adresseModal');
    }

    onEditer(adresse: Adresse): void {
        this.addressModal.openEditModal('adresseModal', adresse);
    }

    updateAdresses($event): void {
        if ($event?.data?.principal === true){
            this.adressePrincipal.emit($event.data.uuid);
        }
        if ($event?.data && this.adresses.length === 0) {
            $event.data.principal = true;
        }
        const currentAddress = this.adresses.filter(address => address.uuid === $event.data.uuid);
        if (currentAddress.length === 0){
            this.adresses.push($event.data);
        } else {
            this.adresses.forEach((item, index) => {
                if (item.uuid === $event.data.uuid) {
                    this.adresses[index] = $event.data;
                }
            });
        }
        this.adresseService.addressSubject.next(this.adresses);
        this.coordonneeService.personUpdatedSubject.next(true);
    }

    onSupprimer(uuid: string): void {
        this.adresses = this.adresses.filter(obj => obj.uuid !== uuid);
        if (this.adresses.length > 0 && !this.isPrincipal(uuid)){
            this.adressePrincipal.emit(this.adresses[0].uuid);
        }
        this.adresseService.addressSubject.next(this.adresses.filter(obj => obj.uuid !== uuid));
        this.coordonneeService.personUpdatedSubject.next(true);
    }

    openModal(id: string): void {
        this.modalService.open(id);
    }

    close(): void {
        this.objetCourant = this.resetAdresse();
    }

    resetAdresse(): Adresse{
        return new Adresse({uuid: uuidv4()});
    }

    hasAdresse(adresses: Array<IAdresse>): boolean {
        return !(adresses === undefined || adresses.length === 0);
    }

    isPrincipal(uuid: string): boolean {
        let hasPrincipal = false;
        this.adresses.forEach((item: Adresse) => {
            if (item.uuid === uuid && item.principal === true){
                hasPrincipal = false;
            }else if (item.principal === true) {
                hasPrincipal = true;
            }
        });
        return hasPrincipal;
    }
}
