import {Component, OnInit} from '@angular/core';
import {MessageGlobals} from "../../../const-global/messages";
import {CampagneService} from "../../_services/campagne.service";

@Component({
    selector: 'app-campagne-active-switcher',
    templateUrl: './campagne-active-switcher.component.html',
    styleUrls: ['./campagne-active-switcher.component.scss']
})
export class CampagneActiveSwitcherComponent implements OnInit {
    public activeCampaigns: boolean;
    public readonly MESSAGE_GLOBALS = MessageGlobals;

    constructor(
        private campagneService: CampagneService
    ) {
    }

    ngOnInit(): void {
        this.activeCampaigns = this.campagneService.campaignActiveSubject.getValue();
    }

    toggle() {
        this.activeCampaigns = !this.activeCampaigns;
        this.campagneService.campaignActiveSubject.next(this.activeCampaigns);
    }
}
