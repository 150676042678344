import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-debouce-input',
  templateUrl: './debouce-input.component.html',
  styleUrls: ['./debouce-input.component.scss']
})
export class DebouceInputComponent implements OnInit {

  @Input()  model!: number | string;
  @Output() modelChange = new EventEmitter<number | string>();
  @ViewChild('debouceInput', {read:ElementRef}) debouceInput: ElementRef<HTMLInputElement>;

  modelChangeSubject = new Subject<string>();

  constructor() {
    this.modelChangeSubject.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.modelChange.emit(value);
        });
  }

  ngOnInit(): void {
  }

}
