import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subject, Subscription} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApiGlobals} from '../../const-global/globals';
import {map, shareReplay} from 'rxjs/operators';
import IHydraCollection from "../../interfaces/hydra-collection";
import {IEvenement} from "../../interfaces/evenement";
import toHttpParams from "./to-http-params.service";

const API_URL = environment.apiURL;

export interface EventCounterUser {
    nbEventRappel: number;
    nbEventNow: number;
    nbEventEnRetard: number;
    nbEventTotal: number;
}

export interface EventCounterFiche {
    nbEventEnRetard: number;
    nbEventAFaire: number;
    nbEventTotal: number;
}

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};
export type EvenementFilter = {
    page?: number;
    itemsPerPage?: number;
    '_with[]'?: string[];
    'type'?: number;
    'statut'?: number;
    'titre'?: string;
    'actif'?: number;
    'userAssigne'?: string;
    'dateEcheance[after]'?: string;
    'dateEcheance[before]'?: string;
};

@Injectable({
    providedIn: 'root'
})
export class EvenementService {
    typeEventSubject = new Subject<any>();
    statutEventSubject = new Subject<any>();
    rechercheEventSubject = new Subject<any>();
    dateDebutEventSubject = new Subject<any>();
    dateFinEventSubject = new Subject<any>();
    public counterEventSubjectUser: BehaviorSubject<EventCounterUser> = new BehaviorSubject({
        nbEventRappel: 0,
        nbEventNow: 0,
        nbEventEnRetard: 0,
        nbEventTotal: 0
    });
    public counterEventSubjectFiche: BehaviorSubject<EventCounterFiche> = new BehaviorSubject({
        nbEventEnRetard: 0,
        nbEventAFaire: 0,
        nbEventTotal: 0
    });
    private apiSubscription: Subscription;
    private nbEventRappel$: Observable<any> = null;

    constructor(private http: HttpClient) {
        // Empêche l'effet blink on appel une première fois le compteur
        const firstCall = this.getNbEventRappel();
        const subs = firstCall.subscribe(value => {
            this.counterEventSubjectUser.next(value);
        });
        // récupère les nouvelles valeurs toutes les n secondes
        this.apiSubscription = interval(ApiGlobals.POLLING_INTERVAL).subscribe(() => {
            // on unsubscribe le compteur, le subject multicast va prendre le relais
            subs.unsubscribe();
            const obs = this.getNbEventRappel(true);
            obs.subscribe(value => {
                this.counterEventSubjectUser.next(value);
            });
        });
    }

    public refreshOnConnect(): void {
        this.nbEventRappel$ = null;
    }

    public majNbEventRappel(): void {
        const firstCall = this.getNbEventRappel();
        firstCall.subscribe(value => {
            this.counterEventSubjectUser.next(value);
        });
    }

    public getListeEvenementsPersonneLienApiPlateforme(uuid: string, evenementFilter: EvenementFilter): Observable<any> {
        const params = toHttpParams(evenementFilter);
        return this.http.get(API_URL + 'personne_liens/' + uuid + '/evenements', {params});
    }

    public getListeEvenementsByUserApiPlateforme(uuid: string, filter: EvenementFilter): Observable<any> {
        const params = toHttpParams(filter);
        return this.http.get(API_URL + 'evenements', {params});
    }


    public getEvenement(uuidEvent: string): Observable<any> {
        return this.http.get(API_URL + 'repertoire/evenement/' + uuidEvent);
    }

    public creerEvenement(form): Observable<any> {
        return this.http.post(API_URL + 'repertoire/creer/evenement', {
            evenement: form,
        }, httpOptions);
    }

    public modifierEvenement(uuid, form): Observable<any> {
        return this.http.put(API_URL + 'repertoire/evenement/' + uuid, {
            evenement: form,
        }, httpOptions);
    }

    public desactiveEvenement(uuid): Observable<any> {
        return this.http.get(API_URL + 'desactive-evenement/' + uuid);
    }

    public terminerEvenement(uuid): Observable<any> {
        const http = this.http.get(API_URL + 'valide-evenement/' + uuid);
        http.subscribe(() => {
            this.getNbEventRappel(true).subscribe(value => {
                this.counterEventSubjectUser.next(value);
                this.counterEventSubjectFiche.next(value);
            });
        });
        return http;
    }

    public getListeTypeEvent(): Observable<any> {
        return this.http.get(API_URL + 'type-event');
    }

    public getNbEventRappel(refresh?: boolean): Observable<any> {
        this.nbEventRappel$ = this.http.get(API_URL + 'count-event').pipe(
            shareReplay(),
            map((res: any) => {
                this.counterEventSubjectUser.next(res);
                return res;
            })
        );

        return this.nbEventRappel$;
    }

    public getNbEventFiche(uuid): Observable<any> {
        return this.http.get(API_URL + 'count-event/' + uuid).pipe(
            shareReplay(1),
            map((res: any) => {
                this.counterEventSubjectFiche.next(res);
                return res;
            })
        );
    }

    create(body: Partial<IEvenement>) {
        return this.request('POST', 'evenements', {body, headers: [{'Content-Type': 'application/json'}]});
    }

    request<IEvenement>(method: string, endPoint: string = '', options = {}): Observable<IEvenement | IHydraCollection<IEvenement>> {
        return this.http.request<IEvenement>(method, API_URL + endPoint, options);
    }
}

