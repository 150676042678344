import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {Select2OptionData} from 'ng-select2';
import {Options} from 'select2';
import {PersonneService} from '../../../_services/personne.service';
import {ModalService} from '../../../_modal';
import {Adresse} from '../../../../models/adresse';
import {v4 as uuidv4} from 'uuid';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdressesGlobals} from '../../../../const-global/adresses';
import {NotifierService} from 'angular-notifier';
import {MessageGlobals} from "../../../../const-global/messages";


@Component({
    selector: 'app-adresses-edit-modal',
    templateUrl: './adresses-edit-modal.component.html',
    styleUrls: ['./adresses-edit-modal.component.scss'],
})
export class AdressesEditModalComponent implements OnInit {
    @Output() added = new EventEmitter<any>();
    @Output() closed = new EventEmitter<any>();

    public options: Options;
    public types: Observable<Array<Select2OptionData>>;
    public statuts: Observable<Array<Select2OptionData>>;
    public form: FormGroup;
    public data: any;
    public edit = false;
    private typeMapping: Array<any>;
    private statutMapping: Array<any>;

    constructor(
        private personneService: PersonneService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private notifier: NotifierService
    ) {
    }

    ngOnInit(): void {
        this.options = {};
        this.personneService.getAdressesTypesAndStatuts().subscribe(
            data => {
                const types = [];
                const statuts = [];
                data.types.map((item: { id: any; text: any; }) => {
                    types.push({
                        id: item.id,
                        text: item.text
                    });
                });
                data.statuts.map((item: { id: any; text: any; }) => {
                    statuts.push({
                        id: item.id,
                        text: item.text
                    });
                });
                this.typeMapping = types;
                this.statutMapping = statuts;
                this.types = new Observable((observer: Subscriber<Select2OptionData[]>) => {
                    observer.next(types);
                    observer.complete();
                });
                this.statuts = new Observable((observer: Subscriber<Select2OptionData[]>) => {
                    observer.next(statuts);
                    observer.complete();
                });
            },
            err => {
                console.log(err);
            }
        );
        this.form = this.formBuilder.group({
            uuid: [this.data?.uuid || uuidv4(), Validators.required],
            principal: [this.data?.principal],
            type: [this.data?.type],
            typeId: [this.data?.typeId, Validators.required],
            statut: [this.data?.statut],
            statutId: [this.data?.statutId, Validators.required],
            lignes: this.formBuilder.group({
                ligne1: [this.data?.lignes?.ligne1, Validators.required],
                ligne2: [this.data?.lignes?.ligne2],
                ligne3: [this.data?.lignes?.ligne3]
            }),
            cp: [this.data?.cp, Validators.required],
            ville: [this.data?.ville, Validators.required],
            cedexCode: [this.data?.cedexCode],
            cedexLibelle: [this.data?.cedexLibelle],
            pays: [this.data?.pays],
        }, {updateOn: 'change'});
    }

    get lignes(): FormGroup {
        return this.form.get('lignes') as FormGroup;
    }

    public openEditModal(id: string, address: Adresse): void {
        this.data = address;
        this.edit = true;
        this.modalService.open(id);
        this.ngOnInit();
    }

    public openModal(id: string): void {
        this.edit = false;
        this.data = new Adresse({
            uuid: uuidv4(),
            typeId: AdressesGlobals.ADDRESS_DEFAULT_TYPEID,
            type: this.getTypeLibelle(AdressesGlobals.ADDRESS_DEFAULT_TYPEID),
            statutId: AdressesGlobals.ADDRESS_DEFAULT_STATUTID,
            statut: this.getStatutLibelle(AdressesGlobals.ADDRESS_DEFAULT_STATUTID)
        });
        this.modalService.open(id);
        this.ngOnInit();
    }

    closeModal(): void {
        // on applique un objet vide avec un nouvel uuid
        this.form.reset();
        this.closed.emit(true);
        this.modalService.close('adresseModal');
    }

    onSubmitModal(): void {
        if (this.form.valid) {
            if (this.form.get('typeId').value === null) {
                this.form.get('typeId').setValue(AdressesGlobals.ADDRESS_DEFAULT_TYPEID);
                this.form.get('type').setValue(this.getTypeLibelle(AdressesGlobals.ADDRESS_DEFAULT_TYPEID));
            }
            if (this.form.get('statutId').value === null) {
                this.form.get('statutId').setValue(AdressesGlobals.ADDRESS_DEFAULT_STATUTID);
                this.form.get('statut').setValue(this.getStatutLibelle(AdressesGlobals.ADDRESS_DEFAULT_STATUTID));
            }
            this.added.emit({
                type: 'adresses',
                data: this.form.getRawValue()
            });
            this.closeModal();
        } else {
            this.notifier.notify('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
    }

    selectChange($event, type: string): void {
        if (this.form.contains(type) && $event) {
            switch (type) {
                case 'typeId':
                    this.form.get('type').setValue(this.getTypeLibelle($event.id));
                    break;
                case 'statutId':
                    this.form.get('statut').setValue(this.getStatutLibelle($event.id));
                    break;
            }
            this.form.get(type).setValue($event.id);
        } else {
            this.form.get(type).setValue(null);
        }
    }

    getStatutLibelle(statutId: number): string | boolean {
        const libelle = this.statutMapping.filter(statut => statut.id === +statutId);
        if (libelle.length > 0) {
            return libelle[0].text;
        }
        return false;
    }

    getTypeLibelle(typeId: number): string | boolean {
        if (this.typeMapping) {
            const libelle = this.typeMapping.filter(statut => statut.id === +typeId);
            if (libelle.length > 0) {
                this.form.get('type').setValue(libelle[0].text);
                return libelle[0].text;
            }
        }
        return false;
    }
}
