import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
    selector: 'app-fonction-personne-select2',
    templateUrl: './fonction-personne-select2.component.html',
    styleUrls: ['./fonction-personne-select2.component.scss']
})
export class FonctionPersonneSelect2Component implements OnChanges {
    @Input() control: FormControl | AbstractControl = new FormControl();
    @Input() ppUuid: string;
    @Input() pmUuid: string;
    items = [];

    constructor(private personneService: PersonneService) {
    }

    onOpen(): void {
        if (this.items.length > 0) {
            return;
        }
        let subject;
        if (this.ppUuid && this.ppUuid !== '-1' && this.pmUuid) {
            subject = this.personneService.getAvailableFonctions(this.ppUuid, this.pmUuid);
        } else {
            subject = this.personneService.getPersonnesFonctions();
        }
        subject.subscribe(items => {
            this.items = items;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ppUuid || changes.pmUuid) {
            this.items = [];
            this.control.setValue(null);
        }
    }
}
