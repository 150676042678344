import {Component, Input} from '@angular/core';
import {
    faAddressCard,
    faBell,
    faChartLine,
    faEnvelope,
    faFileSignature,
    faFilter,
    faHome
} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';
import {IUser} from '../../interfaces/user';
import {PersonneService} from '../_services/personne.service';
import {PersonGlobals, VoletGlobals} from '../../const-global/globals';
import {MessageGlobals} from '../../const-global/messages';
import {EvenementService} from '../_services/evenement.service';
import {environment} from '../../environments/environment';
import {LastEntity} from "../../models/lastentity";
import {EntityTabsService} from "../_services/entity-tabs.service";
import {VoletService} from "../_services/volet.service";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

    public VOLET_GLOBAL = VoletGlobals;
    public PersonGlobals = PersonGlobals;
    public readonly MESSAGE_GLOBALS = MessageGlobals;

    location: string = '';

    @Input() personneConnecte: IUser;
    @Input() uuidPersonne;
	@Input() nbEventEnRetard: number;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public entityTabs: EntityTabsService,
        public personneService: PersonneService,
        public evenementService: EvenementService,
        public voletService: VoletService,
    ) {
    }

    env = environment;
    scrollableLeft: boolean;
    scrollableRight: boolean;

    /** Icons **/
    faHome = faHome;
    faAddressCard = faAddressCard;
    faFilter = faFilter;
    faEnvelope = faEnvelope;
    faFileSignature = faFileSignature;
    faChartLine = faChartLine;
    faBell = faBell;
    public lastItems;


    // ouvre et ferme le volet sur le click de l'avatar dans le menu
    actionVolet(id: string): void {
        this.voletService.volet.next();

        const voletRappel = document.getElementById('voletRappel');
        const voletParametre = document.getElementById('voletParametre');

        if (id === 'voletRappel') {
            if (voletRappel.classList.contains(this.VOLET_GLOBAL.RAPPEL_FERME)) {
                voletRappel.classList.remove(this.VOLET_GLOBAL.RAPPEL_FERME);
                voletParametre.classList.add(this.VOLET_GLOBAL.PARAMETRE_FERME);
            } else if (!voletRappel.classList.contains(this.VOLET_GLOBAL.RAPPEL_FERME)) {
                voletRappel.classList.add(this.VOLET_GLOBAL.RAPPEL_FERME);
            }
        }

        if (id === 'voletParametre') {
            if (voletParametre.classList.contains(this.VOLET_GLOBAL.PARAMETRE_FERME)) {
                voletParametre.classList.remove(this.VOLET_GLOBAL.PARAMETRE_FERME);
                voletRappel.classList.add(this.VOLET_GLOBAL.RAPPEL_FERME);
            } else if (!voletParametre.classList.contains(this.VOLET_GLOBAL.PARAMETRE_FERME)) {
                voletParametre.classList.add(this.VOLET_GLOBAL.PARAMETRE_FERME);
            }
        }
    }


    removeItem(uuid): void {
        this.entityTabs.remove(uuid);
    }

    onClickUpsellLastEntities(entity: LastEntity): void {
        this.router.navigate([entity.uri]);
    }

    /**
     * calcule le scroll total à faire dans le bloc central
     */
    calculateScroll(): void {
        // -5px à cause du scroll sur le bloc parent
        const el = document.getElementById('block_menu_centre');
        this.scrollableLeft = (el.offsetWidth + Math.abs(el.scrollLeft)) < el.scrollWidth - 5;
        this.scrollableRight = el.scrollLeft < 0;
    }

    /**
     * mouvement vers la droite
     */
    public scrollRight(el: HTMLElement): void {
        el.scrollTo({left: (el.scrollLeft + 200), behavior: 'smooth'});
        this.calculateScroll();
    }

    /**
     * mouvement vers la gauche
     */
    public scrollLeft(el: HTMLElement): void {
        el.scrollTo({left: (el.scrollLeft - 200), behavior: 'smooth'});
        this.calculateScroll();
    }
}
