import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CampagneService} from '../_services/campagne.service';
import {catchError} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CampaignsResolver implements Resolve<Observable<any>> {
    constructor(private service: CampagneService) {}

    resolve = (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> => {
        return this.service.getFicheCampaign(route.paramMap.get('id')).pipe(
            catchError((error) => {
                console.error(state + ' ' + error);
                return of({titre: 'N/A'});
            })
        );
    }
}
