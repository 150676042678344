<ng-select2 [data]="formattedFields"
            [options]="options"
            [className]="'typeSelect'"
            id="{{id}}"
            [disabled]="disabled"
            [value]="this.value"
            (valueChanged)="valueChanged($event)"
            (click)="focusSelect2()"
></ng-select2>


