import {Injectable} from '@angular/core';
import {AbstractClient} from "./abstract-client";
import {Filter} from "./personne-lien.service";
import {environment as ENV} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {IDevis} from "../../interfaces/devis";




@Injectable({
    providedIn: 'root'
})
export class DevisService extends AbstractClient<IDevis, Filter> {
    devisActiveSubject = new BehaviorSubject<boolean>(true);

    constructor(http: HttpClient) {
        super(http, ENV.apiURL + 'devis');
    }
}
