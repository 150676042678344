<div class="form_event form_ajouter">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm"
          [formGroup]="form"
          novalidate
    >
        <div class="bandeau_evenement icon calendrier" *ngIf="event; else creationTitle">
            <h1>Evènement : {{event.libelle}}</h1>
        </div>
        <ng-template #creationTitle>
            <div class="bandeau_evenement icon calendrier">
                <h1>Nouvel évènement</h1>
            </div>
        </ng-template>
        <div class="formulaire">
            <div class="container_form form_gauche">
                <div class="bloc_event">
                    <div class="bloc_information_generale">
                        <div class="bandeau_form">Informations générales</div>
                        <div class="bloc_container">
                            <div class="input_container">
                                <div class="input_lg">
                                    <label for="type">Type d'évènement</label>
                                    <div class="select2_sans_badge" id="type">
                                        <app-entity-select
                                                [control]="form.get('type')"
                                                [fetcher]="fetcher"
                                        ></app-entity-select>
                                    </div>
                                </div>
                                <div class="container_statut">
                                    <label>Etat</label>
                                    <div class="choix_statut">
                                        <input type="radio"
                                               name="statut"
                                               id="statut_a_faire"
                                               [value]=1
                                               formControlName="statut"
                                               (click)="onChangedStatut(EVENEMENT_GLOBALS.STATUT_A_FAIRE)"
                                               [ngClass]="{'background_alert_required': !form.controls.statut.value}"
                                        >
                                        <label for="statut_a_faire">A faire</label>
                                        <input type="radio"
                                               name="statut"
                                               id="statut_termine"
                                               [value]=2
                                               checked
                                               formControlName="statut"
                                               (click)="onChangedStatut(EVENEMENT_GLOBALS.STATUT_FAIT)"
                                               [ngClass]="{'background_alert_required': !form.controls.statut.value}"
                                        >
                                        <label for="statut_termine">Terminé</label>
                                        <input type="radio"
                                               name="statut"
                                               id="statut_annule"
                                               [value]=10
                                               checked
                                               formControlName="statut"
                                               (click)="openModal('annuleModalEvent')"
                                               [ngClass]="{'background_alert_required': !form.controls.statut.value}"
                                        >
                                        <label for="statut_termine">Annulé</label>
                                    </div>
                                </div>
                            </div>
                            <div class="input_container full_width">
                                <div class="input_lg">
                                    <label for="libelle">Titre</label>
                                    <input type="text"
                                           maxlength="45"
                                           formControlName="libelle"
                                           name="libelle"
                                           id="libelle"
                                           [ngClass]="{'background_alert_required': !form.controls.libelle.value}"
                                    >
                                </div>
                                <div class="alert" role="alert" *ngIf="form.controls.libelle.value.length === 45">
                                    Le nombre de caractères maximum est de 45
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="input_lg select2_sans_badge"
                                     *ngIf="event; else assignedToCreate">
                                    <label>Assigné à</label>
                                    <app-users-select2 [form]="form"
                                                       [id]="'assignedTo'"
                                                       [formCreation]="formCreation"
                                                       [multiple]="false"
                                                       (changed)="onChangedAssignedTo($event)"
                                                       [ngClass]="{
                                                           'background_alert_required': !form.controls.assignedTo.value
                                                       }"
                                    >
                                    </app-users-select2>
                                </div>
                                <ng-template #assignedToCreate>
                                    <div class="input_lg select2_sans_badge">
                                        <label>Assigné à</label>
                                        <app-users-select2 [form]="form"
                                                           [id]="'assignedTo'"
                                                           [formCreation]="formCreation"
                                                           [multiple]="false"
                                                           (changed)="onChangedAssignedTo($event)"
                                                           [ngClass]="{
                                                                'background_alert_required': !form.controls.assignedTo.value
                                                           }"
                                        >
                                        </app-users-select2>
                                    </div>
                                </ng-template>
                                <div class="input_lg">
                                    <label>Date / échéance</label>
                                    <div class="container_date_time">
                                        <input type="date"
                                               formControlName="dateEcheance"
                                               name="dateEcheance"
                                               id="dateEcheance"
                                               class="input_date"
                                               [ngClass]="{'background_alert_required': !form.controls.dateEcheance.value}"
                                        />
                                        <input type="time"
                                               formControlName="timeEcheance"
                                               name="timeEcheance"
                                               id="timeEcheance"
                                               class="input_time"
                                               [ngClass]="{'background_alert_required': !form.controls.timeEcheance.value}"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="text_area_container">
                                <label for="description">Description</label>
                                <textarea formControlName="description" id="description"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_form from_droit" *ngIf="dataPersonne">
                <div class="bloc_contact">
                    <div class="bandeau_form bandeau_personne_{{dataPersonne.type}} icon addresse_card">
                        <h2>Contact</h2>
                    </div>
                    <div class="container_contact">
                        <app-repertoire-carte [personne]="dataPersonne" [formatCarte]="true"></app-repertoire-carte>
                    </div>
                    <div class="container_contact">
                        <div class="data" *ngIf="dataPersonne.mails.length > 1">
                            <h3>E-mail<span class="span_info_secondaire" *ngIf="countMail > 1">s</span> secondaire<span
                                    class="span_info_secondaire" *ngIf="countMail > 1">s</span></h3>
                            <div class="content_secondaire">
                                <div [ngClass]="{'info_secondaire': countMail > 1}"
                                     *ngFor="let mails of dataPersonne.mails">
                                    <span *ngIf="mails.principal !== true">{{ mails.mail.valeur }}</span>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="dataPersonne.mails.length > 1">
                        <div class="data" *ngIf="dataPersonne.telephones.length > 1">
                            <h3>Tél<span class="span_info_secondaire"
                                         *ngIf="dataPersonne.telephones.length > 1">s</span>
                                secondaire<span class="span_info_secondaire" *ngIf="dataPersonne.telephones.length > 1">s</span>
                            </h3>
                            <div class="content_secondaire">
                                <div [ngClass]="{'info_secondaire': dataPersonne.telephones.length > 1}"
                                     *ngFor="let tels of dataPersonne.telephones">
                                    <span *ngIf="tels.principal !== true && tels.type === COORD_GLOBALS.COORD_TYPE_TEL.int">
                                        {{tels.telephone.text}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="dataPersonne.telephones.length > 1">
                        <div class="data_fax" *ngIf="dataPersonne.telephones[0]?.type === COORD_GLOBALS.COORD_TYPE_FAX">
                            <h3>Fax<span class="span_info_secondaire"
                                         *ngIf="dataPersonne.telephones.length > 1">s&nbsp;</span>
                                secondaire<span class="span_info_secondaire"
                                                *ngIf="dataPersonne.telephones.length > 1">s</span>
                            </h3>
                            <div>
                                <div *ngFor="let fax of dataPersonne.telephones; let i = index">
                                    <div class="content_secondaire"
                                         *ngIf="fax.type === COORD_GLOBALS.COORD_TYPE_FAX.int">
                                        <div [ngClass]="{'info_secondaire': fax.telephone.length > 1}">
                                            <span>{{fax.telephone.text}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="dataPersonne.fax">
                        <div class="data" *ngIf="dataPersonne.adresses.length > 0">
                            <h3>Adresse<span class="span_info_secondaire"
                                             *ngIf="dataPersonne.adresses.length > 1">s</span></h3>
                            <div class="content_secondaire">
                                <div [ngClass]="{'info_secondaire': dataPersonne.adresses.length > 1 }"
                                     *ngFor="let adresses of dataPersonne.adresses">
                                    <span>
                                        {{adresses.adresse.ligne1}}
                                        {{adresses.adresse.cp}}, {{adresses.adresse.ville |titlecase}}
                                        {{adresses.adresse.pays}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr *ngIf="dataPersonne.adresses.length > 0">
                        <div class="data" *ngIf="dataPersonne.sites.length > 0">
                            <h3>Site<span class="span_info_secondaire" *ngIf="dataPersonne.sites.length > 1">s</span>
                            </h3>
                            <div class="content_secondaire">
                                <div [ngClass]="{'info_secondaire': dataPersonne.sites.length > 1}"
                                     *ngFor="let sites of dataPersonne.sites">
                                    <span *ngIf="sites">
                                        {{sites.site.valeur}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="form_bas">
        <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
        <button type="button" class="btn btn-med btn-enregistrer"
                [ngClass]="{'btn-disabled': !form.valid}"
                *ngIf="!event || (!(event.idMeetlaw && event.idMeetlaw?.length > 0))" (click)="onSubmit()"><span>Enregistrer</span>
        </button>
        <button type="button" class="btn btn-med btn-enregistrer"
                [ngClass]="{'btn-disabled': !form.valid}"
                *ngIf="!event || (!(event.idMeetlaw && event.idMeetlaw?.length > 0))" (click)="onSubmitCancel()">
            <span>Enregistrer et fermer</span></button>
    </div>
</div>
<jw-modal id="annuleModalEvent">
    <div class="bloc_title_modal">Êtes-vous sûr de vouloir passer le status de l'évènement à
        <strong class="action_modal valider">annuler</strong>
    </div>
    <hr>
    <div class="bloc_body_modal">Si vous enregistrer le formulaire, vous ne pourrez plus annuler cette action par la
        suite
    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal('annuleModalEvent')">
                <span>Fermer</span>
            </button>
            <button type="submit" class="btn btn-med btn-enregistrer" (click)="setStatutEvent('10')">
                <span>Valider</span>
            </button>
        </div>
    </div>
</jw-modal>
