<app-fil-ariane></app-fil-ariane>
<div class="form_origine">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form" [formGroup]="form">
        <div class="bandeau_origine icon icon_origines-de-contact">
            <h1 *ngIf="origine; else elseTitle">{{origine.libelle}}</h1>
        </div>
        <ng-template #elseTitle>
            <h1>Nouvelle Origine de contact</h1>
        </ng-template>
        <div class="formulaire">
            <div class="container_form form_gauche">
                <div class="bloc_origine">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div class="input_container full_width">
                            <div class="input_lg">
                                <label for="libelle">Intitulé*</label>
                                <input type="text"
                                       id="libelle"
                                       name="libelle"
                                       formControlName="libelle"
                                       maxlength="45"
                                       [ngClass]="{'background_alert_required': !form.controls.libelle.value}"
                                />
                            </div>
                            <div class="alert" role="alert" *ngIf="form.controls.libelle.value?.length === 45">
                                Le nombre de caractères maximum est de 45
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_form form_droit" *ngIf="origine; else elseModifierUsed">
                <div class="bloc_origine">
                    <div class="bandeau_form">Utlisé dans ...</div>
                    <div class="bloc_container">
                        <div class="data_use">
                            <span>Personnes physiques</span>
                            <span *ngIf="nbUsePP; else elseBlock">{{ nbUsePP }}</span>
                            <ng-template #elseBlock>0</ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #elseModifierUsed></ng-template>
        </div>
    </form>
    <div class="form_bas">
        <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
        <button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmit()"><span>Enregistrer</span></button>
        <button type="button" *ngIf="uuidOrigine"
                class="btn btn-med btn-enregistrer"
                (click)="onSubmitCancel()"><span>Enregistrer et fermer</span></button>
    </div>
</div>
