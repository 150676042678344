<div id="menu">
    <div class="block_menu_gauche">
        <app-navigation-item [faIconName]=faHome [route]="'/home'" class="navigation_item"></app-navigation-item>
        <app-navigation-item [faIconName]=faAddressCard [route]="'/repertoire'"
                             class="navigation_item"></app-navigation-item>
        <app-navigation-item [faIconName]=faFilter [route]="'/segment'" class="navigation_item"></app-navigation-item>
        <app-navigation-item [faIconName]=faEnvelope [route]="'/campagne'"
                             class="navigation_item"></app-navigation-item>
            <app-navigation-item [faIconName]=faFileSignature [route]="'/offre'"  class="navigation_item">
            </app-navigation-item>


        <div class="navigation_item"
             matTooltip="Statistiques - {{MESSAGE_GLOBALS.MESSAGE_EN_COURS}}"
             matTooltipClass="tooltip-stats"
             aria-label="tooltip">
            <app-navigation-item [faIconName]=faChartLine [route]="'/statistique'"></app-navigation-item>
        </div>
    </div>
    <div id="block_menu_centre" class="block_menu_centre" #list>
        <div class="container_last_item">
            <app-entity-tab *ngFor="let item of this.entityTabs.entityTabs|async"
                            [type]="item.type"
                            [label]="item.label"
                            [active]="router.isActive(item.uri, false)"
                            (close)="removeItem(item.uuid)"
                            (click)="onClickUpsellLastEntities(item)"
            ></app-entity-tab>
        </div>
    </div>
    <div class="container_fleche">
        <div class="icon fleche fleche_gauche"
             [ngClass]="{'scrollable': scrollableLeft}"
             (click)="scrollLeft(list)"></div>
        <div class="icon fleche fleche_droite"
             [ngClass]="{'scrollable': scrollableRight}"
             (click)="scrollRight(list)"></div>
    </div>
    <div class="block_menu_droite">
        <div class="icon_menu">
            <div class="navigation_item icon i_alerte"
                 (click)="actionVolet('voletRappel')"
                 [matBadge]="nbEventEnRetard" matBadgePosition="below after" [matBadgeHidden]="nbEventEnRetard === 0" matBadgeColor="warn"
            >
            </div>

            <!--            <div class="badge_alert">-->
            <!--                <app-badge-notification></app-badge-notification>-->
            <!--            </div>-->
        </div>
        <app-navigation-item [faIconName]=faBell class="navigation_item"></app-navigation-item>
        <button type="button" class="btn btn-picto-lg bouton_volet"
                (click)="actionVolet('voletParametre')"
                *ngIf="personneConnecte"
        >
            {{personneConnecte.prenom.substring(0, 1) | uppercase}}{{personneConnecte.nom.substring(0, 1) | uppercase}}
        </button>
    </div>
</div>
