import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {PersonneLienService} from "../../_services/personne-lien.service";
import {map} from "rxjs/operators";
import {Fetcher} from "../entity-select/entity-select.component";

@Component({
  selector: 'app-select-pm',
  templateUrl: './select-pm.component.html',
  styleUrls: ['./select-pm.component.scss']
})
export class SelectPmComponent {

  @Input() control: FormControl|AbstractControl = new FormControl();
  @Input() notUuid: string
  @Input() set filiales(filiales: { uuid: string, libelle: string }[]) {
    this.#disabled = filiales.map(f => f.uuid)
  }
  #disabled: string[] = []
  fetcher: Fetcher<any>;

  constructor(private pls: PersonneLienService) {
    this.fetcher = (filter) => {
      return this.pls.list({...filter,
        'not:uuid[]': [this.notUuid],
        "type[]": ['morale'],
      })
          .pipe(map(items => {
            //@ts-ignore
            items["hydra:member"] = items['hydra:member'].map(member => {
              if (this.#disabled.includes(member.personneMorale.uuid)) {
                //@ts-ignore
                member.personneMorale.disabled = true;
                member.personneMorale.libelle = `${member.libelle} (est une filiale de la personne morale courante)`;
              }

              return member.personneMorale
            })

            return items;
          }))
    }
  }
}
