import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Select2OptionData} from 'ng-select2';
import {Options} from 'select2';
import {Router} from '@angular/router';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {MessageGlobals} from '../../../const-global/messages';
import {PersonneService} from '../../_services/personne.service';
import {PersonGlobals} from '../../../const-global/globals';

export class SearchFields {
    repertoire = '';
    segment = '';
    campagne = '';
    tag = '';
    origine = '';
    champsPerso = '';
    utilisateur = '';
    doublons = '';
    products = '';
}

export type Filter = {
    propsAffichage: string;
    visibilityValue: string[];
    typeValue: string[];
    statutValue: string[]
    search: string
}


@Component({
    selector: 'app-repertoire-outils',
    templateUrl: './outils.component.html',
    styleUrls: ['./outils.component.scss']
})
export class OutilsComponent implements OnInit, AfterViewInit {
    public typeData: Array<Select2OptionData>;
    public visibilityOptions: Options;
    public typeOptions: Options;
    public typeValue: string[];

    public statutData: Array<Select2OptionData>;
    public statutValue: string[];
    public statutOptions: Options;

    public visibilityValue: string[];
    public segmentValue: string[];

    public rechercheValue: string;
    public current;
    public propsAffichage = '';
    public routeUri: string;
    public ficheActive: boolean = true;
    public readonly MESSAGE_GLOBALS = MessageGlobals;
    model: Partial<Filter> = {};

    @Output() activeValue = new EventEmitter<boolean>();
    #active = true;
    get active() {return this.#active};
    set active(active) {
        this.#active = active;
        this.activeValue.emit(active);
    };

    @Input() type;
    @Output() search = new EventEmitter<string>();
    @Output() visibilityFiltreEvent = new EventEmitter<string[]>();
    @Output() typeFiltreEvent = new EventEmitter<string[]>();
    @Output() statutFiltreEvent = new EventEmitter<string[]>();
    @Output() segmentFiltreEvent = new EventEmitter<string>();
    @Output() modeAffichage = new EventEmitter<string>();
    @Output() valueChanges = new EventEmitter<Partial<Filter>>();

    @ViewChild('searchfield') searchfield: ElementRef;

    get searchValue(): string {
        const ls = localStorage.getItem('rechercheValue');
        if (ls) {
            const parsedLs = JSON.parse(ls);
            switch (this.router.routerState.snapshot.url.split('/').pop()) {
                case 'repertoire':
                    return parsedLs.repertoire;
                case 'segment':
                    return parsedLs.segment;
                case 'campagne':
                    return parsedLs.campagne;
                case 'tag':
                    return parsedLs.tag;
                case 'origine':
                    return parsedLs.origine;
                case 'champs-personnalise':
                    return parsedLs.champsPerso;
                case 'utilisateurs':
                    return parsedLs.utilisateur;
                case 'catalogue-produits':
                    return parsedLs.products;
                case 'doublons':
                case 'very_strong':
                case 'strong':
                case 'very_weak':
                case 'weak':
                    return parsedLs.doublons;
            }
        }
        console.error('check LS : looking for new URI LS entry ?');
        return '';
    }

    constructor(
        public router: Router,
        public personneService: PersonneService
    ) {
    }

    ngOnInit(): void {
        this.personneService.isEnabled.subscribe(isEnabled => {
            this.ficheActive = !!isEnabled;
        });
        this.routeUri = this.router.url.split('/')[1];
        this.model.propsAffichage = 'carte';
        this.typeData = [
            {
                id: PersonGlobals.PERSON_LIEN_PHYSIQUE,
                text: 'Personne physiques'
            },
            {
                id: PersonGlobals.PERSON_LIEN_MORALE,
                text: 'Personne morales'
            },
            {
                id: PersonGlobals.PERSON_LIEN,
                text: 'Fonction'
            },
        ];

        this.statutData = [
            {
                id: 'prospect',
                text: 'Prospect'
            },
            {
                id: 'client',
                text: 'Client'
            },
            {
                id: 'adverse',
                text: 'Partie Advserse'
            },
            {
                id: 'membre',
                text: 'Membre'
            },
            {
                id: 'autre',
                text: 'Autre'
            },
        ];

        this.typeOptions = {
            multiple: true
        };
        this.statutOptions = {
            multiple: true
        };

        this.setFilterInLocalStorage();
    }

    ngAfterViewInit(): void {
        fromEvent(this.searchfield.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(500),
                distinctUntilChanged(),
                tap(() => {
                    this.setRechercheValue(this.searchfield.nativeElement.value);
                })
            )
            .subscribe();
    }

    public prevent(event): void {
        event.preventDefault();
    }

    public valueChangedStatut(event: string[]): void {
        this.setStatutValue(event);
        this.statutFiltreEvent.emit(event);
    }

    public valueChangedType(event: string[]): void {
        this.setTypeValue(event);
        this.typeFiltreEvent.emit(event);
    }

    // filtre visibilitées select 2
    public valueChangedVisibilitySelect2(visibility): void {
        this.setVisibilityValue(visibility);
        this.visibilityFiltreEvent.emit(visibility);
    }

    // filtre segment select 2 single
    public valueChangedSegmentSelect2(segment): void {
        this.setSegmentValue(segment);
        this.segmentFiltreEvent.emit(segment);
    }

    // switch visibilitées
    public valueChangedVisibility(event: string[]): void {
        this.setVisibilityValue(event)
        this.visibilityFiltreEvent.emit(event);
    }

    public valueChangedIsEnabled($event): void {
        this.ficheActive = !!$event.target.checked;
        this.personneService.isEnabled.next(this.ficheActive);
    }

    public setFilterInLocalStorage(): void {
        this.model.visibilityValue = JSON.parse(localStorage.getItem('visibilityValue'));
        let lsStatutValue = localStorage.getItem('statutValue');
        let lsTypeValue = localStorage.getItem('typeValue');
        let lsVisibilityValue = localStorage.getItem('visibilityValue');
        let lsSegmentValue = localStorage.getItem('segmentValue');

        lsStatutValue = (lsStatutValue !== null) ? lsStatutValue : '["prospect","client"]';
        this.model.statutValue = JSON.parse(lsStatutValue);
        localStorage.setItem('statutValue', lsStatutValue);

        lsTypeValue = (lsTypeValue !== null) ? lsTypeValue : '[]';
        this.model.typeValue = JSON.parse(lsTypeValue);
        localStorage.setItem('typeValue', lsTypeValue);

        lsVisibilityValue = (lsVisibilityValue !== null) ? lsVisibilityValue : '[]';
        this.visibilityValue = JSON.parse(lsVisibilityValue);
        localStorage.setItem('visibilityValue', lsVisibilityValue);

        lsSegmentValue = (lsSegmentValue !== null) ? lsSegmentValue : '[]';
        this.segmentValue = JSON.parse(lsSegmentValue);
        localStorage.setItem('segmentValue', lsSegmentValue);

        const lsSearchValue = localStorage.getItem('rechercheValue') || JSON.stringify(new SearchFields());
        localStorage.setItem('rechercheValue', lsSearchValue);
        this.model.search = this.searchValue
        this.onInput()
    }

    public creerPersonne(): void {
        this.router.navigate(['repertoire/creer']);

    }

    public creerSegment(): void {
        this.router.navigate(['segment/nouveau/modifier']);
    }

    public creerTag(): void {
        this.router.navigate(['tag/creer']);
    }

    public creerChamp(): void {
        this.router.navigate(['champs-personnalise/creer']);
    }

    public creerOrigine(): void {
        this.router.navigate(['origine/creer']);
    }

    public creerUser(): void {
        this.router.navigate(['utilisateurs/creer']);
    }

    createProduct() {
        this.router.navigate(['/parametres/catalogue-produits/ajouter']);
    }

    onInput() {
        this.valueChanges.emit(this.model)
    }

    public changeAffichage(): void {
        if (this.model.propsAffichage === 'carte') {
            this.modeAffichage.emit('liste');
            this.model.propsAffichage = 'liste';
        } else {
            this.modeAffichage.emit('carte');
            this.model.propsAffichage = 'carte';
        }
        this.onInput()
    }

    public setRechercheValue(value): void {
        const ls = localStorage.getItem('rechercheValue');
        let lsSearchValue: SearchFields;
        lsSearchValue = JSON.parse(ls);
        switch (this.router.routerState.snapshot.url.split('/').pop()) {
            case 'repertoire':
                lsSearchValue.repertoire = value;
                break;
            case 'segment':
                lsSearchValue.segment = value;
                break;
            case 'campagne':
                lsSearchValue.campagne = value;
                break;
            case 'tag':
                lsSearchValue.tag = value;
                break;
            case 'origine':
                lsSearchValue.origine = value;
                break;
            case 'champs-personnalise':
                lsSearchValue.champsPerso = value;
                break;
            case 'utilisateurs':
                lsSearchValue.utilisateur = value;
                break;
            case 'catalogue-produits':
                lsSearchValue.products = value;
                break;
            case 'doublons':
            case 'very_strong':
            case 'strong':
            case 'very_weak':
            case 'weak':
                lsSearchValue.doublons = value;
                break;
        }
        localStorage.setItem('rechercheValue', JSON.stringify(lsSearchValue));
        if (this.model.search !== value) {
            this.model.search = value
            this.search.emit(value);
            this.onInput()
        }
    }

    public setVisibilityValue(value): void {
        this.model.visibilityValue = value;
        localStorage.setItem('visibilityValue', JSON.stringify(value));
        this.visibilityFiltreEvent.emit(value);
        this.onInput()
    }

    public setTypeValue(value): void {
        this.model.typeValue = value;
        localStorage.setItem('typeValue', JSON.stringify(value));
        this.onInput()
    }

    public setStatutValue(value): void {
        this.model.statutValue = value;
        localStorage.setItem('statutValue', JSON.stringify(value));
        this.onInput()
    }

    public setSegmentValue(value): void {
        this.segmentValue = value;
        localStorage.setItem('segmentValue', JSON.stringify(value));
    }
}
