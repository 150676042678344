import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonGlobals, VueGlobals} from "../../../const-global/globals";

@Component({
  selector: 'app-entity-tab',
  templateUrl: './entity-tab.component.html',
  styleUrls: ['./entity-tab.component.scss']
})
export class EntityTabComponent implements OnInit {

  PersonGlobals = PersonGlobals
  VueGlobals = VueGlobals
  isActive = false
  @Input() set active(active: boolean) {
    this.isActive = active;
    if (this.isActive) {
      this.scrollIntoView()
    }
  }
  @Input() type;
  @Input() label;

  @Output() close = new EventEmitter<MouseEvent>();

  constructor(private element: ElementRef) { }

  private scrollIntoView()
  {
    setTimeout(() => {
      this.element.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }, 500)
  }

  ngOnInit(): void {
    if (this.isActive) {
      this.scrollIntoView();
    }
  }
}
