<app-fil-ariane></app-fil-ariane>
<div class="form_parametre_generale">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm"
          [formGroup]="form"
          novalidate
    >
        <div class="bandeau_parametre icon edition">
            <h1>Configuration générale du cabinet</h1>
        </div>
        <div class="formulaire_full">
            <div class="container_form">
                <div class="bloc_date_start">
                    <div class="bandeau_form">Date d'exercice</div>
                    <div class="bloc_container">
                        <div class="w-half">
                            <div class="input_lg">
                                <label for="date_start">Date de début d'exercice</label>
                                <input type="text"
                                       id="date_start"
                                       placeholder="JJ/MM"
                                       [maxLength]="5"
                                       formControlName="accountingExerciseDate"
                                       (input)="onInputAccountingExerciseDate($event)"
                                       (focusout)="focusOutAccountingExerciseDate($event)"
                                />
                                <i class="fa-solid fa-calendar-days suffix"></i>
                                <p *ngIf="form.get('accountingExerciseDate').errors?.help" class="mat-error">
                                    {{ form.get('accountingExerciseDate').errors.help }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_form">
                <div class="bloc_offre_et_devis">
                    <div class="bandeau_form">Offres et devis</div>
                    <div class="bloc_container grid gap-3 text-label">
                        <div class="w-full mb-4">
                            <span>Ces informations seront utilisées par défaut sur toutes les offres du cabinet.</span>
                        </div>
                        <div class="container_bloc_offre_et_devis w-full grid grid-cols-2 mb-6">
                            <div class="flex flex-col gap-2 content_logo">
                                <h2 class="text-semi-bold text-semi-bold">Logo du cabinet</h2>
                                <span>Formats autorisés : JPEG, PNG | Poids maximum : 700Ko</span>
                                <app-file-field
                                        [error]="fileErrors.logo  || this.form.get('devisSetting.logo')?.errors?.error"
                                        [accept]="['image/png', 'image/jpeg']"
                                        [uploadedFile]="organization.devisSetting?.logo"
                                        (fileChange)="handleFile('logo', $event, [fileSizeValidator(0.7)])">
                                </app-file-field>
                            </div>
                            <div class="flex flex-col gap-2 content_conditions">
                                <h2 class="text-semi-bold">Conditions générales d'intervention</h2>
                                <span>Formats autorisés : DOC, DOCX, PDF | Poids maximum : 1Mo</span>
                                <app-file-field
                                        [error]="fileErrors.cgi || this.form.get('devisSetting.cgi')?.errors?.error"
                                        [accept]="[
                                            'application/msword',
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                            'application/vnd.oasis.opendocument.spreadsheet',
                                            'application/vnd.oasis.opendocument.text',
                                            '.odt',
                                            'application/pdf',
                                        ]"
                                        [uploadedFile]="organization.devisSetting?.cgi"
                                        (fileChange)="handleFile('cgi', $event, [fileSizeValidator(1)])">
                                </app-file-field>
                            </div>
                        </div>
                        <div class="w-full text-black text-semi-bold" [formGroup]="devisControls">
                            <h3 class="mb-2">Pied de page du devis</h3>
                            <ckeditor [config]="config" [editor]="Editor" formControlName="footer"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <mat-progress-bar
            *ngIf="querying"
            class="progress-bar"
            [mode]="progressMode"
            [value]="uploadProgress"
    ></mat-progress-bar>
    <div class="form_bas">
        <div class="form_bas">
            <button type="button" class="btn btn-med btn-fermer" [routerLink]="backUrl"><span>Annuler</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    [ngClass]="{'btn-disabled': form.invalid || querying || (!form.dirty && !hasFile)}"
                    (click)="onSubmit()"><span>Enregistrer</span>
            </button>
        </div>
    </div>
</div>
