import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ErrorsGlobals} from '../../const-global/errors';

@Injectable({
    providedIn: 'root'
})
export class ExceptionService {
    private readonly notifier: NotifierService;

    constructor(notifier: NotifierService) {
        this.notifier = notifier;
    }


    statutErreur(err: any, notify: boolean = false): string {
        let errorMsg: string;
        if (err.status === 0) {
            errorMsg = ErrorsGlobals.TIMEOUT;
        }
        // if (err.status === 401) {
        //     errorMsg = ErrorsGlobals.AUTH_EXPIRED;
        // }
        if (err.status === 400) {
            if (err.error?.error){
                errorMsg = err.error?.error;
            }else{
                errorMsg = ErrorsGlobals.INVALID_REQUEST;
            }
        }
        if (err.status === 403) {
            errorMsg = ErrorsGlobals.ACCESS_DENIED;
        }
        if (err.status === 404) {
            errorMsg = ErrorsGlobals.NOT_FOUND;
        }
        if (err.status === 409) {
            errorMsg = ErrorsGlobals.ALREADY_EXISTS;
        }
        if (err.status >= 500) {
            errorMsg = ErrorsGlobals.FATAL;
        }

        if (notify && errorMsg) {
            this.notifier.notify('error', errorMsg);
        }

        return errorMsg;
    }
}

