<div class="form_personne form_ajouter" *ngIf="form">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <form
            name="form"
            (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm"
            [formGroup]="form"
            novalidate
    >
        <div class="icon user bandeau_personne_formulaire bandeau_personne_{{typePersonne}}"
             [ngClass]="{'bandeau_user': newUser}">
            <h1 *ngIf="newUser; then createUser else createPerson"></h1>
            <ng-template #createUser>Nouvel Utilisateur</ng-template>
            <ng-template #createPerson>Nouvelle personne {{typePersonne}}</ng-template>
        </div>
        <div class="formulaire">
            <div class="container_form form_gauche">
                <div class="bloc_information_generale" [hidden]="newUser">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div class="input_container">
                            <div class="input_lg">
                                <label for="statut">Statut</label>
                                <app-statut-select2
                                        id="statut"
                                        [control]="form.get('statut')"
s                                       [ngClass]="{'background_alert_required': !form.controls.statut.value}"
                                ></app-statut-select2>
                            </div>
                            <div class="container_civilite" *ngIf="typePersonne === 'physique'"
                                 [ngClass]="{'background_alert_required': !form.controls.civilite.value}">
                                <label>Civilité</label>
                                <div>
                                    <input type="radio"
                                           id="civilite_mr"
                                           name="civilite"
                                           value="M"
                                           formControlName="civilite"
                                           [ngClass]="{'background_alert_required': !form.controls.civilite.value}"
                                    >
                                    <label for="civilite_mr">M.</label>
                                    <input type="radio"
                                           id="civilite_mme"
                                           name="civilite"
                                           value="Mme"
                                           formControlName="civilite"
                                           [ngClass]="{'background_alert_required': !form.controls.civilite.value}"
                                    >
                                    <label for="civilite_autre">Mme.</label>
                                    <input type="radio"
                                           id="civilite_autre"
                                           name="civilite"
                                           value="Non-précisé"
                                           formControlName="civilite"
                                           [ngClass]="{'background_alert_required': !form.controls.civilite.value}"
                                    >
                                    <label for="civilite_autre">Non-précisé</label>
                                </div>
                            </div>
                        </div>
                        <div class="input_container" *ngIf="typePersonne === 'morale'">
                            <div class="input_lg rs_container">
                                <label for="raisonSociale">Raison sociale</label>
                                <input type="text"
                                       id="raisonSociale"
                                       name="raisonSociale"
                                       formControlName="raisonSociale"
                                       autocomplete="off"
                                       [ngClass]="{'background_alert_required': !form.controls.raisonSociale.value}"
                                       (input)="onSearchInputRaisonSociale($event)"
                                       (focusout)="this.resetFilter()"
                                />
                                <div class="suggestion_raison_sociale container" [hidden]="isLoading"
                                     *ngIf="(this.searchSubject.getValue() !== '' && this.personnesMorales.length > 0) || this.idMessage === PMLibre">
                                    <div class="message_info" *ngIf="this.idMessage === PMLibre">
                                        <span class="icon envoye dispo">
                                            Ce nom est disponible.
                                            Aucune fiche correspondant à ce nom n’a été trouvée dans votre répertoire.
                                        </span>
                                    </div>
                                    <div class="message_info"
                                         *ngIf="this.idMessage === PMSimilaire">
                                        <span class="icon exclamation info">
                                            Ce nom est disponible mais il existe des noms similaires !
                                        </span>
                                        <span class="icon exclamation info">
                                            Veuillez vous assurez que cette personne n'existe pas déjà :
                                        </span>
                                    </div>
                                    <div class="message_info"
                                         *ngIf="this.idMessage === PMIdentique">
                                        <span class="icon envoye non_dispo">Ce nom existe déjà.
                                            Veuillez vous assurer que vous n’allez pas créer un doublon.
                                        </span>
                                    </div>
                                    <div class="suggestion"
                                         *ngIf="this.idMessage !== PMLibre">
                                        <ul *ngFor="let personne of this.personnesMorales">
                                            <li class="personne_morale_cliquable icon chevron_right"
                                                (click)="redirectToPM(personne.uuid)">
                                                {{ personne.personneMorale.libelle}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="suggestion_raison_sociale container" [hidden]="!isLoading">
                                    <div class="inline_loader">
                                        <ngx-ui-loader [loaderId]="'search'" fgsSize="20"></ngx-ui-loader>
                                    </div>
                                </div>
                            </div>
                            <div class="input_lg">
                                <label for="formeJuridique">Forme juridique</label>
                                <div class="select2_sans_badge">
                                    <app-form-juridique-select2
                                            id="formeJuridique"
                                            [control]="form.get('formeJuridique')"
                                            [ngClass]="{'background_alert_required': !form.controls.formeJuridique.value}"
                                    ></app-form-juridique-select2>
                                </div>
                            </div>
                        </div>
                        <div class="input_container" *ngIf="typePersonne === 'physique'">
                            <div class="input_lg">
                                <label for="prenom">Prénom</label>
                                <input
                                        type="text"
                                        id="prenom"
                                        name="prenom"
                                        formControlName="prenom"
                                        [ngClass]="{'background_alert_required': !form.controls.prenom.value}"
                                />
                            </div>
                            <div class="input_lg">
                                <label for="nom">Nom</label>
                                <input
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        formControlName="nom"
                                        [ngClass]="{'background_alert_required': !form.controls.nom.value}"
                                />
                            </div>
                        </div>
                        <div class="input_container input_container_coordonnee">
                            <div class="input_lg">
                                <label for="email">Email</label>
                                <input type="text"
                                       id="email"
                                       name="email"
                                       formControlName="email"
                                       [ngClass]="{'background_alert_recommended': !form.controls.email.value}"
                                />
                            </div>
                            <div class="input_container_coordonnee">
                                <div>
                                    <label for="telephone">Indicatif</label>
                                    <div class="container_indicatif select2_sans_badge input_lg" *ngIf="indicatifData">
                                        <ng-select class="azko"
                                                   bindLabel="text"
                                                   [items]="indicatifData | async"
                                                    formControlName="indicatifTel"
                                                    [ngClass]="{'background_alert_recommended': !form.controls.indicatifTel.value &&  !indicatifDefaut}"
                                        ></ng-select>
                                    </div>
                                </div>
                                <div>
                                    <label for="telephone">N° de téléphone</label>
                                    <input type="text"
                                           name="telephone"
                                           id="telephone"
                                           formControlName="telephone"
                                           [ngClass]="{'background_alert_recommended': !form.controls.telephone.value}"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="text_area_container">
                            <label for="memo">Mémo</label>
                            <textarea id="memo" name="memo" rows="4" cols="50" formControlName="memo"></textarea>
                        </div>
                    </div>
                </div>
                <div class="bloc_information_generale" [hidden]="!newUser">
                    profil user
                </div>
            </div>
            <div class="container_form form_droit">
                <div class="bloc_visibilite" [hidden]="newUser">
                    <app-bloc-visibilite [form]="form" [type]="'personne'" [creation]="true"></app-bloc-visibilite>
                </div>
                <div class="bloc_visibilite" [hidden]="!newUser">
                    <!--    profil user    -->
                    <div>profil user</div>
                </div>
            </div>
        </div>
    </form>
    <div class="form_bas">
        <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Annuler</span></button>
        <button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmit()"><span>Enregistrer</span></button>
    </div>
</div>
