import {Component, ElementRef, ViewChild} from '@angular/core';
import {AuthService} from '../_services/auth.service';
import {TokenStorageService} from '../_services/token-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../_services/exception.service';
import {NotifierService} from 'angular-notifier';
import {PersonneService} from "../_services/personne.service";

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
    form: any = {
        login: null,
        password: null,
    };
    formLost: any = {
        login: ''
    };
    errorMessage = '';
    isLoggedIn = false;
    isLoginFailed = false;
    lostPassword = false;
    @ViewChild('emailLostPassword') emailLostPassword: ElementRef;
    @ViewChild('passwordForm') passwordForm: ElementRef;

    constructor(
        private authService: AuthService,
        private tokenStorage: TokenStorageService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private notifier: NotifierService,
        private route: ActivatedRoute,
        private ps: PersonneService,

    ) {}

    onSubmit(): void {
        const {login, password} = this.form;
        this.authService.login(login, password).subscribe(
            data => {
                this.ps.getCurrentUser().subscribe(() =>{ //Create user if not exist (error if multiple call)
                    this.isLoginFailed = false;
                    this.router.navigate([this.route.snapshot.queryParams['redirect'] || '']);
                })
            },
            err => {
                this.errorMessage = err.error.message;
                this.isLoginFailed = true;
            }
        );
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }

    public showHideInputMail(): void {
        this.emailLostPassword.nativeElement.value = '';
        this.passwordForm.nativeElement.value = '';
        if (this.lostPassword === false) {
            document.getElementById('connexion_form').classList.add('d-none');
            document.getElementById('lost_mot_de_passe').classList.remove('d-none');
            this.lostPassword = true;
        } else {
            document.getElementById('connexion_form').classList.remove('d-none');
            document.getElementById('lost_mot_de_passe').classList.add('d-none');
            this.lostPassword = false;
        }
    }

    public sendMotDePasse(): void {
        const {login} = this.formLost;
        if (this.formLost.login !== '') {
            this.authService.sendMotDePasse(login).subscribe(
                () => {
                    this.showNotification('success',
                        'Le mot de passe provisoire à bien été envoyé. Merci de vérifier votre boite mail.');
                    this.showHideInputMail();
                    this.isLoginFailed = false;
                },
                err => {
                    // TODO : traiter le message de retour
                    this.showNotification('error', 'Une erreur est survenue');
                    this.errorMessage = err.error.message;
                    this.isLoginFailed = true;
                }
            );
        } else {
            this.isLoginFailed = true;
        }
    }
}
