import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IOrigine} from '../../../../interfaces/origine';
import {ActivatedRoute, Router} from '@angular/router';
import {OrigineService} from '../../../_services/origine.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NotifierService} from 'angular-notifier';
import {ExceptionService} from '../../../_services/exception.service';
import {MessageGlobals} from "../../../../const-global/messages";

@Component({
    selector: 'app-form-origine',
    templateUrl: './form-origine.component.html',
    styleUrls: ['./form-origine.component.scss']
})
export class FormOrigineComponent implements OnInit {
    public form: FormGroup;
    public origine: IOrigine;
    public uuidOrigine = this.route.snapshot.params.id;
    public nbUsePP: number;
    public nbUseOffer: number;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private origineService: OrigineService,
        private ngxService: NgxUiLoaderService,
        public notifier: NotifierService,
        private exceptionService: ExceptionService
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            libelle: [this.origine, Validators.required]
        }, {});

        if (this.uuidOrigine) {
            this.origineService.getOrigine(this.uuidOrigine).subscribe((origine: IOrigine) => {
                this.origineService.origineSubject.next(origine);
            });

            this.origineService.origineSubject.subscribe(
                (origine: IOrigine) => {
                    this.origine = origine;
                    this.form.get('libelle').setValue(origine?.libelle);
                },
                err => {
                    this.showNotification('error', this.exceptionService.statutErreur(err));
                }
            );

            this.origineService.countOrigineUsage(this.uuidOrigine).subscribe(
                data => {
                    if (data[0] !== undefined) {
                        this.nbUsePP = data[0].nbUsage;
                    }
                }
            );
        }
    }

    private showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    public onSubmit(): void {
        this.ngxService.start();
        const formData: IOrigine = {
            libelle: this.form.controls.libelle.value,
        };
        if (this.form.valid) {
            if (!this.uuidOrigine) {
                this.origineService.creerOrigine(formData).subscribe(
                    data => {
                        this.ngxService.stop();
                        this.showNotification('success', 'L\'origine de contact ' +
                            this.form.controls.libelle.value + ' vient d\'être crée');
                        setTimeout(() => {
                            this.router.navigate(['/origine/modifier/' + data.content.uuid]);
                        }, 1000);
                    },
                    err => {
                        this.ngxService.stop();
                        this.showNotification('error', this.exceptionService.statutErreur(err));
                    }
                );
            }
            else {
                this.origineService.updateOrigine(this.uuidOrigine, formData).subscribe(
                    () => {
                        this.origineService.getOrigine(this.uuidOrigine).subscribe((origine: IOrigine) => {
                            this.origineService.origineSubject.next(origine);
                        });

                        this.ngxService.stop();
                        this.showNotification('success', 'L\'origine de contact ' + this.form.controls.libelle.value + ' vient d\'être mis à jour');
                    },
                    err => {
                        this.ngxService.stop();
                        this.showNotification('error', this.exceptionService.statutErreur(err));
                    }
                );
            }
            this.ngxService.stop();
        } else {
            this.ngxService.stop();
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
    }

    public onCancel(): void {
        this.router.navigate(['origine/']);
    }

    public onSubmitCancel(): void {
        this.onSubmit();
        setTimeout(() => {
            if (this.form.valid === true) {
                this.router.navigate(['/origine']);
            }
        }, 1000);
    }

}
