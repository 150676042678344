import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractClient} from './abstract-client';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from "rxjs";
import {LastEntity} from "../../models/lastentity";

const API_URL = environment.apiURL;

@Injectable({
    providedIn: 'root'
})
export class EntityTabsService extends AbstractClient<LastEntity> {
    entityTabs: BehaviorSubject<LastEntity[]>;

    constructor(http: HttpClient) {
        super(http, API_URL + 'entity_tabs');
        this.list({}).subscribe(entities => this.entityTabs = new BehaviorSubject<LastEntity[]>(entities["hydra:member"]));
    }

    upsert(newEntity: LastEntity, uuid?: string) {

        if (!this.entityTabs) {
            return ;
        }
        const urlUuid = uuid || newEntity.uuid;
        const currentTab = this.entityTabs.getValue().find(entityTab => urlUuid === entityTab.uuid);
        if (undefined !== currentTab) {

            if (0 === Object.keys(newEntity).filter(key => currentTab[key] !== newEntity[key]).length) {
                // nothing to update
                return;
            }

            Object.assign(currentTab, newEntity);
        } else {
            this.entityTabs.next(this.entityTabs.getValue().concat(newEntity).slice(-10));
        }

        super.update(urlUuid, newEntity).subscribe();
    }

    remove(uuid: string) {
        this.entityTabs.next(this.entityTabs.getValue().filter(item => item.uuid !== uuid));

        return super.delete(uuid).subscribe();
    }
}
