<div class="container">
    <section class="title" style="background-color: white">
        <ngx-ui-loader loaderId="{{loaderId}}"></ngx-ui-loader>
        <ng-container *ngIf="personneLiens.length">
            <h3 class="head">Traitement des doublons ({{min(itemsPerPage, hydra['hydra:totalItems']) + 1 | number}}/{{hydra['hydra:totalItems'] + 1 | number}})</h3>
            <div *ngIf="all[0]" class="full_view">
                <div class="jumbotron" *ngIf="isPreview && hasContactToBeMerged()">
                    <p class="danger"><span class="icon exclamation"></span>ATTENTION : Vous allez fusionner plusieurs
                        fiches en une seule.&nbsp; Les données que vous aurez choisi de ne pas conserver &nbsp;<strong>seront
                            perdues définitivement.</strong>.</p>
                    <p class="primary"><span class="icon idea"></span>Vérifier les données que vous souhaitez conserver
                        dans la fiche finale avant d'appliquer votre action de fusion.</p>
                </div>
                <div class=" block row bb" *ngIf="isPreview">
                    <div class=" sticked">Récaputilatif et validation :</div>
                    <div
                            *ngFor="let personneLien of all;let index = index;"
                            [ngClass]="{
                                'notice': true,
                                'bg-success': 0 === index || mergeOptions[index] % 2 !== 0,
                                'bg-warning': mergeOptions[index] % 2 === 0
                            }"
                    >
                        <div *ngIf="0 === index">
                            <div class="icon exclamation"></div>
                            <div>
                                <p>Valeurs qui seront conservées</p>
                            </div>
                        </div>

                        <ng-container *ngIf="0 < index" [ngSwitch]="mergeOptions[index]">
                            <div *ngSwitchCase="0">
                                <div class="icon exclamation"></div>
                                <div>
                                    <p>Données qui ne seront pas conservées</p>
                                </div>
                            </div>
                            <div *ngSwitchCase="1">
                                <div class="icon exclamation"></div>
                                <div>
                                    <p>Valeurs qui seront conservées</p>
                                </div>
                            </div>
                            <div *ngSwitchCase="2">
                                <div class="icon exclamation"></div>
                                <div>
                                    <p>Données conservées dans la fiche inactive</p>
                                </div>
                            </div>
                            <div *ngSwitchCase="3">
                                <div class="icon exclamation"></div>
                                <div>
                                    <p>Valeurs qui seront conservées</p>
                                </div>
                            </div>
                            <div *ngSwitchDefault>
                                <div class="icon exclamation"></div>
                                <div>
                                    <p>Valeurs qui seront conservées</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <header class="block row bb">
                    <div class="flex_end sticked">
                    </div>
                    <div *ngFor="let personneLien of all; let index = index">
                        <div class="name" [class.danger]="isPreview && mergeOptions[index] % 2 === 0">
                            <ng-container *ngIf="personneLien?.personnePhysique">
                                <p>
                                    <span class="icon user success"></span>
                                    {{ 'Non-renseigné' !== personneLien.personnePhysique.civilite ? personneLien.personnePhysique.civilite + '.' : ''}}
                                    {{personneLien.personnePhysique.prenom}} {{personneLien.personnePhysique.nom}}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="personneLien?.personneMorale">
                                <p>
                                    <span class="icon user city"></span>
                                    {{personneLien.personneMorale.libelle}}
                                </p>
                            </ng-container>
                            <div [routerLink]="['/repertoire', personneLien.uuid]" class="icon up-right"></div>
                        </div>

                        <div class="fade info space-between">
                            <div>

                                <p>Créée le {{personneLien.createdAt|date}}</p>
                                <p>
                                    Par {{personneLien?.userCreation?.personnePhysique?.prenom}} {{personneLien?.userCreation?.personnePhysique?.nom}}
                                </p>
                            </div>
                            <p>
                                <span [class]="'icon lock' + (personneLien.visibilite < 3 ? '-open' : '')"></span>
                                {{personneLien.visibilite|visibility}}
                            </p>
                        </div>

                        <div *ngIf="!isPreview">
                            <button type="button" *ngIf="index !== 0 && this.isUser " (click)="setFichePrincipale(personneLien)"
                                     class="btn btn-sm"
                                    [disabled]="this.isUser"
                                    matTooltipClass="tooltip-default"
                                    [matTooltip]="'Cette fiche personne ne peut pas être considérée comme fiche principale car elle est doublon d\'une fiche utilisateur'"
                            >
                                Fiche principale
                            </button>

                            <button type="button" *ngIf="index !== 0 && !this.isUser " (click)="setFichePrincipale(personneLien)"
                                     class="btn btn-sm"
                                    [disabled]="this.isUser">
                                Fiche principale
                            </button>
                            <span class="btn btn-sm fade" *ngIf="index === 0">Fiche principale</span>
                        </div>

                    </div>
                </header>
                <div class="bb" *ngIf="!isPreview">
                    <div class="row">
                        <div class="section-title sticked">
                            Action :
                        </div>
                        <div *ngFor="let personneLien of all; let index = index;">

                            <ol *ngIf="0 !== index">
                                <li class="radio" *ngFor="let option of availableMergeOptions;let i = index"
                                    [matTooltip]="
                                     'Désactiver' === option && personneLien.personnePhysique?.isUser ?
                                     'Cette fiche est une fiche utilisateur et ne peut pas être désactivée.':
                                     null"
                                    matTooltipClass="tooltip-isUser"
                                >
                                    <input [value]="i" type="radio" id="{{i}}-{{personneLien.uuid}}"
                                           name="{{personneLien.uuid}}"
                                           [(ngModel)]="mergeOptions[index]"
                                           [disabled] = "'Désactiver' === option && personneLien?.personnePhysique?.isUser"
                                           (change)="changeMergeOptions(option, personneLien?.personnePhysique?.isUser)"
                                    >
                                    <label
                                            [ngClass]="{'grey': 'Désactiver' ===  option && personneLien.personnePhysique?.isUser}"
                                            for="{{i}}-{{personneLien.uuid}}">{{option}}</label>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="bb">
                    <div class="row">
                        <div class="section-title sticked">
                            Champs simples :
                        </div>
                        <ng-container *ngIf="isPreview">
                            <div *ngFor="let personneLien of all"></div>
                        </ng-container>
                        <ng-container *ngIf="!isPreview">
                            <div
                                    *ngFor="let personneLien of all;let index = index;"
                                    [ngClass]="{'notice': true, 'bg-success': 0 === index, 'bg-warning': 3 >= mergeOptions[index]}"
                            >
                                <div *ngIf="0 === index">
                                    <div class="icon exclamation"></div>
                                    <div>
                                        <p>Cette fiche sera conservée.</p>
                                        <p>Sélectionnez les valeurs à conserver :</p>
                                    </div>
                                </div>

                                <ng-container *ngIf="0 < index" [ngSwitch]="mergeOptions[index]">
                                    <div *ngSwitchCase="0">
                                        <div class="icon exclamation"></div>
                                        <div>
                                            <p>Cette fiche sera fusionnée.</p>
                                            <p>Sélectionnez les valeurs à conserver :</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="1">
                                        <div class="icon exclamation"></div>
                                        <div>
                                            <p>Cette fiche sera conservée telle quelle et n'apparaitra plus dans vos
                                                doublons</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="2">
                                        <div class="icon exclamation"></div>
                                        <div>
                                            <p>Cette fiche sera désactivée</p>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="3">
                                        <div class="icon exclamation"></div>
                                        <div>
                                            <p>Cette fiche ne serait pas traitée et restera considérée comme un
                                                doublon</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="row" *ngFor="let field of simpleFields">
                        <div class="sticked text-right">
                            {{field.label}}
                        </div>
                        <div *ngFor="let item of field.items; let i = index">

                            <div class="radio" [class.disabled]="field.disabled">
                                <input
                                        *ngIf="!isPreview && (0 === i || 0 === mergeOptions[i])"
                                        type="radio"
                                        id="{{item.id}}"
                                        (click)="simpleFieldClick($event, item, field)"
                                        [checked]="simpleFieldChecked(item, field)"
                                        [disabled]="field.disabled"
                                >
                                <label
                                        [classList]="[(!isPreview && simpleFieldChecked(item, field)) || (isPreview && mergeOptions[i] % 2 !== 0) ? 'success':'danger']"
                                        for="{{item.id}}">
                                    <ng-container [ngSwitch]="field.path">
                                        <app-note *ngSwitchCase="'qualite'"
                                                  [ngModel]="item.value"
                                                  disabled
                                        ></app-note>
                                        <span *ngSwitchCase="'visibilite'">{{item.value|visibility}}</span>
                                        <span *ngSwitchCase="'personnePhysique.infoCommerciale'">{{item.value ? 'Oui' : 'Non'}}</span>
                                        <span class="pre-wrap" *ngSwitchDefault>{{(item.value) || '-'}}</span>
                                    </ng-container>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="sticked">
                        </div>
                        <div *ngFor="let i of all"></div>
                    </div>
                </div>

                <div class="row" *ngIf="advancedFields.length">
                    <div class="section-title sticked">
                        Autres champs :
                    </div>
                    <div *ngFor="let i of all"></div>
                </div>

                <div class="row" *ngFor="let field of advancedFields">

                    <div class="sticked text-right content-start">
                        {{field.label}}
                    </div>
                    <div *ngFor="let item of field.items; let i = index">
                        <ol>
                            <li class="checkbox" *ngFor="let value of item.values||[]"
                                [class.disabled]="0 !== i && isCheckboxDisabled(value, field)">
                                <div class="radio">
                                    <input
                                            *ngIf="!isPreview && (0 === i || 0 === mergeOptions[i])"
                                            class="icon"
                                            type="checkbox"
                                            id="{{value['@id']}}{{item.id}}"
                                            [disabled]="0 !== i && isCheckboxDisabled(value, field)"
                                            [checked]="isChecked(value, field)"
                                            (change)="checkboxChange($event, value, field)"
                                    >
                                    <label
                                            [classList]="[(!isPreview && isChecked(value, field)) || (isPreview && mergeOptions[i] % 2 !== 0) ? 'success':'danger']"
                                            for="{{value['@id']}}{{item.id}}"
                                    >{{field.accessor(value)}}</label>


                                </div>
                                <div *ngIf="'personneMorale.liens' === field.path"
                                     [routerLink]="['/repertoire', value.uuid]" class="icon up-right"></div>
                            </li>
                        </ol>
                    </div>
                </div>

                <div class="row" *ngFor="let field of customFields">
                    <div class="sticked text-right">
                        {{field.label}}
                    </div>

                    <div *ngFor="let item of field.items; let i = index">
                        <div class="radio" [class.disabled]="field.disabled">
                            <input
                                    *ngIf="!isPreview && (0 === i || 0 === mergeOptions[i])"
                                    type="radio"
                                    id="{{item.id}}"
                                    (click)="simpleFieldClick($event, item, field)"
                                    [checked]="simpleFieldChecked(item, field)"
                            >
                            <label
                                    [classList]="[(!isPreview && simpleFieldChecked(item, field)) || (isPreview && mergeOptions[i] % 2 !== 0) ? 'success':'danger']"
                                    for="{{item.id}}">
                                <ng-container [ngSwitch]="field.path">
                                    <app-note *ngSwitchCase="'qualite'"
                                              [ngModel]="item.value"
                                              disabled
                                    ></app-note>
                                    <span *ngSwitchCase="'visibilite'">{{item.value|visibility}}</span>
                                    <span *ngSwitchCase="'personnePhysique.infoCommerciale'">{{item.value ? 'Oui' : 'Non'}}</span>
                                    <span *ngSwitchDefault>{{(item.value) || '-'}}</span>
                                </ng-container>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="sticked"></div>
                    <div *ngFor="let i of all"></div>
                </div>
            </div>
        </ng-container>
    </section>
</div>

<div class="form_bas">
    <ng-container *ngIf="isPreview">
        <button type="button" (click)="togglePreview()" class="btn btn-med btn-fermer"><span>Modifier</span></button>
        <button type="button" (click)="save()" class="btn btn-med btn-enregistrer" [disabled]="this.isSaving"><span>Appliquer
        </span></button>
    </ng-container>
    <ng-container *ngIf="!isPreview">
        <button type="button" (click)="cancel()" class="btn btn-med btn-fermer"><span>Annuler</span></button>
        <button type="button" (click)="togglePreview()" class="btn btn-med btn-enregistrer"
                [disabled]="!(mergeOptions|keyvalue).length"><span>Aperçu</span></button>
    </ng-container>
</div>
