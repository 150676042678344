import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CheckboxFilterItemComponent} from '../checkbox-filter-item/checkbox-filter-item.component';
import {PersonneService} from '../../../_services/personne.service';

@Component({
    selector: 'app-personne-statut-filter-item',
    templateUrl: '../checkbox-filter-item/checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class PersonneStatutFilterItemComponent extends CheckboxFilterItemComponent implements OnInit {

    constructor(private personneService: PersonneService) {
        super();
    }

    ngOnInit(): void {
        this.personneService.getListeStatuts().subscribe((data: any) => {
            const statuts = [];
            data.forEach((item: any) => {
                statuts.push({titre : item.texte, uuid : item.libelle});
            });
            this.dataSource = statuts;
        });
    }
}
