<div class="tache" (click)="openDevis(offer?.uuid)">
    <div class="tache_header">
        <span class="libelle">{{ offer?.libelle }}</span>
        <span class="infos">
            <div class="icon circle" [ngClass]="{
                'low': offer?.priorite.id === 1,
                'medium': offer?.priorite.id === 2,
                'high': offer?.priorite.id === 3,
                'critical': offer?.priorite.id === 4
            }"
                 title="Priorité {{ offer?.priorite?.libelle }}"
                 *ngIf="offer?.priorite.id > 0"
            ></div>
        </span>
    </div>
    <div class="tache_content">
        <div class="tache_personne_infos">
            <div *ngIf="offer"
                 class="icon {{ offer['@type'] }}"
                 [ngClass]="{
                    'user': offer['@type'] === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE || offer['@type'] === PERSON_GLOBALS?.PERSON_LIEN,
                    'building': offer['@type'] === PERSON_GLOBALS.PERSON_LIEN_MORALE
                  }" [title]="offer?.contact?.libelle">
               {{  (offer?.contact?.libelle.length>20)? (offer?.contact?.libelle | slice:0:20)+'...':(offer?.contact?.libelle) }}
            </div>
            <div class="badge">
                <app-fonction-sticker [statut]="offer?.contact?.statut"></app-fonction-sticker>
            </div>
        </div>
        <div class="tache_personne_infos">
            <div class="offer-tags" *ngIf="offer?.tags">
                <mat-chip-list>
                    <ng-container *ngFor="let tag of offer.tags;">
                        <mat-chip [title]="allTagsLabels">
                            <span>{{ tag.libelle }}</span>
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
            <div class="prix">
                <ng-container [ngTemplateOutlet]="currency"
                              [ngTemplateOutletContext]="{value: offer?.devis?.montantHT / 100}"></ng-container>
            </div>
        </div>
    </div>
    <div class="tache_footer">
        <div *ngIf="offer?.evenements?.length > 0">
        </div>
    </div>
</div>

<ng-template #currency let-value="value">
    {{ value| currency:'EUR':'symbol':'1.2-2':'fr' }}
</ng-template>
