<div>
    <app-fil-ariane></app-fil-ariane>
    <ngx-ui-loader></ngx-ui-loader>
</div>
<ng-container *ngIf="this.form">
    <notifier-container></notifier-container>
    <div class="form_campagne offre edit" *ngIf="form">
        <form [formGroup]='form'>
            <div class="form_campagne_grid">
                <div class="bandeau_offre icon file-signature">
                    <h1>{{ this.form.get('libelle').value }}</h1>
                </div>
                <div class="rightbox">
                    <app-bloc-visibilite [form]="form"
                                         [type]="'offre'"
                                         [creation]="null"
                    ></app-bloc-visibilite>
                </div>
                <div class="bloc_identite leftbox">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div>
                            <div class="input_container">
                                <div class="espace_input input_lg">
                                    <label for="libelle">Intitulé</label>
                                    <input type="text"
                                           id="libelle"
                                           name="libelle"
                                           [formControlName]="'libelle'"
                                           [ngClass]="{'background_alert_required': !this.form.controls.libelle.valid}"
                                           (change)="syncLibelle()"
                                    />
                                </div>
                                <div class="espace_input input_lg">
                                    <label>Contact</label>
                                    <app-select-contact
                                            [control]="form.get('contact')"
                                            [ngClass]="{'background_alert_required': !this.form.controls.contact.valid}"
                                    ></app-select-contact>

                                </div>
                            </div>
                            <div class="input_container">
                                <div class="espace_input input_lg">
                                    <label>Mots clés</label>
                                    <app-select-tag
                                            id="tags"
                                            [control]="form.controls.tags"
                                            [target]="tagTarget"
                                    >
                                    </app-select-tag>
                                </div>
                                <div class="espace_input input_lg">
                                    <label>Priorité</label>
                                    <ng-select [items]="priorityData"
                                               [multiple]="false"
                                               [closeOnSelect]="true"
                                               [searchable]="false"
                                               [virtualScroll]="true"
                                               id="priorite"
                                               bindValue="id"
                                               bindLabel="text"
                                               formControlName="priorite"
                                               [ngClass]="{'background_alert_required': !this.form?.get('priorite').valid}"
                                    >
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="{{item.icon}}"
                                                 [ngClass]="{'icon circle': item.icon}"
                                            >
                                                {{item.text}}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            <div class="{{item.icon}}"
                                                 [ngClass]="{'icon circle': item.icon}"
                                            >
                                                {{item.text}}
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="input_container wide">
                                <div class=" input_lg">
                                    <label>Memo</label>
                                    <app-creer-memo [offre]="offre" [persist]="false"></app-creer-memo>
                                    <app-memos id="memo"
                                               [disabled]="false"
                                               [offre]="offre"
                                               [memoFiche]="false"
                                               [persist]="false"
                                               (changed)="onChangedSelect2($event, 'memos')"
                                    ></app-memos>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc_devis leftbox" formGroupName="devis">
                    <div class="bandeau_form">Devis</div>
                    <div class="bloc_container">
                        <div>
                            <div class="input_container">
                                <div class="espace_input input_lg">
                                    <label for="libelle">Numéro</label>
                                    <input type="text" formControlName="reference"/>
                                    <div class="label_info" *ngIf="!this.form?.controls.devis.get('reference').value">Le
                                        numéro du devis sera généré automatiquement après avoir enregistré l'offre
                                    </div>
                                </div>
                                <div class="espace_input input_lg">
                                    <label>Référence</label>
                                    <input type="text" formControlName="referencePerso"/>
                                </div>
                            </div>
                            <div class="input_container">
                                <div class="espace_input input_lg">
                                    <label>Libellé du document</label>
                                    <input type="text" formControlName="libelle"/>
                                </div>
                                <div class="espace_input input_lg">
                                    <label>Durée de validité</label>
                                    <ng-select [items]="monthsData"
                                               [multiple]="false"
                                               [closeOnSelect]="true"
                                               [searchable]="false"
                                               [virtualScroll]="true"
                                               id="dureeValidite"
                                               bindValue="id"
                                               bindLabel="text"
                                               formControlName="dureeValidite"
                                               [ngClass]="{'background_alert_required': !this.form.controls.devis.get('dureeValidite').valid}"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="input_container wide">
                                <div class=" input_lg">
                                    <label>Description / Commentaire</label>
                                    <textarea formControlName="commentaire"></textarea>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bloc_devis_lignes" formGroupName="devis">
                <div class="bandeau_form">Lignes du devis</div>
                <div class="bloc_container draggable-list" (cdkDropListDropped)="drop($event)" cdkDropList>
                    <ng-container formArrayName="lignes">
                        <div class="scrollable_container">
                            <div
                                    cdkDragLockAxis="y"
                                    cdkDrag
                                    *ngFor="let ligne of this.formLignes.controls; let index =index"
                            >
                                <ng-container [formGroupName]="index">
                                    <div class="ligne_container">

                                        <div class="draggable-placeholder" *cdkDragPlaceholder></div>
                                        <div cdkDragHandle class="drag-center">
                                            <div class="icon grip-vertical"></div>
                                        </div>
                                        <div class="input_lg ligne_libelle">
                                            <label>Libellé</label>
                                            <app-select-product
                                                    [control]="this.form.controls.devis.get('lignes').get(index.toString()).get('produit')"
                                                    (product)="changeProduct($event, index)"
                                                    [libelle]="this.form.controls.devis.get('lignes').get(index.toString()).get('libelle').value"
                                            ></app-select-product>
                                        </div>
                                        <div class="decorated money">
                                            <label>Prix unitaire HT</label>
                                            <input type="text"
                                                   formControlName="prixUnitaireHT"
                                                   (keyup)="setErrorMessages()"
                                                   (change)="calculerDevis()"
                                            />
                                            <span class="suffix">€</span>
                                            <span *ngIf="this.offre.devis.lignes[index].produit.typeFacturation === 2"
                                                  class="under"> / mois</span>
                                        </div>
                                        <div>
                                            <label>Quantité</label>
                                            <input type="number"
                                                   step="1"
                                                   formControlName="quantite"
                                                   (keyup)="setErrorMessages()"
                                                   (change)="calculerDevis()"
                                            />
                                        </div>
                                        <div class="decorated">
                                            <label>Remise HT</label>
                                            <input type="text"
                                                   formControlName="remise"
                                                   (click)="remiseReset(index)"
                                                   [ngClass]="{'gray': 2 === form.get('devis.lignes.'+index+'.typeRemise').value}"
                                                   (change)="remiseChange(index)"
                                                   (keyup)="setErrorMessages()"
                                            />
                                            <span class="suffix">%</span>
                                        </div>
                                        <div class="decorated money">
                                            <label></label>
                                            <input type="text"
                                                   formControlName="remiseHT"
                                                   (click)="remiseHTReset(index)"
                                                   [ngClass]="{'gray': 1 === form.get('devis.lignes.'+index+'.typeRemise').value}"
                                                   (change)="remiseChangeTTC(index)"
                                                   (keyup)="setErrorMessages()"

                                            />
                                            <span class="suffix">€</span>
                                        </div>
                                        <div class="decorated money">
                                            <label>Total HT</label>
                                            <input type="text" formControlName="totalHT"/>
                                            <span class="suffix">€</span>
                                            <span *ngIf="this.offre.devis.lignes[index].produit.typeFacturation === 2"
                                                  class="under"> / mois</span>
                                        </div>
                                        <div class="decorated">
                                            <label>Taux TVA</label>
                                            <input type="text"
                                                   formControlName="tauxTVA"
                                                   (change)="calculerDevis()"
                                                   (keyup)="setErrorMessages()"
                                            />
                                            <span class="suffix">%</span>
                                        </div>
                                        <div class="decorated money">
                                            <label>Total TTC</label>
                                            <input type="text" formControlName="totalTTC"/>
                                            <span class="suffix">€</span>
                                            <span *ngIf="this.offre.devis.lignes[index].produit.typeFacturation === 2"
                                                  class="under"> / mois</span>
                                        </div>
                                        <div class="delete_btn">
                                            <button type="button" class="btn btn-picto-lg btn-supprimer"
                                                    (click)="deleteLigne(index)">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="alert">
                                        <div class="alert_body" *ngIf="this.errorMessages[index]?.invalid">
                                            <ng-container *ngFor="let error of this.errorMessages[index] | keyvalue">
                                                <div *ngIf="error.key !== 'invalid'">
                                                    {{error.key}} : {{error.value}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="ligne_container create_ligne" *ngIf="creatingLigne">
                                <div></div>
                                <div class="input_lg ligne_libelle">
                                    <app-select-product
                                            [control]="this.form.controls.newProduct"
                                    ></app-select-product>
                                </div>
                                <div>
                                    <button type="button" class="btn btn-sm btn-ajouter btn-new-product" (click)="addNewLigne()">
                                        Ajouter
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type="button" class="btn btn-sm btn-ajouter" *ngIf="!creatingLigne"
                                    (click)="creatingLigne = true">Ajouter une ligne
                            </button>
                        </div>
                    </ng-container>
                    <div class="total_container">
                        <div class="total" *ngIf="this.offre.devis?.montantsDevis">
                            <div class="columns">
                                <div></div>
                                <div></div>
                                <div *ngIf="this.offre.devis?.montantsDevis?.prestations.totalHT > 0">Prestations :
                                </div>
                                <div *ngIf="this.offre.devis?.montantsDevis?.abonnements.totalHT > 0">Abonnements :
                                </div>
                            </div>
                            <ng-container
                                    *ngIf="this.offre.devis?.montantsDevis.prestations.totalRemises > 0 || this.offre.devis?.montantsDevis.abonnements.totalRemises > 0">
                                <div class="hr"></div>
                                <div class="columns regular">
                                    <div></div>
                                    <div class="left">Total HT avant remises</div>
                                    <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0">{{this.offre.devis?.montantsDevis.prestations.totalHTAvantRemises / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}</div>
                                    <div *ngIf="this.offre.devis?.montantsDevis.abonnements.totalHT > 0">{{this.offre.devis?.montantsDevis.abonnements.totalHTAvantRemises / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}
                                        / mois
                                    </div>
                                </div>
                                <div class="columns regular">
                                    <div></div>
                                    <div class="left">Total remises</div>
                                    <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0">
                                        <ng-container
                                                *ngIf="this.offre.devis?.montantsDevis.prestations.totalRemises > 0">
                                            - {{this.offre.devis?.montantsDevis.prestations.totalRemises / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}
                                        </ng-container>
                                        <ng-container
                                                *ngIf="this.offre.devis?.montantsDevis.prestations.totalRemises === 0">
                                            -
                                        </ng-container>
                                    </div>
                                    <div *ngIf="this.offre.devis?.montantsDevis.abonnements.totalHT > 0">
                                        <ng-container
                                                *ngIf="this.offre.devis?.montantsDevis.abonnements.totalRemises > 0">
                                            - {{this.offre.devis?.montantsDevis.abonnements.totalRemises / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}
                                            / mois
                                        </ng-container>
                                        <ng-container
                                                *ngIf="this.offre.devis?.montantsDevis.abonnements.totalRemises === 0">
                                            -
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="hr"
                                 *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0 || this.offre.devis?.montantsDevis.abonnements.totalHT > 0"></div>
                            <div class="columns">
                                <div></div>
                                <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0 || this.offre.devis?.montantsDevis.abonnements.totalHT > 0"
                                     class="left">Total HT
                                </div>
                                <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0">{{this.offre.devis?.montantsDevis.prestations.totalHT / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}</div>
                                <div *ngIf="this.offre.devis?.montantsDevis.abonnements.totalHT > 0">{{this.offre.devis?.montantsDevis.abonnements.totalHT / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}
                                    / mois
                                </div>

                            </div>
                            <ng-container *ngFor="let totalTVA of this.offre.devis?.montantsDevis.totalTVA |keyvalue">
                                <div class="columns regular" *ngIf="totalTVA.value.taux > 0">
                                    <div></div>
                                    <div class="left">
                                        Total TVA {{totalTVA.value.taux / 100| number:'1.0-2':'fr'}} %
                                    </div>
                                    <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0">
                                        <ng-container *ngIf="totalTVA.value.prestations > 0">
                                            {{totalTVA.value.prestations / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}
                                        </ng-container>
                                        <ng-container *ngIf="totalTVA.value.prestations === 0">
                                            -
                                        </ng-container>
                                    </div>
                                    <div *ngIf="this.offre.devis?.montantsDevis.abonnements.totalHT > 0">
                                        <ng-container *ngIf="totalTVA.value.abonnements > 0">
                                            {{totalTVA.value.abonnements / 100| currency:'EUR':'symbol':'1.2-2':'fr'}} /
                                            mois
                                        </ng-container>
                                        <ng-container *ngIf="totalTVA.value.abonnements === 0">
                                            -
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="columns">
                                <div></div>
                                <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0 || this.offre.devis?.montantsDevis.abonnements.totalHT > 0"
                                     class="left">Total TTC
                                </div>
                                <div *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0">{{this.offre.devis?.montantsDevis.prestations.totalTTC / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}</div>
                                <div *ngIf="this.offre.devis?.montantsDevis.abonnements.totalHT > 0">{{this.offre.devis?.montantsDevis.abonnements.totalTTC / 100| currency:'EUR':'symbol':'1.2-2':'fr'}}
                                    / mois
                                </div>
                            </div>
                            <div class="columns bas"
                                 *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0 || this.offre.devis?.montantsDevis.abonnements.totalHT > 0">
                                <div></div>
                                <div class="left">
                                    Montant de l'offre HT<sup>*</sup>
                                </div>
                                <div>{{this.offre.devis.montantHT}} €</div>
                                <div></div>
                            </div>
                            <div class="etoile"
                                 *ngIf="this.offre.devis?.montantsDevis.prestations.totalHT > 0 || this.offre.devis?.montantsDevis.abonnements.totalHT > 0">
                                * total prestations HT + (total abonnements x 12 mois)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="form_bas">
            <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    [ngClass]="{'btn-disabled': this.form.valid === false}"
                    (click)="onSubmit(false)">
                <span>Enregistrer</span>
            </button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    [ngClass]="{'btn-disabled': this.form.valid === false}"
                    (click)="onSubmitCancel()">
                <span>Enregistrer et fermer</span>
            </button>
        </div>
        <app-modal-confirm-exit #confirmModal (cancelEvent)="onCancel()"></app-modal-confirm-exit>
    </div>
</ng-container>

<ng-template #currency let-value="value">
    {{ value| currency:'EUR':'symbol':'1.2-2':'fr' }}
</ng-template>
