'use strict';

export class ApiGlobals {
	static readonly POLLING_INTERVAL = (60000 * 15); // 15min
}

export class UIGlobals {
	static readonly TITLE_CRM = 'AZKO CRM';
}

export class PersonGlobals {
	static readonly PERSON_PHYSIQUE = 'personnePhysique';
	static readonly PERSON_LIEN_PHYSIQUE = 'physique';
	static readonly PERSON_LIEN_MORALE = 'morale';
	static readonly PERSON_LIEN_FONCTION = 'fonction';
	static readonly PERSON_LIEN = 'lien';
	static readonly PERSON_LINKED_CURRENT = 'current';
	static readonly PERSON_LINKED_PARENT = 'parent';
	static readonly PERSON_LINKED_ENFANT = 'enfant';
	static readonly PERSON_LINKED_PHYSIQUE = 'physique';
	static readonly PERSON_STATUT_AUTRE = 'autre';
	static readonly PERSON_STATUT_PROSPECT = 'prospect';
	static readonly PERSON_STATUT_ADVERSE = 'adverse';
	static readonly PERSON_STATUT_MEMBRE = 'membre';
	static readonly PERSON_STATUT_CLIENT = 'client';
}

export class OrganisationGlobals {
	static readonly ORGA_PARENTE = 'organisationParente';
}

export class EvenementGlobals {
	static readonly EVENT_PLANIFIE = 'planifie';
	static readonly EVENT_NOW = 'aujourdhui';
	static readonly EVENT_EN_RETARD = 'en_retard';
	static readonly EVENT_TERMINE = 'termine';
	static readonly EVENT_FAIT = 'fait';

	static readonly APPEL_A_PASSER = 'Appel à passer';
	static readonly APPEL_PASSE = 'Appel passé';
	static readonly APPEL_A_RECEVOIR = 'Appel à recevoir';
	static readonly APPEL_RECU = 'Appel reçu';
	static readonly APPEL_ANNULE = 'Appel annulé';
	static readonly EMAIL_A_ENVOYER = 'E-Mail à envoyer';
	static readonly EMAIL_ENVOYE = 'E-Mail envoyé';
	static readonly EMAIL_A_RECEVOIR = 'E-Mail à recevoir';
	static readonly EMAIL_RECU = 'E-Mail reçu';
	static readonly EMAIL_ANNULE = 'E-Mail annulé';
	static readonly RDV_CABINET = 'Rendez-vous cabinet';
	static readonly RDV_EXTERIEUR = 'Rendez-vous extérieur';
	static readonly RDV_ANNULE = 'Rendez-vous annulé';
	static readonly DEVIS = 'Devis';
	static readonly CHANGEMENT_STATUT = 'Changement de statut';

	static readonly APPEL_ENTRANT_ID = 10;
	static readonly APPEL_SORTANT_ID = 11;
	static readonly MAIL_ENTRANT_ID = 20;
	static readonly MAIL_SORTANT_ID = 21;
	static readonly MAIL_CMP_DESTINATAIRE = 22;
	static readonly MAIL_CMP_OPEN = 23;
	static readonly MAIL_CMP_CLIC = 24;
	static readonly MAIL_CMP_DESINSCRIPTION = 25;
	static readonly RDV_CABINET_ID = 30;
	static readonly RDV_EXTERIEUR_ID = 31;
	static readonly DEVIS_ID = 40;
	static readonly CHANGEMENT_STATUT_ID = 50;

	static readonly STATUT_A_FAIRE = 1;
	static readonly STATUT_FAIT = 2;
	static readonly STATUT_ANNULE = 10;

	static readonly ACTIF = 1;
	static readonly INACTIF = 2;
}

export class CoordonneeGlobals {

	static readonly COORD_TYPE_EMAIL = {
		id: 1,
		text: 'email'
	};

	static readonly COORD_TYPE_SITE = {
		id: 2,
		text: 'site'
	};

	static readonly COORD_TYPE_FAX = {
		id: 4,
		int: 4,
		text: 'fax'
	};

	static readonly COORD_TYPE_TEL = {
		id: 7,
		int: 7,
		text: 'telephone'
	};

	/**
	 * Constante sous-type
	 */
	static readonly COORD_SOUS_TYPE_EMAIL = {
		id: 1,
		text: 'email'
	};

	static readonly COORD_SOUS_TYPE_SITE = {
		id: 1,
		text: 'site'
	};

	static readonly COORD_SOUS_TYPE_TEL = {
		fixe: {
			id: 1,
			text: 'fixe'
		},
		portable: {
			id: 3,
			text: 'portable'
		}
	};

	static readonly COORD_SOUS_TYPE_FAX = {
		id: 4,
		text: 'fax'
	};

	/**
	 * Constante statut
	 */
	static readonly COORD_STATUT_ACTIF = {
		id: 1,
		text: 'Actif'
	};

	static readonly COORD_STATUT_INACTIF = {
		id: 2,
		text: 'Inactif'
	};

	static readonly COORD_STATUT_OPTOUT = {
		id: -1,
		text: 'Désabonné'
	};
	static readonly CONSENT = {
		INVALID: {
			id: 2
		},
		VALID: {
			id: 1
		},
		ND: {
			id: 0
		},
		UNSUBSCRIBED : {
			id: 3
		},
		TYPE: {
			AUTRE: {
				id: 5
			}
		}
	};
}

export class RappelGlobals {
	static readonly RAPPEL_TYPE_FICHE_LIBELLE = 'fiche';
	static readonly RAPPEL_TYPE_TACHE_LIBELLE = 'tache';
}

export class VoletGlobals {
	static readonly RAPPEL_FERME = 'rappel_ferme';
	static readonly PARAMETRE_FERME = 'parametre_ferme';
}

export class ParametreGlobals {
	static readonly SYSTEM_LIBELLE = 'Système';
	static readonly SYSTEM_ID = 1;
	static readonly PERSONNALISE_LIBELLE = 'Personnalisé';

	static readonly TAG_PERSON_LIBELLE = 'Personne';
	static readonly TAG_OFFRE_LIBELLE = 'Offre';
	static readonly TAG_ID_PERSON = 1;
	static readonly TAG_ID_OFFRE = 2;
	static readonly TAG_ID_FULL = 3;
}

export class VueGlobals {
	static readonly PERSONNE = 'personne';
	static readonly SEGMENT = 'segment';
	static readonly OFFRE = 'offre';
	static readonly CAMPAGNE = 'campagne';
}

export class CampaignGlobals {
	static readonly DRAFT = 'draft';
	static readonly DRAFT_ID = 1;
	static readonly QUEUED = 'queued';
	static readonly QUEUED_ID = 2;
	static readonly IN_PROCESS = 'inProcess';
	static readonly IN_PROCESS_ID = 3;
	static readonly SENT = 'sent';
	static readonly SENT_ID = 4;
	static readonly SUSPENDED = 'suspended';
	static readonly SUSPENDED_ID = 5;
	static readonly ARCHIVE = 'archive';
	static readonly ARCHIVE_ID = 6;
	static readonly MAPPING = [
		{
			id: 1,
			libelle: 'Brouillon'
		},
		{
			id: 2,
			libelle: 'Planifié'
		},
		{
			id: 3,
			libelle: 'En cours'
		},
		{
			id: 4,
			libelle: 'Envoyé'
		},
		{
			id: 5,
			libelle: 'Terminé (arrêtée)'
		},
		{
			id: 6,
			libelle: 'Archivé'
		}
	];
}

export class VisibiliteGlobals {
	static readonly PUBLIC = 'public';
	static readonly PROTECTED = 'protected';
	static readonly PRIVATE = 'private';

	static readonly CREATEUR = 1;
	static readonly INTERVENANT = 2;
	static readonly REFERENT = 3;

    static readonly MAPPING = [
        {
            uuid: 'public',
            titre: 'Public'
        },
        {
            uuid: 'protected',
            titre: 'Protégé'
        },
        {
            uuid: 'private',
            titre: 'Privé'
        },
    ];
}

// export class DefaultField {
//
//     static readonly FIELD_DEFAULT = {
//         cp: 'code postal',
//         ville: 'ville',
//         mail: 'email',
//         telephone: 'téléphone',
//         civilite: 'civilité',
//         titre: 'titre',
//         capital: 'capital',
//         effectif: 'effectif'
//     };
// }
