<ng-select
        class="select-tag"
        bindLabel="texte"
        [formControl]="control"
        [items]="items | async"
        [clearable]="false"
>
    <ng-template ng-label-tmp let-item="item">
        <div *ngIf="item.libelle?.length > 0" [class]="item.libelle">
            <span class="ng-value-label">{{item.texte}}</span>
        </div>
    </ng-template>
</ng-select>
