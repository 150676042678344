<div id="gallery">
    <div class="inline_loader" [hidden]="!isLoading">
        <ngx-ui-loader [loaderId]="'listImg'"></ngx-ui-loader>
    </div>
    <div class="image-list-grid" *ngIf="!isLoading">
        <ng-container *ngFor="let image of images">
            <div class="image_list">
                <img height="100" width="100" src="{{ image.path }}" alt="image : {{ image.path }}">
                <label>
                    <div title="Copier le lien dans le presse-papier"
                         (click)="copySrcToClipboard(image.path)"
                    >
                        <fa-icon [icon]="faCopy"></fa-icon>
                    </div>
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="images.length === 0 && isLoading === false">
            <div class="no_imgs">
                <p>Aucune image n'est actuellement présente dans la galerie</p>
                <p><button type="button" class="btn btn-med btn-actualiser" (click)="refresh()">Rafraichir la galerie</button></p>
            </div>
        </ng-container>
    </div>
</div>
