import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import PersonneSimilarStat from "../../../models/personne-similar-stat";
import {LABEL_STATS, PersonneLienService} from "../../_services/personne-lien.service";

@Component({
    selector: 'app-duplication-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnInit {

    totalDuplicated = 0;

    statSimilarities: PersonneSimilarStat[] = [];

    labelStats = LABEL_STATS;

    constructor(
        private pls: PersonneLienService,
        private ref: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.pls.statsSimilarity().subscribe(data => {
            this.statSimilarities = data['hydra:member'];
            this.statSimilarities.forEach(s => {
                s.similarityLevel && (this.totalDuplicated += s.distinct)
            })
            this.ref.markForCheck()
        })
    }
}
