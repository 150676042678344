import { Injectable } from '@angular/core';
import {AbstractClient} from "./abstract-client";
import {Filter} from "./personne-lien.service";
import {HttpClient} from "@angular/common/http";
import {environment as ENV} from "../../environments/environment";
import {ITauxTVA} from "../../interfaces/itaux-tva";

@Injectable({
  providedIn: 'root'
})
export class TauxTVAService extends AbstractClient<ITauxTVA, Filter> {
  constructor(http: HttpClient) {
    super(http, ENV.apiURL + 'taux_tvas');
  }
}
