import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Select2OptionData} from 'ng-select2';
import {ModalService} from '../../../_modal';
import {PersonneService} from '../../../_services/personne.service';
import {CoordonneService} from '../../../_services/coordonne.service';
import {CoordonneeGlobals, PersonGlobals} from '../../../../const-global/globals';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Coordonnee} from '../../../../models/coordonnee';
import {NotifierService} from 'angular-notifier';
import {v4 as uuidv4} from 'uuid';
import {SelectOption} from "../../../../interfaces/select-option";
import {MessageGlobals} from "../../../../const-global/messages";
import {ConsentHelper} from "../../../_helpers/consent.helper";

@Component({
	selector: 'app-coordonnees-edit-modal',
	templateUrl: './coordonnees-edit-modal.component.html',
	styleUrls: ['./coordonnees-edit-modal.component.scss']
})
export class CoordonneesEditModalComponent implements OnInit {
	public readonly COORD_GLOBALS = CoordonneeGlobals;
	public readonly PERSON_GLOBALS = PersonGlobals;
	public readonly ConsentHelper = ConsentHelper;
	@Input() coordonneesDB: any;
	@Input() Acoordonnes: any;
	@Input() personType: string;
	@Input() personStatut: string;
	@Input() idModal;
	@Output() added = new EventEmitter<any>();
	@Output() closed = new EventEmitter<any>();

	public types = [];
	public sousTypes;
	public mappingTypeSousType = [];
	public typePrincipal = true;
	public disabled = false;
	public form: FormGroup;
	public data: Coordonnee;
	public indicatifs: Array<any>;
	public indicatifDefault: string;
	public edit = false;
	public options: any;
	public indicatifData: Observable<Array<Select2OptionData>> = new Observable(observer => {
		observer.next([]);
		observer.complete();
	});
	public typeId: number;
	private principalHasChanged = false;
	public optoutCord: any;

	public statutList: SelectOption[];



    filteredPersonStatut: Array<string> = [
        'membre',
        'adverse'
    ];

    constructor(
        private personneService: PersonneService,
        private modalService: ModalService,
        private coordonneesService: CoordonneService,
        private formBuilder: FormBuilder,
        private notifier: NotifierService
    ) {
    }

    get typeLabel(): string {
        if (this.form.get('type_id')?.value) {
            return this.types.filter(t => t.id === this.form.get('type_id').value)[0].text
        }
        return '';
    }

    ngOnInit(): void {
        if (this.typeId !== CoordonneeGlobals.COORD_TYPE_EMAIL.id) {
            this.statutList = this.ConsentHelper.statut.filter(status => status.id !== -1)
        } else {
            this.statutList = this.ConsentHelper.statut;
        }
        this.options = {};
        // TODO : bouger la méthode dans coordService
        this.personneService.getListeIndicatifs().subscribe(
            data => {
                this.indicatifs = [];
                data.map((item) => {
                    this.indicatifs.push({
                        id: item.uuid,
                        text: (item.pays) ? item.indicatif + ' (' + item.pays + ')' : item.indicatif,
                        value: item.indicatif
                    });
                    if (JSON.parse(item.default)) {
                        this.indicatifDefault = item.uuid;
                    }
                });
                this.indicatifData = new Observable(observer => {
                    observer.next(this.indicatifs);
                    observer.complete();
                });
            },
            err => {
                console.error(err);
            }
        );
        // récupération des la liste des types de coordonnees
        this.personneService.getCoordonneesTypes().subscribe(
            data => {
                const sousTypes = [];

                this.coordonneesService.getCoordInfos().forEach(type => {
                    if (type.text !== 'statuts' && type.id !== '-1') {
                        const typeKey = type.id;
                        const typeLibelle = this.coordonneesService.getRealType(type.value);

                        sousTypes[typeKey] = [];
                        data[typeLibelle].map((item) => {
                            sousTypes[typeKey].push({
                                id: item.id,
                                text: item.text,
                                sous_type_defaut: type.sous_type_defaut
                            });
                        });
                    }
                });

                this.mappingTypeSousType = sousTypes;
            },
            err => {
                console.error(err);
            }
        );

        this.types = this.coordonneesService.getCoordInfos();

        this.form = this.formBuilder.group({
            uuid: [this.data?.uuid || uuidv4(), Validators.required],
            principal: [{value: this.data?.principal, disabled: this.disabled}],
            type_id: [this.data?.type_id, Validators.required],
            sous_type_id: [this.data?.sous_type_id, Validators.required],
            statut_id: [this.data?.statut_id, Validators.required],
            indicatif: [this.data?.indicatif?.uuid || this.data?.indicatif],
            valeur: [this.data?.valeur, Validators.required],
            consent: [this.data?.consent || CoordonneeGlobals.CONSENT.ND.id],
            consent_type: [this.data?.consent_type],
            consent_custom_type: [this.data?.consent_custom_type]
        }, {updateOn: 'change'});

        if (
            this.personType !== PersonGlobals.PERSON_LIEN_MORALE
            && this.form.get('type_id')?.value === CoordonneeGlobals.COORD_TYPE_EMAIL.id
            && !this.filteredPersonStatut.includes(this.personStatut)
        ) {
            this.form.get('consent').setValidators([Validators.required]);
        }

		this.form.get('type_id').valueChanges.subscribe(this.typeChange);
		this.form.get('type_id').updateValueAndValidity();

		this.coordonneesService.coordonneeSubject.subscribe(coordonnee => {
			this.form.get('valeur').setValue(coordonnee.valeur);
		});

		this.form.get('principal').valueChanges.subscribe(() => {
			this.principalHasChanged = true;
		});
        this.form.get('consent').valueChanges.subscribe(consent => {
            if (consent === 0) {
                this.form.get('consent_type').setValue(null);
                this.form.get('consent_custom_type').setValue(null);
            }
        });

        this.form.get('consent_type').valueChanges.subscribe(consentType => {
            // type !== autre
            if (consentType !== 5 || this.filteredPersonStatut.includes(this.personStatut)) {
                this.form.get('consent_custom_type').clearValidators();
            } else {
                this.form.get('consent_custom_type').setValidators([Validators.required]);
            }
            this.form.get('consent_custom_type').updateValueAndValidity();
        });

        this.coordonneesService.optOutCoordSubject.subscribe(emails => {
            this.optoutCord = emails;
        });
    }

	cancel() {
		this.form.reset();
		this.closeModal();
	}

	closeModal(): void {
		// on applique un objet vide avec un nouvel uuid
		this.closed.emit(true);
		this.modalService.close('coordonneeModal');
		this.coordonneesService.resetOptOutCoord();
	}

	/**
	 * Event : Changement de statut (ex: NPAI)
	 */
	coordStatutChange($event): void {
		if (typeof $event !== 'undefined') {
			if ($event === '-1') {
				this.coordonneesService.addOptOutElement({
					'uuid': this.form.get('uuid').value,
					'previousValue': this.form.get('statut_id').value
				});
			} else {
				this.coordonneesService.removeOptOutElement(this.form.get('uuid').value);
			}
			this.form.get('statut_id').setValue($event);
		}
	}

	/**
	 * @param $event // id type string
	 */
	typeChange = ($event: number | null): void => {

		if (!$event) {
			return;
		}

        const libelleType = this.coordonneesService.getRealTypeById($event);

        if (this.Acoordonnes[libelleType].length === 0) {
            this.form.get('principal').disable();
        } else {
            this.form.get('principal').enable();
        }

        this.typeId = $event;
        if (this.typeId !== CoordonneeGlobals.COORD_TYPE_EMAIL.id) {
            this.statutList = this.ConsentHelper.statut.filter(status => status.id !== -1)
        } else {
            this.statutList = this.ConsentHelper.statut;
        }

        this.sousTypes = this.mappingTypeSousType[$event];

        switch (this.typeId) {
            case CoordonneeGlobals.COORD_TYPE_EMAIL.id:
                if (this.personType !== PersonGlobals.PERSON_LIEN_MORALE && !this.filteredPersonStatut.includes(this.personStatut)) {
                    this.form.get('consent').setValidators([Validators.required]);
                }
                this.form.get('valeur').setValidators([Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/i), Validators.required]);
                break;
            case CoordonneeGlobals.COORD_TYPE_SITE.id:
                this.form.get('consent').clearValidators();
                this.form.get('consent_type').clearValidators();
                this.form.get('valeur').setValidators([Validators.pattern(/^(https?:\/\/)(\w)+\.(\w)+/), Validators.required])
                break;
            case CoordonneeGlobals.COORD_SOUS_TYPE_FAX.id:
                this.form.get('consent').clearValidators();
                this.form.get('consent_type').clearValidators();
                this.form.get('sous_type_id').setValue(4)
            case CoordonneeGlobals.COORD_TYPE_TEL.id:
                this.form.get('consent').clearValidators();
                this.form.get('consent_type').clearValidators();
                this.form.get('valeur').setValidators([Validators.pattern(/^\d{4,}$/), Validators.required]);
                break;
        }

        this.form.get('valeur').updateValueAndValidity({emitEvent: false});
    }

    openModal(): void {
        this.edit = false;
        this.data = new Coordonnee({
            uuid: uuidv4(),
            principal: this.typePrincipal,
            type_id: null,
            sous_type_id: 1,
            statut_id: CoordonneeGlobals.COORD_STATUT_ACTIF.id,
            indicatif: this.indicatifDefault,
            indicatif_id: '1',
            valeur: ''
        });

        this.ngOnInit();
        this.modalService.open('coordonneeModal');
    }

    openEditModal(id: string, coordonnee: Coordonnee): void {
        this.edit = true;
        this.typeId = coordonnee.type_id;
        this.disabled = this.lastEntry(this.typeId);
        this.data = coordonnee;
        this.ngOnInit();
        this.modalService.open(id);
    }

    onSubmitModal(): void {
        if (!this.form.valid) {
            this.notifier.notify('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);

            return;
        }

        if (this.principalHasChanged) {
            this.checkPrincipal(
                this.form.get('type_id').value,
                this.form.get('uuid').value,
                this.form.get('principal').value
            );
        }

        this.added.emit({
            type: this.form.get('type_id').value,
            data: this.form.getRawValue()
        });

        this.closeModal();
        this.coordonneesService.resetOptOutCoord();
    }

    checkPrincipal(typeId: number, uuid: string, valuePrincipal: boolean): void {
        const libelleType = this.coordonneesService.getRealTypeById(typeId);
        this.Acoordonnes[libelleType].forEach(() => {
            this.Acoordonnes[libelleType].filter((coordonnee: Coordonnee) => {
                if (coordonnee.uuid !== uuid && valuePrincipal === true) {
                    coordonnee.principal = false;
                }
                this.form.get('principal').setValue(valuePrincipal);
            });
        });
    }

    lastEntry(typeId: number): boolean {
        const type = this.coordonneesService.getRealTypeById(typeId);
        return this.Acoordonnes[type].length === 1;
    }
}
