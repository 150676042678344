<!--<app-filter>-->
<div class="main">
    <div class="bloc1">
        <button type="button" action class="btn btn-ajouter btn-med campagne" [routerLink]="['choix-modele']">
            Ajouter une campagne
        </button>
        <app-campagne-active-switcher></app-campagne-active-switcher>
    </div>
    <!--</app-filter>-->
    <ngx-ui-loader></ngx-ui-loader>
    <div class="campagne_kanban_grid" *ngIf="draft">
        <div class="kanban_col">
            <div class="kanban_col_header light_orange">
                <div class="kanban_col_title icon edition">Brouillon</div>
                <div class="kanban_col_title_count">{{ this.draft.length }}</div>
            </div>
            <div class="kanban_col_body">
                <div class="kanban_col_content" *ngFor="let item of this.draft">
                    <app-kanban-tache [campagne]="item"></app-kanban-tache>
                </div>
            </div>
        </div>
        <div class="kanban_col" *ngIf="scheduled">
            <div class="kanban_col_header light_orange">
                <div class="kanban_col_title icon planifie">Planifié</div>
                <div class="kanban_col_title_count">{{ this.scheduled.length }}</div>
            </div>
            <div class="kanban_col_body">
                <div class="kanban_col_content" *ngFor="let item of this.scheduled">
                    <app-kanban-tache [campagne]="item"></app-kanban-tache>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header pastel_orange">
                <div class="kanban_col_title icon envoi_en_cours">Envoi en cours</div>
                <div class="kanban_col_title_count">{{ this.inProgress.length }}</div>
            </div>
            <div class="kanban_col_body">
                <div class="kanban_col_content" *ngFor="let item of this.inProgress">
                    <app-kanban-tache [campagne]="item"></app-kanban-tache>
                </div>
            </div>
        </div>
        <div class="kanban_col">
            <div class="kanban_col_header">
                <div class="kanban_col_title icon envoye">Envoyé</div>
                <div class="kanban_col_title_count">{{ this.done.length }}</div>
            </div>
            <div class="kanban_col_body">
                <div class="kanban_col_content" *ngFor="let item of this.done">
                    <app-kanban-tache [campagne]="item"></app-kanban-tache>
                </div>
            </div>
        </div>
    </div>
</div>
