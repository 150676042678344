<jw-modal id="modalTemplate">
    <div class="icon chevron_left" (click)="changeModel('prev')" title="suivant"></div>
    <div class="bloc_title_modal">{{ modelApercu?.title | uppercase}}</div>
    <div class="bloc_body_modal" *ngIf="modelApercu?.description">{{ modelApercu?.description }}</div>
    <div class="edit_data">
        <img *ngIf="modelApercu?.image" class="img_modele" src="{{ rootImage }}{{modelApercu?.image}}"
             alt="image du template de l'email">
    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeApercuTemplate('modalTemplate')">
                <span>Fermer</span>
            </button>
            <button type="button" *ngIf="(modelApercu?.free && !modelApercu?.buyDate) || modelApercu?.buyDate"
                    class="btn btn-med campagne icon check" (click)="chooseModel('')">
                <span>Choisir ce modèle</span>
            </button>
            <button type="button" *ngIf="!modelApercu?.free && !modelApercu?.buyDate"
                    class="btn btn-med campagne icon basket" (click)="openModalBuyModele()">
                <span>Acheter ce modèle</span>
            </button>
        </div>
    </div>
    <div class="icon chevron_right" (click)="changeModel('next')" title="précédent"></div>
</jw-modal>
