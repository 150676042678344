<div class="alertes" [ngClass]="{'alertes_personne_modifier': !router.url.endsWith('/repertoire')} ">
    <div *ngFor="let typeAlert of typeAlerts">
        <div *ngIf="(typeAlert === MESSGAGE_INFO.FICHE_A_OPTIMISER_REPERTOIRE) && (!personne.optimized || personne.countPesonneSimilar !== 0)"
             class="carte_alerte" [ngClass]="{
                   'lien': personne?.pmUuid && personne?.ppUuid,
                   'top': positionTop
             }"
        >
            <div class="icon i_alerte" [ngClass]="{'i_idea_sm': personne}">
                <span>Fiche à optimiser</span>
            </div>
        </div>

        <div *ngIf="(typeAlert === MESSGAGE_INFO.FICHE_A_OPTIMISER_FICHE && !personne.optimized)" class="carte_alerte"
             [ngClass]="{'top': positionTop}">
            <div class="icon i_idea">
                <span>Champs recommandés manquants</span>
            </div>
        </div>
        <div *ngIf="(typeAlert === MESSGAGE_INFO.FICHE_DOUBLON && personne?.countPesonneSimilar > 0)"
             class="carte_alerte"
        >
            <div class="icon user_group">
                <span>Doublon détecté. <span class="route" [routerLink]="['/doublons/', personne?.uuid]">Vérifier les doublons.</span></span>
            </div>
        </div>
    </div>
</div>
