import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit, OnDestroy {

    @Output() switch = new EventEmitter<any>();
    @Input() switchValue: boolean;
    @Input() resetSwitch: any;
    private resetSubscription: Subscription;

    constructor(
        private personneService: PersonneService
    ) {
    }

    ngOnInit(): void {
        this.resetSubscription = this.personneService.resetSubject.subscribe((reset) => {
            this.switchValue = reset;
        });
    }

    ngOnDestroy(): void {
        this.resetSubscription.unsubscribe();
    }

    onSwitch($event): void {
        if ($event.target.checked) {
            this.switchValue = true;
            this.switch.next(this.switchValue);
        } else {
            this.switchValue = false;
            this.switch.next(this.switchValue);
        }
    }
}
