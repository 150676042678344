import {ICampagne} from '../interfaces/campagne';

export class Campagne implements ICampagne {
    uuid: string;
    libelle: string;
    fuseauHoraire?: string;
    statut: string;
    contacts: number;
    from: string;
    sent: number;
    actif?: boolean;
    visibilite?: number;
    subject?: string;
    body?: string;
    templateConfig?: object;
    relance?: string;
    segments?: any;
    scheduledAt?: any;
    intervenants?: Array<any> = [];
    referent?: string;
    userCreation?: string;
    userModification?: string;
    creationDate?: any;
    modificationDate?: any;
    endedAt?: any;
    linked?: string;
    stats: any;
    constructor(data?: object) {
        Object.assign(this, data);
    }
}
