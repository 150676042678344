import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {PersonneService} from '../../_services/personne.service';
import {BaseSelect2Component} from '../base-select2/base-select2.component';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
  selector: 'app-chiffre-affaire-select2',
  templateUrl: './chiffre-affaire-select2.component.html',
  styleUrls: ['./chiffre-affaire-select2.component.scss']
})
export class ChiffreAffaireSelect2Component {
    items = this.personneService.getListeChiffreAffaire()
    @Input() control: FormControl|AbstractControl = new FormControl();
    constructor(private personneService: PersonneService) {}
}
