<div class="circle-container" *ngIf="probabilite > 0 && probabilite < 1000">
    <div class="step draft"
         (mouseover)="changeStep(20)"
         (mouseleave)=" restoreValue()"
         (click)="updateValue(20)"
         matTooltip="{{libelles.draft}}"
         matTooltipClass="tooltip-draft"
    >
        <div class="circle"
             [@steps]=" probabilite < 20 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{'white' : probabilite < 20 ||  probabilite === 999}"
        >
            20%
        </div>
    </div>
    <div class="step sent"
         (mouseover)="changeStep(40)"
         (mouseleave)=" restoreValue()"
         (click)="updateValue(40)"
         matTooltip="{{libelles.sent}}"
         matTooltipClass="tooltip-sent"

    >
        <div class="rectangle"
             [@steps]=" probabilite < 40 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{
             'white' : probabilite < 40 ||  probabilite === 999,
             'coloredBefore': probabilite === 20
             }"
        >
        </div>
        <div class="circle"
             [@steps]=" probabilite < 40 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{'white' : probabilite < 40 ||  probabilite === 999 }"
        >
            40%
        </div>
    </div>
    <div class="step negociation"
         (mouseover)="changeStep(60)"
         (mouseleave)=" restoreValue()"
         (click)="updateValue(60)"
         matTooltip="{{libelles.negociation}}"
         matTooltipClass="tooltip-negociation"

    >
        <div class="rectangle"
             [@steps]=" probabilite < 60 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{
             'white' : probabilite < 60 ||  probabilite === 999,
             'coloredBefore': probabilite  === 40
             }"
        >
        </div>
        <div class="circle"
             [@steps]=" probabilite < 60 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{'white' : probabilite < 60 ||  probabilite === 999}"
        >
            60%
        </div>
    </div>
    <div class="step promise"
         (mouseover)="changeStep(80)"
         (mouseleave)=" restoreValue()"
         (click)="updateValue(80)"
         matTooltip="{{libelles.promise}}"
         matTooltipClass="tooltip-promise"

    >
        <div class="rectangle"
             [@steps]=" probabilite < 80 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{
             'white' : probabilite < 80 ||  probabilite === 999,
             'coloredBefore': probabilite  === 60
             }"
        >
        </div>
        <div class="circle"
             [@steps]=" probabilite < 80 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{'white' : probabilite < 80 ||  probabilite === 999}"
        >
            80%
        </div>
    </div>

    <div class="step signed"
         (mouseover)="changeStep(100)"
         (mouseleave)=" restoreValue()"
         (click)="updateValue(100)"
         matTooltip="{{libelles.signed}}"
         matTooltipClass="tooltip-signed"

    >
        <div class="rectangle"
             [@steps]=" probabilite < 100 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{
             'white' : probabilite < 100 ||  probabilite === 999,
             'coloredBefore': probabilite === 80
             }"
        >
        </div>
        <div class="circle"
             [@steps]=" probabilite < 100 ||  probabilite === 999 ? 'white' : ''"
             [ngClass]="{'white' : probabilite < 100 ||  probabilite === 999}"
        >
            100%
        </div>
    </div>
    <div class="step canceled"
         (mouseover)="changeStep(999)"
         (mouseleave)=" restoreValue()"
         (click)="updateValue(999)"
         matTooltip="{{libelles.canceled}}"
         matTooltipClass="tooltip-canceled"

    >
        <div class="rectangle"


        >
            <div class="right"
                 [@steps]="  probabilite < 999 ? 'white' : ''"
                 [ngClass]="{
             'white' : probabilite <= 999,
             'coloredBefore': probabilite  === 100
             }"
                 ></div>
            <div class="center"
            ></div>
            <div class="left"
                 [@steps]="  probabilite < 999 ? 'white' : ''"
                 [ngClass]="{
             'white' : probabilite < 999,
             'coloredBefore': probabilite  === 100
             }"
            ></div>
        </div>
        <div class="circle"
             [@steps]="  probabilite < 999 ? 'white' : ''"
             [ngClass]="{'white' : probabilite < 999 }"
        >
            -100%
        </div>
    </div>
</div>
