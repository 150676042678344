import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment as ENV} from 'src/environments/environment';
import {ITag} from '../../interfaces/tag';

const API_URL = ENV.apiURL;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};

@Injectable({
    providedIn: 'root'
})
export class TagService {
    tagSubject = new Subject<ITag>();

    constructor(private http: HttpClient) {
    }

    getAllTags(recherche: string): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('recherche', recherche)
        };
        return this.http.get(ENV.apiURL + 'parametre/tags/all', options);
    }

    getTagsFiltered(target?: string, systeme?: string, recherche?: string): Observable<any> {
        let options = {};
        if (target) {
            options = {
                params: new HttpParams().set('target', target)
            };
        } else if (systeme) {
            options = {
                params: new HttpParams().set('systeme', systeme)
            };
        } else if (target && systeme) {
            options = {
                params: new HttpParams()
                    .set('target', target)
                    .set('systeme', systeme)
                    .set('recherche', recherche)
            };
        }
        return this.http.get<ITag[]>(API_URL + 'parametre/tags', options);
    }

    getTag(uuid): Observable<any> {
        return this.http.get(API_URL + 'parametre/tag/' + uuid);
    }

    updateTag(uuid: string, form: ITag): Observable<any> {
        return this.http.put(API_URL + 'parametre/tag/' + uuid, {
            tag: form,
        }, httpOptions);
    }

    deleteTag(uuid: string): Observable<any> {
        return this.http.delete(API_URL + 'parametre/tag/' + uuid, httpOptions);
    }

    creerTag(form: ITag): Observable<any> {
        return this.http.post(API_URL + 'parametre/creer/tag', {
            tag: form,
        }, httpOptions);
    }

    countTagUsage(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'parametre/tag/' + uuid + '/count');
    }
}
