import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LayoutComponent} from './layout/layout.component';
import {RepertoireComponent} from './repertoire/repertoire.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SegmentComponent} from './segment/segment.component';
import {CampagneComponent} from './campagne/campagne.component';
import {StatistiqueComponent} from './statistique/statistique.component';
import {AuthGuard} from './_guard/auth.guard';
import {FicheComponent} from './repertoire/fiche/fiche.component';
import {ListeCartesComponent} from './repertoire/carte/liste-cartes/liste-cartes.component';
import {CreerPersonneComponent} from './repertoire/creer/creer-personne/creer-personne.component';
import {CreerChoixComponent} from './repertoire/creer/creer-choix/creer-choix.component';
import {ModifierPersonneComponent} from './repertoire/modifier-personne/modifier-personne.component';
import {CreerFonctionComponent} from './repertoire/creer/creer-fonction/creer-fonction.component';
import {ListeCartesSegmentComponent} from './segment/liste-cartes-segment/liste-cartes-segment.component';
import {FicheSegmentComponent} from './segment/fiche-segment/fiche-segment.component';
import {FicheEditSegmentComponent} from './segment/fiche-edit-segment/fiche-edit-segment.component';
import {ListeTagsComponent} from './parametre/liste-tags/liste-tags.component';
import {PersonneLinkedComponent} from './repertoire/personne-linked/personne-linked.component';
import {RappelComponent} from './repertoire/rappel/rappel.component';
import {ListeChampsPersoComponent} from './parametre/liste-champs-perso/liste-champs-perso.component';
import {AjouterUtilisateurComponent} from './gestion-users/ajouter-utilisateur/ajouter-utilisateur.component';
import {ModifierUtilisateurComponent} from './gestion-users/modifier-utilisateur/modifier-utilisateur.component';
import {ListeUtilisateurComponent} from './gestion-users/liste-utilisateur.component';
import {SegmentResolver} from './_resolver/segment.resolver';
import {PersonneResolver} from './_resolver/personne.resolver';
import {ChampsPersoResolver} from './_resolver/champs-perso.resolver';
import {TagsResolver} from './_resolver/tags.resolver';
import {OrigineResolver} from './_resolver/origine.resolver';
import {CampaignsResolver} from './_resolver/campaigns.resolver';
import {
    ListeOriginesDeContactComponent
} from './parametre/liste-origines-de-contact/liste-origines-de-contact.component';
import {FicheCampagneComponent} from './campagne/fiche-campagne/fiche-campagne.component';
import {FicheEditCampagneComponent} from './campagne/fiche-edit-campagne/fiche-edit-campagne.component';
import {KanbanCampagneComponent} from './campagne/kanban-campagne/kanban-campagne.component';
import {FormTagComponent} from './parametre/liste-tags/form-tag/form-tag.component';
import {FormOrigineComponent} from './parametre/liste-origines-de-contact/form-origine/form-origine.component';
import {FormChampsPersoComponent} from './parametre/liste-champs-perso/form-champs-perso/form-champs-perso.component';
import {FormEvenementComponent} from './liste-evenements/form-evenement/form-evenement.component';
import {ConsommationEmailsComponent} from './parametre/consommation-emails/consommation-emails.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ModifierMotDePasseComponent} from './repertoire/modifier-mot-de-passe/modifier-mot-de-passe.component';
import {RefreshGuard} from './_guard/refresh.guard';
import {DuplicationListingComponent} from './duplication/listing/duplication-listing.component';
import {MergeComponent} from './duplication/merge/merge.component';
import {DuplicationComponent} from './duplication/duplication.component';
import {CatalogueProduitsComponent} from './parametre/products/catalogue-produits/catalogue-produits.component';
import {FormProduitComponent} from './parametre/products/form-produit/form-produit.component';
import {ProductResolver} from './_resolver/product.resolver';
import {CampagneGalerieModelesComponent} from './campagne/campagne-galerie-modeles/campagne-galerie-modeles.component';
import {ChoixModeleComponent} from './campagne/choix-modele/choix-modele.component';
import {OffreKanbanComponent} from './offre/offre-kanban/offre-kanban.component';
import {OffreComponent} from './offre/offre.component';
import {FicheEditOffreComponent} from './offre/fiche-edit-offre/fiche-edit-offre.component';
import {OffresResolver} from './_resolver/offres.resolver';
import {FicheOffreComponent} from './offre/fiche-offre/fiche-offre.component';
import {ConsentementComponent} from './repertoire/consentement/consentement.component';
import {ConfigurationGeneraleComponent} from './parametre/configuration-generale/configuration-generale.component';
import {BlockCampagneComponent} from './campagne/block-campagne/block-campagne.component';
const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
const uuidMatcher = (url: UrlSegment[]) => {
    return url.length === 1 && url[0].path.match(uuidRegex)
        ? {
            consumed: url,
            posParams: {
                uuid: new UrlSegment(url[0].path, {})
            }
        }
        : null;
};
const routes: Routes = [

    {path: 'login', component: LoginComponent},
    // Pour catch les urls qui n'ont pas de router
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    // Routes avec layout
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [RefreshGuard],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full'
            },
            {
                path: 'home', component: DashboardComponent
            },
            {
                path: 'repertoire',
                component: RepertoireComponent,
                data: {
                    ariane: 'Répertoire'
                },
                children: [
                    {
                        path: '',
                        component: ListeCartesComponent,
                        data: {
                            ariane: ''
                        }
                    },
                    {
                        path: 'gestion-des-consentements',
                        component: ConsentementComponent,
                        data: {
                            ariane: 'Gestion des consentements e-mailing'
                        }
                    },
                    {
                        path: 'creer',
                        component: CreerChoixComponent,
                        data: {
                            ariane: 'Nouvelle Personne'
                        }
                    },
                    {
                        path: 'creer/physique',
                        component: CreerPersonneComponent,
                        data: {
                            ariane: 'Nouvelle Personne Physique',
                            type: 'physique'
                        }
                    },
                    {
                        path: 'creer/morale',
                        component: CreerPersonneComponent,
                        data: {
                            ariane: 'Nouvelle Personne Morale',
                            type: 'morale'
                        }
                    },
                    {
                        path: ':id',
                        data: {
                            ariane: '@personne.pl_libelle'
                        },
                        resolve: {
                            personne: PersonneResolver
                        },
                        children: [
                            {
                                path: '',
                                data: {
                                    ariane: '@personne.pl_libelle'
                                },
                                component: FicheComponent,
                            },
                            {
                                path: 'modifier',
                                data: {
                                    ariane: 'Modifier'
                                },
                                component: ModifierPersonneComponent
                            },
                            {
                                path: 'creer/fonction',
                                component: CreerFonctionComponent,
                                data: {
                                    ariane: 'Nouvelle Fonction',
                                    type: 'physique'
                                }
                            },
                        ]
                    },
                    {
                        path: 'creer/evenement/:idPersonne',
                        component: FormEvenementComponent,
                        data: {
                            ariane: 'Nouveau evenement',
                        }
                    },
                    {
                        path: 'modifier/evenement/:idEvenement',
                        component: FormEvenementComponent,
                        data: {
                            ariane: 'Modification de l\'événement',
                        }
                    }
                ]
            },

            {
                path: 'doublons',
                children: [
                    {
                        matcher: uuidMatcher,
                        component: MergeComponent,
                        data: {
                            ariane: 'Traitement de doublon'
                        },
                    },
                    {
                        path: ':level',
                        component: DuplicationComponent,
                        data: {
                            ariane: 'Gestion des doublons'
                        },
                        children: [
                            {
                                path: '',
                                component: DuplicationListingComponent
                            },
                            {
                                path: ':uuid',
                                component: MergeComponent,
                                data: {
                                    ariane: 'Traitement de doublon'
                                },
                            },
                            {
                                path: ':similaritiesCode/:uuid',
                                component: MergeComponent,
                                data: {
                                    ariane: 'Traitement de doublon'
                                },
                            }
                        ]
                    },
                    {
                        path: '',
                        redirectTo: 'all',
                        pathMatch: 'full',
                    }
                ]
            },
            {
                path: 'segment', component: SegmentComponent,
                data: {
                    ariane: 'Segments'
                },
                children: [
                    {
                        path: '',
                        component: ListeCartesSegmentComponent,
                        data: {
                            ariane: ''
                        }
                    },
                    {
                        path: 'creer',
                        component: FicheEditSegmentComponent,
                        data: {
                            ariane: 'Nouveau segment'
                        },
                    },
                    {
                        path: ':id',
                        component: FicheSegmentComponent,
                        data: {
                            ariane: '@segment.titre'
                        },
                        resolve: {
                            segment: SegmentResolver
                        }
                    },
                    {
                        path: ':id/modifier',
                        component: FicheEditSegmentComponent,
                        data: {
                            ariane: 'Modifier'
                        },
                        resolve: {
                            segment: SegmentResolver
                        }
                    },
                    {
                        path: ':id/duplicate',
                        component: FicheEditSegmentComponent,
                        data: {
                            ariane: 'Dupliquer'
                        }
                    }
                ]
            },
            {
                path: 'campagne',
                component: CampagneComponent,
                data: {
                    ariane: 'Campagne'
                },
                children: [
                    {
                        path: '',
                        component: KanbanCampagneComponent,
                        data: {
                            ariane: ''
                        }
                    },
                    {
                        path: 'choix-modele',
                        component: ChoixModeleComponent,
                        data: {
                            ariane: 'Nouvelle campagne'
                        },
                        children: [
                            {
                                path: 'catalogue',
                                component: CampagneGalerieModelesComponent,
                                data: {
                                    ariane: 'Catalogue de modèles'
                                }
                            }
                        ]
                    },
                    {
                        path: 'ajouter',
                        component: FicheEditCampagneComponent,
                        data: {
                            ariane: 'Créer une nouvelle campagne'
                        },
                        children: [
                            {
                                path: ':uuid',
                                component: FicheEditCampagneComponent,
                                data: {
                                    ariane: 'Créer une nouvelle campagne à partir d\'un modèle'
                                }
                            }
                        ]
                    },
                    {
                        path: ':id',
                        component: BlockCampagneComponent,
                        resolve: {
                            campagne: CampaignsResolver
                        },
                        data: {
                            ariane: '@campagne.libelle'
                        },
                        children: [
                            {
                                path: '',
                                component: FicheCampagneComponent,
                            },
                            {
                                path: 'modifier',
                                component: FicheEditCampagneComponent,
                                data: {
                                    ariane: 'Modifier'
                                },
                            }
                        ]
                    }
                ]
            },
            {
                path: 'offre',
                component: OffreComponent,
                data: {
                    ariane: 'Offre'
                },
                children: [
                    {
                        path: '',
                        component: OffreKanbanComponent,
                        data: {
                            ariane: ''
                        }
                    },
                    {
                        path: 'ajouter',
                        component: FicheEditOffreComponent,
                        data: {
                            ariane: 'Créer une nouvelle offre'
                        }
                    },
                    {
                        path: ':id',
                        component: FicheOffreComponent,
                        data: {
                            ariane: '@offre.libelle'
                        },
                        resolve: {
                            offre: OffresResolver
                        },
                        children: [
                            {
                                path: 'modifier',
                                component: FicheEditOffreComponent,
                                data: {
                                    ariane: 'Modifier'
                                },
                            }
                        ]
                    }
                ]
            },
            {path: 'statistique', component: StatistiqueComponent},
            {
                path: 'utilisateurs',
                data: {
                    ariane: 'Paramètre du cabinet'
                },
                children: [
                    {
                        path: '',
                        component: ListeUtilisateurComponent,
                        data: {
                            ariane: 'Utilisateurs'
                        }
                    },
                    {
                        path: 'creer',
                        component: AjouterUtilisateurComponent,
                        data: {
                            ariane: 'Ajouter un utilisateur'
                        }
                    },
                    {
                        path: ':id',
                        component: ModifierUtilisateurComponent,
                        data: {
                            ariane: 'Modifier un utilisateur'
                        }
                    },
                    {
                        path: ':id/mon-compte',
                        component: ModifierUtilisateurComponent,
                        data: {
                            ariane: 'Modifier mon compte'
                        }
                    },
                    {
                        path: ':id/mot-de-passe',
                        component: ModifierMotDePasseComponent,
                        data: {
                            ariane: 'Modification de passe'
                        }
                    }
                ]
            },
            {
                path: 'parametre/campagne/consommation',
                component: ConsommationEmailsComponent,
                data: {
                    ariane: 'Consommation e-mails'
                }
            },
            {
                path: 'tag',
                data: {
                    ariane: 'Paramètre du Cabinet'
                },
                children: [
                    {
                        path: 'creer',
                        component: FormTagComponent,
                        data: {
                            ariane: 'Créer un nouveau mot clé'
                        }
                    },
                    {
                        path: 'modifier',
                        data: {
                            ariane: 'Mot clé',
                            noRouting: true
                        },
                        children: [
                            {
                                path: ':id',
                                component: FormTagComponent,
                                data: {
                                    ariane: '@tags.libelle'
                                },
                                resolve: {
                                    tags: TagsResolver
                                }
                            }
                        ]
                    },
                    {
                        path: '',
                        component: ListeTagsComponent,
                        data: {
                            ariane: 'Mots clés'
                        }
                    }
                ]
            },
            {
                path: 'champs-personnalise',
                data: {
                    ariane: 'Paramètres du Cabinet'
                },
                children: [
                    {
                        path: 'creer',
                        component: FormChampsPersoComponent,
                        data: {
                            ariane: 'Créer un nouveau champ personnalisé'
                        }
                    },
                    {
                        path: ':id',
                        component: FormChampsPersoComponent,
                        data: {
                            ariane: '@champPerso.libelle'
                        },
                        resolve: {
                            champPerso: ChampsPersoResolver
                        }
                    },
                    {
                        path: '',
                        component: ListeChampsPersoComponent,
                        data: {
                            ariane: 'Champs Personnalisés'
                        }
                    }
                ]
            },
            {
                path: 'origine',
                data: {
                    ariane: 'Paramètres du Cabinet'
                },
                children: [
                    {
                        path: 'creer',
                        component: FormOrigineComponent,
                        data: {
                            ariane: 'Créer une nouvelle origine de contact'
                        }
                    },
                    {
                        path: 'modifier',
                        data: {
                            noRouting: true
                        },
                        children: [
                            {
                                path: ':id',
                                component: FormOrigineComponent,
                                data: {
                                    ariane: '@origine.libelle'
                                },
                                resolve: {
                                    origine: OrigineResolver
                                }
                            }
                        ]
                    },
                    {
                        path: '',
                        component: ListeOriginesDeContactComponent,
                        data: {
                            ariane: 'Origines de contact'
                        }
                    }
                ]
            },
            {
                path: 'personnes-liees',
                data: {
                    ariane: ''
                },
                children: [
                    {
                        path: '',
                        component: PersonneLinkedComponent,
                        data: {
                            ariane: ''
                        }
                    }
                ]
            },
            {
                path: 'parametres',
                data: {
                    ariane: 'Paramètres du Cabinet',
                    noRouting: true
                },
                children: [
                    {
                        path: 'configuration-generale',
                        component: ConfigurationGeneraleComponent,
                        data: {
                            ariane: 'Configuration générale du cabinet'
                        }
                    },

                    {
                        path: 'catalogue-produits',
                        component: CatalogueProduitsComponent,
                        data: {
                            ariane: 'Catalogue de produits et services'
                        }
                    },
                    {
                        path: 'catalogue-produits',
                        data: {
                            ariane: 'Catalogue de produits et services',
                        },
                        children: [
                            {
                                path: 'ajouter',
                                pathMatch: 'full',
                                component: FormProduitComponent,
                                data: {
                                    ariane: 'Créer un nouveau produit / service',
                                    noRouting: true
                                }
                            },
                            {
                                path: ':id',
                                pathMatch: 'full',
                                component: FormProduitComponent,
                                data: {
                                    ariane: '@product.libelle'
                                },
                                resolve: {
                                    product: ProductResolver
                                }
                            }
                        ]
                    },
                ]
            },
            {
                path: 'rappel/:id',
                component: RappelComponent,
                data: {
                    ariane: 'Vos Rappels'
                }
            },
            {path: '**', pathMatch: 'full', component: NotFoundComponent}
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
