import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'app-choix-modele',
	templateUrl: './choix-modele.component.html',
	styleUrls: ['./choix-modele.component.scss']
})
export class ChoixModeleComponent implements OnInit {

	constructor(
		public router: Router,
		private route: ActivatedRoute,
	) {
	}

	ngOnInit(): void {
	}

	navigate(uri: string){
		this.router.navigate([uri], {
			relativeTo: this.route,
			queryParamsHandling: 'merge'
		})
	}
}
