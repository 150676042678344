<div class="informations_detaillees" *ngIf="currentBreakpoint === Breakpoints.Medium ||
                                            currentBreakpoint === Breakpoints.Large ||
                                            currentBreakpoint === Breakpoints.XLarge ||
                                            currentBreakpoint === Breakpoints.Web">
    <div class="key">
        <div *ngFor="let field of AMoreField; let i = index">
            <div class="item_info">{{ field.key }}</div>
        </div>
    </div>
    <div class="value">
        <div *ngFor="let field of AMoreField; let i = index">
            <div class="item_info"
                 *ngIf="field.value !== null && field.value !== undefined && field.value !== ''; else elseBlock"
                 [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }">
                {{ field.value }}
            </div>
            <ng-template #elseBlock>
                <div class="item_info no_defined"
                     [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }">
                    - non renseigné -
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="informations_detaillees small" *ngIf="currentBreakpoint === Breakpoints.Small || currentBreakpoint === Breakpoints.XSmall">
    <div *ngFor="let field of AMoreField; let i = index" class="table_more_info_responsive">
        <div class="item_info key">{{ field.key }}</div>
        <div class="item_info"
             *ngIf="field.value !== null && field.value !== undefined && field.value !== ''; else elseBlock"
             [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }">
            {{ field.value }}
        </div>
        <ng-template #elseBlock>
            <div class="item_info no_defined"
                 [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }">
                - non renseigné -
            </div>
        </ng-template>
    </div>
</div>

