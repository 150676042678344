import {Component, OnDestroy, OnInit} from '@angular/core';
import {PersonneService} from '../_services/personne.service';
import {Router} from '@angular/router';
import {SegmentService} from '../_services/segment.service';
import {date} from '../../const-global/format-date';
import {EvenementFilter, EvenementService} from '../_services/evenement.service';
import * as moment from 'moment';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ISegment} from '../../interfaces/segment';
import {IEvenement} from '../../interfaces/evenement';
import {EvenementGlobals, PersonGlobals, VisibiliteGlobals} from '../../const-global/globals';
import {PersonneFonction} from '../../models/personnefonction';
import {Personne} from '../../models/personne';
import {CampagneService} from '../_services/campagne.service';
import {ICampagne} from '../../interfaces/campagne';
import {Filter} from '../_services/personne-lien.service';
import IHydraCollection from '../../interfaces/hydra-collection';
import {finalize} from 'rxjs/operators';
import {ExceptionService} from '../_services/exception.service';
import {Subscription} from "rxjs";
import {Moment} from 'moment/moment';
import {TitleCasePipe} from "@angular/common";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [TitleCasePipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
    public readonly PERSON_GLOBALS = PersonGlobals;
    public readonly EVENEMENT_GLOBALS = EvenementGlobals;
    public readonly VISIBILITY_GLOBALS = VisibiliteGlobals;
    private segment: ISegment;
    public segments: Array<ISegment>;
    public personnes: Array<Personne>;
    public personnesIncomplete: Array<Personne>;
    public events: Array<IEvenement> = [];
    public dataEventAPI: IHydraCollection<IEvenement>;

    public fullDate = date.fullDate;
    public fullDateHeure = date.fullDateHeure;
    public heureMinute = date.heureMinute;

    public nbDonneeManquante: number;
    public nbEventAFaire: number;
    public nbPersonne: number;
    public uuidPersonneLienConnecte: string;
    public nbFicheIncomplete: number;
    public statsProspectPublic: number;
    public statsProspectPrive: number;
    public statsClientPublic: number;
    public statsClientPrive: number;

    public campagnes = [];

    // Paramètres
    private offset = 0;
    private limitSegments = 3;
    private limitContacts = 6;
    private limit = 6;
    private order = 'DESC';
    private myContacts = 'true';
    private recherche = '';
    private typeFiltre = '';
    private statutFiltre = '';
    private activeFiltre = 'true';
    private isComplete = 'false';
    public isLoading: any;

    public filterEvent: EvenementFilter = {
        page: 1,
        itemsPerPage: 10,
        actif: 1,
        statut: 1,
        'userAssigne': ''
    };

    public filterPersonne: Filter = {
        itemsPerPage: 28,
        page: 1,
        search: '',
        'statut.libelle[]': [],
        'type[]': [],
        'visibilite[]': [],
        segment: '',
        active: null
    };

    public statuts = [
        'planifie',
        'aujourdhui',
        'enRetard',
        'termine'
    ];
    private subscription: Subscription;

    constructor(
        private personneService: PersonneService,
        private router: Router,
        private segmentService: SegmentService,
        private evenementService: EvenementService,
        private campagneService: CampagneService,
        private ngxService: NgxUiLoaderService,
        private errorService: ExceptionService,
        private titleCasePipe: TitleCasePipe,
    ) {
    }


    ngOnInit(): void {
        this.getStats();
        this.fetchEvenement();
    }

    shortNumber(nb: number): string {
        if (nb > 1000) {
            return (nb / 1000).toFixed(1) + 'K';
        }

        return nb.toString();
    }

    getStats(): void {
        this.evenementService.getNbEventRappel(true).subscribe();
        this.personneService.getCountPersonne(true, true, true).subscribe(count => {
            this.nbPersonne = count;
            this.statsProspectPublic = count.statsProspectDashboard.public;
            this.statsProspectPrive = count.statsProspectDashboard.prive;

            this.statsClientPublic = count.statsClientDashboard.public;
            this.statsClientPrive = count.statsClientDashboard.prive;

        });
        this.personneService.getCountIncompleteProfils(true).subscribe(value => {
            this.nbFicheIncomplete = value;
        });
    }

    fetchEvenement(): void {
        this.ngxService.startLoader('list');
        this.isLoading = true;
        const evts = new Array<IEvenement>();
        this.personneService.getCurrentUser().subscribe(
            (user: PersonneFonction) => {
                this.uuidPersonneLienConnecte = user.plUuid;
                this.filterEvent['userAssigne'] = "me";
                // liste evenements
                this.filterEvent.actif = 1;
                this.filterEvent.statut = 1;
                this.filterEvent.statut = 1;
                this.filterEvent.itemsPerPage = 6;
                this.evenementService.getListeEvenementsByUserApiPlateforme(this.uuidPersonneLienConnecte, this.filterEvent)
                    .pipe(finalize(() => {
                        this.ngxService.stopLoader('list');
                        this.isLoading = false;
                    }))
                    .subscribe(data => {
                            this.dataEventAPI = data;
                            data['hydra:member'].forEach(evenement => {
                                this.formatDateEvent(evenement);
                                evts.push(evenement);
                            });
                            this.events = evts;
                        },
                        err => {
                            this.errorService.statutErreur(err, true);
                        }
                    );
                this.evenementService.getNbEventRappel().subscribe(count => {
                    this.nbEventAFaire = count.nbEventRappel;
                });
            },
            err => {
                console.error(err);
            }
        );

        // info segments
        this.segmentService.getSegmentsListe(
            this.offset.toString(),
            this.limitSegments.toString(),
            this.recherche,
            this.order,
            this.myContacts
        ).subscribe(
            data => {
                this.segments = data;
            },
            err => {
                console.log(err);
            }
        );

        // liste fiches incompletes
        this.personneService.getPersonnesListe(
            this.offset.toString(),
            this.limitContacts.toString(),
            this.recherche,
            this.typeFiltre,
            this.statutFiltre,
            this.activeFiltre.toString(),
            this.order,
            this.myContacts,
            this.isComplete
        ).subscribe(data => {
                this.personnesIncomplete = this.personneService.listeDatasPersonnes(data);
                this.nbDonneeManquante = 0;
            },
            err => {
                console.log(err);
            }
        );

        // liste contacts récents
        this.personneService.getPersonnesListe(
            this.offset.toString(),
            this.limitContacts.toString(),
            this.recherche,
            this.typeFiltre,
            this.statutFiltre,
            this.activeFiltre.toString(),
            this.order,
            this.myContacts
        ).subscribe(data => {
                this.personnes = this.personneService.listeDatasPersonnes(data);
                this.nbDonneeManquante = 0;
            },
            err => {
                console.error(err);
            }
        );

        this.campagneService.getAllCampaigns(true, this.limit, null, true).subscribe((campaings: ICampagne[]) => {
            this.campagnes = campaings.slice(0, this.limit);
        });
        this.ngxService.stop();
    }

    openFichePersonne(uuid: string): void {
        this.router.navigate(['/repertoire/' + uuid]).then(() => {
            this.subscription = this.personneService.getDatasFiche(uuid).subscribe(dataPersonne => {
                this.personneService.subjectPersonne.next(dataPersonne);
            });
        });
    }

    openFicheSegment(uuid: string): void {
        this.router.navigateByUrl('segment/' + uuid, {state: this.segment}).then(() => {
            this.segmentService.getSegment(uuid, true).subscribe(dataSegment => {
                this.segmentService.subjectSegment.next(dataSegment);
            });
        });
    }

    openCampaign(campagne: ICampagne) {
        this.router.navigate(['/campagne/' + campagne.uuid]);
    }

    openRappel(uuid: string, onglet: string): void {
        this.router.navigate(['/rappel/' + uuid], {queryParams: {onglet}});
    }

	public formatDateEvent(evenement: any): IEvenement {
		evenement.dateEcheance = this.formatDateTime(evenement.dateEcheance);
		evenement.dateCreation = this.formatDate(evenement.dateCreation);
		evenement.dateModification = this.formatDate(evenement.dateModification);
		evenement.moisEcheance = this.formatMonth(evenement.dateEcheance);

		const today = moment();
		const eventDate = moment(evenement.dateEcheance);

		if (eventDate.isSame(today, 'day')) {
			this.setEventProperties(evenement, true, false, false, EvenementGlobals.EVENT_NOW);
		} else if (eventDate.isBefore(today)) {
			const delay = this.getDelay(eventDate);
			this.setEventProperties(evenement, false, true, false, EvenementGlobals.EVENT_EN_RETARD, delay);
		} else {
			this.setEventProperties(evenement, false, false, true, EvenementGlobals.EVENT_PLANIFIE);
		}

		if (evenement.statut === 2) {
			evenement.etat = EvenementGlobals.EVENT_FAIT;
		}

		return evenement;
	}

	private formatDateTime(date: any): Moment {
		return moment(date, 'YYYY-MM-DD HH:mm');
	}

	private formatDate(date: any): Moment {
		return moment(date, 'YYYY-MM-DD');
	}

	private formatMonth(date: any): string {
		return moment(date).format('MMMM');
	}

	private getDelay(eventDate: Moment): string {
		let delay = moment().diff(eventDate, 'seconds');
		let delayUnit = 'secondes';

		if (delay > 60) {
			delayUnit = 'minutes';
			delay = moment().diff(eventDate, 'minutes');
		}

		if (delay > 60) {
			delayUnit = 'heures';
			delay = moment().diff(eventDate, 'hours');
		}

		if (delay > 24) {
			delayUnit = 'jours';
			delay = moment().diff(eventDate, 'days');
		}

		return delay + ' ' + delayUnit;
	}

	private setEventProperties(
		evenement: any,
		aujourdHui: boolean,
		enRetard: boolean,
		planifie: boolean,
		etat: string,
		delay?: string
	): void {
		evenement.aujourdhui = aujourdHui;
		evenement.enRetard = enRetard;
		evenement.planifie = planifie;
		evenement.etat = etat;

		if (delay) {
			evenement.dateEcheanceCalc = delay;
		}
	}

    filterRepertoire(filter): void {
        if (filter.statut) {
            this.filterPersonne['statut.libelle[]'] = {titre: this.titleCasePipe.transform(filter.statut), uuid: filter.statut};
        }

        if (filter.visibility) {
            filter.visibility.forEach((visibilite, index) => {
                this.VISIBILITY_GLOBALS.MAPPING.map((item) => {
                    if (visibilite === item.uuid) {
                        this.filterPersonne['visibilite[]'][index] = {titre: item.titre, uuid: item.uuid};
                    }
                });
            });
        }

        localStorage.setItem('repertoire', JSON.stringify(this.filterPersonne));
        this.router.navigate(['/repertoire']);

    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
