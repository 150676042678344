<!-- data-filter.component.ts-->
<div class="bandeau_outils repertoire">
    <div class="bloc1">
        <ng-content select="[action]"></ng-content>
    </div>
    <div class="bloc2 input_sm">
        <ng-content select="[search]"></ng-content>
    </div>
    <div class="volet_filter">
        <ng-content></ng-content>
    </div>
</div>
<div class="clear"></div>
