<jw-modal id="coordonneeModal" class="noverflow">
	<div class="bandeau_form_modal">
		<ng-container *ngIf="edit">Editer une coordonnée</ng-container>
		<ng-container *ngIf="!edit">Ajouter une coordonnée</ng-container>
	</div>
	<ng-container [formGroup]="this.form" novalidate>
		<div class="edit_data"
		     [ngSwitch]="typeId"
		>
			<div class="container input_lg">
				<label>Type de Coordonnée</label>
				<ng-select
						bindLabel="text"
						bindValue="id"
						formControlName="type_id"
						id="modalType"
						[items]="types"
						[clearable]="false"
				></ng-select>
			</div>
			<div class="container input_lg" *ngIf="typeId === COORD_GLOBALS.COORD_TYPE_TEL.id">
				<label for="modalSousType">Sous-type</label>
				<ng-select
						bindLabel="text"
						bindValue="id"
						formControlName="sous_type_id"
						id="modalSousType"
						[items]="sousTypes"
						[clearable]="false"
				></ng-select>
			</div>
			<div>
				<label *ngIf="typeId !== COORD_GLOBALS.COORD_TYPE_TEL.id && typeId !== COORD_GLOBALS.COORD_TYPE_FAX.id">Valeur</label>
				<div *ngSwitchCase="COORD_GLOBALS.COORD_TYPE_TEL.id">
					<div class="input_container phone-number">
						<div class="input_lg">
							<ng-container *ngTemplateOutlet="indicatif"></ng-container>
						</div>
						<div class="input_lg">
							<ng-container *ngTemplateOutlet="numero"></ng-container>
						</div>
					</div>
				</div>
				<div *ngSwitchCase="COORD_GLOBALS.COORD_TYPE_FAX.id">
					<div class="input_container">
						<div class="input_lg">
							<ng-container *ngTemplateOutlet="indicatif"></ng-container>
						</div>
						<div class="input_lg">
							<ng-container *ngTemplateOutlet="numero"></ng-container>
						</div>
					</div>
				</div>
				<div class="container input_lg" *ngSwitchDefault>
					<input
							formControlName="valeur"
							[ngClass]="{'background_alert_required': (this.form.get('valeur').invalid) || !this.form.get('valeur').value}"
							(keydown.enter)="onSubmitModal()"
					>
				</div>
			</div>
			<div class="container">
				<label>Définir comme {{ typeLabel | lowercase }}</label>
				<div class="btn-inline">
					<input id="mainCord"
					       name="principal"
					       type="radio"
					       class="icon"
					       formControlName="principal"
					       [value]="true"
					/>
					<label for="mainCord">Principal(e)</label>
					<input id="altCord"
					       name="principal"
					       type="radio"
					       class="icon"
					       formControlName="principal"
					       [value]="false"
					/>
					<label for="altCord">Secondaire</label>
				</div>
			</div>
			<div class="container input_lg">
				<label for="modalStatut">Statut</label>
				<ng-select
						bindValue="id"
						bindLabel="text"
						id="modalStatut"
						formControlName="statut_id"
						[items]="this.statutList"
						[clearable]="false"
						[dropdownPosition]="'top'"
				>
					<ng-template ng-label-tmp let-item="item">
						<div class="icon"
						     [ngClass]="{
                                    'times-circle red-cherry': item.id === COORD_GLOBALS.COORD_STATUT_INACTIF.id || item.id === COORD_GLOBALS.COORD_STATUT_OPTOUT.id,
                                    'check green': item.id === COORD_GLOBALS.COORD_STATUT_ACTIF.id
                                 }"
						>
							{{item.text}}
						</div>
					</ng-template>
					<ng-template ng-option-tmp let-item="item" let-index="index">
						<div class="icon"
						     [ngClass]="{
                                    'times-circle red-cherry': item.id === COORD_GLOBALS.COORD_STATUT_INACTIF.id || item.id === COORD_GLOBALS.COORD_STATUT_OPTOUT.id,
                                    'check green': item.id === COORD_GLOBALS.COORD_STATUT_ACTIF.id
                                 }"
						>
							{{item.text}}
						</div>
					</ng-template>
				</ng-select>
			</div>
			<ng-container
					*ngIf="this.form.get('type_id')?.value
					&& (this.personType === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE || this.personType === PERSON_GLOBALS.PERSON_LIEN)
					&& typeId === COORD_GLOBALS.COORD_TYPE_EMAIL.id
					&& !this.filteredPersonStatut.includes(this.personStatut)">
				<div class="input_lg" [ngClass]="{
                    'container': this.form.get('consent')?.value === COORD_GLOBALS.CONSENT.VALID.id || this.form.get('consent')?.value === COORD_GLOBALS.CONSENT.INVALID.id
                }">
					<label for="modalConsent">Consentement</label>
					<ng-select
							[items]="ConsentHelper.consents"
							bindValue="id"
							bindLabel="text"
							id="modalConsent"
							formControlName="consent"
							[clearable]="false"
							[dropdownPosition]="'top'"
					>
						<ng-template ng-label-tmp let-item="item">
							<div class="icon"
							     [ngClass]="{
                                    'times-circle red-cherry': item.id === COORD_GLOBALS.CONSENT.INVALID.id || item.id ===  COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id,
                                    'check green': item.id === COORD_GLOBALS.CONSENT.VALID.id,
                                    'question-circle grey': item.id === COORD_GLOBALS.CONSENT.ND.id
                                 }"
							>
								{{item.text}}
							</div>
						</ng-template>
						<ng-template ng-option-tmp let-item="item" let-index="index">
							<div class="icon"
							     [ngClass]="{
                                    'times-circle red-cherry': item.id === COORD_GLOBALS.CONSENT.INVALID.id ||  item.id === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id,
                                    'check green': item.id === COORD_GLOBALS.CONSENT.VALID.id,
                                    'question-circle grey': item.id === COORD_GLOBALS.CONSENT.ND.id
                                 }"
							>
								{{item.text}}
							</div>
						</ng-template>
					</ng-select>
					<div *ngIf=" this.form.get('consent')?.value === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id
                                                            && typeId === COORD_GLOBALS.COORD_TYPE_EMAIL.id"
						 class="alert"
					>ATTENTION : En passant une adresse e-mail en statut "Désabonné", celle-ci sera verrouillée et ne sera
						plus modifiable.
					</div>
				</div>
				<ng-container
						*ngIf="this.form.get('consent')?.value === COORD_GLOBALS.CONSENT.VALID.id || this.form.get('consent')?.value === COORD_GLOBALS.CONSENT.INVALID.id">
					<div class="input_lg" [ngClass]="{
                    'container': this.form.get('consent_type').value === 5
                }">
						<label for="modalConsentType">Moyen de recueil</label>
						<ng-select
								[items]="ConsentHelper.consentType"
								bindValue="id"
								bindLabel="text"
								id="modalConsentType"
								formControlName="consent_type"
								[clearable]="false"
								[dropdownPosition]="'top'"
						></ng-select>
					</div>
					<div class="input_lg" *ngIf="this.form.get('consent_type').value === 5">
						<label for="modalConsentType">Veuillez préciser ...</label>
						<input
								type="text"
								id="modalCustomConsent"
								formControlName="consent_custom_type"
						/>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>
	<div class="bloc_footer_modal">
		<div class="action_bloc_modal">
			<button type="reset" class="btn btn-med btn-fermer" (click)="cancel();">
				<span>Annuler</span>
			</button>
			<button type="submit"
			        class="btn btn-med btn-enregistrer"
			        [ngClass]="{btn_disabled: this.form.invalid}"
			        [disabled]="form.invalid"
			        (click)="onSubmitModal()">

            <span *ngIf="form.invalid; else elseValid"
                  matTooltip="Formulaire incomplet ou champ incorrect"
                  matTooltipClass="tooltip-alert"
                  aria-label="tooltip">Valider</span>
				<ng-template #elseValid>
					<span>Valider</span>
				</ng-template>
			</button>
		</div>
	</div>
	<!-- NG TEMPLATES -->
	<ng-template #numero [formGroup]="form">
		<label for="modalTel">Numéro</label>
		<input placeholder="Ex : 07xxxxxx96"
		       type="text"
		       id="modalTel"
		       formControlName="valeur"
		       [ngClass]="{'background_alert_required': this.form.get('valeur').invalid}"
		       (keydown.enter)="onSubmitModal()"
		/>
	</ng-template>
	<ng-template #indicatif [formGroup]="form">
		<label for="modalIndicatifTel">Indicatif</label>
		<ng-select
				bindValue="id"
				bindLabel="text"
				formControlName="indicatif"
				id="modalIndicatifTel"
				[items]="indicatifData | async"
				[clearable]="false"
		></ng-select>
	</ng-template>
</jw-modal>
