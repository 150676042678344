import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {ExceptionService} from '../_services/exception.service';
import {Location} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AccessInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private location: Location,
        private exService: ExceptionService,
    ) {
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 403 && '/home' !== this.location.path()) {
                this.exService.statutErreur(err, true);
                return EMPTY;
        }
        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.headers.has('Authorization')) {
            return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
        }

        return next.handle(req);
    }
}
