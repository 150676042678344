<div [formGroup]="form" class="input_lg">
    <ng-select
            bindLabel="text"
            bindValue="id"
            [compareWith]="compareWith"
            [multiple]="multiple"
            [items]="data"
            [formControlName]="id"
    ></ng-select>
</div>
