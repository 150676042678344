// tslint:disable-next-line:max-line-length
import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {Observable, Subscription} from 'rxjs';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../_services/exception.service';
import {HelperService} from '../../_services/helper.service';
import {CoordonneService} from '../../_services/coordonne.service';
import {AdresseService} from '../../_services/adresse.service';
import {VisibiliteService} from '../../_services/visibilite.service';
import {IUser} from '../../../interfaces/user';
import {MessageGlobals} from '../../../const-global/messages';
import {UserService} from '../../_services/user.service';
import {Personne} from '../../../models/personne';
import {Location} from '@angular/common';
import {ParametreGlobals, PersonGlobals} from '../../../const-global/globals';
import {date} from '../../../const-global/format-date';
import {ModalConfirmExitComponent} from '../../layout/modal-confirm-exit/modal-confirm-exit.component';
import {ModalService} from '../../_modal';
import {shareReplay} from 'rxjs/operators';
import {MemoService} from '../../_services/memo.service';

@Component({
    selector: 'app-modifier-personne',
    templateUrl: './modifier-personne.component.html',
    styleUrls: ['./modifier-personne.component.scss']
})
export class ModifierPersonneComponent implements OnInit, AfterViewInit {
    public readonly PERSON_GLOBAL = PersonGlobals;
    public form: FormGroup;
    public champs: any; // champs requis, required etc [{}]
    public message: string;
    public personne: Personne;
    public valeursPersonnalises = [];
    public coordonnees = {};
    public coordonnesPM = {
        adresse: [],
        telephone: [],
        email: []
    };
    public adresses = [];
    public libelle: string;
    public emailPrincipal = 0;
    public telPrincipal = 0;
    public login: string;
    public password: string;
    public type = 'personne';
    public titre: string;
    public currentUuid: string;
    public userUuid: string;
    public blink = false;
    public fullDate = date.fullDate;
    public inputDisabled = false;
    public isAccountOwner: boolean;
    public isPasswordTemp: boolean;
    public titrePersonneLibelle: Observable<string>;
    private liens: Observable<any>;
    public hasTitre = false;
    public listPersonneAttached = [];
    public memoTerme = '';
    private modified: boolean;
    public personneActive?: boolean;
    public effectifs = this.personneService.getListeEffectif()
    @Input() user?;
    @Input() isUserProfil?: boolean;
    @Input() inputTitle: string;
    @Input() newUser?: boolean;
    @Output() resetSwitch: boolean;
    @ViewChild('confirmModal') confirmModal: ModalConfirmExitComponent;
    public tagTarget = ParametreGlobals.TAG_ID_PERSON;
	originesFetcher = () => this.personneService.getListeOrigines();

    get personneStatut(): string {
        return this.form.get('statut').value;
    }

    constructor(
        private personneService: PersonneService,
        private userService: UserService,
        private ref: ChangeDetectorRef,
        public router: Router,
        public route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private helperService: HelperService,
        private coordonneeService: CoordonneService,
        private adresseService: AdresseService,
        private visibiliteService: VisibiliteService,
        private notifier: NotifierService,
        private location: Location,
        private modalService: ModalService,
        private memoService: MemoService
    ) {
    }

	ngOnInit(): void {
		this.titrePersonneLibelle = new Observable(observer => {
			observer.next('');
			observer.complete();
		});
		if (this.newUser) {
			this.personne = this.personneService.datasPersonne({
				type: PersonGlobals.PERSON_LIEN_PHYSIQUE
			});
			this.titrePersonneLibelle = new Observable(observer => {
				observer.next(this.personne.titre);
				observer.complete();
			});
			this.hasTitre = true;
			this.form = this.formBuilder.group({
				login: [{value: null, disabled: false}, Validators.required],
				nom: [this.personne?.nom, Validators.required],
				prenom: [this.personne?.prenom, Validators.required],
				civilite: [null, Validators.required],
				userProfil: [null, Validators.required],
				titre: [this.personne?.titre || ''],
				uuid: [],
				adresses: []
			}, {});
			this.coordonnees = [];
			this.form.get('nom').valueChanges.subscribe(nom => {
				this.personne.nom = nom;
				this.updatePersonneLibelle();
			});
			this.form.get('prenom').valueChanges.subscribe(prenom => {
				this.personne.prenom = prenom;
				this.updatePersonneLibelle();
			});
		} else {
			if (this.user) {
				this.currentUuid = this.user.plUuid;
			} else if (this.route?.parent?.snapshot.params.id) {
				this.currentUuid = this.route.parent.snapshot.params.id;
			} else {
				this.currentUuid = this.route.snapshot.params.id;
			}
			this.getPersonne();
		}

		this.personneService.getCurrentUser().subscribe((dataPersonneCourante: IUser) => {
			this.isAccountOwner = (dataPersonneCourante.uuid === this.user?.ppUuid);
		});
		this.userService.isPasswordTemp().subscribe((status) => {
			this.isPasswordTemp = status;
		});
	}

	ngAfterViewInit(): void {
		this.setMemoValue();
		this.coordonneeService.personUpdatedSubject.subscribe(statut => {
			if (statut === true) {
				this.confirmModal.modified.next(true);
			}
			this.blink = !!statut;
		});
		this.confirmModal.modified.subscribe(v => {
			this.modified = !!v;
		});
	}

	getDataPersonneCourante(): Subscription {
		return this.personneService.getCurrentUser().subscribe((dataPersonneCourante: IUser) => {
			this.login = dataPersonneCourante.login;
			this.isAccountOwner = (dataPersonneCourante.uuid === this.user?.ppUuid);
		}, err => {
			this.showNotification('error', this.exceptionService.statutErreur(err));
		});
	}

	getPersonne(): Subscription {
		return this.personneService.getDatasFiche(this.currentUuid).subscribe(data => {

            // récupération des coordonnées de la personne morale
            if (data['personneMorale'] && data['personneMorale']['lien']) {
                this.coordonnesPM = this.getCoordonneesPM(data['personneMorale']['lien']);
            }

            this.personneService.subjectPersonne.next(data);
            this.personne = this.personneService.datasPersonne(data);
            this.titrePersonneLibelle = new Observable(observer => {
                observer.next(this.personne.titre);
                observer.complete();
            });
            this.hasTitre = true;
            if (this.personne.type === 'lien') {
                this.inputDisabled = true;
            }
            if (this.personne.type === PersonGlobals.PERSON_LIEN) {
                if (this.personne.mail !== null && this.personne.telephone !== null) {
                    this.personne.isComplete = true;
                }
            }
			if (this.isUserProfil) {
				this.userUuid = this.user.uuid;
				this.login = this.user.login;
				this.form = this.formBuilder.group({
					login: [{value: this.user.login, disabled: true}, Validators.required],
					nom: [this.user.nom, Validators.required],
					prenom: [this.user.prenom, Validators.required],
					civilite: [this.user.civilite, Validators.required],
					userProfil: ['', Validators.required],
					actif: null,
					coordonnees: [null],
					personalises: [],
					personnePhysique: this.formBuilder.group({
						titre: this.user?.titre,
						origine: null,
					}),
					uuid: [this.user.plUuid],
					adresses: [''],
					isReferent: [this.user.referent],
				}, {});
			} else {
                if (this.personne.type === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
                    this.getDataPersonneCourante();
                    this.personneService.getListeChampsCreationPP().subscribe(
                        dataPhysique => {
                            this.champs = dataPhysique;
                        });
                    this.formPersonnePhysique();
                }
                if (this.personne.type === PersonGlobals.PERSON_LIEN_MORALE) {
                    this.personneService.getListeChampsCreationPM().subscribe(
                        dataMorale => {
                            this.champs = dataMorale;
                        });
                    this.formPersonneMorale();
                }
                if (this.personne.type === PersonGlobals.PERSON_LIEN) {
                    this.personneService.getListeChampsCreationPF().subscribe(
                        dataLien => {
                            this.champs = dataLien;
                        });
                    this.formPersonneLien();
                }
            }

                this.personneService.getCoordonnees(this.personne.uuid).subscribe(coordonnees => {
                        this.coordonneeService.coordonneeSubject.next(coordonnees);
                    },
                    err => {
                        console.log(err);
                    }
                );
                this.personneService.getAdresses(this.personne.uuid).subscribe(
                    address => {
                        this.adresseService.addressSubject.next(address);
                    },
                    err => {
                        console.log(err);
                    }
                );
                this.adresseService.addressSubject.subscribe((address) => {
                    this.adresses = address;
                });
                this.visibiliteService.sendData(this.personne.uuid, this.type);
                this.form.patchValue(data);

                this.liens = this.personneService.getPersonneLiens(this.personne.uuid).pipe(
                    shareReplay()
                );

				this.form.controls.actif.valueChanges.subscribe(changes => {
					if (!changes) {
						this.liens.subscribe(liens => {
							if (this.listPersonneAttached.length === 0) {
								this.listPersonneAttached = liens.filter(onglet => onglet.type === PersonGlobals.PERSON_LIEN);
							}
						});
						if (this.personne.type !== PersonGlobals.PERSON_LIEN) {
                            this.modalService.open('desactivationCascade');
                        }
					}
				});
			}, err => {
				this.showNotification('error', this.exceptionService.statutErreur(err));
            });
    }

    private getCoordonneesPM(data) {
        this.coordonnesPM.adresse = data['adressePrincipal'] ? data['adressePrincipal']['adresse'] : null;
        this.coordonnesPM.email = data['mailPrincipal'] ? data['mailPrincipal']['mail'] : null
        this.coordonnesPM.telephone = data['telephonePrincipal'] ? data['telephonePrincipal']['libelle'] : null;

        return this.coordonnesPM;
    }

	formPersonneMorale(): void {
		this.form = this.formBuilder.group({
			visibilite: ['', Validators.required],
			raisonSociale: ['', Validators.required],
			formeJuridique: ['', Validators.required],
			qualite: null,
			civilite: [''],
			prenom: [''],
			nom: [''],
			actif: [''],
			memos: [''],
			statut: [''],
			apporteur: null,
			isApporteur: [''],
			uuid: [''],
			tags: [''],
			personnalises: [],
			coordonnees: [''],
			adresses: [],
			siret: ['', Validators.pattern('^[0-9]{14}$')],
			codeNaf: [this.personne?.codeNaf],
			codeNace: [this.personne?.codeNace],
			tvaCom: ['', Validators.pattern('^\\w{2}[0-9]{11}$')],
			effectif: [''],
			chiffreAffaire: [''],
			capital: ['', Validators.pattern('^[0-9]{1,}$')],
			personneMorale: this.formBuilder.group({
				parent: [],
				apporteur: []
			}),
			fonctionPersonnalisee: [''],
			intervenants: [''],
			referent: [null],
			fonction: [''],
		}, {});
	}

	formPersonnePhysique(): void {
		this.form = this.formBuilder.group({
			personnePhysique: this.formBuilder.group({
				titre: null,
				origine: null,
			}),
			visibilite: ['', Validators.required],
			raisonSociale: [''],
			formeJuridique: [''],
			civilite: ['', Validators.required],
			prenom: ['', Validators.required],
			nom: ['', Validators.required],
			qualite: null,
			actif: [''],
			memos: [''],
			statut: [''],
			apporteur: [''],
			isApporteur: [''],
			uuid: [''],
			tags: [''],
			personnalises: [],
			coordonnees: [''],
			adresses: [],
			siret: [''],
			codeNaf: [''],
			codeNace: [''],
			effectif: [''],
			capital: [''],
			chiffreAffaire: [''],
			organisationParente: [''],
			fonctionPersonnalisee: [''],
			fonction: [''],
			intervenants: [''],
			referent: [null],
			infoCommerciale: [],
			login: [this.login],
			password: [''],
			repeatPassword: [''],
			oldPassword: ['']
		}, {});
	}

	formPersonneLien(): void {
		this.form = this.formBuilder.group({
			personnePhysique: this.formBuilder.group({
				origine: new FormControl({value: null, disabled: true}),
			}),
			qualite: new FormControl({value: null, disabled: true}),
			fonction: ['', Validators.required],
			fonctionPersonnalisee: [''],
			visibilite: [''],
			raisonSociale: [''],
			formeJuridique: [''],
			civilite: [''],
			prenom: [''],
			nom: [''],
			actif: [''],
			memos: [''],
			statut: new FormControl({value: null, disabled: true}),
			apporteur: new FormControl({value: null, disabled: true}),
			isApporteur: [''],
			origine: [''],
			uuid: [''],
			tags: new FormControl({value: null, disabled: true}),
			personnalises: [],
			coordonnees: [''],
			adresses: [],
			capital: [''],
			siret: [''],
			codeNaf: [''],
			codeNace: [''],
			effectif: [''],
			chiffreAffaire: [''],
			organisationParente: [''],
			intervenants: [''],
			referent: [null],
			infoCommerciale: [],
		}, {});
	}

	public showNotification(type: string, message: string): void {
		setTimeout(() => {
			this.notifier.notify(type, message);
		}, 1000);
	}

	public onSubmit(): void {
		if (this.isUserProfil || this.newUser) {
			this.updateUser();
		} else {
			this.updatePersonne();
		}
		this.confirmModal.modified.next(false);
	}

	public onCancel(): void {
		if (this.modified) {
			this.confirmModal.openConfirmationModal();
		}
		if (!this.confirmModal.isOpened()) {
			if (this.isUserProfil || this.newUser) {
				this.router.navigate(['utilisateurs']);
			} else {
				this.router.navigate(['repertoire/' + this.currentUuid]);
			}
		}
	}

	public onSubmitCancel(): void {
		this.onSubmit();
		if (this.form.valid) {
			this.onCancel();
		}
	}

	private updateUser(): void {
		this.ngxService.start();
		if (this.form.valid) {
			const formData = this.form.value;
			formData.personnalises = this.valeursPersonnalises;
			const uuid = (this.route.snapshot.params.id) ? this.route.snapshot.params.id : 'new';
			this.userService.update(uuid, this.form.value).subscribe(() => {
					this.showNotification('success', MessageGlobals.MESSAGE_MODIFICATION);
					this.userService.rightTreeRefresh.next();
				},
				err => {
					this.ngxService.stop();
					this.showNotification('error', this.exceptionService.statutErreur(err));
				});
		} else {
			this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
		}
		this.ngxService.stop();
	}

	private updatePersonne(): void {
		this.ngxService.start();
		const formData = this.form.value;
		formData.uuid = this.personne.uuid;
		formData.personnalises = this.valeursPersonnalises;
		formData.coordonnees = this.coordonnees;
		formData.adresses = this.adresses;

		if (this.form.valid) {
			this.personneService.updatePersonne(this.personne.uuid, formData).subscribe(() => {
					if (this.memoTerme !== '') {
						this.memoService.add(this.memoTerme, this.personne.uuid, true).subscribe(() => {
							this.getPersonne();
							this.memoService.subjectMemo.next('');
						});
					} else {
						this.getPersonne();
					}
					this.showNotification('success', MessageGlobals.MESSAGE_MODIFICATION);
					this.ngxService.stop();
				},
				err => {
					this.ngxService.stop();
					this.showNotification('error', this.exceptionService.statutErreur(err));
				}
			);
		} else {
			this.ngxService.stop();
			this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
		}
	}

	onChangePersonnalise($event): void {
		this.valeursPersonnalises = $event;
	}

	onChangeCoordonnees($event): void {
		let foundEmail = false;
		let foundTel = false;
		this.coordonnees = $event;
		if ($event.emails) {
			$event.emails.forEach(email => {
				if (email.principal === true) {
					this.emailPrincipal = 1;
					foundEmail = true;
				}
			});
			if (foundEmail === false) {
				this.emailPrincipal = 0;
			}
		}

		if ($event.telephones) {
			$event.telephones.forEach(telephone => {
				if (telephone.principal === true) {
					this.telPrincipal = 1;
					foundTel = true;
				}
			});
			if (foundTel === false) {
				this.telPrincipal = 0;
			}
		}
	}

	setAdressePrincipal($event: string): void {
		this.adresses.forEach((item, index) => {
			this.adresses[index].principal = (item.uuid === $event);
		});
		this.adresseService.addressSubject.next(this.adresses);
	}

	public showDeletionConfirmationModal(positionSwitch): void {
		this.personneActive = positionSwitch;
		if (positionSwitch === false) {
			this.modalService.open('confirmDeletionModal');
		}
		this.form.controls.actif.setValue(positionSwitch);
	}

	private updatePersonneLibelle(): void {
		const prenom = this.personne?.prenom || '';
		const nom = this.personne?.nom || '';
		this.personne.personnePhysiqueLibelle = prenom + ' ' + nom.toUpperCase();
	}

	/** fired when CSS animation ends */
	animationEnd(): void {
		this.coordonneeService.personUpdatedSubject.next(false);
	}

	public confirmDeletion(): void {
		const formData = {
			apporteur: this.form.controls.apporteur.value ? this.form.controls.apporteur.value : null,
			isApporteur: this.form.controls.isApporteur.value,
			civilite: this.form.controls.civilite.value,
			nom: this.form.controls.nom.value,
			origine: this.form.controls.origine.value ? this.form.controls.origine.value : null,
			prenom: this.form.controls.prenom.value,
			statut: this.form.controls.statut.value ? this.form.controls.statut.value : null,
			titre: this.form.controls.titre?.value ? this.form.controls.titre?.value : null,
			qualite: this.form.controls.qualite.value ? this.form.controls.qualite.value : null,
			uuid: this.personne.uuid,
			visibilite: this.form.controls.visibilite.value,
			actif: this.personneActive,
			referent: this.form.controls.referent.value,
			intervenants: this.form.controls.intervenants.value,
			tags: this.form.controls.tags.value ? this.form.controls.tags.value : null,
			memos: this.form.controls.memos.value,
			personnalises: this.form.controls.personnalises.value || [],
			coordonnees: this.coordonnees,
			adresses: this.adresses,
			raisonSociale: this.form.controls.raisonSociale.value,
			formeJuridique: this.form.controls.formeJuridique.value ? this.form.controls.formeJuridique.value : null,
			capital: this.form.controls.capital.value,
			siret: this.form.controls.siret.value,
			codeNaf: this.form.controls.codeNaf.value,
			codeNace: this.form.controls.codeNace.value,
			tvaCom: this.form.get('tvaCom')?.value,
			effectif: this.form.controls.effectif.value ? this.form.controls.effectif.value : null,
			chiffreAffaire: this.form.controls.chiffreAffaire.value ? this.form.controls.chiffreAffaire.value : null,
			organisationParente: this.form.controls.organisationParente.value ? this.form.controls.organisationParente.value : null,
			fonction: this.form.controls.fonction.value,
			fonctionPersonnalisee: this.form.controls.fonctionPersonnalisee.value
		};

		this.personneService.updatePersonne(this.personne.uuid, formData).subscribe(
			(uuids: any) => {
				const uuid = uuids?.content || '';
				this.ngxService.stop();
				this.router.navigate(['/repertoire/']).then(() => {
					this.showNotification('success', MessageGlobals.MESSAGE_DESACTIVATION);
				});
			},
			err => {
				this.ngxService.stop();
				this.showNotification('error', this.exceptionService.statutErreur(err));
			}
		);

		this.modalService.close('confirmDeletionModal');
	}

	/**
	 * Permet de fermer une modale
	 */
	public closeModal(modalId: string): void {
		this.personneService.resetSubject.next(true);
		this.modalService.close(modalId);
	}

	public setMemoValue(): void {
		this.memoService.subjectMemo.subscribe(value => {
			this.memoTerme = value;
		});
	}
}
