import {SelectOption} from "../../interfaces/select-option";

export class ConsentHelper {
    public static readonly statut: SelectOption[] = [
        {id: 1, text: "Actif"},
        {id: 2, text: "Invalide"},
    ];
    public static readonly consentType: SelectOption[] = [
        {id: 1, text: 'E-mail'},
        {id: 2, text: 'Écrit'},
        {id: 3, text: 'Appel enregistré'},
        {id: 4, text: 'Site internet'},
        {id: 5, text: 'Autre'}
    ];

    public static readonly consents: SelectOption[] = [
        {id: 1, text: 'Oui'},
        {id: 2, text: 'Non'},
        {id: 3, text: 'Désabonné'},
        {id: 0, text: 'Non-renseigné'}
    ];

    public static readonly filteredPersonStatut: Array<string> = [
        'membre',
        'partenaire',
        'adverse'
    ];

    public static getConsentTypeById(id) {
        return ConsentHelper.consentType.filter(elem => elem.id === id);
    }
}
