import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-types-event-select2',
    templateUrl: './types-event-select2.component.html',
    styleUrls: ['./types-event-select2.component.scss']
})
export class TypesEventSelect2Component implements OnInit{

    @Output() typeEvent = new EventEmitter<string>();
    @Output() rechercheEvent = new EventEmitter<string>();
    items = [];

    ngOnInit() {
        this.items = [
            {uuid: '10', libelle: 'Appel entrant'},
            {uuid: '11', libelle: 'Appel sortant'},
            {uuid: '20', libelle: 'E-Mail entrant'},
            {uuid: '21', libelle: 'E-Mail sortant'},
            {uuid: '30', libelle: 'Rendez-vous cabinet'},
            {uuid: '31', libelle: 'Rendez-vous extérieur'},
            {uuid: '40', libelle: 'Devis'},
            {uuid: '50', libelle: 'Changement de statut'}
        ];
    }

    public valueChangeType(types: any): void {
        if (!types) {
            this.typeEvent.emit(null);
        } else {
            this.typeEvent.emit(types.uuid);
        }
    }

}
