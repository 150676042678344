<div class="checkbox_fiche_disabled">
    <input type="checkbox"
           class="switch"
           id="activeCampaigns"
           [checked]="this.activeCampaigns"
           (change)="toggle()"
    >
    <label for="activeCampaigns"
           class="switch icon active"
           matTooltip="{{MESSAGE_GLOBALS.MESSAGE_CAMPAGNE_ACTIVE}}"
           matTooltipClass="tooltip-default"
           aria-label="tooltip"
           *ngIf="this.activeCampaigns === true">
    </label>
    <label for="activeCampaigns"
           class="switch icon inactive"
           matTooltip="{{MESSAGE_GLOBALS.MESSAGE_CAMPAGNE_INACTIVE}}"
           matTooltipClass="tooltip-default"
           aria-label="tooltip"
           *ngIf="this.activeCampaigns === false">
    </label>
</div>
