'use strict';

export class ChampsPersoGlobals {
    static readonly CHAMP_LIBRE_SINGLE_LINE = {
        text: 'Texte libre (une ligne)',
        id: '10'
    };
    static readonly CHAMP_LIBRE_MULTIPLE_LINE = {
        text: 'Texte libre (plusieurs lignes)',
        id: '30'
    };
    static readonly CHAMP_DATE = {
        text: 'Date',
        id: '40'
    };
    static readonly CHAMP_DATE_TIME = {
        text: 'Date et Heure',
        id: '41'
    };
    static readonly CHAMP_EMAIL = {
        text: 'E-mail',
        id: '11'
    };
    static readonly CHAMP_TEL_FAX = {
        text: 'Téléphone/Fax',
        id: '12'
    };
    static readonly CHAMP_LISTE_MULTIPLE = {
        text: 'Liste déroulante multiple',
        id: '23'
    };
    static readonly CHAMP_LISTE = {
        text: 'Liste déroulante',
        id: '20'
    };
    static readonly CHAMP_RADIO = {
        text: 'Boutons radios',
        id: '21'
    };
    static readonly CHAMP_CHECKBOX = {
        text: 'Cases à cocher',
        id: '22'
    };
    static readonly CHAMP_INTEGER = {
        text: 'Numérique',
        id: '50'
    };
    static readonly CHAMP_MONEY = {
        text: 'Monétaire',
        id: '51'
    };
}
