import {Component} from '@angular/core';
import {SelectFilterItemComponent} from '../select-filter-item/select-filter-item.component';
import {Filter, PersonneLienService} from '../../../_services/personne-lien.service';

@Component({
    selector: 'app-personne-referent-filter',
    templateUrl: './personne-referent-filter.component.html',
    styleUrls: ['./personne-referent-filter.component.scss'],
})
export class PersonneReferentFilterComponent extends SelectFilterItemComponent {
    apiFilter: Filter = {};

    constructor(private personneService: PersonneLienService) {
        super();

        this.apiFilter.personReferent = 1;
        this.apiFilter.groups = '&PersonReferent';
        this.apiFilter['type[]'] = ['physique'];
        this.personneService.list(this.apiFilter).subscribe(data => {
            this.dataSource = data['hydra:member'];
        });
    }

    updateFilter($event: any): void {
        const values = [];
        if ($event !== null && $event.length > 0) {
            $event.forEach((item) => {
                item.uuid = item.personnePhysique.user.id;
                item.titre = item.personnePhysique.libelle;
                values.push(item);
            });
        }
        this.updated.emit({event: {}, values, filter: this.filter.attributeName});
    }
}
