<div id="login-container">
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
    <img class="login-logo" src="assets/images/logo-upsell.png" alt="logo CRM">
    <div id="login-form">
        <form
                name="form"
                (ngSubmit)="f.form.valid && onSubmit()"
                #f="ngForm"
                novalidate
        >
            <div id="connexion_form">
                <label for="login" class="label-login">Identifiant</label>
                <input
                        type="text"
                        name="login"
                        [ngClass]="{ 'form-submitted': password.errors && f.submitted }"
                        [(ngModel)]="form.login"
                        required
                        #login="ngModel"
                        id="login"
                />
                <div class="alert" role="alert" *ngIf="login.errors && f.submitted">Nom d'utilisateur requis</div>
                <label for="password" class="label-login">Mot de passe</label>
                <input
                        type="password"
                        name="password"
                        [ngClass]="{ 'form-submitted': password.errors && f.submitted }"
                        [(ngModel)]="form.password"
                        required
                        minlength="5"
                        #password="ngModel"
                        #passwordForm
                        id="password"
                />
                <div
                        class="alert alert-danger"
                        role="alert"
                        *ngIf="password.errors && f.submitted"
                >
                    <div class="alert" role="alert" *ngIf="password.errors.required && f.submitted">
                        Mot de passe requis
                    </div>
                </div>
                <div class="form-group form-connexion">
                    <div
                            class="alert alert-danger"
                            role="alert"
                            *ngIf="f.submitted && isLoginFailed"
                    >
                        Identifiant et/où mot de passe incorrect : {{ errorMessage }}
                    </div>
                </div>
                <div class="connexion">
                    <div id="login-connexion">
                        <button type="submit" class="btn btn-lg btn-connexion">
                            <span>Connexion</span>
                        </button>
                    </div>
                    <div id="login-mot-de-passe-oublie">
                        <a (click)="showHideInputMail()" class="btn btn-lg">Mot de passe oublié</a>
                    </div>
                </div>
            </div>
        </form>
        <form name="formLost"
              (ngSubmit)="formLost.valid && onSubmit()"
              #f="ngForm"
              novalidate
        >
            <div id="lost_mot_de_passe" class="d-none">
                <label for="email" class="label-login">Adresse email</label>
                <input
                        placeholder="adresse@email.com"
                        type="email"
                        name="email"
                        [(ngModel)]="formLost.login"
                        id="email"
                        [ngClass]="{ 'background_alert_required': isLoginFailed }"
                        #emailLostPassword
                />
                <div class="form-group form-connexion">
                    <div
                            class="alert alert-danger"
                            role="alert"
                            *ngIf="isLoginFailed"
                    >
                        Merci de saisir votre adresse mail
                    </div>
                </div>
                <div class="form-group form-connexion"></div>
                <div class="connexion">
                    <button type="button" (click)="sendMotDePasse()" class="btn btn-lg btn-envoyer">
                        <span>Envoyer</span>
                    </button>
                    <button type="button" (click)="showHideInputMail()" class="btn btn-lg btn-retour">
                        <span>Retour</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
