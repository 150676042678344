import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SegmentService} from '../../_services/segment.service';
import {v4 as uuidv4} from 'uuid';
import {PersonneService} from "../../_services/personne.service";

@Component({
    selector: 'app-segment-chart-custom',
    templateUrl: './segment-chart-custom.component.html',
    styleUrls: ['./segment-chart-custom.component.scss']
})
export class SegmentChartCustomComponent implements OnInit, OnChanges {

    public colorPublic = '161, 161, 161';
    public colorPrivate = '190, 190, 190';
    public opacityStart = 1;
    public opacityEnd = 0.3;

    public aOpacity = [];
    public aColorsPub = [];
    public aColorsPriv = [];

    public totalResultPublic = 0;
    public totalResultPrivate = 0;
    public initField: boolean;

    @Input() data: Array<any>;
    @Input() nbFilter: number;

    newValue = {
        id: uuidv4(),
        order: 0,
        prive: '0',
        public: '0',
        colorPublic: '',
        colorPrivate: ''
    };

    constructor(private segmentService: SegmentService, private personneService: PersonneService) {
    }

    ngOnInit(): void {
        this.initField = false;
        this.addFilter();
    }

    addFilter(): void {
        this.segmentService.segmentUpdatedSubject.subscribe(value => {
            this.initField = value;
            if (this.initField === true) {
                this.data.push(this.newValue);
                this.newValue.order = this.nbFilter;

                this.aOpacity = this.variateOpacity(this.opacityStart, this.opacityEnd, this.data.length);
                this.aColorsPub = this.generateColors(this.colorPublic, this.aOpacity);
                this.aColorsPriv = this.generateColors(this.colorPrivate, this.aOpacity);

                this.data.forEach(field => {
                    if (typeof this.aColorsPub[field.order] !== 'undefined') {
                        field.colorPublic = this.aColorsPub[field.order];
                    }
                    if (typeof this.aColorsPriv[field.order] !== 'undefined') {
                        field.colorPrivate = this.aColorsPriv[field.order];
                    }
                });
            }
            this.initField = false;
        });
    }

    // retourne une liste de valeurs selon le nombre d'element donné en paramètre
    variateOpacity(nStart, nEnd, nNbValues): Array<any> {
        const nIncrement = (nStart - nEnd) / nNbValues;
        const aValues = [];
        let nMyValue = nStart;

        for (let i = 1; i <= nNbValues - 1; i++) {
            if (i === 1) {
                aValues.push('1');
            } else {
                nMyValue = nMyValue - nIncrement;
                aValues.push(nMyValue.toFixed(2));
            }
        }

        return aValues;
    }

    // concataine une couleur rgb pour en faire une rgba
    generateColors(color, aOpacity): Array<any> {
        const aColors = [];
        aOpacity.forEach(opacity => {
            aColors.push('rgba(' + color + ', ' + opacity + ')');
        });
        return aColors;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.totalResultPublic = 0;
        this.totalResultPrivate = 0;
        this.aOpacity = this.variateOpacity(this.opacityStart, this.opacityEnd, this.data.length + 1);
        this.aColorsPub = this.generateColors(this.colorPublic, this.aOpacity);
        this.aColorsPriv = this.generateColors(this.colorPrivate, this.aOpacity);

        this.personneService.getCountAllPersonnes().subscribe((data) => {
            this.totalResultPublic = parseInt(data.public, 10);
            this.totalResultPrivate = parseInt(data.prive, 10);
        });
        this.data.forEach(field => {

            if (typeof this.aColorsPub[field.order] !== 'undefined') {
                field.colorPublic = this.aColorsPub[field.order];
            }
            if (typeof this.aColorsPriv[field.order] !== 'undefined') {
                field.colorPrivate = this.aColorsPriv[field.order];
            }
        });
    }
}
