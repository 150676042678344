<app-data-filter
        [dataFilter]="dataFilter"
        (changeFilter)="onInputFilter()"
        [messageSwitch]="messageSwitch"
></app-data-filter>

<header>
    <div *ngFor="let stat of statSimilarity.slice(1).reverse()" [routerLink]="['..', labelLevel[stat.similarityLevel]]"
         #routerLinkActiveRef="routerLinkActive" routerLinkActive="active-link"
         [className]="'similarity_level_' + labelLevel[stat.similarityLevel]"
         (click)="goToAll(routerLinkActiveRef.isActive)">
        <div class="card">
            <div class="head">
                DOUBLONS<br>{{libelleLevel[stat.similarityLevel]|uppercase}}
            </div>
            <div class="content">
                <div class="stat">
                    <div class="public">
                        <span class="icon lock-open"></span>
                        <span class="count">{{stat.publicted}}</span>
                    </div>
                    <div class="private">
                        <span class="icon lock"></span>
                        <span class="count">{{stat.private}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="card etat-du-repertoire">
            <div class="head">
                <div>
                    ÉTAT DU RÉPERTOIRE
                </div>
                <div>
                    <div class="percent">{{(this.ratioDuplicated)|percent:'1.0-1' }}</div>
                    <div style="color: #CC0000;">doublonné</div>
                </div>
            </div>

        </div>
    </div>
</header>

<div class="container">
    <section [className]="'similarity_level_' + level">
        <h3 class="head">Liste des doublons {{libelleLevel[similarityLevel]}} ({{
            5 === similarityLevel ? statAll?.distinct : statSimilarity[similarityLevel]?.distinct
            }})</h3>
        <div class="scrollable"
             infiniteScroll
             infiniteScrollDistance="2"
             infiniteScrollThrottle="50"
             [scrollWindow]="false"
             (scrolled)="onScroll()"
        >
            <div class="content">
                <div class="text-center" *ngIf="!list.length && !listLoading">
                    Aucun resultat trouvé
                </div>
                <table class="table table-hover">
                    <tbody>
                    <tr *ngFor="let similar of list" [routerLink]="[similar.similaritiesCode, similar['uuid']]"
                        [className]="(5 === similarityLevel) ? 'row_'+labelLevel[similar.similarityLevel] : ''">
                        <th scope="row" style="width: 35%">
                            <span [class]="'icon ' + ('morale' === similar.type ? 'city' : 'user_group')"></span>
                            {{similar.libelle}}
                        </th>
                        <td *ngIf="3 > similar.visibility[0]" class="public" style="width: 15%">
                            <span class="icon lock-open"></span> &nbsp; {{visibilities(similar.visibility)}}
                        </td>
                        <td *ngIf="3 === similar.visibility[0]" class="private" style="width: 15%">
                            <span class="icon lock"></span> &nbsp; {{visibilities(similar.visibility)}}
                        </td>
                        <td class="count_fiches" style="width:15%">
                            <strong>{{similar.nb + 1}} fiches </strong>
                            <span> à vérifier</span>
                        </td>
                        <td style="width:35%">
                            {{getLibelleSimilarity(similar.computedSimilarities)}}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="loader" [class.loading]="listLoading">
                    <ngx-ui-loader [loaderId]="'list'"></ngx-ui-loader>
                </div>
            </div>
        </div>
    </section>
</div>
