import {Component, Input, OnInit} from '@angular/core';
import {ILead} from "../../../interfaces/lead";
import {PersonGlobals} from "../../../const-global/globals";
import {Router} from "@angular/router";

@Component({
	selector: 'app-card-kanban',
	templateUrl: './card-kanban.component.html',
	styleUrls: ['./card-kanban.component.scss']
})
export class CardKanbanComponent implements OnInit {
	@Input('offer') offer: ILead;
	public readonly PERSON_GLOBALS = PersonGlobals;

	constructor(private router: Router) {
	}

	get allTagsLabels(): string {
		const tagsLabels = [];
		for (let tag in this.offer.tags) {
			tagsLabels.push(this.offer.tags[tag].libelle);
		}
		return tagsLabels.join(" - ");
	}

	ngOnInit(): void {
	}

	openDevis(uuid: any) {
		this.router.navigate(['offre', uuid]);
	}
}
