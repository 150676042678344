import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IOrganisation} from '../../interfaces/organisation';
import {shareReplay, switchMap} from 'rxjs/operators';

const SSO_URL = environment.authURL;

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {
    organisationSubject: Observable<IOrganisation>;

    // organisationSubject;

    constructor(private http: HttpClient) {
        this.organisationSubject = this.http
            .get<IOrganisation>(SSO_URL + 'current-organisation')
            .pipe(shareReplay(1));
    }

    getDatasOrganisation(): Observable<any> {
        return this.organisationSubject;
    }

    detail(): Observable<IOrganisation>  {
        return this.organisationSubject
            .pipe(
                switchMap(orga => {
                    const params = {groups: 'UploadedFile'};
                    return this.http.get(SSO_URL + 'organisations/' + orga.dbId, {params});
                })
            );
    }

    update(body: IOrganisation): Observable<IOrganisation> {
        return this.organisationSubject
            .pipe(
                switchMap(orga => {
                    return this.http
                        .request(
                            'PATCH',
                            SSO_URL + 'organisations/' + orga.dbId,
                            {
                                body,
                                headers: {
                                    'Content-Type': 'application/merge-patch+json',
                                    accept: 'application/ld+json',
                                },
                                params: {groups: 'UploadedFile'},
                            }
                        );
                })
            );
    }

    getOrganisationSettings(uuid): Observable<any> {
        return this.http.get(SSO_URL + 'organisation/' + uuid + '/settings');
    }
}
