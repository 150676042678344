<div class="container_onglet">
    <div class="onglets" #onglets>
        <div *ngFor="let onglet of data; let index = index;"
             (click)="changeOnglet($event, onglet.uuid)"
             class="div_onglet"
        >
            <span *ngIf="onglet.type === PERSON_GLOBAL.PERSON_LIEN_PHYSIQUE"
                  class="onglet {{onglet.type}}"
                  [ngClass]="{'active': active === onglet.uuid}"
                  id="{{ active === onglet.uuid ? 'ongletActive' : 'o'+index}}"
            >
                {{onglet.prenom | titlecase}} {{onglet.nom | uppercase}}
            </span>
            <span *ngIf="onglet.type === PERSON_GLOBAL.PERSON_LIEN"
                  class="onglet {{onglet.type}}"
                  [ngClass]="{'active': active === onglet.uuid}"
                  id="{{ active === onglet.uuid ? 'ongletActive' : 'o'+index}}"
            >
            {{onglet.fonction | titlecase}} ({{onglet.raisonSociale |uppercase}})
            </span>
        </div>
    </div>
    <div class="container_fleche">
        <div class="icon fleche fleche_gauche" (click)="scrollLeft(onglets);"></div>
        <div class="icon fleche fleche_droite" (click)="scrollRight(onglets);"></div>
    </div>
    <div class="action">
        <button type="button" class="btn btn-sm btn-ajouter" (click)="addFonction(active)">Ajouter une fonction</button>
    </div>
</div>
