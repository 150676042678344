<div class="carte_avatar morale" *ngIf="personne && personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE"
     [ngClass]="  {
                  'incomplete': !personne.optimized,
                  'avatar_fiche': this.modeFiche,
                  'display_card_mode': this.modeCarte
                 }"
>
    <div *ngIf="personne.personneMorale?.libelle"
         class="carte_avatar_inner cercle_lg texte_personne_{{personne.type}}">
        {{personne.personneMorale.libelle.substring(0, 1) | uppercase}}
    </div>
</div>
<div class="carte_avatar" *ngIf="personne && personne.type !== PERSON_GLOBAL.PERSON_LIEN_MORALE"
     [ngClass]="  {
                  'lien': personne.type === 'lien',
                  'incomplete': !personne.optimized,
                  'avatar_fiche': this.modeFiche,
                  'display_card_mode': this.modeCarte,
                  'small': this.small,
                  'centered': this.centered,
                  'volet': this.volet
                 }"
>
    <div *ngIf="personne.personnePhysique"
         class="carte_avatar_inner cercle_lg cercle_sm"
         [ngClass]="{
                'texte_personne_morale' : personne.type === PERSON_GLOBAL.PERSON_LIEN_MORALE,
                'texte_personne_physique' : personne.type !== PERSON_GLOBAL.PERSON_LIEN_MORALE || !personne.type,
                'small': this.small
                }">
        {{personne?.personnePhysique?.prenom?.substring(0, 1) | uppercase}}{{personne?.personnePhysique?.nom?.substring(0, 1) | uppercase}}
    </div>
    <div class="carte_avatar morale small" *ngIf="personne.type === 'lien'">
        <div class="carte_avatar_inner texte_personne_morale">
            {{personne.personneMorale.libelle.substring(0, 1) | uppercase}}
        </div>
    </div>
</div>
