import {Component, Input, Output, OnDestroy, OnInit, EventEmitter, ElementRef} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {Filter} from '../../_services/personne-lien.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, tap} from 'rxjs/operators';
import {ProductService} from '../../_services/product.service';
import {IProduct} from "../../../interfaces/product";

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss']
})
export class SelectProductComponent implements OnInit, OnDestroy {

  @Input() control: FormControl|AbstractControl = new FormControl();

  @Input() libelle = null;
  @Output() product = new EventEmitter<IProduct>();
  filter: Filter = {};
  items = [];
  subscription: Subscription;
  input$ = new Subject<string>();
  loading = false;
  hasMore = true;

  constructor(private productService: ProductService, private elRef: ElementRef) {}

  ngOnInit(): void {
    if (this.libelle){
      this.control.setValue({libelle: this.libelle});
    }
    this.input$.pipe(
        tap(() => this.loading = true),
        debounceTime(400),
        distinctUntilChanged(),
    ).subscribe((term) => {
      this.initFilter();
      this.filter.search = term;
      this.items = [];
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.input$.unsubscribe();
  }

  fetch() {
    this.loading = true;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.productService.list(this.filter)
      .pipe(
          finalize(() =>  this.loading = false)
      )
        .subscribe(items => {
        this.items = this.items.concat(items['hydra:member'].map(member => {
          return member;
        }));
        this.hasMore = !!items['hydra:view']['hydra:next'];
        });
  }

  scroll($event) {
    if (this.loading
        || !this.hasMore
        || $event.end < (this.items.length - 10)
    ) {
      return ;
    }
    this.filter.page = Number(this.filter.page) ? this.filter.page : 0;
    this.filter.page++;
    this.fetch();
  }

  onOpen(parent = null) {
    if(parent) {
      const El = this.elRef.nativeElement.closest(parent)
      El.style.overflowX = 'unset'
      for (let child of El.children) {
        child.style.overflowX = 'clip';
      }
    }
    if (0 === this.items.length) {
      this.initFilter();
      this.fetch();
    }
  }
  onClose(parent = null): void {
    if(parent) {
      const El = this.elRef.nativeElement.closest(parent)
      El.style.overflowX = null
      for (let child of El.children) {
        child.style.overflowX = null;
      }
    }
  }
  onChange($event) {
    this.product.emit($event);

  }

  private initFilter() {
    this.hasMore = true;
    this.filter = {
      search: '',
      page: 1,
      active: true
    };
  }
}
