import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {Clipboard} from '@angular/cdk/clipboard';
import {faCopy} from '@fortawesome/free-solid-svg-icons';
import {CampagneService} from "../../_services/campagne.service";
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Subscription} from "rxjs";

export interface ImageListItem {
    extra_metadata: [];
    file_size: number;
    last_modifie: number;
    mime_type: any;
    path: string;
    type: string;
    visibility: string;
}

@Component({
    selector: 'app-img-gallery',
    templateUrl: './img-gallery.component.html',
    styleUrls: ['./img-gallery.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImgGalleryComponent implements OnInit, OnDestroy {
    @Input() orgUuid;
    public faCopy = faCopy;
    public images: ImageListItem[] = [];
    isLoading = true;

    private subscriptions = {
        campagneService: Subscription.EMPTY,
        imgGallery: Subscription.EMPTY
    };

    constructor(
        private campagneService: CampagneService,
        private notifier: NotifierService,
        private clipboard: Clipboard,
        private ngxService: NgxUiLoaderService
    ) {}

    ngOnInit() {
        this.subscriptions.campagneService = this.campagneService.imgGalleryLoading.subscribe((loading) => {
            this.isLoading = loading;
            const loader = this.ngxService.getLoader('listImg');
            if(loader){
                if(loading){
                    this.ngxService.startLoader('listImg');
                }else{
                    this.ngxService.stopLoader('listImg');
                }
            }
        });
        this.campagneService.getGallery(this.orgUuid);
        this.subscriptions.imgGallery = this.campagneService.imgGallery.subscribe((imgs) => {
            this.images = imgs;
        });
    }

    public copySrcToClipboard(src: string){
        this.clipboard.copy(src);
        this.notifier.notify('success', 'Le lien vers l\'image a été copié dans votre presse-papier');
    }

    refresh(){
        this.campagneService.getGallery(this.orgUuid);
    }

    ngOnDestroy(): void {
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }
}
