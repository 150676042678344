import {Component, OnInit} from '@angular/core';
import {HelperService} from '../_services/helper.service';

@Component({
    selector: 'app-test-composant',
    templateUrl: './test-composant.component.html',
    styleUrls: ['./test-composant.component.scss']
})
export class TestComposantComponent implements OnInit {
    private fileName = '';

    constructor(private helperService: HelperService) {
    }

    ngOnInit(): void {
    }

    onFileSelected($event): void {
        const file: File = $event.target.files[0];

        if (file) {

            this.fileName = file.name;

            const formData = new FormData();
            formData.append('file', file);
            this.helperService.importFile(formData).subscribe(
                data => {
                }
            );
        }
    }

}
