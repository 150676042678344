import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-creer-choix',
    templateUrl: './creer-choix.component.html',
    styleUrls: ['./creer-choix.component.scss']
})
export class CreerChoixComponent implements OnInit {

  constructor() { }

    ngOnInit(): void {
    }

}
