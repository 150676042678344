<ng-select [items]="data | async"
           bindLabel="text"
           bindValue="id"
           id="{{ id }}"
           [(ngModel)]="value"
           placeholder="- aucun -"
           [disabled]="disabled"
           (focus)="focusSelect2()"
></ng-select>
<!--- confirmation changement profil utilisateur -->
<app-role-downgrade-confirmation #confirmDowngradeModal></app-role-downgrade-confirmation>
