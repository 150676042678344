import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PersonneService} from '../_services/personne.service';
import {TokenStorageService} from '../_services/token-storage.service';
import {IUser} from '../../interfaces/user';
import {Subscription} from 'rxjs';
import {UserService} from '../_services/user.service';
import {VoletGlobals} from '../../const-global/globals';
import {PersonneFonction} from '../../models/personnefonction';
import {EvenementService} from '../_services/evenement.service';
import {OrganisationService} from '../_services/organisation.service';
import {Organisation} from '../../models/organisation';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
	public VOLET_GLOBAL = VoletGlobals;
	public personne: IUser;
	public position = false;
	private errorMessage = '';
	public uuidPersonne = '';
	public isUserPasswordTemp: boolean;
	public nbEventEnRetard: any;
	public organisation: Organisation;
	@ViewChild('mainContainer', {static: true}) mainContainer: ElementRef;

	private subscriptions = {
		password: Subscription.EMPTY,
		countEvent: Subscription.EMPTY,
		userCurrent: Subscription.EMPTY,
	};

	constructor(
		private tokenStorageService: TokenStorageService,
		private personneService: PersonneService,
		private userService: UserService,
		private evenementService: EvenementService,
		private organisationService: OrganisationService
	) {
	}

	ngOnInit(): void {
		this.subscriptions.userCurrent = this.personneService.getCurrentUser()
			.subscribe((data: PersonneFonction) => {
				this.personne = data;
				this.uuidPersonne = this.personne.plUuid;
			});

		this.organisationService.getDatasOrganisation()
			.subscribe(data => {
				this.organisation = data;
			});

		this.subscriptions.countEvent = this.evenementService.counterEventSubjectUser
			.subscribe((events) => {
				this.nbEventEnRetard = events.nbEventEnRetard;
			});
		this.subscriptions.password = this.userService.isPasswordTemp()
			.subscribe(isUserPasswordTemp => {
				this.mainContainer.nativeElement.style.setProperty('--nav-height', isUserPasswordTemp ? '90px' : '60px');
				this.isUserPasswordTemp = isUserPasswordTemp;
				if (this.isUserPasswordTemp === true) {
					this.mainContainer.nativeElement.style.height = 'calc(100vh - 110px)';
				}
			});
	}

	ngOnDestroy(): void {
		// noinspection JSUnusedLocalSymbols
		for (const [key, value] of Object.entries(this.subscriptions)) {
			value.unsubscribe();
		}
	}

	// permet de fermet volet
	closeVolet(): void {
		const voletParametre = document.getElementById('voletParametre');
		const voletRappel = document.getElementById('voletRappel');
		if (!voletParametre.classList.contains(this.VOLET_GLOBAL.PARAMETRE_FERME)) {
			voletParametre.classList.add(this.VOLET_GLOBAL.PARAMETRE_FERME);
		}
		if (!voletRappel.classList.contains(this.VOLET_GLOBAL.RAPPEL_FERME)) {
			voletRappel.classList.add(this.VOLET_GLOBAL.RAPPEL_FERME);
		}
	}
}
