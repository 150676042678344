<div class="creation">
    <div class="bandeau icon addresse_card">
        <h1>Nouvelle Personne</h1>
    </div>
    <h3>Type de personne</h3>
    <div class="choix_personne">
        <div></div>
        <div class="badge badge_moral icon building" routerLink="morale">
            <a>Morale</a>
        </div>
        <div></div>
        <div class="badge badge_physique icon user" routerLink="physique">
            <a>Physique</a>
        </div>
        <div></div>
    </div>
</div>
