import {MatPaginatorIntl} from "@angular/material/paginator";


export function getFrenchPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.firstPageLabel = 'Première page';
    paginatorIntl.lastPageLabel = 'Dernière page';
    paginatorIntl.nextPageLabel = 'Page suivante';
    paginatorIntl.previousPageLabel = 'Page précédente';
    paginatorIntl.itemsPerPageLabel = 'Élément(s) par page';
    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        return ((page * pageSize) + 1) + ' - ' + ((page * pageSize) + pageSize) + ' sur ' + length;
    };
    
    return paginatorIntl;
}
