import {PersonnePhysique} from './personnephysique';
import {IOrganisation} from '../interfaces/organisation';
import {IOrigine} from '../interfaces/origine';
import {Personne} from './personne';

export class PersonneFonction extends Personne {

    public ppUuid?: string;
    public plUuid?: string;
    public userUuid?: string;
    public login?: string;
    public libelle?: string;
    public referent?: boolean;
    public active?: boolean;
    public destinataires?: any;
    public destinataireEvents?: any;
    public leads?: any;
    public evenements?: any;
    public intervenants?: any;
    public typePersonne?: string;
    public prenom?: string;
    public nom?: string;
    public titre?: string;
    public origine?: IOrigine;
    public apporteur?: PersonnePhysique;
    public personnePhysiqueLibelle?: string;
    public personneMoraleLibelle?: string;

    public fillFromDatas(personne): void {

        super.fillFromDatas(personne);
        Object.assign(this, personne);
    }
}
