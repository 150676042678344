import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PersonneService} from '../../../_services/personne.service';
import {ModalService} from '../../../_modal';
import {CoordonneService} from '../../../_services/coordonne.service';
import {FormGroup} from '@angular/forms';
import {CoordonneesEditModalComponent} from '../coordonnees-edit-modal/coordonnees-edit-modal.component';
import {Coordonnee} from '../../../../models/coordonnee';
import {CoordonneeGlobals, PersonGlobals} from "../../../../const-global/globals";

@Component({
	selector: 'app-coordonnees',
	templateUrl: './coordonnees.component.html',
	styleUrls: ['./coordonnees.component.scss']
})
export class CoordonneesComponent implements OnInit {

	public readonly COORD_GLOBALS = CoordonneeGlobals;
	public readonly PERSON_GLOBALS = PersonGlobals;
	public infoConsentement: string;
	@Input() uuid: string;
	@Input() personType: string;
	@Input() personStatut: string;
	@Input() emailPrincipal;
	@Input() telPrincipal;
	@Input() sitePrincipal;
	@Input() faxPrincipal;
	@Input() display: boolean = true;
	@Output() coordonneePrincipal = new EventEmitter<string>();
	@Output() valeursEvent = new EventEmitter<object>();
	@ViewChild('coordonneeModal') coordonneeModal: CoordonneesEditModalComponent;

	public form: FormGroup;
	public coordonnees = {
		emails: [],
		telephones: [],
		faxes: [],
		sites: [],
		statuts: []
	};
	private indicatifList = [];

	constructor(
		private personneService: PersonneService,
		private modalService: ModalService,
		private coordonneeService: CoordonneService,
	) {
	}

	ngOnInit(): void {
		this.form = new FormGroup({});
		this.personneService.getListeIndicatifs().subscribe(indicatifs => {
			this.indicatifList = indicatifs;
		});
		this.coordonneeService.coordonneeSubject.subscribe((data) => {
			Object.keys(data).forEach(key => {
				if (key === 'emails') {
					this.coordonnees.emails = data.emails;
				}
				if (key === 'sites') {
					this.coordonnees.sites = data.sites;
				}
				if (key === 'telephones') {
					this.coordonnees.telephones = data.telephones;
				}
				if (key === 'faxes') {
					this.coordonnees.faxes = data.faxes;
				}
			});
			this.valeursEvent.emit(this.coordonnees);
		});
	}

	onSupprimer(uuid, entite): void {
		this.coordonnees[entite] = this.coordonnees[entite].filter(obj => obj.uuid !== uuid);
		this.coordonnees[entite].forEach(coord => {
			if (coord.principal === false) {
				this.coordonnees[entite][0].principal = true;
			}
		});
		this.valeursEvent.emit(this.coordonnees);
		// écoute la modification du form
		this.coordonneeService.personUpdatedSubject.next(true);
	}

	onAjouter(): void {
		this.coordonneeModal.openModal();
	}

	updateCoordonnee($event): void {

		if ($event?.data?.principal === true) {
			this.coordonneePrincipal.emit($event.data.uuid);
		}

		if ($event?.data && Object.keys(this.coordonnees).length === 0) {
			$event.data.principal = true;
		}

		let typeNewCoordonnee = '';

		const currentCoordonneeType = this.coordonneeService.getCoordInfos().filter(type => type.id === $event.type);
		if (currentCoordonneeType) {
			typeNewCoordonnee = this.coordonneeService.getRealType(currentCoordonneeType[0].value);
		}
		if (this.coordonnees[typeNewCoordonnee]) {
			const currentCoordonnee = this.coordonnees[typeNewCoordonnee].filter(coordonnee => coordonnee.uuid === $event.data.uuid);
			if (currentCoordonnee.length === 0) {
				this.coordonnees[typeNewCoordonnee].push($event.data);
			} else {
				this.coordonnees[typeNewCoordonnee].forEach((item, index) => {
					if (item.uuid === $event.data.uuid) {
						this.coordonnees[typeNewCoordonnee][index] = $event.data;
					}
				});
			}
		}
		this.coordonneeService.coordonneeSubject.next(this.coordonnees);
		this.coordonneeService.personUpdatedSubject.next(true);
	}

	onEditer(coordonnee: Coordonnee): void {
		this.coordonneeModal.openEditModal('coordonneeModal', coordonnee);
	}

	onChangeRadio(uuid: string, typeCoordonnee: string): void {
		this.coordonnees[typeCoordonnee].map((item) => {
			item.principal = (item.uuid === uuid);
		});
		this.valeursEvent.emit(this.coordonnees);
	}

	hasProperty(coordonnees: any, property: string): boolean {
		return property in coordonnees && coordonnees[property].length > 0;
	}

	public getIndicatifLibelle(indicatif): string {
		const uuid = (typeof indicatif.uuid !== 'undefined') ? indicatif.uuid : indicatif;
		const indicatifCurrent = this.indicatifList.filter(indcf => indcf.uuid === uuid);
		return (typeof indicatifCurrent[0] !== 'undefined') ? indicatifCurrent[0].indicatif : '';
	}
}
