import {Component} from '@angular/core';
import {CampagneService} from '../../_services/campagne.service';

@Component({
    selector: 'app-campagne-parente-select2',
    templateUrl: './campagne-parente-select2.component.html',
    styleUrls: ['./campagne-parente-select2.component.scss']
})
export class CampagneParenteSelect2Component {

    constructor(private service: CampagneService) {
    }

    public data = [];

    open() {
        this.service.getAllCampaigns().subscribe(campagnes => {
            const dt = [];
            campagnes.forEach(campagne => {
                    dt.push({
                        id: campagne.uuid,
                        libelle: campagne.libelle
                    });
                }
            );

            this.data = dt;
        });
    }

}
