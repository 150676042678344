import {ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PersonGlobals} from '../../../const-global/globals';
import {date} from '../../../const-global/format-date';
import {PersonneService} from '../../_services/personne.service';
import {UserService} from '../../_services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../_services/exception.service';
import {HelperService} from '../../_services/helper.service';
import {CoordonneService} from '../../_services/coordonne.service';
import {AdresseService} from '../../_services/adresse.service';
import {VisibiliteService} from '../../_services/visibilite.service';
import {NotifierService} from 'angular-notifier';
import {MessageGlobals} from '../../../const-global/messages';
import {CustomValidators} from '../../_validators/custom-validators';

@Component({
	selector: 'app-modifier-mot-de-passe',
	templateUrl: './modifier-mot-de-passe.component.html',
	styleUrls: ['./modifier-mot-de-passe.component.scss']
})
export class ModifierMotDePasseComponent implements OnInit {
	public readonly PERSON_GLOBAL = PersonGlobals;
	public form: FormGroup;
	public longueurPassword = 7;
	public currentUuid = this.route.snapshot.params.id;
	public typeChampPassword = 'password';
	public fullDate = date.fullDate;
	public user: any;

	@ViewChild('oldPasswordInput') oldPasswordInput: ElementRef;
	@ViewChild('newPasswordInput') newPasswordInput: ElementRef;
	@ViewChild('newRepeatPasswordInput') newRepeatPasswordInput: ElementRef;

	constructor(
		private personneService: PersonneService,
		private userService: UserService,
		private ref: ChangeDetectorRef,
		public router: Router,
		public route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private ngxService: NgxUiLoaderService,
		private exceptionService: ExceptionService,
		private helperService: HelperService,
		private coordonneeService: CoordonneService,
		private adresseService: AdresseService,
		private visibiliteService: VisibiliteService,
		private renderer: Renderer2,
		private notifier: NotifierService
	) {
	}

	ngOnInit(): void {
		this.getUser();
		this.initForm();
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			oldPassword: new FormControl('', [
				Validators.required,
			]),
			newPassword: new FormControl('', [
				Validators.required,
				Validators.minLength(this.longueurPassword)
			]),
			newRepeatPassword: new FormControl('', [
				Validators.required,
				Validators.minLength(this.longueurPassword)
			])
		}, {
			validators: [CustomValidators.match('newPassword', 'newRepeatPassword')]
		});
	}

	getUser(): void {
		if (this.currentUuid) {
			this.userService.getUserByUuid(this.currentUuid).subscribe(user => {
					this.user = user;
				},
				error => {
					this.notifier.notify('error', this.exceptionService.statutErreur(error));
				});
		} else {
			this.notifier.notify('error', this.exceptionService.statutErreur('User not Found'));
		}
	}

	public showNotification(type: string, message: string): void {
		setTimeout(() => {
			this.notifier.notify(type, message);
		}, 1000);
	}

	public onSubmit(): void {
		this.updatePassword();
	}

	public onSubmitCancel(): void {
		this.onSubmit();
		if (this.form.valid) {
			this.onCancel();
		}
	}

	public onCancel(): void {
		this.router.navigate(['repertoire']);
	}

	updatePassword(): void {
		this.ngxService.start();
		if (this.form.valid && this.user.login) {
			const formDataSso = {
				login: this.user.login,
				oldPassword: this.form.controls.oldPassword.value,
				password: this.form.controls.newRepeatPassword.value,
				repeatPassword: this.form.controls.newPassword.value
			};
			this.userService.changeMotDePasse(formDataSso).subscribe(
				() => {
					this.ngxService.stop();
					this.form.reset();
					this.showNotification('success', 'Mot de passe modifié avec succès');
				},
				err => {
					this.showNotification('error', this.exceptionService.statutErreur(err));
				}, () => {
					this.userService.isPasswordTemp().subscribe((status) => {
						this.userService.passwordSubject.next(status);
					},
					err => {
						console.log(err);
					});
				}
			);
		} else {
			this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
		}
		this.ngxService.stop();
	}

	// TODO: on peux reutiliser ce code dans le projet
	public showHidePassword(): void {
		if (this.typeChampPassword === 'password') {
			this.typeChampPassword = 'text';
			document.querySelectorAll('.oeil').forEach(el => el.classList.remove('d-none'))
			document.querySelectorAll('.oeil_barre').forEach(el => el.classList.add('d-none'))
		} else {
			this.typeChampPassword = 'password';
			document.querySelectorAll('.oeil').forEach(el => el.classList.add('d-none'))
			document.querySelectorAll('.oeil_barre').forEach(el => el.classList.remove('d-none'))
		}
	}
}
