import {Component, OnInit} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {UserService} from '../../_services/user.service';

@Component({
    selector: 'app-cartes-utilisateur',
    templateUrl: './cartes-utilisateur.component.html',
    styleUrls: ['./cartes-utilisateur.component.scss']
})
export class CartesUtilisateurComponent implements OnInit {
    public users = [];

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private location: Location,
        public notifier: NotifierService,
        private ngxService: NgxUiLoaderService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        const ls = localStorage.getItem('rechercheValue');
        const lsValue = JSON.parse(ls);
        this.onRecherche(lsValue?.utilisateur ? lsValue?.utilisateur : '');
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }

    public onRecherche(recherche): void {
        this.userService.getUsersListe(recherche).subscribe(apiUsers => {
            this.users = apiUsers;
        });
    }
}
