import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {EvenementService, EventCounterFiche} from '../../_services/evenement.service';
import {NotifierService} from 'angular-notifier';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../_services/exception.service';
import {date} from '../../../const-global/format-date';
import {IOnglet} from '../../../interfaces/onglet';
import {ITag} from '../../../interfaces/tag';
import {Personne} from '../../../models/personne';
import * as moment from 'moment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ParametreGlobals, PersonGlobals} from '../../../const-global/globals';
import {ErrorsGlobals} from "../../../const-global/errors";
import {EntityTabsService} from "../../_services/entity-tabs.service";

@Component({
    selector: 'app-fiche',
    templateUrl: './fiche.component.html',
    styleUrls: ['./fiche.component.scss']
})
export class FicheComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('dateDebutField') dateDebutField: ElementRef;
    @ViewChild('dateFinField') dateFinField: ElementRef;
    @ViewChild('searchEventField') searchEventField: ElementRef;

    public readonly ONGLET_EVENT = 'event';
    public readonly PERSON_GLOBALS = PersonGlobals;

    private reload: boolean;
    private paramsOnglet = '';
    public libelle: string;
    public personne: Personne;
    // TODO : events provisoire
    public events: any;
    public onglets: IOnglet[];
    public tags: ITag[];
    public disabled = false;
    public nbEventATraiter: number;
    public nbEvent: number;
    public fullDate = date.fullDate;
    public ongletName: string;
    public totalPersonne: number;
    public paramsCreation = 'false';
    public paramsType = '';
    public uuidPersonneCreate: string;
    public libellePersonneCreate: string;
    public uuidFiche = this.route.snapshot.params.id;
    public toggleFilter = true;
    public tagTarget = ParametreGlobals.TAG_ID_PERSON;

    private searchSubject = new Subject<string | undefined>();

    // events
    public moment = moment().locale('fr_FR');
    // Personne liée
    public form: FormGroup;
    public nbEnfant: number;
    public nbParent: number;
    public nbLienPhysique = 0;

    public reset;
    private subscriptions = {
        router: Subscription.EMPTY,
        search: Subscription.EMPTY,
        counterEvents: Subscription.EMPTY,
        eventFiche: Subscription.EMPTY,
        datasFiche: Subscription.EMPTY
    };

    constructor(
        private personneService: PersonneService,
        private route: ActivatedRoute,
        public router: Router,
        private cd: ChangeDetectorRef,
        private entityTabsService: EntityTabsService,
        private evenementService: EvenementService,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private notifier: NotifierService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(routeData => {
            const data = routeData.personne
            this.entityTabsService.upsert({
                uuid: data.ppUuid || data.pmUuid,
                label: data.personnePhysiqueLibelle || data.personneMoraleLibelle,
                type: data.type,
                uri: '/repertoire/' + data.uuid,
            })

            // this.personneService.refreshPersonne(data);
            this.subscriptions.counterEvents = this.evenementService.counterEventSubjectFiche.subscribe((count: EventCounterFiche) => {
                this.nbEventATraiter = count.nbEventAFaire;
                this.nbEvent = count.nbEventTotal;
            });
            this.subscriptions.eventFiche = this.evenementService.getNbEventFiche(data.uuid).subscribe(data => {
                this.evenementService.counterEventSubjectFiche.next(data);
            });

        this.paramsOnglet = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.onglet : null;
        this.paramsCreation = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.creation : null;
        this.paramsType = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.type : null;
        this.uuidPersonneCreate = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.uuidPersonne : null;
        this.libellePersonneCreate = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.libellePersonne : null;

        this.tags = [];
        this.onglets = [];
        this.libelle = '';

        if (this.paramsOnglet) {
            this.openOnglet(this.paramsOnglet);
        } else {
            this.openOnglet(PersonGlobals.PERSON_LIEN_PHYSIQUE === data.type ? this.ONGLET_EVENT : 'personne-linked');
        }

        this.subscriptions.datasFiche = this.personneService.subjectPersonne
            .subscribe(data => {
                if (data.type !== PersonGlobals.PERSON_LIEN_PHYSIQUE) {
                    // affectation pour les conditions coté html
                    this.nbEnfant = data.personneLinked.morale.enfants.length;
                    this.nbParent = data.personneLinked.morale.parents.length;
                    this.nbLienPhysique = data.counterPersonneLinkedPP;

                    // count pour afficher le nb de lien
                    this.totalPersonne = data.personneLinked.morale.enfants.length +
                        data.personneLinked.morale.parents.length +
                        data.counterPersonneLinkedPP;
                } else if (this.paramsCreation !== 'true') {
                    this.ongletName = this.ONGLET_EVENT;
                }
                this.personne = data;
                this.form = this.formBuilder.group({
                        tags: [this.personne?.tags],
                    }, {}
                );
                this.form.controls.tags.valueChanges.subscribe(() => {
                    this.updateTags();
                });
            });

            this.personneService.subjectPersonne.next(data)
        });
    }

    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
        this.subscriptions.search = this.searchSubject
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap((searchQuery) => {
                    this.evenementService.rechercheEventSubject.next(searchQuery)
                })
            ).subscribe();
    }

    onSearchInput(event: Event) {
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery?.trim());
    }

    showNotification(typeNotif: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(typeNotif, message);
        }, 1000);
    }

    toPM(uuid): void {
        this.router.navigateByUrl('/repertoire', {skipLocationChange: true}).then(
            () => this.router.navigate(['/repertoire/' + uuid])
        );
    }

    toModif(uuid): void {
        this.router.navigate([{outlets: {primary: 'repertoire/' + uuid + '/modifier'}}]).then();
    }

    creerEvenement(): void {
        this.router.navigate(['repertoire/creer/evenement/' + this.personne.uuid]);
    }

    /* Permet d'afficher le bon onglet sur le redirect après création */
    openOnglet(onglet: string): void {
        if (typeof onglet !== 'undefined') {
            this.ongletName = onglet;
        } else {
            this.ongletName = this.ONGLET_EVENT;
        }
    }

    eventCreateLien($event: boolean): void {
        this.reload = $event;
        if ($event === true) {
            this.personneService.getDatasFiche(this.uuidFiche).subscribe(dataPersonne => {
                this.personneService.subjectPersonne.next(dataPersonne);
            });
        }
    }

    // --------------------> Filtre evenement : <---------------------------------
    changeTypeEvent(type): void {
        this.evenementService.typeEventSubject.next(type);
    }

    changeStatutEvent(statut): void {
        this.evenementService.statutEventSubject.next(statut);
    }

    changeDateDebutEvent(event): void {
        /**
         * Si la date est vide on faut un next de null pour avoir les valeurs par défaut et on remet l'input
         * en type text pour avoir le placeholder
         */
        if (event.target.value === '') {
            event.target.type = 'text';
            event.target.placeholder = 'Echéance après le';
            this.evenementService.dateDebutEventSubject.next(null);
        } else {
            this.evenementService.dateDebutEventSubject.next(event.target.value);
        }
    }

    changeDateFinEvent(event): void {
        /**
         * Si la date est vide on fait un next de null pour avoir les valeurs par défaut et on remet l'input
         * en type text pour avoir le placeholder
         */
        if (event.target.value === '') {
            event.target.type = 'text';
            event.target.placeholder = 'Echéance avant le';
            this.evenementService.dateFinEventSubject.next(null);
        } else {
            this.evenementService.dateFinEventSubject.next(event.target.value);
        }
    }
    private updateTags(): void{
        const dataTags = [];
        this.form?.controls.tags?.value.forEach( tag => {
           dataTags.push(tag.uuid);
        });
        this.personneService.setTags(dataTags, this.personne.uuid.toString()).subscribe(
            () => {
                this.showNotification('success', 'Le tag a bien été mis à jour');
            },
            err => {
                this.showNotification('error',  err.error.message);
            }
        );
    }

    hasContactInfo(value: any): boolean {
        return value !== undefined && value !== null && value !== '';
    }

}
