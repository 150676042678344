import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {IChamp} from '../../../interfaces/champ';
import {ChampPersoService} from '../../_services/champ-perso.service';
import {Helper} from "../../_helpers/common.helper";

@Component({
    selector: 'app-liste-champs-perso',
    templateUrl: './liste-champs-perso.component.html',
    styleUrls: ['./liste-champs-perso.component.scss']
})
export class ListeChampsPersoComponent implements OnInit {

    public champsPerso: IChamp[];

    constructor(
        public router: Router,
        private champPerso: ChampPersoService,
        public notifier: NotifierService
    ) {
    }

    ngOnInit(): void {
        const ls = localStorage.getItem('rechercheValue');
        if (ls){
            const lsValue = JSON.parse(ls);
            if (lsValue && lsValue.champsPerso) {
                this.onRecherche(lsValue.champsPerso);
            }else{
                this.onRecherche('');
            }
        }else{
            this.onRecherche('');
        }
    }

    public onRecherche(recherche): void {
        this.champPerso.getAllChampsPerso(recherche).subscribe(apiChamps => {
            this.champsPerso = Helper.sortArrayByLabel(apiChamps);
        });
    }

    public openChamp(champ: IChamp): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['champs-personnalise/' + champ.uuid]);
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }
}
