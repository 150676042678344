import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {Filter, PersonneLienService} from "../../_services/personne-lien.service";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, finalize, tap} from "rxjs/operators";

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.scss']
})
export class SelectContactComponent implements OnInit, OnDestroy {

  @Input() control: FormControl|AbstractControl = new FormControl();

  filter: Filter = {};
  items = [];
  subscription: Subscription;
  input$ = new Subject<string>();
  loading = false;
  hasMore = true;

  constructor(private pls: PersonneLienService) {}

  ngOnInit(): void {
    this.input$.pipe(
        tap(() => this.loading = true),
        debounceTime(400),
        distinctUntilChanged(),
    ).subscribe((term) => {
      this.initFilter();
      this.filter.search = term;
      this.items = [];
      this.fetch();
    });
  }

  ngOnDestroy() {
    this.input$.unsubscribe();
  }

  fetch() {
    this.loading = true;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.pls.list(this.filter)
      .pipe(
          finalize(() =>  this.loading = false)
      )
        .subscribe(items => {

        this.items = this.items.concat(items['hydra:member'].map(member => {
          return member;
        }));

        this.hasMore = !!items['hydra:view']['hydra:next'];
        });
  }

  scroll($event) {
    if (this.loading
        || !this.hasMore
        || $event.end < (this.items.length - 10)
    ) {
      return ;
    }

    this.filter.page++;
    this.fetch();
  }

  onOpen() {
    if (0 === this.items.length) {
      this.initFilter();
      this.fetch();
    }
  }

  private initFilter() {
    this.hasMore = true;
    this.filter = {
      search: '',
      'type[]': ['lien', 'physique'],
      page: 1,
      active: true
    };
  }
}
