'use strict';

export class FiltresGlobals {
    static readonly PERSONNE_TYPE_FILTRE = {
        id: 'personneType',
        name: 'Type(s)',
        attributeName: 'type[]',
        value: null,
        hidden: false,
        inputType: 'checkbox'
    };
    static readonly PERSONNE_STATUT_FILTRE = {
        id: 'personneStatut',
        name: 'Statut(s)',
        attributeName: 'statut.libelle[]',
        value: null,
        hidden: false,
        inputType: 'checkbox'
    };
    static readonly PERSONNE_SEGMENT_FILTRE = {
        id: 'personneSegment',
        name: 'Segment',
        attributeName: 'segment',
        value: null,
        hidden: false,
        inputType: 'checkbox_unique'
    };
    static readonly VISIBILITE_FILTRE = {
        id: 'visibilite',
        name: 'Visibilité(s)',
        attributeName: 'visibilite[]',
        value: null,
        hidden: false,
        inputType: 'checkbox'
    };
    static readonly PERSONNE_GROUPS_FILTRE = {
        id: 'personneGroups',
        name: 'groups',
        attributeName: 'groups[]',
        value: ['&PrincipalContactInformation'],
        hidden: true,
        inputType: 'none'
    };
    static readonly OFFRE_PROBABILITE_FILTRE = {
        id: 'offreProbabilite',
        name: 'probabilite',
        attributeName: 'probabilite',
        value: null,
        hidden: true,
        inputType: 'none'
    };
    static readonly OFFRE_CONTACT_STATUT_FILTRE = {
        id: 'personneStatut',
        name: 'Statut(s)',
        attributeName: 'contact.statut.libelle[]',
        value: null,
        hidden: false,
        inputType: 'checkbox'
    };

    // todo
    static readonly PERSONNE_REFERENT = {
        id: 'personneReferent',
        name: 'Référent(s)',
        attributeName: 'referents[]',
        value: null,
        hidden: false,
        inputType: 'select'
    };

    static readonly PERSONNE_CONSENT = {
        id: 'personneConsent',
        name: 'Consentement',
        attributeName: 'consent[]',
        value: null,
        hidden: false,
        inputType: 'checkbox'
    };

    static readonly PERSONNE_MOYEN_RECUEIL = {
        id: 'personneRecueil',
        name: 'Moyen de recueil',
        attributeName: 'consentType[]',
        value: null,
        hidden: false,
        inputType: 'checkbox'
    };
}
