import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {map} from "rxjs/operators";
import {ISegment} from "../../../interfaces/segment";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-form-juridique-select2',
    templateUrl: './form-juridique-select2.component.html',
    styleUrls: ['./form-juridique-select2.component.scss']
})
export class FormJuridiqueSelect2Component {

    items = this.personneService.getListeFormeJuridique().pipe(map(items => {
        return items.map((item) => {
                return {...item, label: item['label'] = (item.libelle ? `[${item.libelle}] - ` : '') + item.libelleLong}
            }
        )
    }))

    @Input()  model!: ISegment;
    @Output() modelChange = new EventEmitter<ISegment>();
    @Input() control: FormControl|AbstractControl = new FormControl();

    constructor(private personneService: PersonneService) { }
}
