<div class="content_volet_filter">
    <div class="fond_volet_filter"
         [ngClass]="{'d-none': filterOpen === false}"
         (click)="applyAndCloseFilter()">
    </div>
    <div class="volet volet_filter"
         id="voletFilter"
         [ngClass]="{'volet_ferme': !filterOpen}"
    >
        <app-filter>
            <div class="header_filter">
                <h2>FILTRES</h2>
                <div class="filter_close">
                    <span class="underline cursor-pointer" (click)="applyAndCloseFilter()">Fermer</span>
                    <span class="icon close" (click)="applyAndCloseFilter()"></span>
                </div>
            </div>
            <div class="body_filter">
                <mat-accordion>
                    <ng-container *ngFor="let filtre of dataFilter.filters">
                        <div class="filter-item" *ngIf="!filtre.hidden">
                            <ng-container [ngSwitch]="filtre.id">
                                <app-personne-type-filter-item
                                        *ngSwitchCase="FILTRES_GLOBALS.PERSONNE_TYPE_FILTRE.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                ></app-personne-type-filter-item>
                                <app-personne-statut-filter-item
                                        *ngSwitchCase="FILTRES_GLOBALS.PERSONNE_STATUT_FILTRE.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                ></app-personne-statut-filter-item>
                                <app-personne-segment-filter-item
                                        *ngSwitchCase="FILTRES_GLOBALS.PERSONNE_SEGMENT_FILTRE.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                ></app-personne-segment-filter-item>
                                <app-personne-visibilite-filter-item
                                        *ngSwitchCase="FILTRES_GLOBALS.VISIBILITE_FILTRE.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                ></app-personne-visibilite-filter-item>
                                <app-personne-referent-filter
                                        *ngSwitchCase="FILTRES_GLOBALS.PERSONNE_REFERENT.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                >
                                </app-personne-referent-filter>
                                <app-mail-consent
                                        *ngSwitchCase="FILTRES_GLOBALS.PERSONNE_CONSENT.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                >
                                </app-mail-consent>
                                <app-mail-consent-moyen-recueil
                                        *ngSwitchCase="FILTRES_GLOBALS.PERSONNE_MOYEN_RECUEIL.id"
                                        [filter]="filtre"
                                        (updated)="updateFilter($event.event, $event.values, $event.filter)"
                                >
                                </app-mail-consent-moyen-recueil>
                            </ng-container>
                        </div>
                    </ng-container>
                </mat-accordion>
            </div>
            <div class="footer_filter">
                <button type="button" class="btn btn-med btn-reset-filter btn btn-fermer"
                        [ngClass]="{'btn-disabled': numberFilterSet.total === 0 && btnResetFilter === false}"
                        (click)="clearAllFilter(); countNbVoletOpen = 0">Effacer tous les
                    filtres
                </button>
                <button type="button" class="btn btn-med btn-valider"
                        [ngClass]="{'btn-disabled': numberFilterSet.total === 0 && btnApplyFilter === false}"
                        (click)="applyAndCloseFilter()">
                    Appliquer tous les filtres
                </button>
            </div>
        </app-filter>
    </div>
</div>
<div class="content_filter">
    <div class="left">
        <ng-content action></ng-content>
    </div>
    <div class="right">
        <app-debouce-input (modelChange)="updateSearch($event)" [(model)]="dataFilter.search"
                           (click)="focusSearch()"
        ></app-debouce-input>
        <div class="flex content-btn-filter gap-2">
            <button type="button" class="btn btn-sm btn-filter" (click)="openFilter()">
                Filtrer&nbsp;
                <div class='flex' *ngIf="this.numberFilterSet.total !== 0">
                    <div class="parenthese">
                        (
                    </div>
                    {{this.numberFilterSet.total}}
                    <div class="parenthese">
                        )
                    </div>
                </div>
            </button>
            <ng-content select=".after"></ng-content>
            <div class="checkbox_fiche_disabled" [hidden]="noToggle">
                <div class="active-switcher">
                    <app-active-switcher
                            [messageSwitch]="messageSwitch"
                            [dataFilter]="dataFilter"
                            (switchActif)="switchActif()"
                    ></app-active-switcher>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bloc_tag" *ngIf="numberFilterSet.total !== 0">
    <div class="tags_filter">
        <ng-container *ngFor="let filter of dataFilter.filters; let i = index">
            <ng-container *ngIf="filter.value">
                <div *ngFor="let item of filter.value" class="flex">
                    <div class="tag_filter"
                         *ngIf="item.titre"
                         matTooltip="{{ item.titre }}"
                         [matTooltipDisabled]="item.titre.length < 20"
                    >
                        <span [ngClass]="{'text-ellipsis' : item.titre.length > 20}">{{item.titre}}</span>
                        <span class="pl-2 icon close cursor-pointer"
                              (click)="clearFilterById(filter, item)"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div>
        <span *ngIf="this.numberFilterSet.total" class="underline cursor-pointer" (click)="clearAllFilter()">Tout effacer</span>
    </div>
</div>
<jw-modal id="cancel" [(display)]="this.displayModal">
    <app-information [titre]="this.MODAL_INFORMATIONS.FILTER_DATA['titre']"
                     [aide]="this.MODAL_INFORMATIONS.FILTER_DATA['aide']"
                     [bas]="this.MODAL_INFORMATIONS.FILTER_DATA['bas']"
                     [validerTexte]="this.MODAL_INFORMATIONS.FILTER_DATA['validerTexte']"
                     [fermerTexte]="this.MODAL_INFORMATIONS.FILTER_DATA['fermerTexte']"
                     (fermer)="cancelData()"
                     (valider)="saveAndUnlockEdit()"
    ></app-information>
</jw-modal>
