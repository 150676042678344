import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
