
<input type="file" class="file-input"
       (change)="onFileSelected($event)" >

<!--TODO TEST -->
<h2>Boutons large</h2><br>

<button type="button" class="btn btn-lg btn-fermer"><span>Fermer</span></button><br>
<button type="button" class="btn btn-lg btn-enregistrer"><span>Enregistrer</span></button><br>
<button type="button" class="btn btn-lg btn-connexion"><span>Connexion</span></button><br>
<button type="button" class="btn btn-lg btn-precedent"><span>Précédent</span></button><br>
<button type="button" class="btn btn-lg btn-suivant"><span>Suivant</span></button><br>

<h2>Boutons moyen</h2><br>

<button type="button" class="btn btn-med btn-ajouter"><span>Ajouter</span></button><br>
<button type="button" class="btn btn-med btn-filtrer"><span>Filtrer</span></button><br>
<button type="button" class="btn btn-med btn-actualiser"><span>Actualiser</span></button><br>
<button type="button" class="btn btn-med btn-creation-devis"><span>Créer le devis</span></button><br>
<button type="button" class="btn btn-med btn-voir-devis"><span>Voir le devis</span></button><br>
<button type="button" class="btn btn-med btn-envoyer-devis"><span>Envoyer le devis</span></button><br>
<button type="button" class="btn btn-med btn-envoyer"><span>Envoyer</span></button><br>
<button type="button" class="btn btn-med btn-voir-plus"><span>Voir plus</span></button><br>
<button type="button" class="btn btn-med btn-exporter"><span>Exporter</span></button><br>
<button type="button" class="btn btn-med btn-mon-compte"><span>Mon compte</span></button><br>
<button type="button" class="btn btn-med btn-deconnexion"><span>Me déconnecter</span></button><br>

<h2>Petit boutons</h2><br>

<button type="button" class="btn btn-sm btn-ajouter"><span>Champs personnalisés</span></button><br>
<button type="button" class="btn btn-sm btn-filtrer"><span>Mots clés</span></button><br>
<button type="button" class="btn btn-sm btn-actualiser"><span>Utilisateurs</span></button><br>
<button type="button" class="btn btn-sm btn-creation-devis"><span>Profils utilisateurs</span></button><br>


<h2>Picto seul big</h2><br>

<button type="button" class="btn btn-picto-lg btn-favoris"></button><br>
<button type="button" class="btn btn-picto-lg btn-supprimer"></button><br>
<button type="button" class="btn btn-picto-lg btn-editer"></button><br>
<button type="button" class="btn btn-picto-lg btn-enregistrer"></button><br>
<button type="button" class="btn btn-picto-lg btn-valider"></button><br>
<button type="button" class="btn btn-picto-lg btn-actualiser"></button><br>
<button type="button" class="btn btn-picto-lg btn-jouer"></button><br>
<button type="button" class="btn btn-picto-lg btn-exporter"></button><br>


<h2>Petit seul medium</h2><br>

<button type="button" class="btn btn-picto-med btn-enregistrer"></button><br>
<button type="button" class="btn btn-picto-med btn-ajouter"></button><br>
