<app-fil-ariane></app-fil-ariane>
<div class="main">
    <div>
        <app-repertoire-outils
                [type]="'origine'"
                (search)="onRecherche($event)"
        ></app-repertoire-outils>
    </div>
    <div class="content_origines"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
    >
        <div *ngIf="origines === undefined || origines.length === 0" class="message_information">Aucun résultat
            trouvé
        </div>
        <ul class="origines">
            <li class="origines_item" *ngFor="let origine of origines; let i = index">
                <div class="origine" *ngIf="origine" (click)="openOrigine(origine.uuid, origine.systeme)">
                    <div class="origines_content" [ngClass]="{'card_disabled': origine.systeme === 'Système'}">
                        <div class="icon icon_origines-de-contact origine_titre bandeau_origine" [ngClass]="{'card_disabled': origine.systeme === 'Système'}">
                            <h2>{{ origine.libelle }}</h2>
                        </div>
                        <div class="carte_texte" [ngClass]="{'card_disabled': origine.systeme === 'Système'}">
                            <p class="texte_systeme">{{ origine.systeme | titlecase }}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
</div>
