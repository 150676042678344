
export default function (params: object): {[param: string]: string | string[]} {
    let httpParams = {};
    Object.entries(params).map(([_, v]) => {
        if (Array.isArray(v)) {
            if (v.length > 0) {
                params[_] = v.map(v => `${v}`);
            }
        } else if (v) {
            httpParams[_] = `${v}`;
        }
    })

    return httpParams;
}
