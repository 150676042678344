import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-repertoire',
    templateUrl: './repertoire.component.html',
    styleUrls: ['./repertoire.component.scss']
})
export class RepertoireComponent {

    constructor(
        public router: Router,
    ) {
    }
}
