<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title class="justify-between">
            <div>{{filter.name}}</div>
            <div class="pastille_counter"
                 *ngIf="filter.getCount() !== 0">{{ filter.getCount() }}</div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex items-center gap-2" *ngFor="let item of dataSource">
        <input class="icon checkbox_principal filter_volet"
               type="checkbox"
               id="{{filter.attributeName}}{{item.uuid}}"
               [checked]="isChecked(item)"
               (change)="updateFilter($event, item)">
        <label for="{{filter.attributeName}}{{item.uuid}}">{{ item.titre }}</label>

    </div>
</mat-expansion-panel>
