export enum SortDirection {
    asc,
    desc
}

export class Helper {

    /**
     * sort array by label based on direction (ASC by default)
     * @param array
     * @param direction
     */
    static sortArrayByLabel(array: Array<any>, direction: SortDirection = SortDirection.asc): any {
        let sortedArray = [];
        if(direction === SortDirection.asc){
            sortedArray = array.sort(function (a, b) {
                return a.libelle.localeCompare(b.libelle);
            });
        }
        if(direction === SortDirection.desc){
            sortedArray = array.sort(function (a, b) {
                return b.libelle.localeCompare(a.libelle);
            });
        }
        return sortedArray;
    }
}
