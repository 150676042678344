import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SegmentService} from '../../_services/segment.service';
import {date} from '../../../const-global/format-date';
import {ITag} from '../../../interfaces/tag';
import {ISegment} from '../../../interfaces/segment';
import {PersonneFonction} from '../../../models/personnefonction';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NotifierService} from 'angular-notifier';
import {PersonneService} from '../../_services/personne.service';
import {PersonGlobals} from '../../../const-global/globals';
import {EntityTabsService} from "../../_services/entity-tabs.service";
import {EntityType} from "../../../models/lastentity";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-fiche',
    templateUrl: './fiche-segment.component.html',
    styleUrls: ['./fiche-segment.component.scss'],
})
export class FicheSegmentComponent implements OnInit {
    private errorMessage: string;
    public tags: ITag;
    public disabled = false;
    public segment: ISegment;
    public resultats: Array<PersonneFonction>;
    public fullDate = date.fullDate;
    public uuidSegment = null;
    public updateInProgress = false;
    public readonly PERSON_GLOBALS = PersonGlobals;

    // paramètre pour l'infinite scroll
    public offset = 0;
    public limit = 30;
    private finListe = false;
    private rechercheEnCours = false;
    private routeSubscription: Subscription;

    constructor(
        private segmentService: SegmentService,
        private route: ActivatedRoute,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private notifier: NotifierService,
        private personneService: PersonneService,
        private entityTabService: EntityTabsService,
    ) {
        this.notifier = notifier;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe((routerParams) => {
            this.uuidSegment = routerParams.id;
            this.getResultats(this.uuidSegment);
            this.segmentService.getSegment(this.uuidSegment, true).subscribe(data => {
                    this.entityTabService.upsert({
                        uuid: data.uuid,
                        type: EntityType.segment,
                        uri: 'segment/' + data.uuid,
                        label: data.titre,
                    })

                    this.segment = data;
                    this.segmentService.subjectSegment.next(data);
                    if (this.segment.filtres) {
                        this.segment.filtres.forEach((filtre, index) => {
                            if (filtre.champ.libelle === 'Code NACE') {
                                this.personneService.getNaceCodes().subscribe((dataNace: Array<any>) => {
                                    dataNace.forEach(code => {
                                            if (code?.fields?.buyer_nace_cde) {
                                                filtre.valeurs.forEach((val, i) => {
                                                    if (val === code?.fields?.buyer_nace_cde) {
                                                        this.segment.filtres[index].valeurs[i] =
                                                            code?.fields?.buyer_nace_cde + ' - ' +
                                                            code?.fields?.buyer_nace_name;
                                                    }
                                                });
                                            }
                                        }
                                    );
                                });
                            }
                            if (filtre.champ.libelle === 'Code NAF') {
                                this.personneService.getNafCodes().subscribe((dataNaf: Array<any>) => {
                                    dataNaf.forEach(code => {
                                            if (code?.code) {
                                                filtre.valeurs.forEach((val, i) => {
                                                    if (val === code?.code) {
                                                        this.segment.filtres[index].valeurs[i] =
                                                            code.code + ' - ' + code.libelle;
                                                    }
                                                });

                                            }
                                        }
                                    );
                                });
                            }
                        });
                    }
                },
                err => {
                    this.errorMessage = err.error.message;
                });
        });
    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    /* Récupère la liste des résultats du segment */
    getResultats(uuid: string): void {
        this.updateInProgress = true;
        this.ngxService.startLoader('resultats');
        this.segmentService.getSegmentResultats(
            uuid,
            this.offset.toString(),
            this.limit.toString()
        ).subscribe(
            data => {
                this.resultats = data.resultats;
            },
            err => {
                this.updateInProgress = false;
                this.ngxService.stopLoader('resultats');
                this.errorMessage = err.error.message;
            },() => {
                this.ngxService.stopLoader('resultats');
                this.updateInProgress = false;
            }
        );
    }

    export(uuid: string): void {
        this.segmentService.getExportCsv(uuid, this.segment.titre);
    }

    onScroll(uuid: string): void {
        if (!this.rechercheEnCours && !this.finListe) {
            this.offset = this.offset + this.limit;
            this.rechercheEnCours = true;
            this.segmentService.getSegmentResultats(
                uuid,
                this.offset.toString(),
                this.limit.toString()
            ).subscribe(data => {
                    this.finListe = data.resultats.length === 0;
                    data.resultats.map(res => {
                        this.resultats.push(res);
                    });
                    this.rechercheEnCours = false;
                },
                err => {
                    this.errorMessage = err.error.message;
                    this.showNotification('error', this.errorMessage);
                }
            );
        }
    }

    toModifier(uuid: string): void {
        this.router.navigate(['/segment',  uuid, 'modifier']);
    }

    toDuplicate(uuid: string): void {
        this.router.navigate(['segment', uuid, 'duplicate']);
    }

    createCampaign(uuid: string): void {
        this.router.navigate(['/campagne/choix-modele'], {queryParams: {segment: uuid}});
    }

    actualiser(uuid: string): void {
        this.offset = 0;
        this.getResultats(uuid);
        this.ngOnInit();
    }

    isInstanceOf(candidat: Array<string>): string {
        return typeof candidat;
    }
}
