import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateFormaterService {
    public moment: any = moment;
    constructor() {}

    /**
     * Formate la date au format YYYY-MM-DD HH:mm:ss (année-mois-jour heure:minute:seconde)
     */
    formatDateTime(date: Date): string {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    /**
     * Concataine la date et l'heure pour qu'elle soit lisible facilement
     */
    concatDateTime(date: Date): string {
        return moment(date).format('DD/MM/YYYY') + ' à ' + moment(date).format('HH:mm');
    }

    /**
     * Sort array by DATE DESC
     */
    sortByDateDESC(data: Array<any>): Array<any> {
        return data.sort((a: any, b: any) => {
            const a_utc = moment(a.endDate.date, 'DD/MM/YYYY à hh:ii');
            const b_utc = moment(b.endDate.date, 'DD/MM/YYYY à hh:ii');
            return (b_utc as any) - (a_utc as any);
        });
    }
    getTimezone(date: any): string {
        if (Object.prototype.hasOwnProperty.call(date, 'timezone')) {
            // @ts-ignore
            return date.timezone.replaceAll('_', ' ').replaceAll('/', ', ');
        }
        return '';
    }

    getDate(date: any): string {
        if (!date) {
            return '';
        }
        const tz = this.getTimezone(date);
        if (tz && '' !== tz) {
            return this.concatDateTime(date.date) + ' (' + tz + ')';
        }
        return this.concatDateTime(date.date);
    }
}
