<ng-select
        [items]="ConsentHelper.consents"
        [closeOnSelect]="true"
        [clearable]="false"
        bindValue="id"
        bindLabel="valeur"
        [(ngModel)]="value"
        (change)="update($event)"
        placeholder="">
    <ng-template ng-label-tmp let-item="item">
        <div class="icon"
             [ngClass]="{
                                    'times-circle red-cherry': item.id === COORD_GLOBALS.CONSENT.INVALID.id || item.id === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id,
                                    'check green': item.id === COORD_GLOBALS.CONSENT.VALID.id,
                                    'question-circle small': item.id === COORD_GLOBALS.CONSENT.ND.id
                                 }"
        >
            {{item.text}}
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div class="icon"
             [ngClass]="{
                                    'times-circle red-cherry': item.id === COORD_GLOBALS.CONSENT.INVALID.id || item.id === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id,
                                    'check green': item.id === COORD_GLOBALS.CONSENT.VALID.id,
                                    'question-circle small ': item.id === COORD_GLOBALS.CONSENT.ND.id
                                 }"
        >
            {{item.text}}
        </div>
    </ng-template>
</ng-select>
