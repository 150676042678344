<ng-select
        bindLabel="libelle"
        [loading]="loading"
        [items]="items"
        [formControl]="control"
        [virtualScroll]="true"
        [addTag]="true"
        [typeahead]="input$"
        (scroll)="scroll($event)"
        (open)="onOpen('.scrollable_container')"
        (close)="onClose('.scrollable_container')"
        addTagText="Ajouter libellé"
        loadingText="Chargement"
        (change)="onChange($event)"
        [clearable]="false"
>
    <ng-template ng-label-tmp let-item="item">
    {{  (item?.libelle.length>40)? (item?.libelle | slice:0:40)+'...':(item?.libelle) }}
</ng-template>
</ng-select>
