<div>
    <app-fil-ariane></app-fil-ariane>
    <ngx-ui-loader></ngx-ui-loader>
</div>
<ng-container *ngIf="this.form">
    <notifier-container></notifier-container>
    <div class="form_campagne">
        <form [formGroup]='form'>
            <div class="form_campagne_grid">
                <div class="bandeau_campagne icon mail">
                    <h1>{{ this.form.get('libelle').value }}</h1>
                </div>
                <div class="rightbox">
                    <app-bloc-visibilite [form]="form"
                                         [type]="'campagne'"
                                         [creation]="this.newCampaign"
                    ></app-bloc-visibilite>
                </div>
                <div class="bloc_identite leftbox">
                    <div class="bandeau_form">Informations générales</div>
                    <div class="bloc_container">
                        <div>
                            <div class="input_container">
                                <div class="espace_input input_lg">
                                    <label for="libelle">Intitulé</label>
                                    <input type="text"
                                           id="libelle"
                                           name="libelle"
                                           [formControlName]="'libelle'"
                                           [ngClass]="{'background_alert_required': !this.form.controls.libelle.valid}"
                                    />
                                </div>
                                <div class="container_heritage hidden">
                                    <div class="input_container input_lg">
                                        <label for="campagne_parente">Campagne Parente</label>
                                        <app-campagne-parente-select2 id="campagne_parente">
                                        </app-campagne-parente-select2>
                                    </div>
                                    <div class="relance">
                                        <input type="checkbox" id="relance" class="icon" formControlName="relance">
                                        <label for="relance">Relance</label>
                                    </div>
                                </div>
                            </div>
                            <div class="input_container three_cols">
                                <div class="espace_input input_lg">
                                    <label>Planifié le</label>
                                    <input type="date"
                                           min="{{minDate}}"
                                           formControlName="dateEnvoi"
                                    >
                                </div>
                                <div class="espace_input input_lg">
                                    <label>Début d'envoi à</label>
                                    <input
                                            type="time"
                                            formControlName="heureDebut"
                                    >
                                </div>
                                <div class="espace_input input_lg fuseau_horaire">

                                    <label for="libelle">
                                        Fuseau horaire utilisé pour l'envoi
                                        <span class="icon info"
                                           matTooltipClass="fuseau_horaire"
                                           matTooltip="La campagne sera envoyée à l'heure du fuseau horaire que vous
                                           aurez sélectionné. Ce fuseau horaire sera utilisé uniquement pour l'envoi de
                                            cette campagne et peut être différent d'une campagne à l'autre."
                                           aria-label="tooltip"
                                        >
                                        </span>
                                    </label>

                                    <ng-select
                                            id="fuseauHoraire"
                                            formControlName="fuseauHoraire"
                                            [items]="this.timezones"
                                            [dropdownPosition]="'auto'"
                                            bindValue="id"
                                            bindLabel="text"
                                            [clearable]="false"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc_destinataires leftbox">
                    <div class="bandeau_form">Destinataires</div>
                    <div class="bloc_container">
                        <div>
                            <div class="input_lg">
                                <label for="segments">Segment(s)</label>
                                <ng-select
                                        id="segments"
                                        bindValue="id"
                                        bindLabel="text"
                                        formControlName="segments"
                                        [items]="allSegments"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                >
                                </ng-select>
                            </div>
                            <div class="premise" *ngIf="countEmails?.count || countInProgress">
                                Prévision d'envoi :
                                <ng-container
                                        *ngIf="countInProgress then inprogress; else countedContacts;"></ng-container>
                                <ng-template #inprogress>
                                    <fa-icon [icon]="faSpinner" size="xs" [spin]="true"></fa-icon>
                                    calcul en cours...
                                </ng-template>
                                <ng-template #countedContacts>
                                <span [ngClass]="{
                                    'green': PercentageCoverage >= 80,
                                    'orange': PercentageCoverage > 50 && PercentageCoverage < 80,
                                    'red': PercentageCoverage <= 50
                                    }"
                                >
                                    {{ countEmails.count }}
                                </span>
                                    e-mail(s)
                                    pour <span class="nb_contacts">{{ countEmails.count + countEmails.skipped }}</span>
                                    contact(s)
                                    <span *ngIf="PercentageCoverage < 50" class="red info">(Attention segmentation peu performante <b>{{ PercentageCoverage | number: '1.0-2' +
                                    '' }}%</b>)</span>
                                </ng-template>
                            </div>
                            <div class="premise" *ngIf="!countEmails?.count && !countInProgress">
                                Prévision d'envoi :
                                <span class="red">0</span> e-mail
                                <span *ngIf="!countEmails?.count && form.get('segments').value.length" class="red">
                                    (Attention, votre campagne ne pourra pas être envoyée)
                                </span>
                            </div>
                        </div>
                        <div class="filters">
                            <ng-container *ngFor="let filtre of filtres.controls; let i = index">
                                <ng-template [ngTemplateOutlet]="filtreTemplate"
                                             [ngTemplateOutletContext]="{id: i}"
                                >
                                </ng-template>
                            </ng-container>
                        </div>
                        <button type="button" class="btn btn-med btn-ajouter hidden" (click)="addFilter()">
                            <span>Ajouter un filtre par campagne</span>
                        </button>
                    </div>
                </div>
                <div class="bloc_message">
                    <div class="bandeau_form">Message</div>
                    <div class="bloc_container">
                        <div class="input_container input_medium">
                            <div class="espace_input input_lg">
                                <label for="from">Nom de l'expéditeur</label>
                                <input type="text" id="from" formControlName="from"
                                       placeholder="ex: Cabinet John Doe & cie"
                                       [ngClass]="{'background_alert_required': !this.form.controls.from.valid}">
                            </div>
                            <div class="input_lg">
                                <label for="from">E-mail de l'expéditeur</label>
                                <input type="text" id="from_mail" [value]="replyTo"
                                       class="background_disabled" disabled *ngIf="replyTo">
                            </div>
                        </div>
                        <div class="input_container">
                            <label for="subject">Objet</label>
                            <input type="text" id="subject" formControlName="subject"
                                   [ngClass]="{'background_alert_required': !this.form.controls.subject.valid}">
                        </div>
                        <div class="input_container">
                            <label>Corps du message</label>
                            <div class="bloc_img_gallery">
                                <button type="button" (click)="openGallery()"
                                        class="btn btn-med icon gallery"
                                        [ngClass]="{'opened': galleryOpened}"
                                >
                                    Ouvrir la banque d'image
                                </button>
                                <ng-container *ngIf="galleryOpened">
                                    <app-img-gallery [@opening] [orgUuid]="this.orgUuid"></app-img-gallery>
                                </ng-container>
                            </div>
                            <email-editor
                                    [options]="_options()"
                                    [tools]="tools()"
                                    [appearance]="appearance()"
                                    (ready)="editorReady()"
                            >
                            </email-editor>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="form_bas">
            <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    (click)="onSubmit(false)"
                    [ngClass]="{'blinking': blink, 'btn-disabled': editorLoadStatus === false}"
                    (animationend)="animationEnd()"
                    [disabled]="!editorLoadStatus"
            ><span>Enregistrer</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    (click)="onSubmit(true)"
                    [ngClass]="{'btn-disabled': editorLoadStatus === false}"
                    [disabled]="!editorLoadStatus">
                <span>Enregistrer et fermer</span>
            </button>
        </div>
        <app-modal-confirm-exit #confirmModal (cancelEvent)="onCancel()"></app-modal-confirm-exit>
    </div>
    <ng-template #filtreTemplate let-index="id">
        <div class="input_container radios">
            <input type="radio"
                   name="filtre_mode"
                   id="filtre_include"
                   value="1"
            >
            <label for="filtre_include">Inclure</label>
            <input type="radio"
                   name="filtre_mode"
                   id="filtre_exclude"
                   value="2"
            >
            <label for="filtre_exclude">Exclure</label>
        </div>
        <div class="input_container input_lg">
            <label for="filtre_destinataires">Les destinataires</label>
            <ng-select id="filtre_destinataires"></ng-select>
        </div>
        <div class="input_container input_lg">
            <label for="filtre_campagnes">De(s) campagne(s)</label>
            <ng-select id="filtre_campagnes"></ng-select>
        </div>
        <button type="button" class="btn btn-picto-med btn-supprimer" (click)="removeFilter(index)"></button>
    </ng-template>
</ng-container>

<!-- MODALS -->
<jw-modal id="modal-gallery">
    <div class="bloc_title_modal">Banque d'image</div>
    <div class="bloc_body_modal">
        <ng-container>
            <app-img-gallery [@opening] [orgUuid]="this.orgUuid"></app-img-gallery>
        </ng-container>
    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="closeGallery()">
                <span>Fermer</span>
            </button>
        </div>
    </div>
</jw-modal>
