import {Component, Input, OnInit} from '@angular/core';
import {IPersonneStatut} from "../../../interfaces/personnestatut";

@Component({
  selector: 'app-fonction-sticker',
  templateUrl: './fonction-sticker.component.html',
  styleUrls: ['./fonction-sticker.component.scss']
})
export class FonctionStickerComponent {
  @Input() statut: IPersonneStatut;
}
