import {Component, Input} from '@angular/core';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {SegmentService} from '../../_services/segment.service';
import {date} from '../../../const-global/format-date';
import {ISegment} from '../../../interfaces/segment';
import {Personne} from '../../../models/personne';
import {NotifierService} from 'angular-notifier';

@Component({
    selector: 'app-segment-carte',
    templateUrl: './carte-segment.component.html',
    styleUrls: ['./carte-segment.component.scss']
})
export class CarteSegmentComponent {
    @Input() segment: ISegment;
    public faArrowRight = faArrowRight;
    public fullDate = date.fullDate;
    public updateInProgress = false;
    public personne: Personne;

    constructor(
        private segmentService: SegmentService,
        public router: Router,
        private notifier: NotifierService
    ) {
    }

    ngOnInit(): void {
        /** Personne Utilisée pour l'avatar de la carte **/
        this.personne = new Personne();
        this.personne.fillFromDatas({nom: this.segment?.nom, prenom: this.segment?.prenom});
    }

    openFiche(uuid: string): void {
        this.router.navigate(['segment/' + uuid], {state: this.segment}).then(() => {
            this.segmentService.getSegment(uuid, true).subscribe(dataSegment => {
                this.segmentService.subjectSegment.next(dataSegment);
            });
        });
    }

    export(uuid: string): void {
        this.segmentService.getExportCsv(uuid, this.segment.titre);
    }

    calculate(uuid: string): void {
        this.getResultats(uuid);
    }

    /* Récupère la liste des résultats du segment */
    getResultats(uuid: string): void {
        this.updateInProgress = true;
        this.segmentService.getSegmentResultats(
            uuid, '0', null, '1'
        ).subscribe(
            data => {
                this.segment.nbContactsPublics = data.count.public;
                this.segment.nbContactsPrives = data.count.prive;
            },
            (err) => {
                this.notifier.notify('error', err);
            },
            () => {
                this.updateInProgress = false;
            }
        );
    }
}
