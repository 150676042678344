import {Component, OnInit} from '@angular/core';
import {RightslistDatabase, DroitItemFlatNode, DroitItemNode, RolesTreeComponent} from '../roles-tree.component';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../_services/user.service';
import {MatTreeFlatDataSource} from '@angular/material/tree';

@Component({
    selector: 'app-roles-tree-user',
    templateUrl: './roles-tree-user.component.html',
    styleUrls: ['./roles-tree-user.component.scss'],
    providers: [RightslistDatabase]
})
export class RolesTreeUserComponent extends RolesTreeComponent implements OnInit {
    public disabledFeature: 'disabled';
    public userDataSource: MatTreeFlatDataSource<DroitItemNode, DroitItemFlatNode>;

    // tslint:disable-next-line:variable-name
    constructor(_database: RightslistDatabase, protected userService: UserService, protected route: ActivatedRoute) {
        super(_database, userService, route);
        this.userDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnInit(): void {
        this.userService.getUserTree(this.userUuid).subscribe(userGroupsDroits => {
            this.userDataSource.data = this._database.buildTree(userGroupsDroits, 0);
        });
    }
}
