import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AccessInterceptor} from './access.interceptor';
import {AuthInterceptor} from './auth.interceptor';
import {NotfoundInterceptor} from "./notfound.interceptor";

export const InterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AccessInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: NotfoundInterceptor, multi: true},
];
