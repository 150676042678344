import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../_services/user.service';
import {Observable} from 'rxjs';
import {RoleDowngradeConfirmationComponent} from '../../gestion-users/role-downgrade-confirmation/role-downgrade-confirmation.component';
import {ModalService} from '../../_modal';
import {Options} from 'select2';
import {FormGroup} from '@angular/forms';
import {Select2OptionData} from 'ng-select2';
import {v4 as uuidv4} from 'uuid';

export interface Select2Interface {
    id: string;
    text: string;
    selected?: boolean;
    rank: number;
}

@Component({
    selector: 'app-roles-select2',
    templateUrl: './roles-select2.component.html',
    styleUrls: ['./roles-select2.component.scss']
})
export class RolesSelect2Component implements OnInit, AfterViewInit {
    private readonly uuidSelect2: string = 'select2-' + uuidv4();
    @Input() uuid: string;
    @Input() form: FormGroup;
    @Input() id: string;
    @Input() disabled: boolean;
    @ViewChild('confirmDowngradeModal') confirmDowngradeModal: RoleDowngradeConfirmationComponent;
    public data: Observable<Array<Select2OptionData>> = new Observable(observer => {
        observer.next([]);
        observer.complete();
    });
    private roles: Array<Select2Interface>;
    private oldProfile: Select2Interface;
    public options: Options;

    constructor(
        private userService: UserService,
        private modalService: ModalService
    ) {
        this.options = {};
    }

    private _value: string;
    private previousValue: string;
    get value(): string {
        return this._value;
    }
    set value(value: string) {
        if (value !== null){
            this.previousValue = this._value;
            const newProfile = this.roles.filter(profil => profil.id === value)[0];
            if (this.oldProfile?.id !== newProfile?.id && this.oldProfile?.rank < newProfile?.rank) {
                this.modalService.open('confirmDowngradeModal');
                this._value = value;
                this.form.controls.userProfil.setValue(this._value);
            }else{
                if (this._value !== value){
                    this._value = value;
                    this.form.controls.userProfil.setValue(this._value);
                }
            }
        }
    }

    ngOnInit(): void {
        this.initSelect();
    }


    ngAfterViewInit(): void {
        if (this.confirmDowngradeModal?.confirmChange) {
            this.confirmDowngradeModal.confirmChange.subscribe(change => {
                if (change === false){
                    this.value = this.previousValue;
                }
            });
        }
    }

    initSelect(): void {
        this.uuid = this.uuid || '';
        this.userService.getUserRolesByUuid(this.uuid).subscribe(
            (data: Array<Select2Interface>) => {
                this.roles = [];
                let hasProfil = false;
                data.map((item) => {
                    if (item.selected){
                        this.value = item.id;
                        hasProfil = true;
                    }
                    this.roles.push({
                        id: item.id,
                        text: item.text,
                        rank: item.rank
                    });
                });

                if (!hasProfil) {
                    const defaultProfil = this.roles.filter(profil => profil.text === 'Utilisateur standard')[0];
                    this.value = defaultProfil.id;
                }

                this.oldProfile = this.roles.filter(profil => profil.id === this.value)[0];

                this.data = new Observable(obs => {
                    obs.next(data);
                    obs.complete();
                });
            });
    }

    valueChanged($event): void {
        this.form.controls.userProfil.setValue($event);
    }

    focusSelect2(): void {
        const span = document.getElementsByClassName('select2-search select2-search--dropdown')[0];
        if (span) {
            const input = span.children[0];
            input.id = this.uuidSelect2;
            document.getElementById(input.id).focus();
        }
    }
}
