<jw-modal id="confirmDowngradeModal">
    <div class="bloc_title_modal">Modification de profil utilisateur</div>
    <hr>
    <div class="bloc_body_modal">Attention, vous êtes sur le point de modifier un profil utilisateur, celui-ci étant inférieur au profil actuel des risques de pertes d'accès à certaines parties du CRM peuvent survenir.<br/><strong class="action_modal valider">Veuillez confirmer votre action.</strong></div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="cancel()">
                <span>Annuler</span></button>
            <button type="submit" class="btn btn-med btn-valider" (click)="confirm()">
                <span>Confirmer</span>
            </button>
        </div>
    </div>
</jw-modal>
