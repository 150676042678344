import {Component, Input} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-origine-select2',
    templateUrl: './origine-select2.component.html',
    styleUrls: ['./origine-select2.component.scss']
})
export class OrigineSelect2Component {

    @Input() control: FormControl
    items: []
    constructor(private personneService: PersonneService) {
    }

    onOpen() {
        this.personneService.getListeOrigines().subscribe(items => this.items = items)
    }
}
