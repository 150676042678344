import {Component, Input} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-titre-autocomplete',
    templateUrl: './titre-autocomplete.component.html',
    styleUrls: ['./titre-autocomplete.component.scss']
})
export class TitreAutocompleteComponent {
    @Input() control: FormControl;
    items = [];

    constructor(private personneService: PersonneService) { }

    onOpen() {
        this.personneService.getListeTitres().subscribe(items => this.items = items);
    }
}
