<div class="main">
    <div>
        <app-fil-ariane></app-fil-ariane>
        <app-repertoire-outils
                (search)="onRecherche($event)"
                [type]="'users'"
        ></app-repertoire-outils>
    </div>
    <div class="content_users"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
    >
        <div *ngIf="users === undefined || users.length === 0" class="message_information">Aucun résultat trouvé</div>
        <ul class="cartes">
            <ng-container *ngIf="users">
                <li class="cartes_item" *ngFor="let user of users; let i = index">
                    <div class="carte_content">
                        <div class="icon mon_compte carte_titre bandeau_parametre"
                             routerLink="/utilisateurs/{{ user.uuid }}"
                             routerLinkActive="active"
                             title="Éditer l'utilisateur"
                        >
                            <h2>{{ user.prenom | titlecase }} {{ user.nom | uppercase }}
                                ({{ user.civilite | titlecase }})</h2>
                        </div>
                        <div class="carte_texte">
                            <h2 class="user_titre">{{user.role | titlecase}}</h2>
                            <h3 *ngIf="user.isOwner">(mon compte)</h3>
                        </div>
                        <div class="carte_footer">
                            <button
                                    class="btn btn_border_white btn btn-picto-lg picto-user"
                                    title="Voir la fiche de cette personne"
                                    [routerLink]="['/', 'repertoire', user.plUuid]" routerLinkActive="active"
                            >
                            </button>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
<!--    TODO : il manque le notifier ici-->
</div>
