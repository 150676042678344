import {Injectable} from '@angular/core';
import {AbstractClient} from "./abstract-client";
import {Filter} from "./personne-lien.service";
import {environment, environment as ENV} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {Devis} from "../../models/devis";
import {IDevis} from "../../interfaces/devis";
import {ITag} from "../../interfaces/tag";
import {IMemo} from "../../interfaces/memo";
import {IPersonnelien} from "../../interfaces/personne-lien";
import {IUser} from "../../interfaces/user";
import {map} from "rxjs/operators";
import {ILead} from "../../interfaces/lead";

export class Lead implements ILead {
    "@id"?: string;
    "@type"?: string;
    "uuid"?: string;
    "visibilite"?: number;
    "statut"?: string;
    "libelle"?: string;
    "probabilite"?: any;

    "priorite"?: {
        id: number,
        libelle: string
    };
    "contact"?: IPersonnelien;
    "devis"?: Devis;
    intervenants?: string[];
    referent: any;
    tags?: ITag[];
    evenements?: [];
    memos?: IMemo[];
    active?: boolean;
    total?: number;
    createdAt?: string;
    updatedAt?: string;
    poids?: number;
    userModification?: IUser;
    userCreation?: IUser;

    constructor(data?: object) {
        Object.assign(this, data);
    }
}

const API_URL = environment.apiURL;

const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};

export interface LeadFilter {
    "statut"?: number;
    "libelle"?: string;
    "active"?: boolean;
    "search"?: string;
    "probabilite"?: number;
}

@Injectable({
    providedIn: 'root'
})
export class LeadService extends AbstractClient<ILead, Filter> {
    activeSubject = new BehaviorSubject<boolean>(true);
    private httpClient: HttpClient;
    constructor(http: HttpClient) {
        super(http, ENV.apiURL + 'leads');
        this.httpClient = http;
    }

    updateIntervenants(data: any): Observable<any> {
        return this.httpClient.post(API_URL + 'leads/intervenants', data, httpOptions);
    }

    getTotals(active): Observable<any> {
        return this.httpClient.get(API_URL + 'leads/kanban/totals?active=' + active);
    }

    getPdfUrl(uuid) {
        return this.request('GET', `/${uuid}.pdf`, {
            headers: new HttpHeaders(
                {accept: 'application/pdf'}
            ),
            responseType: 'blob'
        })
        .pipe(map(pdf => URL.createObjectURL(pdf)));
    }

	sendDevis(uuid: string, data: object = {}): Observable<any> {
		return this.httpClient.post(API_URL + 'devis/send/' + uuid, data);
	}
}
