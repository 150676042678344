import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable, Subject} from 'rxjs';

@Component({
    selector: 'app-probabilite-cursor',
    templateUrl: './probabilite-cursor.component.html',
    styleUrls: ['./probabilite-cursor.component.scss'],
    animations: [
        trigger('steps', [
            state('white',
                style({   backgroundColor: 'var(--gris-clair-2)'})
            ),
            state('*',
                style({})
            )
        ])
    ]
})
export class ProbabiliteCursorComponent implements OnInit, OnDestroy, ControlValueAccessor {

    public libelles = {
        draft: 'En préparation',
        sent: 'Offre envoyée',
        negociation: 'Négociation',
        promise: 'Promesse',
        signed: 'Signé',
        canceled: 'Perdu'
    };
    initialValue: {id: number, libelle: string};
    @Input() formControl: AbstractControl;
    @Input() modalOpened: boolean;
    @Input() currentValue: Subject<number>;
    probabilite = 0;
    @Output() changed = new EventEmitter<string>();
    constructor() {
    }

    ngOnInit(): void {
        this.probabilite = 0;
        this.currentValue?.subscribe( next => {
            this.probabilite = next;
        });
        this.initialValue = this.formControl?.value;
        this.probabilite = this.initialValue?.id;
    }

    public changeStep(value): void{
        if (this.formControl.value?.id < value && this.formControl.value?.id < 100){
            this.probabilite =  value;
        }
    }
    public restoreValue(): void{
        if (!this.modalOpened){
            this.probabilite = this.formControl.value?.id;
        }
    }

    public updateValue(value): void{
            this.changed.emit(value);
    }

    ngOnDestroy(): void {
    }

    registerOnChange(fn: any): void {
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
    }
}
