import {Component, Input, OnInit} from '@angular/core';
import {Personne} from '../../../../models/personne';
import {PersonGlobals} from '../../../../const-global/globals';
import {IMemo} from '../../../../interfaces/memo';
import {MemoService} from '../../../_services/memo.service';
import {Router} from '@angular/router';
import {Lead} from "../../../_services/lead.service";


@Component({
    selector: 'app-creer-memo',
    templateUrl: './creer-memo.component.html',
    styleUrls: ['./creer-memo.component.scss']
})
export class CreerMemoComponent implements OnInit {

    @Input() personne: Personne;

    @Input() memoFiche: boolean;

    @Input() offre: Lead;

    @Input() persist;
    public memoTerme;
    public readonly PERSON_GLOBALS = PersonGlobals;

    constructor(private memoService: MemoService, public router: Router) {
    }

    ngOnInit(): void {
        this.memoService.subjectMemo.subscribe(value => {
            this.memoTerme = value;
        });
    }

    // permet de set le memo dans le formulaire d'edit d'une personne sur chaque keydown
    setMemoTerme($event): void {
        if (this.offre && $event.key === 'Enter' && this.persist){
            this.addMemo();
        }
        this.memoService.subjectMemo.next(this.memoTerme);
    }

    addMemo(): void {
        if (this.memoTerme) {
            let uuid = null;
            if (this.personne){
                uuid = this.personne.uuid;
            }
            if (this.offre){
                uuid = this.offre.uuid;
            }
            this.memoService.add(this.memoTerme, uuid, this.persist).subscribe((data: IMemo) => {
                if (this.personne){
                    this.personne.memos.unshift(data);

                }else if (this.offre){
                    this.offre.memos.unshift(data);
                }
                },
                err => {
                    console.error(err);
                }
            );
            this.memoTerme = '';
        }
    }

}
