import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {Fetcher} from "../../form/entity-select/entity-select.component";
import {PersonneLienService} from "../../_services/personne-lien.service";

@Component({
    selector: 'app-apporteur-select2',
    templateUrl: './apporteur-select2.component.html',
    styleUrls: ['./apporteur-select2.component.scss']
})
export class ApporteurSelect2Component {

    @Input() control: FormControl|AbstractControl = new FormControl();
    @Input() notUuid: string;
    fetcher: Fetcher<any>

    constructor(private personneService: PersonneLienService) {
        this.fetcher = (filter) => {
            return personneService.list({
                ...filter,
                'order[countApportes]': 'desc',
                'type[]': ['physique'],
                'not:uuid[]': this.notUuid ? [this.notUuid] : null
            });
        }
    }
}
