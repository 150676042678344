import {Component, Input, OnInit} from '@angular/core';
import {PersonneService} from '../../../_services/personne.service';
import {Personne} from '../../../../models/personne';
import {NotifierService} from 'angular-notifier';
import {PersonGlobals, RappelGlobals} from '../../../../const-global/globals';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-fiche-incomplete-rappel',
    templateUrl: './fiche-incomplete-rappel.component.html',
    styleUrls: ['./fiche-incomplete-rappel.component.scss']
})
export class FicheIncompleteRappelComponent implements OnInit {
    public readonly PERSON_GLOBAL = PersonGlobals;
    public readonly RAPPEL_GLOBAL = RappelGlobals;

    @Input() tabName: string;
    public listePersonnes: Array<Personne> = [];

    public plUuid = this.route.snapshot.params.id;

    // Paramètre request
    public offsetPersonne = 0;
    public limitPersonne = 40;
    private order = 'DESC';
    private activeFiltre = 'true';
    private fetchOnGoing = false;
    private finListe = false;

    constructor(
        private personneService: PersonneService,
        private notifier: NotifierService,
        private route: ActivatedRoute,
        public router: Router,
    ) {}

    ngOnInit(): void {
        this.getPersonnesListe()
    }

    private getPersonnesListe() : void {
        this.fetchOnGoing = true;
        this.personneService.getListePersonnesIncomplete(
            this.offsetPersonne.toString(),
            this.limitPersonne.toString(),
            this.activeFiltre.toString(),
            this.order
        ).subscribe(data => {
                this.listePersonnes.push(...data);
                this.fetchOnGoing = false;
                this.finListe = data.length < this.limitPersonne
            },
            err => {
                console.log(err);
            }
        );
    }

    public onScroll(): void {
        if (!this.fetchOnGoing && !this.finListe) {
            this.offsetPersonne = this.offsetPersonne + this.limitPersonne;
            this.getPersonnesListe();
        }
    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    public openFiche(uuid: string): void {
        this.router.navigate(['/repertoire/' + uuid]);
    }

    public modifierFiche(uuid: string): void {
        this.router.navigate(['/repertoire/' + uuid + '/modifier']);
    }
}
