import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HelperService} from '../_services/helper.service';
import {PersonneService} from '../_services/personne.service';
import {VisibiliteService} from '../_services/visibilite.service';
import {Subscription} from 'rxjs';
import {IUser} from '../../interfaces/user';
import {ModalService} from '../_modal';
import {PersonGlobals} from '../../const-global/globals';
import {ActivatedRoute, Router} from '@angular/router';
import {Validators} from "@angular/forms";

@Component({
    selector: 'app-bloc-visibilite',
    templateUrl: './bloc-visibilite.component.html',
    styleUrls: ['./bloc-visibilite.component.scss']
})
export class BlocVisibiliteComponent implements OnInit {

    @Input() form;
    @Input() type: string;
    @Input() creation: boolean;
    @Input() typePersonne: string;
    private subscriptionName: Subscription;
    public uuidFichePersonneLien = this.route.snapshot.params.id;
    public listPersonneAttached = Array<any>();

    constructor(
        public router: Router,
        private helperService: HelperService,
        private ref: ChangeDetectorRef,
        private personneService: PersonneService,
        private visibiliteService: VisibiliteService,
        private modalService: ModalService,
        private route: ActivatedRoute
    ) {
    }


    ngOnInit(): void {
        this.uuidFichePersonneLien = this.router.url.split('/')[2];

        this.form.get('visibilite').valueChanges.subscribe(visibility => {
            this.form.get('referent').setValidators(1 !== visibility ? Validators.required : null);
            this.form.get('referent').updateValueAndValidity();
        });
        // set la visibilité par defaut à privé
        if (this.creation === true && typeof this.form.control?.visibilite === 'undefined') {
            this.form.controls.visibilite.setValue(1);
        }
        if (this.form && this.form.controls?.uuid) {
            this.subscriptionName = this.visibiliteService.receiveData().subscribe(
                data => {
                    const intervenants = [];
                    data.map((item) => {
                        if (item.type === 1) {
                            this.form.get('referent').setValue(item.uuid);
                        } else if (item.type === 2) {
                            intervenants.push(item.uuid);
                        }
                    });
                    this.form.get('intervenants').setValue(intervenants);

                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    onChangeActif($event): void {
        this.form.controls.actif.setValue($event.target.checked);
    }

    getPersonneRattachee(uuid): void {
        this.listPersonneAttached = [];
        this.personneService.getPersonneLiens(uuid).subscribe(liens => {
            liens.forEach(onglet => {
                if (onglet.type === PersonGlobals.PERSON_LIEN) {
                    this.listPersonneAttached.push(onglet);
                }
            });
        });
    }

    openModal(idModal: string): void {
        if (this.router.url.startsWith('/repertoire/')){
            if (this.typePersonne === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
                this.modalService.open(idModal);
                this.getPersonneRattachee(this.uuidFichePersonneLien);
            }
        }
    }

    closeModal(idModal): void {
        this.modalService.close(idModal);
    }
}
