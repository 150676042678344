import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NoteComponent),
        multi: true,
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteComponent implements ControlValueAccessor {
    @Input() set max(max: number) {
        this.values = new Array(max)
        for (let i = 0; i < max; i++) {
            this.values[i] = i+1;
        }
    }
    onChange: any = () => {};
    onTouched: any = () => {};
    disabled = false;
    values = [1,2,3];
    value: number;
    touched = false;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    setValue(value) {
        if (this.disabled || value === this.value) {
            return ;
        }
        this.markAsTouched()
        this.value = value;
        this.onChange(value);
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    writeValue(value: number): void {
        if (this.value === value) {
            return ;
        }
        this.value = value;
        this.changeDetector.markForCheck()
    }
}
