import {Component, OnInit} from '@angular/core';
import {OrigineService} from '../../_services/origine.service';
import {ActivatedRoute, Router} from '@angular/router';
import {IOrigine} from '../../../interfaces/origine';
import {NotifierService} from 'angular-notifier';
import {ParametreGlobals} from '../../../const-global/globals';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-liste-origines-de-contact',
    templateUrl: './liste-origines-de-contact.component.html',
    styleUrls: ['./liste-origines-de-contact.component.scss']
})
export class ListeOriginesDeContactComponent implements OnInit {
    public plUuid = this.route.snapshot.params.id;
    public origines: Array<IOrigine> = [];
    public recherche: '';
    private offset = 0;
    private limit = 20;
    private errorMessage = '';

    constructor(
        public origineService: OrigineService,
        private route: ActivatedRoute,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        public notifier: NotifierService
    ) {
    }

    ngOnInit(): void {
        const ls = localStorage.getItem('rechercheValue');
        if (ls){
            const lsValue = JSON.parse(ls);
            if (lsValue && lsValue.origine){
                this.onRecherche(lsValue.origine);
            }else{
                this.onRecherche('');
            }
        }else{
            this.onRecherche('');
        }
    }

    public openOrigine(uuid: string, systeme: string): void {
        if (systeme === 'Système') {
            this.showNotification('warning', 'Vous n\'avez pas les droits pour modifier les origines de contact systèmes');
        } else {
            this.router.navigate(['origine/modifier/' + uuid]);
        }
    }

    public onRecherche(recherche): void {
        this.ngxService.start();
        this.offset = 0;
        this.recherche = recherche;
        this.origineService.searchOrigine(
            this.offset.toString(),
            this.limit.toString(),
            this.recherche
        ).subscribe((data) => {
                this.origines = [];
                data.forEach((origine: IOrigine) => {
                    if (origine.systeme) {
                        origine.systeme = ParametreGlobals.SYSTEM_LIBELLE;
                    } else {
                        origine.systeme = ParametreGlobals.PERSONNALISE_LIBELLE;
                    }
                    this.origines.push(origine);
                });
                this.ngxService.stop();
            },
            err => {
                this.errorMessage = err.error.message;
                this.ngxService.stop();
                this.showNotification('error', this.errorMessage);
            }
        );
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }

}
