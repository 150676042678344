import {Personne} from './personne';

export class PersonnePhysique extends Personne {

    constructor() {
        super();
    }

    public ppUuid?: string;
    public isUser?: boolean;
    public user?: any;
    public nom?: string;
    public prenom?: string;
    public titre?: string;
    public origine?: any;
    public apporteur?: any;
    public infoCommerciale?: boolean;
    public civilite?: string;
    public personnePhysiqueLibelle?: string;
    public referent: boolean;

    public fillFromDatas(personne: any): void {

        super.fillFromDatas(personne);
        Object.assign(this, personne);
    }
}
