import {Component, Input, OnInit} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {IUser} from '../../../interfaces/user';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-users-select2',
    templateUrl: './users-select2.component.html',
    styleUrls: ['./users-select2.component.scss']
})
export class UsersSelect2Component implements OnInit {
    @Input() formCreation: boolean;
    @Input() form: FormGroup;
    @Input() multiple: boolean;
    @Input() id: string;
    data = [];
    compareWith = (item, value) => {
        return item.id === value
    };

    constructor(private personneService: PersonneService) {}

    ngOnInit(): void {
        this.personneService.getListeUsers(true).subscribe((data: IUser[]) => {
                const referents = [];
                data.map((item: IUser) => {
                        referents.push({
                            id: item.uuid,
                            text: item.prenom + ' ' + item.nom
                        });
                    }
                );

                referents.sort(function(a, b){
                    return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
                });

                if (this.formCreation === false && this.form.value?.assignedTo) {
                    this.form.controls[this.id].setValue(this.form.value?.assignedTo);
                }
                this.data = referents
            },
            err => {
                console.error(err);
            }
        );
    }
}
