import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-carte-produit',
  templateUrl: './carte-produit.component.html',
  styleUrls: ['./carte-produit.component.scss']
})
export class CarteProduitComponent implements OnInit {

  @Input() product: any;
  public prixTypeLibelle: string;

  constructor(public router: Router) {

  }

  ngOnInit(): void {
    this.product.prixUnitaire = this.product.prixUnitaire / 100;
    switch (this.product.typeFacturation) {
      case 0:
        this.prixTypeLibelle = 'Prix unitaire HT';
        break;
      case 1:
        this.prixTypeLibelle = 'Prix horaire HT';
        break;
      case 2:
        this.prixTypeLibelle = 'Prix unitaire HT / mois';
        break;
      default:
        this.prixTypeLibelle = '';
        break;
    }
  }

  public openCard(product: any): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/parametres/catalogue-produits/' + product.uuid]).then(r => {});
  }
}
