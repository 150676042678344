import {Component, ViewEncapsulation} from '@angular/core';
import {ConsentHelper} from "../../../_helpers/consent.helper";
import {CheckboxFilterItemComponent} from "../checkbox-filter-item/checkbox-filter-item.component";

@Component({
    selector: 'app-mail-consent',
    templateUrl: '../checkbox-filter-item/checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class MailConsentComponent extends CheckboxFilterItemComponent {
    constructor() {
        super();

        const consent = [];
        ConsentHelper.consents.forEach(item => {
            consent.push({titre: item.text, uuid: item.id.toString()});
        });
        this.dataSource = consent;
    }
}
