import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {Observable} from 'rxjs';
import {NgSelect2Component, Select2OptionData} from 'ng-select2';
import {Options} from 'select2';
import {FormBuilder, FormGroup} from '@angular/forms';
import {v4 as uuidv4} from 'uuid';
import {ChampsPersoGlobals} from '../../../const-global/champs-perso';

@Component({
    selector: 'app-champs-personalises',
    templateUrl: './champs-personalises.component.html',
    styleUrls: ['./champs-personalises.component.scss']
})
export class ChampsPersonalisesComponent implements OnInit {
    public readonly CHAMPS_GLOBAL = ChampsPersoGlobals;
    @Input() type: string; // type personne
    @Input() uuid: string;
    @Input() editForm: FormGroup;
    @Input() formValeurs: Array<any>;
    @Output() valeursEvent = new EventEmitter<object>();

    @ViewChildren('select2') select2Champ: QueryList<NgSelect2Component>;

    public form: FormGroup;
    public CheckBoxValeurs = [];
    public listeChamps = [];
    public listeChampsVisibles = [];
    public champs = [];
    public options: Options;
    public optionsMultiple: Options;

    public data: Observable<Array<Select2OptionData>>;
    public disabled = false;

    constructor(
        private formBuilder: FormBuilder,
        private personneService: PersonneService,
    ) {
    }

    ngOnInit(): void {
        this.options = {};
        this.optionsMultiple = {multiple: true};

        this.form = this.formBuilder.group({});
        if (this.uuid && this.type) {
            this.getPersonneChamps();
        } else if (this.type) {
            this.fetchChamps();
        }
    }

    getPersonneChamps(): void {
        this.personneService.getPersonneChamps(this.uuid).subscribe(
            data => {
                this.champs = data;
                if (data.length) {
                    data.map((item) => {
                        this.formValeurs.push({id: item.uuid, value: item.valeur});
                        this.valeursEvent.emit(this.formValeurs);
                    });
                }
                this.fetchChamps();
            },
            err => {
                console.log(err);
            }
        );
    }

    fetchChamps(): void {
        this.personneService.getChampsDisponbiles(this.type).subscribe(
            data => {
                this.listeChamps = data;
                const AselectData = [];
                const AcheckBox = [];
                const Atext = [];
                const AtextArea = [];
                const Aemail = [];
                const AtelFax = [];
                const Adate = [];
                const AdateTime = [];
                const AInteger = [];
                const AMoney = [];
                const AInputsrray = [];
                AInputsrray.push(Atext, AtextArea, Aemail, AtelFax, Adate, AdateTime, AInteger, AMoney);
                data.map((item) => {
                    item.disabled = item.requis === true;
                    if(item.requis === true){
                        this.listeChampsVisibles.push(item);
                    }else{
                        if (this.champs.length) {
                            this.champs.map((valeur) => {
                                if (valeur.uuid === item.uuid) {
                                    item.disabled = true;
                                    this.listeChampsVisibles.push(item);
                                }
                            });
                        }
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_LIBRE_MULTIPLE_LINE.text) {
                        AtextArea.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_LIBRE_SINGLE_LINE.text) {
                        Atext.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_EMAIL.text) {
                        Aemail.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_TEL_FAX.text) {
                        AtelFax.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_DATE.text) {
                        Adate.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_DATE_TIME.text) {
                        AdateTime.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_INTEGER.text) {
                        AInteger.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_MONEY.text) {
                        AMoney.push(item);
                    }
                    if (item.type === this.CHAMPS_GLOBAL.CHAMP_CHECKBOX.text) {
                        if (item.valeurs) {
                            const checkBoxValues = item.valeurs.map(val => {
                                AcheckBox.push(item);
                                const obj = {};
                                obj[val.id] = false;
                                if (this.champs.length > 0) {
                                    this.champs.map((valeur) => {
                                        if (valeur.uuid === item.uuid) {
                                            if (typeof valeur.valeur === 'string') {
                                                if (valeur.valeur === val.id) {
                                                    obj[val.id] = true;
                                                }
                                            } else {
                                                valeur.valeur.map((itemValeur) => {
                                                    if (itemValeur.toString() === val.id) {
                                                        obj[val.id] = true;
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                                return this.formBuilder.group(obj);
                            });

                            const formArray = this.formBuilder.array(checkBoxValues);
                            this.form.addControl(item.uuid, formArray);
                        }

                    } else {
                        this.form.addControl(item.uuid, this.formBuilder.control({}));
                    }
                    AselectData.push({
                        id: item.uuid,
                        text: item.libelle,
                        disabled: this.disableItemIfSelected(item)
                    });
                });
                AInputsrray.map((array) => {
                    array.map((item) => {
                        this.setDefaultValue(item);
                    });
                });
                this.data = new Observable<Select2OptionData[]>(obs => {
                    obs.next(AselectData);
                    obs.complete();
                });
                if (this.champs.length) {
                    this.champs.map((valeur) => {
                        this.listeChampsVisibles.map((item) => {
                            if (item.uuid === valeur.uuid) {
                                if (item.type === this.CHAMPS_GLOBAL.CHAMP_CHECKBOX.text) {
                                    item.valeurs.map((val) => {
                                            const id = val.id;
                                            const bool = valeur.valeur === val.id;
                                            if (valeur.valeur === val.id && bool) {
                                                this.form.controls[valeur.uuid].get(id).patchValue({
                                                    id: bool
                                                });
                                            }
                                        }
                                    );
                                } else if (item.type === this.CHAMPS_GLOBAL.CHAMP_MONEY.text) {
                                    if (Number.isInteger(valeur.valeur)) {
                                        valeur.valeur = (valeur.valeur / 100).toLocaleString();
                                    }
                                    this.form.controls[valeur.uuid].setValue(
                                            parseFloat(valeur.valeur.replace(',', '.'))
                                        );

                                } else {
                                    this.form.controls[valeur.uuid].setValue(valeur.valeur);
                                }
                            }
                        });
                    });
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    // set des valeurs par defaut dans les valeurs des champs
    setDefaultValue(item): void {
        if (item.valeurs && Array.isArray(item.valeurs)) {
            item.valeurs.map((val) => {
                this.form.controls[item.uuid].setValue(val.text);
            });
        } else {
            this.form.controls[item.uuid].setValue('');
        }
    }

    disableItemIfSelected(item): boolean {
        let disabled = false;
        if (this.listeChampsVisibles) {
            this.listeChampsVisibles.forEach(champ => {
                if (item.uuid === champ.uuid) {
                    disabled = true;
                    return;
                }
            });
        }
        return disabled;
    }

    onChange($event, uuid: string): void {
        this.formValeurs = this.formValeurs.filter(obj => obj.id !== uuid);
        this.formValeurs.push({id: uuid, value: this.form.controls[uuid].value});
        this.valeursEvent.emit(this.formValeurs);
    }

    onChangeCheckBox($event, uuid: string): void {
        this.formValeurs.map((valeur) => {
            if (valeur.id === uuid) {
                this.CheckBoxValeurs = valeur.value;
            }
        });
        if ($event.target.checked) {
            this.CheckBoxValeurs.push($event.target.name);
        } else {
            this.CheckBoxValeurs = this.CheckBoxValeurs.filter(obj => obj !== $event.target.name);
        }
        this.formValeurs = this.formValeurs.filter(obj => obj.id !== uuid);
        this.formValeurs.push({id: uuid, value: this.CheckBoxValeurs});
        this.valeursEvent.emit(this.formValeurs);
    }

    onValueChange($event, uuid: string): void {
        this.formValeurs = this.formValeurs.filter(obj => obj.id !== uuid);
        this.formValeurs.push({id: uuid, value: $event});
        this.valeursEvent.emit(this.formValeurs);
    }

    onChampChange($event, uuid: string): void {
        let nouveauChamp;
        let ancienChamp;
        this.listeChamps.map((item) => {
            if (item.uuid === $event) {
                nouveauChamp = item;
                this.onChange($event, item.uuid);
            }
            if (item.uuid === uuid) {
                ancienChamp = item;
            }
        });
        if (this.listeChampsVisibles.indexOf(nouveauChamp) < 0) {
            this.listeChampsVisibles[this.listeChampsVisibles.indexOf(ancienChamp)] = nouveauChamp;
        }
        if (!ancienChamp) {
            this.listeChampsVisibles.map((item) => {
                if (item.uuid === uuid) {
                    ancienChamp = item;
                }
            });
            this.listeChampsVisibles[this.listeChampsVisibles.indexOf(ancienChamp)] = nouveauChamp;
        }
    }

    buildSelectOptions(): Array<Select2OptionData> {
        const dataDisabled: Select2OptionData[] = [];
        this.listeChamps.forEach(champ => {
            dataDisabled.push({
                id: champ.uuid,
                text: champ.libelle,
                disabled: this.disableItemIfSelected(champ)
            });
        });
        return dataDisabled;
    }

    ajouterChamp(): void {
        // empêche d'ajouter plus de champs que de valeurs disponibles
        if (this.listeChampsVisibles.length < this.listeChamps.length) {
            this.data = new Observable<Select2OptionData[]>(obs => {
                obs.next(this.buildSelectOptions());
                obs.complete();
            });
            this.listeChampsVisibles.push({uuid: uuidv4()});
        }
    }

    onSupprimer(uuid: string): void {
        if (this.form.controls[uuid]) {
            const data = {id: uuid, value: this.form.controls[uuid].value};
            this.formValeurs = this.formValeurs.filter(obj => obj.id !== data.id);
            this.valeursEvent.emit(this.formValeurs);
        }
        this.listeChamps.filter(obj => {
            if (obj.uuid === uuid) {
                obj.disabled = false;
                return obj;
            }
        });
        this.listeChampsVisibles = this.listeChampsVisibles.filter(obj => obj.uuid !== uuid);
    }
}
