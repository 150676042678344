import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-segment',
    templateUrl: './segment.component.html',
    styleUrls: ['./segment.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SegmentComponent {
    constructor(
        public router: Router,
    ) {}
}
