import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonneService} from '../../_services/personne.service';
import {EvenementService} from '../../_services/evenement.service';
import {NotifierService} from 'angular-notifier';
import * as moment from 'moment';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../_services/exception.service';
import {Personne} from '../../../models/personne';
import {CoordonneeGlobals, EvenementGlobals} from '../../../const-global/globals';
import {IEvenement} from '../../../interfaces/evenement';
import {formatDate} from '@angular/common';
import {shareReplay} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {ModalService} from '../../_modal';
import {MessageGlobals} from "../../../const-global/messages";

@Component({
    selector: 'app-form-evenement',
    templateUrl: './form-evenement.component.html',
    styleUrls: ['./form-evenement.component.scss']
})
export class FormEvenementComponent implements OnInit, AfterViewChecked {
    @Output() closed = new EventEmitter<any>();

    public readonly EVENEMENT_GLOBALS = EvenementGlobals;
    public readonly COORD_GLOBALS = CoordonneeGlobals;
    public event: IEvenement;
    public form: FormGroup;
    public dataPersonne: Personne;
    public uuidPersonne = this.route.snapshot.params.idPersonne;
    public uuidEvent = this.route.snapshot.params.idEvenement;
    public assignedTo: string;
    public userCurrent: Observable<any> = new ReplaySubject();
    public message: string;
    public statut: string;
    public current = moment().format('L');
    public moment = moment().locale('fr_FR');
    public formCreation = true;
    private dashboard = 'false';
    private rappel = 'false';
    public statutEvent;
    public fetcher = () => this.evenementService.getListeTypeEvent();

    // compteur coordonnées et adresses
    public countMail = 0;
    public countTel = 0;
    public countSite = 0;
    public countAdresse = 0;

    @Input() formValeurs;
    @Output() valeursEvent = new EventEmitter<object>();

    constructor(
        private router: Router,
        private personneService: PersonneService,
        public evenementService: EvenementService,
        private formBuilder: FormBuilder,
        private ref: ChangeDetectorRef,
        private route: ActivatedRoute,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        public notifier: NotifierService,
        private modalService: ModalService
    ) {
        this.notifier = notifier;
    }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                    this.dashboard = params?.dashboard;
                    this.rappel = params?.rappel;
                }
            );
        this.form = this.formBuilder.group({
                libelle: ['', Validators.required],
                type: ['', Validators.required],
                statut: ['', Validators.required],
                assignedTo: ['', Validators.required],
                dateEcheance: ['', Validators.required],
                timeEcheance: ['', Validators.required],
                description: ['']
            }, {}
        );

        this.userCurrent = this.personneService.getCurrentUser().pipe(
            shareReplay()
        );

        // appel de getPersonne pour récupérer les infos dans contact
        if (!this.uuidEvent) {
            this.formCreation = true;
            this.userCurrent.subscribe(user => {
                this.form.controls.assignedTo.setValue(user?.plUuid);
            });
        }
        if (this.uuidEvent) {
            this.formCreation = false;
            this.getEvent();
        }
    }

    ngAfterViewChecked(): void {
        this.ref.detectChanges();
    }

    public onSubmit(): void {
        this.ngxService.start();
        const formData = {
            libelle: this.form.controls.libelle.value,
            type: this.form.controls.type.value.id,
            statut: this.form.controls.statut.value,
            assignedTo: this.form.controls.assignedTo.value,
            dateEcheance: this.form.controls.dateEcheance.value,
            timeEcheance: this.form.controls.timeEcheance.value,
            description: this.form.controls.description.value,
            uuidPersonne: this.uuidPersonne
        };
        if (this.form.valid) {
            if (this.event) {
                this.evenementService.modifierEvenement(this.uuidEvent, formData).subscribe(
                    () => {
                        if (parseInt(formData.statut, 10) === this.EVENEMENT_GLOBALS.STATUT_ANNULE) {
                            this.router.navigate(['/repertoire/' + this.uuidPersonne]);
                        } else {
                            this.ngxService.stop();
                            this.getEvent();
                        }
                        this.showNotification('success', 'L\'évènement ' + this.form.controls.libelle.value + ' vient d\'être mis à jour');
                        this.evenementService.majNbEventRappel();
                    },
                    err => {
                        this.ngxService.stop();
                        this.showNotification('error', this.exceptionService.statutErreur(err));
                    }
                );
            } else {
                this.evenementService.creerEvenement(formData).subscribe(
                    () => {
                        this.ngxService.stop();
                        this.router.navigate(['/repertoire/' + this.uuidPersonne]);
                    },
                    err => {
                        this.ngxService.stop();
                        this.showNotification('error', this.exceptionService.statutErreur(err));
                    }
                );
            }
        } else {
            this.ngxService.stop();
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
    }

    public getEvent(): void {
        this.evenementService.getEvenement(this.uuidEvent).subscribe(
            (event: IEvenement) => {
                this.event = event;
                this.event.statut = event.statut;
                this.assignedTo = event.userAssigne.uuid;
                this.uuidPersonne = event.lien.uuid;
                this.form.controls.assignedTo.setValue(this.assignedTo);
                this.form.patchValue(event);
                // le set value de la date est après le patchValue (c'est voulut)
                this.form.controls.dateEcheance.setValue(formatDate(event.dateEcheance, 'yyyy-MM-dd', 'en'));
                this.form.controls.timeEcheance.setValue(formatDate(event.dateEcheance, 'HH:mm', 'en'));
                if (this.event.idMeetlaw) {
                    this.form.controls.type.disable();
                    this.form.controls.libelle.disable();
                    this.form.controls.statut.disable();
                    this.form.controls.assignedTo.disable();
                    this.form.controls.dateEcheance.disable();
                    this.form.controls.timeEcheance.disable();
                    this.form.controls.description.disable();
                    this.form.controls.uuidPersonne?.disable();
                }
            }, err => {
                this.showNotification('error', this.exceptionService.statutErreur(err));
                this.router.navigate(['/repertoire/' + this.uuidPersonne]);
            }
        );
    }

    // Permet de compter le nombre de coordonnées et adresses secondaire pour chaque type
    public countCoordSecondaire(): void {
        this.dataPersonne.mails?.forEach(mail => {
            if (mail.principal !== true) {
                this.countMail++;
            }
        });
        this.dataPersonne.telephones?.forEach(telephone => {
            if (telephone.principal !== true) {
                this.countTel++;
            }
        });
        this.dataPersonne.adresses?.forEach(adresse => {
            if (adresse.principal !== true) {
                this.countAdresse++;
            }
        });
        this.dataPersonne.sites?.forEach(site => {
            if (site.principal !== true) {
                this.countSite++;
            }
        });
    }

    public onCancel(): void {
        if (this.rappel === 'true') {
            this.userCurrent.subscribe(user => {
                this.router.navigate(['/rappel/' + user.plUuid], {
                    queryParams: {
                        onglet: 'tache'
                    }
                });
            });
        } else if (this.dashboard === 'true') {
            this.router.navigate(['/home']);
        } else {
            this.router.navigate(['/repertoire/' + this.uuidPersonne]);
        }
    }


    public onSubmitCancel(): void {
        this.onSubmit();
        setTimeout(() => {
            this.onCancel();
        }, 1000);
    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    public verificationChamps(): void {
        if (!this.form.valid) {
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
    }

    onChangedSelect2Create($event: string, id: string, statutEvent: number): void {
        this.form.controls[id].setValue($event);
        this.titleComplete(statutEvent);
    }

    onChangedSelect2Edit($event: number, id: string): void {
        this.form.controls[id].setValue($event);
    }

    onChangedAssignedTo($event): void {
        this.form.controls.assignedTo.setValue($event);
    }

    onChangedStatut(statut: number): void {
        this.titleComplete(statut);
    }

    public openModal(id: string): void {
        this.modalService.open(id);
    }

    public closeModal(idModal): void {
        this.closed.emit(true);
        this.modalService.close(idModal);
    }

    setStatutEvent(status): void {
        if (status === this.EVENEMENT_GLOBALS.STATUT_ANNULE) {
            this.form.controls.statut.setValue(status);
        }

        this.closeModal('annuleModalEvent');
    }

    /**
     * Permet de d'auto-compléter le libellé en fonction du type et du statut
     */
    titleComplete(statut: number): void {
        this.statutEvent = statut;

        if (this.statutEvent === EvenementGlobals.STATUT_ANNULE) {
            this.openModal('annuleModalEvent');
        }
        // appel
        if (parseInt(this.form.controls.type.value.id, 10) === EvenementGlobals.APPEL_ENTRANT_ID) {
            const appel = (this.statutEvent === EvenementGlobals.STATUT_A_FAIRE)
                ? EvenementGlobals.APPEL_A_RECEVOIR
                : EvenementGlobals.APPEL_RECU;
            this.form.controls.libelle.setValue(appel);
        }
        if (parseInt(this.form.controls.type.value.id, 10) === EvenementGlobals.APPEL_SORTANT_ID) {
            const appel = (this.statutEvent === EvenementGlobals.STATUT_A_FAIRE)
                ? EvenementGlobals.APPEL_A_PASSER
                : EvenementGlobals.APPEL_PASSE;
            this.form.controls.libelle.setValue(appel);
        }
        // email
        if (parseInt(this.form.controls.type.value.id, 10) === EvenementGlobals.MAIL_ENTRANT_ID) {
            const email = (this.statutEvent === EvenementGlobals.STATUT_A_FAIRE)
                ? EvenementGlobals.EMAIL_A_RECEVOIR
                : EvenementGlobals.EMAIL_RECU;
            this.form.controls.libelle.setValue(email);
        }
        if (parseInt(this.form.controls.type.value.id, 10) === EvenementGlobals.MAIL_SORTANT_ID) {
            const email = (this.statutEvent === EvenementGlobals.STATUT_A_FAIRE)
                ? EvenementGlobals.EMAIL_A_ENVOYER
                : EvenementGlobals.EMAIL_ENVOYE;
            this.form.controls.libelle.setValue(email);
        }
        // rdv domicile
        if (parseInt(this.form.controls.type.value, 10) === EvenementGlobals.RDV_CABINET_ID) {
            this.form.controls.libelle.setValue(EvenementGlobals.RDV_CABINET);
        }
        // rdv exterieur
        if (parseInt(this.form.controls.type.value, 10) === EvenementGlobals.RDV_EXTERIEUR_ID) {
            this.form.controls.libelle.setValue(EvenementGlobals.RDV_EXTERIEUR);
        }
        // devis
        if (parseInt(this.form.controls.type.value, 10) === EvenementGlobals.DEVIS_ID) {
            this.form.controls.libelle.setValue(EvenementGlobals.DEVIS);
        }
        // changement de statut
        if (parseInt(this.form.controls.type.value, 10) === EvenementGlobals.CHANGEMENT_STATUT_ID) {
            this.form.controls.libelle.setValue(EvenementGlobals.CHANGEMENT_STATUT);
        }
    }
}

