<div class="layout">
    <div class="container message_information alert" *ngIf="isUserPasswordTemp && personne">
        Vous utilisez actuellement un mot de passe temporaire.&nbsp;
        Veuillez modifier&nbsp;<a routerLink="/utilisateurs/{{personne.userUuid}}/mot-de-passe">votre mot de passe</a>.
    </div>
    <div class="header">
        <app-menu [personneConnecte]="personne"
                  [uuidPersonne]="uuidPersonne"
                  *ngIf="personne"
                  [nbEventEnRetard]="nbEventEnRetard"
        >
        </app-menu>
    </div>
    <div class="main_container" #mainContainer>
        <ng-container *ngIf="personne && organisation">
            <div class="volet volet_parametre parametre_ferme" id="voletParametre" (mouseleave)="closeVolet()">
                <app-volet-parametre [personneConnecte]="personne" [organisation]= "organisation"></app-volet-parametre>
            </div>
            <div class="volet volet_rappel rappel_ferme" id="voletRappel" (mouseleave)="closeVolet()" *ngIf="personne">
                <app-volet-rappel [uuidPersonneCo]="uuidPersonne"
                                  [rappel]="true"
                                  [dashboard]="false"
                                  [limit]="6"
                ></app-volet-rappel>
            </div>
            <router-outlet></router-outlet>
        </ng-container>
    </div>
</div>
