import {IDevisLigne} from '../interfaces/devisligne';

export class Devisligne implements IDevisLigne{
    '@id'?: string;
    'uuid'?: string;
    produitId?: number;
    libelle?: string;
    prixUnitaireHT?: number;
    quantite?: number;
    tauxTVA?: number;
    devis?: any;
    dateCreation?: Date;
    dateModification?: Date;
    userCreation?: any;
    userModification?: any;
    poids?: number;
    remise?: number;
    remiseHT?: number;
    remiseDisabled?: boolean;
    remiseHTDisabled?: boolean;
    produit?: any;
    typeRemise?: number;

    constructor(data?: object) {
        Object.assign(this, data);
        this.remiseDisabled = false;
        this.remiseHTDisabled = false;
    }
}

