import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot,  Resolve} from '@angular/router';
import {SegmentService} from '../_services/segment.service';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SegmentResolver implements Resolve<Observable<any>> {
    constructor(private service: SegmentService) {}

    resolve = (
        route: ActivatedRouteSnapshot
    ): Observable<any> => {
        return this.service.getFilAriane(route.paramMap.get('id')).pipe(
            catchError((error) => {
                console.error(error);
                return of('N/A');
          })
        );
    }
}
