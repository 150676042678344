<div class="bloc_title_modal">{{titre}}</div>
<div class="bloc_body_modal aide">
    <div>
        {{aide}}
    </div>
    <div class="bas">
        {{bas}}
    </div>
</div>
<div class="bloc_footer_modal">
    <div class="action_bloc_modal">
        <button *ngIf="fermerOn" type="reset" class="btn btn-med btn-fermer" (click)="this.fermer.emit()">
            <span>{{fermerTexte}}</span>
        </button>
        <button *ngIf="validerOn" type="reset" class="btn btn-med btn-valider" (click)="this.valider.emit()">
            <span>{{validerTexte}}</span>
        </button>
    </div>
</div>