import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseSelect2Component} from '../base-select2/base-select2.component';
import {IOperator} from '../../_helpers/segment.helper';

@Component({
    selector: 'app-operateurs-select2',
    templateUrl: './operateurs-select2.component.html',
    styleUrls: ['./operateurs-select2.component.scss']
})
export class OperateursSelect2Component extends BaseSelect2Component implements OnChanges{
    @Input() listeOperateurs: Array<any>;
    @Input() operateur;
    @Input() filtre;
    @Input() refreshCustom: any;
    public dataset;

    constructor() {
        super();
    }
    ngOnChanges(changes: SimpleChanges): void {
        // tslint:disable-next-line:forin
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'refreshCustom': {
                        this.initSelect();
                    }
                                          break;
                }
            }
        }
    }
    initSelect(): void {
        this.value = this.operateur.value.uuid;
        this.dataset = [];
        const groupes = [];
        let size = 0;
        let selectId = null;
        if (typeof this.listeOperateurs !== 'undefined') {
            const opes = this.listeOperateurs;
            Object.keys(this.listeOperateurs).map((key, index) => {
                const groupe = {
                    text: key,
                    children: []
                };
                opes[key].map((operateur: IOperator) => {
                    if (operateur.operateursValues.includes(this.filtre.value.champ.value.operateursValues)){
                        size ++;
                        selectId = operateur.uuid;
                        groupe.children.push({
                            id: operateur.uuid,
                            text: operateur.text,
                            nbValeurs: operateur.nbValeurs
                        });
                    }
                });
                if (groupe.children.length > 0){
                    groupes.push(groupe);
                }
            });
            this.dataset = groupes;
            if (size === 1 ){
                this.value = selectId;
            }
        }
    }

    public valueChanged($event): void {
        const opes = this.listeOperateurs;
        Object.keys(this.listeOperateurs).map((key, index) => {
            const selectedOperator = opes[key].filter(op => op.uuid === $event);
            if (selectedOperator[0]) {
                this.operateur.setValue(selectedOperator[0]);
            }
        });
        this.filtre.value.refreshCustom.setValue(Math.random());
    }
}
