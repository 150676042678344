import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PersonneService} from '../../../_services/personne.service';
import {IPersonnelien} from "../../../../interfaces/personne-lien";
import IHydraCollection from "../../../../interfaces/hydra-collection";

@Component({
    selector: 'app-repertoire-liste',
    templateUrl: './liste.component.html',
    styleUrls: ['./liste.component.scss']
})
export class ListeComponent implements OnInit {
    @Input() personnes: IHydraCollection<IPersonnelien>;

    constructor(
        public router: Router,
        private personneService: PersonneService
    ) {
    }

    ngOnInit(): void {
    }

    openFiche(uuid: string): void {
        this.router.navigate(['repertoire/' + uuid]).then(() => {
            this.personneService.getDatasFiche(uuid).subscribe(dataPersonne => {
                this.personneService.subjectPersonne.next(dataPersonne);
            });
        });
    }

}
