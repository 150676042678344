<div class="container_personne_linked" *ngIf="personnesMoralesLinked || personnesPhysiquesLinked">
    <div class="container_carte">
        <div class="action_onglet_personne_linked">
            <button type="button" class="btn btn-med btn-ajouter"
                    (click)="openModal(PERSON_GLOBALS.PERSON_LIEN_MORALE)">
                <span>Rattacher une filiale</span>
            </button>
        </div>
        <div class="bloc_personne_linked">
            <div class="bandeau_form">Organigramme</div>
            <div class="container_orga pl_orga">
                <div class="bloc_container orga orga_parent">
                    <div *ngFor="let pmParent of personnesMoralesLinked | keyvalue">
                        <div *ngIf="pmParent.key === 'parents'">
                            <div *ngFor="let parents of pmParent.value.slice().reverse() | keyvalue">
                                <ng-container [ngTemplateOutlet]="carteRepertoire"
                                              [ngTemplateOutletContext]="{
                                                personne: parents.value,
                                                ongletName: ongletName,
                                                type: 'parent'
                                              }"
                                >
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="orga orga_current">
                        <div *ngFor="let pmCurrent of personnesMoralesLinked | keyvalue; let i = index">
                            <div *ngIf="pmCurrent.key === 'current'">
                                <ng-container *ngIf="nbEnfant > 0 || nbParent > 0; else aucunLien"
                                              [ngTemplateOutlet]="carteRepertoire"
                                              [ngTemplateOutletContext]="{
                                                personne: pmCurrent.value[0],
                                                ongletName: ongletName,
                                                type: 'current'
                                              }"
                                >
                                </ng-container>
                            </div>
                            <ng-template #aucunLien>
                                <div class="message_information"
                                     *ngIf="i === 0">
                                    Aucune filiale liée
                                </div>
                            </ng-template>
                        </div>
                        <div class="pl_orga orga orga_enfant" *ngIf="nbEnfant !== 0">
                            <div *ngFor="let pmEnfant of personnesMoralesLinked | keyvalue">
                                <div *ngIf="pmEnfant.key === 'enfants'" class="container_enfant">
                                    <div *ngFor="let enfant of pmEnfant.value">
                                        <ng-container [ngTemplateOutlet]="carteRepertoire"
                                                      [ngTemplateOutletContext]="{
                                                        personne: enfant,
                                                        ongletName: ongletName,
                                                        type: 'enfant'
                                                      }"
                                        ></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container_carte">
        <div class="action_onglet_personne_linked">
            <button type="button" class="btn btn-med btn-ajouter"
                    (click)="openModal(PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE)">
                <span>Rattacher un collaborateur</span>
            </button>
        </div>
        <div class="bloc_personne_linked">
            <div class="bandeau_form">Collaborateurs</div>
            <div class="container_orga">
                <div class="orga_physique">
                    <div *ngIf="nbLienPhysique > 0; else aucunLien">
                        <ng-container *ngFor="let personne of personnesPhysiquesLinked; let i = index">
                            <div *ngIf="!personne.anonymised" class="container_physique">
                                <ng-container [ngTemplateOutlet]="carteRepertoire"
                                              [ngTemplateOutletContext]="{
                                                personne: personne,
                                                ongletName: ongletName,
                                                type: 'physique'
                                              }">
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #aucunLien>
                        <div class="message_information">
                            Aucune personne liée
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<jw-modal id="morale">
    <form name="form"
          [formGroup]="formParent"
          #f="ngForm"
          novalidate
    >
        <div class="bloc_title_modal">Rattacher une filiale</div>
        <hr>
        <div class="bloc_body_modal" *ngIf="this.idModal === PERSON_GLOBALS.PERSON_LIEN_MORALE">
            <div class="input_container">
                <div class="select2_sans_badge input_lg">
                    <label>Personne morale existante</label>
                    <app-parents-or-children
                        [currentPersonne]="personne?.morale.current[0].uuid"
                        [control]="formParent.get('organisationParente')"
                    >
                    </app-parents-or-children>
                </div>
                <button type="button" class="btn btn-med btn-ajouter"
                        (click)="createPersonne(PERSON_GLOBALS.PERSON_LIEN_MORALE)">
                    <span>Nouvelle personne morale</span>
                </button>

            </div>
        </div>
        <div class="bloc_footer_modal">
            <div class="action_bloc_modal">
                <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal()">
                    <span>Annuler</span></button>
                <button type="button" class="btn btn-med btn-enregistrer" (click)="submit(formParent)">
                    <span>Valider</span>
                </button>
            </div>
        </div>
    </form>
</jw-modal>
<jw-modal id="physique">
    <form name="form"
          [formGroup]="form"
          #f="ngForm"
          novalidate
    >
        <div class="bloc_title_modal">Rattacher un collaborateur</div>
        <hr>
        <div class="bloc_body_modal" *ngIf="this.idModal === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE">
            <div class="input_container body_modal_p">
                <div class="select2_sans_badge input_lg">
                    <label>Personne Physique existante</label>
                    <app-personnnes-physique-select2
                            [control]="form.get('personnePhysique')"
                            [uuidPersonneCreate]="uuidPersonneCreate"
                    ></app-personnnes-physique-select2>
                </div>
                <div class="or">
                    <div>|</div>
                    <div>OU</div>
                    <div>|</div>
                </div>
                <button type="button" class="btn btn-med btn-ajouter"
                        (click)="createPersonne(PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE)">
                    <span>Nouvelle personne physique</span>
                </button>
            </div>
            <div class="input_container_single full_width">
                <div class="select2_sans_badge input_lg">
                    <label>Fonction</label>
                    <div class="select2_sans_badge input_lg">
                        <app-fonction-personne-select2
                                [control]="form.get('fonction')"
                                [ppUuid]="form.get('personnePhysique').value || ''"
                                [pmUuid]="personnesMoralesLinked?.current[0]?.personneMorale.uuid"
                                [ngClass]="{'background_alert_required': !form.controls.fonction.value}"
                        >
                        </app-fonction-personne-select2>
                    </div>
                </div>
            </div>
            <div class="input_container_single full_width">
                <div class="input_container_single full_width">
                    <label for="fonction_personnalisee">Fonction personnalisée</label>
                    <input type="text"
                           id="fonction_personnalisee"
                           formControlName="fonction_personnalisee"
                    />
                </div>
            </div>
        </div>
        <div class="bloc_footer_modal">
            <div class="action_bloc_modal">
                <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal()">
                    <span>Annuler</span></button>
                <button type="button" class="btn btn-med btn-enregistrer"
                        [ngClass]="{'background_disabled': !form.valid}"
                        [disabled]="!form.valid"
                        (click)="submit(form)"
                >
                    <span>Valider</span>
                </button>
            </div>
        </div>
    </form>
</jw-modal>
<!--- TEMPLATES --->
<ng-template #carteRepertoire let-type="type" let-ongletName="ongletName" let-personne="personne">
    <app-repertoire-carte [personne]="personne"
                          [ongletName]="ongletName"
                          [arboType]="type"
                          [formatCarte]="true"
                          [centered]="true"
    ></app-repertoire-carte>
</ng-template>
