import {Component, Input, OnInit} from '@angular/core';
import {ModalService} from '../../_modal';
import {Router} from '@angular/router';
import {CampagneTemplateInterface} from '../../../interfaces/campagneTemplate';
import {environment} from "../../../environments/environment";

@Component({
	selector: 'app-modal-apercu-template',
	templateUrl: './modal-apercu-template.component.html',
	styleUrls: ['./modal-apercu-template.component.scss']
})

export class ModalApercuTemplateComponent implements OnInit {
	@Input() public models: any;
	public rootImage = environment.apiRootURL + 'assets/template_campagne/';
	public modelApercu: CampagneTemplateInterface;

	constructor(private modalService: ModalService, private router: Router) {
	}

	ngOnInit(): void {
		this.modalService.subjectDatas.subscribe((idTemplate) => {
			this.models.filter((model, index) => {
				if (model.id === idTemplate) {
					this.modelApercu = model;
				}
			});
		});
	};

	changeModel(sens): void {
		let index = this.models.indexOf(this.modelApercu);
		if (sens === 'next') {
			if (index === this.models.length - 1) {
				index = 0;
			} else {
				index++;
			}
		} else {
			if (index === 0) {
				index = this.models.length - 1;
			} else {
				index--;
			}
		}

		this.modelApercu = this.models[index];
	}

	closeApercuTemplate(id): void {
		this.modalService.close(id);
	}

	chooseModel(model): void {
		if (model === 'vierge') {
			this.router.navigate(['/campagne/ajouter']);
		} else {
			this.router.navigate(['/campagne/ajouter'], {
				queryParams:
					{
						uuid_template: this.modelApercu.uuid
					}
			});
		}
	}

	openModalBuyModele(): void {
		this.modalService.close('modalTemplate');
		this.modalService.open('modalBuyTemplate');
	}
}
