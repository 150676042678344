export enum EntityType {
    lien = 'lien',
    physique = 'physique',
    morale = 'morale',
    segment = 'segment',
    campagne = 'campagne',
    duplicationPm = 'double_morale',
    duplicationPP = 'double_physique',
}

export class LastEntity {
    uuid?: string;
    label: string;
    uri: string;
    type: EntityType;
    createdAt?: string;
}
