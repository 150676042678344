import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as d3 from 'd3';
import * as D3Funnel from 'd3-funnel';

@Component({
    selector: 'app-segment-funnel-chart',
    templateUrl: './segment-funnel-chart.component.html',
    styleUrls: ['./segment-funnel-chart.component.scss']
})
export class SegmentFunnelChartComponent implements OnInit, OnChanges {
    private readonly width = 125;
    private readonly minHeight = 48;
    private readonly baseRowHeight = 80;
    private readonly dynamicHeight = true;
    private readonly labelColor = '#ffb200';
    private readonly gradientStartColor = '#EDEFEFFF';
    private readonly gradientStopColor = '#CDCDCD';

    private svg;

    @Input() data: Array<any>;
    constructor() {
    }

    ngOnInit(): void {
        this.createSVG();
        if (this.data){
            this.drawFunnel(this.data);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data?.currentValue && changes.data.firstChange !== true){
            this.data = changes.data.currentValue;
            if (this.data && this.data.length > 0) {
                this.drawFunnel(this.data);
            }
        }
    }

    /**
     * créé le svg qui va accueillir le chart
     * @private
     */
    private createSVG(): void {
        this.svg = new D3Funnel('#funnel');
    }

    /**
     * Dessine le chart funnel avec les datas récupérées en input
     * @param data
     * @private
     */
    private drawFunnel(data: any[]): void {
        if (data.length === 0){
            data = [{id: '0', order: '0', prive: 0, public: 0}];
        }
        const chartColorScale = d3
            .scaleLinear()
            .domain([0, data.length])
            .interpolate(d3.interpolateHcl)
            .range([d3.rgb(this.gradientStartColor), d3.rgb(this.gradientStopColor)]);

        const funnelData = data.map((row, index) => {
            return [
                row.public + ' + ' + row.prive, // label
                // tslint:disable-next-line:radix
                parseInt(row.prive) + parseInt(row.public), // value
                d3.color(chartColorScale(index)).hex() // backgroundColor
            ];
        });
        this.svg.draw(funnelData, {
            chart: {
                width: this.width,
                height: this.baseRowHeight * data.length,
                bottomPinch: 0
            },
            block: {
                dynamicHeight: this.dynamicHeight,
                minHeight: this.minHeight
            },
            label: {
                fill: this.labelColor,
                format: '{l}'
            }
        });
    }
}
