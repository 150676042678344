import {Component, OnInit} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NotifierService} from 'angular-notifier';
import {ExceptionService} from '../../../_services/exception.service';
import {LABEL_STATS, PersonneLienService} from '../../../_services/personne-lien.service';
import IHydraCollection from '../../../../interfaces/hydra-collection';
import {IPersonnelien} from '../../../../interfaces/personne-lien';
import {finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MessageGlobals} from '../../../../const-global/messages';
import {FilterItem} from '../../../../models/filteritem';
import {DataFilter} from '../../../../models/datafilter';
import {FiltresGlobals} from '../../../../const-global/filtres';
import PersonneSimilarStat from "../../../../models/personne-similar-stat";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-liste-cartes',
    templateUrl: './liste-cartes.component.html',
    styleUrls: ['./liste-cartes.component.scss']
})

export class ListeCartesComponent implements OnInit {

    env = environment;
    public readonly MESSAGE_GLOBALS = MessageGlobals;
    public dataFilter: DataFilter;
    public personnes: Array<IPersonnelien> = [];
    public personneLiens: IHydraCollection<IPersonnelien>;
    private personneLienSubscription?: Subscription;
    public isLoading: any;
    public FILTRES_GLOBALS = FiltresGlobals;
    public statSimilarities: PersonneSimilarStat[] = [];
    public totalDuplicated = 0;
    public modeAffichage = 'carte';
    subscriptions = {
        activeSubject: Subscription.EMPTY
    };
    public messageSwitch = {
        actif: this.MESSAGE_GLOBALS.MESSAGE_FICHE_ACTIVE,
        inactif: this.MESSAGE_GLOBALS.MESSAGE_FICHE_INACTIVE
    };

    public labelStats = LABEL_STATS

    constructor(
        private ngxService: NgxUiLoaderService,
        public personneLienService: PersonneLienService,
        private errorService: ExceptionService,
        private notifier: NotifierService,
    ) {
    }

    ngOnInit(): void {
        this.initDataFilter();
    }

    initDataFilter(): void {
        this.dataFilter = new DataFilter();
        this.dataFilter.lsKey = 'repertoire';
        this.dataFilter.filters = [
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_TYPE_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_STATUT_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_SEGMENT_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.VISIBILITE_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.PERSONNE_GROUPS_FILTRE),
        ];
        this.personneLienService.statsSimilarity().subscribe(data => {
            this.statSimilarities = data['hydra:member'];
            this.statSimilarities.forEach(s => {
                s.similarityLevel && (this.totalDuplicated += s.distinct)
            })
        })
    }

    onScroll(): void {
        if (!this.personneLienSubscription.closed) {
            return;
        }
        if (!this.personneLiens || this.personneLiens['hydra:view']['hydra:next']) {
            this.dataFilter.page++;
            this.fetchPersonneLien();
        }
    }

    fetchPersonneLien(): void {
        this.ngxService.startLoader('list');
        if (this.personneLienSubscription) {
            this.personneLienSubscription.unsubscribe();
        }
        this.isLoading = true;
        this.personneLienSubscription = this.personneLienService.list(this.dataFilter.getQueryParams())
            .pipe(finalize(() => {
                this.ngxService.stopLoader('list');
                this.isLoading = false;
            }))
            .subscribe(data => {
                    this.personneLiens = data;
                    this.personnes = this.personnes.concat(...data['hydra:member']);
                },
                err => {
                    this.errorService.statutErreur(err, true);
                    console.error(err);
                }
            );
    }

    showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    changeFilter(): void {
        this.personnes = [];
        this.fetchPersonneLien();
    }

    toggle(): void {
        this.dataFilter.active = !this.dataFilter.active;
        localStorage.setItem(this.dataFilter.lsKey, JSON.stringify(this.dataFilter.getValues()));
        this.changeFilter();
    }
}
