/* tslint:disable:variable-name */
// noinspection UnnecessaryLocalVariableJS

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment as ENV} from 'src/environments/environment';
import {shareReplay} from 'rxjs/operators';

const API_URL = ENV.apiURL;
const AUTH_URL = ENV.authURL;

const httpOptions = {
	headers: new HttpHeaders(
		{
			'Content-Type': 'application/json',
			accept: 'application/son'
		}
	)
};

@Injectable({
	providedIn: 'root'
})
export class UserService {

	public rightTreeRefresh = new Subject<any>();
	public passwordSubject = new Subject<any>();
	private isTempPassword$: Observable<any> = null;

	constructor(private http: HttpClient) {
	}

	getUsers(): Observable<any> {
		return this.http.get(API_URL + 'users', {responseType: 'json'});
	}

	refreshOnConnect(): void {
		this.isTempPassword$ = null;
	}

	changeMotDePasse(form): Observable<any> {
		return this.http.post(AUTH_URL + 'new-password', {
			credentials: form
		}, httpOptions);
	}

	isPasswordTemp(): Observable<any> {
		this.http.get(AUTH_URL + 'get-password-temp').subscribe(status => {
			this.passwordSubject.next(status);
		});
		return this.passwordSubject;
	}

	getUsersListe(recherche: string): Observable<any> {
		const options = {
			params: new HttpParams()
				.set('recherche', recherche)
		};
		return this.http.get(ENV.apiURL + 'utilisateurs/all', options);
	}

	getUserByUuid(uuid: string): Observable<any> {
		return this.http.get(ENV.apiURL + 'utilisateurs/' + uuid);
	}

	getUserRolesByUuid(uuid: string): Observable<any> {
		const _uuid = uuid || 'all';
		const rolesTreeByUuid$ = this.http.get(ENV.apiURL + 'utilisateurs/roles/' + _uuid).pipe(
			shareReplay(1)
		);
		return rolesTreeByUuid$;
	}

	getProfilTree(uuid: string): Observable<any> {
		const rolesTree$ = this.http.get(ENV.apiURL + 'utilisateurs/roles/tree/profil/' + uuid).pipe(
			shareReplay(1)
		);
		return rolesTree$;
	}

	getUserTree(uuid: string): Observable<any> {
		const rolesTreeUser$ = this.http.get(ENV.apiURL + 'utilisateurs/roles/tree/user/' + uuid).pipe(
			shareReplay(1)
		);
		return rolesTreeUser$;
	}

	update(uuid: string, form): Observable<any> {
		return this.http.put(ENV.apiURL + 'utilisateurs/update/' + uuid,
			form,
			httpOptions
		);
	}
}
