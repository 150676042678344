import {Component, Input} from '@angular/core';
import {BaseSelect2Component} from '../base-select2/base-select2.component';
import {IChamp} from '../../_helpers/segment.helper';

@Component({
    selector: 'app-champs-requetables-select2',
    templateUrl: './champs-requetables-select2.component.html',
    styleUrls: ['./champs-requetables-select2.component.scss']
})
export class ChampsRequetablesSelect2Component extends BaseSelect2Component {
    @Input() listeChamps: Array<IChamp>;
    @Input() form;
    @Input() champ;
    @Input() filtre;
    public formattedFields;
    public flatList: Array<any>;

    constructor() {
        super();
    }

    initSelect(): void {
        if (this.champ) {
            this.value = this.champ.value.uuid;
        }
        const data = [];
        this.formattedFields = [];
        const champs = this.listeChamps;
        Object.keys(this.listeChamps).map((key) => {
            const groupe = {
                text: key,
                children: []
            };
            if (typeof champs[key] === 'object'){
                Object.keys(champs[key]).map((i) => {
                    groupe.children.push({
                        id: champs[key][i].uuid,
                        uuid: champs[key][i].uuid,
                        text: champs[key][i].text,
                        type: champs[key][i].type,
                        max: champs[key][i].max,
                        min: champs[key][i].min,
                        operateursValues: champs[key][i].operateurValuesType
                    });
                });
            }else{
                champs[key].map((item0) => {
                    groupe.children.push({
                        id: item0.uuid,
                        uuid: item0.uuid,
                        text: item0.text,
                        type: item0.type,
                        max: item0.max,
                        min: item0.min,
                        operateursValues: item0.operateurValuesType
                    });
                });
            }
            data.push(groupe);
        });
        this.formattedFields = data;
    }

    public valueChanged($event): void {
        const champs = this.formattedFields;
        Object.keys(this.formattedFields).map((key) => {
            const selectedChamp = champs[key].children.filter(champ => champ.id === $event);
            if (selectedChamp[0]) {
                this.champ.setValue(selectedChamp[0]);
            }
        });
        this.filtre.value.refreshCustom.setValue(Math.random());
    }
}
