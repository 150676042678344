import {Component, Injectable, OnInit} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {UserService} from '../../_services/user.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

export class DroitItemNode {
    children: DroitItemNode[];
    nom: string;
    libelle: string;
    group: string;
    uuid: string;
    selected: boolean;
    level: number;
}

export class DroitItemFlatNode {
    libelle: string;
    level: number;
    expandable: boolean;
    selected: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class RightslistDatabase {
    dataChange = new BehaviorSubject<DroitItemNode[]>([]);
    private level: number;

    get data(): DroitItemNode[] {
        return this.dataChange.value;
    }

    constructor(
        protected userService: UserService,
        protected route: ActivatedRoute,
    ) {
        this.initialize();
    }

    buildChild(obj: {[key: number]: {
            uuid: string,
            nom: string,
            libelle: string,
            group: string,
            selected: boolean
        }
    }): DroitItemNode[] {

        return Object.keys(obj).reduce<DroitItemNode[]>((accumulator, key) => {
            const childNode = Object.assign(new DroitItemNode(), obj[key]);
            childNode.children = null;
            return accumulator.concat(childNode);
        }, []);

    }

    buildTree(obj, level: number): DroitItemNode[] {
        this.level = level;
        return Object.keys(obj).reduce<DroitItemNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new DroitItemNode();
            node.libelle = key;

            if (value != null) {
                if (typeof value === 'object') {
                    node.children = this.buildChild(value);
                } else {
                    node.libelle = value;
                }
            }

            return accumulator.concat(node);
        }, []);
    }

    initialize(): void {
        //     file node as children.
        this.userService.rightTreeRefresh.subscribe(() => {
            this.userService.getProfilTree(this.route.snapshot.params.id).subscribe(groupsDroits => {
                const data = this.buildTree(groupsDroits, 0);
                this.dataChange.next(data);
            });
        });
        this.userService.rightTreeRefresh.next();
    }
}

@Component({
    selector: 'app-roles-tree',
    templateUrl: './roles-tree.component.html',
    styleUrls: ['./roles-tree.component.scss'],
    providers: [RightslistDatabase],
})
export class RolesTreeComponent implements OnInit {

    // TODO : Unused
    private profilTreeSub: Subscription;
    protected userUuid: string;

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    public flatNodeMap = new Map<DroitItemFlatNode, DroitItemNode>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    public nestedNodeMap = new Map<DroitItemNode, DroitItemFlatNode>();

    /** A selected parent node to be inserted */
    public selectedParent: DroitItemFlatNode | null = null;

    public treeControl: FlatTreeControl<DroitItemFlatNode>;

    public treeFlattener: MatTreeFlattener<DroitItemNode, DroitItemFlatNode>;

    public dataSource: MatTreeFlatDataSource<DroitItemNode, DroitItemFlatNode>;

    /** The selection for checklist */
    checklistSelection = new SelectionModel<DroitItemFlatNode>(true /* multiple */);

    constructor(
        // tslint:disable-next-line:variable-name
        protected _database: RightslistDatabase,
        protected userService: UserService,
        protected route: ActivatedRoute,
    ) {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren,
        );
        this.userUuid = this.route.snapshot.params.id;
        this.treeControl = new FlatTreeControl<DroitItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        // TODO : En cours
        // this.userService.rightTreeRefresh.subscribe(() => {
        //     this.profilTreeSub = this.userService.getProfilTree(this.userUuid).subscribe(groupsDroits => {
        //         this.dataSource.data = this.buildTree(groupsDroits, 0);
        //         console.log(this.dataSource.data);
        //     });
        // });
        _database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    ngOnInit(): void {
        // TODO : En cours
        // this.userService.rightTreeRefresh.next();
    }

    getLevel = (node: DroitItemFlatNode) => node.level;

    isExpandable = (node: DroitItemFlatNode) => node.expandable;

    getChildren = (node: DroitItemNode): DroitItemNode[] => node.children;

    // tslint:disable-next-line:variable-name
    hasChild = (_: number, _nodeData: DroitItemFlatNode) => _nodeData.expandable;

    // tslint:disable-next-line:variable-name
    hasNoContent = (_: number, _nodeData: DroitItemFlatNode) => _nodeData.libelle === '';

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: DroitItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.libelle === node.libelle ? existingNode : new DroitItemFlatNode();
        flatNode.libelle = node.libelle;
        flatNode.level = level;
        flatNode.selected = node.selected;
        flatNode.expandable = !!node.children?.length;
        if (flatNode.selected === true) {
            this.checklistSelection.select(flatNode);
        }
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
        // tslint:disable-next-line:semicolon
    };

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: DroitItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.length > 0 &&
            descendants.every(child => {
                return this.checklistSelection.isSelected(child);
            });
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: DroitItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the item selection. Select/deselect all the descendants node */
    itemSelectionToggle(node: DroitItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach(child => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf item selection. Check all the parents to see if they changed */
    leafItemSelectionToggle(node: DroitItemFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: DroitItemFlatNode): void {
        let parent: DroitItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);

            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: DroitItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every(child => {
                return this.checklistSelection.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: DroitItemFlatNode): DroitItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }
}
