import {PersonneFonction} from './personnefonction';

export class Segment {
    public uuid: string;
    public titre: string;
    public intervenants: Array<PersonneFonction>;
    public referent: Array<PersonneFonction>;
    public visibilite: string;
    public actif: number;
    public nbContactsPublics: number;
    public nbContactsPrives: number;
    public filtres: null;

    constructor(
        uuid: string,
        titre: string,
        intervenants: Array<PersonneFonction>,
        referent: Array<PersonneFonction>,
        visibilite: string,
        actif: number,
        nbContactsPublics: number,
        nbContactsPrives: number,
        filtres: null,
    ) {
        this.uuid = uuid;
        this.titre = titre;
        this.intervenants = intervenants;
        this.referent = referent;
        this.visibilite = visibilite;
        this.actif = actif;
        this.nbContactsPublics = nbContactsPublics;
        this.nbContactsPrives = nbContactsPrives;
        this.filtres = filtres;
    }
}
