<div class="tache" *ngIf="campagne" (click)="openFiche(campagne.uuid)">
    <div class="tache_header">
        <span class="libelle">{{ campagne?.libelle }}</span>
        <span class="infos">
            <i class="icon link" *ngIf="campagne.linked"></i>
            <i class="icon repeat" *ngIf="campagne.relance"></i>
        </span>
    </div>
    <!--    flex si file d'attente sinon grid-->
    <div class="content_card_kanban"
         [ngClass]="{
                     'flex space-between' : campagne.statut === CAMPAIGN_GLOBALS.QUEUED,
                     'grid' : campagne.statut !== CAMPAIGN_GLOBALS.QUEUED
                     }">
        <!--        On affiche le status avant la progressbar si file d'attente-->
        <div class="tache_status status" *ngIf="campagne.statut === CAMPAIGN_GLOBALS.QUEUED">
            <span class="icon clock scheduled"></span>
            <span class="infos scheduled">
                <b>Planifié le :</b>
                {{dateFormaterService.getDate(campagne.startDate)}}
            </span>
        </div>
        <!--        On affiche le nombre de contacts si file d'attente ou brouillon-->
        <ng-container *ngIf="campagne.statut === CAMPAIGN_GLOBALS.QUEUED || campagne.statut === CAMPAIGN_GLOBALS.DRAFT">
            <!--            // TODO : ajouter le nombre de contacts plus tard un ticket sera prévu à cet effet-->
<!--            <span class="text-text-semi-bold flex justify-end">{{ campagne?.contacts }}</span>-->
        </ng-container>
        <!--        On affiche le nombre de contacts si en cours ou envoyé-->
        <div *ngIf="campagne.statut === CAMPAIGN_GLOBALS.IN_PROCESS || campagne.statut === CAMPAIGN_GLOBALS.SENT">
            <div class="number">
                <span class="count text-semi-bold">
                    {{ (campagne?.sent * 100) / campagne?.contacts | numberDecimal: 0 }}% envoyés |
                    {{ (campagne?.opened * 100) / campagne?.contacts | numberDecimal: 0 }}% ouverts |
                    {{ (campagne?.clicked * 100) / campagne?.contacts | numberDecimal: 0 }}% clics
                </span>
            </div>
        </div>
        <!--        On affiche la progressbar si en cours ou envoyé-->
        <div class="progress flex space-between items-center gap-3 mb-1"
             *ngIf="campagne.statut === CAMPAIGN_GLOBALS.IN_PROCESS || campagne.statut === CAMPAIGN_GLOBALS.SENT">
            <mat-progress-bar color="warn" [value]="campagne?.sent * 100 / campagne?.contacts"></mat-progress-bar>
            <div class="number">
                <span class="counts text-semi-bold">{{ campagne?.sent }}/{{ campagne?.contacts }}</span>
            </div>
        </div>
        <!--        On affiche le status après la progressbar si différent de file d'attente -->
        <div class="tache_status status" *ngIf="campagne.statut !== CAMPAIGN_GLOBALS.QUEUED">
            <span *ngIf="campagne.statut === CAMPAIGN_GLOBALS.IN_PROCESS" class="icon clock encours"></span>
            <span *ngIf="campagne.statut === CAMPAIGN_GLOBALS.SENT" class="icon valider done"></span>
            <span *ngIf="campagne.statut === CAMPAIGN_GLOBALS.SUSPENDED" class="icon suspended done"></span>
            <span class="infos"
                  *ngIf="campagne.statut !== CAMPAIGN_GLOBALS.DRAFT && campagne.statut !== CAMPAIGN_GLOBALS.SENT && campagne.statut !== CAMPAIGN_GLOBALS.SUSPENDED"
                  [ngClass]="{'encours':campagne.statut === CAMPAIGN_GLOBALS.IN_PROCESS}"><b>Envoi en cours ...</b></span>
            <span *ngIf="campagne.statut === CAMPAIGN_GLOBALS.SENT"
                  class="infos done"><b>Terminé le :</b> {{dateFormaterService.getDate(campagne.endDate)}}</span>
            <span *ngIf="campagne.statut === CAMPAIGN_GLOBALS.SUSPENDED"
                  class="infos done"><b>Arrêté le :</b> {{dateFormaterService.getDate(campagne.endDate)}}</span>
        </div>
    </div>
</div>
