import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {Select2OptionData} from 'ng-select2';
import {PersonneService} from '../../../_services/personne.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../../_services/exception.service';
import {IIndicatifTelephone} from '../../../../interfaces/indicatiftelephone';
import {Location} from '@angular/common';
import {PersonGlobals} from '../../../../const-global/globals';
import {Filter, PersonneLienService} from '../../../_services/personne-lien.service';
import {IPersonnelien} from '../../../../interfaces/personne-lien';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {MessageGlobals} from "../../../../const-global/messages";

@Component({
    selector: 'app-creer-personne',
    templateUrl: './creer-personne.component.html',
    styleUrls: ['./creer-personne.component.scss']
})
export class CreerPersonneComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
    public form: FormGroup;
    public indicatifData: Observable<Array<Select2OptionData>>;
    private notifier: NotifierService;
    public indicatifOptions: object;
    public champs: object;
    public indicatifDefaut: string;
    public typePersonne: string;
    private paramsUuidPl = '';
    private paramsCreation = '';
    private paramsTypePersonne = '';
    public personnesMorales: IPersonnelien[] = [];
    public idMessage = 0;
    public PMSimilaire = 1;
    public PMIdentique = 2;
    public PMLibre = 3;
    public isLoading: any;
    public timeout;
    @Input() newUser?: boolean;

    filter: Filter = {
        itemsPerPage: 0,
        page: 1,
        search: '',
        'statut.libelle[]': [],
        'type[]': [],
        segment: '',
        active: true
    };
    public searchSubject = new BehaviorSubject<string>('');
    private searchSubscription: Subscription;
    private listRaisonSocialesSubscription: Subscription;
    private personneServiceRSSubscription: Subscription;

    constructor(
        private personneService: PersonneService,
        private personneLienService: PersonneLienService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private location: Location,
        notifier: NotifierService
    ) {
        this.notifier = notifier;
    }

    ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
        this.listRaisonSocialesSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.paramsUuidPl = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.uuid : null;
        this.paramsCreation = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.arbo : null;
        this.paramsTypePersonne = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.type : null;
        this.typePersonne = this.route.snapshot.data ? this.route.snapshot.data.type : null;



        if (this.newUser) {
            // c'est un nouvel user
            this.typePersonne = PersonGlobals.PERSON_LIEN_PHYSIQUE;
        }

        if (this.typePersonne === 'physique') {
            this.form = this.formBuilder.group({
                statut: ['', Validators.required],
                civilite: ['', Validators.required],
                prenom: ['', Validators.required],
                nom: ['', Validators.required],
                visibilite: ['', Validators.required],
                raisonSociale: [''],
                formeJuridique: [''],
                email: [''],
                indicatifTel: [this.indicatifDefaut],
                telephone: [''],
                actif: [''],
                referent: [null],
                intervenants: [null],
                memo: ['']
            }, {});
        } else if (this.typePersonne === 'morale') {
            this.form = this.formBuilder.group({
                statut: ['', Validators.required],
                visibilite: ['', Validators.required],
                raisonSociale: ['', Validators.required],
                formeJuridique: ['', Validators.required],
                civilite: [''],
                prenom: [''],
                nom: [''],
                email: [''],
                indicatifTel: [''],
                telephone: [''],
                actif: [''],
                referent: [null],
                intervenants: [null],
                memo: ['']
            }, {});
        } else if (this.newUser) {
            this.form = this.formBuilder.group({
                statut: ['', Validators.required],
                civilite: [''],
                prenom: [''],
                nom: [''],
                email: [''],
                telephone: [''],
            }, {});
        }

        this.personneService.getListeIndicatifs().subscribe(
            (data: IIndicatifTelephone[]) => {
                const indicatifs = [];
                data.map((item) => {
                    indicatifs.push(
                        {
                            uuid: item.uuid,
                            text: (item.pays) ? item.indicatif + ' (' + item.pays + ')' : item.indicatif,
                            selected: JSON.parse(item.default)
                        });
                    if (JSON.parse(item.default) === true) {
                        this.form.get('indicatifTel').setValue({
                            uuid: item.uuid,
                            text: (item.pays) ? item.indicatif + ' (' + item.pays + ')' : item.indicatif,
                            selected: JSON.parse(item.default)
                        });
                    }
                });
                this.indicatifData = new Observable(observer => {
                    observer.next(indicatifs);
                    observer.complete();
                });
            },
            err => {
                this.showNotification('error', this.exceptionService.statutErreur(err));
            }
        );

        this.indicatifOptions = {
            multiple: false
        };
        if (this.form && !this.newUser) {
            this.form.controls.actif.setValue(true);
        }

        if (this.typePersonne === 'physique') {
            this.personneService.getListeChampsCreationPP().subscribe(
                data => {
                    this.champs = data;
                }, err => {
                    this.showNotification('error', this.exceptionService.statutErreur(err));
                }
            );
        } else {
            this.personneService.getListeChampsCreationPM().subscribe(
                data => {
                    this.champs = data;
                }, err => {
                    this.showNotification('error', this.exceptionService.statutErreur(err));
                }
            );
        }

        this.listRaisonSocialesSubscription = this.personneLienService.searchRaisonSocialeSubject.subscribe(search => {
                if (search !== '') {
                    this.filter.search = search;
                    this.filter['type[]'] = ['morale'];
                    this.filter.itemsPerPage = 10;
                    this.personneServiceRSSubscription = this.personneLienService.list(this.filter).subscribe(personnes => {
                        this.isLoading = false;
                        this.personnesMorales = personnes['hydra:member'];
                        if (this.personnesMorales.length > 0) {
                            this.personnesMorales.forEach(personne => {
                                if (personne.personneMorale.libelle !== this.filter.search) {
                                    this.idMessage = this.PMSimilaire;
                                } else if (personne.personneMorale.libelle === this.filter.search) {
                                    this.idMessage = this.PMIdentique;
                                }
                            });
                        } else {
                            this.idMessage = this.PMLibre;
                        }
                    }, () => {
                        this.ngxService.stopLoader('search');
                        this.resetFilter();
                    }, () => {
                        this.ngxService.stopLoader('search');
                    });
                } else {
                    this.resetFilter();
                }
            },
            () => {
                this.personneServiceRSSubscription.unsubscribe();
            },
            () => {
                this.personneServiceRSSubscription.unsubscribe();
            }
        );
    }

    ngAfterViewChecked(): void {
        this.ref.detectChanges();
    }

    ngAfterViewInit(): void {
        this.searchSubscription = this.searchSubject.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(searchQuery => {
                    this.personneLienService.searchRaisonSocialeSubject.next(searchQuery);
                }
            )
        ).subscribe();
    }

    public onSubmit(): void {
        this.ngxService.start();
        const formData = {
            raisonSociale: this.form.controls.raisonSociale.value,
            formeJuridique: this.form.controls.formeJuridique.value,
            civilite: this.form.controls.civilite.value,
            nom: this.form.controls.nom.value,
            prenom: this.form.controls.prenom.value,
            email: this.form.controls.email.value,
            indicatifTel: this.form.controls.indicatifTel.value,
            telephone: this.form.controls.telephone.value,
            visibilite: this.form.controls.visibilite.value,
            actif: this.form.controls.actif.value,
            referent: this.form.controls.referent.value,
            intervenants: this.form.controls.intervenants.value,
            statut: this.form.controls.statut.value,
            memo: this.form.controls.memo.value,
            type: this.typePersonne,
        };

        if (this.form.valid) {
            this.personneService.createPersonne(formData).subscribe(
                data => {
                    const uuid = data.content.uuidPersonnePhysique ?
                        data.content.uuidPersonnePhysique :
                        data.content.uuidPersonneMorale;
                    const libellePersonne = data.content.RaisonSocialePersonneMorale ?
                        data.content.RaisonSocialePersonneMorale :
                        null;
                    if (this.paramsUuidPl && this.paramsCreation && this.paramsTypePersonne) {
                        this.router.navigate(['/repertoire/' + this.paramsUuidPl],
                            {
                                queryParams: {
                                    onglet: 'personne-linked',
                                    creation: 'true',
                                    uuidPersonne: uuid,
                                    libellePersonne,
                                    uuidPersonneLien: data.content.uuid,
                                    type: this.paramsTypePersonne
                                }
                            }
                        );
                    } else {
                        this.router.navigate(['/repertoire/' + data.content.uuid + '/modifier']);
                    }
                    this.ngxService.stop();
                    this.setLastEntity(data.content.uuid);
                },
                err => {
                    this.ngxService.stop();
                    this.showNotification('error', this.exceptionService.statutErreur(err));
                }
            );
        } else {
            this.ngxService.stop();
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
    }

    // set une last entity à la création d'une personne
    public setLastEntity(uuid): void {
        this.personneService.getDatasFiche(uuid).subscribe(dataPersonne => {
            this.personneService.subjectPersonne.next(dataPersonne);
        });
    }

    public onCancel(): void {
        this.location.back();
    }

    onChangedSelect2($event: string, id: string): void {
        this.form.controls[id].setValue($event);
    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    onSearchInputRaisonSociale(event: Event): void {
        this.isLoading = true;
        this.ngxService.startLoader('search');
        const searchQuery = (event.target as HTMLInputElement).value;
        this.searchSubject.next(searchQuery?.trim());
    }

    public resetFilter(): void {
        this.idMessage = 0;
        this.isLoading = false;
        this.personnesMorales = [];
        this.filter.search = '';
        this.filter['type[]'] = [];
        this.filter.itemsPerPage = 0;
    }

    public redirectToPM(uuidPM): void {
        this.router.navigate(['/repertoire/' + uuidPM]);
    }
}
