<div class="carte_avatar" *ngIf="personneConnecte">
    <app-avatar [personne]="personneConnecte" [volet]="true"></app-avatar>
</div>
<h2 *ngIf="personneConnecte">{{personneConnecte.prenom}} {{personneConnecte.nom | uppercase}}</h2>
<div *ngIf="personneConnecte">
    <a class="btn btn-med btn-mon-compte" [routerLink]="['utilisateurs', personneConnecte.userUuid, 'mon-compte']">Mon
        compte</a>
    <a class="btn btn-med icon lock" [routerLink]="['utilisateurs/' + personneConnecte.userUuid + '/mot-de-passe']">Mot
        de passe</a>
</div>
<a class="btn btn-med btn-deconnexion" (click)="logout()">Me déconnecter</a>
<hr>
<div class="icon parametre_menu"></div>
<div class="datas_volet">
    <h2>Paramètres du cabinet</h2>
    <div class="content_orga_name" *ngIf="organisation">
        <h3 class="organisation_name">{{ organisation.nom |uppercase }}</h3>
    </div>
    <a class="btn btn-sm btn-setting" routerLink="/parametres/configuration-generale">Configuration générale</a>
    <a class="btn btn-sm btn-champs-personnalises" routerLink="/champs-personnalise">Champs personnalisé</a>
    <a class="btn btn-sm btn-tags" routerLink="/tag">Mots clés</a>
    <a class="btn btn-sm btn-origines-de-contact" routerLink="/origine">Origines de contact</a>
    <a class="btn btn-sm btn-utilisateurs" routerLink="/utilisateurs">Utilisateurs</a>
    <a class="btn btn-sm btn-email" routerLink="parametre/campagne/consommation">Consommation e-mails</a>
    <a class="fa-light btn btn-sm btn-catalogue-produits" routerLink="parametres/catalogue-produits">Catalogue
        Produits</a>
    <a class="btn btn-sm btn-question" (click)="displayContactSupport = true">Aide et support</a>
    <div class="feature_disabled"></div>
    <a class="btn btn-sm btn-profil-utilisateurs disabled">Profils utilisateurs</a>
</div>

<jw-modal id="contact-support" [(display)]="displayContactSupport">
    <div class="bloc_title_modal">Aide et Support</div>
    <div class="bloc_body_modal aide">
        <div>Besoin d'aide sur votre CRM ? </div>
        <div>Contactez le support AZKO CRM : </div>
        <ul>
            <li> - par mail à l'adresse : <a href="mailto:azko-crm@septeo.com?Subject=[Assistance CRM] - {{organisation.nom}} ({{organisation.id}}) - {{personneConnecte.prenom}} {{personneConnecte.nom}} ({{personneConnecte.id}})">azko-crm@septeo.com</a></li>
            <li> - par téléphone au : <a href="tel:+33467154440">+33 (0)4 67 15 44 40</a></li>
        </ul>

    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="displayContactSupport = false">
                <span>Fermer</span>
            </button>
        </div>
    </div>
</jw-modal>
