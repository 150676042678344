import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LeadService, LeadFilter} from '../../_services/lead.service';
import {ILead} from "../../../interfaces/lead";
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Subscription} from 'rxjs';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {CdkDrag, CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {
	ModalConfirmProbabiliteComponent
} from '../../layout/modal-confirm-probabilite/modal-confirm-probabilite.component';
import {DataFilter} from '../../../models/datafilter';
import {FilterItem} from '../../../models/filteritem';
import {FiltresGlobals} from '../../../const-global/filtres';
import {MessageGlobals} from '../../../const-global/messages';

registerLocaleData(localeFr);

@Component({
	selector: 'app-offre-kanban',
	templateUrl: './offre-kanban.component.html',
	styleUrls: ['./offre-kanban.component.scss']
})
export class OffreKanbanComponent implements OnInit, OnDestroy {
    draft: ILead[] = [];
    sent: ILead[] = [];
    negociation: ILead[] = [];
    promise: ILead[] = [];
    signed: ILead[] = [];
    canceled: ILead[] = [];
    public newProbabilite = 0;
    public currentIndex = 0;
    transfetEvent = null;
    draftTotal: number;
    sentTotal: number;
    negociationTotal: number;
    promiseTotal: number;
    signedTotal: number;
    canceledTotal: number;
    filter: LeadFilter = {
        search: null,
        active: true
    };
    probabilites = {
        canceled: 999,
        negociation: 60,
        promise: 80,
        signed: 100,
        sent: 40,
        draft: 20
    };

    subscriptions = {
        draft: Subscription.EMPTY,
        sent: Subscription.EMPTY,
        negociation: Subscription.EMPTY,
        promise: Subscription.EMPTY,
        signed: Subscription.EMPTY,
        canceled: Subscription.EMPTY,
    };
    listLoading = false;
    counters = {
        canceled: 0,
        negociation: 0,
        promise: 0,
        signed: 0,
        sent: 0,
        draft: 0
    };
    @ViewChild('confirmModal') confirmModal: ModalConfirmProbabiliteComponent;
    public dataFilter: DataFilter;
    public FILTRES_GLOBALS = FiltresGlobals;
    public readonly MESSAGE_GLOBALS = MessageGlobals;
    public messageSwitch = {
        actif: this.MESSAGE_GLOBALS.MESSAGE_OFFRE_ACTIVE,
        inactif: this.MESSAGE_GLOBALS.MESSAGE_OFFRE_INACTIVE
    };
    constructor(
        public leadService: LeadService,
        private ngxUiLoaderService: NgxUiLoaderService,
    ) {
        this.sortPredicate.bind(this);
    }

    get totalDraft(): number {
        return this.draft.length;
    }

    get totalNegociation(): number {
        return this.negociation.length;
    }

    get totalPromise(): number {
        return this.promise.length;
    }

    get totalSent(): number {
        return this.sent.length;
    }

    get totalSigned(): number {
        return this.signed.length;
    }

    get totalCanceled(): number {
        return this.canceled.length;
    }

    get isActive(): boolean{
        return this.dataFilter.active;
    }

    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.initDataFilter();
    }

    initDataFilter(): void{
        this.dataFilter = new DataFilter();
        this.dataFilter.lsKey = 'offre';
        this.dataFilter.filters = [
            new FilterItem(this.FILTRES_GLOBALS.VISIBILITE_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.OFFRE_CONTACT_STATUT_FILTRE),
            new FilterItem(this.FILTRES_GLOBALS.OFFRE_PROBABILITE_FILTRE),
        ];
    }
    private getTotals(): void {
        this.leadService.getTotals(this.dataFilter.active).subscribe(data => {
            this.draftTotal = data.draft;
            this.sentTotal = data.sent;
            this.negociationTotal = data.negociation;
            this.promiseTotal = data.promise;
            this.signedTotal = data.signed;
            this.canceledTotal = data.canceled;
        });
    }

    private refresh(): void {
        if (this.listLoading === true) {
            return;
        }
        this.listLoading = true;

        this.ngxUiLoaderService.stopLoader('list');
        const queryParams = this.dataFilter.getQueryParams();

        Object.keys(this.probabilites).map((key) => {

            queryParams.probabilite = this.probabilites[key];
            this.subscriptions[key] = this.leadService.list(queryParams).subscribe(leads =>   {
                this[key] = leads['hydra:member'];
                this.counters[key] = this.calculateTotal(this[key]);
                this.listLoading = false;
            });
        });

        this.getTotals();

        if (!this.listLoading) {
            this.ngxUiLoaderService.stopLoader('list');
        }
    }

    toggle(): void {
        this.dataFilter.active = !this.dataFilter.active;
        localStorage.setItem(this.dataFilter.lsKey, JSON.stringify(this.dataFilter.getValues()));
        this.refresh();
    }

    calculateTotal(data: ILead[]): number {
        let result = 0;
        if (data.length > 0) {
            result = data.reduce((a: number, b: ILead) => {
                return a + b.devis?.montantHT;
            }, 0);
        }
        return result;
    }

    public drop(event: CdkDragDrop<ILead[]>): void {
        if (event.previousContainer !== event.container) {
            this.transfetEvent = event;
            transferArrayItem(
                this.transfetEvent.previousContainer.data,
                this.transfetEvent.container.data,
                this.transfetEvent.previousIndex,
                this.transfetEvent.currentIndex,
            );
            this.newProbabilite = this.probabilites[event.container.id.replace('column_', '')];
            if (this.newProbabilite > 99){
                this.confirmModal.openConfirmationModal();
            }else{
                this.updateColunmItem();
            }

        }else{
            this.transfetEvent = event;
            transferArrayItem(
                this.transfetEvent.previousContainer.data,
                this.transfetEvent.container.data,
                this.transfetEvent.previousIndex,
                this.transfetEvent.currentIndex,
            );
            this.calculatePoids();
        }
    }
    updateColunmItem(): void{
        this.updateTotals();
        this.leadService.patch(
            this.transfetEvent.item.element.nativeElement.id,
            {probabilite: this.newProbabilite}
        ).subscribe(() => {
            this.calculatePoids();
            this.refresh();
        });
    }
    onConfirmModal($event): void{
        if ($event){
            this.updateColunmItem();
        }
    }
    onCancelModal($event): void{
        if ($event){
            transferArrayItem(
                this.transfetEvent.container.data,
                this.transfetEvent.previousContainer.data,
                this.transfetEvent.currentIndex,
                this.transfetEvent.previousIndex,
            );
        }
    }

    updateTotals(): void{
        this.counters.draft = this.calculateTotal(this.draft);
        this.counters.sent = this.calculateTotal(this.sent);
        this.counters.negociation = this.calculateTotal(this.negociation);
        this.counters.promise = this.calculateTotal(this.promise);
        this.counters.signed = this.calculateTotal(this.signed);
        this.counters.canceled = this.calculateTotal(this.canceled);
    }

    onRecherche(recherche): void{
        this.filter.search = recherche;
        this.refresh();
    }

    sortPredicate = (index: number, item: CdkDrag<any>): boolean => {
        return  index === 0;
    }

    calculatePoids(): void {
        let i = 0;
        this.transfetEvent.container.data.forEach(() => {
            this.leadService.patch(
                this.transfetEvent.container.data[i].uuid,
                {poids: this.transfetEvent.container.data.length - i}
            ).subscribe(() => {
            });
            i = i + 1;
        });
    }

    changeFilter(): void {
        this.refresh();
    }
}
