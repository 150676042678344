import {Component, ViewEncapsulation} from '@angular/core';
import {CheckboxFilterItemComponent} from '../checkbox-filter-item/checkbox-filter-item.component';

@Component({
    selector: 'app-personne-visibilite-filter-item',
    templateUrl: '../checkbox-filter-item/checkbox-filter-item.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class PersonneVisibiliteFilterItemComponent extends CheckboxFilterItemComponent {
    dataSource = [
        {
            uuid: 'public',
            titre: 'Public'
        },
        {
            uuid: 'protected',
            titre: 'Protégé'
        },
        {
            uuid: 'private',
            titre: 'Privé'
        },
    ];
}
