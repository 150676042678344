import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {PersonnePhysique} from '../../../models/personnephysique';
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-personnnes-physique-select2',
    templateUrl: './personnnes-physique-select2.component.html',
    styleUrls: ['./personnnes-physique-select2.component.scss']
})
export class PersonnnesPhysiqueSelect2Component implements OnInit {
    public value: string;
    public personnePhysiques: Array<PersonnePhysique>;
    @Input() control: FormControl | AbstractControl = new FormControl();
    @Input() uuidPersonneCreate: string;
    @Input() reload: any;
    @Output() select2PPChange = new EventEmitter<boolean>();

    constructor(private personneService: PersonneService) {
    }

    ngOnInit() {
        const personnePhysique = [];
        this.personneService.getListePersonnesPhysiques().subscribe(
            data => {
                const personnes = [];
                data.map((item: PersonnePhysique) => {
                    // On vérifie si l'uuid de la personne qui vient d'être crée est présent dans la liste
                    if (item.uuid === this.uuidPersonneCreate) {
                        personnePhysique.push(
                            {
                                id: this.uuidPersonneCreate
                            }
                        );
                        this.value = personnePhysique[0].id;
                    }
                    if(!item.civilite){
                        item.civilite = 'Non-renseigné';
                    }
                    personnes.push({
                        id: item.uuid,
                        libelle: item.prenom + ' ' + item.nom
                    });
                });

                personnes.sort(function(a, b){
                    return a.libelle.toLowerCase().localeCompare(b.libelle.toLowerCase());
                });

                this.personnePhysiques = personnes;
            },
            err => {
                console.error(err);
            }
        );
    }
}
