import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {ModalService} from '../../_modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../_services/exception.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageGlobals} from '../../../const-global/messages';
import {OrganisationGlobals, PersonGlobals} from '../../../const-global/globals';
import {Personne} from '../../../models/personne';
import {PersonneLinked} from '../../../models/personnemoralelinked';
import {
    ParentsOrChildrenComponent
} from '../../select2/personnes-morales/parents-or-children/parents-or-children.component';
import {finalize} from "rxjs/operators";
import {IPersonnelien} from "../../../interfaces/personne-lien";

@Component({
    selector: 'app-personne-linked',
    templateUrl: './personne-linked.component.html',
    styleUrls: ['./personne-linked.component.scss']
})
export class PersonneLinkedComponent implements OnInit, AfterViewInit {
    // pour pouvoir y accéder côté template (sans toutefois pouvoir les modifier)
    public readonly ORGA_PARENTE = OrganisationGlobals.ORGA_PARENTE;
    public readonly PERSON_GLOBALS = PersonGlobals;
    public idModal: string;
    public formParent: FormGroup;
    public form: FormGroup;
    public reset;
    @Input() uuid: string;
    @Input() personne: any;
    @Input() ongletName: string;
    @Input() creationPersonne: string;
    @Input() typePersonne: string;
    @Input() uuidPersonneCreate: string;
    @Input() libellePersonneCreate: string;
    @Input() personnesPhysiquesLinked: Array<IPersonnelien>;
    @Input() personnesMoralesLinked: PersonneLinked;
    @Input() nbLienPhysique: number;
    @Input() nbEnfant: number;
    @Input() nbParent: number;
    @Output() closed = new EventEmitter<any>();
    @Output() reload = new EventEmitter<boolean>();

    constructor(
        private personneService: PersonneService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        private notifier: NotifierService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.formParent = this.formBuilder.group({
            organisationParente: [null, [Validators.required]],
        })

        this.form = this.formBuilder.group({
            personnePhysique: [null, [Validators.required]],
            fonction: [null, [Validators.required]],
            fonction_personnalisee: null,
        });
    }

    ngAfterViewInit(): void {
        this.displayModal();
    }

    // Permet de reinitialiser la route afin de pouvoir créer une nouvelle personne
    reinitUrlAndModal(): void {
        this.router.navigate([], {
            queryParams: {
                creation: null,
                uuidPersonne: null,
                uuidPersonneLien: null
            }
        });
    }

    public submit(form: FormGroup): void {
        this.reinitUrlAndModal();
        if (!form.valid) {
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);

            return;
        }
        this.ngxService.start();
        this.personneService.createLien(this.uuid, form.value)
            .pipe(finalize(() => this.ngxService.stop()))
            .subscribe(() => {
                    this.closeModal();
                    this.reload.emit(true);
                    this.personneService.sendResetSelect2(true);
                    this.showNotification('success', 'La personne a bien été rattachée');
                },
                err => {
                    this.showNotification('error', this.exceptionService.statutErreur(err));
                }
            );
    }

    // retourne true si une personne vient d'être crée
    personneIsCreate(): boolean {
        return this.route.snapshot.queryParams.uuidPersonne && this.route.snapshot.queryParams.creation === 'true';
    }

    openModal(id: string): void {
        this.idModal = id;
        this.modalService.open(this.idModal);
    }

    closeModal(): void {
        // reset the select2 value
        this.reset = {
            id: '',
            text: '',
            disabled: true
        };
        this.closed.emit(true);
        this.modalService.close(this.idModal);
        this.reinitUrlAndModal();
        this.form.reset();
        this.formParent.reset();
    }

    showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
    }

    /**
     * OnChanged est une fonction appelée au change d'un composant select2
     * @example
     * // ne retourne rien, mais set la valeur du form controller 'physique' avec la valeur '6d83abd3-3879-4c09-8a45-30144f181a70'
     * onChanged('6d83abd3-3879-4c09-8a45-30144f181a70', 'physique')
     * @param $event event
     * @param type string Valeurs possibles : voir constantes PERSON_LIEN_{XXX}
     */
    onChanged($event, type: string): void {
        if (typeof type !== 'undefined') {
            this.formParent.controls[type].setValue($event);
        }
    }

    createPersonne(type: string): void {
        if (type === PersonGlobals.PERSON_LIEN_MORALE) {
            this.router.navigate(['/repertoire/creer/morale'], {
                queryParams:
                    {
                        arbo: 'true',
                        uuid: this.uuid,
                        type: PersonGlobals.PERSON_LIEN_MORALE
                    }
            });
        } else if (type === PersonGlobals.PERSON_LIEN_PHYSIQUE) {
            if (this.personneIsCreate() === false || !this.personneIsCreate()) {
                this.router.navigate(['/repertoire/creer/physique'], {
                    queryParams:
                        {
                            arbo: 'true',
                            uuid: this.uuid,
                            type: PersonGlobals.PERSON_LIEN_PHYSIQUE
                        }
                });
            } else {
                this.showNotification('error', 'Vous devez d\'abord valider la création');
            }
        }
    }

    // permet d'afficher la modal après avoir été redirigé suite à la création d'une personne
    public displayModal(): void {

        if (this.creationPersonne === 'true' && this.typePersonne === 'physique') {
            this.openModal(PersonGlobals.PERSON_LIEN_PHYSIQUE);
        }
        if (this.creationPersonne === 'true' && this.typePersonne === 'morale') {
            this.openModal(PersonGlobals.PERSON_LIEN_MORALE);
        }
    }
}
