import {Component, Input} from '@angular/core';
import {PersonneService} from '../../_services/personne.service';
import {map} from "rxjs/operators";
import {AbstractControl, FormControl} from "@angular/forms";

@Component({
    selector: 'app-select2-code-nace',
    templateUrl: './select2-code-nace.component.html',
    styleUrls: ['./select2-code-nace.component.scss']
})
export class Select2CodeNaceComponent {
    items = this.personneService.getNaceCodes().pipe(map(items => {
        return items.map((item) => {
                return {
                    ...item,
                    id: item?.Class,
                    label: item?.Class + ' - ' + item?.Activity,
                }
            }
        )
    }));

    @Input() control: FormControl | AbstractControl = new FormControl();

    constructor(private personneService: PersonneService) {
    }


}
