import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EvenementService, EventCounterUser} from '../../_services/evenement.service';
import {date} from '../../../const-global/format-date';
import {PersonneService} from '../../_services/personne.service';
import {Router} from '@angular/router';
import {RappelGlobals} from '../../../const-global/globals';

@Component({
    selector: 'app-volet-rappel',
    templateUrl: './volet-rappel.component.html',
    styleUrls: ['./volet-rappel.component.scss']
})
export class VoletRappelComponent implements OnInit {
    public readonly typeMapping = [
        RappelGlobals.RAPPEL_TYPE_FICHE_LIBELLE,
        RappelGlobals.RAPPEL_TYPE_TACHE_LIBELLE
    ];

    @Input() uuidPersonneCo: string;
    @Input() rappel: string;
    @Input() dashboard: string;
    @Input() limit: number;

	public events = [];
    public nbEventEnRetard: number;
    public nbFicheIncomplete: number;
    public fullDate = date.fullDate;
    public nbEvent: EventCounterUser;

    constructor(
        private evenementService: EvenementService,
        private personneService: PersonneService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.getEvent();
        this.getFicheIncomplete();
        this.evenementService.counterEventSubjectUser.subscribe(
            (counters: EventCounterUser) => {
                this.nbEvent = counters;
            }
        );
    }

    public getEvent(): void {
        this.nbEventEnRetard = 0;
        this.evenementService.getNbEventRappel().subscribe(value => {
            this.nbEvent = value;
        });
    }

    public getFicheIncomplete(): void {
        this.nbFicheIncomplete = 0;
        this.personneService.getCountIncompleteProfils().subscribe(value => {
            this.nbFicheIncomplete = value;
        });
    }

    /**
     * retourne le nombre de rappels total en attente.
     */
    public getCurrentRappelCounts(): number {
        return Number(this.nbEvent.nbEventEnRetard) + Number(this.nbFicheIncomplete);
    }

    public openOnglet(type: string): void {
        if (this.typeMapping.filter(tp => tp === type)) {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/rappel/', this.uuidPersonneCo], { queryParams: { onglet: type } });
        } else {
            console.error('Given type does not exists.');
        }
    }

    public openEvent(uuidEvent: string): void {
        this.router.navigate(['repertoire/modifier/evenement/' + uuidEvent]).then(() => {
            window.location.reload();
        });
    }
}
