import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {  trigger,
  state,
  style,
  animate,
  transition} from '@angular/animations';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('display', [
      // ...
      state('enter', style({
        // opacity: .3,
        // backgroundColor: 'black',
      })),
      state('exit', style({
        opacity: 0,
        display: 'none'
      })),
      transition('enter => exit', [
        animate('1s')
      ]),
      transition('exit => enter', [
        animate('1s')
      ]),
    ]),
  ],
})
export class SpinnerComponent implements OnInit {

  @Input() display = true;

  yopDisplay = true;

  constructor() { }

  ngOnInit(): void {
  }

  animationDone($event: any): void {
    if (!this.display) {
      this.yopDisplay = false;
    }
  }

  animationStart(): void {
    if (this.display) {
      this.yopDisplay = true;
    }
  }
}
