<jw-modal [id]="this.modalID" class="modal-lg">
	<div class="bloc_title_modal">Envoi de votre offre</div>
	<div class="bloc_body_modal" [formGroup]="form">
		<div class="input_container">
			<div class="input_lg">
				<label>Nom de l'expediteur</label>
				<input type="text" formControlName="senderName">
			</div>
		</div>
		<div class="input_container">
			<div class="input_lg">
				<label>E-mail de l'expediteur</label>
				<input type="email" formControlName="senderDisplay">
			</div>
		</div>
		<div class="input_container">
			<div class="input_lg">
				<label>E-mail du destinataire</label>
				<ng-select
						formControlName="to"
						bindValue="valeur"
						bindLabel="valeur"
						[items]="emailsData"
				>
					<div class="" *ngIf="this.emailsData.length === 0 && !loading">Aucune adresse e-mail renseignée pour
						ce contact
					</div>
					<ng-template ng-label-tmp let-item="item">
						{{ item?.principal === true ? 'Principale' : 'Secondaire' }} : {{  item?.valeur }}
					</ng-template>
					<ng-template ng-option-tmp let-item="item">
						{{ item?.principal === true ? 'Principale' : 'Secondaire' }} : {{  item?.valeur }}
					</ng-template>
				</ng-select>
				<div class="alert" *ngIf="emailsData.length <= 0">
					Aucune adresse e-mail renseignée pour ce contact
				</div>
			</div>
		</div>
		<div class="input_container">
			<div class="input_lg">
				<label>Objet du message</label>
				<input type="text" formControlName="object">
			</div>
		</div>
		<div class="input_container">
			<div class="input_lg">
				<label class="text-bold">Message</label>
				<ckeditor [editor]="editor" [config]="config" formControlName="message"></ckeditor>
			</div>
		</div>
	</div>
	<div class="bloc_footer_modal">
		<div class="action_bloc_modal">
			<button type="reset" class="btn btn-med btn-fermer" (click)="close()">
				<span>Annuler</span></button>
			<button type="submit" class="btn btn-med btn-envoyer-devis" (click)="send()"
			        [ngClass]="{
						'btn-disabled': !this.form.valid
			        }"
			>
				<span>Envoyer</span>
			</button>
		</div>
	</div>
</jw-modal>
