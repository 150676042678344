import {Component, Input, OnInit} from '@angular/core';
import {CampaignGlobals} from '../../../const-global/globals';
import {Campagne} from '../../../models/campagne';
import {Router} from '@angular/router';
import {DateFormaterService} from '../../_services/date-formater.service';

@Component({
    selector: 'app-kanban-tache',
    templateUrl: './kanban-tache.component.html',
    styleUrls: ['./kanban-tache.component.scss']
})
export class KanbanTacheComponent implements OnInit {
    @Input() campagne: Campagne;
    public readonly CAMPAIGN_GLOBALS = CampaignGlobals;

    constructor(
        public router: Router,
        public dateFormaterService: DateFormaterService
    ) {
    }

    ngOnInit(): void {
    }

    openFiche(uuid: string): void {
        this.router.navigate(['/campagne/' + uuid]);
    }
}
