<app-fil-ariane></app-fil-ariane>
<div class="main">
    <div>
        <app-repertoire-outils
                [type]="'tag'"
                (search)="onRecherche($event)"
        ></app-repertoire-outils>
    </div>
    <div class="content_tags"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
    >
        <div *ngIf="tags === undefined || tags.length === 0" class="message_information">Aucun résultat
            trouvé
        </div>
        <ul class="tags">
            <li class="tags_item" *ngFor="let tag of tags; let i = index">
                <div class="tag" *ngIf="tag" (click)="openTag(tag.uuid, tag.systeme)">
                    <div class="tag_content" [ngClass]="{'card_disabled': tag.systeme === 1}">
                        <div class="icon icon_tag tag_titre bandeau_parametre"
                             [ngClass]="{'card_disabled': tag.systeme === 1}">
                            <h2>{{ tag.libelle }}</h2>
                        </div>
                        <div class="carte_texte">
                            <p class="texte_systeme">{{tag.systeme === 1 ? ParametreGlobals.SYSTEM_LIBELLE : ParametreGlobals.PERSONNALISE_LIBELLE }}</p>
                            <p class="texte_target">{{tag.target}}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <notifier-container></notifier-container>
    <ngx-ui-loader></ngx-ui-loader>
</div>
