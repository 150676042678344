<div class="container_memo" [ngClass]="{'memo_fiche': this.memoFiche}" *ngIf="personne">
    <div class="donnee_memo" *ngFor="let memo of memoList?.memos" data-uuid="{{memo.uuid}}">
        <div class="bandeau_fiche bandeau_memo">
            <span class="identite_personne_memo">
                <span *ngIf="memo.civilite !== 'Non-renseigné'">{{ memo.civilite}}. </span>
                {{memo.prenom}} {{memo.nom}}
            </span>
            <span class="date_memo"> Le {{memo?.dateCreation?.date | date:'dd/MM/yyyy'}} </span>
        </div>
        <div class="memo_fiche">
            <span>{{ memo.texte }}</span>
        </div>
    </div>
</div>
<div class="container_memo offre" [ngClass]="{'memo_fiche': this.memoFiche}" *ngIf="offre">
    <div class="donnee_memo" *ngFor="let memo of memoList?.memos" data-uuid="{{memo.uuid}}">
        <div class="bandeau_fiche bandeau_memo">
            <span class="identite_personne_memo"  *ngIf="memo.userModification">
                <span *ngIf="memo.userModification?.personnePhysique?.civilite !== 'Non-renseigné'">
                    {{ memo.userModification?.personnePhysique?.civilite}}.
                </span>
                {{memo.userModification?.personnePhysique?.prenom}} {{memo.userModification?.personnePhysique?.nom}}
            </span>
            <span class="identite_personne_memo"  *ngIf="memo.nom">
                <span *ngIf="memo.civilite !== 'Non-renseigné'">{{ memo.civilite}}. </span>
                {{memo.prenom}} {{memo.nom}}
            </span>
            <span *ngIf="memo.nom" class="date_memo"> Le {{memo?.dateCreation?.date | date:'dd/MM/yyyy'}} </span>
            <span *ngIf="memo.userModification" class="date_memo"> Le {{memo?.dateCreation | date:'dd/MM/yyyy'}} </span>

        </div>
        <div class="memo_fiche">
            <span>{{ memo.texte }}</span>
        </div>
    </div>
</div>
