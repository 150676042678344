import {Component, OnInit} from '@angular/core';
import {EvenementService, EventCounterUser} from '../../_services/evenement.service';
import {ExceptionService} from '../../_services/exception.service';
import {PersonneService} from '../../_services/personne.service';
import {ActivatedRoute} from '@angular/router';
import {RappelGlobals} from '../../../const-global/globals';
import {NotifierService} from 'angular-notifier';

@Component({
    selector: 'app-rappel',
    templateUrl: './rappel.component.html',
    styleUrls: ['./rappel.component.scss']
})
export class RappelComponent implements OnInit {
    public readonly RAPPEL_GLOBAL = RappelGlobals;

    public tabName: string;

    public nbEvent: EventCounterUser;
    public plUuid = this.route.snapshot.params.id;
    public nbFicheIncomplete = 0;
    public libelleTache: string;

    // Paramètre request
    private paramsOnglet = '';

    constructor(
        private route: ActivatedRoute,
        private evenementService: EvenementService,
        private exceptionService: ExceptionService,
        private personneService: PersonneService,
        private notifier: NotifierService,
    ) {
    }

    ngOnInit(): void {
        this.countEventRappel();
        this.countFichesIncompletes();
        this.tabName = 'fiche';
        this.paramsOnglet = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.onglet : null;
        this.openOnglet(this.paramsOnglet);
    }

    public countEventRappel(): void {
        this.evenementService.counterEventSubjectUser.subscribe(
            (counters: EventCounterUser) => {
                this.nbEvent = counters;
                if (this.nbEvent.nbEventRappel > 1) {
                    this.libelleTache = 'Tâches';
                } else {
                    this.libelleTache = 'Tâche';
                }
            },
            err => {
                this.showNotification('error', this.exceptionService.statutErreur(err));
            }
        );
    }

    public countFichesIncompletes(): void {
        this.personneService.getCountIncompleteProfils(true).subscribe(value => {
                this.nbFicheIncomplete = value;
            },
            err => {
                this.showNotification('error', this.exceptionService.statutErreur(err));
            });
    }

    public openOnglet(onglet: string): void {
        if (typeof onglet !== 'undefined') {
            this.tabName = onglet;
        } else {
            this.tabName = RappelGlobals.RAPPEL_TYPE_FICHE_LIBELLE;
        }
    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }
}
