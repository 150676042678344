<div class="main">
<!--    <app-spinner></app-spinner>-->
    <ngx-ui-loader loaderId="saveConsent"></ngx-ui-loader>
    <div>
        <app-data-filter
                *ngIf="!this.displayModal"
                [dataFilter]="dataFilter"
                (changeFilter)="changeFilter()"
                [noToggle]="true"
                [editLocked]="this.isEdited || this.isSelected"
                (saveData)="filterAction()"
                (hideSticky)="this.displayModalCancelSearch = $event"
                (cancel)="cancelData()"
        ></app-data-filter>
    </div>
    <div class="form_consent_grid">
        <button type="button" class="btn btn-med icon arrow-left" routerLink="/repertoire" [style.display]="'none'">Retour</button>
        <div class="table_consent relative">
            <div class="bandeau_consent">
                Gestion des consentements e-mailing
                <div class="icon info"
                     (mouseover)="showTooltip = !showTooltip"
                     (mouseleave)="showTooltip = !showTooltip"
                     (click)="showTooltip = !showTooltip"
                ></div>
            </div>
            <div class="absolute tooltip-consent" *ngIf="showTooltip">
                <span>
                    En conformité avec le Règlement Général de Protection des Données (RGPD),
                    le consentement est requis pour toutes les personnes physiques et les personnes
                    fonctions qui possèdent une ou plusieurs adresses e-mails. Les exceptions concernent :
                </span><br><br>

                <ul class="pl-4">
                    <li>Les fiches "Membre" qui sont les personnes faisant partie de votre organisation.</li>
                    <li>Les fiches "Partie Adverse" qui sont exclues automatiquement des envois d'emailing.</li>
                </ul>
                <br>

                <span>
                    Les personnes morales ne sont pas soumises aux règles RGPD car leurs adresses e-mails doivent
                    être génériques <i>(ex : contact@...)</i> et ne doivent pas correspondre à un individu en particulier.
                </span><br><br>

                <span>
                    <b>Important :</b> dès lors que le nom d'une personne physique apparait dans l'adresse e-mail,
                    cette dernière doit être renseignée sur la personne fonction et doit être soumise à la demande de
                    consentement. Si tel n'est pas le cas, <b>vous ne serez pas conforme au RGPD</b>.
                </span>
            </div>
            <div class="options_consent">
                <mat-checkbox
                        name="required"
                        class="icon check-all"
                        [checked]="checkedConsentAll()"
                        [disabled]="0 === reduceDataSourceLength()"
                        (change)="toggleSelectConsentAll($event)">
                    Tout sélectionner
                </mat-checkbox>
                <span [style.width.px]="300" *ngIf="0 < this.listConsent.length">
                    <ng-select [clearable]="false" [ngModel]="updateTypeConsentAll"
                               (change)="optionConsentAll($event)" placeholder="Action pour la sélection">
                        <ng-option value="consent">Modifier le consentement</ng-option>
                        <ng-option value="consentType">Modifier le moyen de recueil</ng-option>
                        <ng-option value="consentFull">Modifier les deux</ng-option>
                    </ng-select>
                </span>

            </div>
            <div class="container_table_consent" #matTableRef>
                <table mat-table matSort
                       #consentSort="matSort"
                       [dataSource]="dataSourced"
                       (matSortChange)="sortData()"
                >
                    <ng-container matColumnDef="selectConsent">
                         <th #listHeaders mat-header-cell
                            *matHeaderCellDef>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                    name="required"
                                    class="icon"
                                    [disabled]="isSelectDisable(row)"
                                    [checked]="isChecked(row.uuid)"
                                    (change)="toggleSelectConsent(row.uuid)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="id">
                         <th #listHeaders mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell *matCellDef="let row; let i = index">{{i + 1}}</td>
                    </ng-container>
                    <ng-container matColumnDef="nom">
                         <th #listHeaders mat-header-cell *matHeaderCellDef mat-sort-header="lien.libelle">Nom</th>
                        <td  class="nom"  mat-cell *matCellDef="let row">{{row.lien?.libelle}}</td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                         <th #listHeaders mat-header-cell *matHeaderCellDef mat-sort-header="lien.type">Type</th>
                        <td mat-cell
                            class="type"
                            *matCellDef="let row">{{ row.lien?.type | PersonType | titlecase //noinspection ValidateTypes}}</td>
                    </ng-container>
                    <ng-container matColumnDef="statut">
                         <th #listHeaders mat-header-cell *matHeaderCellDef mat-sort-header="lien.statut.libelle">Statut</th>
                        <td mat-cell *matCellDef="let row" class="statut">{{row.lien?.statut?.libelle | titlecase}}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                         <th #listHeaders mat-header-cell *matHeaderCellDef mat-sort-header="mail.valeur">E-mail</th>
                        <td mat-cell *matCellDef="let row"  class="email">
                            <ul>
                                <li [ngClass]="{
                                     'green': row.consent === COORD_GLOBALS.CONSENT.VALID.id,
                                     'grey': row.consent === COORD_GLOBALS.CONSENT.ND.id,
                                     'red-cherry': row.consent === COORD_GLOBALS.CONSENT.INVALID.id || COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id
                                }">
                                    {{ row.mail.valeur }} <span *ngIf="row.principal === true">(principal)</span>
                                </li>
                            </ul>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="consent">
                         <th #listHeaders mat-header-cell *matHeaderCellDef mat-sort-header="consent">Consentement</th>
                        <td mat-cell *matCellDef="let row; let i = index"  class="consent">
                            <ng-container
                                    *ngIf="row === this.initialValues[row.uuid]?.consent
                                    || (row.consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id &&  this.initialValues[row.uuid] === undefined )
                                    || row.consent !== COORD_GLOBALS.CONSENT.ND.id && row.consentEditable !== true;
                                else showSelect"
                            >
                                <ng-container [ngTemplateOutlet]="consentDisplay"
                                              [ngTemplateOutletContext]="{'consent': row.consent}">
                                </ng-container>
                            </ng-container>
                            <ng-template #showSelect>
                                <app-consent-select
                                        [value]= "row.consent"
                                        (change)="changeConsent($event, row)"
                                >
                                </app-consent-select>
                            </ng-template>

                            <ng-template #consentDisplay let-consent="consent">
                                <div class="icon"
                                     [ngClass]="{
                                    'times-circle red-cherry': consent === COORD_GLOBALS.CONSENT.INVALID.id || consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id,
                                    'check green': consent === COORD_GLOBALS.CONSENT.VALID.id,
                                    'question-circle grey': consent === COORD_GLOBALS.CONSENT.ND.id
                                 }">
                                    <span *ngIf="consent === COORD_GLOBALS.CONSENT.VALID.id">Oui</span>
                                    <span *ngIf="consent === COORD_GLOBALS.CONSENT.INVALID.id">Non</span>
                                    <span *ngIf="consent === COORD_GLOBALS.CONSENT.ND.id">Non Renseigné</span>
                                    <span *ngIf="consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id">Désabonné</span>
                                </div>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="moyen">
<!--                        <th mat-header-cell *matHeaderCellDef mat-sort-header="consentType">Moyen de recueil</th>-->
                         <th #listHeaders mat-header-cell *matHeaderCellDef>Moyen de recueil</th>
                        <td mat-cell *matCellDef="let row, let i = index"  class="consentType">
                            <ng-container
                                    *ngIf="row.consent === COORD_GLOBALS.CONSENT.ND.id || row.consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id || row.consentEditable !== true else showSelect;"
                                    [ngTemplateOutlet]="consentTypeDisplay"
                                    [ngTemplateOutletContext]="{'consentType': row.consentType}">
                            </ng-container>
                            <ng-template #showSelect>
                                <app-moyen-recueil
                                        [value]= "{consent_type: row.consentType, consent_custom_type: row.consentDesc}"
                                        (change)="changeConsentType($event, row)"
                                ></app-moyen-recueil>
                            </ng-template>
                            <ng-template #consentTypeDisplay let-consentType="consentType">
                                <div *ngIf="(consentType || row.consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id) &&
                                 row.consent !== COORD_GLOBALS.CONSENT.ND.id; else
                                noContent">
                                    <span  *ngIf="row.consent !== COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id" >
                                        {{ ConsentHelper.getConsentTypeById(consentType)[0].text }}
                                        <ng-container *ngIf="consentType === 5">
                                            : {{row.consentDesc}}
                                        </ng-container>
                                    </span>
                                    <span  *ngIf="row.consent === COORD_GLOBALS.CONSENT.UNSUBSCRIBED.id" >
                                        <ng-container *ngIf="3 === row.consent || 5 === consentType">
                                         {{row.consentDesc}}
                                    </ng-container>
                                    </span>
                                </div>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ref">
                         <th #listHeaders mat-header-cell *matHeaderCellDef mat-sort-header="lien.referent">Référent</th>
                        <td mat-cell *matCellDef="let row"  class="referent">
                            <ng-container *ngIf="row.lien['intervenantReferent'] && row.lien['intervenantReferent']['user'] else noContent">
                                {{ row.lien['intervenantReferent']['user']['personnePhysique']['libelle'] }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:
                    !this.displayModalConsentAll && !this.displayModalCancel && !this.displayModal &&
                    !displayModalCancelSort && !this.displayModalCancelSearch"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell center" [attr.colspan]="displayedColumns.length">
                            Aucun résultat
                        </td>
                    </tr>
                </table>
    <div class="inline_loader" [hidden]="!isLoading" *ngIf="!this.displayModal">
        <ngx-ui-loader [loaderId]="'list'"></ngx-ui-loader>
    </div>
            </div>
        </div>
    </div>
    <div>
        <div class="form_bas">
            <button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Annuler</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    (click)="onSubmit()"
                    [ngClass]="{'btn_disabled': !form.valid || !this.isEdited}"
                    [disabled]="!form.valid || !this.isEdited"
            ><span>Enregistrer</span></button>
            <button type="button" class="btn btn-med btn-enregistrer"
                    (click)="onSubmitClose()"
                    [ngClass]="{'btn_disabled': !form.valid || !this.isEdited}"
                    [disabled]="!form.valid || !this.isEdited"
            ><span>Enregistrer et Fermer</span></button>
        </div>
    </div>
</div>
<notifier-container></notifier-container>
<!-- TEMPLATES -->
<ng-template #noContent>
    -
</ng-template>

<!-- MODALS -->
<jw-modal id="consent-all" [(display)]="displayModalConsentAll">
    <ngx-ui-loader  loaderId="selectConsent"></ngx-ui-loader>
    <app-consent-select-all *ngIf="displayModalConsentAll"
                            [type]="this.updateTypeConsentAll"
                            [nb]="this.listConsent.length"
                            (complete)="closeModalConsentAll($event)"
    ></app-consent-select-all>
</jw-modal>
<jw-modal id="consent-ok" [(display)]="displayModal">
    <app-information [titre]="this.MODAL_INFORMATIONS.RGPD['titre']"
                     [aide]="this.MODAL_INFORMATIONS.RGPD['aide']"
                     [bas]="this.MODAL_INFORMATIONS.RGPD['bas']"
                     [validerTexte]="this.MODAL_INFORMATIONS.RGPD['validerTexte']"
                     [fermerTexte]="this.MODAL_INFORMATIONS.RGPD['fermerTexte']"
                     [fermerOn]="this.MODAL_INFORMATIONS.RGPD['fermerOn']"
                     (valider)="modalClicked()"
    ></app-information>
</jw-modal>
<jw-modal id="cancel" [(display)]="displayModalCancel">
    <app-information [titre]="this.MODAL_INFORMATIONS.LEAVE_PAGE['titre']"
                     [aide]="this.MODAL_INFORMATIONS.LEAVE_PAGE['aide']"
                     [bas]="this.MODAL_INFORMATIONS.LEAVE_PAGE['bas']"
                     [validerTexte]="this.MODAL_INFORMATIONS.LEAVE_PAGE['validerTexte']"
                     [fermerTexte]="this.MODAL_INFORMATIONS.LEAVE_PAGE['fermerTexte']"
                     (fermer)="displayModalCancel = false"
                     (valider)="onCancelModal()"
    ></app-information>
</jw-modal>
<jw-modal id="cancelSort" [(display)]="this.displayModalCancelSort">
    <app-information [titre]="this.MODAL_INFORMATIONS.FILTER_DATA['titre']"
                     [aide]="this.MODAL_INFORMATIONS.FILTER_DATA['aide']"
                     [bas]="this.MODAL_INFORMATIONS.FILTER_DATA['bas']"
                     [validerTexte]="this.MODAL_INFORMATIONS.FILTER_DATA['validerTexte']"
                     [fermerTexte]="this.MODAL_INFORMATIONS.FILTER_DATA['fermerTexte']"
                     (fermer)="cancelData()"
                     (valider)="prepareSort(); this.listConsent = []"
    ></app-information>
</jw-modal>
