<div>
    <label class="label_info_container">
        Nb. de contacts
    </label>
</div>
<div class="nb_contact_segment">
    <div class="header_chart_custom">
        <div class="header_contact icon unlock color-public">
            {{ totalResultPublic }}
        </div>
        <div class="header_contact icon lock red color-private">
            {{ totalResultPrivate }}
        </div>
    </div>
    <div class="body_chart_custom">
        <div class="contact_public datas">
            <div class="body_contact public"
                 *ngFor="let result of data; let i = index"
                 [style.background]="result.colorPublic"
            >
                <div>{{ result.public }}</div>
            </div>
        </div>
        <div class="contact_private datas">
            <div class="body_contact private"
                 *ngFor="let result of data; let i = index"
                 [style.background]="result.colorPrivate"
            >
                <div>{{ result.prive }}</div>
            </div>
        </div>
    </div>
</div>
