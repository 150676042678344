<div class="content_modele">
    <div *ngIf="model.free === false && !model.buyDate"
         class="pricing flex justify-center flex-col items-center">
        <span class="text-white text-semi-bold text-lg">{{ model?.price }}€</span>
        <span class="text-white text-semi-bold">Hors taxes</span>
    </div>
    <div class="modele" [ngClass]="{'free': model?.free === true, 'payant': model?.free === false }">
        <div class="statut_modele" [ngClass]="{'free': model?.free === true || model?.buyDate, 'payant': !model.free && !model.buyDate}">
            <div class="sous_bandeau_campagne">
                <span>{{ model?.title }}</span>
            </div>
            <div class="bloc_modele_template" *ngIf="!modelVierge; else imgModelVierge">
                <img *ngIf="model?.image" class="img_modele" src="{{ rootImage }}{{model?.image}}"
                     alt="image du template de l'email">
            </div>
            <ng-template #imgModelVierge>
                <div class="bloc_modele_template">
                    <div class="fake_image" style="padding: 15px">
                        <div style="height: 270px; width: 270px; background-color: #B5BDBE"></div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="form_bas_modele" [ngClass]="{'display_end': modelVierge === true}"
             *ngIf="model?.free; else blockPayant">
            <button type="button" class="btn btn-med icon eye" *ngIf="!modelVierge"
                    (click)="openApercuTemplate('modalTemplate', model.id)">
                <span>Aperçu</span>
            </button>
            <button type="button" class="btn btn-med campagne icon check" *ngIf="modelVierge" (click)="chooseModel('vierge')">
                <span>Choisir ce modèle</span>
            </button>
            <button type="button" class="btn btn-med campagne icon check" *ngIf="!modelVierge" (click)="chooseModel('')">
                <span>Choisir ce modèle</span>
            </button>
        </div>
        <ng-template #blockPayant>
            <div class="form_bas_modele">
                <button type="button" class="btn btn-med icon eye" *ngIf="!modelVierge"
                        (click)="openApercuTemplate('modalTemplate', model.id)">
                    <span>Aperçu</span>
                </button>
                <button type="button" class="btn btn-med campagne icon basket"
                        *ngIf="!model?.free && !model?.buyDate" (click)="openModalBuyTemplate('modalBuyTemplate', model.id)">
                    <span>Acheter ce modèle</span>
                </button>
                <button type="button" class="btn btn-med campagne icon check"
                        *ngIf="(model?.free && !model?.buyDate) || model?.buyDate"
                        (click)="chooseModel('')">
                    <span>Choisir ce modèle</span>
                </button>
            </div>
        </ng-template>
    </div>
</div>
