import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Personne} from '../../../models/personne';
import {PersonGlobals} from '../../../const-global/globals';
import {Router} from '@angular/router';
import {Lead} from "../../_services/lead.service";
import {PersonneService} from "../../_services/personne.service";

@Component({
    selector: 'app-memos',
    templateUrl: './memos.component.html',
    styleUrls: ['./memos.component.scss']
})
export class MemosComponent implements OnInit {

    @Input() personne: Personne;
    @Input() offre: Lead;
    @Input() persist: boolean;
    @Input() disabled: boolean;
    @Input() memoFiche: boolean;
    @Output() changed = new EventEmitter<string>();
    public readonly PERSON_GLOBALS = PersonGlobals;
    public memoList = null;

    constructor(
        public router: Router,
        public personneService: PersonneService
    ) {
    }

    ngOnInit(): void {
        if (this.personne) {
            this.memoList = this.personne;
        }
        if (this.offre) {
            this.memoList = this.offre;
        }
        this.personneService.subjectPersonne.subscribe(value => {
            this.memoList = value;
        });
    }
}
