import {Component, OnInit} from '@angular/core';
import {SegmentService} from '../../_services/segment.service';
import {ISegment} from '../../../interfaces/segment';

@Component({
    selector: 'app-liste-cartes-segment',
    templateUrl: './liste-cartes-segment.component.html',
    styleUrls: ['./liste-cartes-segment.component.scss']
})

export class ListeCartesSegmentComponent implements OnInit {

    public errorMessage: string;
    public segments: Array<ISegment>;
    private finListe = false;
    private rechercheEnCours = false;
    // Paramètres
    public offset = 0;
    public limit = 20;
    public recherche = '';
    public ordre = 'DESC';

    public filter = {
        search: ''
    };

    constructor(private segmentService: SegmentService) {
    }

    ngOnInit(): void {
        const ls = localStorage.getItem('segment') || '{"search": null}';
        this.filter = JSON.parse(ls);

        this.segmentService.getSegmentsListe(
            this.offset.toString(),
            this.limit.toString(),
            this.filter.search,
            this.ordre
        ).subscribe(
            data => {
                this.segments = data;
            },
            err => {
                this.errorMessage = err.error.message;
            }
        );
    }

    onScroll(): void {
        if (!this.rechercheEnCours && !this.finListe) {
            this.rechercheEnCours = true;
            this.offset = this.offset + this.limit;
            this.segmentService.getSegmentsListe(
                this.offset.toString(),
                this.limit.toString(),
                this.recherche,
                this.ordre
            ).subscribe(data => {
                    this.finListe = data.length === 0;
                    data.map((item) => this.segments.push(item));
                    this.rechercheEnCours = false;
                },
                err => {
                    this.errorMessage = err.error.message;
                }
            );
        }
    }

    onRecherche(recherche: string): void {
        this.filter.search = recherche;
        localStorage.setItem('segment', JSON.stringify(this.filter));
        this.offset = 0;
        this.segmentService.getSegmentsListe(
            this.offset.toString(),
            this.limit.toString(),
            this.filter.search,
            this.ordre
        ).subscribe(data => {
                this.segments = [];
                data.map((item) => this.segments.push(item));
                this.offset = this.offset + this.limit;
            },
            err => {
                this.errorMessage = err.error.message;
            }
        );
    }
}
