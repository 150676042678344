import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonGlobals} from '../../../const-global/globals';
import {PersonneService} from '../../_services/personne.service';
import {EvenementService} from '../../_services/evenement.service';
import {IPersonnelien} from '../../../interfaces/personne-lien';
import {EntityTabsService} from "../../_services/entity-tabs.service";
import {EntityType} from "../../../models/lastentity";

@Component({
    selector: 'app-repertoire-carte',
    templateUrl: './carte.component.html',
    styleUrls: ['./carte.component.scss'],
})

export class CarteComponent {
    public readonly PERSON_GLOBALS = PersonGlobals;
    @Input() personne: IPersonnelien;
    @Input() formatCarte: boolean;
    @Input() formatFiche: boolean;
    @Input() centered: boolean;
    @Input() carteEvent: boolean;
    @Input() modeAffichage: string;
    @Input() ongletName: string;
    @Input() arboType: string;
    public uuidFicheUrl = this.route.snapshot.params.id;
    public PERSON_GLOBAL = PersonGlobals;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private evenementService: EvenementService,
        private personneService: PersonneService,
        private entityTabsService: EntityTabsService,
    ) {
    }


    openFiche(personnelien: IPersonnelien): void {
        this.addTabs(personnelien);
        this.router.navigate(
            ['/repertoire/' + personnelien.uuid],
            {queryParams: {onglet: this.carteEvent ? 'event' : 'personne-linked'}}
        );
    }

    addTabs(personneLien: IPersonnelien) {
        this.entityTabsService.upsert({
            uuid: personneLien.personnePhysique?.uuid || personneLien.personneMorale?.uuid,
            label: personneLien.personnePhysique.libelle || personneLien.personneMorale.libelle,
            type: personneLien.type as EntityType,
            uri: '/repertoire/' + personneLien.uuid,
        })
    }

    hasCoordinates(): boolean {
        return (
            (this.personne.adressePrincipal?.adresse?.cp ||
                this.personne.adressePrincipal?.adresse?.ville ||
                this.personne.telephonePrincipal?.telephone?.text ||
                this.personne.mailPrincipal?.mail?.valeur) !== undefined
        );
    }
}
