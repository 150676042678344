import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {Lead, LeadService} from '../../_services/lead.service';
import {ParametreGlobals, PersonGlobals} from '../../../const-global/globals';
import {NotifierService} from 'angular-notifier';
import {HelperService} from '../../_services/helper.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Subject, Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SegmentService} from '../../_services/segment.service';
import {DevisService} from '../../_services/devis.service';
import {DevisligneService} from '../../_services/devisligne.service';
import {MemoService} from '../../_services/memo.service';
import {date} from '../../../const-global/format-date';
import {PersonneService} from '../../_services/personne.service';
import {IPersonnelien} from '../../../interfaces/personne-lien';
import {ITag} from '../../../interfaces/tag';
import {
	ModalConfirmProbabiliteComponent
} from '../../layout/modal-confirm-probabilite/modal-confirm-probabilite.component';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EvenementService} from "../../_services/evenement.service";
import {EventStatus, EventType} from "../../../interfaces/evenement";
import {ModalSendDevisComponent} from "../modal-send-devis/modal-send-devis.component";

@Component({
	selector: 'app-fiche-offre',
	templateUrl: './fiche-offre.component.html',
	styleUrls: ['./fiche-offre.component.scss'],
	animations: [
		trigger('opening', [
			state('opened',
				style({height: '200px'})
			),
			state('closed',
				style({height: '0px'})
			),
			transition('opened => closed', [
				animate('0.5s')
			]),
			transition('closed => opened', [
				animate('0.5s')
			])
		])
	]
})
export class FicheOffreComponent implements OnInit, OnDestroy {
	@ViewChild('confirmModal') confirmModal: ModalConfirmProbabiliteComponent;
	@ViewChild('sendDevisModal') sendDevisModal: ModalSendDevisComponent;
	public fullDate = date.fullDate;
	public form: FormGroup;
	public PERSON_GLOBALS = PersonGlobals;
	public offre: Lead;
	public value: any;
	public newProbabilite: number;
	public currentProbabilite = new Subject();
	subscriptions = {
		activeSubject: Subscription.EMPTY
	};
	public personneContact: IPersonnelien;
	public montantTTC: string;
	public montantHT: string;
	public tagTarget = ParametreGlobals.TAG_ID_OFFRE;
	public priorityData: any;
	public pdfUrl: SafeResourceUrl;
	public pdfFilename: string;

	constructor(public router: Router,
	            public route: ActivatedRoute,
	            private location: Location,
	            private formBuilder: FormBuilder,
	            private leadService: LeadService,
	            private devisService: DevisService,
	            private devisLigneService: DevisligneService,
	            private service: SegmentService,
	            private helperService: HelperService,
	            private memoService: MemoService,
	            private notifier: NotifierService,
	            private ngxService: NgxUiLoaderService,
	            private personneService: PersonneService,
	            private domSanitizer: DomSanitizer,
	            private evenementService: EvenementService,
	) {
	}

	ngOnDestroy(): void {
		// noinspection JSUnusedLocalSymbols
		for (const [key, value] of Object.entries(this.subscriptions)) {
			value.unsubscribe();
		}
	}

	ngOnInit(): void {
		this.subscriptions.activeSubject = this.leadService.activeSubject.subscribe(() => {
			this.ficheInit();
		});
	}

	ficheInit(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		const priorites = [
			{
				id: 4,
				text: 'Urgente',
				icon: 'critical'
			},
			{
				id: 3,
				text: 'Elevée',
				icon: 'high'
			},
			{
				id: 2,
				text: 'Normale',
				icon: 'medium'
			},

			{
				id: 1,
				text: 'Faible',
				icon: 'low'
			}
		];
		const formattedDatasPrio = [];

		priorites.forEach(prio => {
			formattedDatasPrio.push({
				id: prio.id,
				text: prio.text,
				icon: prio.icon
			});
		});
		this.priorityData = [...formattedDatasPrio];
		const id = this.route?.snapshot.params.id;
		if (id) {
			this.pdfFilename = `${id}.pdf`
			this.leadService.detail(id).subscribe(lead => {
				this.offre = new Lead(lead);
				this.currentProbabilite.next(this.offre.probabilite?.id);
				this.montantTTC = this.formatCurrency(this.offre.devis?.montantTTC / 100);
				this.montantHT = this.formatCurrency(this.offre.devis?.montantHT / 100);

				this.personneService.getDatasFiche(this.offre.contact?.uuid).subscribe((personne) => {
					this.personneContact = personne;
				});
				this.initForm();
				if (lead.devis.lignes.length) {
					this.leadService.getPdfUrl(id)
						.subscribe((url) => {
							this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${url}#toolbar=0&navpanes=0&scrollbar=0`)
						})
				}
			});
		}
	}

	private initForm(): void {
		this.offre?.memos.sort((a, b) => (a.dateModification < b.dateModification) ? 1 : -1);
		this.form = this.formBuilder.group({
				uuid: [this.offre?.uuid],
				tags: [this.offre?.tags],
				memos: [this.offre?.memos],
				probabilite: [this.offre?.probabilite]
			}, {}
		);
		this.form.controls.tags.valueChanges.subscribe(() => {
			this.updateTags();
		});
		this.ngxService.stop();
	}

	private updateTags(): void {
		const dataTags = [];
		this.form?.controls.tags?.value.forEach((tag: ITag) => {
			dataTags.push('/api/v1/tags/' + tag.uuid);
		});
		this.leadService.patch(this.offre?.uuid, {tags: dataTags}).subscribe(() => {
			this.notifier.notify('success', 'Le tag a bien été mis à jour');
		});
	}

	private formatCurrency(numberData: number): string {
		return numberData.toLocaleString(
			'fr-FR',
			{
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
				style: 'currency',
				currency: 'EUR'
			}
		);
	}

	public updateProbabilite($event): void {
		if (this.form?.controls.probabilite?.value.id < 100 && this.form?.controls.probabilite?.value.id !== $event) {
			this.newProbabilite = $event;
			this.confirmModal.openConfirmationModal();
		}
	}

	openEdition(): void {
		this.router.navigate([{outlets: {primary: 'offre/' + this.offre?.uuid + '/modifier'}}]).then();
	}

	onConfirmModal($event): void {
		if ($event) {
			this.form?.controls.probabilite?.setValue({id: this.newProbabilite});
			this.currentProbabilite.next(this.newProbabilite);
			this.leadService.patch(this.offre?.uuid, {probabilite: this.newProbabilite}).subscribe(() => {
			});
		}
	}

	onCancelModal($event): void {
		if ($event) {
			this.currentProbabilite.next(this.form?.controls.probabilite?.value.id);
		}
	}

	onDownload() {
		this.evenementService.create({
			type: EventType.leadDownloaded,
			statut: EventStatus.endEd,
			titre: 'Devis Téléchargé',
			description: '',
			lead: `/api/v1/leads/${this.offre.uuid}`
		}).subscribe()
	}
}
