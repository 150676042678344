import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CampagneTemplateService} from '../../_services/campagne-template.service';
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
    selector: 'app-campagne-galerie-modeles',
    templateUrl: './campagne-galerie-modeles.component.html',
    styleUrls: ['./campagne-galerie-modeles.component.scss']
})
export class CampagneGalerieModelesComponent implements OnInit {
    public form: FormGroup;
    public models = [];

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public campagneTemplateService: CampagneTemplateService,
        private ngxService: NgxUiLoaderService
    ) {
    }

    ngOnInit(): void {
        this.ngxService.start();
        this.campagneTemplateService.getAllTemplateCampagne().subscribe((data: any) => {
            data['hydra:member'].forEach((template) => {
                this.models.push(template);
                this.ngxService.stop();
            })
        });

        this.campagneTemplateService.templateCampagneSubject.subscribe((data) => {
            this.models = data;
            this.ngxService.stop();
        });
        this.ngxService.stop();
    }
}
