import {IDataFilter} from '../interfaces/datafilter';
import {FilterItem} from './filteritem';

export class DataFilter implements IDataFilter {
    lsKey?: string;
    total?: number;
    filters?: Array<FilterItem>;
    active?: boolean;
    itemsPerPage?: number;
    page?: number;
    search?: string;

    constructor(itemsPerPage?: number, page?: number, search?: string) {
        this.itemsPerPage = itemsPerPage ?? 28;
        this.page = page ?? 1;
        this.search = search ?? null;
        this.active = true;
    }

    public reInit(): void {
        this.page = 1;
        this.filters.forEach(filter => {
            filter.value = filter.hidden ? filter.value : null;
        });
    }

    public getQueryParams(): any {
        const queryParams = {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
            search: this.search ?? '',
            active: this.active,
        };
        this.filters.forEach(filter => {
            if (filter.value) {
                queryParams[filter.attributeName] = Array.isArray(filter.value)
                    ? filter.value.map(i => i?.uuid || i)
                    : filter.value.uuid || filter.value;
            }
        });
        return queryParams;
    }

    getValues(): Object {
        const queryParams = {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
            search: this.search ?? '',
            active: this.active,
        };
        this.filters.forEach(filter => {
            if (filter.value) {
                queryParams[filter.attributeName] = filter.value;
            }
        });

        return queryParams;
    }

    public getCounts(): any {
        let total = 0;
        const counts = {
            total: 0
        };
        this.filters.forEach(filter => {
            if (!filter.hidden) {
                counts[filter.attributeName] = filter.getCount();
                total += counts[filter.attributeName];
            }
        });
        counts.total = total;
        return counts;
    }

    public setValuesFromLS(model): void {
        this.page = model.page ?? this.page;
        this.itemsPerPage = model.itemsPerPage ?? this.itemsPerPage;
        this.active = model.active ?? this.active;
        this.search = model.search ?? this.search;
        this.filters.forEach(filter => {
            if (model[filter.attributeName]) {
                if (filter.inputType === 'checkbox') {
                    filter.value = model[filter.attributeName] ?
                        !Array.isArray(model[filter.attributeName])
                            ? [model[filter.attributeName]]
                            : model[filter.attributeName] : filter.value;
                } else if (filter.inputType === 'select') {
                    filter.value = model[filter.attributeName] ?
                        !Array.isArray(model[filter.attributeName])
                            ? [model[filter.attributeName]]
                            : model[filter.attributeName] : filter.value;
                } else {
                    filter.value = model[filter.attributeName] ?? filter.value;
                }
            }
        });
    }
}
