<div class="adresses warning" *ngIf="this.adresses?.length === 0">
    <div class="container_adresses"
         *ngIf="personType !== PERSON_GLOBALS.PERSON_LIEN"
         [ngClass]="{'background_alert_recommended': this.adresses?.length === 0}"
    >
        <div class="border_icon_coordonnee icon"></div>
        <div class="data_adresses">
            <div class="input_container">
                <div class="icon i_alerte"></div>
                <div>
                    <label class="type_adresse">Veuillez saisir au moins une adresse</label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="adresses" *ngIf="adresses">
    <div class="container_adresses" *ngFor="let adresse of adresses">
        <div class="border_icon_coordonnee icon"></div>
        <div class="data_adresses">
            <div class="input_container">
                <input
                        name="'adresse'"
                        (change)="onChangeRadio(adresse.uuid)"
                        type="radio"
                        [checked]="adresse.principal"
                />

                <div class="container_radio">
                    <label class="type_adresse">{{adresse.type}}</label>
                    <span *ngIf="adresse.lignes.ligne1">{{adresse.lignes.ligne1}} </span>
                    <span *ngIf="adresse.lignes.ligne2">{{adresse.lignes.ligne2}} </span>
                    <span *ngIf="adresse.lignes.ligne3">{{adresse.lignes.ligne3}} </span>
                    <span>{{adresse.cp}} {{adresse.ville}} </span>
                    <span>{{adresse.cedexCode}} {{adresse.cedexLibelle}} </span>
                    <span>{{adresse.pays}}</span>

                </div>
                <div class="container_bouton_adresses">
                    <button type="button" class="btn btn-picto-med btn-editer" (click)="onEditer(adresse)"></button>
                    <button type="button" class="btn btn-picto-med btn-supprimer"
                    (click)="onSupprimer(adresse.uuid)"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="bloc_container">
        <button type="button" class="btn btn-med btn-ajouter" (click)="onAjouter()">
            <span>Ajouter une adresse</span>
        </button>
    </div>
</div>
<app-adresses-edit-modal
    #addressModal
    (closed)="close()"
    (added)="updateAdresses($event)"
></app-adresses-edit-modal>
