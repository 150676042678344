<notifier-container></notifier-container>
<ng-container *ngIf="!router.url.endsWith('/modifier')">
    <div>
        <app-fil-ariane></app-fil-ariane>
        <ngx-ui-loader></ngx-ui-loader>
    </div>
    <div class="fiche">
        <div class="fiche_gauche">
            <div class="fiche_infos" *ngIf="campaign">
                <div class="header">
                    <span class="icon mail"></span>
                    <span class="title">{{ campaign.libelle }}</span>
                    <div class="btn_border_white">
                        <button type="button" class="btn btn-picto-lg picto-edition" (click)="updateCampaign(campaign)"
                                [disabled]="campaign.statut > CAMPAIGN_GLOBALS.QUEUED_ID"
                                [ngClass]="{'btn_disabled': campaign.statut > CAMPAIGN_GLOBALS.QUEUED_ID}"
                        >
                        </button>
                    </div>
                </div>
                <div class="content">
                    <div>
                        <h5>Segment(s)</h5>
                        <span class="datas">{{ getSegmentsLists() }}</span>
                    </div>
                    <ng-container *ngIf="campaign.statut != CAMPAIGN_GLOBALS.SENT_ID; else send">
                        <div>
                            <h5>Statut</h5>
                            <span class="datas flex items-center gap-1"
                                  [ngClass]="{'inprogress' : campaign.statut == CAMPAIGN_GLOBALS.IN_PROCESS_ID}">
                                        {{ getStatutLibelle(campaign.statut) }}
                                        <ng-container *ngIf="campaign.statut == CAMPAIGN_GLOBALS.IN_PROCESS_ID">...</ng-container>
                                 <button type="button" class="btn btn-med btn-fermer stop gap-2"
                                         *ngIf="campaign.statut == CAMPAIGN_GLOBALS.IN_PROCESS_ID"
                                         (click)="stopCampaign()"
                                         [ngClass]="{'btn_disabled': campaign.statut > CAMPAIGN_GLOBALS.IN_PROCESS_ID, 'updating': updateInProgress}"
                                         [disabled]="campaign.statut > CAMPAIGN_GLOBALS.IN_PROCESS_ID">
                                    Annuler
                                </button>
                            </span>

                        </div>
                    </ng-container>
                    <ng-template #send>
                        <div class="flex items-center infos justify-between">
                            <div>
                                <h5>Envoi démarré le</h5>
                                <span class="inprogress datas">
                                    {{dateFormaterService.getDate(this.campaign.scheduledAt)}}
                                </span>
                            </div>
                            <div *ngIf="campaign.statut == CAMPAIGN_GLOBALS.SENT_ID && this.campaign?.endedAt">
                                <h5>Envoi terminé le</h5>
                                <span class="sent datas">
                                    {{dateFormaterService.getDate(this.campaign?.endedAt)}}
                                </span>
                            </div>
                        </div>
                        <div *ngIf="campaign.statut == CAMPAIGN_GLOBALS.IN_PROCESS_ID">
                            <button type="button" class="btn btn-med btn-envoyer stop"
                                    (click)="stopCampaign()"
                                    [ngClass]="{'btn_disabled': campaign.statut > CAMPAIGN_GLOBALS.IN_PROCESS_ID, 'updating': updateInProgress}"
                                    [disabled]="campaign.statut > CAMPAIGN_GLOBALS.IN_PROCESS_ID">
                                Annuler
                            </button>
                        </div>
                    </ng-template>
                    <div *ngIf="campaign.statut == CAMPAIGN_GLOBALS.DRAFT_ID"
                         class="flex justify-center items-center">
                        <button type="button" class="btn btn-med btn-planifier"
                                [ngClass]="{'btn_disabled': campaign.statut >= CAMPAIGN_GLOBALS.QUEUED_ID}"
                                routerLink="/campagne/{{ campaign.uuid }}/modifier"
                        >
                            Planifier
                        </button>
                        <button type="button" class="btn btn-med btn-envoyer"
                                (click)="scheduleCampaignNow()"
                                [ngClass]="{'btn_disabled': campaign.statut >= CAMPAIGN_GLOBALS.IN_PROCESS_ID, 'updating': updateInProgress}"
                                [disabled]="campaign.statut >= CAMPAIGN_GLOBALS.IN_PROCESS_ID">
                            Envoyer maintenant
                        </button>
                    </div>
                    <div *ngIf="campaign.statut == CAMPAIGN_GLOBALS.QUEUED_ID"
                         class="flex justify-between items-center"
                    >
                        <div>
                            <h5>Envoi planifié le</h5>
                            <span class="datas"
                                  [ngClass]="{'queued': campaign.statut == CAMPAIGN_GLOBALS.QUEUED_ID}">
                                    <ng-container *ngIf="this.campaign.statut === CAMPAIGN_GLOBALS.QUEUED_ID">
                                        {{dateFormaterService.getDate(this.campaign.scheduledAt)}}
                                    </ng-container>
                                </span>
                        </div>
                        <div>
                            <button type="button" class="btn btn-med btn-envoyer"
                                    (click)="scheduleCampaignNow()"
                                    [ngClass]="{'btn_disabled': campaign.statut >= CAMPAIGN_GLOBALS.IN_PROCESS_ID, 'updating': updateInProgress}"
                                    [disabled]="campaign.statut >= CAMPAIGN_GLOBALS.IN_PROCESS_ID">
                                Envoyer maintenant
                            </button>
                        </div>
                    </div>
                    <div *ngIf="campaign.statut == CAMPAIGN_GLOBALS.SENT_ID">
                        <div>
                            <h5>Nombre de destinataires</h5>
                            <span class="datas">{{ this.campaign?.stats.nbDest }}</span>
                        </div>
                    </div>
                    <div class="campaign_stats" *ngIf="campaign.statut == CAMPAIGN_GLOBALS.SENT_ID">
                        <div class="stats_item">
                            <span>Envoyés</span>
                            <div class="pl-2 pr-2">
                                <div class="relative">
                                    <mat-progress-bar class="progress_bar" color="warn"
                                                      [value]="percentSent"></mat-progress-bar>
                                    <span class="number_item">{{sent}}</span>
                                </div>
                            </div>
                            <span class="text-semi-bold">{{percentSent}}%</span>
                        </div>
                        <div class="stats_item">
                            <span>Echecs</span>
                            <div class="pl-2 pr-2">
                                <div class="relative">
                                    <mat-progress-bar class="progress_bar echec"
                                                      [value]="percentFailure"></mat-progress-bar>
                                    <span class="number_item">{{failure}}</span>
                                </div>
                            </div>
                            <span class="text-semi-bold">{{percentFailure}}%</span>
                        </div>
                        <div class="stats_item">
                            <span>Ouverts</span>
                            <div class="pl-2 pr-2">
                                <div class="relative">
                                    <mat-progress-bar class="progress_bar" [value]="percentOpened"></mat-progress-bar>
                                    <span class="number_item">{{opened}}</span>
                                </div>
                            </div>
                            <span class="text-semi-bold">{{percentOpened}}%</span>
                        </div>
                        <div class="stats_item">
                            <span>Cliqués</span>
                            <div class="pl-2 pr-2">
                                <div class="relative">
                                    <mat-progress-bar class="progress_bar"
                                                      color="warn"
                                                      [value]="percentClicked">
                                    </mat-progress-bar>
                                    <span class="number_item">{{clicked}}</span>
                                </div>
                            </div>
                            <span class="text-semi-bold">{{percentClicked}}%</span>
                        </div>
                    </div>
                </div>
                <div class="footer flex justify-center">
                    <button type="button" class="btn btn-med btn-copy"
                            (click)="duplicate(campaign.uuid)"
                            [ngClass]="{'updating': duplicateInProgress}"
                    ><span>Dupliquer</span></button>
                    <span><button type="button" class="btn btn-med btn-copy btn_disabled" title="Prochainement disponible">Créer une relance</button></span>
                </div>
            </div>
            <div class="fiche_spare_infos">
                <div class="status_personne">
                    <div class="bloc_actif">
                        <i class="icon" [ngClass]="{
                                'actif': campaign?.actif,
                                'inactif': !campaign?.actif
                            }"></i>
                        <span *ngIf="campaign?.actif">Actif</span>
                        <span class="inactif" *ngIf="!campaign?.actif">Inactif</span>
                    </div>
                </div>
                <div class="date_creation_fiche">
                    <p>
                        <span>Créé le <b>{{ this.campaign?.creationDate.date | date:'dd/MM/yyyy' }} </b></span>
                        <span>par <b>{{ this.campaign?.userCreation }}</b></span>
                    </p>
                    <p>
                        <span>Modifié le <b>{{ this.campaign?.modificationDate.date | date:'dd/MM/yyyy'}} </b></span>
                        <span>par <b>{{ this.campaign?.userModification }}</b></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="fiche_tabs">
            <div class="onglet">
                <div class="onglet_fiche icon file-alt"
                     (click)="changeTab('message')"
                     [ngClass]="{selected: selectTab === 'message'}">
                    Message
                </div>
                <div class="onglet_fiche icon list"
                     (click)="changeTab('destinataires')"
                     [ngClass]="{selected: selectTab === 'destinataires'}">
                    Destinataires
                </div>
            </div>
            <div class="content" *ngIf="campaign && this.selectTab === 'message'">
                <div class="btn-test"
                     *ngIf="campaign.statut.toString() === CAMPAIGN_GLOBALS.DRAFT_ID.toString()
                     || campaign.statut.toString() === CAMPAIGN_GLOBALS.QUEUED_ID.toString()"
                >
                    <div class="btn btn-med btn-envoyer bg-orange" (click)="openModal('envoiTest')">Envoyer un test
                    </div>
                </div>
                <iframe id="preview_campagne" [srcdoc]="campaign.body | previewCampagne"
                        *ngIf="campaign.body else nocontent;"></iframe>
                <ng-template #nocontent>
                    <div class="no-content">Template E-mail non paramétré</div>
                </ng-template>
            </div>
            <div [ngClass]="{'hidden': this.selectTab !== 'destinataires'}" class="list_destinataires">
                <div class="mat-elevation-z8">
                    <ngx-ui-loader loaderId="campagne_destinataires"></ngx-ui-loader>
                    <section class="table-contact mat-elevation-z8" tabindex="0">
                            <div class="input_search">
                                <input
                                        type="search"
                                        placeholder="Recherche"
                                        size="30"
                                        class="search"
                                        (keyup)="applyFilter($event)"
                                >
                                <i class="fas fa-search"></i>
                            </div>
                        <div class="table-block" #matTableRef>
                            <table mat-table [dataSource]="dataSourced" matSort matSortActive="libelle"
                                   matSortDirection="asc" #tableSort="matSort"
                                   (matSortChange)="sortData()">
                                <ng-container matColumnDef="libelle">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="libelle">Nom</th>
                                    <td mat-cell *matCellDef="let row">{{row.libelle}}</td>
                                </ng-container>
                                <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="email">E-mail</th>
                                    <td mat-cell *matCellDef="let row">{{row.email}}</td>
                                </ng-container>
                                <ng-container matColumnDef="statut">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="statut"
                                        [disabled]="mode <= 3">Statut</th>
                                    <td mat-cell *matCellDef="let row" class="statut">
                                        <div *ngIf="row.statut;else clickUndefined">
                                            <div class="icon"
                                                 [ngClass]="{
                                    'times-circle red-cherry': row.statut.etat === 0,
                                    'check green': row.statut.etat === 1,
                                    'question-circle grey': row.statut.etat === ''
                                 }">
                                            </div>
                                            <div>
                                                {{row.statut.label}}
                                            </div>
                                        </div>
                                        <ng-template #clickUndefined>-</ng-template>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="nombre de clics">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="click"
                                        [disabled]="mode <= 3">Nombre de clics</th>
                                    <td mat-cell *matCellDef="let row"><span
                                            *ngIf="row.click; else clickUndefined">{{row.click}}</span>
                                        <ng-template #clickUndefined>-</ng-template>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns;  sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell center" [attr.colspan]="displayedColumns.length">
                                        Aucun résultat
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </section>
                    <tr mat-footer-row *matFooterRowDef="['paginator']; sticky: true"></tr>
                </div>
            </div>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="modalEnvoiTest"></ng-container>
</ng-container>
<ng-template #modalEnvoiTest>
    <jw-modal id="envoiTest">
        <div class="bloc_title_modal">Envoyer un e-mail test</div>
        <hr>
        <div class="bloc_body_modal">
            <div class="modal-info">
                <p>Vous êtes sur le point d’envoyer un e-mail test à l’adresse suivante.</p>
                <p>Si vous souhaitez rajouter des adresses e-mail, veuillez les ajouter ci dessous.</p>
            </div>
            <div [formGroup]="formModal">
                <div class="form-inline-grid">
                    <div>
                        <input type="text" formControlName="user_email">
                    </div>
                </div>
                <ng-container formArrayName="emails">
                    <div *ngFor="let emailInput of emails.controls; let i = index;" class="form-inline-grid">
                        <input type="email" [formControlName]="i">
                        <button type="button" class="btn btn-med btn-supprimer" (click)="removeEmail(i)"></button>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="emails.controls.length < 2">
                <button type="button" class="btn btn-med btn-ajouter" (click)="addNewInputMail()"></button>
            </div>
        </div>
        <div class="bloc_footer_modal">
            <div class="action_bloc_modal">
                <button type="reset" class="btn btn-med btn-fermer" (click)="closeModal('envoiTest')">
                    <span>Annuler</span></button>
                <button type="submit" class="btn btn-med btn-envoyer bg-orange"
                        (click)="validateAndClose('envoiTest')"><span>Envoyer l'e-mail test</span>
                </button>
            </div>
        </div>
    </jw-modal>
</ng-template>
