import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IChamp} from '../../interfaces/champ';


const API_URL = environment.apiURL;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
            accept: 'application/son'
        }
    )
};

@Injectable({
    providedIn: 'root'
})
export class ChampPersoService {

    constructor(private http: HttpClient) {
    }

    getAllChampsPerso(recherche: string): Observable<any> {
        const options = {
            params: new HttpParams()
                .set('recherche', recherche)
        };
        return this.http.get(API_URL + 'parametre/champs-perso/all', options);
    }

    getAllTypeChampsPerso(): Observable<any> {
        return this.http.get(API_URL + 'parametre/type/champs-perso');
    }

    getDatasChamp(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'parametre/champs-perso/' + uuid);
    }

    creerChamp(form: IChamp): Observable<any> {
        return this.http.post(API_URL + 'parametre/creer/champ-perso', {
            champ: form,
        }, httpOptions);
    }

    updateChamp(uuid: string, form: IChamp): Observable<any> {
        return this.http.put(API_URL + 'parametre/edit/champ-perso/' + uuid, {
            champ: form,
        }, httpOptions);
    }

    countChampUsage(uuid: string): Observable<any> {
        return this.http.get(API_URL + 'parametre/champ/' + uuid + '/count');
    }
}
