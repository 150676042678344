<jw-modal id="confirmCancelling">
    <div class="bloc_title_modal" *ngIf="probabilite < 100">Modification du statut</div>
    <div class="bloc_title_modal"  *ngIf="probabilite > 99">Avertissement !</div>
    <div class="bloc_body_modal">
        <div *ngIf="probabilite < 100">
            Êtes-vous sûr de vouloir modifier le statut de cette offre ?
        </div>
        <div *ngIf="probabilite > 99">
        Vous allez passer cette offre en statut <ng-container *ngIf="probabilite === 100">"100% - Signé"</ng-container><ng-container *ngIf="probabilite === 999">"-100% - Perdu"</ng-container>.
        <div class="second_div" >
            Suite à cette action, l'offre ne sera plus modifiable.
            <br />
            <strong class="action_modal valider">Cette action est irréversible.</strong>
            </div>
        </div>
    </div>
    <div class="bloc_footer_modal">
        <div class="action_bloc_modal">
            <button type="reset" class="btn btn-med btn-fermer" (click)="cancelConfirmationModal()">
                <span>Annuler</span></button>
            <button type="submit" class="btn btn-med btn-valider" (click)="confirmConfirmationModal()">
                <span>Confirmer</span>
            </button>
        </div>
    </div>
</jw-modal>
