<div class="liste_carte">
    <table>
        <thead>
            <tr>
                <th></th>
                <th>Nom / Raison sociale</th>
                <th>Type</th>
                <th>Fonction</th>
                <th>Ville</th>
                <th>Email</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let personne of personnes; let i = index" class="row_tableau">
                <td class="action row_{{personne.type}}"><button type="button" class="btn btn-picto-med picto-edition" (click)="openFiche(personne.uuid)"></button></td>
                <td *ngIf="personne.type === 'physique'" class="row_{{personne.type}}">{{ personne.nom  |titlecase }}</td>
                <td *ngIf="personne.type === 'morale' || personne.type === 'lien' " class="row_{{personne.type}}">{{ personne.raisonSociale  |titlecase }}</td>
                <td class="row_{{personne.type}}">{{ personne.type  |titlecase }}</td>
                <td class="row_{{personne.type}}">{{ personne.fonction  |titlecase }}</td>
                <td class="row_{{personne.type}}">{{ personne.ville  |titlecase }}</td>
                <td class="row_{{personne.type}}">{{ personne.mail  |titlecase }}</td>
            </tr>
        </tbody>
    </table>
</div>
