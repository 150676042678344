import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PersonneService} from '../../../_services/personne.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../../_services/exception.service';
import {MessageGlobals} from "../../../../const-global/messages";
import {IPersonnelien} from "../../../../interfaces/personne-lien";
import {PersonGlobals} from "../../../../const-global/globals";

@Component({
    selector: 'app-creer-fonction',
    templateUrl: './creer-fonction.component.html',
    styleUrls: ['./creer-fonction.component.scss']
})
export class CreerFonctionComponent implements OnInit {

    public uuid = this.route.snapshot.params.id;
    public type = this.route.snapshot.data ? this.route.snapshot.data.type : null;
    public libelle = window.sessionStorage.getItem(this.uuid);
    public personneMorale = [];
    public valuePhysique: { id: any; text: string };
    public message: string;
    public form: FormGroup;
    public pmFetcher = () => this.personneService.getPersonnesSelect('morale');
    public ppFetcher = () => this.personneService.getPersonnesSelect('physique');
    public personne: IPersonnelien;

    constructor(
        private personneService: PersonneService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private notifier: NotifierService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService
    ) {
    }

    ngOnInit(): void {
        if (this.type === 'physique') {
            this.form = this.formBuilder.group({
                morale: ['', Validators.required],
                physique: ['', Validators.required],
                fonction: ['', Validators.required],
                fonction_personnalisee: ['']
            }, {});
            this.route.parent.data.subscribe(data => {
                if (data.personne.type === PersonGlobals.PERSON_LIEN) {
                    this.personne = data.personne.personnePhysique.liens.find(p => p.type === PersonGlobals.PERSON_LIEN_PHYSIQUE)
                } else {
                    this.personne = data.personne;
                }
                this.form.get('physique').setValue(this.personne);
            });
        }
    }

    public onSubmit(): void {
        const formData = {
            physique: this.form.controls.physique.value,
            morale: this.form.controls.morale.value,
            fonction: this.form.controls.fonction.value,
            fonction_personnalisee: this.form.controls.fonction_personnalisee.value
        };
        if (this.form.valid) {
            this.ngxService.start();
            this.personneService.createPersonneFonction(formData).subscribe(
                data => {
                    this.router.navigate(['/repertoire/' + data.content.uuid + '/modifier']);
                },
                err => {
                    this.showNotification('error', this.exceptionService.statutErreur(err));
                },
                () => {
                    this.ngxService.stop();
                }
            );
        } else {
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
            this.ngxService.stop();
        }

    }

    public showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 500);
    }

    public onCancel(): void {
        this.router.navigate(['/repertoire/' + this.uuid]);
    }
}
