<div class="carte"
     *ngIf="personne"
     (click)="openFiche(personne)"
     (auxclick)="$event.button === 1 && addTabs(personne)"
     [ngClass]="{'pm_arbo_current': arboType === PERSON_GLOBAL.PERSON_LINKED_CURRENT,
                'pm_arbo_parent': arboType === PERSON_GLOBAL.PERSON_LINKED_PARENT,
                'pm_arbo_enfant': arboType === PERSON_GLOBAL.PERSON_LINKED_ENFANT,
                'pm_arbo_physique': arboType === PERSON_GLOBAL.PERSON_LINKED_PHYSIQUE,
                'pp_arbo_curent': uuidFicheUrl === personne.uuid
                }"
>
    <div class="carte_content"
         [ngClass]="{current_personne_linked: ongletName === 'personne-linked', carte_content_disabled: formatCarte !== true}">
        <!--        bandeau PP-->
        <div class="carte_titre bandeau_personne bandeau_personne_{{personne.type}} icon user"
             *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_PHYSIQUE || personne.type === PERSON_GLOBALS.PERSON_LIEN">
            <h2>{{ personne.personnePhysique.libelle }}</h2>
        </div>
        <!--        bandeau PM-->
        <div class="carte_titre bandeau_personne bandeau_personne_{{personne.type}} icon building"
             *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN_MORALE">
            <h2>{{ personne.personneMorale.libelle }}</h2>
        </div>
        <!--        bandeaux Fonction-->
        <div class="carte_sous_titre {{personne.type}}"
             *ngIf="personne.type === PERSON_GLOBALS.PERSON_LIEN">
            <app-fonction-sticker [statut]="personne.statut"
                                  *ngIf="personne.statut?.libelle && router.url.endsWith('/repertoire')">
            </app-fonction-sticker>
            <p *ngIf="ongletName !== 'personne-linked'; else blockPPLinked">
                {{ personne.fonction.libelle }} <br/>
                {{ personne.personneMorale.libelle }}
            </p>
            <ng-template #blockPPLinked>
                <p>{{ personne.fonction.libelle }}</p>
            </ng-template>
        </div>
        <div class="carte_sous_titre" *ngIf="personne.type !== PERSON_GLOBALS.PERSON_LIEN">
            <app-fonction-sticker [statut]="personne.statut"></app-fonction-sticker>
        </div>
        <div class="carte_texte" *ngIf="ongletName !== 'personne-linked'">
            <ng-container>
                <div class="coordonnees_personne">
                    <p *ngIf="personne?.adressePrincipal?.adresse?.cp">{{personne?.adressePrincipal?.adresse?.cp}} {{personne?.adressePrincipal?.adresse?.ville}}</p>
                    <p *ngIf="!personne?.adressePrincipal?.adresse?.cp">{{personne?.personneMorale?.lien?.adressePrincipal?.adresse?.cp}} {{personne?.personneMorale?.lien?.adressePrincipal?.adresse?.ville}}</p>
                    <p *ngIf="personne?.mailPrincipal?.mail?.valeur"> {{personne?.mailPrincipal?.mail?.valeur}}</p>
                    <p *ngIf="!personne?.mailPrincipal?.mail?.valeur"> {{personne?.personneMorale?.lien?.mailPrincipal?.mail?.valeur}}</p>
                    <p *ngIf="personne?.telephonePrincipal?.telephone?.text"> {{personne?.telephonePrincipal?.telephone?.text}}</p>
                    <p *ngIf="!personne?.telephonePrincipal?.telephone?.text"> {{personne?.personneMorale?.lien?.telephonePrincipal?.telephone.text}}</p>
                </div>
            </ng-container>
            <div class="visibility">
                <span class="icon"
                      [ngClass]="{'unlock color-public': personne.visibilite === 1 || personne.visibilite === 2,
                                  'lock': personne.visibilite === 3
                                 }"
                >
                </span>
            </div>
        </div>
        <app-avatar [personne]="personne" [modeCarte]="this.formatCarte" [modeFiche]="this.formatFiche"
                    [centered]="this.centered"></app-avatar>
        <ng-container>
            <app-fiche-completion-notice [personne]="personne" [typeAlerts]="['optimiser_repertoire']"></app-fiche-completion-notice>
        </ng-container>
    </div>
</div>

<div *ngIf="modeAffichage === 'liste' && personne"
     (click)="openFiche(personne)"
     class="repertoire_liste"
>
</div>
