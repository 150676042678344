<app-fil-ariane></app-fil-ariane>
<div class="form_champ">
	<notifier-container></notifier-container>
	<ngx-ui-loader></ngx-ui-loader>
	<form name="form"
	      (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm"
	      [formGroup]="form"
	      novalidate
	>
		<div class="bandeau_parametre icon edition" *ngIf="edition; else creationTitle">
			<h1>{{ this.form.get('libelle').value }}</h1>
		</div>
		<ng-template #creationTitle>
			<div class="bandeau_parametre icon edition">
				<h1>Nouveau champ personnalisé</h1>
			</div>
		</ng-template>
		<div class="formulaire">
			<div class="container_form form_gauche">
				<div class="bloc_champ">
					<div class="bandeau_form">Informations générales</div>
					<div class="bloc_container">
						<div class="input_container full_width">
							<div class="input_lg">
								<label for="libelle">Intitulé</label>
								<input type="text"
								       id="libelle"
								       name="libelle"
								       formControlName="libelle"
								       [ngClass]="{'background_alert_required' : !form.controls.libelle.value}"
								/>
							</div>
						</div>
						<div class="input_container">
							<div class="">
								<label>Type de champ</label>
								<ng-select [items]="allFields"
								           [closeOnSelect]="true"
								           [clearable]="false"
								           bindValue="id"
								           bindLabel="valeur"
								           formControlName="type"
								           placeholder="Sélectionner le champ"
								           [ngClass]="{'background_alert_required': !this.form.get('type').value}"
								>
								</ng-select>
							</div>
							<div class="checkbox required">
								<input type="checkbox"
								       id="requis"
								       name="required"
								       class="icon"
								       [checked]="!!+form.controls.required.value"
								       formControlName="required"
								       (change)="onChangeCheckboxRequired($event)"
								>
								<label for="requis">Obligatoire</label>
							</div>
						</div>
						<div>
							<div class="entite_cible">
								<label>Entités cibles</label>
								<div class="checkbox require">
									<input type="checkbox"
									       id="target_physique"
									       name="{{this.form.get('cible').value}}"
									       class="icon"
									       value="2"
									       [ngClass]="{'background_alert_required':!form.controls.cible.value}"
									       [checked]="calcTarget(this.form.get('cible').value,[2,3,6,7])"
									       (change)="onChangeCheckboxTarget($event,2)"
									>
									<label for="target_physique">Personnes Physiques</label>
									<input type="checkbox"
									       id="target_morale"
									       name="{{this.form.get('cible').value}}"
									       class="icon"
									       value="1"
									       [ngClass]="{'background_alert_required':!form.controls.cible.value}"
									       [checked]="calcTarget(form.controls.cible.value,[1,3,5,7])"
									       (change)="onChangeCheckboxTarget($event,1)"
									>
									<label for="target_morale">Personnes Morales</label>
									<input type="checkbox"
									       id="target_fonction"
									       name="{{this.form.get('cible').value}}"
									       class="icon"
									       value="4"
									       [ngClass]="{'background_alert_required':!form.controls.cible.value}"
									       [checked]="calcTarget(form.controls.cible.value,[4,5,6,7])"
									       (change)="onChangeCheckboxTarget($event,4)"
									>
									<label for="target_fonction">Fonctions</label>
								</div>
							</div>
						</div>
						<div>
							<div class="champ_valeur"
							     *ngIf="this.form.get('type').value?.toString() === CHAMPS_GLOBAL.CHAMP_LISTE.id ||
                                 this.form.get('type').value?.toString() === CHAMPS_GLOBAL.CHAMP_LISTE_MULTIPLE.id ||
                                 this.form.get('type').value?.toString() === CHAMPS_GLOBAL.CHAMP_RADIO.id ||
                                 this.form.get('type').value?.toString() === CHAMPS_GLOBAL.CHAMP_CHECKBOX.id">
								<div class="inline">
									<div>
										<label *ngIf="this.form.get('valeurs').value">Valeurs possibles</label>
									</div>
									<ng-container [ngTemplateOutlet]="sorting"></ng-container>
								</div>
								<ng-container [ngTemplateOutlet]="multifields"></ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container_form form_droit" *ngIf="edition">
				<div class="bloc_champ">
					<div class="bandeau_form">Utlisé dans...</div>
					<div class="bloc_container">
						<div class="data_use">
							<span>Personnes physiques</span>
							<span *ngIf="nbUsePP; else elseBlock">{{nbUsePP}}</span>
						</div>
						<div class="data_use">
							<span>Personnes morales</span>
							<span *ngIf="nbUsePM; else elseBlock">{{nbUsePM}}</span>
						</div>
						<div class="data_use">
							<span>Fonctions</span>
							<span *ngIf="nbUseFunction; else elseBlock">{{nbUseFunction}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="form_bas">
		<button type="button" class="btn btn-med btn-fermer" (click)="onCancel()"><span>Fermer</span></button>
		<button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmit()"><span>Enregistrer</span></button>
		<ng-container *ngIf="edition">
			<button type="button" class="btn btn-med btn-enregistrer" (click)="onSubmitCancel()"><span>Enregistrer et fermer</span>
			</button>
		</ng-container>
	</div>
</div>
<app-modal-confirm-exit #confirmModal (cancelEvent)="onCancel()"></app-modal-confirm-exit>

<!--- TEMPLATES --->
<ng-template #elseBlock>0</ng-template>
<ng-template #multifields>
	<div class="draggable-list" (cdkDropListDropped)="drop($event)"
	     [formGroup]="form"
	     cdkDropList
	>
		<div class="draggable-box"
		     formArrayName="valeurs"
		     *ngFor="let val of formValeurs.controls; let i = index"
		     cdkDragLockAxis="y"
		     cdkDrag>
			<ng-container [formGroupName]="i">
				<div class="draggable-placeholder" *cdkDragPlaceholder></div>
				<div cdkDragHandle class="drag-center">
					<div class="icon grip-vertical"></div>
				</div>
				<div>
					<input type="text"
					       formControlName="text"
					       value="{{val.text}}"
					       id="id-{{val.id}}"
					/>
				</div>
				<div>
					<button type="button" type="button" class="btn btn-picto-lg btn-supprimer"
					        (click)="removeValeur(i)"></button>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="action_valeur_champ">
		<button type="button" class="btn btn-picto-med btn-ajouter"
		        (click)="ajouterChamp()"></button>
	</div>
</ng-template>
<ng-template #single_field let-field_type="fieldType">
	<div class="input_lg input_container full_width"
	     [formGroup]="form"
	>
		<input type="{{ field_type }}"
		       class="input_champ_valeur"
		       formControlName="valeurs"
		/>
	</div>
</ng-template>

<ng-template #sorting>
	<div class="sorting">
		<button type="button">
			<fa-icon [icon]="faSortAlphaDown"
			         size="lg"
			         (click)="sortAlphaDown()"
			         class="action"
			         title="tri par ordre alphabétique décroissant"
			></fa-icon>
		</button>
		<button type="button">
			<fa-icon [icon]="faSortAlphaUp"
			         size="lg"
			         (click)="sortAlphaUp()"
			         class="action"
			         title="tri par ordre alphabétique croissant"
			></fa-icon>
		</button>
	</div>
</ng-template>
