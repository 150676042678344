<div>
    <input type="checkbox"
           class="switch"
           id="actif"
           [checked]="!!+switchValue"
           (change)="onSwitch($event)"
    >
    <label for="actif"
           class="switch"
           *ngIf="!!+switchValue">Actif
    </label>
    <label for="actif"
           class="switch"
           *ngIf="!+switchValue">Inactif
    </label>
</div>
