<app-fil-ariane></app-fil-ariane>
<div class="body-container"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     (scrolled)="onScroll()">
    <div class="main">
        <div>
            <app-repertoire-outils
                    (search)="onRecherche($event)"
                    [type]="'products'"
                    (activeValue)="switchActive($event)"
            >
            </app-repertoire-outils>
        </div>
        <ngx-ui-loader></ngx-ui-loader>
        <div class="content_cartes"

        >
            <div *ngIf="products === undefined || products.length === 0" class="message_information">Aucun résultat
                trouvé
            </div>
            <ul class="cartes" >
                <ng-container *ngIf="products">
                    <li *ngFor="let product of products" class="products_item">
                        <app-carte-produit [product]="product"></app-carte-produit>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <notifier-container></notifier-container>
</div>

