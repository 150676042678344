<div class="container_visibilite"
     [formGroup]="form"
     *ngIf="form"
>
    <div class="bandeau_form">
        Visibilité
        <div class="input_visibilite">
            <input type="checkbox"
                   class="switch"
                   id="actif"
                   [ngClass]="{'background_alert_required': !form.controls.actif.value}"
                   [checked]="!!+form.controls.actif.value"
                   (change)="onChangeActif($event)"
            >
            <label for="actif"
                   class="switch"
                   *ngIf="!!+form.controls.actif.value">Actif
            </label>
            <label for="actif"
                   class="switch"
                   *ngIf="!+form.controls.actif.value">Inactif
            </label>
        </div>
    </div>
    <div class="bloc_container">
        <div>
            <div class="choix_visibilite">
                <input type="radio"
                       name="visibilite"
                       id="visibilite_public"
                       [value]="1"
                       formControlName="visibilite"
                       [ngClass]="{'background_alert_required': !form.controls.visibilite.value}"
                >
                <label for="visibilite_public">Public</label>
                <input type="radio"
                       name="visibilite"
                       id="visibilite_protege"
                       [value]="2"
                       formControlName="visibilite"
                       [ngClass]="{'background_alert_required': !form.controls.visibilite.value}"
                >
                <label for="visibilite_protege">Protégé</label>
                <input type="radio"
                       name="visibilite"
                       id="visibilite_prive"
                       [value]="3"
                       formControlName="visibilite"
                       [ngClass]="{'background_alert_required': !form.controls.visibilite.value}"
                >
                <label for="visibilite_prive">Privé</label>
            </div>
            <div class="info_visibilite" *ngIf="form.controls.visibilite.value === '1'">
                <label>Lecture : Tous</label>
                <label>Ecriture: Tous</label>
            </div>
            <div class="info_visibilite" *ngIf="form.controls.visibilite.value === '2'">
                <label>Lecture : Tous</label>
                <label>Ecriture: Créateur, Référent, Intervenants</label>
            </div>
            <div class="info_visibilite" *ngIf="form.controls.visibilite.value === '3'">
                <label>Lecture : Créateur, Référent, Intervenants</label>
                <label>Ecriture: Créateur, Référent, Intervenants</label>
            </div>
        </div>
        <div class="input_container full_width"><br>
            <div *ngIf="this.form">
                <label>Référent</label>
                <app-users-select2 [form]="form"
                                   [id]="'referent'"
                                   [formCreation]="creation"
                                   [multiple]="false">
                </app-users-select2>
            </div>
        </div>
        <div class="input_container full_width" *ngIf="this.form">
            <div>
                <label>Intervenant(s)</label>
                <app-users-select2 [form]="form" [id]="'intervenants'" [multiple]="true"></app-users-select2>
            </div>
        </div>
    </div>
</div>
