import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HelperService} from './helper.service';

@Injectable({
    providedIn: 'root'
})
export class VisibiliteService {

    constructor(private helperService: HelperService) {
    }

    private subjectName = new Subject<any>();

    sendData(uuid: string, type: string): void {
        if (uuid && type) {
            this.helperService.getIntervenants(uuid, type).subscribe(
                data => {
                    this.subjectName.next(data);
                },
                err => {
                    console.log(err);
                }
            );
        }
    }

    receiveData(): Observable<any> {
        return this.subjectName.asObservable();
    }
}
