import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalService} from '../../_modal';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-modal-confirm-exit',
  templateUrl: './modal-confirm-exit.component.html',
  styleUrls: ['./modal-confirm-exit.component.scss']
})
export class ModalConfirmExitComponent implements OnInit {

  private modalOpen = false;
  public modified = new Subject<boolean>();
  @Output() cancelEvent = new EventEmitter<boolean>();
  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.modified.next(true);
  }

  public openConfirmationModal(): void {
    this.modalService.open('confirmCancelling');
    this.modalOpen = true;
  }

  public confirmExitModal(): void {
    this.modalOpen = false;
    this.modified.next(false);
    this.cancelEvent.emit(true);
  }

  public cancelConfirmationModal(): void {
    this.modalService.close('confirmCancelling');
    this.modalOpen = false;
  }

  public isOpened(): boolean {
    return this.modalOpen;
  }

}
