<div class="recherche-bandeau-outils">
    <button type="button" class="icon-search"></button>
    <input type="search"
           #debouceInput
           class="input-selection"
           placeholder="Recherche"
           size="30"
           [ngModel]="model"
           (ngModelChange)="modelChangeSubject.next($event)"
    />
</div>
