import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IChamp} from '../../../../interfaces/champ';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalConfirmExitComponent} from '../../../layout/modal-confirm-exit/modal-confirm-exit.component';
import {ChampPersoService} from '../../../_services/champ-perso.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ExceptionService} from '../../../_services/exception.service';
import {NotifierService} from 'angular-notifier';
import {PersonGlobals} from '../../../../const-global/globals';
import {ChampsPersoGlobals} from '../../../../const-global/champs-perso';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Subscription} from "rxjs";
import {faSortAlphaDown, faSortAlphaUp} from "@fortawesome/free-solid-svg-icons";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {MessageGlobals} from "../../../../const-global/messages";

@Component({
    selector: 'app-form-champs-perso',
    templateUrl: './form-champs-perso.component.html',
    styleUrls: ['./form-champs-perso.component.scss']
})
export class FormChampsPersoComponent implements OnInit, OnDestroy {
    faSortAlphaDown = faSortAlphaDown;
    faSortAlphaUp = faSortAlphaUp;

    public form: FormGroup;
    public champ: IChamp;
    public valeurCible = 0;
    public uuidChamp = this.route.snapshot.params.id;
    public nbUsePP: number;
    public nbUsePM: number;
    public nbUseFunction: number;
    private modified = false;
    public edition = false;
    public readonly CHAMPS_GLOBAL = ChampsPersoGlobals;

    private mmultipleType = [
        this.CHAMPS_GLOBAL.CHAMP_LISTE.id,
        this.CHAMPS_GLOBAL.CHAMP_LISTE_MULTIPLE.id,
        this.CHAMPS_GLOBAL.CHAMP_RADIO.id,
        this.CHAMPS_GLOBAL.CHAMP_CHECKBOX.id
    ];
    private subscriptions = {
        champSubscription: Subscription.EMPTY,
        formSubs: Subscription.EMPTY,
        fieldList: Subscription.EMPTY
    };

    allFields = [];

    @ViewChild('champText') champText: ElementRef;
    @ViewChild('confirmModal') confirmModal: ModalConfirmExitComponent;

    constructor(
        private router: Router,
        public route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private champService: ChampPersoService,
        private ngxService: NgxUiLoaderService,
        private exceptionService: ExceptionService,
        public notifier: NotifierService
    ) {
    }

    get formValeurs(): FormArray {
        return this.form.get('valeurs') as FormArray;
    }

    newValeur() {
        const newIndex = String(this.form.get('valeurs').value.length);
        return this.formBuilder.group(
            {
                id: newIndex,
                weight: 0,
                text: ''
            }
        )
    }

    addValeur() {
        this.formValeurs.push(this.newValeur());
    }

    removeValeur(i: number) {
        this.formValeurs.removeAt(i);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            libelle: ['', Validators.required],
            type: [null, Validators.required],
            required: [''],
            cible: [0, [Validators.required, Validators.min(1), Validators.max(7)]],
            valeurs: null,
            uuid: ['']
        }, {});
        this.subscriptions.fieldList = this.champService.getAllTypeChampsPerso().subscribe(
            (data: Array<any>) => {
                this.allFields = [...data];
            }
        );
        if (this.uuidChamp) {
            this.edition = true;
            this.subscriptions.champSubscription = this.champService.getDatasChamp(this.uuidChamp).subscribe(data => {
                    let self = this;
                    this.form = this.formBuilder.group({
                        libelle: [data.libelle, Validators.required],
                        type: [parseInt(data.type), Validators.required],
                        required: [data.required],
                        cible: [
                            Number(data.cible),
                            [Validators.required, Validators.min(1), Validators.max(7)]
                        ],
                        valeurs: [data.valeurs],
                        uuid: [data.uuid]
                    }, {});
                    this.valeurCible = data.cible;
                    if (this.mmultipleType.some(s => s.includes(data.type))) {
                        this.form.controls.valeurs = this.formBuilder.array([]);
                        data.valeurs.forEach(function (valeur) {
                            self.formValeurs.push(
                                self.formBuilder.group(
                                    {
                                        id: valeur.id,
                                        weight: valeur.weight,
                                        text: valeur.text
                                    })
                            )
                        })
                    }
                }, () => {

                },
                () => {
                    this.countChampUsage();
                });
        }
        this.form.controls.type.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(typeId => {
                if (typeId) {
                    if (this.mmultipleType.some(s => s.includes(typeId))) {
                        this.form.controls.valeurs = this.formBuilder.array([]);
                    } else {
                        this.form.controls.valeurs = this.formBuilder.control('');
                    }
                }
            }
        );
    }

    public countChampUsage(): void {
        this.champService.countChampUsage(this.uuidChamp).subscribe(
            data => {
                this.nbUsePP = data.filter(count => count.pl_type === PersonGlobals.PERSON_LIEN_PHYSIQUE)[0]?.nbUsage;
                this.nbUsePM = data.filter(count => count.pl_type === PersonGlobals.PERSON_LIEN_MORALE)[0]?.nbUsage;
                this.nbUseFunction = data.filter(count => count.pl_type === PersonGlobals.PERSON_LIEN)[0]?.nbUsage;
            }
        );
    }

    private showNotification(type: string, message: string): void {
        setTimeout(() => {
            this.notifier.notify(type, message);
        }, 1000);
    }

    // Perso
    onSubmit(): void {
        this.ngxService.start();
        if (this.form.valid) {
            if (this.mmultipleType.some(s => s.includes(this.form.get('type').value?.toString()))) {
                this.recalculatedWeight();
            }
            if (this.edition) {
                this.updateChampPerso(this.form.getRawValue());
            } else {
                this.createChampPerso(this.form.getRawValue());
            }
            this.ngxService.stop();
        } else {
            this.ngxService.stop();
            this.showNotification('error', MessageGlobals.MESSAGE_CHAMPS_REQUIS);
        }
    }

    private createChampPerso(formData: IChamp): void {
        this.champService.creerChamp(formData).subscribe(
            data => {
                this.ngxService.stop();
                this.showNotification('success', 'Le champ ' + this.form.controls.libelle.value + ' vient d\'être crée');
                setTimeout(() => {
                    this.router.navigate(['/champs-personnalise/' + data.uuid]);
                }, 1000);
            },
            err => {
                this.ngxService.stop();
                this.showNotification('error', this.exceptionService.statutErreur(err));
            }
        );
    }

    private updateChampPerso(formData: IChamp): void {
        this.champService.updateChamp(this.uuidChamp, formData).subscribe(
            () => {
            },
            err => {

                this.showNotification('error', this.exceptionService.statutErreur(err));
            },
            () => {
                this.ngxService.stop();
                this.showNotification('success', 'Le champ ' + this.form.controls.libelle.value + ' vient d\'être modifié');
            }
        );
    }

    // Ferme le formulaire, sauvegarde et redirige sur la liste des champs
    public onSubmitCancel(): void {
        this.onSubmit();
        setTimeout(() => {
            if (this.form.valid) {
                this.router.navigate(['champs-personnalise']);
            } else {
                this.showNotification('error', 'Une erreur est présente sur le formulaire');
            }
        }, 1000);
    }

    public onCancel(): void {
        if (this.edition) {
            if (this.modified) {
                this.confirmModal.openConfirmationModal();
            }
            if (!this.confirmModal.isOpened()) {
                this.router.navigate(['/champs-personnalise']);
            }
        } else {
            this.router.navigate(['/champs-personnalise']);
        }
    }

    public ajouterChamp(): void {
        this.addValeur();
    }

    public onChangeCheckboxRequired($event): void {
        if ($event.target.checked) {
            this.form.controls.required.setValue('1');
        } else {
            this.form.controls.required.setValue('0');
        }
    }

    public onChangeCheckboxTarget($event, valueCheckbox): void {
        if ($event.target.value === '1' || $event.target.value === '2' || $event.target.value === '4') {
            if ($event.target.checked) {
                this.valeurCible += valueCheckbox;
            } else {
                this.valeurCible -= valueCheckbox;
            }
        }
        this.form.get('cible').setValue(this.valeurCible);
    }

    public calcTarget(cible, array): number {
        return array.includes(cible);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.formValeurs.controls, event.previousIndex, event.currentIndex);
    }

    /**
     * Recalculate fields weight
     */
    public recalculatedWeight() {
        let i = 0;
        this.formValeurs.controls.forEach(champ => {
            champ.patchValue({weight: String(i)});
            i = i + 1;
        });
    }

    /**
     * Kills subscription(s) on component destroy event
     */
    ngOnDestroy(): void {
        // noinspection JSUnusedLocalSymbols
        for (const [key, value] of Object.entries(this.subscriptions)) {
            value.unsubscribe();
        }
    }

    sortAlphaDown() {
        if (this.formValeurs.controls.length > 0) {
            this.formValeurs.controls.sort(function (a, b) {
                return a.value.text.localeCompare(b.value.text);
            });
        }
    }

    sortAlphaUp() {
        if (this.formValeurs.controls.length > 0) {
            this.formValeurs.controls.sort(function (a, b) {
                return b.value.text.localeCompare(a.value.text);
            });
        }
    }
}
