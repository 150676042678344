'use strict';

export class ModalInformations {
    static readonly RGPD: object = {
        titre:'Règlementation RGPD',
        aide:'En application de la règlementation sur la protection des données personnelles,  il vous incombe en' +
            ' tant que responsable de traitement de recueillir le consentement des personnes concernées pour le' +
            ' traitement de leurs données avant leur intégration au sein de l’application ainsi que le moyen de' +
            ' recueil de ce consentement.',
        bas:'SEPTEO LEGALTECH ne pourra être tenu pour responsable en cas de litige ou de blocage des envois' +
            ' d’e-mails suite à une mauvaise utilisation des consentements.',
        validerOn:true,
        fermerOn:false,
        validerTexte:'J\'ai compris',
        fermerTexte:''
    }
    static readonly LEAVE_PAGE: object = {
        titre:'Avertissement',
        aide:'Si vous quittez cette page, les modifications que vous venez de réaliser ne seront pas enregistrées.',
        bas:'Souhaitez-vous quitter la page sans enregistrer ?',
        validerOn:true,
        fermerOn:true,
        validerTexte:'Oui',
        fermerTexte:'Non'
    }
    static FILTER_DATA: object = {
        titre:'Avertissement',
        aide:'Si vous filtrez ces informations, la sélection en cours ne sera plus appliquée et/ou les modifications' +
            ' que vous venez de réaliser ne seront pas enregistrées.',
        bas:'Souhaitez-vous continuer ?',
        validerOn:true,
        fermerOn:true,
        validerTexte:'Oui',
        fermerTexte:'Non'
    }
}
